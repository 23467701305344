import { createSelector } from 'reselect';
import { RootState } from '..';

const WalletConnectPopUpState = (state: RootState) => state.wallet;
/**
 * @description State of DEX, please keep ONE instance within app
 */
export const walletPopUpIsOpenSelector = createSelector(
  WalletConnectPopUpState,
  ({ isOpen }) => isOpen
);
