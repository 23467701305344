import { Stack, styled, Typography } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledItemContainer = styled(Stack)(({ theme }) => ({
  padding: '16px',
  background: Color.BACKGROUND_NFTS,
  borderRadius: BorderRadius.SEMI_SMALL,
  backgroundImage: `linear-gradient(#211824, #421539), ${Color.BORDER_HOT_COLLECTION_NFT}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  border: Border.TRANSPARENT_BORDER,
  overflow: 'hidden',
  [theme.breakpoints.down('mobile')]: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '420px',
  },
}));

export const StyledImage = styled('img')(() => ({
  marginBottom: '24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  height: '250px',
  width: '100%',
  display: 'block',
  objectFit: 'cover',
}));

export const StyledCollectionTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.TEXT_GRAY_SECONDARY,
  marginBottom: '8px',
}));

export const StyledCollectionType = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.GREEN,
  margin: 0,
}));

export const StyledNftTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  color: Color.WHITE,
  paddingBottom: '16px',
  borderBottom: Border.ACCENT_BORDER,
}));

export const StyledPriceContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '16px 0 8px',
}));

export const StyledPriceTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  color: Color.WHITE_OPACITY_LIGHT,
  whiteSpace: 'nowrap',
}));

export const StyledPriceUSDValue = styled(Typography)(() => ({
  marginRight: '8px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  color: Color.TEXT_GRAY_SECONDARY,
}));

export const StyledTokenIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
}));

export const StyledPriceValue = styled(Typography)(() => ({
  marginLeft: '8px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  fontWeight: 500,
}));
