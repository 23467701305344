import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Pagination } from '@mui/material';

import ProjectItem from '../ProjectItem';
import { getUpcomingProjectsThunk } from 'state/launchpad/thunks';
import launchpadSlice from 'state/launchpad/slice';
import { upcomingProjectsSelector } from 'state/launchpad/selectors';
import { useAppSelector } from '@hooks/useAppSelector';
import { StyledTitle } from '../CurrentProjects/styles';
import {
  StyledUpcomingProjectsList,
  StyledUpcomingProjectsWrapper,
} from './styles';
import { Color } from 'helpers/themeStyles';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';

const UpcomingProjects: React.FC = () => {
  const { t } = useTranslation();
  const mobileUpcomingProjects = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();
  const upcomingProjects = useAppSelector(upcomingProjectsSelector);

  const [page, setPage] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getUpcomingProjectsThunk({ status: 'upcoming', limit: 4, page: value })
    );
    mobileUpcomingProjects?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    const queryParams = { status: 'upcoming', limit: 4, page: page };

    dispatch(getUpcomingProjectsThunk(queryParams));
    return function cleanup() {
      dispatch(launchpadSlice.actions.resetUpcomingProjects());
    };
  }, []);

  return (
    <div ref={mobileUpcomingProjects}>
      <StyledUpcomingProjectsWrapper
        style={{ marginBottom: upcomingProjects?.docs.length ? '0' : '60px' }}
      >
        <StyledTitle variant='h3'>{t('lpUpcomingProjectsTitle')}</StyledTitle>
        <StyledUpcomingProjectsList>
          {!!upcomingProjects?.docs.length &&
            upcomingProjects?.docs.map((project) => (
              <ProjectItem
                key={project._id}
                project={project}
              />
            ))}
        </StyledUpcomingProjectsList>
        {upcomingProjects && upcomingProjects.totalDocs > 4 && (
          <Pagination
            count={upcomingProjects?.totalPages ?? 1}
            page={page}
            onChange={handleChange}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            sx={{
              '& .MuiPagination-ul': {
                justifyContent: 'center',
                marginBottom: '24px',
              },
              '& .MuiButtonBase-root': {
                color: Color.WHITE,
              },
            }}
          />
        )}
      </StyledUpcomingProjectsWrapper>
    </div>
  );
};

export default UpcomingProjects;
