import {
  Avatar,
  Card,
  PaginationItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledTypography = styled(Typography)(() => ({
  marginBottom: '32px',
  textAlign: 'left',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  width: 'calc((100% - 48px)/4)',
  [theme.breakpoints.down(1320)]: {
    width: 'calc((100% - 20px)/2)',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    maxWidth: '420px',
  },
}));

export const StyledCard = styled(Card)(() => ({
  padding: '16px',
  height: '282px',
  background: Color.BACKGROUND_NFTS,
  borderRadius: BorderRadius.SEMI_SMALL,
  backgroundImage: `linear-gradient(#211824, #421539), ${Color.BORDER_HOT_COLLECTION_NFT}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  border: Border.TRANSPARENT_BORDER,
  position: 'relative',
}));

export const StyledAvatar = styled(Avatar)(() => ({
  width: 64,
  height: 64,
  position: 'absolute',
  bottom: '110px',
  left: '36px',
  border: `4px solid ${Color.WHITE}`,
  borderRadius: BorderRadius.SMALL,
}));

export const StyledTitle = styled(Typography)(() => ({
  marginBottom: '16px',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textTransform: 'uppercase',
  color: Color.WHITE,
}));

export const BottomWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
}));

export const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  color: Color.WHITE,
  '&.MuiButtonBase-root': {
    fontSize: TextSize.NORMAL,
    fontFamily: FontFamily.INTER,
    borderRadius: BorderRadius.SEMI_SMALL,
    '&.Mui-selected': {
      fontWeight: 700,
      background: `${Color.DARK_PURPLE}!important`,
    },
  },
}));

export const StyledNoImgContainer = styled(Stack)(() => ({
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BACKGROUND,
  width: '100%',
  height: '60%',
  alignItems: 'center',
  justifyContent: 'center',
}));
