import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import {
  DEXToken,
  Percent,
  Position,
  UniswapProvider,
} from '@omisoftnet/game-dex-sdk';
import { utils, BigNumber } from 'ethers';
import { useAccount } from 'wagmi';

import {
  PooledContainer,
  PooledTokenContainer,
  PooledToken,
} from 'components/RemoveLiquidityModal/styles';
import { dexSelector } from 'state/dex/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import { transactionStatuses } from 'helpers/transactionStatuses';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { explorers } from 'helpers/explorers';

import {
  MainContent,
  StyledDialog,
  StyledHeadingContainer,
  StyledIconButton,
  StyledTitle,
  StyledRemoveButton,
  TokenFeesTitle,
} from './styles';
import { IPosition } from '@omisoftnet/game-dex-sdk/dist/providers';
import { PageRoutes } from 'config/routes';

const RemoveLiquiditySubmitter = ({
  firstTokenSymbol,
  secondTokenSymbol,
  isOpen,
  onClose,
  firstTokenIcon,
  secondTokenIcon,
  firstTokenValue,
  secondTokenValue,
  slippageTolerance,
  position,
  collectAsWETH,
  percentToRemove,
  firstTokenAddress,
  secondTokenAddress,
  firstTokenChainId,
  secondTokenChainId,
  firstTokenDecimals,
  secondTokenDecimals,
  firstTokenName,
  secondTokenName,
  liquidityId,
  token0Fees,
  token1Fees,
}: {
  firstTokenSymbol: string;
  secondTokenSymbol: string;
  isOpen: boolean;
  onClose: () => void;
  firstTokenIcon: string;
  secondTokenIcon: string;
  firstTokenValue: string;
  secondTokenValue: string;
  slippageTolerance?: number | undefined;
  position: Position;
  collectAsWETH: boolean;
  percentToRemove: Percent;
  firstTokenAddress: string;
  secondTokenAddress: string;
  firstTokenChainId: number;
  secondTokenChainId: number;
  firstTokenDecimals: number;
  secondTokenDecimals: number;
  firstTokenName: string;
  secondTokenName: string;
  liquidityId: string;
  token0Fees: string;
  token1Fees: string;
}) => {
  const { t } = useTranslation();
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dex = useSelector(dexSelector);
  const { address } = useAccount();
  async function removeLiquidity() {
    const token0 = DEXToken.fromAddress(
      firstTokenAddress,
      utils.parseUnits(firstTokenValue, firstTokenDecimals),
      firstTokenChainId,
      firstTokenDecimals,
      firstTokenSymbol,
      firstTokenName,
      firstTokenIcon
    );
    const token1 = DEXToken.fromAddress(
      secondTokenAddress,
      utils.parseUnits(secondTokenValue, secondTokenDecimals),
      secondTokenChainId,
      secondTokenDecimals,
      secondTokenSymbol,
      secondTokenName,
      secondTokenIcon
    );
    if (!dex) return;
    const provider = await dex.getDEXProvider(
      firstTokenChainId,
      token0,
      token1
    );

    if (provider instanceof UniswapProvider) {
      try {
        const tx = await (
          await provider.removeLiquidity(
            Number(liquidityId),
            position,
            percentToRemove,
            { useNative: !collectAsWETH } // By default uni collect as WETH, but can give ETH
          )
        ).wait(1);

        dispatch(
          setTransactionHash({
            hash: tx.transactionHash,
            from: tx.from,
            to: tx.to,
            owner: address!,
            chainId: firstTokenChainId,
            transaction_type: 'removeLiquidity',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: firstTokenIcon,
                },
                token1: {
                  ...token1,
                  logoURI: secondTokenIcon,
                },
                token0Amount: firstTokenValue,
                token1Amount: secondTokenValue,
              },
            },
          })
        );
        setOpenWaitingPopup(false);
        setTransactionSubmittedStatus(true);
      } catch (error) {
        console.error(error); // for debug
        setOpenWaitingPopup(false);
        setTransactionRejectedStatus(true);
      }
    }
  }

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => onClose()}
      >
        <StyledHeadingContainer>
          <StyledTitle>{t('removeLiquidityTitle')}</StyledTitle>
          <StyledIconButton onClick={() => onClose()}>
            <CloseIcon />
          </StyledIconButton>
        </StyledHeadingContainer>
        <MainContent>
          <PooledContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {t('pooled')} {firstTokenSymbol}:
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                {firstTokenValue}
                <img
                  src={firstTokenIcon}
                  width='24'
                  height='24'
                />
              </PooledToken>
            </PooledTokenContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {t('pooled')} {secondTokenSymbol}:
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                {secondTokenValue}
                <img
                  src={secondTokenIcon}
                  width='24'
                  height='24'
                />
              </PooledToken>
            </PooledTokenContainer>
            {(token0Fees !== '0.0' || token1Fees !== '0.0') && (
              <>
                <TokenFeesTitle>{t('collectFeesTitle')}</TokenFeesTitle>
                <PooledTokenContainer>
                  <PooledToken
                    style={{ fontWeight: '600', marginRight: '12px' }}
                  >
                    {firstTokenSymbol} {t('feesEarned')}:
                  </PooledToken>
                  <PooledToken
                    style={{
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                  >
                    {token0Fees === '0.0' ? 0 : token0Fees}
                    <img
                      src={firstTokenIcon}
                      width='24'
                      height='24'
                    />
                  </PooledToken>
                </PooledTokenContainer>
                <PooledTokenContainer>
                  <PooledToken
                    style={{ fontWeight: '600', marginRight: '12px' }}
                  >
                    {secondTokenSymbol} {t('feesEarned')}:
                  </PooledToken>
                  <PooledToken
                    style={{
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                  >
                    {token1Fees === '0.0' ? 0 : token1Fees}
                    <img
                      src={secondTokenIcon}
                      width='24'
                      height='24'
                    />
                  </PooledToken>
                </PooledTokenContainer>
              </>
            )}
          </PooledContainer>
          <StyledRemoveButton
            onClick={() => {
              //onClose();
              setOpenWaitingPopup(true);
              removeLiquidity();
            }}
          >
            {t('remove')}
          </StyledRemoveButton>
        </MainContent>
      </StyledDialog>
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          action={t('removing')}
          firstTokenSymbol={firstTokenSymbol}
          secondTokenSymbol={secondTokenSymbol}
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          firstTokenValue={firstTokenValue}
          secondTokenValue={secondTokenValue}
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => navigate(PageRoutes.Liquidity)}
          result={transactionStatuses.SUBMIT}
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
};

export default RemoveLiquiditySubmitter;
