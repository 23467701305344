import {
  Button,
  Dialog,
  Slider,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import {
  Color,
  Border,
  BorderRadius,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '531px',
    borderRadius: BorderRadius.SMALL,
    paddingBottom: '10px',
    background:
      'linear-gradient(180deg, rgb(44, 31, 73) 0%, rgb(94, 39, 120) 100%)',

    [theme.breakpoints.down('mobile')]: {
      minWidth: 'inherit',
      width: '100%',
      margin: 0,
      padding: 0,
      border: 'none',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
    },
  },
}));

export const StyledDialogTitle = styled('h6')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: '700',
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '22.5px 21px',
  borderBottom: ` 1px solid ${Color.DARK_PURPLE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    borderTop: Border.ACCENT_BORDER,
    borderBottom: Border.ACCENT_BORDER,
    alignItems: 'center',
    padding: '19px 21px',
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: '16px',
});

export const MainContent = styled('div')({
  padding: '16px 16px 0px',
  display: 'flex',
  rowGap: '16px',
  flexDirection: 'column',
});

export const AmountContainer = styled('div')({
  borderRadius: BorderRadius.NORMAL,
  backgroundColor: Color.AMOUNT_BACKGROUND,
  padding: 20,
});

export const AmountTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  lineHeight: '150%',
});

export const AmountPercentageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const AmountPercentage = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_LARGE,
  lineHeight: '150%',
  color: Color.WHITE_OPACITY_LIGHT,

  [theme.breakpoints.down('mobile')]: {
    fontWeight: 700,
    fontSize: TextSize.MEDIUM,
  },
}));

export const AmountButtonGroup = styled(ToggleButtonGroup)({
  display: 'flex',
  columnGap: '22px',
});

export const PercentageBtn = styled(ToggleButton)({
  padding: '4px 6px',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  lineHeight: '150%',
  fontSize: TextSize.SUPER_SMALL,
  backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  borderRadius: `${BorderRadius.SMALL}!important`,
  maxHeight: '26px',
});
export const StyledSlider = styled(Slider)({
  color: Color.WHITE_OPACITY,
  '& .MuiSlider-thumb': {
    color: Color.PINK,
  },
});

export const PooledContainer = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  padding: 16,
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
});

export const PooledTokenContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const PooledToken = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const CollectContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginTop: '10px',
  marginBottom: '35px',

  [theme.breakpoints.down('mobile')]: {
    marginBottom: '25px',
  },
}));

export const StyledSwitch = styled(Switch)({
  width: 69,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    marginTop: -5,
    marginLeft: -1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: Color.WHITE_OPACITY,
    height: 28,
    borderRadius: BorderRadius.HUGE,
  },
});

export const RemoveBtnContainer = styled('div')(({ theme }) => ({
  padding: '20px 16px 0px',
  borderTop: `1px solid ${Color.DARK_PURPLE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    padding: '20px 16px',
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const StyledRemoveButton = styled(Button)(({ theme }) => ({
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: '18px 0px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    padding: '9px 0px',
  },
}));
