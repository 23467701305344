import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ROUTES } from '../../config/apiRoutes';
import { ADD_TRANSACTION_URL, BASE_URL } from '../../helpers/constants';

interface IState {
  tx: any;
  from: string;
  to: string;
  publicAddress: string;
}

const initialState: IState = {
  tx: '',
  from: '',
  to: '',
  publicAddress: '',
};

const transactionsSlice = createSlice({
  name: 'transaction',
  initialState: initialState,
  reducers: {
    setTransactionHash: (state, action) => {
      state.tx = action.payload;
      (async () => {
        if (state.tx.data) {
          try {
            axios.post(ADD_TRANSACTION_URL, state.tx, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            });
          } catch (err) {
            console.error(err);
          }
        }
      })().catch(console.error);
    },
    setClearAllTransactions: (state, action) => {
      state.publicAddress = action.payload;
      (async () => {
        try {
          axios.delete(
            BASE_URL + API_ROUTES.TRANSACTIONS.CLEAR_ALL(state.publicAddress),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          );
        } catch (err) {
          console.error(err);
        }
      })().catch(console.error);
    },
  },
  extraReducers: (builder) => builder,
});

export const { setTransactionHash, setClearAllTransactions } =
  transactionsSlice.actions;

export default transactionsSlice;
