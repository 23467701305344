import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { SubTitleStyled } from 'components/NFTBuyModal/styles';
import { SelectedNft } from 'types/nft';
import getNetworkIcon from 'helpers/getNetworkIcon';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import {
  NftInfoBlockContainer,
  StyledNftForSale,
  StyledNftImage,
  StyledNftTitle,
  StyledNftTitlePrice,
  StyledPrice,
  StyledSmallNoImage,
} from './styles';
import DefaultTokenIcon from 'svg/DefaultTokenIcon';
import { makeShortTextLine } from 'helpers/makeShortTextLine';
import { useAccount } from 'wagmi';

type BlockProps = {
  selectedNFT: SelectedNft;
  onDetailsOpen: () => void;
  forSale: boolean;
  price: string;
  lowestPriceUSD: string;
  icon: string;
};

const NftDetailsBlock = ({
  selectedNFT,
  onDetailsOpen,
  forSale,
  price,
  lowestPriceUSD,
  icon,
}: BlockProps) => {
  const { t } = useTranslation();
  const { chain } = useAccount();
  const [imgError, setImgError] = useState(false);

  return (
    <>
      <NftInfoBlockContainer
        onClick={onDetailsOpen}
        sx={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', marginTop: '20px' }}>
          {imgError ? (
            <StyledSmallNoImage style={{ marginRight: '16px' }} />
          ) : (
            <StyledNftImage
              src={
                selectedNFT?.nft.image
                  ? formatIpfsUrl(selectedNFT?.nft.image[1])
                  : ''
              }
              onError={() => setImgError(true)}
            />
          )}

          <div>
            <SubTitleStyled>
              {makeShortTextLine(selectedNFT?.nft.nft_collection_name)}
            </SubTitleStyled>
            <StyledNftTitle sx={{ wordBreak: 'break-all' }}>
              {makeShortTextLine(selectedNFT?.nft.name)}
            </StyledNftTitle>
            {forSale && <StyledNftForSale>{t('forSale')}</StyledNftForSale>}
          </div>
        </div>
        {forSale && (
          <div
            style={{
              display: 'flex',
              minHeight: '64px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                minHeight: '64px',
                justifyContent: 'space-between',
                marginTop: '32px',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '64px',
                  justifyContent: 'space-between',
                }}
              >
                <StyledNftTitlePrice>{t('lowestPrice')}</StyledNftTitlePrice>
                <StyledNftTitlePrice>{t('yourPrice')}</StyledNftTitlePrice>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '64px',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                {lowestPriceUSD && (
                  <StyledPrice>
                    {selectedNFT.nft.chain_id === chain?.id
                      ? `${lowestPriceUSD} USD`
                      : '-'}
                  </StyledPrice>
                )}
                <StyledPrice>
                  {!!icon ? (
                    <img
                      src={icon}
                      width='18px'
                      height='18px'
                      style={{ borderRadius: '50%', marginRight: '8px' }}
                      alt=''
                    />
                  ) : (
                    <DefaultTokenIcon />
                  )}

                  {price}
                </StyledPrice>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '64px',
                justifyContent: 'space-between',
              }}
            ></div>
          </div>
        )}
      </NftInfoBlockContainer>
    </>
  );
};

export default NftDetailsBlock;
