// import { Client, Transport, Chain, Account } from 'viem';
// import { Config, useAccount, useClient } from 'wagmi';
// import { providers } from 'ethers';
// import { getConnectorClient } from '@wagmi/core';
// import { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { dexSelector } from 'state/dex/selectors';
// import { config } from 'config/wagmiConfig';

// export const useSigner = () => {
//   const [signer, setSigner] = useState<providers.JsonRpcSigner>();
//   const { isConnected } = useAccount();
//   const client = useClient();
//   if (!isConnected) return;
//   const dex = useSelector(dexSelector);
//   function clientToSigner(client: Client<Transport, Chain, Account>) {
//     const { account, chain, transport } = client;
//     const network = {
//       chainId: chain.id,
//       name: chain.name,
//       ensAddress: chain.contracts?.ensRegistry?.address,
//     };
//     const provider = new providers.Web3Provider(transport, network);
//     const signer = provider.getSigner(account.address);
//     setSigner(signer);
//   }

//   /** Action to convert a Viem Client to an ethers.js Signer. */
//   async function getEthersSigner(
//     config: Config,
//     { chainId }: { chainId?: number } = {}
//   ) {
//     const client = await getConnectorClient(config, { chainId: chainId });
//     console.log('CLIENT', client);
//     return clientToSigner(client);
//   }
//   if (!signer) {
//     getEthersSigner(config);
//   }
//   return signer;
// };
import { providers } from 'ethers';
import { useMemo } from 'react';
import type { Account, Chain, Client, Transport } from 'viem';
import { Config, useConnectorClient } from 'wagmi';

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export function useSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}
