import React from 'react';

const HomeIconActive: React.FC = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_602_669)'>
        <rect
          x='8'
          y='6'
          width='32'
          height='32'
          rx='6'
          fill='#B032D6'
          shapeRendering='crispEdges'
        />
        <path
          d='M20 26.504V31.3329H18.1667C17.226 31.3329 16.3314 30.9805 15.6776 30.3645C15.0251 29.7499 14.6667 28.9252 14.6667 28.0742V20.8288C14.6685 19.9244 15.0738 19.0522 15.8004 18.4296C15.8004 18.4295 15.8004 18.4295 15.8004 18.4295L21.5171 13.5307L21.5179 13.53C22.1809 12.9601 23.0591 12.6476 23.9705 12.6676L23.9706 12.6676C24.8819 12.6874 25.7426 13.0378 26.3758 13.6351L26.3765 13.6357L32.3258 19.2329C32.3259 19.2329 32.326 19.233 32.326 19.2331C32.9781 19.8484 33.3355 20.674 33.3333 21.5254V21.5271V28.0746C33.3333 28.9256 32.9749 29.7503 32.3224 30.365C31.6686 30.9809 30.774 31.3333 29.8333 31.3333H29.0002H29L28 31.3331V26.504C28 25.124 27.2186 23.8697 25.9852 23.1988C24.7554 22.5298 23.2446 22.5298 22.0148 23.1988C20.7814 23.8697 20 25.124 20 26.504Z'
          fill='white'
          stroke='white'
          strokeWidth='1.33333'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_602_669'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411904 0 0 0 0 0.130625 0 0 0 0 0.870833 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_602_669'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_602_669'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomeIconActive;
