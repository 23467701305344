import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { BorderRadius, Color } from 'helpers/themeStyles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement='left'
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Color.DARK_PURPLE_OPACITY_LARGE,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.DARK_PURPLE_OPACITY_LARGE,
    borderRadius: BorderRadius.SEMI_NORMAL,
  },
}));

export const StyledMobileTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      placement='left'
      classes={{ popper: className }}
    />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Color.DARK_PURPLE,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.DARK_PURPLE,
    borderRadius: BorderRadius.SEMI_NORMAL,
    maxWidth: '220px',
  },
}));
