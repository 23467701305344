import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';

import NftCard from 'components/NftCard';
import { Color } from 'helpers/themeStyles';
import { Nft } from 'types/nft';
import { StyledTitle } from './styles';

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <ArrowBack sx={{ color: Color.WHITE }} />
    </div>
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <ArrowForward sx={{ color: Color.WHITE }} />
    </div>
  );
};

const NftMoreFromCollection = ({ nfts }: { nfts: Nft[] }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Stack
      px={isMobile ? '' : 2}
      py={4}
    >
      <StyledTitle>{t('moreFromThisCollection')}</StyledTitle>
      {nfts.length > 1 ? (
        <Slider {...settings}>
          {nfts.map(({ _id, image, name, nft_collection, chain_id }) => (
            <NftCard
              id={_id}
              key={_id}
              image={image}
              collection={nft_collection}
              name={name}
              chainId={chain_id}
              // width='280px'
            />
          ))}
        </Slider>
      ) : (
        <NftCard
          id={nfts[0]._id}
          key={nfts[0]._id}
          image={nfts[0].image}
          collection={nfts[0].nft_collection}
          name={nfts[0].name}
          chainId={nfts[0].chain_id}
          // width='280px'
        />
      )}
    </Stack>
  );
};

export default NftMoreFromCollection;
