import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Pagination } from '@mui/material';
import { format } from 'date-fns';

import { useAppSelector } from '@hooks/useAppSelector';
import { Color } from 'helpers/themeStyles';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import CompletedProjectsItem from './CompletedProjectsItem';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';
import launchpadSlice from 'state/launchpad/slice';
import { getCompletedProjectsThunk } from 'state/launchpad/thunks';
import { completedProjectsSelector } from 'state/launchpad/selectors';
import { StyledTitle } from '../CurrentProjects/styles';
import {
  StyledCompletedProjectsList,
  StyledCompletedProjectsWrapper,
} from './styles';

const CompletedProjects: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 431;
  const mobileCompletedProjects = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const completedProjects = useAppSelector(completedProjectsSelector);
  const limitQueryParam = isMobile ? 4 : 6;
  const showPagination =
    completedProjects &&
    ((isMobile && completedProjects.totalDocs > 4) ||
      (!isMobile && completedProjects.totalDocs > 6));

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getCompletedProjectsThunk({
        status: 'closed',
        limit: limitQueryParam,
        page: value,
      })
    );
    mobileCompletedProjects?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    dispatch(
      getCompletedProjectsThunk({
        status: 'closed',
        limit: limitQueryParam,
        page,
      })
    );
    return function cleanup() {
      dispatch(launchpadSlice.actions.resetUpcomingProjects());
    };
  }, []);

  return (
    <div ref={mobileCompletedProjects}>
      <StyledCompletedProjectsWrapper
        style={{ marginBottom: completedProjects?.docs.length ? '0' : '40px' }}
      >
        <StyledTitle variant='h3'>{t('lpCompletedProjectsTitle')}</StyledTitle>
        <StyledCompletedProjectsList>
          {!!completedProjects?.docs.length &&
            completedProjects?.docs.map((project) => (
              <CompletedProjectsItem
                key={project._id}
                id={project._id}
                logo={formatIpfsUrl(project.logo[1])}
                name={project.projectTitle}
                participants={project.tokenSaleDetails.participants}
                goal={project.poolInformation.toRise}
                totalRaise={project.poolInformation.toRiseProgress}
                tokenPrice={project.tokenSaleDetails.price}
                endedIn={format(
                  new Date(project.updatedAt),
                  'dd.MM.yyyy HH:mm'
                )}
              />
            ))}
        </StyledCompletedProjectsList>
        {showPagination && (
          <Pagination
            count={completedProjects?.totalPages ?? 1}
            page={page}
            onChange={handleChange}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            sx={{
              '& .MuiPagination-ul': {
                justifyContent: 'center',
                marginBottom: '24px',
              },
              '& .MuiButtonBase-root': {
                color: Color.WHITE,
              },
            }}
          />
        )}
      </StyledCompletedProjectsWrapper>
    </div>
  );
};

export default CompletedProjects;
