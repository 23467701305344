import {
  DialogContent,
  List,
  Avatar,
  IconButton,
  ThemeProvider,
  InputAdornment,
  Grid,
  Stack,
  useTheme,
  useMediaQuery,
  LinearProgress,
  Box,
  CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { DEX, DEXToken, TokenCache, utils } from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';
import React, { useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSigner } from '@hooks/useSigner';
import { dexSelector } from 'state/dex/selectors';
import {
  firstTokenSelector,
  secondTokenSelector,
  tokenListSelector,
  topTokensSelector,
} from 'state/swap/selectors';
import {
  setFirstToken,
  setPopularTokens,
  setSecondToken,
  setTokenList,
} from 'state/swap/slice';
import {
  themes,
  StyledButton,
  StyledCommonTokens,
  StyledCommonTokensContainer,
  StyledDialog,
  StyledFab,
  StyledInput,
  StyledSearch,
  StyledTitle,
  StyledTokenSelectorIcon,
} from './styles';
import useDebounce from '@hooks/useDebounce';
import { ExpandMore } from 'shared/ExpandMoreAndLess';
import { Color } from 'helpers/themeStyles';
import TokenItem from './TokenItem';
import {
  setFirstLiquidityToken,
  setIsClearAll,
  setSecondLiquidityToken,
} from 'state/liquidity/slice';
import {
  secondLiquidityTokenSelector,
  firstLiquidityTokenSelector,
  isClearAllSelector,
} from 'state/liquidity/selectors';
import { fetchData } from 'config/api';
import { useBalances } from '@hooks/useBalances';
import { useAccount } from 'wagmi';
import { isChainSupproted } from 'helpers/isChainSupproted';

type ControlProps = {
  firstToken?: boolean;
  defaultToken?: string;
  width?: string;
  fromLiquidity?: boolean;
  fromV2?: boolean;
  height?: string;
  border?: string;
  borderRadius?: string;
  fromV3?: boolean;
  fetchData?: boolean;
};

const TokenSelector = (props: ControlProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isConnected } = useAccount();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const selectedFirstLiquidityToken = useSelector(firstLiquidityTokenSelector);
  const selectedSecondLiquidityToken = useSelector(
    secondLiquidityTokenSelector
  );
  const [isOpen, setIsOpen] = useState(false);
  const [tokenName, setTokenName] = useState<string | undefined>(
    props.fromV2 && props.firstToken
      ? selectedFirstLiquidityToken?.symbol
      : props.fromV2
      ? selectedSecondLiquidityToken?.symbol
      : ''
  );
  const [tokenIcon, setTokenIcon] = useState(
    props.fromV2 && props.firstToken
      ? selectedFirstLiquidityToken?.icon
      : props.fromV2
      ? selectedSecondLiquidityToken?.icon
      : ''
  );
  const [tokenAddress, setTokenAddress] = useState('');
  const [searchParams, setSearchParams] = useState('');
  const [imgError, setImgError] = useState(false);
  const debouncedValue = useDebounce(searchParams, 200);
  const { t } = useTranslation();
  const tokenList = useSelector(tokenListSelector);
  const popularTokens = useSelector(topTokensSelector);
  const dex = useSelector(dexSelector);
  const { chain } = useAccount();
  const selectedFirstToken = useSelector(firstTokenSelector);
  const selectedSecondToken = useSelector(secondTokenSelector);
  const isClearAll = useSelector(isClearAllSelector);
  const signer = useSigner();
  const { results, isLoading } = useBalances({
    fetchData: props.fetchData!,
    isOpen: isOpen,
  });
  async function getTopTokens() {
    if (!isConnected || !dex) return;
    if (chain && isChainSupproted(chain?.id!) && dex) {
      const topTokens = await dex.getTopTokens(chain?.id ?? 1);

      dispatch(setPopularTokens([...topTokens]));
    }
    if (!chain) {
      const topTokens = await dex.getTopTokens(1);

      dispatch(setPopularTokens([...topTokens]));
    }
  }
  // Query can be ommited @Danylo
  async function getLessPopularTokens(debouncedValue?: string) {
    const queryParam =
      debouncedValue && debouncedValue.length === 42 ? 'address' : 'name';
    if (signer && debouncedValue && debouncedValue.length === 42) {
      const token = await DEXToken.fromBlockchain(debouncedValue, signer!);
      dispatch(setTokenList([token]));
    } else {
      const { data } = await fetchData(
        `/tokens?${queryParam}=${debouncedValue}&chainId=${
          chain?.id ?? 1
        }&limit=80`
      );
      TokenCache.clear();
      TokenCache.addTokens(data.docs);
      const tokens = TokenCache.iter();
      dispatch(setTokenList([...tokens]));
    }
  }
  useEffect(() => {
    // we need this in swap when change token's places
    if (!props.fromLiquidity) {
      setTokenName(
        props.firstToken
          ? selectedFirstToken?.symbol
            ? selectedFirstToken?.symbol
            : ''
          : selectedSecondToken?.symbol
          ? selectedSecondToken?.symbol
          : ''
      );
      setTokenIcon(
        props.firstToken
          ? selectedFirstToken?.icon
            ? selectedFirstToken?.icon
            : ''
          : selectedSecondToken?.icon
          ? selectedSecondToken?.icon
          : ''
      );
    }
  }, [selectedFirstToken, selectedSecondToken]);

  useEffect(() => {
    getTopTokens();
    // For complete ux, prefetch token list @Danylo
    props.defaultToken && setTokenName(props.defaultToken);
  }, [isOpen]);

  useEffect(() => {
    getLessPopularTokens(debouncedValue);
  }, [debouncedValue]);

  const setToken = (token: DEXToken) => {
    if (!props.fromLiquidity) {
      if (props.firstToken) {
        dispatch(setFirstToken(token));
      } else {
        dispatch(setSecondToken(token));
      }
    } else {
      if (props.firstToken) {
        dispatch(setFirstLiquidityToken(token));
      } else {
        dispatch(setSecondLiquidityToken(token));
      }
    }
    setImgError(false);
    setTokenName(token.symbol!);
    setTokenIcon(token.icon);
    setTokenAddress(token.address);
    setSearchParams('');
    setIsOpen(false);
  };

  useEffect(() => {
    // reset image error after clicking on Exchange button
    if (!props.fromLiquidity) {
      setImgError(false);
    }
  }, [selectedFirstToken]);

  useEffect(() => {
    return () => {
      if (!props.fromV2) {
        dispatch(setFirstLiquidityToken(DEXToken.default()));
        dispatch(setSecondLiquidityToken(DEXToken.default()));
      }
    };
  }, []);
  useEffect(() => {
    if (isClearAll && props.fromLiquidity) {
      setTokenName('');
    }
    return () => {
      dispatch(setIsClearAll(false));
    };
  }, [isClearAll]);
  return (
    <>
      <StyledButton
        onClick={() => setIsOpen(true)}
        variant='contained'
        style={{
          width:
            props.width && !isMobile
              ? props.width
              : props.fromV3 && isMobile
              ? '100%'
              : 'auto',
          height: props.height ? props.height : 'auto',
          border: props.border ? props.border : 'none',
          borderRadius: props.borderRadius ? props.borderRadius : 'none',
        }}
        sx={{
          backgroundColor:
            !tokenName && !props.fromV2
              ? Color.DARK_PURPLE_OPACITY
              : props.fromLiquidity || !props.fromV2
              ? Color.BACKGROUND
              : 'transparent',
          padding: '0 12px',
          '&:hover': {
            backgroundColor: !tokenName
              ? Color.DARK_PURPLE_OPACITY
              : 'transparent',
            boxShadow: 'none',
          },
          '&.MuiButton-root:first-child': {
            marginBottom: isMobile ? '16px' : 0,
          },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          sx={{ mr: 1 }}
        >
          {tokenName && tokenName !== '!' ? (
            imgError ? (
              <StyledTokenSelectorIcon
                src={
                  chain?.id === 1
                    ? './images/default_eth_icon.webp'
                    : './images/default_bnb_icon.webp'
                }
              />
            ) : (
              <StyledTokenSelectorIcon
                onError={() => setImgError(true)}
                src={tokenIcon}
              />
            )
          ) : null}
          {tokenName === '!' || !tokenName ? t('selectToken') : tokenName}
        </Stack>
        <ExpandMore />
      </StyledButton>
      <ThemeProvider theme={themes}>
        <StyledDialog
          open={isOpen}
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
          fullWidth
          maxWidth='xs'
          onClose={() => {
            setIsOpen(false);
            setSearchParams('');
          }}
        >
          <StyledTitle>
            {t('selectToken')}
            <IconButton
              aria-label='close'
              onClick={() => {
                setIsOpen(false);
                setSearchParams('');
              }}
              sx={{
                position: 'absolute',
                right: 25,
                top: 20,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </StyledTitle>
          <StyledInput
            placeholder={t('searchName')!}
            size='small'
            onChange={(e) => {
              setSearchParams(
                e.target.value.length < 6
                  ? e.target.value.toUpperCase()
                  : e.target.value.toLowerCase()
              );
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <StyledSearch />
                </InputAdornment>
              ),
            }}
            variant='outlined'
          />
          <StyledCommonTokens sx={{ paddingBottom: isMobile ? 0 : undefined }}>
            {t('commonTokens')}
          </StyledCommonTokens>
          <StyledCommonTokensContainer
            container
            spacing={2}
            justifyContent='center'
            sx={{ paddingRight: isMobile ? '28px' : undefined }}
          >
            {popularTokens?.length &&
              popularTokens.map((token) => {
                return (
                  <Grid
                    item
                    key={`${token.address}::${token.chainId}`}
                    sx={{
                      '&.MuiGrid-root:first-child': {
                        paddingLeft: 0,
                      },
                    }}
                  >
                    <StyledFab
                      variant='extended'
                      size='medium'
                      disabled={
                        props.fromLiquidity &&
                        selectedFirstLiquidityToken.symbol !== '!' &&
                        selectedSecondLiquidityToken.symbol !== '!' &&
                        (token.wrapped.address ===
                          selectedFirstLiquidityToken.wrapped.address ||
                          token.wrapped.address ===
                            selectedSecondLiquidityToken.wrapped.address)
                      }
                      onClick={() => {
                        token.amount = utils.parseUnits('0', token.decimals);
                        setToken(token);
                      }}
                    >
                      <Avatar
                        sx={{ mr: 1, width: '24px', height: '24px' }}
                        src={token.icon}
                      />
                      {token.symbol}
                    </StyledFab>
                  </Grid>
                );
              })}
          </StyledCommonTokensContainer>

          <DialogContent sx={{ padding: isMobile ? '0 4px' : '' }}>
            <List>
              {tokenList.length &&
                tokenList // Already sorted by volumeUSD (popularity) @Danylo
                  .map((token) => {
                    return (
                      <TokenItem
                        key={`${token.address}::${token.chainId}::${token.isNative}`}
                        token={token}
                        tokenName={tokenName}
                        tokenAddress={tokenAddress}
                        setToken={setToken}
                        fromLiquidity={props.fromLiquidity}
                        balance={results && results[token.address]}
                        isLoading={isLoading}
                      />
                    );
                  })}
            </List>
          </DialogContent>
        </StyledDialog>
      </ThemeProvider>
    </>
  );
};

export default TokenSelector;
