const LiquidityIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.5 12H14.5H8.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M20.5 8H14.5H8.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M20.5 16H14.5H8.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M5.5 12H5H4.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M5.5 8H5H4.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M5.5 16H5H4.5'
        stroke='#CECECE'
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  );
};

export default LiquidityIcon;
