import { createSlice } from '@reduxjs/toolkit';
import { SelectedNft } from 'types/nft';
import { getSelectedNftThunk } from './thunks';

interface IState {
  selectedNft: SelectedNft | null;
}

const initialState: IState = {
  selectedNft: null,
};

const nftSlice = createSlice({
  name: 'nft',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedNft: (state) => {
      state.selectedNft = initialState.selectedNft;
    },
    setSelectedNft: (state, action) => {
      state.selectedNft = action.payload.data ?? null;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(getSelectedNftThunk.fulfilled, (state, { payload }) => {
      state.selectedNft = payload;
    }),
});

export default nftSlice;
