const HomeIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='32'
        height='32'
        rx='6'
        fill='white'
        fillOpacity='0.1'
      />
      <path
        d='M21 26H21.8333C22.9384 26 23.9981 25.5864 24.7796 24.8502C25.561 24.1141 26 23.1157 26 22.0746V15.5271C26.0026 14.4851 25.5647 13.4851 24.7833 12.7479L18.8333 7.1501C18.0762 6.436 17.0561 6.02435 15.9851 6.00106C14.914 5.97758 13.8746 6.34418 13.0833 7.02444L7.36662 11.9233C6.49814 12.6676 6.00205 13.7218 6 14.8281V22.0742C6 23.1153 6.43899 24.1136 7.22043 24.8498C8.00187 25.586 9.06157 25.9995 10.1667 25.9995H12.6667V20.504C12.6667 19.3821 13.3021 18.3454 14.3333 17.7845C15.3646 17.2235 16.6354 17.2235 17.6667 17.7845C18.6979 18.3454 19.3333 19.3821 19.3333 20.504V25.9995L21 26Z'
        fill='white'
      />
    </svg>
  );
};

export default HomeIcon;
