import { Box, Button, Dialog, Stack, styled, Typography } from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    width: '412px',
    borderRadius: BorderRadius.SMALL,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#161616, #161616), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  },
}));

export const StyledDialogHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'transparent',
  color: Color.WHITE,
  borderBottom: Border.ACCENT_BORDER,
  padding: '8px 16px',
}));

export const StyledDialogContent = styled(Stack)(() => ({
  padding: '16px',
}));

export const StylesDialogWrapper = styled('div')(() => ({
  padding: '22px 16px',
  borderRadius: BorderRadius.NORMAL,
  background: Color.BACKGROUND,
  backgroundImage: `linear-gradient(#161616, #161616), ${Color.BUTTON_GRADIENT_ORANGE_TO_PINK}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  marginBottom: '12px',
}));

export const StyledBackButton = styled(Button)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  textTransform: 'none',
  fontWeight: 700,
  color: Color.WHITE,
}));

export const StyledStepNumber = styled(Box)(() => ({
  width: 27,
  height: 27,
  borderRadius: BorderRadius.HUGE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 600,
}));

export const StyledStepTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  textTransform: 'uppercase',
  color: Color.WHITE,
}));

export const StyledText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 400,
  color: Color.WHITE,
  marginBottom: 16,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  border: 'none',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '16px 0',
  color: Color.WHITE,
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },
  '&.MuiButton-root:hover': {
    border: 'none',
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));
