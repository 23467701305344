import { FastField, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Add } from '@mui/icons-material';

import { fileTypes, fileTypesInLaunchpad } from 'helpers/fileTypes';
import { BorderRadius, Color, TextSize } from 'helpers/themeStyles';
import uploadImage from 'shared/uploadImageLaunchpad.png';
import {
  loadingSelector,
  newProjectIdSelector,
  projectDetailsSelector,
} from 'state/launchpad/selectors';
import {
  getProjectByIdThunk,
  updateProjectThunk,
} from 'state/launchpad/thunks';
import Editor from '../MdEditor/MdEditor';
import {
  StyledCreateProjectWrapper,
  StyledInput,
  StyledLabel,
  StyledNextButton,
  StyledTitle,
} from '../CreateProjectFirstStep/styles';
import { StyledBackButton, btnAdditionalStyles } from '../CreateProject/styles';
import {
  StyledAddButton,
  StyledDescription,
  StyledError,
  TeamCardContainer,
  UploadArea,
  UploadAreaContainer,
  UploadImage,
} from './styles';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import launchpadSlice from 'state/launchpad/slice';
import { initialValues } from 'components/Launchpad/CreateProjectSecondStep/config';
import { InitialValuesType } from 'components/Launchpad/CreateProjectSecondStep/FormValuesInterface';
import Loader from 'shared/Loader';

interface ImageErrorState {
  typeError: boolean;
  sizeError: boolean;
}

const CreateProjectSecondStep = ({
  activeStep,
  totalSteps,
  handleNext,
  setOpenClearFormPopup,
}: {
  activeStep?: number;
  totalSteps?: () => number;
  handleNext?: () => void;
  setOpenClearFormPopup: (newState: boolean) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const projectIdRedux = useSelector(newProjectIdSelector);
  const currentProject = useSelector(projectDetailsSelector);
  const loading = useSelector(loadingSelector);
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [logoImage, setLogoImage] = useState<File>();
  const [logoImageUrl, setLogoImageUrl] = useState<string>('');
  const [typeLogoError, setTypeLogoError] = useState(false);
  const [sizeLogoError, setSizeLogoError] = useState(false);
  const [productImageUrl, setProductImageUrl] = useState('');
  const [problemImageUrl, setProblemImageUrl] = useState('');
  const [tokenImageUrl, setTokenImageUrl] = useState('');
  const [businessImageUrl, setBusinessImageUrl] = useState('');
  const [investorsImageUrl, setInvestorsImageUrl] = useState('');
  const [roadmapImageUrl, setRoadmapImageUrl] = useState('');
  const [teamInfo, setTeamInfo] = useState(
    currentProject?.teamMembers.length
      ? currentProject?.teamMembers
      : [{ name: '', about: '', position: '', linkedIn: '' }]
  );
  const [navigationImageErrors, setNavigationImageErrors] = useState<
    ImageErrorState[]
  >([]);

  const updateNavigationImageErrors = (
    index: number,
    errorType: keyof ImageErrorState,
    value: boolean
  ) => {
    setNavigationImageErrors((prevErrorStates) => {
      const newErrorStates = [...prevErrorStates];
      newErrorStates[index] = {
        ...newErrorStates[index],
        [errorType]: value,
      };
      return newErrorStates;
    });
  };

  const resetNavigationImageErrors = (index: number) => {
    setNavigationImageErrors((prevErrorStates) => {
      const newErrorStates = [...prevErrorStates];
      newErrorStates[index] = {
        typeError: false,
        sizeError: false,
      };
      return newErrorStates;
    });
  };

  useEffect(() => {
    if (projectIdRedux && !loading) {
      dispatch(getProjectByIdThunk({ id: projectIdRedux }));
    }
  }, [loading]);
  useEffect(() => {
    dispatch(launchpadSlice.actions.resetProjectDetails());
  }, []);

  const correctImageLink = (title: string) => {
    if (title.toLowerCase().includes('problem') && problemImageUrl) {
      return problemImageUrl;
    } else if (title.toLowerCase().includes('product') && productImageUrl) {
      return productImageUrl;
    } else if (title.toLowerCase().includes('token') && tokenImageUrl) {
      return tokenImageUrl;
    } else if (title.toLowerCase().includes('business') && businessImageUrl) {
      return businessImageUrl;
    } else if (title.toLowerCase().includes('investors') && investorsImageUrl) {
      return investorsImageUrl;
    } else if (title.toLowerCase().includes('roadmap') && roadmapImageUrl) {
      return roadmapImageUrl;
    } else {
      return undefined;
    }
  };

  useMemo(() => {
    if (logoImage) {
      setLogoImageUrl(URL.createObjectURL(logoImage));
    }
  }, [logoImage]);

  useMemo(() => {
    if (image) {
      setImageUrl(URL.createObjectURL(image));
    }
  }, [image]);
  const compressedFile = async (file: File) => {
    const imageBitmap = await createImageBitmap(file);
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx!.drawImage(imageBitmap, 0, 0);

    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, file.type, 0.5)
    );
    const compressedFile = new File([blob as BlobPart], file.name, {
      type: (blob as File).type,
    });
    return compressedFile;
  };
  return (
    <>
      {loading ? (
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          minHeight={isMobile ? 250 : 450}
        >
          <Loader />
        </Stack>
      ) : (
        <Formik
          initialValues={initialValues(currentProject!)}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
          }: FormikProps<InitialValuesType>) => (
            <StyledCreateProjectWrapper>
              <Form onSubmit={handleSubmit}>
                <StyledTitle>{t('projectOverview')}</StyledTitle>
                <StyledDescription
                  sx={{
                    fontSize: isMobile ? TextSize.TABLE_SMALL : TextSize.SMALL,
                    mb: 2,
                  }}
                >
                  <span style={{ color: Color.PINK }}>*</span>{' '}
                  {t('allFieldsBelowAreRequired')}
                </StyledDescription>
                <StyledLabel style={{ marginBottom: '16px' }}>
                  {t('logoImage')} <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <Stack
                  columnGap='24px'
                  flexDirection={isMobile ? 'column' : 'row'}
                  alignItems='center'
                  marginBottom='16px'
                >
                  <UploadAreaContainer
                    style={{
                      width: isMobile ? '' : 'auto',
                    }}
                  >
                    <FileUploader
                      handleChange={async (file: File) => {
                        setTypeLogoError(false);
                        setSizeLogoError(false);
                        const canvasFile = await compressedFile(file);
                        setFieldValue('logo', canvasFile);
                        setLogoImage(canvasFile);
                      }}
                      name='file'
                      types={fileTypesInLaunchpad}
                      onTypeError={() => {
                        setTypeLogoError(true);
                      }}
                      onSizeError={() => {
                        setSizeLogoError(true);
                      }}
                      maxSize={10}
                    >
                      {logoImageUrl || currentProject?.logo.length ? (
                        <img
                          src={
                            logoImageUrl ||
                            formatIpfsUrl(currentProject?.logo[1]!)
                          }
                          width={'100%'}
                          height={'300'}
                          alt=''
                          style={{
                            borderRadius: BorderRadius.NORMAL,
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <UploadArea
                          style={{
                            width: isMobile ? '' : '120px',
                            minHeight: isMobile ? '' : '120px',
                          }}
                        >
                          <UploadImage
                            src={uploadImage}
                            style={{
                              marginBottom: '0px',
                              width: '30px',
                              height: '33px',
                            }}
                          />
                          {isMobile && (
                            <div>
                              <StyledDescription
                                style={{ textAlign: 'center' }}
                              >
                                {t('clickToUploadOrDragAndDrop')}
                              </StyledDescription>
                              <StyledDescription>
                                {t('supportedFilesInLaunchpad')}
                              </StyledDescription>
                            </div>
                          )}
                        </UploadArea>
                      )}
                    </FileUploader>
                    {typeLogoError && (
                      <StyledError>{t('fileTypeError')}</StyledError>
                    )}
                    {sizeLogoError && (
                      <StyledError>{t('fileSizeError')}</StyledError>
                    )}
                  </UploadAreaContainer>
                  {!isMobile && (
                    <div>
                      <StyledDescription>
                        {t('clickToUploadOrDragAndDrop')}
                      </StyledDescription>
                      <StyledDescription>
                        {t('supportedFilesInLaunchpad')}
                      </StyledDescription>
                    </div>
                  )}
                </Stack>
                <StyledLabel style={{ marginBottom: '16px' }}>
                  {t('bannerImage')}{' '}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <Stack marginBottom='16px'>
                  <UploadAreaContainer>
                    <FileUploader
                      handleChange={async (file: File) => {
                        setTypeError(false);
                        setSizeError(false);
                        const canvasFile = await compressedFile(file);

                        setFieldValue('heroPicture', canvasFile);
                        setImage(canvasFile);
                      }}
                      name='file'
                      types={fileTypesInLaunchpad}
                      onTypeError={() => {
                        setTypeError(true);
                      }}
                      onSizeError={() => {
                        setSizeError(true);
                      }}
                      maxSize={10}
                    >
                      {imageUrl || currentProject?.heroPicture.length ? (
                        <img
                          src={
                            imageUrl ||
                            formatIpfsUrl(currentProject?.heroPicture[1]!)
                          }
                          width={'100%'}
                          height={'300'}
                          alt=''
                          style={{
                            borderRadius: BorderRadius.NORMAL,
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <UploadArea>
                          <UploadImage
                            src={uploadImage}
                            style={{
                              marginBottom: '10px',
                              width: '30px',
                              height: '33px',
                            }}
                          />
                          <div>
                            <StyledDescription style={{ textAlign: 'center' }}>
                              {t('clickToUploadOrDragAndDrop')}
                            </StyledDescription>
                            <StyledDescription>
                              {t('supportedFilesInLaunchpad')}
                            </StyledDescription>
                          </div>
                        </UploadArea>
                      )}
                    </FileUploader>
                    {typeError && (
                      <StyledError>{t('fileTypeError')}</StyledError>
                    )}
                    {sizeError && (
                      <StyledError>{t('fileSizeError')}</StyledError>
                    )}
                  </UploadAreaContainer>
                </Stack>
                {values.navigation.map((item, idx) => {
                  const itemImageErrors = navigationImageErrors[idx] || {};
                  return (
                    <Stack
                      flexDirection='column'
                      marginBottom='16px'
                    >
                      <StyledLabel style={{ marginBottom: '16px' }}>
                        {t(item.subtitle.toLowerCase())}{' '}
                        {item.subtitle.toLowerCase() !==
                          'descriptionofbudgetdistribution' &&
                          item.subtitle.toLowerCase() !== 'businessmodel' &&
                          item.subtitle.toLowerCase() !== 'investors' && (
                            <span style={{ color: Color.PINK }}>*</span>
                          )}
                      </StyledLabel>
                      <FastField name={`navigation.${[idx]}.details`}>
                        {() => (
                          <Editor
                            handleEditorChange={({ text }) =>
                              setFieldValue(`navigation.${[idx]}.details`, text)
                            }
                            height='200px'
                            width='100%'
                            value={item.details}
                          />
                        )}
                      </FastField>
                      {item.subtitle.toLowerCase() !==
                        'descriptionofbudgetdistribution' && (
                        <UploadAreaContainer>
                          <FileUploader
                            name='file'
                            types={fileTypes}
                            maxSize={10}
                            handleChange={async (file: File) => {
                              const canvasFile = await compressedFile(file);
                              resetNavigationImageErrors(idx);
                              setFieldValue(
                                `${item.subtitle.toLowerCase()}Image`,
                                canvasFile
                              );
                              if (
                                item.subtitle.toLowerCase().includes('problem')
                              ) {
                                setProblemImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              } else if (
                                item.subtitle.toLowerCase().includes('product')
                              ) {
                                setProductImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              } else if (
                                item.subtitle.toLowerCase().includes('token')
                              ) {
                                setTokenImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              } else if (
                                item.subtitle.toLowerCase().includes('business')
                              ) {
                                setBusinessImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              } else if (
                                item.subtitle
                                  .toLowerCase()
                                  .includes('investors')
                              ) {
                                setInvestorsImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              } else if (
                                item.subtitle.toLowerCase().includes('roadmap')
                              ) {
                                setRoadmapImageUrl(
                                  URL.createObjectURL(canvasFile)
                                );
                              }
                            }}
                            onTypeError={() => {
                              updateNavigationImageErrors(
                                idx,
                                'typeError',
                                true
                              );
                            }}
                            onSizeError={() => {
                              updateNavigationImageErrors(
                                idx,
                                'sizeError',
                                true
                              );
                            }}
                          >
                            {currentProject?.navigation[idx]?.picture.length ||
                            correctImageLink(item.subtitle) ? (
                              <img
                                src={
                                  correctImageLink(item.subtitle) ||
                                  formatIpfsUrl(
                                    currentProject?.navigation[idx]?.picture[1]!
                                  )
                                }
                                width={'100%'}
                                height={'300'}
                                alt=''
                                style={{
                                  borderRadius: BorderRadius.NORMAL,
                                  objectFit: 'cover',
                                }}
                              />
                            ) : (
                              <UploadArea>
                                <UploadImage
                                  src={uploadImage}
                                  style={{
                                    marginBottom: '10px',
                                    width: '30px',
                                    height: '33px',
                                  }}
                                />
                                <div>
                                  <StyledDescription
                                    style={{ textAlign: 'center' }}
                                  >
                                    {t('clickToUploadOrDragAndDrop')}
                                  </StyledDescription>
                                  <StyledDescription>
                                    {t('supportedFilesInLaunchpad')}
                                  </StyledDescription>
                                </div>
                              </UploadArea>
                            )}
                          </FileUploader>
                          {itemImageErrors.typeError && (
                            <StyledError>{t('fileTypeError')}</StyledError>
                          )}
                          {itemImageErrors.sizeError && (
                            <StyledError>{t('fileSizeError')}</StyledError>
                          )}
                        </UploadAreaContainer>
                      )}
                    </Stack>
                  );
                })}
                <StyledLabel>{t('Team')}</StyledLabel>
                {teamInfo.map((item, index) => (
                  <TeamCardContainer>
                    <Stack
                      flexDirection={isMobile ? 'column' : 'row'}
                      gap='16px'
                      width='100%'
                    >
                      <Stack
                        flexDirection='column'
                        width='100%'
                      >
                        <StyledLabel>{t('memberName')}</StyledLabel>
                        <StyledInput
                          name={`teamMembers[${index}].name`}
                          value={values.teamMembers[index]?.name}
                          onChange={(e) => {
                            setFieldValue(
                              `teamMembers[${index}].name`,
                              e.target.value
                            );
                            const values = [...teamInfo];
                            values[index] = {
                              ...values[index],
                              name: e.target.value,
                            };
                            setTeamInfo(values);
                          }}
                          placeholder='Member name'
                          size='small'
                          required
                        />
                      </Stack>
                      <Stack
                        flexDirection='column'
                        width='100%'
                      >
                        <StyledLabel>{t('memberPosition')}</StyledLabel>
                        <StyledInput
                          name={`teamMembers[${index}].position`}
                          value={values.teamMembers[index]?.position}
                          onChange={(e) => {
                            setFieldValue(
                              `teamMembers[${index}].position`,
                              e.target.value
                            );
                            const values = [...teamInfo];
                            values[index] = {
                              ...values[index],
                              position: e.target.value,
                            };
                            setTeamInfo(values);
                          }}
                          placeholder='Member position'
                          size='small'
                          required
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      flexDirection='column'
                      width='100%'
                    >
                      <StyledLabel>{t('about')}</StyledLabel>
                      <StyledInput
                        name={`teamMembers[${index}].about`}
                        value={values.teamMembers[index]?.about}
                        onChange={(e) => {
                          setFieldValue(
                            `teamMembers[${index}].about`,
                            e.target.value
                          );
                          const values = [...teamInfo];
                          values[index] = {
                            ...values[index],
                            about: e.target.value,
                          };
                          setTeamInfo(values);
                        }}
                        placeholder='Member details'
                        size='small'
                        required
                      />
                    </Stack>
                    <Stack
                      flexDirection='column'
                      width='100%'
                    >
                      <StyledLabel>{t('linkedInURL')}</StyledLabel>
                      <StyledInput
                        name={`teamMembers[${index}].linkedIn`}
                        value={values.teamMembers[index]?.linkedIn}
                        onChange={(e) => {
                          setFieldValue(
                            `teamMembers[${index}].linkedIn`,
                            e.target.value
                          );
                          const values = [...teamInfo];
                          values[index] = {
                            ...values[index],
                            linkedIn: e.target.value,
                          };
                          setTeamInfo(values);
                        }}
                        placeholder='LinkedIn URL'
                        size='small'
                        required
                      />
                    </Stack>
                  </TeamCardContainer>
                ))}
                <StyledAddButton
                  fullWidth={isMobile ? true : false}
                  onClick={() => {
                    // we'll add fields for a new team member only if the user has filled all fields for the previous member
                    const disabledAddMemberBtn =
                      !teamInfo[teamInfo.length - 1].about ||
                      !teamInfo[teamInfo.length - 1].name ||
                      !teamInfo[teamInfo.length - 1].position;
                    if (!disabledAddMemberBtn) {
                      const values = [...teamInfo];
                      values.push({
                        name: '',
                        about: '',
                        position: '',
                        linkedIn: '',
                      });
                      setTeamInfo(values);
                      setFieldValue('teamMembers', values);
                    }
                  }}
                >
                  <Add sx={{ color: Color.WHITE, marginRight: '10px' }} />
                  {t('addMember')}
                </StyledAddButton>
                <Stack
                  justifyContent='space-between'
                  flexDirection='row'
                  mt={8}
                >
                  {activeStep !== 0 && (
                    <StyledBackButton
                      disabled={activeStep === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenClearFormPopup(true);
                      }}
                      sx={{ mr: 1, ...btnAdditionalStyles }}
                    >
                      {t('back')}
                    </StyledBackButton>
                  )}
                  {totalSteps && activeStep !== totalSteps() - 1 && (
                    <StyledNextButton
                      onClick={() => {
                        const form = new FormData();
                        form.append('logo', values.logo!);
                        form.append('productImage', values.productImage!);
                        form.append('problemImage', values.problemImage!);
                        form.append('tokenomicsImage', values.tokenomicsImage!);
                        form.append('roadmapImage', values.roadmapImage!);
                        form.append(
                          'businessmodelImage',
                          values.businessmodelImage!
                        );
                        form.append('investorsImage', values.investorsImage!);
                        form.append('heroPicture', values.heroPicture!);
                        form.append(
                          'navigation',
                          JSON.stringify(values.navigation)
                        );
                        form.append(
                          'teamMembers',
                          JSON.stringify(values.teamMembers)
                        );
                        form.append('status', values.status);

                        dispatch(
                          updateProjectThunk({
                            values: form,
                            id: projectIdRedux,
                          })
                        );
                        handleNext && handleNext();
                      }}
                      sx={{ mr: 1, ...btnAdditionalStyles }}
                      disabled={
                        !values.heroPicture ||
                        !values.logo ||
                        (!values.navigation[0].details &&
                          !values.productImage) ||
                        (!values.navigation[1].details &&
                          !values.problemImage) ||
                        (!values.navigation[2].details &&
                          !values.tokenomicsImage) ||
                        (!values.navigation[3].details &&
                          !values.roadmapImage) ||
                        !projectIdRedux
                      }
                    >
                      {t('saveAndContinue')}
                    </StyledNextButton>
                  )}
                </Stack>
              </Form>
            </StyledCreateProjectWrapper>
          )}
        </Formik>
      )}
    </>
  );
};
export default CreateProjectSecondStep;
