import {
  Box,
  Button,
  Paper,
  Snackbar,
  styled,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const UserButton = styled(Button)({
  background: Color.DARK_PURPLE_OPACITY,
  textTransform: 'capitalize',
  color: Color.WHITE,
  justifyContent: 'space-between',
  height: '40px',
  borderRadius: BorderRadius.HUGE,
  '&.MuiButtonBase-root': {
    justifyContent: 'center',
    gap: '6px',
  },
});

export const AppBarButton = styled(Button)({
  padding: '12px 24px',
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  borderRadius: BorderRadius.SEMI_SMALL,
  border: 'none',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  minWidth: '159px',
});

export const AppBarText = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  margin: 0,
});

export const ErrorText = styled(AppBarText)({
  paddingRight: '24px',
  paddingTop: '2px',
  fontWeight: 700,
  color: Color.RED,
});

export const AvatarContainer = styled(Box)({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
  background: Color.PURPLE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight': {
    top: '-4px',
    right: '67px',
  },
  '& .MuiPaper-root.MuiAlert-root': {
    padding: '5px',
    background: 'none',
  },
  '& .MuiAlert-message': {
    padding: '0',
    fontSize: TextSize.SUPER_SMALL,
    fontFamily: FontFamily.INTER,
    color: Color.TEXT_OPACITY_LIGHT,
  },
  '& .MuiAlert-icon': {
    display: 'none',
  },
  '& .MuiAlert-action': {
    display: 'none',
  },
});

export const StyledNetworkErrorSnackbar = styled(Snackbar)({
  width: '300px',
  zIndex: -1,
  '&.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight': {
    top: '80px',
    right: '15px',
  },
  '& .MuiPaper-root.MuiAlert-root': {
    padding: '5px',
    background: 'none',
  },
});

export const StyledNetworkErrorTitle = styled('div')({
  fontSize: TextSize.NORMAL,
  fontFamily: FontFamily.INTER,
  color: Color.TEXT_OPACITY_LIGHT,
  fontWeight: 700,
});

export const StyledNetworkErrorSubtitle = styled('div')({
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
  color: Color.TEXT_OPACITY_LIGHT,
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: '305px',
  padding: '20px',
  boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    minWidth: '280px',
  },
}));
