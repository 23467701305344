export const makeShortTextLine = (
  name: string | undefined
): string | undefined => {
  if (
    name
      ?.split(' ')
      .map((i) => i.length > 14)
      .at(0)
  ) {
    return name?.slice(0, 10) + '...';
  } else {
    return name;
  }
};
