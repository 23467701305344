import { ReactNode, useEffect, useState } from 'react';
import { Box, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageRoutes } from 'config/routes';
import { Color } from 'helpers/themeStyles';
import { CustomNftTab } from './CustomNftTab';
import { StyledNftMarketplace } from './styles';

const NftMarketplace = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);
  const currentLocation = window.location.href;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const regexp = /my-nfts|create-collection|create-nft/;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (currentLocation.includes('collections')) {
      setValue(1);
    } else if (regexp.test(currentLocation)) {
      setValue(2);
    } else {
      setValue(0);
    }
  }, [currentLocation, value]);

  return (
    <StyledNftMarketplace>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          sx={{
            '& .MuiTabs-indicator': {
              background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
            },
            '& .MuiTabs-flexContainer': {
              justifyContent: isMobile ? 'space-between' : 'center',
            },
          }}
        >
          <CustomNftTab
            label={t('overview')}
            component={Link}
            to={PageRoutes.Nft}
          />
          <CustomNftTab
            label={t('collections')}
            component={Link}
            to={PageRoutes.NftCollections}
          />
          <CustomNftTab
            label={t('myNFTs')}
            component={Link}
            to={PageRoutes.MyNfts}
          />
        </Tabs>
      </Box>
      {children}
    </StyledNftMarketplace>
  );
};

export default NftMarketplace;
