import { useTranslation } from 'react-i18next';
import { CardContent, CardMedia, Grid } from '@mui/material';
import { useState } from 'react';
import { utils } from '@omisoftnet/game-dex-sdk';

import { NftCollection } from 'types/nftCollection';
import {
  BottomWrapper,
  StyledAvatar,
  StyledCard,
  StyledLink,
  StyledNoImgContainer,
  StyledTitle,
} from './styles';
import { BorderRadius, Color } from 'helpers/themeStyles';
import { StyledAuthor } from 'components/HotNftCollections/styles';
import { StyledPrice, StyledVolume } from 'components/NewestCollections/styles';
import { formatWalletKey } from 'helpers/formatWalletKey';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { stableTokens } from 'helpers/nativeTokens';

type ControlProps = {
  nft: NftCollection;
};

const NftCollectionListItem = ({ nft }: ControlProps) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);
  const shortOwnerName = formatWalletKey(nft?.owner?.publicAddress);
  return (
    <StyledLink
      to={`/nft-marketplace/collections/${nft.slug ? nft.slug : nft._id}`}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}
    >
      <StyledCard>
        {imgError ? (
          <StyledNoImgContainer>
            <StyledAuthor sx={{ color: Color.WHITE }}>
              {t('imageIsLoadingNow')}
            </StyledAuthor>
          </StyledNoImgContainer>
        ) : (
          <CardMedia
            style={{ borderRadius: BorderRadius.SEMI_SMALL }}
            component='img'
            onError={() => setImgError(true)}
            height='60%'
            image={
              nft.image_banner_url
                ? formatIpfsUrl(nft.image_banner_url[1])
                : formatIpfsUrl(nft.image_url[1])
            }
            alt='nft image'
          />
        )}
        <CardContent sx={{ '&.MuiCardContent-root': { p: 0, mt: 4 } }}>
          {!imgError && (
            <StyledAvatar
              alt='nft avatar'
              src={formatIpfsUrl(nft.image_url[1])}
            />
          )}
          <Grid container>
            <StyledTitle variant='h5'>{shortOwnerName}</StyledTitle>
            <Grid
              container
              flexDirection='column'
            >
              <StyledAuthor>{nft.name}</StyledAuthor>
              <BottomWrapper>
                <StyledVolume>{t('volume')}</StyledVolume>
                <StyledPrice>
                  {nft?.volume
                    ? `USD ${utils.formatUnits(
                        `${nft.volume}`,
                        stableTokens[nft.chain_id].decimals
                      )}`
                    : '-'}
                </StyledPrice>
              </BottomWrapper>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </StyledLink>
  );
};

export default NftCollectionListItem;
