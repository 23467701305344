import { utils } from '@omisoftnet/game-dex-sdk';
import { BigNumberish } from 'ethers';

const formatTokenBalance = (value: BigNumberish, decimals: number): string => {
  const formattedValue = utils.formatUnits(value, decimals);
  if (Number(formattedValue) === 0) {
    return '0.0';
  } else if (Number(formattedValue) >= 1) {
    const trimmedNumberString = formattedValue.replace(
      /(\.[0-9]*[1-9])0+$/,
      '$1'
    );
    const roundToFiveSymbolsNumber = Number(trimmedNumberString).toFixed(5);
    return parseFloat(roundToFiveSymbolsNumber).toString();
  } else if (Number(formattedValue) >= 0.00001) {
    return formattedValue.slice(0, 7);
  }
  return '<0.00001';
};

export default formatTokenBalance;
