import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { ModeEditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useCountdown } from '../../../../hooks/useCountDown';
import { renderToString } from 'react-dom/server';

import getNetworkIcon from 'helpers/getNetworkIcon';
import { networks } from 'helpers/networks';
import { stableTokens } from 'helpers/nativeTokens';
import { Color } from 'helpers/themeStyles';
import PreviewIcon from 'svg/PreviewIcon';
import { PageRoutes } from 'config/routes';
import {
  StyledHeaderBtn,
  StyledIcon,
  StyledLink,
  StyledLogo,
  StyledNetworkIcon,
  StyledOverlay,
  StyledProjectContainer,
  StyledRowContainer,
  StyledText,
  StyledTextContainer,
  StyledCountdownContainer,
  StyledCountdownElement,
  StyledCountdownValue,
  StyledCountdownLabel,
  StyledNetworkBtn,
  StyledProjectTitle,
  StyledAccentText,
  TransitionWrapper,
  StyledStatusBtn,
  StyledEditAndPreviewBtn,
  StyledDeployBtn,
} from './styles';
import { ProjectDetails } from 'types/project';
import {
  isStatusApproved,
  isStatusClosed,
  isStatusCurrent,
  isStatusDraft,
  isStatusPending,
  isStatusUpcoming,
} from 'helpers/projectStatusCheck';
import DeleteProjectPopup from 'components/Launchpad/DeleteProjectPopup';
import ChangeNetworkPopup from 'components/ChangeNetworkPopup';
import RemoveIcon from 'svg/RemoveIcon';
import NotFoundProjectLogoIcon from 'svg/NotFoundProjectLogoIcon';
import NotFoundProjectBannerIcon from 'svg/NotFoundProjectBannerIcon';
import PlatformCommissionInfoPopup from 'components/Launchpad/PlatformCommissionInfoPopup';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { useAccount } from 'wagmi';

type Props = { project: ProjectDetails; myProject?: boolean | undefined };

const ProjectItem: React.FC<Props> = ({ project, myProject }) => {
  const {
    _id: id,
    projectTitle: name,
    status,
    information: description,
    logo,
    heroPicture,
    poolInformation: {
      toRise: fundraiseGoal,
      toRiseProgress,
      chain,
      tokenDistributionDate,
      launchDate,
    },
  } = project;
  const { t } = useTranslation();
  const theme = useTheme();
  const { chain: network } = useAccount();
  const [isChangeNetworkOpen, setIsChangeNetworkOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [isHovered, setIsHovered] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCommissionInfoPopup, setOpenCommissionInfoPopup] = useState(false);
  const isOwner = localStorage.getItem('userId') === project?.owner;
  const isProjectNotDeployed =
    status &&
    (isStatusApproved(status) ||
      isStatusPending(status) ||
      isStatusDraft(status));
  const isProjectEdditable =
    status && (isStatusPending(status) || isStatusDraft(status));
  const isProjectActive =
    status && isStatusCurrent(status) && !isStatusClosed(status);
  const [days, hours, minutes, seconds, isExpired] = useCountdown(
    new Date(tokenDistributionDate).getTime(),
    Boolean(isProjectActive),
    id
  );
  const svgString = renderToString(<NotFoundProjectBannerIcon />);
  const bannerUrl = `data:image/svg+xml;base64,${Buffer.from(
    svgString
  ).toString('base64')}`;

  useCountdown(launchDate, false, id, status);
  return (
    <>
      <StyledLink
        to={`/launchpad/${id}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        height={
          !isExpired && !isStatusUpcoming(status) && !isProjectNotDeployed
            ? '475px'
            : '375px'
        }
      >
        <StyledProjectContainer
          style={{
            backgroundImage:
              heroPicture[0] &&
              !formatIpfsUrl(heroPicture[0]).includes('undefined')
                ? `${Color.PROJECT_CARD_GRADIENT},url(${formatIpfsUrl(
                    heroPicture[0]
                  )})`
                : `${Color.PROJECT_CARD_GRADIENT},url(${bannerUrl})`,
          }}
        >
          {myProject && isProjectNotDeployed ? (
            <StyledRowContainer
              style={{ alignItems: 'flex-start', paddingBottom: '0' }}
            >
              <Stack
                gap={1}
                alignItems='flex-start'
              >
                <StyledText>{t('myProjectStatus')}:</StyledText>
                <StyledStatusBtn
                  style={{
                    backgroundColor: isStatusPending(status!)
                      ? Color.DARK_YELLOW
                      : isStatusDraft(status!)
                      ? Color.TEXT_GRAY_SECONDARY
                      : Color.GREEN,
                  }}
                >
                  {isStatusPending(status) ? t('pendingApproval') : status}
                </StyledStatusBtn>
              </Stack>
              <Stack
                direction='row'
                justifyContent={'flex-end'}
                gap={2}
              >
                <StyledEditAndPreviewBtn
                  startIcon={<PreviewIcon />}
                  style={{
                    position: isMobile ? 'absolute' : 'static',
                    bottom: 24,
                    left: 24,
                    width: isMobile ? '130px' : 'auto',
                  }}
                >
                  {t('preview')}
                </StyledEditAndPreviewBtn>
                {isStatusApproved(status!) && isOwner && (
                  <StyledDeployBtn
                    onClick={(e) => {
                      e.preventDefault();
                      network?.id !== chain
                        ? setIsChangeNetworkOpen(true)
                        : setOpenCommissionInfoPopup(true);
                    }}
                    style={{
                      position: isMobile ? 'absolute' : 'static',
                      top: 24,
                      right: 24,
                    }}
                  >
                    {t('deployProject')}
                  </StyledDeployBtn>
                )}
                {isProjectEdditable && (
                  <Link
                    to={PageRoutes.CreateProjectOnLaunchpad}
                    state={{ projectId: id }}
                    style={{
                      position: isMobile ? 'absolute' : 'static',
                      bottom: 24,
                      right: 24,
                    }}
                  >
                    <StyledEditAndPreviewBtn
                      sx={{ width: isMobile ? '130px' : 'auto' }}
                      startIcon={<ModeEditOutlined />}
                    >
                      {t('editProject')}
                    </StyledEditAndPreviewBtn>
                  </Link>
                )}
                {isStatusDraft(status!) && (
                  <StyledEditAndPreviewBtn
                    sx={{
                      padding: '6px 12px',
                      position: isMobile ? 'absolute' : 'static',
                      top: 24,
                      right: 24,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDeletePopup(true);
                    }}
                  >
                    <RemoveIcon
                      width='20'
                      height='20'
                    />
                  </StyledEditAndPreviewBtn>
                )}
              </Stack>
            </StyledRowContainer>
          ) : (
            <StyledRowContainer style={{ alignItems: 'center' }}>
              <Stack
                direction='row'
                gap={1}
                alignItems='flex-start'
              >
                <StyledHeaderBtn>{t('IDO')}</StyledHeaderBtn>
              </Stack>
              <StyledNetworkBtn>
                <StyledNetworkIcon
                  src={getNetworkIcon(chain)}
                  alt='network icon'
                />
                {networks[chain]}
              </StyledNetworkBtn>
            </StyledRowContainer>
          )}
          <TransitionWrapper style={{ opacity: isHovered ? 1 : 0 }}>
            {isHovered && (
              <Stack
                alignItems='flex-start'
                mx={3}
                mb={isStatusDraft(status!) ? 0 : isProjectNotDeployed ? -2 : -6}
              >
                {logo[1] && !formatIpfsUrl(logo[1]).includes('undefined') ? (
                  <StyledLogo
                    src={formatIpfsUrl(logo[1])}
                    alt='project logo'
                  />
                ) : (
                  <NotFoundProjectLogoIcon
                    width='64px'
                    height='64px'
                  />
                )}
                <StyledProjectTitle sx={{ margin: '14px 0 6px' }}>
                  {name}
                </StyledProjectTitle>
                <StyledText>
                  {description.length > 100
                    ? description.slice(0, 100) + '...'
                    : description}
                </StyledText>
              </Stack>
            )}
          </TransitionWrapper>
          <TransitionWrapper style={{ opacity: !isHovered ? 1 : 0 }}>
            {!isHovered && (
              <Stack
                alignItems={isMobile ? 'center' : 'flex-start'}
                mx={3}
                mb={
                  isStatusDraft(status!) ? -2 : isProjectNotDeployed ? -3 : -6
                }
                direction={isMobile ? 'row' : 'column'}
                sx={{ marginTop: isMobile ? '130px' : '0' }}
              >
                {logo[1] && !formatIpfsUrl(logo[1]).includes('undefined') ? (
                  <StyledLogo
                    src={formatIpfsUrl(logo[1])}
                    alt='project logo'
                  />
                ) : (
                  <NotFoundProjectLogoIcon
                    width='64px'
                    height='64px'
                  />
                )}
                <StyledProjectTitle
                  sx={{ margin: isMobile ? '0 0 0 14px' : '14px 0' }}
                >
                  {name}
                </StyledProjectTitle>
              </Stack>
            )}
          </TransitionWrapper>
          <StyledRowContainer
            style={
              isMobile
                ? {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    position: 'absolute',
                    bottom:
                      !isExpired &&
                      !isStatusUpcoming(status) &&
                      !isProjectNotDeployed
                        ? -280
                        : -180,
                    left: 0,
                  }
                : { alignItems: 'flex-end', paddingTop: 0 }
            }
          >
            <Stack>
              <StyledTextContainer>
                <StyledText style={{ fontWeight: 700 }}>
                  {t('raised')}:
                </StyledText>
                <StyledText>
                  {toRiseProgress ? toRiseProgress : t('TBA')}
                </StyledText>
                <StyledIcon src={stableTokens[chain]?.icon} />
                <StyledAccentText>
                  {stableTokens[chain]?.symbol}
                </StyledAccentText>
              </StyledTextContainer>
              <StyledTextContainer sx={{ mt: 1 }}>
                <StyledText style={{ fontWeight: 700 }}>
                  {t('fundraiseGoal')}:
                </StyledText>
                <StyledText>{fundraiseGoal}</StyledText>
                <StyledIcon src={stableTokens[chain]?.icon} />
                <StyledAccentText>
                  {stableTokens[chain]?.symbol}
                </StyledAccentText>
              </StyledTextContainer>
            </Stack>
            {!isExpired &&
              !isStatusUpcoming(status) &&
              !isProjectNotDeployed &&
              !isStatusClosed(status) && (
                <Stack
                  gap={1}
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  sx={{ marginTop: isMobile ? '10px' : '0' }}
                  zIndex={1}
                >
                  <StyledText style={{ fontWeight: 700 }}>
                    {t('allocationRoundFinishsIn')}
                  </StyledText>
                  <StyledCountdownContainer>
                    <StyledCountdownElement>
                      <StyledCountdownValue>{days}</StyledCountdownValue>
                      <StyledCountdownLabel>
                        {t('lpCountdownDays')}
                      </StyledCountdownLabel>
                    </StyledCountdownElement>
                    <StyledCountdownElement>
                      <StyledCountdownValue>{hours}</StyledCountdownValue>
                      <StyledCountdownLabel>
                        {t('lpCountdownHours')}
                      </StyledCountdownLabel>
                    </StyledCountdownElement>
                    <StyledCountdownElement>
                      <StyledCountdownValue>{minutes}</StyledCountdownValue>
                      <StyledCountdownLabel>
                        {t('lpCountdownMinutes')}
                      </StyledCountdownLabel>
                    </StyledCountdownElement>
                    <StyledCountdownElement>
                      <StyledCountdownValue>{seconds}</StyledCountdownValue>
                      <StyledCountdownLabel>
                        {t('lpCountdownSeconds')}
                      </StyledCountdownLabel>
                    </StyledCountdownElement>
                  </StyledCountdownContainer>
                </Stack>
              )}
            {(isStatusUpcoming(status) || isProjectNotDeployed) && (
              <Stack
                gap={1}
                alignItems='flex-start'
                zIndex={1}
              >
                <StyledText style={{ fontWeight: 700 }}>
                  {t('allocationRoundStartsIn')}
                </StyledText>
                <StyledAccentText>{t('TBA')}</StyledAccentText>
              </Stack>
            )}
          </StyledRowContainer>
          <StyledOverlay
            style={{
              opacity: isHovered ? 1 : 0,
            }}
          />
        </StyledProjectContainer>
      </StyledLink>
      {openDeletePopup && (
        <DeleteProjectPopup
          isOpen={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
          projectId={id}
        />
      )}
      <ChangeNetworkPopup
        isOpen={isChangeNetworkOpen}
        onClose={() => setIsChangeNetworkOpen(false)}
        chainId={chain}
        unsetError={() => {}}
        title='investSwitchNetwork'
      />
      <PlatformCommissionInfoPopup
        isOpen={openCommissionInfoPopup}
        onClose={() => setOpenCommissionInfoPopup(false)}
        project={project}
      />
    </>
  );
};

export default ProjectItem;
