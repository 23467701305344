import { Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '32px',

  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}));

export const StyledTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  textDecoration: 'none',
  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,

  [theme.breakpoints.down('mobile')]: {
    display: 'block',
    marginTop: '24px',
    textAlign: 'center',
  },
}));

export const ItemsContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '16px',
}));

export const StyledContainer = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  padding: '40px',
  background: Color.SWAP_GRADIENT,
  borderRadius: BorderRadius.NORMAL,
}));

export const StyledText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
}));
