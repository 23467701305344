import React from 'react';

const HelpIcon: React.FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='32'
        height='32'
        rx='6'
        fill='white'
        fillOpacity='0.1'
      />
      <path
        d='M20.342 6H11.658C10.1587 6.00419 8.72182 6.60172 7.66184 7.66184C6.60169 8.72215 6.00418 10.1588 6 11.658V20.342C6.00419 21.8413 6.60172 23.278 7.66184 24.3382C8.722 25.3983 10.1588 25.9958 11.658 26H20.342C21.8413 25.9958 23.2782 25.3983 24.3382 24.3382C25.3983 23.278 25.9958 21.8413 26 20.342V11.658C25.9958 10.1587 25.3983 8.72197 24.3382 7.66184C23.278 6.60169 21.8412 6.00418 20.342 6ZM16.7559 19.8863C16.7599 20.5283 16.3999 20.9103 15.7699 20.9103C15.1398 20.9103 14.7199 20.5422 14.7178 20.0043C14.7178 19.2583 15.0518 18.8863 15.7178 18.8663C16.26 18.8503 16.8239 19.1823 16.7559 19.8863V19.8863ZM18.1559 15.2701C17.8718 15.4901 17.5738 15.6902 17.2878 15.9062V15.906C16.8661 16.1824 16.6238 16.6626 16.6519 17.1661C16.6589 17.3447 16.6062 17.5203 16.5017 17.6654C16.3973 17.8103 16.2474 17.9162 16.0759 17.966C15.8933 18.0363 15.6924 18.044 15.5049 17.9879C15.3174 17.9319 15.1539 17.8151 15.0399 17.656C14.9651 17.5308 14.9245 17.388 14.9219 17.2421C14.8926 16.3244 15.3185 15.4516 16.0598 14.91C16.3808 14.6658 16.6837 14.3983 16.9658 14.1101C17.1003 13.9516 17.1822 13.7552 17.1999 13.548C17.2459 12.982 16.882 12.618 16.2959 12.58C15.5879 12.5339 14.9919 12.7179 14.5778 13.3439V13.3441C14.4911 13.4917 14.3612 13.609 14.2056 13.6806C14.0501 13.7521 13.8764 13.7742 13.7078 13.7441C13.1078 13.644 12.8498 13.156 13.0958 12.5941C13.3533 12.0464 13.796 11.6072 14.3458 11.3541C14.9105 11.0765 15.5288 10.9249 16.1579 10.91C16.5136 10.9471 16.8669 11.0053 17.2158 11.084C18.2818 11.386 18.9159 12.238 18.9958 13.37C19.0744 14.1083 18.7535 14.8324 18.1539 15.27L18.1559 15.2701Z'
        fill='white'
      />
    </svg>
  );
};

export default HelpIcon;
