import { createSlice } from '@reduxjs/toolkit';
import { DEXToken } from '@omisoftnet/game-dex-sdk';

type liquiditySettings = {
  slippage: number;
  deadline: number;
};

interface IState {
  firstToken: DEXToken;
  secondToken: DEXToken;
  liquiditySettings: liquiditySettings;
  isClearAll?: boolean;
}

const initialState: IState = {
  firstToken: DEXToken.default(),
  secondToken: DEXToken.default(),
  liquiditySettings: {
    slippage: 0.5,
    deadline: 30,
  },
  isClearAll: false,
};

const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState: initialState,
  reducers: {
    setFirstLiquidityToken: (state, action) => {
      state.firstToken = action.payload;
    },
    setSecondLiquidityToken: (state, action) => {
      state.secondToken = action.payload;
    },
    updateAmountFirstToken: (state, action) => {
      state.firstToken = DEXToken.fromAddress(
        state.firstToken.address,
        action.payload,
        state.firstToken.chainId,
        state.firstToken.decimals,
        state.firstToken.symbol,
        state.firstToken.name,
        state.firstToken.logoUri,
        state.firstToken.isWrapped
      );
      // state.firstToken.amount = action.payload;
      // state.firstToken = state.firstToken;
      // state.firstToken = {
      //   ...state.firstToken,
      //   amount: action.payload,
      // };
    },
    updateAmountSecondToken: (state, action) => {
      state.secondToken = DEXToken.fromAddress(
        state.secondToken.address,
        action.payload,
        state.secondToken.chainId,
        state.secondToken.decimals,
        state.secondToken.symbol,
        state.secondToken.name,
        state.secondToken.logoUri,
        state.secondToken.isWrapped
      );
      // state.secondToken = {
      //   ...state.secondToken,
      //   amount: action.payload,
      // };
    },
    setLiquiditySettings: (state, action) => {
      state.liquiditySettings = {
        slippage: action.payload.slippage,
        deadline: action.payload.deadline,
      };
    },
    setIsClearAll: (state, action) => {
      state.isClearAll = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const {
  setFirstLiquidityToken,
  setSecondLiquidityToken,
  updateAmountFirstToken,
  updateAmountSecondToken,
  setLiquiditySettings,
  setIsClearAll,
} = liquiditySlice.actions;

export default liquiditySlice;
