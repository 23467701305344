import { styled } from '@mui/material';

import { Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const CardWrap = styled('table')(() => ({
  width: '100%',
}));

export const TableTitleWrap = styled('tr')(({}) => ({}));

export const TableTitleStyled = styled('th')(({ theme }) => ({
  padding: '14px 0',
  textAlign: 'left',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SUPER_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  textTransform: 'uppercase',

  [theme.breakpoints.down('mobile')]: {
    '&:nth-child(n+2)': {
      paddingLeft: '10px',
    },
  },
}));

export const TableItemWrap = styled('tr')(({ theme }) => ({
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
  cursor: 'pointer',
  '&:hover': {
    background: Color.DARK_PURPLE_OPACITY,
  },

  [theme.breakpoints.down('mobile')]: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
  },
}));

export const TableItemStyled = styled('td')(({ theme }) => ({
  padding: '14px 0',

  [theme.breakpoints.down('mobile')]: {
    '&:nth-child(n+2)': {
      padding: '14px 10px',
    },
  },
}));

export const RowWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const AvatarWrap = styled('div')({
  minWidth: '64px',
  minHeight: '64px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px',
});

export const IconWrap = styled('div')({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: 'grey',
  marginRight: '8px',
});

export const SmallText = styled('p')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  margin: 0,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.LITTLE,
  },
}));

export const TitleText = styled('div')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  margin: 0,
  fontWeight: '400',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.LITTLE,
  },
}));

export const PaginationWrap = styled('div')({
  margin: '0 auto',
});

export const SeeMoreBtn = styled('button')({
  display: 'block',
  margin: '32px auto',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: '700',
  color: Color.PURPLE,
  background: 'none',
  border: 'none',
  padding: '0',
  cursor: 'pointer',
  outline: 'inherit',
});
