import axios from 'axios';
import { CHAT_LOGIN_URL } from 'helpers/constants';
import { LocalStorage } from 'utils/LocalStorage';

export const userChatOperation = async (
  loginData: object,
  operation: string
) => {
  switch (operation) {
    case 'create':
      const resPost = await axios.post(CHAT_LOGIN_URL, loginData);
      const loginResponsePostData = { ...loginData, id: resPost.data._id };
      LocalStorage.setUserChatData(loginResponsePostData);
      break;
    case 'update':
      const resPut = await axios.put(CHAT_LOGIN_URL, loginData);
      const loginResponsePutData = { ...loginData, id: resPut.data.sender };
      LocalStorage.setUserChatData(loginResponsePutData);
      break;
  }
};
