import { useSigner } from '@hooks/useSigner';
import { API } from 'config/api';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import WelcomeIcon from 'shared/WelcomeIcon.svg';
import { useAccount, useDisconnect } from 'wagmi';
import {
  StyledCancelBtn,
  StyledContainer,
  StyledDexIcon,
  StyledDialoig,
  StyledPrivacyPolicy,
  StyledSignBtn,
  StyledTitle,
} from './styles';

const WelcomeNftPopup = ({
  isOpen,
  onClose,
  isConnecting,
}: {
  isOpen: boolean;
  onClose: () => void;
  isConnecting?: boolean;
}) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const signer = useSigner();
  const { disconnect } = useDisconnect();
  const location = useLocation();

  useEffect(() => {}, [signer]);
  async function getNonce() {
    try {
      const data = await API.get(`/auth/nonce?publicAddress=${address}`);
      onClose();
      const signature = await signer!.signMessage(data.data.user.nonce);
      const tokens = await API.post(`/auth/crypto-login`, {
        signature: signature,
        nonce: data.data.user.nonce,
      });
      localStorage.setItem('accessToken', tokens.data.accessToken);
      localStorage.setItem('refreshToken', tokens.data.refreshToken);
      localStorage.setItem('userId', tokens.data.user._id);
      localStorage.setItem('currentWalletAddress', address as string);
      onClose();
      if (location.pathname === '/nft-marketplace/my-nfts') {
        window.location.reload();
      }
    } catch (error) {
      disconnect();
      localStorage.removeItem('currentWalletAddress');
      onClose();
    }
  }
  useEffect(() => {}, [signer]);

  return (
    <StyledDialoig open={isOpen}>
      <StyledContainer>
        <StyledDexIcon src={WelcomeIcon} />
        <StyledTitle>{t('welcomeTitle')}</StyledTitle>
        <StyledPrivacyPolicy>
          {t('byConnecting')}
          {t('termsOfService')} {t('andConsentTo')} {t('privacyPolicy')}
        </StyledPrivacyPolicy>
        <StyledSignBtn
          onClick={() => {
            getNonce();
          }}
        >
          {t('acceptAndSign')}
        </StyledSignBtn>
        <StyledCancelBtn
          onClick={() => {
            disconnect();
            onClose();
          }}
        >
          {t('cancel')}
        </StyledCancelBtn>
      </StyledContainer>
    </StyledDialoig>
  );
};
export default WelcomeNftPopup;
