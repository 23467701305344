const VideoPlayer = ({
  id,
  width,
  height,
}: {
  id: string;
  width?: string;
  height?: string;
}) => {
  return (
    <>
      <iframe
        width={width ? width : '100%'}
        height={height ? height : '560'}
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
        style={{ marginTop: '8px' }}
      />
    </>
  );
};

export default VideoPlayer;
