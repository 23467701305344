import {
  CopyrightWrap,
  IconWrap,
  LinkStyled,
  LinksWrapper,
  SocialIconsContent,
  SocialIconsWrapper,
  TransitionWrapper,
} from 'components/SideBarBottomSection/styles';
import { Color } from 'helpers/themeStyles';
import FacebookIcon from 'svg/FacebookIcon';
import DiscordIcon from 'svg/DiscordIcon';
import InstagramIcon from 'svg/InstagramIcon';
import MediumIcon from 'svg/MediumIcon';
import RedditIcon from 'svg/RedditIcon';
import TelegramIcon from 'svg/TelegramIcon';
import TwitterIcon from 'svg/TwitterIcon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

type TSideBarBottomSection = {
  isHovering: boolean;
};

const SideBarBottomSection: React.FC<TSideBarBottomSection> = ({
  isHovering,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <div style={{ margin: isMobile ? '0px -16px' : 'undefined' }}>
      <LinksWrapper
        style={{
          opacity: isHovering ? 1 : 0,
          borderTop: isHovering
            ? `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT} `
            : '',
        }}
      >
        {/* {isHovering && (
          <>
            <LinkStyled
              href='https://planetex.io/'
              rel='noopener norefferer'
              target='_blank'
            >
              {t('termsOfUse')}
            </LinkStyled>
            <LinkStyled
              style={{ paddingTop: !isMobile ? '16px' : '' }}
              href='https://planetex.io/'
              rel='noopener norefferer'
              target='_blank'
            >
              {t('communityGuidelines')}
            </LinkStyled>
          </>
        )} */}
      </LinksWrapper>
      <SocialIconsWrapper>
        <TransitionWrapper style={{ opacity: isHovering ? 1 : 0 }}>
          {isHovering && (
            <SocialIconsContent
              style={{
                backgroundColor: 'transparent',
                width: !isMobile ? '152px' : '336px',
                height: !isMobile ? 'undefined' : '32px',
                justifyContent: !isMobile ? 'flex-start' : 'space-evenly',
              }}
            >
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <FacebookIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <DiscordIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <InstagramIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <MediumIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <RedditIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <TelegramIcon />
              </IconWrap>
              <IconWrap
                href='https://omisoft.net/'
                rel='norefferer noopener'
                target='_blank'
              >
                <TwitterIcon />
              </IconWrap>
            </SocialIconsContent>
          )}
        </TransitionWrapper>
        {!isHovering && (
          <SocialIconsContent
            style={{
              backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
              width: '76px',
              justifyContent: 'space-between',
            }}
          >
            <IconWrap
              href='https://omisoft.net/'
              rel='norefferer noopener'
              target='_blank'
            >
              <FacebookIcon />
            </IconWrap>
            <IconWrap
              href='https://omisoft.net/'
              rel='norefferer noopener'
              target='_blank'
            >
              <DiscordIcon />
            </IconWrap>
            <IconWrap
              href='https://omisoft.net/'
              rel='norefferer noopener'
              target='_blank'
            >
              <InstagramIcon />
            </IconWrap>
            <IconWrap
              href='/'
              rel='norefferer noopener'
            >
              ...
            </IconWrap>{' '}
          </SocialIconsContent>
        )}
      </SocialIconsWrapper>
      <TransitionWrapper style={{ opacity: isHovering ? 1 : 0 }}>
        {isHovering && <CopyrightWrap>© Copyright</CopyrightWrap>}
      </TransitionWrapper>
      {!isHovering && <CopyrightWrap>© Copyright</CopyrightWrap>}
    </div>
  );
};

export default SideBarBottomSection;
