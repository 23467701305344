const MenuIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.83333 10.8333H8.83333C9.05435 10.8333 9.26631 10.7455 9.42259 10.5893C9.57887 10.433 9.66667 10.221 9.66667 10V3.33333C9.66667 3.11232 9.57887 2.90036 9.42259 2.74408C9.26631 2.5878 9.05435 2.5 8.83333 2.5H3.83333C3.61232 2.5 3.40036 2.5878 3.24408 2.74408C3.0878 2.90036 3 3.11232 3 3.33333V10C3 10.221 3.0878 10.433 3.24408 10.5893C3.40036 10.7455 3.61232 10.8333 3.83333 10.8333ZM3 16.6667C3 16.8877 3.0878 17.0996 3.24408 17.2559C3.40036 17.4122 3.61232 17.5 3.83333 17.5H8.83333C9.05435 17.5 9.26631 17.4122 9.42259 17.2559C9.57887 17.0996 9.66667 16.8877 9.66667 16.6667V13.3333C9.66667 13.1123 9.57887 12.9004 9.42259 12.7441C9.26631 12.5878 9.05435 12.5 8.83333 12.5H3.83333C3.61232 12.5 3.40036 12.5878 3.24408 12.7441C3.0878 12.9004 3 13.1123 3 13.3333V16.6667ZM11.3333 16.6667C11.3333 16.8877 11.4211 17.0996 11.5774 17.2559C11.7337 17.4122 11.9457 17.5 12.1667 17.5H17.1667C17.3877 17.5 17.5996 17.4122 17.7559 17.2559C17.9122 17.0996 18 16.8877 18 16.6667V10.8333C18 10.6123 17.9122 10.4004 17.7559 10.2441C17.5996 10.0878 17.3877 10 17.1667 10H12.1667C11.9457 10 11.7337 10.0878 11.5774 10.2441C11.4211 10.4004 11.3333 10.6123 11.3333 10.8333V16.6667ZM12.1667 8.33333H17.1667C17.3877 8.33333 17.5996 8.24554 17.7559 8.08926C17.9122 7.93297 18 7.72101 18 7.5V3.33333C18 3.11232 17.9122 2.90036 17.7559 2.74408C17.5996 2.5878 17.3877 2.5 17.1667 2.5H12.1667C11.9457 2.5 11.7337 2.5878 11.5774 2.74408C11.4211 2.90036 11.3333 3.11232 11.3333 3.33333V7.5C11.3333 7.72101 11.4211 7.93297 11.5774 8.08926C11.7337 8.24554 11.9457 8.33333 12.1667 8.33333Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default MenuIcon;
