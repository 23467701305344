import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import NewestCollections from 'components/NewestCollections';
import HotNftCollections from 'components/HotNftCollections';
import AllNFTs from 'components/AllNFTs';
import { StyledTittle } from 'components/NewestCollections/styles';
import { StyledTitle } from './styles';

const NftOverview = () => {
  const { t } = useTranslation();

  return (
    <Box py={4}>
      <StyledTitle variant='h3'>{t('exploreCollections')}</StyledTitle>
      <NewestCollections />
      <StyledTittle>{t('hotCollections')}</StyledTittle>

      <HotNftCollections />
      <AllNFTs />
    </Box>
  );
};

export default NftOverview;
