const HomeIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 22H18.3333C19.4384 22 20.4981 21.5864 21.2796 20.8502C22.061 20.1141 22.5 19.1157 22.5 18.0746V11.5271C22.5026 10.4851 22.0647 9.48514 21.2833 8.74793L15.3333 3.1501C14.5762 2.436 13.5561 2.02435 12.4851 2.00106C11.414 1.97758 10.3746 2.34418 9.58328 3.02444L3.86662 7.92333C2.99814 8.66758 2.50205 9.72183 2.5 10.8281V18.0742C2.5 19.1153 2.93899 20.1136 3.72043 20.8498C4.50187 21.586 5.56157 21.9995 6.66666 21.9995H9.16666V16.504C9.16666 15.3821 9.80209 14.3454 10.8333 13.7845C11.8646 13.2235 13.1354 13.2235 14.1667 13.7845C15.1979 14.3454 15.8333 15.3821 15.8333 16.504V21.9995L17.5 22Z'
        fill='white'
      />
    </svg>
  );
};

export default HomeIcon;
