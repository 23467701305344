import { Button, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
export const StyledTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: 16,
  color: '#424141',
});
export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20%',
});
export const Logo = styled('img')(() => ({
  width: 38,
  height: 38,
  marginLeft: 3,
  marginRight: 3,
}));

export const StyledButton = styled(Button)({
  backgroundColor: '#1976d2',
  borderRadius: '20px',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: '#6dabe9',
  },
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#ffffff',
  textTransform: 'none',
  fontWeight: '700',
});
