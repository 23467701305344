const LiquidityIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='32'
        height='32'
        rx='6'
        fill='white'
        fillOpacity='0.1'
      />
      <path
        d='M25.3 7.78734C25.3 8.77408 23.6203 9.57498 21.5499 9.57498C19.4781 9.57498 17.7998 8.77408 17.7998 7.78734C17.7998 6.80002 19.4781 6 21.5499 6C23.6203 6 25.3 6.80002 25.3 7.78734Z'
        fill='white'
      />
      <path
        d='M19.7502 12.6694C19.7502 13.6563 18.0705 14.4571 16.0001 14.4571C13.9281 14.4571 12.25 13.6563 12.25 12.6694C12.25 11.6821 13.9281 10.8821 16.0001 10.8821C18.0705 10.8821 19.7502 11.6823 19.7502 12.6694V12.6694Z'
        fill='white'
      />
      <path
        d='M10.45 20.8821C8.89221 20.8821 7.57275 20.5146 6.69995 19.9087V20.8821C6.69995 21.8685 8.37804 22.6694 10.45 22.6694C12.5203 22.6694 14.2001 21.8685 14.2001 20.8821V19.9087C13.3269 20.5146 12.0078 20.8821 10.45 20.8821V20.8821Z'
        fill='white'
      />
      <path
        d='M14.2001 17.5515C14.2001 18.5382 12.5203 19.3391 10.45 19.3391C8.37804 19.3391 6.69995 18.5382 6.69995 17.5515C6.69995 16.5642 8.37804 15.7642 10.45 15.7642C12.5202 15.7642 14.2001 16.5642 14.2001 17.5515Z'
        fill='white'
      />
      <path
        d='M10.45 24.2124C8.89221 24.2124 7.57275 23.8452 6.69995 23.2393V24.2124C6.69995 25.1993 8.37804 26 10.45 26C12.5203 26 14.2001 25.1993 14.2001 24.2124V23.2393C13.3269 23.8452 12.0078 24.2124 10.45 24.2124V24.2124Z'
        fill='white'
      />
      <path
        d='M21.5497 11.118C21.2992 11.118 21.0657 11.0936 20.8284 11.0746C21.0519 11.4124 21.1749 11.7882 21.1749 12.1901V12.8872C21.3002 12.8935 21.4216 12.9053 21.5497 12.9053C23.6201 12.9053 25.2998 12.1044 25.2998 11.1179V10.1445C24.4268 10.7505 23.1076 11.1179 21.5497 11.1179L21.5497 11.118Z'
        fill='white'
      />
      <path
        d='M16 15.9999C15.7495 15.9999 15.5158 15.9755 15.2783 15.9565C15.502 16.2945 15.6249 16.6702 15.6249 17.0724V17.769C15.7503 17.7753 15.871 17.7873 16 17.7873C18.0704 17.7873 19.7501 16.9866 19.7501 16V15.0269C18.877 15.6328 17.5578 16 16 16L16 15.9999Z'
        fill='white'
      />
      <path
        d='M16 22.6608C15.8697 22.6608 15.7515 22.6431 15.6249 22.6385V23.7332C15.6249 23.9741 15.5765 24.2045 15.4937 24.4242C15.6606 24.4354 15.8269 24.4486 16 24.4486C18.0704 24.4486 19.7501 23.6475 19.7501 22.6609V21.6875C18.877 22.2933 17.5579 22.6608 16 22.6608L16 22.6608Z'
        fill='white'
      />
      <path
        d='M21.5497 21.1092C21.4194 21.1092 21.3012 21.0915 21.1749 21.0869V22.1816C21.1749 22.4225 21.1266 22.6526 21.0437 22.8721C21.2107 22.8832 21.3763 22.8965 21.5497 22.8965C23.6201 22.8965 25.2998 22.0957 25.2998 21.1092V20.1357C24.4268 20.7417 23.1076 21.1092 21.5497 21.1092L21.5497 21.1092Z'
        fill='white'
      />
      <path
        d='M16.0001 19.3302C15.8698 19.3302 15.7517 19.3126 15.625 19.3079V21.0996C15.7504 21.1059 15.8711 21.1178 16.0001 21.1178C18.0705 21.1178 19.7502 20.3171 19.7502 19.3305V18.3569C18.8771 18.9627 17.558 19.3302 16.0001 19.3302V19.3302Z'
        fill='white'
      />
      <path
        d='M21.5498 17.7781C21.4196 17.7781 21.3014 17.7608 21.175 17.7559V19.5477C21.3004 19.554 21.4219 19.5659 21.5498 19.5659C23.6203 19.5659 25.2999 18.7652 25.2999 17.7783V16.8052C24.427 17.411 23.1077 17.7781 21.5498 17.7781V17.7781Z'
        fill='white'
      />
      <path
        d='M21.5498 14.4482C21.4196 14.4482 21.3014 14.4309 21.175 14.4259V16.2176C21.3004 16.2239 21.4219 16.2355 21.5498 16.2355C23.6203 16.2355 25.2999 15.4349 25.2999 14.4482V13.4751C24.427 14.081 23.1077 14.4482 21.5498 14.4482V14.4482Z'
        fill='white'
      />
    </svg>
  );
};

export default LiquidityIcon;
