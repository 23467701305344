import { createSlice } from '@reduxjs/toolkit';
import { DEX } from '@omisoftnet/game-dex-sdk';
import { providers } from 'ethers';

interface IState {
  dex: DEX | null;
  signer: providers.JsonRpcSigner | null;
}

const initialState: IState = {
  dex: null,
  signer: null,
};

const dexSlice = createSlice({
  name: 'dex',
  initialState: initialState,
  reducers: {
    setSigner: (state, action) => {
      state.signer = action.payload;
    },
    setDex: (state, action) => {
      state.dex = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});
export const { setSigner, setDex } = dexSlice.actions;
export default dexSlice;
