/**
 * @description Object containing all request endpoints
 */
export const API_ROUTES = {
  AUTH: {
    REFRESH_TOKEN: 'auth/refresh/',
  },
  USERS: {
    CREATE: 'users/create/',
    CREATE_MODERATOR: 'users/moderator-create/',
    GET_ADMIN: 'users/admin/',
    GET_LIST: (limit: number, page: number) =>
      `users/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `users/delete/${id}/`,
    GET_BY_ID: (id: string) => `users/${id}/`,
    UPDATE: (id: string) => `users/${id}/`,
  },
  MESSAGES: {
    GET_ALL_MESSAGES: 'messages',
    ADD_NEW_MESSAGE: 'message',
  },
  CHAT_LOGIN: {
    ENTER_THE_CHAT: 'chat',
  },
  NFT_COLLECTION: {
    GET_HOT: `nft-collections/all?isHot=true&limit=4`,
    GET_NFTS_FROM_COLLECTION: (id: string) =>
      `nft-collection/nft/${id}/?limit=12`,
  },
  NFT: {
    GET_ONE: (id: string) => `nft/one/${id}/`,
  },
  TRANSACTIONS: {
    ADD_TRANSACTION: 'transaction-history/create',
    CLEAR_ALL: (publicAddress: string) =>
      `transaction-history/user/${publicAddress}`,
  },
  TOKENS: {
    GET_TOKENS: 'tokens',
  },
  LAUNCHPAD: {
    GET_PROJECTS_BY_STATUS: (
      status: string = '',
      limit: number | undefined,
      page: number | undefined
    ) =>
      limit && page
        ? `projects/all?status=${status}&page=${page}&limit=${limit}`
        : `projects/all?status=${status}`,
    CREATE_OR_UPDATE_PROJECT: '/project/',
    UPDATE_PROJECT: (id: string) => `/project/update/${id}`,
    GET_PROJECT_BY_ID: (id: string) => `/project/one/${id}`,
    GET_PROJECT_BY_USER_ID: (userID: string, limit: number, page: number) =>
      `project/owner?owner=${userID}&page=${page}&limit=${limit}`,
    DELETE_PROJECT_BY_ID: (id: string) => `project/delete/${id}`,
    UPDATE_PROJECT_STATUS: (id: string) => `project/status/${id}`,
    GET_USER_PROJECTS_BY_DAY: (id: string) => `project/day/${id}`,
  },
};
