import { Button, Paper, Stack, styled, Typography } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled(Paper)(({ theme }) => ({
  width: 531,
  height: 'fit-content',
  borderRadius: BorderRadius.NORMAL,
  marginBottom: 30,
  background: Color.SWAP_GRADIENT,

  [theme.breakpoints.down('mobile')]: {
    margin: '-23px 0px 0px 0px',
    width: '100%',
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN,
    boxShadow: 'inherit',
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: 0,
});

export const HeadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 20,
  borderBottom: Border.ACCENT_BORDER,
});

export const TitleContainer = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,
});

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontWeight: 700,
    fontSize: TextSize.MIDDLE,
  },
}));

export const SelectorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 16,

  [theme.breakpoints.down('mobile')]: {
    padding: '40px 16px ',
  },
}));

export const StyledText = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
});

export const TokenContainer = styled('div')(({ theme }) => ({
  padding: 16,
  background: Color.BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
  border: Border.ACCENT_BORDER,
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledUSDBalance = styled('span')(({ theme }) => ({
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE_OPACITY_LIGHT,
  fontFamily: FontFamily.INTER,
  fontWeight: 700,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.NORMAL,
    fontWeight: 400,
  },
}));

export const TipContainer = styled(Stack)({
  padding: 12,
  margin: '12px 16px 32px 16px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  background: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
});

export const TipText = styled('p')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  lineHeight: '150%',
  margin: 0,
});

export const BtnContainer = styled('div')({
  borderTop: Border.ACCENT_BORDER,
});

export const StyledBtn = styled(Button)(({ theme }) => ({
  width: '94%',
  textTransform: 'uppercase',
  boxSizing: 'border-box',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  letterSpacing: '0.05em',
  color: Color.WHITE,
  padding: 16,
  margin: '12px 16px',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
    margin: '20px 16px',
    padding: '10px',
  },
}));
