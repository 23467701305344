import { styled } from '@mui/material';
import { Accordion, Box, Button, InputBase, Paper } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled('div')(({ theme }) => ({
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 400,
  margin: '8px 0 4px',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: Color.BACKGROUND_MAIN,
}));
export const StyledCollapsedPaper = styled(Paper)(() => ({
  width: '412px',
  borderRadius: BorderRadius.NORMAL,
  background: Color.SWAP_GRADIENT,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  boxShadow: '0px 4px 99px rgba(0, 0, 0, 0.25)',
}));
export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '412px',
  height: 'auto',
  borderRadius: BorderRadius.NORMAL,
  background: Color.SWAP_GRADIENT,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  boxShadow: '0px 4px 99px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(2px)',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN,
  },
}));
export const StyledCollapsedHeader = styled('span')(() => ({
  padding: '25px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const StyledHeader = styled('span')(({ theme }) => ({
  padding: '10px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: Border.ACCENT_BORDER,

  [theme.breakpoints.down('mobile')]: {
    padding: '19px 16px',
  },
}));
export const StyledTitle = styled('div')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontWeight: 700,
    fontSize: TextSize.SMALL,
  },
}));

export const StyledSettings = styled('div')(() => ({
  cursor: 'pointer',
}));
export const StyledInput = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 16px 4px 16px',
  borderRadius: BorderRadius.NORMAL,
  padding: '14px 0 22px',
  backgroundColor: Color.BACKGROUND,

  [theme.breakpoints.down('mobile')]: {
    padding: '0 0 14px',
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  margin: '0 16px',
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  fontFamily: 'Inter',
  height: '24px',
  '& ::placeholder': {
    color: Color.WHITE_OPACITY_LIGHT,
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& :disabled': {
    color: `${Color.WHITE_OPACITY_LIGHT}!important`,
  },

  [theme.breakpoints.down('mobile')]: {
    margin: '0 16px 16px',
  },
}));
export const RevertButtonContainer = styled(Box)(() => ({
  '& :hover': {
    backgroundColor: Color.DARK_PURPLE,
    transition: 'background-color 0.3s linear',
  },
}));
export const RevertButton = styled('div')(
  ({ isLoading }: { isLoading: boolean }) => ({
    color: isLoading ? 'gray' : Color.WHITE,
    position: 'absolute',
    padding: '5px 5px 0',
    backgroundColor: isLoading ? 'gray' : Color.BACKGROUND,
    border: isLoading ? '4px solid gray' : `4px solid ${Color.DARK_PURPLE}`,
    borderRadius: BorderRadius.SMALL,
    left: '50%',
    transform: 'translate(-50%, -60%)',
    cursor: isLoading ? 'auto' : 'pointer',
  })
);
export const ButtonWrapper = styled('div')(({}) => ({
  margin: '-4px 16px 8px 16px',
}));
export const StyledButton = styled(Button)(({}) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '12px 0',
  color: Color.WHITE,
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },
}));
export const StyledButtonConnect = styled(StyledButton)(() => ({
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  },
}));
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  backgroundColor: Color.BACKGROUND,
  color: Color.TEXT_OPACITY_LIGHT,
  border: 'none',
  boxShadow: 'none',
  borderTop: `1px solid ${Color.BORDER_WHITE_EXTRA_OPACITY}`,
  borderTopLeftRadius: '0px !important',
  borderTopRightRadius: '0px !important',
  borderBottomLeftRadius: '16px',
  minHeight: '0px !important',
  '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '0px',

    [theme.breakpoints.down('mobile')]: {
      paddingBottom: '10px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': { margin: '4px 0' },
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    minHeight: 0,
  },
  '&.MuiPaper-root.MuiAccordion-root:last-of-type': {
    borderBottomLeftRadius: '16px !important',
    borderBottomRightRadius: '16px !important',
  },
}));
export const ExchangeDetailsWrap = styled('div')(({ theme }) => ({
  borderTopWidth: '1px',
  borderBottomWidth: '1px',
  borderTopStyle: 'solid',
  borderBottomStyle: 'solid',
  borderColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  padding: '0',

  [theme.breakpoints.down('mobile')]: {
    padding: '8px 0px',
  },
}));
export const ExchangeDetailsItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 0',

  [theme.breakpoints.down('mobile')]: {
    padding: '8px 0px',
  },
}));
export const ExchangeTitle = styled('span')(() => ({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  maxWidth: '50%',
}));
export const ExchangePrice = styled('span')(() => ({
  color: Color.WHITE_OPACITY_LIGHT,
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  maxWidth: '50%',
}));
export const AccordionTitleStyled = styled('span')(() => ({
  color: Color.TEXT_OPACITY_LIGHT,
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
}));
