import { Dialog, Button, Stack, styled } from '@mui/material';
import {
  BorderRadius,
  FontFamily,
  Color,
  TextSize,
  Border,
  BoxShadow,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '412px',
    borderRadius: BorderRadius.SMALL,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  },
});

export const StyledTitle = styled('h6')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 600,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,
});

export const StyledHeadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 16,
  borderBottom: Border.ACCENT_BORDER,
});

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: 0,
  '& :hover': {
    background: 'transparent',
  },
});

export const MainContent = styled('div')({
  padding: 16,
});

export const IconContainer = styled('div')({
  height: 36,
  width: 36,
  background: Color.BACKGROUND,
  border: `4px solid ${Color.DARK_PURPLE}`,
  borderRadius: BorderRadius.SMALL,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledText = styled('h6')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,
});

export const StyledRowContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
});

export const StyledConfirmButton = styled(Button)(({ theme }) => ({
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  padding: '16px 0px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  width: '100%',
  marginTop: '16px',

  [theme.breakpoints.down('mobile')]: {
    padding: 13,
  },
}));
