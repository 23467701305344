import React from 'react';

const SubmittedIcon: React.FC = () => {
  return (
    <svg
      width='26'
      height='22'
      viewBox='0 0 26 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 11.0965L9.14072 19L24.5 2'
        stroke='#11C243'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default SubmittedIcon;
