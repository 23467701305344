const RevertIcon = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_407_11421)'>
        <path
          d='M13.75 1.375L13.75 20.625L20.625 13.75'
          stroke='white'
          strokeWidth='1.73333'
          strokeLinejoin='round'
        />
        <path
          d='M8.25 20.625L8.25 1.375L1.375 8.25'
          stroke='white'
          strokeWidth='1.73333'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_407_11421'>
          <rect
            width='22'
            height='22'
            fill='white'
            transform='translate(22) rotate(90)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RevertIcon;
