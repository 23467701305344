import { Pagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { PageRoutes } from 'config/routes';
import launchpadSlice from 'state/launchpad/slice';
import {
  isLimitedSelector,
  projectsByOwnerSelector,
} from 'state/launchpad/selectors';
import ProjectItem from '../Preview/ProjectItem';
import {
  getProjectsByOwner,
  getUsersProjectByDay,
} from 'state/launchpad/thunks';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';
import { Color } from 'helpers/themeStyles';
import {
  StyledButton,
  StyledMyProjectsList,
  StyledRowContainer,
  StyledTitle,
} from './styles';
import ChangeNetworkPopup from 'components/ChangeNetworkPopup';
import { StyledBuyButton } from 'components/NftDetails/styles';

const LaunchpadMyProjects = () => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const dispatch = useDispatch();
  const userID = localStorage.getItem('userId');
  const projectsByOwner = useSelector(projectsByOwnerSelector);
  const [page, setPage] = useState<number>(1);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const isLimited = useSelector(isLimitedSelector);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (userID) {
      dispatch(getProjectsByOwner({ userID, limit: 4, page: value }));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      dispatch(getUsersProjectByDay({ id: userId }));
    }
  }, []);
  useEffect(() => {
    // delete project id if the user returned to MyProjects while creating a new project
    localStorage.removeItem('newProjectId');
    dispatch(launchpadSlice.actions.resetProjectId());
  }, []);

  useEffect(() => {
    if (userID) {
      dispatch(getProjectsByOwner({ userID, limit: 4, page }));
    }
  }, []);

  return (
    <>
      <Stack
        my={4}
        style={{ margin: 0 }}
      >
        <StyledRowContainer>
          <StyledTitle>{t('myProjects')}</StyledTitle>
          {address && !isLimited ? (
            <StyledButton to={PageRoutes.CreateProjectOnLaunchpad}>
              {t('createProject')}
            </StyledButton>
          ) : address && isLimited ? (
            <StyledBuyButton onClick={() => setIsInfoPopupOpen(true)}>
              {t('createProject')}
            </StyledBuyButton>
          ) : null}
        </StyledRowContainer>
        <StyledMyProjectsList>
          {!!projectsByOwner?.docs.length &&
            projectsByOwner?.docs.map((project) => (
              <ProjectItem
                myProject
                key={project._id}
                project={project}
              />
            ))}
        </StyledMyProjectsList>
        <Pagination
          count={projectsByOwner?.totalPages ?? 1}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <CustomPaginationItem {...item} />}
          sx={{
            '& .MuiPagination-ul': {
              justifyContent: 'center',
              marginBottom: '24px',
            },
            '& .MuiButtonBase-root': {
              color: Color.WHITE,
            },
          }}
        />
      </Stack>
      <ChangeNetworkPopup
        isOpen={isInfoPopupOpen}
        chainId={chain?.id!}
        onClose={() => setIsInfoPopupOpen(false)}
        title={t('youAlreadyCreatedThreeProjects')}
        information={true}
        unsetError={() => {}}
      />
    </>
  );
};

export default LaunchpadMyProjects;
