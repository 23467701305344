import { CSSProperties } from '@mui/material/styles/createMixins';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled('div')(({ theme }) => ({
  margin: '40px auto 100px',
  background: Color.SWAP_GRADIENT,
  color: Color.WHITE,
  width: '670px',
  borderRadius: BorderRadius.MEDIUM,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    margin: 0,
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN,
  },
}));

export const StyledHeaderContainer = styled(Stack)(({ theme }) => ({
  padding: '20px 16px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: Border.ACCENT_BORDER,

  [theme.breakpoints.down('mobile')]: {
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const StyledTitle = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
});

export const StyledSmallText = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledFormFieldTitle = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.SMALL,
});

export const StyledFormSection = styled(Box)({
  margin: '16px',
  padding: '24px 16px',
  background: Color.BACKGROUND,
  borderRadius: BorderRadius.MEDIUM,
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_MIDDLE}`,
});

export const StyledInputContainer = styled(Stack)({
  margin: '16px 0',
  gap: '8px',
});

export const StyledInput = styled(TextField)({
  border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
  borderRadius: BorderRadius.SMALL,
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  '& .MuiInputBase-root': {
    color: Color.WHITE,
    FontFamily: FontFamily.INTER,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
  '& ::placeholder': {
    color: Color.WHITE_OPACITY_LIGHT,
  },
});

export const StyledInputLabel = styled(InputLabel)({
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  '&.MuiFormLabel-root': {
    color: Color.WHITE,
  },
});

export const StyledFormControl = styled(FormControl)({
  background: Color.BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
});

export const StyledSelect = styled(Select)({
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
  color: Color.WHITE,
  '& .MuiSvgIcon-root': {
    color: Color.WHITE,
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select': {
    color: Color.WHITE,
    fontFamily: FontFamily.ZENDOTS,
    fontSize: TextSize.SMALL,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
});

export const StyledMenuItem = styled(MenuItem)({
  padding: '12px 8px',
  margin: '0 12px',
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  '&.Mui-selected': {
    backgroundColor: `${Color.DARK_PURPLE_OPACITY_MIDDLE}!important`,
    borderRadius: BorderRadius.SMALL,
  },
});

export const StyledCollectionIcon = styled('img')({
  width: '32px',
  height: '32px',
  borderRadius: BorderRadius.EXTRA_SMALL,
});

export const PropertiesText = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const PropertiesTitle = styled('p')({
  margin: '0 16px 8px',
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
});

export const StyledAddButton = styled(Button)(({ theme }) => ({
  padding: '8px',
  minWidth: 0,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.TABLE_SMALL,
  borderRadius: BorderRadius.SMALL,

  [theme.breakpoints.down('mobile')]: {
    margin: '8px auto',
  },
}));

export const PropertiesBlock = styled(Stack)({
  minWidth: '90px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_ORANGE_TO_PINK}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
});

export const StyledConfirmBtn = styled(Button)(({ theme }) => ({
  marginTop: '24px',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  color: Color.WHITE,
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
  padding: '16px',
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
    marginTop: '14px',
  },
}));

export const ButtonContainer = styled('div')({
  borderTop: Border.ACCENT_BORDER,
  padding: '10px 16px',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const NetworkIcon = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
});

export const UploadAreaContainer = styled('div')({
  width: '100%',
  minHeight: '389px',
  backgroundColor: Color.BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
});

export const UploadArea = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '389px',
  height: '100%',
  borderRadius: BorderRadius.NORMAL,
  cursor: 'pointer',
});

export const UploadImage = styled('img')({
  width: '96px',
  height: '96px',
  marginBottom: '32px',
});

export const UploadText = styled('p')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  opacity: '0.6',
});

export const selectMenuListStyle: CSSProperties = {
  maxHeight: '200px',
  overflowY: 'auto',
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  padding: '16px 0',
};

export const selectPaperStyle: CSSProperties = {
  background: 'transparent',
};

export const StyledError = styled('span')({
  color: Color.RED,
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  lineHeight: '160%',
});
