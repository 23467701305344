import { styled } from '@mui/system';
import { Border, BorderRadius, Color, TextSize } from 'helpers/themeStyles';

export const StyledAuthor = styled('p')({
  margin: 0,
  marginBottom: '6px',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
});

export const StyledName = styled('p')({
  marginBottom: '26px',
  paddingTop: '8px',
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
});

export const StyledSmallImg = styled('img')(({ theme }) => ({
  width: '64px',
  height: '64px',
  position: 'absolute',
  left: '16px',
  top: '72px',

  [theme.breakpoints.down('mobile')]: {
    left: '38px',
    top: '82px',
    borderRadius: BorderRadius.SMALL,
    border: `4px solid ${Color.WHITE}`,
  },
}));

export const StyledCardItem = styled('div')(({ theme }) => ({
  padding: '16px',
  background: Color.BACKGROUND_NFTS,
  borderRadius: BorderRadius.SEMI_SMALL,
  backgroundImage: `linear-gradient(#211824, #421539), ${Color.BORDER_HOT_COLLECTION_NFT}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  border: Border.TRANSPARENT_BORDER,
  minHeight: '240px',
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    boxSizing: 'border-box',
    marginBottom: '24px',
    width: '100%',
    maxWidth: '420px',
  },
}));
