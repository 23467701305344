import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Color, FontFamily } from 'helpers/themeStyles';
import {
  StyledContainer,
  StyledDialog,
  StyledTitle,
  StyledHeadingContainer,
} from './styles';
import { StyledBackButton } from '../CreateProject/styles';
import AlertRoundedIcon from 'svg/AlertRoundedIcon';

const MobileMetamaskInfo = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledContainer>
        <StyledHeadingContainer>
          <StyledTitle>{t('metaMaskInfo')}</StyledTitle>
          <IconButton
            onClick={onClose}
            aria-label='close'
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <Close sx={{ color: Color.WHITE }} />
          </IconButton>
        </StyledHeadingContainer>
        <AlertRoundedIcon />
        <StyledTitle style={{ marginTop: '20px' }}>
          {t('goToMetaMaskBrowser')}
        </StyledTitle>
        <StyledBackButton
          onClick={onClose}
          style={{
            fontFamily: FontFamily.INTER,
            textTransform: 'none',
            flex: '0.5',
          }}
        >
          {t('ok')}
        </StyledBackButton>
      </StyledContainer>
    </StyledDialog>
  );
};
export default MobileMetamaskInfo;
