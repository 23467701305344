import { createSlice } from '@reduxjs/toolkit';
import {
  DEXToken,
  Percent,
  SwapType,
  ethers,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';

type SwapSettings = {
  slippage: Percent;
  deadline: number;
  type: SwapType;
};

interface IState {
  firstToken?: DEXToken;
  secondToken?: DEXToken;
  tokenList: DEXToken[];
  topTokens: DEXToken[];
  swapSettings: SwapSettings;
  openTopSwitchPanel: Boolean;
  error: boolean;
  isDataLoading: boolean;
}

const initialState: IState = {
  firstToken: undefined,
  secondToken: undefined,
  tokenList: [],
  topTokens: [],
  isDataLoading: false,
  swapSettings: {
    slippage: new Percent(5, 10_000),
    deadline: Math.floor(Date.now() / 1000 + 1800),
    type: SwapType.SWAP_ROUTER_02,
  }, // We cant know recipient already
  openTopSwitchPanel: true,
  error: false,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState: initialState,
  reducers: {
    setFirstToken: (state, action) => {
      if (action.payload)
        action.payload.amount =
          state.firstToken?.amount ?? ethers.BigNumber.from(0);
      const prevTokenAmount = utils.formatUnits(
        state.firstToken?.amount ?? ethers.BigNumber.from(0),
        state.firstToken?.decimals
      );
      action.payload.amount = utils.parseUnits(
        `${prevTokenAmount.substring(
          0,
          prevTokenAmount.indexOf('.') + action.payload?.decimals
        )}`,
        action.payload?.decimals
      );
      state.firstToken = action.payload;
    },
    setSecondToken: (state, action) => {
      if (action.payload)
        action.payload.amount =
          state.secondToken?.amount ?? ethers.BigNumber.from(0);
      const prevTokenAmount = utils.formatUnits(
        state.secondToken?.amount ?? ethers.BigNumber.from(0),
        state.secondToken?.decimals
      );
      action.payload.amount = utils.parseUnits(
        `${prevTokenAmount.substring(
          0,
          prevTokenAmount.indexOf('.') + action.payload?.decimals
        )}`,
        action.payload?.decimals
      );
      state.secondToken = action.payload;
    },
    setTokenList: (state, action) => {
      state.tokenList = action.payload;
    },
    setPopularTokens: (state, action) => {
      state.topTokens = action.payload;
    },
    setSwapSettings: (state, action) => {
      state.swapSettings = action.payload;
    },
    openTopSwitchPanel: (state, action) => {
      state.openTopSwitchPanel = action.payload;
    },
    switchTokens: (state, action) => {
      state.firstToken = action.payload.selectedSecondToken;
      action.payload.selectedFirstToken.amount = BigNumber.from(0);
      state.secondToken = action.payload.selectedFirstToken;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetFirstToken: (state) => {
      state.firstToken = initialState.firstToken;
    },
    resetSecondToken: (state) => {
      state.secondToken = initialState.secondToken;
    },
    setIsDataLoading: (state, { payload }) => {
      state.isDataLoading = payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const {
  setFirstToken,
  setSecondToken,
  setTokenList,
  setPopularTokens,
  setSwapSettings,
  openTopSwitchPanel,
  switchTokens,
  setError,
  resetFirstToken,
  resetSecondToken,
  setIsDataLoading,
} = tokenSlice.actions;

export default tokenSlice;
