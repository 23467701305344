import React from 'react';

const FacebookIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0C4.477 0 0 4.477 0 10C0 15.013 3.693 19.153 8.505 19.876V12.65H6.031V10.021H8.505V8.272C8.505 5.376 9.916 4.105 12.323 4.105C13.476 4.105 14.085 4.19 14.374 4.229V6.523H12.732C11.71 6.523 11.353 7.492 11.353 8.584V10.021H14.348L13.942 12.65H11.354V19.897C16.235 19.236 20 15.062 20 10C20 4.477 15.523 0 10 0Z'
        fill='white'
      />
    </svg>
  );
};

export default FacebookIcon;
