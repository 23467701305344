import React from 'react';

const NFTMarketplaceIcon: React.FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='32'
        height='32'
        rx='6'
        fill='white'
        fillOpacity='0.1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3425 6.08505L24.5926 10.4308C24.8427 10.5626 25 10.8268 25 11.1151L24.9998 20.8849C24.9998 21.1734 24.8427 21.4374 24.5926 21.5692L16.3425 25.9149C16.1276 26.0283 15.8723 26.0283 15.6573 25.9149L7.4072 21.5692C7.1573 21.4374 7 21.1734 7 20.8849V11.1151C7 10.8266 7.15712 10.5626 7.4072 10.4308L15.6573 6.08505C15.8722 5.97165 16.1275 5.97165 16.3425 6.08505ZM20.4999 14.4616C21.2102 14.4616 22.4196 15.3225 23.4999 16.2289L23.5001 11.5843L16 7.63386L8.49989 11.5843L8.49971 17.9425C9.5837 17.0018 10.6893 16.181 11.3115 16.0269C11.326 16.0244 11.3406 16.0216 11.3551 16.0189C11.4052 16.0096 11.4552 16.0002 11.4999 16.0002C12.27 16.0002 14.6423 17.5332 16.3986 18.953C16.6427 19.1499 16.9964 19.1082 17.1886 18.8568C17.3809 18.6069 17.3391 18.244 17.0951 18.047C16.9896 17.9617 16.6639 17.7015 16.2159 17.3658C17.4106 16.2445 19.4708 14.4616 20.4999 14.4616ZM14.8746 14.4614C15.9101 14.4614 16.7496 13.6003 16.7496 12.5383C16.7496 11.4762 15.9101 10.6152 14.8746 10.6152C13.8391 10.6152 12.9996 11.4762 12.9996 12.5383C12.9996 13.6003 13.8391 14.4614 14.8746 14.4614Z'
        fill='white'
      />
    </svg>
  );
};

export default NFTMarketplaceIcon;
