import React from 'react';

const MetamaskIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.1476 2.67383L13.6523 8.24068L15.0384 4.95631L21.1476 2.67383Z'
        fill='#E2761B'
        stroke='#E2761B'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.84467 2.67383L11.2797 8.29341L9.96143 4.95631L3.84467 2.67383ZM18.4511 15.5778L16.4548 18.6362L20.726 19.8113L21.9539 15.6456L18.4511 15.5778ZM3.05371 15.6456L4.27405 19.8113L8.54524 18.6362L6.549 15.5778L3.05371 15.6456Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.30446 10.4105L7.11426 12.2109L11.3553 12.3992L11.2047 7.84179L8.30446 10.4105ZM16.6886 10.4105L13.7508 7.78906L13.6529 12.3992L17.8864 12.2109L16.6886 10.4105ZM8.54552 18.6365L11.0917 17.3936L8.89204 15.6761L8.54552 18.6365ZM13.9015 17.3936L16.4551 18.6365L16.1011 15.6761L13.9015 17.3936Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.4545 18.6365L13.9009 17.3936L14.1042 19.0583L14.0816 19.7589L16.4545 18.6365ZM8.54492 18.6365L10.9178 19.7589L10.9027 19.0583L11.0911 17.3936L8.54492 18.6365Z'
        fill='#D7C1B3'
        stroke='#D7C1B3'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9553 14.5764L8.83105 13.9511L10.3301 13.2656L10.9553 14.5764ZM14.0363 14.5764L14.6616 13.2656L16.1682 13.9511L14.0363 14.5764Z'
        fill='#233447'
        stroke='#233447'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.54506 18.6365L8.90664 15.5782L6.54883 15.646L8.54506 18.6365ZM16.0931 15.5782L16.4547 18.6365L18.4509 15.646L16.0931 15.5782ZM17.8859 12.2109L13.6524 12.3993L14.0441 14.5763L14.6694 13.2656L16.1759 13.951L17.8859 12.2109ZM8.83131 13.951L10.3379 13.2656L10.9556 14.5763L11.3549 12.3993L7.1138 12.2109L8.83131 13.951Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.11426 12.2109L8.89204 15.6761L8.83177 13.951L7.11426 12.2109ZM16.1764 13.951L16.1011 15.6761L17.8864 12.2109L16.1764 13.951ZM11.3553 12.3993L10.9561 14.5763L11.4532 17.145L11.5662 13.7627L11.3553 12.3993ZM13.6529 12.3993L13.4495 13.7552L13.5399 17.145L14.0446 14.5763L13.6529 12.3993Z'
        fill='#E4751F'
        stroke='#E4751F'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0439 14.5764L13.5392 17.1451L13.9007 17.3937L16.1004 15.6762L16.1757 13.9512L14.0439 14.5764ZM8.83105 13.9512L8.89132 15.6762L11.0909 17.3937L11.4525 17.1451L10.9553 14.5764L8.83105 13.9512Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0816 19.7591L14.1042 19.0586L13.9159 18.8928H11.076L10.9027 19.0586L10.9178 19.7591L8.54492 18.6367L9.37355 19.3147L11.0534 20.4823H13.9385L15.6259 19.3147L16.4545 18.6367L14.0816 19.7591Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9014 17.3931L13.5399 17.1445H11.4532L11.0916 17.3931L10.9033 19.0579L11.0766 18.8922H13.9165L14.1048 19.0579L13.9014 17.3931Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.4642 8.60226L22.1045 5.52882L21.1479 2.67383L13.9012 8.05236L16.6884 10.4102L20.6281 11.5627L21.5019 10.5458L21.1253 10.2746L21.7279 9.72468L21.2609 9.36309L21.8635 8.90358L21.4642 8.60226ZM2.89551 5.52882L3.53581 8.60226L3.12903 8.90358L3.73167 9.36309L3.27216 9.72468L3.87479 10.2746L3.49814 10.5458L4.36443 11.5627L8.30417 10.4102L11.0914 8.05236L3.84466 2.67383L2.89551 5.52882Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.6281 11.5634L16.6884 10.4109L17.8861 12.2113L16.1008 15.6764L18.4511 15.6463H21.9539L20.6281 11.5634ZM8.30418 10.4109L4.36445 11.5634L3.05371 15.6463H6.549L8.89175 15.6764L7.11398 12.2113L8.30418 10.4109ZM13.6526 12.3996L13.9012 8.05308L15.0462 4.95703H9.96143L11.0914 8.05308L11.355 12.3996L11.4454 13.7706L11.453 17.1454H13.5396L13.5547 13.7706L13.6526 12.3996Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MetamaskIcon;
