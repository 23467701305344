import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Pool, PositionCache } from '@omisoftnet/game-dex-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { dexSelector, signerSelector } from 'state/dex/selectors';
import { setIsOpen } from 'state/walletconnect/slice';
import EmptyLiquidityIcon from 'svg/EmptyLiquidutyIcon';
import PositionItem from './PositionItem';

import { DEXToken } from '@omisoftnet/game-dex-sdk';
import { IPosition } from '@omisoftnet/game-dex-sdk/dist/providers';
import { fetchData } from 'config/api';
import { BigNumber } from 'ethers';
import {
  setFirstLiquidityToken,
  setLiquiditySettings,
  setSecondLiquidityToken,
} from 'state/liquidity/slice';
import {
  StyledButton,
  StyledConnectWalletBtn,
  StyledContainer,
  StyledHeaderTitle,
  StyledLiquiditySubtitle,
  StyledLiquidityTitle,
  StyledModalContent,
  StyledNewPositionBtn,
  StyledPositionHeader,
} from './styles';
import { useSigner } from '@hooks/useSigner';
import { isChainSupproted } from 'helpers/isChainSupproted';

type Props = {
  fromV2?: boolean;
  openAddLiquidity?: React.MouseEventHandler<HTMLButtonElement>;
};

class PositionV2 implements IPosition {
  private payload: any;
  constructor(_payload: any) {
    this.payload = _payload;
  }
  id(): string {
    return this.payload._id;
  }
  token0(): DEXToken {
    return DEXToken.fromAddress(
      this.payload.tokenAInfo[0].address,
      BigNumber.from(this.payload.amountA),
      this.payload.tokenAInfo[0].chainId,
      this.payload.tokenAInfo[0].decimals,
      this.payload.tokenAInfo[0].symbol,
      this.payload.tokenAInfo[0].name,
      this.payload.tokenAInfo[0].logoUri
    );
  }
  token1(): DEXToken {
    return DEXToken.fromAddress(
      this.payload.tokenBInfo[0].address,
      BigNumber.from(this.payload.amountB),
      this.payload.tokenBInfo[0].chainId,
      this.payload.tokenBInfo[0].decimals,
      this.payload.tokenBInfo[0].symbol,
      this.payload.tokenBInfo[0].name,
      this.payload.tokenBInfo[0].logoUri
    );
  }
  liquidity(): BigNumber {
    return BigNumber.from(this.payload.liquidity);
  }
  pool(): Pool {
    throw new Error('Method not implemented.');
  }
  fee(): string {
    return '3000';
  }
  wrapped() {
    throw new Error('Method not implemented.');
  }
}
export default function LiquidityPositions(props: Props) {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const dex = useSelector(dexSelector);
  // WTF
  const [positions, setPositions] = useState<IPosition[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');
  const V2 = chain?.id && [56, 97].includes(chain?.id);
  const signer = useSelector(signerSelector);

  // Need to separate V2 and V3 positions Hook doesn't work on mobile
  // useEffect(() => {
  //   getLiquidityPositions();
  // }, [userId, address, signer, chain]);
  console.log('v2', V2);
  useEffect(() => {
    if (!isChainSupproted(chain?.id!) || !dex) return;
    if (!V2 && signer != undefined) {
      setLoading(true);
      const cache = [...PositionCache.iter()].filter(
        (p) => !p.liquidity().isZero()
      );
      setPositions(cache);
      console.time('Fetch Positions');
      dex.liquidityPositions(address!).then((data) => {
        setPositions(data.filter((p) => !p.liquidity().isZero()));
        console.timeEnd('Fetch Positions');
        setLoading(false);
      });
    }
  }, [signer]);
  useEffect(() => {
    if (V2 && userId) {
      setLoading(true);
      fetchData(`/liquidity/${userId}`).then((data) => {
        const testV2PositionData = [];
        const v2PositionsData = [];
        for (let pos of data.data) {
          if (pos?.tokenAInfo[0]?.chainId === 56) {
            v2PositionsData.push(new PositionV2(pos));
          } else {
            testV2PositionData.push(new PositionV2(pos));
          }
        }
        if (chain?.id === 56) {
          setPositions(v2PositionsData);
        } else {
          setPositions(testV2PositionData);
        }
        setLoading(false);
      });
    }
  }, [userId, chain]);
  // const sortLiquidityPositions = (positions: any) => {
  //   const liquidityPositionsState = [];
  //   for (let pos of positions) {
  //     if (pos?.wrappedPosition?.liquidity.length > 0) {
  //       liquidityPositionsState.push(pos);
  //     }
  //   }
  //   setLiquidityPositions(liquidityPositionsState);
  // };

  // const getLiquidityPositions = async () => {
  //   setLoading(true);
  //   try {
  //     if (V2) {
  //     } else {
  //     }
  //   } catch (error) {
  //     console.error('ERROR', error);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // Reset the liquidity tokens
    // BUT IF TOKENS IS DEXTOKEN ITS MUST TO BE A DEXTOKEN EVEN IF ITS EMPTY
    dispatch(setFirstLiquidityToken(DEXToken.default()));
    dispatch(setSecondLiquidityToken(DEXToken.default()));
  }, []);

  useEffect(() => {
    dispatch(
      setLiquiditySettings({
        slippage: '0.5',
        deadline: '30',
      })
    );
  }, []);

  return (
    <>
      <StyledContainer>
        <Stack
          direction='row'
          justifyContent='space-between'
          mb='24px'
          sx={{ display: isMobile ? 'block' : 'flex' }}
        >
          <StyledLiquidityTitle>{t('liquidity')}</StyledLiquidityTitle>
          {address && (
            <StyledNewPositionBtn
              variant='contained'
              onClick={props.openAddLiquidity}
            >
              {t('newPosition')}
            </StyledNewPositionBtn>
          )}
        </Stack>
        {loading ? (
          <StyledButton
            disabled
            fullWidth
          >
            <div>{t('loading')}</div>
          </StyledButton>
        ) : (
          <Stack>
            <StyledModalContent>
              {!positions.length && (
                <Stack
                  my={10}
                  justifyContent='center'
                  alignItems='center'
                >
                  <EmptyLiquidityIcon />
                  <StyledLiquiditySubtitle>
                    {t('positionsWillAppearHere')}
                  </StyledLiquiditySubtitle>
                  {!address && (
                    <StyledConnectWalletBtn
                      variant='contained'
                      onClick={() => dispatch(setIsOpen(true))}
                    >
                      {t('connectAWallet')}
                    </StyledConnectWalletBtn>
                  )}
                </Stack>
              )}

              {!!positions.length && address && (
                <div
                  style={{
                    width: isMobile ? '110%' : '100%',
                  }}
                >
                  <StyledPositionHeader>
                    <StyledHeaderTitle>{`${t('yourPositions')} (${
                      positions.length
                    })`}</StyledHeaderTitle>
                  </StyledPositionHeader>
                  <Stack
                    py={3}
                    px={2}
                    gap={4}
                  >
                    {positions.map((position: any) => (
                      <PositionItem
                        key={Number(position.id())}
                        position={position}
                        fromV2={props.fromV2}
                      />
                    ))}
                  </Stack>
                </div>
              )}
            </StyledModalContent>
          </Stack>
        )}
      </StyledContainer>
    </>
  );
}
