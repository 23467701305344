import React from 'react';

const ErrorIcon = ({ width, height }: { width?: string; height?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : '74'}
      height={height ? height : '74'}
      viewBox='0 0 24 24'
      fill='none'
      stroke='rgb(250, 43, 57)'
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
      <line
        x1='12'
        y1='9'
        x2='12'
        y2='13'
      ></line>
      <line
        x1='12'
        y1='17'
        x2='12.01'
        y2='17'
      ></line>
    </svg>
  );
};

export default ErrorIcon;
