import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateProjectStatusThunk } from 'state/launchpad/thunks';

const useCountdown = (
  targetDate: any,
  currentProjectFlag: boolean,
  id: string,
  status?: string
) => {
  const countDownDate = new Date(targetDate).getTime();
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    let interval: any;
    if (currentProjectFlag) {
      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const date = new Date(targetDate);
        if (currentTime >= date.getTime()) {
          // change project status
          dispatch(updateProjectStatusThunk({ id, status: 'closed' }));
          clearInterval(interval);
        }
        setCountDown(countDownDate - currentTime);
      }, 1000);
    }
    if (!currentProjectFlag && status === 'upcoming') {
      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const date = new Date(targetDate);
        if (currentTime >= date.getTime()) {
          // change project status
          dispatch(updateProjectStatusThunk({ id, status: 'current' }));
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: any) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const isExpired = days + hours + minutes + seconds <= 0;

  return [days, hours, minutes, seconds, isExpired];
};

export { useCountdown };
