import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Color } from 'helpers/themeStyles';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&.Mui-selected': {
        backgroundColor: `${Color.BACKGROUND}!important`,
        border: '2px solid transparent!important',
        backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_ORANGE_TO_PINK}`,
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
        width: '24%',
        flexDirection: 'column',
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
        width: '24%',
        flexDirection: 'column',
      },
      [theme.breakpoints.down('mobile')]: {
        flexGrow: 1,
      },
    },
    [theme.breakpoints.between('mobile', 1400)]: {
      width: '100%',
      justifyContent: 'center',
    },
  })
);
