import { useSigner } from '@hooks/useSigner';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Dialog,
  Grid,
  IconButton,
  Stack,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Percent, utils } from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import {
  RevertButton,
  StyledAdditionalSwapInfo,
  StyledButton,
  StyledCloseIcon,
  StyledContent,
  StyledDialog,
  StyledEstimatedOutputInfo,
  StyledExchange,
  StyledGridInfo,
  StyledGridSubtitle,
  StyledGridTitle,
  StyledMinimumReceived,
  StyledSwapTokensContainer,
  StyledTitle,
  StyledTokenContainer,
  StyledTokenSymbol,
  StyledValue,
  themes,
} from './styles';

import { SwapRoute } from '@uniswap/smart-order-router';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { roundNumberInSwap } from 'helpers/roundNumberInSwap';
import { TextSize } from 'helpers/themeStyles';
import { transactionStatuses } from 'helpers/transactionStatuses';
import Loader from 'shared/Loader';
import { dexSelector } from 'state/dex/selectors';
import {
  firstTokenSelector,
  secondTokenSelector,
  swapSettingsSelector,
} from 'state/swap/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import BottomArrowIcon from 'svg/BottomArrowIcon';

type ConfirmModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  // firstTokenValue: string;
  // estimatedOutput: any;
  // estimatedPoolFee: number;
  // intervalId?: NodeJS.Timer;
  // exchangeRate: string | false;
  // firstToken: DEXToken;
  // secondToken: DEXToken;
  route: SwapRoute | null;
  aquote?: string;
  clearTokensValue: () => void;
  isWrap?: boolean;
  isUnwrap?: boolean;
};

const ConfirmSwapModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { address } = useAccount();
  const signer = useSigner();
  const [isOpen, setIsOpen] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const firstToken = useSelector(firstTokenSelector);
  const secondToken = useSelector(
    props.isWrap || props.isUnwrap ? firstTokenSelector : secondTokenSelector
  );
  const dex = useSelector(dexSelector);
  const swapSettings = useSelector(swapSettingsSelector);
  // const [priceImpact, setPriceImpact] = useState<string>('0.0');
  const [[, perOneEstimatedOutput], setPerOneEstimatedOutput] = useState<
    [number, BigNumber]
  >([3000, BigNumber.from(0)]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  // const { route } = useQuote();
  const { route } = props;

  // async function estimate() {
  //   if (firstToken && secondToken) {
  //     firstToken.amount = utils.parseUnits('1', firstToken.decimals);
  //     const perOneOutput = await dex.getAmountsOut(firstToken, secondToken);
  //     // setPerOneEstimatedOutput(perOneOutput);
  //     // const priceImpact = await dex
  //     //   .getPriceImpact(firstToken.chainId, firstToken, secondToken)
  //     //   .catch((err) => console.error(err));

  //     // // We cant calculate priceimpact on testnet, limited realization of alpharouter
  //     // if (priceImpact) setPriceImpact(priceImpact);
  //   }
  // }
  async function swap() {
    try {
      // firstToken!.amount = utils.parseUnits(
      //   props.firstTokenValue,
      //   firstToken?.decimals
      // );
      // const parsedSlipage = Math.floor(
      //   Number.parseFloat(String(swapSettings.slippage)) * 100
      // );
      // const deadline =
      //   Math.floor(Date.now() / 1000) + 60 * swapSettings.deadline;
      // const tx = await dex.swap(firstToken!, secondToken!, {
      //   deadline: deadline,
      //   fee: props.estimatedPoolFee,
      //   slipage: parsedSlipage,
      // });
      const feeData = await signer!.getFeeData();
      const gasUnits = await signer?.estimateGas({
        from: address,
        to: route?.methodParameters?.to,
        value: route?.methodParameters?.value,
        data: route?.methodParameters?.calldata,
      });
      const tx = await signer!.sendTransaction({
        to: route?.methodParameters?.to,
        data: route?.methodParameters?.calldata,
        value: route?.methodParameters?.value,
        gasLimit: gasUnits?.add(gasUnits.mul(30).div(100)),
        gasPrice: feeData.gasPrice ?? undefined,
      });
      dispatch(
        setTransactionHash({
          hash: tx.hash,
          from: tx.from,
          to: tx.to,
          owner: address!,
          chainId: firstToken!.chainId,
          transaction_type: 'swap',
          data: {
            token_data: {
              token0: {
                ...firstToken,
                logoURI: firstToken?.icon,
              },
              token1: {
                ...secondToken,
                logoURI: secondToken?.icon,
              },
              token0Amount: utils
                .formatUnits(
                  firstToken?.amount ?? BigNumber.from(0),
                  firstToken?.decimals
                )
                .slice(0, 10),
              token1Amount: utils
                .formatUnits(
                  secondToken?.amount ?? BigNumber.from(0),
                  secondToken?.decimals
                )
                .slice(0, 10),
            },
          },
        })
      );
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionSubmittedStatus(true);
    } catch (error) {
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionRejectedStatus(true);
      console.log(error); // for debug
    }
  }

  async function isWrap() {
    try {
      const tx = await firstToken?.wrapped.deposit(signer!, firstToken.amount);
      if (tx) {
        dispatch(setTransactionHash({ hash: tx.hash }));
      }
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionSubmittedStatus(true);
    } catch (error) {
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionRejectedStatus(true);
      console.log(error); // for debug
    }
  }
  async function isUnwrap() {
    try {
      const tx = await secondToken?.wrapped.withdraw(
        signer!,
        secondToken.amount
      );
      if (tx) {
        dispatch(setTransactionHash({ hash: tx.hash }));
      }
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionSubmittedStatus(true);
    } catch (error) {
      props.setIsOpen(false);
      setIsOpen(false);
      setTransactionRejectedStatus(true);
      console.log(error); // for debug
    }
  }
  // useEffect(() => {
  //   if (props.isOpen && !props.isWrap && !props.isUnwrap) {
  //     estimate();
  //   }
  // }, [props.isOpen, props.isWrap, props.isUnwrap]);
  const perOneTokenEstimate = Number(
    utils
      .formatUnits(perOneEstimatedOutput ?? '0x0', secondToken?.decimals)
      .slice(0, 7)
  );
  const firstTokenValue = utils.formatUnits(
    firstToken?.amount ?? BigNumber.from(0),
    firstToken?.decimals
  );
  const secondTokenValue = utils.formatUnits(
    secondToken?.amount ?? BigNumber.from(0),
    secondToken?.decimals
  );
  return (
    <>
      <ThemeProvider theme={themes}>
        <StyledDialog
          open={props.isOpen}
          maxWidth='sm'
          fullWidth
          onClose={() => {
            props.setIsOpen(false);
          }}
        >
          <StyledTitle>
            {props.isWrap
              ? t('confirmWrap')
              : props.isUnwrap
              ? t('confirmUnwrap')
              : t('confirmSwap')}
            <IconButton
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => {
                props.setIsOpen(false);
              }}
            >
              <StyledCloseIcon />
            </IconButton>
          </StyledTitle>
          <StyledContent>
            <StyledSwapTokensContainer
              container
              flexDirection={'column'}
              rowGap='5px'
            >
              <StyledTokenContainer
                container
                alignItems='center'
                justifyContent='space-between'
                flexWrap='nowrap'
              >
                <Grid container>
                  <StyledValue>
                    {isMobile
                      ? firstTokenValue.length > 10
                        ? firstTokenValue.slice(0, 10) + '...'
                        : firstTokenValue
                      : firstTokenValue.slice(0, 14)}
                  </StyledValue>
                </Grid>
                <Grid
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  <Avatar
                    sx={{ mr: 1, width: '24px', height: '24px' }}
                    src={firstToken?.icon}
                  />
                  <StyledTokenSymbol>{firstToken?.symbol}</StyledTokenSymbol>
                </Grid>
              </StyledTokenContainer>
              <Grid item>
                <RevertButton>
                  <BottomArrowIcon />
                </RevertButton>
              </Grid>
              <StyledTokenContainer
                container
                alignItems='center'
                justifyContent='space-between'
                flexWrap='nowrap'
              >
                <Grid container>
                  <StyledValue>
                    {props.isWrap || props.isUnwrap
                      ? isMobile
                        ? firstTokenValue.length > 10
                          ? firstTokenValue.slice(0, 10) + '...'
                          : firstTokenValue
                        : firstTokenValue.slice(0, 14)
                      : isMobile
                      ? secondTokenValue.length > 10
                        ? secondTokenValue.slice(0, 10) + '...'
                        : secondTokenValue
                      : secondTokenValue.slice(0, 14)}
                  </StyledValue>
                </Grid>
                <Grid
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  <Avatar
                    sx={{ mr: 1, width: '24px', height: '24px' }}
                    src={secondToken?.icon}
                  />
                  <StyledTokenSymbol>{secondToken?.symbol}</StyledTokenSymbol>
                </Grid>
              </StyledTokenContainer>
            </StyledSwapTokensContainer>
            <Grid
              container
              flexDirection={'column'}
              marginTop='40px'
            >
              <Grid item>
                <StyledExchange>
                  1 {firstToken?.symbol} ={' '}
                  {props.isWrap || props.isUnwrap
                    ? 1
                    : props.aquote &&
                      Number(
                        roundNumberInSwap(
                          Number(props.aquote!.replace(/,/g, ''))
                        )
                      ) > 0.00001
                    ? roundNumberInSwap(Number(props.aquote.replace(/,/g, '')))
                    : '<0.00001'}{' '}
                  {secondToken?.symbol}
                </StyledExchange>
              </Grid>
              <StyledAdditionalSwapInfo
                container
                rowGap='8px'
              >
                <Grid
                  container
                  justifyContent='space-between'
                >
                  <Grid item>{t('expectedOutput')}</Grid>
                  <Grid item>
                    {props.isWrap || props.isUnwrap
                      ? isMobile
                        ? firstTokenValue.length > 10
                          ? firstTokenValue.slice(0, 10) + '...'
                          : firstTokenValue
                        : firstTokenValue.slice(0, 14)
                      : secondToken?.decimals !== 4 &&
                        secondToken?.amount.gt(
                          utils.parseUnits('0.00001', secondToken?.decimals)
                        )
                      ? roundNumberInSwap(Number(secondTokenValue))
                      : '<0.00001'}{' '}
                    {secondToken?.symbol}
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  justifyContent='space-between'
                >
                  <Grid item>{t('priceImpact')}</Grid>
                  <Grid item>{priceImpact} %</Grid>
                </Grid> */}
              </StyledAdditionalSwapInfo>
              <StyledMinimumReceived
                container
                justifyContent='space-between'
                alignItems='center'
                sx={{ paddingTop: isMobile ? '8px' : undefined }}
              >
                <Grid
                  item
                  maxWidth='165px'
                >
                  {t('minimumReceivedAfterSlippage')}{' '}
                  {`(${(
                    swapSettings?.slippage ?? new Percent(50, 10_000)
                  ).toFixed()}%)`}
                </Grid>
                <Grid item>
                  {props.isWrap || props.isUnwrap
                    ? firstTokenValue
                    : roundNumberInSwap(
                        Number(
                          route?.trade
                            .minimumAmountOut(
                              swapSettings?.slippage ?? new Percent(50, 10_000)
                            )
                            .toExact()
                        )
                      )}{' '}
                  {secondToken?.symbol}
                </Grid>
              </StyledMinimumReceived>
              <StyledEstimatedOutputInfo
                sx={{ margin: isMobile ? '8px 0px 16px 0px' : undefined }}
              >
                {t('swapResultDescriptionPart1')}{' '}
                <strong>
                  {props.isWrap || props.isUnwrap
                    ? firstTokenValue
                    : roundNumberInSwap(
                        Number(
                          route?.trade
                            .minimumAmountOut(
                              swapSettings?.slippage ?? new Percent(50, 10_000)
                            )
                            .toExact()
                        )
                      )}{' '}
                  {secondToken?.symbol}
                </strong>{' '}
                {t('swapResultDescriptionPart2')}
              </StyledEstimatedOutputInfo>
              {props.isWrap ? (
                <StyledButton
                  onClick={() => {
                    isWrap();
                    setIsOpen(true);
                    props.setIsOpen(false);
                  }}
                  sx={{ fontSize: isMobile ? TextSize.TABLE_SMALL : 'inherit' }}
                >
                  {t('confirmWrap')}
                </StyledButton>
              ) : props.isUnwrap ? (
                <StyledButton
                  onClick={() => {
                    isUnwrap();
                    setIsOpen(true);
                    props.setIsOpen(false);
                  }}
                >
                  {t('confirmUnwrap')}
                </StyledButton>
              ) : (
                <StyledButton
                  onClick={() => {
                    swap();
                    setIsOpen(true);
                    props.setIsOpen(false);
                    // clearInterval(props.intervalId);
                  }}
                >
                  {t('confirmSwap')}
                </StyledButton>
              )}
            </Grid>
          </StyledContent>
        </StyledDialog>
        <Dialog
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          <StyledContent style={{ padding: '20px 0' }}>
            <Stack
              alignItems='center'
              mt={'20px'}
              pt={'26px'}
            >
              <Loader />
            </Stack>
            <Grid
              container
              flexDirection='column'
              alignItems='center'
              rowGap='12px'
            >
              <StyledGridTitle item>
                {t('waitingForConfirmation')}
              </StyledGridTitle>
              <StyledGridSubtitle item>
                {props.isWrap
                  ? t('wrapping')
                  : props.isUnwrap
                  ? t('unwrapping')
                  : t('swapping')}{' '}
                {firstTokenValue} {firstToken?.symbol} {t('for')}{' '}
                {secondTokenValue} {secondToken?.symbol}
              </StyledGridSubtitle>
              <StyledGridInfo item>{t('confirmTransaction')}</StyledGridInfo>
            </Grid>
          </StyledContent>
        </Dialog>
      </ThemeProvider>
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          clearTokensValue={() => props.clearTokensValue()}
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
          clearTokensValue={() => props.clearTokensValue()}
        />
      )}
    </>
  );
};

export default ConfirmSwapModal;
