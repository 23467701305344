import { Button, styled } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const UploadAreaContainer = styled('div')({
  width: '100%',
  minHeight: '120px',
  borderRadius: BorderRadius.NORMAL,
});

export const TeamCardContainer = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '20px',
  gap: '16px',
  marginBottom: '10px',
});
export const StyledDescription = styled('p')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SUPER_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  margin: 0,
  marginBottom: '8px',
});
export const UploadArea = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '200px',
  height: '100%',
  borderRadius: BorderRadius.NORMAL,
  cursor: 'pointer',
  border: `2px dashed ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
});

export const UploadImage = styled('img')({
  width: '96px',
  height: '96px',
  marginBottom: '32px',
});

export const UploadText = styled('p')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  opacity: '0.6',
});

export const StyledError = styled('span')({
  color: Color.RED,
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  lineHeight: '160%',
});

export const StyledAddButton = styled(Button)(({ theme }) => ({
  padding: '8px',
  minWidth: 0,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.TABLE_SMALL,
  borderRadius: BorderRadius.SMALL,
}));
