import React from 'react';

const ArrowRed = () => {
  return (
    <svg
      width='11'
      height='7'
      viewBox='0 0 11 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_136_3757)'>
        <path
          d='M6.01018 5.96004L10.4599 1.51029C10.7749 1.19531 10.5518 0.656738 10.1064 0.656738H1.20687C0.761422 0.656738 0.538339 1.19531 0.853321 1.51029L5.30307 5.96004C5.49833 6.1553 5.81491 6.1553 6.01018 5.96004Z'
          fill='#FF3156'
        />
      </g>
      <defs>
        <clipPath id='clip0_136_3757'>
          <rect
            width='11'
            height='7'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRed;
