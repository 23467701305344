import React, { useState, useEffect } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';

import {
  PageWrap,
  RowContainer,
  TitleStyled,
  SortWrap,
  ButtonStyled,
  ClearTitle,
  NoTransactionWrap,
  NoTransactionTitle,
  StyledButton,
} from './styles';
import { FontFamily, TextSize } from 'helpers/themeStyles';
import PopularTokenCard from 'components/PopularTokenCard';
import BiggestTransactionsCard from 'components/BiggestTransactionsCard';
import HotNftCollections from 'components/HotNftCollections';
import { PageRoutes } from 'config/routes';
import { fetchTransactionsHistory } from 'config/api';
import { setClearAllTransactions } from 'state/transactions/slice';
import DefaultIcon from 'svg/DefaultIcon';
import ConfirmClearAllTransactionsPopup from 'components/ConfirmClearAllTransactionsPopup';
import { setIsOpen } from 'state/walletconnect/slice';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const is1320 = useMediaQuery(theme.breakpoints.down(1320));

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [transactions, setTransactions] = useState<Object[]>([]);
  const [mobileTransactions, setMobileTransactions] = useState<Object[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (address) {
      getAllTransactions();
    }
  }, [transactions?.length, address, page]);

  const getAllTransactions = async () => {
    try {
      //setLoading(true);

      fetchTransactionsHistory(`/transaction-history/`, {
        limit: 10,
        page: page,
        //@ts-ignore
        publicAddress: address,
      }).then((data) => {
        const transactionsState = data.data.docs;
        setTransactions(transactionsState);

        if (transactionsState?.length) {
          const mobileTransactionsState = transactionsState.filter(Boolean);
          setMobileTransactions(mobileTransactionsState);
          setTotalPages(data.data.totalPages);
        }

        // setLoading(false);
      });
    } catch (error) {
      console.error('ERROR', error);
      // setLoading(false);
    }
  };

  const clearAllTransactions = () => {
    dispatch(setClearAllTransactions(address));
    setTransactions([]);
  };

  return (
    <PageWrap>
      {isMobile && (
        <Typography
          sx={{ fontFamily: FontFamily.ZENDOTS, fontSize: TextSize.LARGE }}
          variant='h3'
        >
          {t('welcomeTitle')}
        </Typography>
      )}
      <RowContainer style={{ flexWrap: is1320 ? 'wrap' : 'nowrap' }}>
        <PopularTokenCard />
      </RowContainer>
      <RowContainer
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <TitleStyled>{t('hotHFTCollections')}</TitleStyled>
        <Link
          style={{ textDecoration: 'none' }}
          to={PageRoutes.NftCollections}
        >
          <ButtonStyled>{t('viewAllCollections')}</ButtonStyled>
        </Link>
      </RowContainer>

      <RowContainer>
        <HotNftCollections />
      </RowContainer>
      <RowContainer
        style={{
          marginTop: '30px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TitleStyled>{t('transactionHistory')}</TitleStyled>
        <SortWrap>
          {/* {<SortTitle>{t('sortBy')}:</SortTitle>} */}
          {transactions?.length && address ? (
            <ClearTitle onClick={() => setIsConfirmModalOpen(true)}>
              {t('clearAll')}
            </ClearTitle>
          ) : null}
        </SortWrap>
      </RowContainer>

      {address ? (
        transactions?.length ? (
          <BiggestTransactionsCard
            page={page}
            setPage={setPage}
            totalPages={totalPages!}
            transactions={transactions}
            mobileTransactions={mobileTransactions}
          />
        ) : (
          <NoTransactionWrap>
            <DefaultIcon />
            <NoTransactionTitle>{t('noTransaction')}</NoTransactionTitle>
          </NoTransactionWrap>
        )
      ) : (
        <NoTransactionWrap>
          <StyledButton
            variant='contained'
            onClick={() => dispatch(setIsOpen(true))}
          >
            {t('connectWallet')}
          </StyledButton>
        </NoTransactionWrap>
      )}
      <ConfirmClearAllTransactionsPopup
        openPopup={isConfirmModalOpen}
        setOpenPopup={setIsConfirmModalOpen}
        clearAllTransactions={clearAllTransactions}
      />
    </PageWrap>
  );
};

export default Home;
