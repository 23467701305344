import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { Pagination } from '@mui/material';

import ProjectItem from '../ProjectItem';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  currentProjectsSelector,
  projectsByOwnerSelector,
} from 'state/launchpad/selectors';
import { getCurrentProjectsThunk } from 'state/launchpad/thunks';
import launchpadSlice from 'state/launchpad/slice';
import { PageRoutes } from 'config/routes';
import {
  StyledButton,
  StyledCurrentProjectsList,
  StyledCurrentProjectsWrapper,
  StyledRowContainer,
  StyledTitle,
} from './styles';
import { Color } from 'helpers/themeStyles';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';

const CurrentProjects: React.FC = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const currentProjects = useAppSelector(currentProjectsSelector);
  const projectsByAddress = useSelector(projectsByOwnerSelector);

  const [page, setPage] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getCurrentProjectsThunk({ status: 'current', limit: 4, page: value })
    );
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const queryParams = { status: 'current', limit: 4, page: page };
    dispatch(getCurrentProjectsThunk(queryParams));
    return function cleanup() {
      dispatch(launchpadSlice.actions.resetCurrentProjects());
    };
  }, []);

  return (
    <StyledCurrentProjectsWrapper
      style={{ marginBottom: currentProjects?.docs.length ? '0' : '40px' }}
    >
      <StyledRowContainer>
        <StyledTitle variant='h3'>{t('lpCurrentProjectsTitle')}</StyledTitle>
        {!projectsByAddress?.docs.length && address && (
          <StyledButton to={PageRoutes.CreateProjectOnLaunchpad}>
            {t('createProject')}
          </StyledButton>
        )}
      </StyledRowContainer>
      <StyledCurrentProjectsList>
        {!!currentProjects?.docs.length &&
          currentProjects?.docs.map((project) => (
            <ProjectItem
              key={project._id}
              project={project}
            />
          ))}
      </StyledCurrentProjectsList>
      {currentProjects && currentProjects?.totalDocs > 4 && (
        <Pagination
          count={currentProjects?.totalPages ?? 1}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <CustomPaginationItem {...item} />}
          sx={{
            '& .MuiPagination-ul': {
              justifyContent: 'center',
              marginBottom: '24px',
            },
            '& .MuiButtonBase-root': {
              color: Color.WHITE,
            },
          }}
        />
      )}
    </StyledCurrentProjectsWrapper>
  );
};

export default CurrentProjects;
