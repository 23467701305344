export function roundPricesInV3(num: number) {
  const absNum = Math.abs(num);
  if (absNum >= 1) {
    return Number(num.toFixed(2)).toString();
  } else if (absNum >= 0.00001) {
    return Number(num.toFixed(7)).toString();
  } else {
    return '<0.00001';
  }
}
