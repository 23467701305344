import { PageRoutes } from 'config/routes';
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: ReactElement;
}

const MobileRoutes: React.FC<Props> = ({ children }) => {
  const isMobile = window.screen.width <= 430;
  return isMobile ? children : <Navigate to={PageRoutes.Home} />;
};

export default MobileRoutes;
