import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { Color } from 'helpers/themeStyles';
import { UserChatAvatars } from 'shared/avatars/getUserChatAvatars';
import {
  StyledMessage,
  StyledMessageAvatar,
  StyledMessageContainer,
  StyledMessageWrapper,
  StyledNickname,
  StyledTime,
} from './styles';
import { IMessage } from 'types/chat';

interface MessageProps {
  message?: IMessage;
  own: boolean;
}

export default function Message({ message, own }: MessageProps) {
  const formattedTime =
    message?.createdAt && format(new Date(message?.createdAt), 'HH:mm');
  const nickname = message?.nickname;
  return (
    <StyledMessageContainer
      sx={{
        alignItems: !own ? 'flex-start' : 'flex-end',
        justifyContent: !own ? 'flex-start' : 'flex-end',
      }}
    >
      <StyledMessageWrapper>
        <Stack
          sx={{
            alignItems: !own ? 'flex-start' : 'flex-end',
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
          >
            {!own && <StyledNickname>{nickname}</StyledNickname>}
            <StyledTime>{formattedTime}</StyledTime>
          </Stack>
          <StyledMessage
            sx={{
              background: !own
                ? Color.BORDER_WHITE_EXTRA_OPACITY
                : Color.DARK_PURPLE_OPACITY_LIGHT,
            }}
          >
            {message?.text}
          </StyledMessage>
        </Stack>
      </StyledMessageWrapper>
    </StyledMessageContainer>
  );
}
