import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import {
  StyledAddTokenButton,
  StyledButton,
  StyledContainer,
  StyledContentContainer,
  StyledDialog,
  StyledHeader,
  StyledTitle,
} from './styles';
import { Color } from 'helpers/themeStyles';

type ControlProps = {
  openPopup: boolean;
  setOpenPopup: (newValue: boolean) => void;
  clearAllTransactions: () => void;
};

const ConfirmClearAllTransactionsPopup = ({
  openPopup,
  setOpenPopup,
  clearAllTransactions,
}: ControlProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <StyledDialog
      onClose={handleClose}
      open={openPopup}
    >
      <StyledContainer sx={{ p: 2 }}>
        <StyledHeader sx={{ justifyContent: 'flex-end' }}>
          <IconButton sx={{ p: 0 }}>
            <Close
              sx={{ cursor: 'pointer', color: Color.WHITE }}
              onClick={handleClose}
            />
          </IconButton>
        </StyledHeader>
        <StyledContentContainer>
          <StyledTitle
            style={{
              marginBottom: '30px',
            }}
          >
            {t('deleteTransactionsHistory')}
          </StyledTitle>

          <StyledAddTokenButton
            onClick={() => {
              clearAllTransactions();
              setOpenPopup(false);
            }}
          >
            {t('clearAll')}
          </StyledAddTokenButton>

          <StyledButton
            variant='contained'
            fullWidth
            onClick={handleClose}
            sx={{
              marginTop: '20px',
            }}
          >
            {t('close')}
          </StyledButton>
        </StyledContentContainer>
      </StyledContainer>
    </StyledDialog>
  );
};

export default ConfirmClearAllTransactionsPopup;
