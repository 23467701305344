import { styled, Typography } from '@mui/material';
import { Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 700,
  color: Color.WHITE,
  marginBottom: '16px',
}));
