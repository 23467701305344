const NFTIcon = () => {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.84251 0.0850491L18.0926 4.4308C18.3427 4.56262 18.5 4.82678 18.5 5.1151L18.4998 14.8849C18.4998 15.1734 18.3427 15.4374 18.0926 15.5692L9.84251 19.9149C9.62764 20.0283 9.37234 20.0283 9.15732 19.9149L0.907198 15.5692C0.657297 15.4374 0.5 15.1734 0.5 14.8849V5.1151C0.5 4.82661 0.657123 4.56261 0.907198 4.4308L9.15732 0.0850491C9.37219 -0.0283497 9.62749 -0.0283497 9.84251 0.0850491ZM13.9999 8.46161C14.7102 8.46161 15.9196 9.32247 16.9999 10.2289L17.0001 5.58432L9.5 1.63386L1.99989 5.58432L1.99971 11.9425C3.0837 11.0018 4.1893 10.181 4.81154 10.0269C4.82599 10.0244 4.84056 10.0216 4.85511 10.0189C4.9052 10.0096 4.95516 10.0002 4.99988 10.0002C5.76998 10.0002 8.14234 11.5332 9.89862 12.953C10.1427 13.1499 10.4964 13.1082 10.6886 12.8568C10.8809 12.6069 10.8391 12.244 10.5951 12.047C10.4896 11.9617 10.1639 11.7015 9.71585 11.3658C10.9106 10.2445 12.9708 8.46161 13.9999 8.46161ZM8.37459 8.46135C9.41009 8.46135 10.2496 7.60031 10.2496 6.53825C10.2496 5.4762 9.41009 4.61515 8.37459 4.61515C7.33908 4.61515 6.49956 5.4762 6.49956 6.53825C6.49956 7.60031 7.33908 8.46135 8.37459 8.46135Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default NFTIcon;
