import { createSlice } from '@reduxjs/toolkit';
import {
  createProjectThunk,
  getCurrentProjectsThunk,
  getProjectByIdThunk,
  getUpcomingProjectsThunk,
  updateProjectThunk,
  getCompletedProjectsThunk,
  getProjectsByOwner,
  getUsersProjectByDay,
} from './thunks';
import { IPaginate } from 'types/pagination';
import { ProjectDetails } from 'types/project';
import { statement } from '@babel/template';

interface IState {
  upcomingProjects: IPaginate | null;
  currentProjects: IPaginate | null;
  completedProjects: IPaginate | null;
  newProjectId: string;
  projectDetails: ProjectDetails | undefined;
  projectsByOwner: IPaginate | null;
  loading: boolean | null;
  isLimited: boolean;
}

const initialState: IState = {
  upcomingProjects: null,
  currentProjects: null,
  completedProjects: null,
  newProjectId: '',
  projectDetails: undefined,
  projectsByOwner: null,
  loading: false,
  isLimited: false,
};

const launchpadSlice = createSlice({
  name: 'launchpad',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setUpcomingProjects: (state, action) => {
      state.upcomingProjects = action.payload.data ?? null;
    },
    resetUpcomingProjects: (state) => {
      state.upcomingProjects = initialState.upcomingProjects;
    },
    setCurrentProjects: (state, action) => {
      state.currentProjects = action.payload.data ?? null;
    },
    resetCurrentProjects: (state) => {
      state.currentProjects = initialState.currentProjects;
    },
    setCompletedProjects: (state, action) => {
      state.completedProjects = action.payload.data ?? null;
    },
    resetCompletedProjects: (state) => {
      state.completedProjects = initialState.completedProjects;
    },
    resetProjectDetails: (state) => {
      state.projectDetails = initialState.projectDetails;
    },
    setProjectId: (state, action) => {
      state.newProjectId = action.payload;
    },
    resetProjectId: (state) => {
      state.newProjectId = initialState.newProjectId;
    },
    resetProjectsByOwner: (state) => {
      state.projectsByOwner = initialState.projectsByOwner;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUpcomingProjectsThunk.fulfilled, (state, { payload }) => {
        state.upcomingProjects = payload;
      })
      .addCase(getCurrentProjectsThunk.fulfilled, (state, { payload }) => {
        state.currentProjects = payload;
      })
      .addCase(getCompletedProjectsThunk.fulfilled, (state, { payload }) => {
        state.completedProjects = payload;
      })
      .addCase(createProjectThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProjectThunk.fulfilled, (state, { payload }) => {
        localStorage.setItem('newProjectId', payload._id);
        state.newProjectId = payload._id;
        state.loading = false;
      })
      .addCase(updateProjectThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProjectThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProjectByIdThunk.fulfilled, (state, { payload }) => {
        state.projectDetails = payload;
      })
      .addCase(getProjectsByOwner.fulfilled, (state, { payload }) => {
        state.projectsByOwner = payload;
      })
      .addCase(getUsersProjectByDay.fulfilled, (state, { payload }) => {
        state.isLimited = payload.isLimited;
      }),
});

export default launchpadSlice;
