import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setNetwork } from 'state/network/slice';
import { setTokenList, setPopularTokens } from 'state/swap/slice';
import { BuyStyled } from 'components/NFTBuyModal/styles';
import { StyledDialog, StyledTitle } from './styles';
import { Color } from 'helpers/themeStyles';
import { useAccount, useSwitchChain } from 'wagmi';

const ChangeNetworkPopup = ({
  isOpen,
  onClose,
  chainId,
  unsetError,
  title,
  information,
}: {
  isOpen: boolean;
  onClose: () => void;
  chainId: number;
  unsetError: () => void;
  title: string;
  information?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { chain } = useAccount();
  const { switchChainAsync: hookSwitchNetwork } = useSwitchChain();
  const switchNetwork = async () => {
    try {
      await hookSwitchNetwork({ chainId: chainId });
      dispatch(setNetwork(chain));
      dispatch(setTokenList([]));
      dispatch(setPopularTokens([]));
      unsetError();
      onClose();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <StyledDialog
      open={isOpen}
      onClose={() => onClose()}
    >
      <StyledTitle>{t(title)}</StyledTitle>
      {!information ? (
        <BuyStyled
          onClick={() => switchNetwork()}
          style={{ justifyContent: 'center' }}
        >
          {t('switchNetwork')}
        </BuyStyled>
      ) : (
        <BuyStyled
          onClick={() => onClose()}
          style={{ width: '50%', margin: '0 auto', color: Color.WHITE }}
        >
          {t('iUnderstand')}
        </BuyStyled>
      )}
    </StyledDialog>
  );
};
export default ChangeNetworkPopup;
