const SwapMobileIcon = () => {
  return (
    <svg
      width='19'
      height='14'
      viewBox='0 0 19 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.5 6L0.5 10L4.5 14V11H11.5V9H4.5V6ZM18.5 4L14.5 0V3H7.5V5H14.5V8L18.5 4Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default SwapMobileIcon;
