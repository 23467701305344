import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

import { TextSize, Color } from 'helpers/themeStyles';
import {
  StyledLink,
  StyledLogo,
  StyledProjectContainer,
  StyledProjectHeader,
  StyledRowContainer,
  StyledText,
  StyledTitle,
} from './styles';
import { ProjectDetails } from 'types/project';

type Props = {
  id: string;
  logo: string;
  name: string;
  participants: string[];
  totalRaise: number;
  tokenPrice: number;
  endedIn: string;
  goal: string;
};

const CompletedProjectsItem: React.FC<Props> = ({
  id,
  logo,
  name,
  participants,
  totalRaise,
  tokenPrice,
  endedIn,
  goal,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <StyledLink to={`/launchpad/${id}`}>
      <StyledProjectContainer>
        <StyledProjectHeader>
          <StyledLogo src={logo} />
          <StyledTitle>{name}</StyledTitle>
        </StyledProjectHeader>
        <StyledRowContainer>
          <StyledText>{t('participants')}</StyledText>
          <StyledText
            style={{
              color: Color.WHITE,
              fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
            }}
          >
            {participants?.length}
          </StyledText>
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledText>{t('goal')}</StyledText>
          <StyledText
            style={{
              color: Color.WHITE,
              fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
            }}
          >
            ${goal}
          </StyledText>
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledText>{t('totalRaise')}</StyledText>
          <StyledText
            style={{
              color: Color.WHITE,
              fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
            }}
          >
            ${totalRaise}
          </StyledText>
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledText>{t('tokenPrice')}</StyledText>
          <StyledText
            style={{
              color: Color.WHITE,
              fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
            }}
          >
            ${tokenPrice}
          </StyledText>
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledText>{t('endedIn')}</StyledText>
          <StyledText
            style={{
              color: Color.WHITE,
              fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
            }}
          >
            {endedIn.split(' ')[0]}
            <StyledText
              style={{
                color: Color.WHITE,
                fontSize: isMobile ? TextSize.EXTRA_SMALL : TextSize.MIDDLE,
              }}
            >
              {` at ${endedIn.split(' ')[1]}`}
            </StyledText>
          </StyledText>
        </StyledRowContainer>
      </StyledProjectContainer>
    </StyledLink>
  );
};

export default CompletedProjectsItem;
