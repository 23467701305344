import React from 'react';

const DefaultIcon: React.FC = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38 0L10 0C7.34881 0.00317572 4.80712 1.05776 2.93244 2.93244C1.05776 4.80712 0.00317572 7.34881 0 10L0 38C0.00317572 40.6512 1.05776 43.1929 2.93244 45.0676C4.80712 46.9422 7.34881 47.9968 10 48L38 48C40.6512 47.9968 43.1929 46.9422 45.0676 45.0676C46.9422 43.1929 47.9968 40.6512 48 38L48 10C47.9968 7.34881 46.9422 4.80712 45.0676 2.93244C43.1929 1.05776 40.6512 0.00317572 38 0ZM44 38C44 39.5913 43.3679 41.1174 42.2426 42.2426C41.1174 43.3679 39.5913 44 38 44L10 44C8.4087 44 6.88258 43.3679 5.75736 42.2426C4.63214 41.1174 4 39.5913 4 38L4 10C4 8.4087 4.63214 6.88258 5.75736 5.75736C6.88258 4.63214 8.4087 4 10 4L38 4C39.5913 4 41.1174 4.63214 42.2426 5.75736C43.3679 6.88258 44 8.4087 44 10L44 38Z'
        fill='#FF73A0'
      />
      <path
        d='M14 20.0002C15.1867 20.0002 16.3467 19.6483 17.3334 18.989C18.3201 18.3297 19.0892 17.3926 19.5433 16.2963C19.9974 15.1999 20.1162 13.9935 19.8847 12.8296C19.6532 11.6658 19.0818 10.5967 18.2426 9.75754C17.4035 8.91843 16.3344 8.34699 15.1705 8.11547C14.0067 7.88396 12.8003 8.00278 11.7039 8.45691C10.6075 8.91103 9.67047 9.68007 9.01118 10.6668C8.35189 11.6535 8 12.8135 8 14.0002C8 15.5915 8.63214 17.1176 9.75736 18.2428C10.8826 19.368 12.4087 20.0002 14 20.0002ZM14 12.0002C14.3956 12.0002 14.7822 12.1175 15.1111 12.3372C15.44 12.557 15.6964 12.8694 15.8478 13.2348C15.9991 13.6003 16.0387 14.0024 15.9616 14.3904C15.8844 14.7783 15.6939 15.1347 15.4142 15.4144C15.1345 15.6941 14.7781 15.8846 14.3902 15.9618C14.0022 16.0389 13.6001 15.9993 13.2346 15.8479C12.8692 15.6966 12.5568 15.4402 12.3371 15.1113C12.1173 14.7824 12 14.3957 12 14.0002C12 13.4698 12.2107 12.961 12.5858 12.586C12.9609 12.2109 13.4696 12.0002 14 12.0002Z'
        fill='#FF73A0'
      />
      <path
        d='M33.4137 20.5859C33.2236 20.3877 32.993 20.2327 32.7376 20.1316C32.4822 20.0305 32.208 19.9856 31.9337 19.9999C31.6599 20.0096 31.3909 20.075 31.1433 20.1923C30.8957 20.3096 30.6746 20.4762 30.4937 20.6819L24.4337 27.5999L19.4137 22.5779C19.0386 22.203 18.53 21.9924 17.9997 21.9924C17.4693 21.9924 16.9607 22.203 16.5857 22.5779L8.58567 30.5779C8.39465 30.7624 8.24229 30.9831 8.13747 31.2271C8.03265 31.4711 7.97748 31.7336 7.97517 31.9991C7.97287 32.2647 8.02347 32.528 8.12403 32.7738C8.22459 33.0196 8.3731 33.2429 8.56088 33.4307C8.74867 33.6185 8.97197 33.767 9.21777 33.8676C9.46356 33.9681 9.72692 34.0187 9.99248 34.0164C10.258 34.0141 10.5205 33.959 10.7645 33.8541C11.0085 33.7493 11.2292 33.5969 11.4137 33.4059L17.9997 26.8279L21.7997 30.6279L16.4937 36.6819C16.1441 37.0813 15.9675 37.6033 16.0028 38.1329C16.0381 38.6625 16.2823 39.1564 16.6817 39.5059C17.0811 39.8555 17.603 40.0321 18.1326 39.9968C18.6622 39.9615 19.1561 39.7173 19.5057 39.3179L25.9417 31.9619H25.9537L25.9677 31.9399L32.0977 24.9399L36.5857 29.4279C36.9629 29.7922 37.4681 29.9938 37.9925 29.9893C38.5169 29.9847 39.0185 29.7744 39.3893 29.4036C39.7601 29.0328 39.9705 28.5311 39.975 28.0067C39.9796 27.4823 39.778 26.9771 39.4137 26.5999L33.4137 20.5859Z'
        fill='#FF73A0'
      />
    </svg>
  );
};

export default DefaultIcon;
