import { Stack, Typography, styled } from '@mui/material'; 
import { Link } from 'react-router-dom';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledCurrentProjectsWrapper = styled('div')({});

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
  lineHeight: '150%',

  [theme.breakpoints.down('mobile')]: {
    lineHeight: '110%',
    margin: '0 0 24px 0',
  },
}));

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}));

export const StyledButton = styled(Link)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  textDecoration: 'none',
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
  padding: '12px 24px',

  [theme.breakpoints.down('mobile')]: {
    margin: '0 0 24px 0',
    width: 'calc(100% - 48px)',
    textAlign: 'center'
  },
}));

export const StyledCurrentProjectsList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  flexWrap: 'wrap',
  marginTop: '24px',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    gap: '48px',
  },
  [theme.breakpoints.down('mobile')]: {
    marginTop: '0px',
    gap: '24px',
  },
}));