import * as React from 'react';
import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import {
  BottomNavigationButton,
  TapBarContainer,
  TapBarWrapper,
} from 'components/TapBar/styles';
import HomeIcon from 'svg/tapBar/HomeIcon';
import NFTIcon from 'svg/tapBar/NFTIcon';
import TradeIcon from 'svg/tapBar/TradeIcon';
import MenuIcon from 'svg/tapBar/MenuIcon';
import LiquidityIcon from 'svg/tapBar/LiquidityIcon';
import { PageRoutes } from 'config/routes';
import { openTopSwitchPanel } from 'state/swap/slice';

export default function TapBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [value, setValue] = React.useState('');
  const [pageURL, setPageURL] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLocation = window.location.href;
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const scrollToTop = () => {
    if (value === 'trade') {
      dispatch(openTopSwitchPanel(true));
    }
    navigate(`${pageURL}`);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    switch (value) {
      case 'home':
        navigate(`${PageRoutes.Home}`);
        setPageURL(PageRoutes.Home);
        break;
      case 'NFT':
        navigate(`${PageRoutes.Nft}`);
        setPageURL(PageRoutes.Nft);
        break;
      case 'trade':
        navigate(`${PageRoutes.Swap}`);
        setPageURL(PageRoutes.Swap);
        break;
      case 'sideMenu':
        navigate(`${PageRoutes.SideMenu}`);
        setPageURL(PageRoutes.SideMenu);
        break;
      case 'liquidity':
        navigate(`${PageRoutes.Liquidity}`);
        setPageURL(PageRoutes.Liquidity);
        break;
    }
  }, [value]);

  return (
    <>
      {isMobile && (
        <TapBarContainer onClick={scrollToTop}>
          <TapBarWrapper
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationButton
              value='home'
              icon={<HomeIcon />}
            />
            <BottomNavigationButton
              value='NFT'
              icon={<NFTIcon />}
              className={currentLocation.includes('nft') ? 'Mui-selected' : ''}
            />
            <BottomNavigationButton
              value='trade'
              icon={<TradeIcon />}
            />
            <BottomNavigationButton
              value='liquidity'
              className={
                currentLocation.includes('liquidity') ? 'Mui-selected' : ''
              }
              icon={<LiquidityIcon />}
            />
            <BottomNavigationButton
              value='sideMenu'
              icon={<MenuIcon />}
            />
          </TapBarWrapper>
        </TapBarContainer>
      )}
    </>
  );
}
