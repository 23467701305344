import {
  Button,
  Dialog,
  TextField,
  Switch,
  Stack,
  ListItem,
  DialogTitle,
  Typography,
  ListItemText,
  OutlinedInput,
  styled,
} from '@mui/material';
import {
  BorderRadius,
  Color,
  Border,
  FontFamily,
  TextSize,
  BoxShadow,
} from 'helpers/themeStyles';

export const LiquiditySettingsContainer = styled('div')(() => ({
  borderRadius: BorderRadius.SMALL,
  background: Color.DARK_PURPLE_OPACITY,
  padding: '6px',
  width: '20px',
  height: '21px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.LIGHT_PURPLE_BACKGROUND}, ${Color.LIGHT_PURPLE_BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 412,
    borderRadius: BorderRadius.SMALL,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,

    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      margin: 0,
    },
  },
}));

export const StyledDialogHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: Border.ACCENT_BORDER,
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
  padding: 16,
}));

export const StyledDialogContent = styled(Stack)(() => ({
  padding: '16px',
}));

export const StyledDialogContentTitle = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  color: Color.WHITE,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '6px 0',
  justifyContent: 'space-between',

  [theme.breakpoints.down('mobile')]: {
    padding: '12px 0',
  },
}));

export const StyledListItemText = styled(ListItemText)(() => ({
  '& .MuiTypography-root': {
    color: Color.WHITE,
    fontWeight: 400,
    fontSize: TextSize.SMALL,
    fontFamily: FontFamily.INTER,
  },
}));

export const StyledInfotipText = styled(Typography)(() => ({
  padding: 8,
  fontFamily: FontFamily.INTER,
}));

export const StyledSlippageToleranceBtn = styled(Button)(() => ({
  borderRadius: BorderRadius.HUGE,
  fontWeight: 700,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  border: `1px solid ${Color.WHITE_OPACITY}`,
  color: Color.WHITE,
  width: 55,
  height: 40,
}));

export const StyledLiquiditySettingsInput = styled(OutlinedInput)(() => ({
  borderRadius: BorderRadius.HUGE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  border: `1px solid ${Color.WHITE_OPACITY}`,
  '& .MuiInputBase-input': {
    width: 65,
    height: 40,
    padding: 0,
    textAlign: 'center',
    color: Color.WHITE,
    fontWeight: 700,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: BorderRadius.HUGE,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: Color.PURPLE,
    },
  },
  '& .MuiTypography-root': {
    color: Color.WHITE,
  },
}));

export const StyledPercentSymbol = styled('span')(() => ({
  marginBottom: '5px',
  fontWeight: 700,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
}));

export const StyledLiquidityDeadlineInput = styled(
  StyledLiquiditySettingsInput
)(({ theme }) => ({
  borderRadius: BorderRadius.SMALL,
  '& .MuiInputBase-input': {
    width: 86,
    textAlign: 'right',
    paddingRight: 8,

    [theme.breakpoints.down('mobile')]: {
      fontWeight: 400,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: BorderRadius.SMALL,
  },
}));

export const StyledLiquiditySwitch = styled(Switch)(({ theme }) => ({
  width: 77,
  height: 39,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(38px)',
      color: Color.PURPLE,
      '& + .MuiSwitch-track': {
        backgroundColor: Color.DARK_PURPLE_OPACITY_LIGHT,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 34,
    height: 34,
  },
  '& .MuiSwitch-track': {
    borderRadius: BorderRadius.HUGE,
    backgroundColor: Color.WHITE_OPACITY,
    opacity: 1,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.TEXT_GRADIENT,
  padding: 10,
  marginTop: 16,
}));
