import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  utils,
  tickToPrice,
  DEXToken,
  Percent,
  Pool,
  UniswapProvider,
} from '@omisoftnet/game-dex-sdk';
import { useAccount } from 'wagmi';
import { nearestUsableTick, Position } from '@uniswap/v3-sdk';

import {
  StyledCloseBtn,
  StyledDialog,
  StyledTitle,
  HeadingContainer,
  LiquidityPairContainer,
  LiquidityPair,
  StyledPositionItemStatus,
  StyledStatusIcon,
  StyledStatusTitle,
  MainContent,
  FeeTierContainer,
  TokenContainer,
  StyledPrice,
  SelectedRangeContainer,
  SelectedTitle,
  StyledSwitchPairButton,
  MaxPriceContainer,
  MinMaxStyledContainer,
  PriceTitle,
  PriceNumber,
  PriceDescription,
  StyledBtn,
} from './styles';
import { Color } from 'helpers/themeStyles';
import { liquiditySettingsSelector } from 'state/liquidity/selectors';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { dexSelector } from 'state/dex/selectors';
import isFirstTokenChoosen from 'helpers/isFirstTokenChoosen';
import { setTransactionHash } from 'state/transactions/slice';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import { MAX_TICK, MIN_TICK } from 'helpers/constants';
import { findNearestTeack } from 'helpers/findNearestTick';
import { roundPricesInV3 } from 'helpers/roundPricesInV3';

type AddLiquidityPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  fee: number;
  minPrice?: string;
  maxPrice?: string;
  currentPrice?: string;
  firstToken: DEXToken;
  secondToken: DEXToken;
  increase?: boolean;
  status?: string;
  isFullRange?: boolean;
  poolSpacing?: number;
  tickLower?: number;
  tickUpper?: number;
  tickCurrent?: number;
  positionId?: string;
  outOfRange?: boolean;
  newPool?: boolean;
  mockedPool?: Pool;
  clearTokensValue: () => void;
  deposit0Disabled: boolean;
  deposit1Disabled: boolean;
};

const AddLiquidityPopup = ({
  isOpen,
  onClose,
  fee,
  minPrice,
  maxPrice,
  currentPrice,
  increase,
  status = 'In Range',
  isFullRange,
  poolSpacing,
  firstToken,
  secondToken,
  tickLower,
  tickUpper,
  tickCurrent,
  positionId,
  outOfRange,
  newPool,
  mockedPool,
  clearTokensValue,
  deposit0Disabled,
  deposit1Disabled,
}: AddLiquidityPopupProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const liquiditySettings = useSelector(liquiditySettingsSelector);
  const dispatch = useDispatch();

  const dex = useSelector(dexSelector);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [token0, token1] = useMemo(
    () =>
      firstToken &&
      firstToken.symbol !== '!' &&
      secondToken &&
      secondToken.symbol !== '!' &&
      firstToken.wrapped.address !== secondToken.wrapped.address
        ? firstToken.wrapped.sortsBefore(secondToken.wrapped)
          ? [firstToken.wrapped, secondToken.wrapped]
          : [secondToken.wrapped, firstToken.wrapped]
        : [undefined, undefined],
    [firstToken, secondToken, firstToken.amount, secondToken.amount]
  );

  let isSorted = false;
  if (
    firstToken &&
    firstToken.symbol !== '!' &&
    secondToken &&
    secondToken.symbol !== '!'
  ) {
    isSorted =
      firstToken?.wrapped &&
      secondToken?.wrapped &&
      firstToken.wrapped.address !== secondToken.wrapped.address &&
      firstToken?.wrapped.sortsBefore(secondToken?.wrapped);
  }
  const minPriceToken0 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token0, token1, tickUpper).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickUpper &&
        tickToPrice(
          //@ts-ignore
          firstToken.wrapped,
          secondToken.wrapped,
          findNearestTeack(tickUpper)
        ).toFixed(7);
  const maxPriceToken0 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token0, token1, tickLower).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickLower &&
        tickToPrice(
          //@ts-ignore
          firstToken.wrapped,
          secondToken.wrapped,
          findNearestTeack(tickLower)
        ).toFixed(7);
  const maxPriceToken1 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token1, token0, tickUpper).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickUpper &&
        tickToPrice(
          //@ts-ignore
          secondToken.wrapped,
          firstToken.wrapped,
          findNearestTeack(tickUpper)
        ).toFixed(7);
  const minPriceToken1 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token1, token0, tickLower).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickLower &&
        tickToPrice(
          //@ts-ignore
          secondToken.wrapped,
          firstToken.wrapped,
          findNearestTeack(tickLower)
        ).toFixed(7);
  const currentPriceToken0 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token0, token1, tickCurrent).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickCurrent &&
        //@ts-ignore
        tickToPrice(
          //@ts-ignore
          firstToken.wrapped,
          secondToken.wrapped,
          tickCurrent
        ).toFixed(7);
  const currentPriceToken1 =
    increase && token0 && token1
      ? Number(
          //@ts-ignore
          tickToPrice(token1, token0, tickCurrent).toFixed(7)
        )
      : firstToken &&
        secondToken &&
        tickCurrent &&
        //@ts-ignore
        tickToPrice(
          //@ts-ignore
          secondToken.wrapped,
          firstToken.wrapped,
          tickCurrent
        ).toFixed(7);

  const [tokenAlignment, setTokenAlignment] = useState(firstToken.symbol);
  const handleTokenAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment === null && firstToken.symbol === tokenAlignment) {
      setTokenAlignment(secondToken.symbol);
    } else if (newAlignment === null && secondToken.symbol === tokenAlignment) {
      setTokenAlignment(firstToken.symbol);
    } else {
      setTokenAlignment(newAlignment);
    }
  };

  async function addLiquidity() {
    try {
      setOpenWaitingPopup(true);
      // firstToken!.amount = utils.parseUnits(depositIn, firstToken!.decimals);
      // secondToken!.amount = utils.parseUnits(depositOut, secondToken!.decimals);
      const parsedSlipage = Math.floor(
        Number.parseFloat(String(liquiditySettings.slippage)) * 100
      );
      if (newPool) {
        //@ts-ignore
        // mockedPool!.fee = fee * 10000;
        const tickLowerNear = nearestUsableTick(tickLower!, poolSpacing!);
        const tickUpperNear = nearestUsableTick(tickUpper!, poolSpacing!);
        const position = Position.fromAmounts({
          pool: mockedPool!,
          tickLower: tickUpperNear,
          tickUpper: tickLowerNear,
          amount0: token0!.amount.toHexString(),
          amount1: token1!.amount.toHexString(),
          useFullPrecision: true,
        });
        if (!dex) return;
        const tx = await dex.mintLiquidity(position, {
          deadline:
            Math.floor(Date.now() / 1000) +
            60 * Number(liquiditySettings.deadline),
          slipage: parsedSlipage,
          useNative: (firstToken?.isNative || secondToken?.isNative) ?? false,
          tickLower,
          tickUpper,
          createPool: true,
        });

        dispatch(
          setTransactionHash({
            hash: tx!.hash,
            chainId: token0!.chainId,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'addLiquidity',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: token0?.icon,
                },
                token1: {
                  ...token1,
                  logoURI: token1?.icon,
                },
                token0Amount: utils.formatUnits(
                  token0?.amount ?? '0',
                  token0?.decimals
                ),
                token1Amount: utils.formatUnits(
                  token1?.amount ?? '0',
                  token1?.decimals
                ),
              },
            },
          })
        );
      } else {
        if (!dex) return;
        const provider = await dex.getDEXProvider(token0!.chainId);
        if (!(provider instanceof UniswapProvider)) {
          throw new Error('Unsupported DEX');
        }
        const pool = await provider.getPoolFromContract(
          token0!.wrapped.address,
          token1!.wrapped.address,
          fee * 10000
        );

        const tickLowerNear = nearestUsableTick(tickLower!, poolSpacing!);
        const tickUpperNear = nearestUsableTick(tickUpper!, poolSpacing!);

        let position: Position;
        if (deposit0Disabled) {
          position = Position.fromAmount1({
            pool,
            tickLower: tickLowerNear,
            tickUpper: tickUpperNear,
            amount1: token0!.amount.toHexString(),
          });
        } else if (deposit1Disabled) {
          position = Position.fromAmount0({
            pool,
            tickLower: tickLowerNear,
            tickUpper: tickUpperNear,
            amount0: token1!.amount.toHexString(),
            useFullPrecision: true,
          });
        } else {
          const p = {
            pool,
            tickLower: isFullRange
              ? MIN_TICK.MIN_TICK
              : tickLowerNear < tickUpperNear
              ? tickLowerNear
              : tickUpperNear,
            tickUpper: isFullRange
              ? MAX_TICK.MAX_TICK
              : tickUpperNear > tickLowerNear
              ? tickUpperNear
              : tickLowerNear,
            amount0: token0!.amount.toHexString(),
            amount1: token1!.amount.toHexString(),
            useFullPrecision: false,
          };

          position = Position.fromAmounts(p);
        }
        let tx: any;
        if (positionId) {
          const provider = await dex.getDEXProvider(
            token0!.chainId,
            token0,
            token1
          );
          tx = await provider.addLiquidity(positionId, token0!, token1!, {
            // deadline: liquiditySettings.deadline, NOT UPDATED
            slipage: parsedSlipage,
            useNative: (firstToken?.isNative || secondToken?.isNative) ?? false,
            //@ts-ignore
            tickLower:
              (isFullRange
                ? MIN_TICK.MIN_TICK
                : tickLowerNear < tickUpperNear
                ? tickLowerNear
                : tickUpperNear) || 3600,
            tickUpper:
              (isFullRange
                ? MAX_TICK.MAX_TICK
                : tickUpperNear > tickLowerNear
                ? tickUpperNear
                : tickLowerNear) || 3600,
          });
        } else {
          if (!dex) return;
          tx = await dex.mintLiquidity(position, {
            // deadline: liquiditySettings.deadline,
            slipage: parsedSlipage,
            useNative: (firstToken?.isNative || secondToken?.isNative) ?? false,
            //@ts-ignore
            tickLower:
              (isFullRange
                ? MIN_TICK.MIN_TICK
                : tickLowerNear < tickUpperNear
                ? tickLowerNear
                : tickUpperNear) || 3600,
            tickUpper:
              (isFullRange
                ? MAX_TICK.MAX_TICK
                : tickUpperNear > tickLowerNear
                ? tickUpperNear
                : tickLowerNear) || 3600,
          });
        }

        dispatch(
          setTransactionHash({
            hash: tx!.hash,
            chainId: token0!.chainId,
            from: tx?.from,
            owner: address!,
            to: tx?.to,
            transaction_type: 'addLiquidity',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: firstToken?.icon,
                },
                token1: {
                  ...token1,
                  logoURI: secondToken?.icon,
                },
                token0Amount: utils.formatUnits(
                  token0?.amount ?? '0',
                  token0?.decimals
                ),
                token1Amount: utils.formatUnits(
                  token1?.amount ?? '0',
                  token1?.decimals
                ),
              },
            },
          })
        );
      }
      setTransactionSubmittedStatus(true);
      setOpenWaitingPopup(false);
    } catch (error) {
      setTransactionRejectedStatus(true);
      setOpenWaitingPopup(false);
      console.error(error); // for debug
    }
  }

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <HeadingContainer>
        <StyledTitle>{t('addLiquidity')}</StyledTitle>
        <StyledCloseBtn onClick={() => onClose()}>
          <CloseIcon />
        </StyledCloseBtn>
      </HeadingContainer>
      <MainContent>
        <LiquidityPairContainer>
          <LiquidityPair>
            {increase
              ? `${token0?.symbol}/${token1?.symbol}`
              : `${firstToken?.symbol}/${secondToken?.symbol}`}
          </LiquidityPair>
          <StyledPositionItemStatus>
            <StyledStatusIcon
              style={{
                background: outOfRange ? Color.LIGHT_RED : Color.GREEN,
                //status === 'In Range' ? Color.GREEN : Color.LIGHT_RED,
              }}
            />
            <StyledStatusTitle>
              {outOfRange ? `${t('outOfRange')}` : status}
            </StyledStatusTitle>
          </StyledPositionItemStatus>
        </LiquidityPairContainer>
        <FeeTierContainer>
          <TokenContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '6px',
              }}
            >
              <img
                src={token0?.icon}
                alt='token-icon'
                width='24'
                height='24'
              />
              <span>{token0?.symbol}</span>
            </div>
            <StyledPrice>
              {utils.formatUnits(token0?.amount ?? '0', token0?.decimals)}
            </StyledPrice>
          </TokenContainer>
          <TokenContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '6px',
              }}
            >
              <img
                src={token1?.icon}
                alt='token-icon'
                width='24'
                height='24'
              />
              <span>{token1?.symbol}</span>
            </div>
            <StyledPrice>
              {utils.formatUnits(token1?.amount ?? '0', token1?.decimals)}
            </StyledPrice>
          </TokenContainer>
          <TokenContainer>
            <span>{t('feeTier')}</span>
            <StyledPrice>{fee} %</StyledPrice>
          </TokenContainer>
        </FeeTierContainer>
        <SelectedRangeContainer>
          <SelectedTitle>{t('selectedRange')}</SelectedTitle>
          <ToggleButtonGroup
            value={tokenAlignment}
            onChange={handleTokenAlignment}
            exclusive
            aria-label='text alignment'
            sx={{
              color: Color.WHITE,
              '& .Mui-selected': {
                backgroundColor: `${Color.WHITE_OPACITY}!important`,
              },
            }}
          >
            <StyledSwitchPairButton value={token1?.symbol ?? ''}>
              {token1?.symbol}
            </StyledSwitchPairButton>
            <StyledSwitchPairButton value={token0?.symbol ?? ''}>
              {token0?.symbol}
            </StyledSwitchPairButton>
          </ToggleButtonGroup>
        </SelectedRangeContainer>
        <MinMaxStyledContainer>
          <MaxPriceContainer>
            <PriceTitle>{t('minPrice')}</PriceTitle>
            <PriceNumber>
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                  ? maxPriceToken1 === 0
                    ? 0
                    : Number(maxPriceToken1) > 10000000000
                    ? 0
                    : roundPricesInV3(Number(maxPriceToken1))
                  : maxPriceToken0 === 0
                  ? 0
                  : Number(maxPriceToken0) > 10000000000
                  ? '∞'
                  : roundPricesInV3(Number(maxPriceToken0))
                : isFullRange
                ? '0'
                : !isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                ? minPriceToken0 === 0
                  ? 0
                  : Number(minPriceToken0) > 10000000000
                  ? 0
                  : roundPricesInV3(Number(minPriceToken0))
                : minPriceToken1 === 0
                ? 0
                : Number(minPriceToken1) > 10000000000
                ? '∞'
                : roundPricesInV3(Number(minPriceToken1))}
            </PriceNumber>
            <PriceTitle>
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                  ? token0?.symbol!
                  : token1?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                ? firstToken?.symbol
                : secondToken?.symbol}{' '}
              per{' '}
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                  ? token1?.symbol!
                  : token0?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                ? secondToken?.symbol
                : firstToken?.symbol}
            </PriceTitle>
            <PriceDescription>
              {t('priceLiquidityDescription')}{' '}
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                  ? token0?.symbol!
                  : token1?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? firstToken?.symbol
                : secondToken?.symbol}{' '}
              {t('atThisPrice')}
            </PriceDescription>
          </MaxPriceContainer>
          <MaxPriceContainer>
            <PriceTitle>{t('maxPrice')}</PriceTitle>
            <PriceNumber>
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                  ? minPriceToken1 === 0
                    ? 0
                    : Number(minPriceToken1) > 10000000000
                    ? '∞'
                    : roundPricesInV3(Number(minPriceToken1))
                  : minPriceToken0 === 0
                  ? '∞'
                  : Number(minPriceToken0) > 10000000000
                  ? '∞'
                  : roundPricesInV3(Number(minPriceToken0))
                : isFullRange
                ? '∞'
                : !isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? maxPriceToken0 === 0
                  ? 0
                  : Number(maxPriceToken0) > 10000000000
                  ? '∞'
                  : roundPricesInV3(Number(maxPriceToken0))
                : maxPriceToken1 === 0
                ? '∞'
                : Number(maxPriceToken1) > 10000000000
                ? '∞'
                : roundPricesInV3(Number(maxPriceToken1))}
            </PriceNumber>
            <PriceTitle>
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                  ? token0?.symbol!
                  : token1?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                ? firstToken?.symbol
                : secondToken?.symbol}{' '}
              per{' '}
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!)
                  ? token1?.symbol!
                  : token0?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? secondToken?.symbol
                : firstToken?.symbol}
            </PriceTitle>
            <PriceDescription>
              {t('priceLiquidityDescription')}{' '}
              {increase
                ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                  ? token1?.symbol!
                  : token0?.symbol!
                : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? secondToken?.symbol
                : firstToken?.symbol}{' '}
              {t('atThisPrice')}
            </PriceDescription>
          </MaxPriceContainer>
        </MinMaxStyledContainer>
        <MaxPriceContainer>
          <PriceTitle>{t('currentPrice')}</PriceTitle>
          <PriceNumber>
            {increase
              ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? roundPricesInV3(Number(currentPriceToken1))
                : roundPricesInV3(Number(currentPriceToken0))
              : !isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
              ? roundPricesInV3(Number(currentPriceToken0))
              : roundPricesInV3(Number(currentPriceToken1))}
          </PriceNumber>
          <PriceTitle>
            {increase
              ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? token0?.symbol!
                : token1?.symbol!
              : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
              ? firstToken?.symbol!
              : secondToken?.symbol!}{' '}
            per{' '}
            {increase
              ? isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
                ? token1?.symbol!
                : token0?.symbol!
              : isFirstTokenChoosen(tokenAlignment!, token0?.symbol!!)
              ? secondToken?.symbol!
              : firstToken?.symbol!}
          </PriceTitle>
        </MaxPriceContainer>
        <StyledBtn onClick={() => addLiquidity()}>
          {t('addLiquidity')}
        </StyledBtn>
      </MainContent>
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={onClose}
          result={transactionStatuses.SUBMIT}
          clearTokensValue={clearTokensValue}
          navigateToPoolList
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={onClose}
          result={transactionStatuses.REJECT}
        />
      )}

      {openWaitingPopup && (
        <LiquidityWaitingPopup
          action={t('supplying')}
          firstTokenSymbol={!increase ? firstToken?.symbol! : token0?.symbol!!}
          secondTokenSymbol={
            !increase ? secondToken?.symbol! : token1?.symbol!!
          }
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          firstTokenValue={
            !increase
              ? utils.formatUnits(firstToken!.amount, firstToken!.decimals)
              : utils.formatUnits(token0!.amount, token0!.decimals)
          }
          secondTokenValue={
            !increase
              ? utils.formatUnits(secondToken!.amount, secondToken!.decimals)
              : utils.formatUnits(token1!.amount, token1?.decimals)
          }
        />
      )}
    </StyledDialog>
  );
};

export default AddLiquidityPopup;
