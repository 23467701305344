import { createSelector } from 'reselect';
import { RootState } from '..';

const DEXState = (state: RootState) => state.dex;
/**
 * @description State of DEX, please keep ONE instance within app
 */
export const dexSelector = createSelector(DEXState, ({ dex }) => dex);

export const signerSelector = createSelector(DEXState, ({ signer }) => signer);
