import {
  Tabs,
  Tab,
  Box,
  Button,
  CSSObject,
  styled,
  Accordion,
  AccordionDetails,
} from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from 'helpers/themeStyles';
import { Link } from 'react-router-dom';
import LoaderBanner from '../../../shared/demodexBanner.png';

interface ItemIndicatorProps {
  width?: number;
}

export const StyledLoaderContainer = styled('div')({
  backgroundImage: `url(${LoaderBanner})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
});
export const StyledPleaseWaitText = styled('span')({
  textAlign: 'center',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.EXTRA_HUGE,
  lineHeight: '150%',
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  backgroundClip: 'text',
  '-webkit-text-fill-color': 'transparent',
  marginBottom: '24px',
});
export const StyledContentIsUploadingText = styled('span')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
});
export const StyledProjectPageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));

export const ProjectNavBarWrapper = styled('div')({
  cursor: 'pointer',
  margin: 0,
  '&:not(:last-child)': {
    marginRight: '24px',
  },
});

export const StyledProjectDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    width: '100%',
  },

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));

export const StyledProjectFinInfoContainer = styled('div')(({ theme }) => ({
  display: 'block',
  width: '33%',

  [theme.breakpoints.down('xl')]: {
    width: '38%',
  },
}));

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '40px',
  color: Color.WHITE,
  margin: '20px 0px 32px',

  [theme.breakpoints.down('mobile')]: {
    columnGap: '20px',
  },
}));

export const StyledLogo = styled('img')({
  borderRadius: BorderRadius.SMALL,
});

export const StyledProjectName = styled('h1')(({ theme }) => ({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_HUGE,
  lineHeight: '150%',

  [theme.breakpoints.down('xl')]: {
    fontSize: TextSize.EXTRA_LARGE,
    lineHeight: '100%',
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MEDIUM,
    lineHeight: '100%',
  },
}));

export const StyledProjectDescription = styled('a')(({ theme }) => ({
  margin: 0,
  textDecoration: 'none',
  fontWeight: '400',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.PINK,

  [theme.breakpoints.down('xl')]: {
    fontSize: TextSize.SMALL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledProjectBanner = styled('div')(
  ({
    bannerLink,
    backgroundPosition,
    heignt,
  }: {
    bannerLink?: string;
    backgroundPosition?: string | boolean;
    heignt?: string;
  }) => ({
    position: 'relative',
    backgroundImage: `url(${bannerLink})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${heignt}`,
    width: '100%',
    borderRadius: BorderRadius.SMALL,
  })
);

export const StyledProjectChain = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '5px',
  padding: '12px',
  backgroundColor: Color.BACKGROUND_MAIN_WITH_OPACITY,
  borderRadius: BorderRadius.NORMAL,
});

export const StyledNetworkName = styled('p')({
  margin: 0,
  color: Color.WHITE,
});

export const SocialContainer = styled('div')({
  display: 'flex',
  margin: '20px 0px',
  color: Color.WHITE,
  alignItems: 'center',
});

export const SocialTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  marginRight: '20px',
});

export const SocialItems = styled('div')({
  display: 'flex',
  columnGap: '16px',
});

export const SocialItem = styled('a')({
  textDecoration: 'none',
  background: Color.DARK_PURPLE_OPACITY_MIDDLE,
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
});

export const StyledWebsiteLink = styled('a')({
  textDecoration: 'none',
  color: Color.LIGHT_RED,
});

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': { columnGap: '10px' },
});

export const StyledTab = styled(Tab)({
  background: Color.DARK_PURPLE_BACKGROUND,
  color: Color.WHITE,
  borderRadius: BorderRadius.NORMAL,
});

export const StyledTabButton = styled('div')({});

export const TokenSaleContainer = styled('div')(({ theme }) => ({
  backgroundColor: Color.DARK_PURPLE_BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '18px',

  [theme.breakpoints.down('mobile')]: {
    padding: '8px',
  },
}));

export const StyledRowContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const TokenDetailsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: TextSize.SMALL,
});
export const StyledFinancialInfoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 150,
  width: '100%',
  padding: '40px 24px',
  background: Color.DARK_PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SEMI_SMALL,
}));

export const StyledFinancialInfoLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TokenDetailsName = styled('span')(({ theme }) => ({
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const TokenDetailssValue = styled('span')(({ theme }) => ({
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledFinancialTitle = styled('h2')({
  margin: 0,
  marginBottom: '20px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,
});

export const StyledFinancialInfoGoal = styled('p')({
  margin: 0,
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledFinancialInfoLineLabel = styled('p')({
  margin: 0,
  fontWeight: 'bold',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
});

export const StyledFinancialInfoLineValue = styled('p')({
  margin: 0,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
});

export const ConnectWalletContainer = styled('div')({
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BUTTON_PROJECT_BACKGROUND}, ${Color.BUTTON_PROJECT_BACKGROUND}), ${Color.TEXT_GRADIENT}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
});

export const ConnectWalletButton = styled(Button)({
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  background: Color.TEXT_GRADIENT,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  width: '100%',
  border: '2px solid transparent',
  backgroundClip: 'text',
});

export const StyledChapter = styled('div')({
  borderBottom: `1px solid ${Color.BORDER_COLOR}`,
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  textTransform: 'uppercase',
  marginTop: '40px',
});
export const StyledHeadingChapter = styled('h3')({
  margin: '18px 0px',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
});
export const StyledSimpleText = styled('p')({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
  fontSize: TextSize.EXTRA_SMALL,
  margin: 0,
  marginBottom: '10px',
});
export const StyledTeamContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '18px',
});
export const StyledTeamCard = styled('div')(({ theme }) => ({
  background: Color.DARK_PURPLE_OPACITY,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '24px',
  width: '43%',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));

export const StyledTeamText = styled('p')(({ theme }) => ({
  marginBottom: '10px',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledLinkedInLink = styled('a')({
  textDecoration: 'none',
  color: Color.LINKEDIN_COLOR,
  fontFamily: FontFamily.INTER,
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: '10px',
  fontSize: TextSize.SMALL,
  cursor: 'pointer',
  fontWeight: '700',
});

export const StyledNavWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 18,
  paddingLeft: 0,
}));

export const StyledNavScrolledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  position: 'sticky',
  top: 50,
  zIndex: 100,
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '38px',
  padding: '30px 0',
}));

export const StyledNavItem = styled('p')(({ theme }) => ({
  position: 'relative',
  margin: '0 0 4px 0',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 'bold',
  color: Color.WHITE,

  [theme.breakpoints.down('xl')]: {
    fontSize: TextSize.NORMAL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.NORMAL,
  },
}));

export const ItemIndicator = styled('div')<ItemIndicatorProps>((props) => ({
  '::after': {
    content: '""',
    width: `${props.width}px`,
    height: '2px',
    background: Color.TEXT_GRADIENT,
    position: 'absolute',
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: '32px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND_MAIN}, ${Color.BACKGROUND_MAIN}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,
  padding: '16px',

  [theme.breakpoints.down('mobile')]: {
    marginTop: '0',
  },
}));

export const StyledAccordionTitle = styled(StyledChapter)({
  marginTop: 0,
  fontSize: TextSize.SMALL,
  paddingBottom: '4px',
});

export const StyledGradientTitle = styled(StyledAccordionTitle)({
  background: Color.TEXT_GRADIENT,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  background: Color.DARK_PURPLE_OPACITY,
  padding: '24px',
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  // width: '100%',
});

export const StyledBackBtnContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  textDecoration: 'none',

  [theme.breakpoints.down('mobile')]: {
    marginBottom: '12px',
  },
}));

export const StyledBackText = styled('span')(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.NORMAL,
  color: Color.WHITE,
  margin: 0,
}));

export const TokenSaleDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '40px',
  marginTop: '40px',
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    rowGap: '16px',
  },
}));
