import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/system';
import { DEX, TokenCache } from '@omisoftnet/game-dex-sdk';
import { useTranslation } from 'react-i18next';

import { PageRoutes } from 'config/routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { userSelector } from 'state/auth/selectors';
import { dexSelector } from 'state/dex/selectors';
import { LocalStorage } from 'utils/LocalStorage';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import NftMarketplace from './pages/NftMarketplace';
import Pool from './pages/Pool';
import Liquidity from 'pages/Liquidity';
import LaunchpadPage from 'pages/LaunchpadPage';
import CreateProjectWrapperComponent from 'components/Launchpad/CreateProject/CreateProjectWrapperComponent';
import NoMatch from './pages/NoMatch';
import NavBar from './components/NavBar';
import Header from './components/Header';
import Aside from './components/Aside';
import TapBar from 'components/TapBar';
import SideMenu from 'components/SideMenu';
import initLocalization from './i18n';
import ConnectWalletModal from 'components/ConnectWalletModal';
import Home from 'pages/Home';
import PoolDetails from 'components/PoolDetails';
import theme from 'helpers/themeStyles';
import MobileRoutes from 'helpers/onlyMobileRoutes';
import SwapModal from 'components/SwapModal';
import NftCollectionsList from 'components/NftCollectionsList';
import NftOverview from 'components/NftOverview';
import NftMyCollectionsAndNFTs from 'components/NftMyCollectionsAndNFTs';
import CreateNftCollection from 'components/CreateNftCollection';
import NftCollectionDetails from 'components/NftCollectionDetails';
import CreateNft from 'components/CreateNft';
import Messanger from 'components/Messanger';
import NftDetails from 'components/NftDetails';
import { API } from 'config/api';
import { API_ROUTES } from 'config/apiRoutes';
import { setFirstToken, setSecondToken, setTokenList } from 'state/swap/slice';
import ProjectPage from 'components/Launchpad/ProjectPage';
import LaunchpadOverview from 'components/Launchpad/LaunchpadOverview';
import LaunchpadMyProjects from 'components/Launchpad/LaunchpadMyProjects';
import LandscapeOrientationWarning from 'components/LandscapeOrientationWarning';
import MobileMetamaskInfo from 'components/Launchpad/MobileMetamaskInfo';
import { nativeTokens, stableTokens } from 'helpers/nativeTokens';
import { stableTokensForSwapUsdt } from 'helpers/stableTokens';
import ChangeNetworkPopup from 'components/ChangeNetworkPopup';
import { useSigner } from '@hooks/useSigner';
import { setSigner, setDex } from 'state/dex/slice';

initLocalization();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(userSelector);
  const token = LocalStorage.getAccessToken();
  const { isConnected, chain } = useAccount();
  const signer = useSigner({ chainId: chain?.id ?? 1 });
  useEffect(() => {
    console.log('signer', signer);
    if (signer) {
      console.log('signer', signer);
      dispatch(setSigner(signer));
      dispatch(setDex(new DEX(user, signer)));
    }
  }, [signer]);
  const dex = useSelector(dexSelector);
  const { t } = useTranslation();
  const [showLandscapeWarning, setShowLandscapeWarning] = useState(false);
  const [unsupportedChainWarning, setUnsupportedChainWarning] = useState(false);
  const [openMetamaskInfoPopup, setMetamaskInfoPopup] = React.useState(false);
  const suppChains = [1, 5, 56, 97];
  const isSupportedChain = suppChains.includes(chain?.id!);
  const getTokens = async () => {
    if (chain && isSupportedChain) {
      const { data } = await API.get(
        `${API_ROUTES.TOKENS.GET_TOKENS}?chainId=${chain?.id}&limit=80`
      );
      TokenCache.clear();
      TokenCache.addTokens(data.docs);
      const tokenList = TokenCache.iter();
      dispatch(setTokenList([...tokenList]));
    }
    if (!chain) {
      const { data } = await API.get(
        `${API_ROUTES.TOKENS.GET_TOKENS}?chainId=1&limit=256`
      );
      TokenCache.clear();
      TokenCache.addTokens(data.docs);
      const tokenList = TokenCache.iter();
      dispatch(setTokenList([...tokenList]));
    }
  };

  useEffect(() => {
    getTokens();
  }, [user, chain?.id]);

  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobileInLandscapeMode = window.innerWidth < 1000;
      const isLandscape = window.matchMedia('(orientation: landscape)').matches;
      if (isMobileInLandscapeMode && isLandscape) {
        setShowLandscapeWarning(true);
      } else {
        setShowLandscapeWarning(false);
      }
    };
    handleOrientationChange();
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addEventListener('change', handleOrientationChange);
    return () => {
      mediaQueryList.removeEventListener('change', handleOrientationChange);
    };
  }, []);
  useEffect(() => {
    if (chain && isSupportedChain) {
      dispatch(setFirstToken(nativeTokens[chain?.id || 1]));
      dispatch(setSecondToken(stableTokensForSwapUsdt[chain?.id || 1]));
    } else {
      dispatch(setFirstToken(nativeTokens[1]));
      dispatch(setSecondToken(stableTokensForSwapUsdt[1]));
    }
  }, [chain]);

  useEffect(() => {
    if (chain && !isSupportedChain) {
      setUnsupportedChainWarning(true);
    }
  }, [chain?.id]);
  // const queryClient = new QueryClient();

  return (
    <>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          {showLandscapeWarning ? (
            <LandscapeOrientationWarning />
          ) : (
            // <WagmiProvider config={dex.wallet.wagmi}>
            //   <QueryClientProvider client={queryClient}>
            <Router>
              <ConnectWalletModal setMobileWarning={setMetamaskInfoPopup} />
              <MobileMetamaskInfo
                isOpen={openMetamaskInfoPopup}
                onClose={() => {
                  setMetamaskInfoPopup(false);
                }}
              />
              <Header isConnected={isConnected} />
              <NavBar />
              <Aside />
              <main>
                <Routes>
                  <Route
                    path={PageRoutes.Pool}
                    element={<Pool />}
                  />
                  <Route
                    path={PageRoutes.Home}
                    element={<Home />}
                  />
                  <Route
                    path={PageRoutes.Swap}
                    element={
                      <MobileRoutes>
                        <SwapModal />
                      </MobileRoutes>
                    }
                  />
                  <Route
                    path={PageRoutes.Liquidity}
                    element={<Liquidity />}
                  />
                  <Route
                    path={PageRoutes.Nft}
                    element={
                      <NftMarketplace>
                        <NftOverview />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.Login}
                    element={
                      <Navigate
                        replace
                        to={PageRoutes.Swap}
                      />
                    }
                  />
                  <Route
                    path='editPool/:id'
                    element={<PoolDetails />}
                  />
                  <Route
                    path={PageRoutes.NftCollections}
                    element={
                      <NftMarketplace>
                        <NftCollectionsList />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.CreateNftCollection}
                    element={
                      <NftMarketplace>
                        <CreateNftCollection />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.CreateNft}
                    element={
                      <NftMarketplace>
                        <CreateNft />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.MyNfts}
                    element={
                      <NftMarketplace>
                        <NftMyCollectionsAndNFTs />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.NftCollection}
                    element={
                      <NftMarketplace>
                        <NftCollectionDetails />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.NftDetails}
                    element={
                      <NftMarketplace>
                        <NftDetails />
                      </NftMarketplace>
                    }
                  />
                  <Route
                    path={PageRoutes.Launchpad}
                    element={
                      <LaunchpadPage>
                        <LaunchpadOverview />
                      </LaunchpadPage>
                    }
                  />
                  <Route
                    path={PageRoutes.MyProjects}
                    element={
                      <LaunchpadPage>
                        <LaunchpadMyProjects />
                      </LaunchpadPage>
                    }
                  />
                  <Route
                    path={PageRoutes.CreateProjectOnLaunchpad}
                    element={
                      <LaunchpadPage>
                        <CreateProjectWrapperComponent />
                      </LaunchpadPage>
                    }
                  />
                  <Route
                    path={PageRoutes.LaunchpadProject}
                    element={
                      <LaunchpadPage>
                        <ProjectPage />
                      </LaunchpadPage>
                    }
                  />
                  <Route
                    path={PageRoutes.SideMenu}
                    element={
                      <MobileRoutes>
                        <SideMenu />
                      </MobileRoutes>
                    }
                  />
                  <Route
                    path={PageRoutes.Chat}
                    element={
                      <MobileRoutes>
                        <Messanger />
                      </MobileRoutes>
                    }
                  />
                  <Route
                    path='*'
                    element={<NoMatch />}
                  />
                </Routes>
                <TapBar />
                {unsupportedChainWarning && (
                  <ChangeNetworkPopup
                    isOpen={unsupportedChainWarning}
                    onClose={() => setUnsupportedChainWarning(false)}
                    chainId={1}
                    unsetError={() => {}}
                    title={t('unsupportedNetworkError', {
                      networkName: chain?.name,
                    })}
                  />
                )}
              </main>
            </Router>
            //   </QueryClientProvider>
            // </WagmiProvider>
          )}

          {/* <div style={{ zIndex: 200, position: 'relative' }}>
          <Web3Modal
            projectId={dex.wallet.walletconnectProjectId}
            enableNetworkView={false}
            themeMode={'dark'}
            desktopWallets={[]}
            // @ts-ignore This property exists! just test without and with
            enableStandaloneMode={true}
            ethereumClient={dex.wallet.ethereumClient}
          />
        </div> */}
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
