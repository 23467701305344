import {
  Accordion,
  AccordionSummary,
  Button,
  PaginationItem,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';
import { Link } from 'react-router-dom';

export const StyledHeader = styled(Paper)(() => ({
  marginBottom: '40px',
  padding: '16px',
  backgroundColor: Color.BACKGROUND,
  color: Color.WHITE,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND},${Color.BACKGROUND}), ${Color.TEXT_GRADIENT}`,
  boxShadow: BoxShadow.NFT_CARD_SHADOW,
}));
export const StyledHeroContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '32px',
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '420px',
    gap: '24px',
  },
}));
export const StyledContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '32px',
  [theme.breakpoints.between('mobile', 1439)]: {
    flexDirection: 'column',
    width: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '420px',
    gap: '24px',
  },
}));
export const StyledDetaliedStack = styled(Stack)(({ theme }) => ({
  width: '30%',
  [theme.breakpoints.between('mobile', 1439)]: {
    flexDirection: 'column',
    width: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));

export const StyledSaleEventsStack = styled(Stack)(({ theme }) => ({
  width: '70%',
  gap: '32px',
  [theme.breakpoints.down(1439)]: {
    width: '100%',
  },
}));
export const StyledNftNumber = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  minWidth: '32px',
  height: '29px',
  background: Color.NFT_COUNT,
  borderRadius: '6px',
  color: Color.WHITE,
  textAlign: 'center',
  justifyContent: 'center',
  right: '0',
  padding: '0 8px',
}));

export const StyledDetailsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '70%',
  paddingLeft: '58px',

  [theme.breakpoints.down('mobile')]: {
    padding: 0,
    width: '100%',
  },
}));

export const StyledCollectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.NORMAL,
  backgroundImage: Color.TEXT_GRADIENT,
  backgroundClip: 'text',
  textFillColor: 'transparent',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.LARGE,
  margin: '16px 0 32px',

  [theme.breakpoints.down('mobile')]: {
    fontFamily: FontFamily.ZENDOTS,
    fontWeight: 400,
    fontSize: TextSize.MEDIUM,
    margin: '12px 0 8px',
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,

  [theme.breakpoints.down('mobile')]: {
    fontWeight: 400,
  },
}));

export const StyledPrice = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  margin: '32px 0 16px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
    color: Color.TEXT_GRAY_SECONDARY,
  },
}));

export const StyledPriceValue = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledUSDPriceValue = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.TEXT_GREY,
}));

export const StyledSellBtn = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '18px 60px',
  background: Color.TEXT_GRADIENT,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));

export const StyledImg = styled('img')(() => ({
  minHeight: '100%',
  minWidth: '100%',
  objectFit: 'cover',
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: '8px',
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  padding: '16px 32px',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
}));

export const AccordionTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
}));

export const AccordionSubtitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
  color: Color.WHITE,
  marginTop: '8px',
}));

export const StyledPropertiesTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  lineHeight: 2.5,
  fontWeight: 400,
  textAlign: 'center',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledPropertiesValue = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
  color: Color.WHITE,
  fontWeight: 500,
}));

export const StyledDetailsValue = styled(StyledPropertiesValue)(() => ({
  textTransform: 'none',
}));

export const StyledMetadatasValue = styled(Link)(() => ({
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
  color: Color.WHITE,
  fontWeight: 500,
}));

export const StyledPropertiesPercent = styled('span')(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.TEXT_GREY,
  fontWeight: 400,
}));

export const StyledOwnerTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 600,
  padding: '0 16px 16px',
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
}));

export const StyledOwnerProperties = styled(StyledPropertiesTitle)(
  ({ theme }) => ({
    borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
    padding: '8px 16px ',
    color: Color.WHITE_OPACITY_LIGHT,
    fontSize: TextSize.SUPER_SMALL,
    textTransform: 'uppercase',

    textAlign: 'start',
  })
);

export const StyledPriceValueSmall = styled(StyledPriceValue)(() => ({
  fontSize: TextSize.NORMAL,
  padding: '8px 16px 0',
  display: 'flex',
}));

export const StyledUSDPriceValueSmall = styled(StyledUSDPriceValue)(() => ({
  fontSize: TextSize.SUPER_SMALL,
  padding: '0 16px ',
}));

export const StyledOwnerInfoContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px 0',
  flexGrow: 1,
}));

export const StyledOwnerAddress = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE,
}));

export const StyledBuyButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  textDecoration: 'none',
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
  padding: '12px 24px',
  textAlign: 'center',

  [theme.breakpoints.down('mobile')]: {
    margin: '24px 0',
  },
}));
export const BlackBuyStyled = styled(Button)(({ theme }) => ({
  marginRight: '8px',
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '18px 60px',
  display: 'flex',
  background: Color.BACKGROUND,
  border: `2px solid ${Color.PURPLE}`,
  alignItems: 'center',
  [theme.breakpoints.down(1300)]: {
    padding: '12px 30px',
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '10px 20px',
  },
}));
export const StyledTableCell = styled('th')(() => ({
  borderBottom: Border.ACCENT_BORDER,
  width: '20%',
}));

export const StyledTableTitle = styled(StyledPropertiesTitle)(() => ({
  color: Color.WHITE_OPACITY_LIGHT,
  padding: '8px 16px ',
  textTransform: 'uppercase',
  fontSize: TextSize.EXTRA_SMALL,
  textAlign: 'left',
}));

export const StyledTableText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
  textAlign: 'start',
  padding: '8px 16px',
}));

export const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  color: Color.BACKGROUND,
  '&.MuiButtonBase-root': {
    fontSize: TextSize.NORMAL,
    fontFamily: FontFamily.INTER,
    '&.Mui-selected': {
      fontWeight: 700,
      background: `${Color.DARK_PURPLE_OPACITY_MIDDLE}!important`,
      borderRadius: '6px!important',
    },
  },
}));

export const StyleDetailedBox = styled(Accordion)(() => ({
  '&:last-of-type': {
    borderRadius: BorderRadius.NORMAL,
  },
  '&:first-of-type': {
    borderRadius: BorderRadius.NORMAL,
  },
  // '&-MuiAccordionSummary-expandIconWrapper': {
  //   color: Color.WHITE,
  // },
  '& svg': {
    color: Color.WHITE,
  },
  background: Color.SWAP_GRADIENT,
  color: Color.WHITE,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  boxShadow: '0px 4px 99px rgba(0, 0, 0, 0.25)',
  borderRadius: BorderRadius.NORMAL,
}));

export const StyledOwnerBox = styled(Paper)(() => ({
  background: Color.SWAP_GRADIENT,
  color: Color.WHITE,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  boxShadow: '0px 4px 99px rgba(0, 0, 0, 0.25)',
  borderRadius: BorderRadius.NORMAL,
}));

export const StyledEventBox = styled(Paper)(() => ({
  background: Color.SWAP_GRADIENT,
  color: Color.WHITE,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  boxShadow: '0px 4px 99px rgba(0, 0, 0, 0.25)',
  borderRadius: BorderRadius.NORMAL,
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.Mui-expanded': {
    borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  },
}));
export const StyledPriceBlock = styled('div')(() => ({
  height: '64px',
  paddingTop: '11px',
}));

export const StyledPriceAndSellBtnStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'end',
  justifyContent: 'space-between',
  gap: '8px',
  marginBottom: '16px',
  [theme.breakpoints.down(1350)]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    alignItems: 'normal',
    marginBottom: '0px',
  },
}));

export const StyledNftDetailsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.between(1439, 1580)]: {
    flexDirection: 'column',
  },
}));

export const StyledNftPropertiesItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
