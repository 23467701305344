import { Color } from 'helpers/themeStyles';
import { styled } from '@mui/material';

export const StyledWrapper = styled('div')(() => ({
  color: 'white',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  overflowY: 'auto',
  borderRadius: '10px 10px 0 0',
  flexGrow: 1,
  position: 'relative',
  width: '100%',
}));
export const MainContainer = styled('div')(() => ({
  display: 'flex',
  height: 'calc(100vh - 64px)',
  background: Color.BACKGROUND,
}));
