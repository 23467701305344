import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledContainer,
  StyledTitle,
  Logo,
  StyledButton,
  StyledLink,
} from './styles';

const NoMatch: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <Logo
        src='https://res.cloudinary.com/dhk15xaeq/image/upload/v1668685614/Postil/uniswap-logo-782F5E6363-seeklogo.com_f4y5ht.png'
        alt='logo'
      />
      <Typography
        variant='h1'
        fontSize={48}
        fontWeight={700}
      >
        404
      </Typography>
      <StyledTitle variant='h4'>{t('pageNotFound')}</StyledTitle>
      <StyledButton>
        <StyledLink to='/'>{t('backHome')}</StyledLink>
      </StyledButton>
    </StyledContainer>
  );
};

export default NoMatch;
