import {
  InputBase,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledHeaderContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
}));

export const StyledSortBy = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 700,
  color: Color.WHITE_OPACITY_LIGHT,
}));

export const StyledInputBase = styled(InputBase)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
  width: 170,
  height: 40,
  borderRadius: BorderRadius.NORMAL,
  border: `1px solid ${Color.WHITE}`,
  padding: '0 16px',
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    fill: Color.WHITE,
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  '&.Mui-selected': {
    backgroundColor: `${Color.DARK_PURPLE_OPACITY_MIDDLE}!important`,
  },
}));

export const StyledResultsTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  margin: '16px 0',
  fontSize: TextSize.MIDDLE_PLUS,
}));

export const StyledContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
}));

export const NoNftContainer = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'center',
  background: Color.SWAP_GRADIENT,
  borderRadius: BorderRadius.NORMAL,
  padding: '40px 0px',
  rowGap: '32px',
});
export const NoNftsText = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
});

export const StyledSelect = styled(Select)({
  minWidth: '130px',
  padding: '0',
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND_MAIN}, ${Color.BACKGROUND_MAIN}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,
  '& .MuiSvgIcon-root': {
    color: Color.WHITE,
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.TABLE_SMALL,
    padding: '8px 12px',
  },
});

export const selectMenuListStyle: CSSProperties = {
  marginTop: '2px',
  borderRadius: BorderRadius.SMALL,
  border: Border.ACCENT_BORDER,
  color: Color.WHITE,
  padding: '0',
};

export const selectPaperStyle: CSSProperties = {
  background: Color.BACKGROUND_MAIN,
  borderRadius: BorderRadius.SMALL,
};
