import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  useAccount,
  useSwitchChain,
  useWaitForTransactionReceipt,
} from 'wagmi';
import { BigNumber } from 'ethers';
import {
  Pool as PancakePool,
  TickMath as TickMathPancake,
} from '@pancakeswap/v3-sdk';
import { Price } from '@uniswap/sdk-core';
import { TickMath } from '@uniswap/v3-sdk';
import { PageRoutes } from 'config/routes';
import {
  DEXToken,
  Pool,
  Position,
  ethers,
  priceToClosestTick,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useScroll from '@hooks/useScroll';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { renderToString } from 'react-dom/server';
import SummaryPopUp from './SummaryPopUp/SummaryPopUp';

import VideoPlayer from '../VideoPlayer';
import {
  StyledProjectPageWrapper,
  StyledProjectDetailsContainer,
  SocialContainer,
  SocialItem,
  SocialItems,
  StyledHeadingContainer,
  StyledLogo,
  StyledNetworkName,
  StyledProjectBanner,
  StyledProjectChain,
  StyledProjectDescription,
  StyledProjectName,
  TokenDetailsBlock,
  TokenDetailsName,
  TokenDetailssValue,
  StyledChapter,
  StyledHeadingChapter,
  StyledTeamContainer,
  StyledTeamCard,
  StyledTeamText,
  StyledLinkedInLink,
  StyledNavWrapper,
  StyledNavItem,
  ProjectNavBarWrapper,
  ItemIndicator,
  StyledAccordionTitle,
  StyledAccordionDetails,
  StyledRowContainer,
  StyledBackBtnContainer,
  StyledBackText,
  TokenSaleDetailsContainer,
  StyledLoaderContainer,
  StyledPleaseWaitText,
  StyledContentIsUploadingText,
} from './styles';
import style from './markdown-styles.module.css';
import { NetworkIcon } from 'components/NetworkSelector/styles';
import TwitterIcon from 'svg/TwitterIcon';
import TelegramIcon from 'svg/TelegramIcon';
import ArrowBackIcon from 'svg/ArrowBackIcon';
import { BorderRadius, Color } from 'helpers/themeStyles';
import { getProjectByIdThunk } from 'state/launchpad/thunks';
import {
  loadingSelector,
  newProjectIdSelector,
  projectDetailsSelector,
} from 'state/launchpad/selectors';
import { GitHub } from '@mui/icons-material';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import launchpadSlice from 'state/launchpad/slice';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { setTransactionHash } from 'state/transactions/slice';
import Loader from 'shared/Loader';
import InvestModal from '../InvestModal';
import ChangeNetworkPopup from 'components/ChangeNetworkPopup';
import { launchpadContracts } from 'config/contracts';
import { useCountdown } from '@hooks/useCountDown';
import { StyledIcon } from '../Preview/ProjectItem/styles';
import { stableTokens } from 'helpers/nativeTokens';
import NotFoundProjectLogoIcon from 'svg/NotFoundProjectLogoIcon';
import NotFoundProjectBannerIcon from 'svg/NotFoundProjectBannerIcon';
import { Launchpad } from '@abis/types';
import { useLaunchpadContract } from '@hooks/useContract';
import tryParseCurrencyAmount from 'helpers/tryParseCurrencyAmount';
import JSBI from 'jsbi';
import { StyledButton } from './SummaryPopUp/styles';
import PlatformCommissionInfoPopup from '../PlatformCommissionInfoPopup';
import WaitingPopup from '../WaitingPopup';
import { useSigner } from '@hooks/useSigner';

const ProjectPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [isOpen, setIsOpenInvest] = useState(false);
  const [isChangeNetworkOpen, setIsChangeNetworkOpen] = useState(false);
  const loading = useSelector(loadingSelector);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { isConnected } = useAccount();
  const location = useLocation();
  const project = useSelector(projectDetailsSelector);
  const projectIdRedux = useSelector(newProjectIdSelector);
  const { id } = useParams();
  const isOwner = localStorage.getItem('userId') === project?.owner;
  const navBarItems = [
    'Product',
    'Problem',
    'Tokenomics',
    'Roadmap',
    'Business Model',
    'Team',
    'Investors',
  ];

  const { chains } = useSwitchChain();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(navBarItems[0]);
  const {
    problem,
    product,
    businessmodel,
    tokenomics,
    roadmap,
    team,
    investors,
    scrollToAnchor,
  } = useScroll();
  const [openCommissionInfoPopup, setOpenCommissionInfoPopup] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const signer = useSigner();
  const { address, chain } = useAccount();
  const [firstToken, setFirstToken] = useState<DEXToken>();
  const [secondToken, setSecondToken] = useState<DEXToken>();
  const [depositAmount, setDepositAmount] = useState<BigNumber | undefined>();
  const [amounts, setAmountsDesired] = useState<[BigNumber, BigNumber]>();
  const [isWaitingPopupOpen, setIsWaitingPopupOpen] = useState(false);
  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const { isSuccess } = useWaitForTransactionReceipt({
    hash: txHash && (txHash as `0x${string}`),
  });
  useEffect(() => {
    if (isSuccess) {
      setIsWaitingPopupOpen(false);
      setTransactionSubmittedStatus(true);
    }
  }, [isSuccess]);
  const participants = useMemo(() => {
    return project?.tokenSaleDetails.participants.filter(
      (el) => el.address === address
    );
  }, [project]);
  useMemo(async () => {
    if (project?.tokenAddress && signer) {
      const firstToken = await DEXToken.fromBlockchain(
        project.tokenAddress,
        signer!
      );
      const secondToken = stableTokens[chain?.id!];
      setFirstToken(firstToken);
      setSecondToken(secondToken);
    }
  }, [project?.tokenAddress, signer, chain]);
  const [token0, token1] = useMemo(
    () =>
      firstToken &&
      firstToken.symbol !== '!' &&
      secondToken &&
      secondToken.symbol !== '!' &&
      firstToken.wrapped.address !== secondToken.wrapped.address
        ? firstToken.wrapped.into().sortsBefore(secondToken.wrapped.into())
          ? [firstToken.wrapped.into(), secondToken.wrapped.into()]
          : [secondToken.wrapped.into(), firstToken.wrapped.into()]
        : [undefined, undefined],
    [firstToken, secondToken]
  );
  const contract = useLaunchpadContract(project?.launchpadAddress);

  useMemo(async () => {
    const depositAmount = await contract?.toClaim();
    if (
      participants &&
      depositAmount?.eq(
        utils.parseUnits(
          String(participants[0].amount),
          stableTokens[project?.poolInformation.chain!].decimals
        )
      ) &&
      project?.poolInformation.chain === 1
    ) {
      const originalValue = participants[0].amount;
      let percentage = 0.02;
      let newValue = originalValue - originalValue * percentage;
      setDepositAmount(
        utils.parseUnits(
          String(newValue).slice(0, 8),
          stableTokens[project?.poolInformation.chain!].decimals
        )
      );
    } else {
      setDepositAmount(depositAmount);
    }
  }, [project, contract]);
  const currentSqrt = useMemo(() => {
    const invertPrice = Boolean(
      token0 && token0.wrapped.address !== firstToken?.wrapped.address
    );
    const parsedQuoteAmount = tryParseCurrencyAmount(
      project?.tokenSaleDetails.price,
      invertPrice ? token0?.wrapped : token1?.wrapped
    );
    if (
      parsedQuoteAmount &&
      token0 &&
      token1 &&
      depositAmount &&
      !depositAmount.isZero()
    ) {
      const baseAmount = tryParseCurrencyAmount(
        '1',
        invertPrice ? token1.wrapped : token0.wrapped
      );
      const price =
        baseAmount && parsedQuoteAmount
          ? new Price(
              baseAmount.currency,
              parsedQuoteAmount.currency,

              baseAmount.quotient,
              parsedQuoteAmount.quotient
            )
          : undefined;
      const truePrice = (invertPrice ? price?.invert() : price) ?? undefined;
      let sqrt;
      if (truePrice && signer) {
        const currentTick = priceToClosestTick(truePrice);
        const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
        sqrt = currentSqrt;
        const mockedPool = new Pool(
          token0.wrapped,
          token1.wrapped,
          100,
          currentSqrt,
          JSBI.BigInt(0),
          currentTick,
          []
        );
        const pancakePool = new PancakePool(
          //@ts-ignore
          token0.wrapped,
          token1.wrapped,
          100,
          currentSqrt,
          JSBI.BigInt(0),
          currentTick,
          []
        );
        const payAmount = utils
          .parseUnits(
            utils.formatUnits(depositAmount, pancakePool?.token1.decimals),
            stableTokens[project?.poolInformation.chain!].decimals
          )
          .toHexString();
        const MaxUint =
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
        const { amount0, amount1 } = Position.fromAmounts({
          //@ts-ignore
          pool: chain?.id === 1 || 5 ? mockedPool! : pancakePool,
          tickLower: -887270,
          tickUpper: 887270,
          amount0:
            token0.address === stableTokens[chain?.id!].address
              ? payAmount
              : MaxUint,
          amount1:
            token1.address === stableTokens[chain?.id!].address
              ? payAmount
              : MaxUint,
        });

        setAmountsDesired([
          utils.parseUnits(amount0.toFixed(), amount0.currency.decimals),
          utils.parseUnits(amount1.toFixed(), amount1.currency.decimals),
        ]);
      }

      return sqrt ?? undefined;
    }
  }, [
    token0,
    firstToken?.wrapped.address,
    project?.tokenSaleDetails.price,
    token1,
    signer,
    depositAmount,
  ]);
  const handleClaim = async () => {
    const contr = new ethers.Contract(
      project?.launchpadAddress!,
      launchpadContracts[chain?.id!].launchpadAbi,
      signer!
    ) as Launchpad;
    try {
      if (
        currentSqrt &&
        amounts?.length &&
        depositAmount &&
        !depositAmount.isZero()
      ) {
        setIsWaitingPopupOpen(true);

        const tx = await contr.functions.claim(
          currentSqrt?.toString(),
          token0?.address !== stableTokens[chain?.id!].address
            ? amounts[0]
            : amounts[1],
          token0?.address !== stableTokens[chain?.id!].address
            ? amounts[1]
            : amounts[0]
        );
        setTxHash(tx.hash as `0x${string}`);
        dispatch(setTransactionHash({ hash: tx.hash }));
      }
    } catch (err) {
      console.log(err);
      setIsWaitingPopupOpen(false);
      setTransactionRejectedStatus(true);
    }
  };
  const isTeamPresented =
    project?.teamMembers?.length! > 0 && project?.teamMembers[0].name !== '';

  const presentedNavBarItems = useMemo(() => {
    return navBarItems.filter((title) => {
      const data = project?.navigation.find(
        (item) => item.subtitle === title.replace(/\s/g, '')
      );
      return data && (data.details || data.picture.length > 0);
    });
  }, [project]);
  const youtubeRegExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const videoMatch = project?.videoLink.match(youtubeRegExp);
  const videoID =
    videoMatch && videoMatch[2].length === 11 ? videoMatch[2] : '';
  const svgString = renderToString(<NotFoundProjectBannerIcon />);
  const bannerUrl = `data:image/svg+xml;base64,${Buffer.from(
    svgString
  ).toString('base64')}`;

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  // disable scroll navBar
  // useEffect(() => {
  //   const element = document.querySelector('#nav-bar-anchor');
  //   window.addEventListener('scroll', () => {
  //     const elementRoof: any = element?.getBoundingClientRect()?.y;
  //     elementRoof <= 5 ? setNavBarRoof(true) : setNavBarRoof(false);
  //   });
  // });
  useEffect(() => {
    const projectId = location.pathname.includes('create')
      ? projectIdRedux
      : id;
    if (projectId && !loading) {
      dispatch(getProjectByIdThunk({ id: projectId }));
    }
  }, [dispatch, location.pathname, id, loading]);

  useEffect(() => {
    return () => {
      dispatch(launchpadSlice.actions.resetProjectDetails());
    };
  }, []);

  const handleClick = (i: string) => {
    setActiveTab(i);
  };
  const navItemRefs = useMemo(() => {
    if (isTeamPresented && presentedNavBarItems.includes('Business Model'))
      presentedNavBarItems.splice(5, 0, 'Team');
    if (isTeamPresented && !presentedNavBarItems.includes('Business Model'))
      presentedNavBarItems.splice(4, 0, 'Team');
    return presentedNavBarItems.map(() =>
      React.createRef<HTMLParagraphElement>()
    );
  }, [presentedNavBarItems]);

  const navBar = presentedNavBarItems.map((i, index) => {
    const isActive = i === activeTab;
    return (
      <ProjectNavBarWrapper
        onClick={() => {
          scrollToAnchor(i);
          handleClick(i);
        }}
        key={index}
      >
        <StyledNavItem ref={navItemRefs[index]}>{i}</StyledNavItem>
        {isActive && (
          <ItemIndicator
            width={navItemRefs[index].current?.getBoundingClientRect().width}
          />
        )}
      </ProjectNavBarWrapper>
    );
  });
  useCountdown(
    project?.poolInformation.launchDate,
    project?.status === 'current',
    project?._id!,
    project?.status
  );
  const minimumConsiderRise = useMemo(() => {
    let isMinimumConsider = false;
    if (
      project &&
      project.poolInformation.toRiseProgress &&
      project.poolInformation.lowestPercentageOfFundraisingGoal
    ) {
      const goal = Number(project?.poolInformation.toRise);
      const currentValue = project?.poolInformation.toRiseProgress;
      const percentage =
        project?.poolInformation.lowestPercentageOfFundraisingGoal;
      const howMuchBaksShouldBeMinimum = goal * (percentage / 100);
      if (currentValue >= howMuchBaksShouldBeMinimum) {
        isMinimumConsider = true;
      } else {
        isMinimumConsider = false;
      }
    }
    if (
      !project?.poolInformation.lowestPercentageOfFundraisingGoal &&
      project?.poolInformation.toRiseProgress &&
      project?.poolInformation.toRise
    ) {
      isMinimumConsider =
        project?.poolInformation.toRiseProgress >=
        Number(project?.poolInformation.toRise)
          ? true
          : false;
    }
    return isMinimumConsider;
  }, [
    project?.poolInformation.lowestPercentageOfFundraisingGoal,
    project?.poolInformation.toRise,
    project?.poolInformation.toRiseProgress,
  ]);
  const getMinAllocationWithPercent = useMemo(() => {
    if (project) {
      const originalValue = parseFloat(project.tokenSaleDetails.minAllocation);
      const percent = 0.02;
      let result = originalValue - originalValue * percent;
      const newValue = utils.parseUnits(
        String(result).slice(
          0,
          stableTokens[project.poolInformation.chain].decimals + 2
        ),
        stableTokens[project.poolInformation.chain].decimals
      );
      // const etherVal = utils.parseUnits(
      //   project.tokenSaleDetails.minAllocation,
      //   stableTokens[project.poolInformation.chain].decimals
      // );
      // const percentage = ethers.utils.parseUnits(
      //   LAUNCHPAD_COMMISSION,
      //   stableTokens[project.poolInformation.chain].decimals
      // ); // 2%
      // const percentageAmount = etherVal
      //   .mul(percentage)
      //   .div(ethers.constants.WeiPerEther);
      // const result = etherVal.sub(percentageAmount);
      return newValue;
    }
    return '0x0';
  }, [project]);
  return (
    <>
      {loading ? (
        <StyledLoaderContainer>
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={450}
          >
            <Loader />
            <StyledPleaseWaitText>
              {t('pleaseWaitForImageUpload')}
            </StyledPleaseWaitText>
            <StyledContentIsUploadingText>
              {t('contentUploading')}
            </StyledContentIsUploadingText>
          </Stack>
        </StyledLoaderContainer>
      ) : (
        <StyledProjectPageWrapper>
          <StyledProjectDetailsContainer>
            <StyledBackBtnContainer to={PageRoutes.Launchpad}>
              <ArrowBackIcon />
              <StyledBackText>{t('back')}</StyledBackText>
            </StyledBackBtnContainer>
            <StyledHeadingContainer>
              {project?.logo[0] ? (
                <StyledLogo
                  src={project?.logo[0] && formatIpfsUrl(project?.logo[0]!)}
                  width={isMobile ? '80px' : '100px'}
                  height={isMobile ? '80px' : '90px'}
                />
              ) : (
                <NotFoundProjectLogoIcon
                  width={isMobile ? '80px' : '100px'}
                  height={isMobile ? '80px' : '90px'}
                />
              )}
              <Stack
                justifyContent='space-between'
                width='100%'
              >
                <StyledProjectName>{project?.projectTitle}</StyledProjectName>
                <StyledProjectDescription
                  href={project?.socialMedia.website}
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  {/* @ts-ignore */}
                  {isMobile && project?.socialMedia?.website?.length > 31
                    ? project?.socialMedia.website.slice(0, 30) + '...'
                    : project?.socialMedia.website}
                </StyledProjectDescription>
              </Stack>
            </StyledHeadingContainer>
            <div id='nav-bar-anchor'></div>
            {!isMobile && (
              // !navBarRoof &&
              <Stack
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent='space-between'
                marginBottom='24px'
              >
                <StyledNavWrapper>{navBar}</StyledNavWrapper>{' '}
                {project?.status === 'closed' &&
                minimumConsiderRise &&
                participants?.length &&
                depositAmount?.gte(getMinAllocationWithPercent) &&
                isConnected ? (
                  <StyledButton
                    variant='outlined'
                    color='inherit'
                    onClick={() => {
                      chain?.id === project.poolInformation.chain
                        ? handleClaim()
                        : setIsChangeNetworkOpen(true);
                    }}
                  >
                    {t('claim')}
                  </StyledButton>
                ) : project?.status === 'closed' &&
                  !minimumConsiderRise &&
                  participants?.length &&
                  depositAmount?.gte(getMinAllocationWithPercent) &&
                  isConnected ? (
                  <StyledButton
                    variant='outlined'
                    color='inherit'
                    onClick={() => {
                      chain?.id === project.poolInformation.chain
                        ? handleClaim()
                        : setIsChangeNetworkOpen(true);
                    }}
                  >
                    {t('redeem')}
                  </StyledButton>
                ) : (
                  <>
                    {isConnected && project?.status === 'approved' && isOwner && (
                      <StyledButton
                        variant='outlined'
                        color='inherit'
                        onClick={() => {
                          chain?.id !== project.poolInformation.chain
                            ? setIsChangeNetworkOpen(true)
                            : setOpenCommissionInfoPopup(true);
                        }}
                      >
                        {t('deploy')}
                      </StyledButton>
                    )}
                    {project?.launchpadAddress &&
                      project.status !== 'closed' &&
                      project.status === 'current' &&
                      isConnected && (
                        <StyledButton
                          variant='outlined'
                          color='inherit'
                          onClick={() => {
                            chain?.id === project.poolInformation.chain
                              ? setIsOpenInvest(true)
                              : setIsChangeNetworkOpen(true);
                          }}
                        >
                          {t('invest')}
                        </StyledButton>
                      )}
                  </>
                )}
              </Stack>
            )}
            {/* {!isMobile && navBarRoof && (
              <StyledNavScrolledWrapper>{navBar}</StyledNavScrolledWrapper>
            )} */}
            {isMobile && (
              <Stack justifyContent='space-between'>
                <StyledNavWrapper>{navBar}</StyledNavWrapper>

                {project?.status === 'closed' &&
                minimumConsiderRise &&
                participants?.length &&
                depositAmount?.gte(
                  BigNumber.from(project?.tokenSaleDetails.minAllocation)
                ) &&
                isConnected ? (
                  <StyledButton
                    variant='outlined'
                    color='inherit'
                    onClick={() => {
                      chain?.id === project.poolInformation.chain
                        ? handleClaim()
                        : setIsChangeNetworkOpen(true);
                    }}
                  >
                    {t('claim')}
                  </StyledButton>
                ) : project?.status === 'closed' &&
                  !minimumConsiderRise &&
                  participants?.length &&
                  depositAmount?.gte(
                    BigNumber.from(project?.tokenSaleDetails.minAllocation)
                  ) &&
                  isConnected ? (
                  <StyledButton
                    variant='outlined'
                    color='inherit'
                    onClick={() => {
                      chain?.id === project.poolInformation.chain
                        ? handleClaim()
                        : setIsChangeNetworkOpen(true);
                    }}
                  >
                    {t('redeem')}
                  </StyledButton>
                ) : (
                  <>
                    {isConnected && project?.status === 'approved' && isOwner && (
                      <StyledButton
                        variant='outlined'
                        color='inherit'
                        onClick={() => {
                          chain?.id !== project.poolInformation.chain
                            ? setIsChangeNetworkOpen(true)
                            : setOpenCommissionInfoPopup(true);
                        }}
                      >
                        {t('deploy')}
                      </StyledButton>
                    )}
                    {project?.launchpadAddress &&
                      project.status !== 'closed' &&
                      project.status === 'current' &&
                      isConnected && (
                        <StyledButton
                          variant='outlined'
                          color='inherit'
                          onClick={() => {
                            chain?.id === project.poolInformation.chain
                              ? setIsOpenInvest(true)
                              : setIsChangeNetworkOpen(true);
                          }}
                        >
                          {t('invest')}
                        </StyledButton>
                      )}
                  </>
                )}
              </Stack>
            )}
            <StyledProjectBanner
              bannerLink={
                project?.heroPicture[0]
                  ? formatIpfsUrl(project?.heroPicture[0]!)
                  : bannerUrl
              }
              backgroundPosition={
                isMobile ? '50%' : Boolean(project?.heroPicture[0])
              }
              heignt={isMobile ? '200px' : '400px'}
            >
              <StyledProjectChain>
                <NetworkIcon
                  src={
                    [1, 5].includes(project?.poolInformation.chain!)
                      ? 'https://res.cloudinary.com/dhk15xaeq/image/upload/v1670343505/%D0%91%D0%B5%D0%B7_%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_vxjkle.png'
                      : 'https://pancakeswap.finance/images/chains/56.png'
                  }
                />
                <StyledNetworkName>
                  {
                    chains.filter(
                      (chain) => chain.id === project?.poolInformation.chain!
                    )[0]?.name
                  }
                </StyledNetworkName>
              </StyledProjectChain>
            </StyledProjectBanner>
            {/* {isMobile && (
              <SummaryPopUp
                project={project!}
                isConnected={isConnected}
                minimumConsiderRise={minimumConsiderRise}
                isOwner={isOwner}
                setIsChangeNetworkOpen={setIsChangeNetworkOpen}
                chainID={chain?.id}
                setIsOpenInvest={setIsOpenInvest}
              />
            )} */}
            <TokenSaleDetailsContainer>
              <SummaryPopUp
                project={project!}
                isConnected={isConnected}
                minimumConsiderRise={minimumConsiderRise}
                isOwner={isOwner}
                setIsChangeNetworkOpen={setIsChangeNetworkOpen}
                chainID={chain?.id}
                setIsOpenInvest={setIsOpenInvest}
              />
              <Stack
                rowGap='20px'
                flex='1'
              >
                <StyledAccordionTitle>
                  {t('tokenSaleDetails')}
                </StyledAccordionTitle>
                <StyledAccordionDetails>
                  <TokenDetailsBlock>
                    <TokenDetailsName>{t('salePrice')}</TokenDetailsName>
                    <StyledRowContainer>
                      <StyledIcon
                        src={
                          stableTokens[project?.poolInformation?.chain!]?.icon
                        }
                      />
                      <TokenDetailssValue>
                        {project?.tokenSaleDetails.price}
                      </TokenDetailssValue>
                    </StyledRowContainer>
                  </TokenDetailsBlock>
                  <TokenDetailsBlock>
                    <TokenDetailsName>
                      {t('tokenAllocationForSale')}
                    </TokenDetailsName>
                    <TokenDetailssValue>
                      {project?.tokenSaleDetails.allocation}
                    </TokenDetailssValue>
                  </TokenDetailsBlock>
                  <TokenDetailsBlock>
                    <TokenDetailsName>{t('minPerWallet')}</TokenDetailsName>
                    <StyledRowContainer>
                      <StyledIcon
                        src={
                          stableTokens[project?.poolInformation?.chain!]?.icon
                        }
                      />
                      <TokenDetailssValue>
                        {project?.tokenSaleDetails.minAllocation}
                      </TokenDetailssValue>
                    </StyledRowContainer>
                  </TokenDetailsBlock>
                  <TokenDetailsBlock>
                    <TokenDetailsName>{t('maxPerWallet')}</TokenDetailsName>
                    <StyledRowContainer>
                      <StyledIcon
                        src={
                          stableTokens[project?.poolInformation?.chain!]?.icon
                        }
                      />
                      <TokenDetailssValue>
                        {project?.tokenSaleDetails.maxAllocation}
                      </TokenDetailssValue>
                    </StyledRowContainer>
                  </TokenDetailsBlock>
                  <TokenDetailsBlock>
                    <TokenDetailsName>{t('totalTokenSupply')}</TokenDetailsName>
                    <TokenDetailssValue>
                      {project?.tokenSaleDetails.total_supply}
                    </TokenDetailssValue>
                  </TokenDetailsBlock>
                  {project?.poolInformation
                    .lowestPercentageOfFundraisingGoal && (
                    <TokenDetailsBlock>
                      <TokenDetailsName>
                        {t('minimumAmountForProjectSuccess')}
                      </TokenDetailsName>
                      <StyledRowContainer>
                        <StyledIcon
                          src={
                            stableTokens[project?.poolInformation?.chain!]?.icon
                          }
                        />
                        <TokenDetailssValue>
                          {Number(project?.poolInformation.toRise) *
                            (project?.poolInformation
                              .lowestPercentageOfFundraisingGoal /
                              100)}
                        </TokenDetailssValue>
                      </StyledRowContainer>
                    </TokenDetailsBlock>
                  )}
                </StyledAccordionDetails>
              </Stack>
            </TokenSaleDetailsContainer>
            <StyledChapter ref={product}>{t('lpSocialMedia')}</StyledChapter>
            <SocialContainer>
              <Stack
                flexDirection='row'
                alignItems='center'
              >
                <SocialItems>
                  {project?.socialMedia['twitter'] && (
                    <SocialItem
                      href={project?.socialMedia['twitter']}
                      rel='noreferrer noopener'
                      target='_blank'
                    >
                      <TwitterIcon />
                    </SocialItem>
                  )}
                  {project?.socialMedia['telegram'] && (
                    <SocialItem
                      href={project?.socialMedia['telegram']}
                      rel='noreferrer noopener'
                      target='_blank'
                    >
                      <TelegramIcon />
                    </SocialItem>
                  )}
                  {project?.socialMedia['github'] && (
                    <SocialItem
                      href={project?.socialMedia['github']}
                      rel='noreferrer noopener'
                      target='_blank'
                    >
                      <GitHub sx={{ fill: Color.WHITE }} />
                    </SocialItem>
                  )}
                </SocialItems>
              </Stack>
            </SocialContainer>
            {project?.videoLink && videoID && <VideoPlayer id={videoID} />}
            <StyledChapter ref={product}>{t('product')}</StyledChapter>
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[0]?.details!}
            </ReactMarkdown>
            {project?.navigation[0]?.picture[0] && (
              <img
                src={formatIpfsUrl(project?.navigation[0].picture[0])}
                style={{ borderRadius: BorderRadius.SMALL }}
                alt=''
              />
            )}
            <StyledChapter ref={problem}>{t('problem')}</StyledChapter>
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[1]?.details!}
            </ReactMarkdown>
            {project?.navigation[1]?.picture[0] && (
              <img
                src={formatIpfsUrl(project?.navigation[1].picture[0])}
                style={{ borderRadius: BorderRadius.SMALL }}
              />
            )}
            <StyledChapter ref={tokenomics}>{t('tokenomics')}</StyledChapter>
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[2]?.details!}
            </ReactMarkdown>
            {project?.navigation[2]?.picture[0] && (
              <img
                src={formatIpfsUrl(project?.navigation[2].picture[0])}
                style={{ borderRadius: BorderRadius.SMALL }}
              />
            )}
            <StyledChapter ref={roadmap}>{t('roadmap')}</StyledChapter>
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[3]?.details!}
            </ReactMarkdown>
            {project?.navigation[3]?.picture[0] && (
              <img
                src={formatIpfsUrl(project?.navigation[3]?.picture[0])}
                style={{ borderRadius: BorderRadius.SMALL }}
              />
            )}
            {(project?.navigation[4]?.details ||
              project?.navigation[4]?.picture[0]) && (
              <StyledChapter ref={businessmodel}>
                {t('businessModel')}
              </StyledChapter>
            )}
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[4]?.details!}
            </ReactMarkdown>
            {project?.navigation[4]?.picture[0] && (
              <img
                src={formatIpfsUrl(project?.navigation[4]?.picture[0])}
                style={{ borderRadius: BorderRadius.SMALL }}
              />
            )}
            {isTeamPresented && (
              <StyledChapter ref={team}>{t('team')}</StyledChapter>
            )}
            <StyledTeamContainer>
              {isTeamPresented &&
                project?.teamMembers?.map((item) => {
                  if (
                    item.name ||
                    item.position ||
                    item.about ||
                    item.linkedIn
                  ) {
                    return (
                      <StyledTeamCard>
                        {item.name && (
                          <StyledHeadingChapter>
                            {item.name}
                          </StyledHeadingChapter>
                        )}
                        {item.position && (
                          <StyledTeamText
                            style={{
                              color: Color.WHITE_OPACITY_LIGHT,
                              fontWeight: 700,
                            }}
                          >
                            {item.position}
                          </StyledTeamText>
                        )}
                        {item.about && (
                          <StyledTeamText>{item.about}</StyledTeamText>
                        )}
                        {item.linkedIn && (
                          <StyledLinkedInLink
                            href={item.linkedIn}
                            rel='noreferrer noopener'
                            target='_blank'
                          >
                            <LinkedInIcon sx={{ fill: Color.LINKEDIN_COLOR }} />{' '}
                            {t('linkedIn')}
                          </StyledLinkedInLink>
                        )}
                      </StyledTeamCard>
                    );
                  }
                  return null;
                })}
            </StyledTeamContainer>
            {(project?.navigation[5]?.details ||
              project?.navigation[5]?.picture[0]) && (
              <StyledChapter ref={investors}>{t('investors')}</StyledChapter>
            )}
            <ReactMarkdown className={style.reactMarkDown}>
              {project?.navigation[5]?.details!}
            </ReactMarkdown>
            {project?.navigation[5]?.picture[0] && (
              <img src={formatIpfsUrl(project?.navigation[5].picture[0])} />
            )}
          </StyledProjectDetailsContainer>
          {/* {!isMobile && (
            <SummaryPopUp
              project={project!}
              isConnected={isConnected}
              minimumConsiderRise={minimumConsiderRise}
              isOwner={isOwner}
              setIsChangeNetworkOpen={setIsChangeNetworkOpen}
              chainID={chain?.id}
              setIsOpenInvest={setIsOpenInvest}
            />
          )} */}
        </StyledProjectPageWrapper>
      )}
      <InvestModal
        isOpen={isOpen}
        onClose={() => setIsOpenInvest(false)}
      />
      <ChangeNetworkPopup
        isOpen={isChangeNetworkOpen}
        onClose={() => setIsChangeNetworkOpen(false)}
        chainId={project?.poolInformation.chain!}
        unsetError={() => {}}
        title='investSwitchNetwork'
      />
      <PlatformCommissionInfoPopup
        isOpen={openCommissionInfoPopup}
        onClose={() => setOpenCommissionInfoPopup(false)}
        project={project}
      />
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          clearTokensValue={() => {}}
          reloadPage={true}
          fromLaunchpad={true}
          tokenFromLaunchpad={firstToken}
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
          clearTokensValue={() => {}}
        />
      )}
      <WaitingPopup
        isOpen={isWaitingPopupOpen}
        onClose={() => {
          setIsWaitingPopupOpen(false);
        }}
      />
    </>
  );
};

export default ProjectPage;
