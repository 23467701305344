import { Dialog, Button, Stack } from '@mui/material';
import { styled } from '@mui/system';
import {
  Color,
  BorderRadius,
  BoxShadow,
  Border,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: Color.BACKGROUND,
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
    borderRadius: BorderRadius.SMALL,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '412px!important',
  },
});
export const StyledHeadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
});

export const StyledTitle = styled('h3')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
  marginBottom: '12px',
  textAlign: 'center',
});
export const StyledText = styled('p')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  lineHeight: '150%',
  textAlign: 'center',
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  marginBottom: '32px',
});
export const StyledBtnContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: '16px',
});

export const StyledBtn = styled(Button)({
  width: '100%',
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  padding: '16px 0px',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  color: Color.WHITE,
  flex: 1,
  textTransform: 'none',
});
