import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { NFTCollection } from '@omisoftnet/game-nft-sdk';
import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useWaitForTransactionReceipt } from 'wagmi';

import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { fetchOffer } from 'config/api';

import { useAsUsd } from '@hooks/useAsUsd';
import { useSigner } from '@hooks/useSigner';
import { NetworkIcon } from 'components/NetworkSelector/styles';
import { StyledSmallNoImage } from 'components/NftDetailsPopup/styles';
import NFT_MARKETPLACE from 'config/contracts';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import formatTokenBalance from 'helpers/formatTokenBalance';
import { makeShortTextLine } from 'helpers/makeShortTextLine';
import { Color } from 'helpers/themeStyles';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { dexSelector } from 'state/dex/selectors';
import { transactionHashSelector } from 'state/transactions/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import { setIsOpen } from 'state/walletconnect/slice';
import { SelectedNft } from 'types/nft';
import { Offer } from 'types/offer';
import {
  BlackBuyStyled,
  BuyStyled,
  CollectionPreview,
  PaymentCount,
  PaymentDetailsWrap,
  PaymentFiatValue,
  PaymentText,
  RowStyled,
  StyledCheckoutButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
  StyledImg,
  StyledValue,
  SubTitleStyled,
  TitleStyled,
  WarningTextStyled,
  WarningWrap,
} from './styles';

type PropTypes = {
  black: boolean;
  nftID: string;
  totalSupply: number;
  price: string;
  selectedNFT: SelectedNft;
  payableToken: string;
  offerFrom?: string | undefined;
};

export default function NFTBuyModal({
  black,
  nftID,
  totalSupply,
  price,
  selectedNFT,
  offerFrom,
}: PropTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [open, setOpen] = useState(false);
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [transactionSubmitted, setTransactionSubmitted] = useState(false);
  const [transactionRejected, setTransactionRejected] = useState(false);
  const [tokenAllowance, setTokenAllowance] = useState(false);
  const transactionHash = useSelector(transactionHashSelector);
  const [imgError, setImgError] = useState(false);
  const { isConnected, address } = useAccount();
  const signer = useSigner();
  const { chain } = useAccount();
  const dispatch = useDispatch();
  const ERC1155 = useMemo(() => {
    if (chain) {
      return NFT_MARKETPLACE[chain.id].contract;
    }
  }, [chain]);
  const NFTExchanger = useMemo(() => {
    if (chain) {
      return NFT_MARKETPLACE[chain.id].market;
    }
  }, [chain]);
  const nft = useMemo(() => {
    if (ERC1155 && NFTExchanger && signer) {
      return new NFTCollection(signer!, ERC1155, NFTExchanger);
    }
  }, [ERC1155, NFTExchanger, signer]);
  const [currentOffer, setCurrentOffer] = useState<Offer>();
  const [payableToken, setPayableToken] = useState<DEXToken>();
  const [currentUsd, setCurrentUsd] = useState<string | undefined>();
  const dex = useSelector(dexSelector);
  const { getValueInUsd } = useAsUsd();
  const { isLoading, isSuccess } = useWaitForTransactionReceipt({
    chainId: chain?.id,
    hash: transactionHash,
  });
  useEffect(() => {
    if (isSuccess) {
      setTokenAllowance(false);
    }
  }, [isSuccess]);
  const { data: tokenBalance } = useBalance({
    address,
    chainId: chain?.id,
    token: !payableToken?.isNative
      ? (payableToken?.address as `0x${string}`)
      : undefined,
  });
  const noEnoughBudget =
    Number(price && utils.formatUnits(`${price}`, payableToken?.decimals)) >
    Number(tokenBalance?.formatted);
  const getOffer = async () => {
    const res = await fetchOffer(`offers/nft/${nftID}`, { from: offerFrom });
    setCurrentOffer(res.data);
  };

  useEffect(() => {
    getOffer();
  }, [offerFrom, open]);

  const fetchToken = async () => {
    try {
      const token = await DEXToken.fromBlockchain(
        currentOffer?.payable_token!,
        signer!
      );
      setPayableToken(token);
      const allowance = await token.allowance(signer!, address!, NFTExchanger!);
      setTokenAllowance(allowance.sub(price).isNegative());
    } catch (e) {
      console.error(e);
    }
  };
  async function tokenApprove() {
    const tx = await payableToken?.approve(signer!, NFTExchanger!);
    dispatch(
      setTransactionHash({
        hash: tx?.hash,
        from: tx?.from,
        owner: address!,
        to: tx?.to,
        transaction_type: 'approve',
        data: {
          token_data: {
            token1: {
              ...payableToken,
              logoURI: payableToken?.icon,
            },
          },
        },
      })
    );
  }
  useEffect(() => {
    if (currentOffer && NFTExchanger) {
      fetchToken();
    }
  }, [currentOffer, NFTExchanger]);

  async function buy() {
    try {
      if (currentOffer && nft) {
        setOpenWaitingPopup(true);
        delete currentOffer.updatedAt;
        delete currentOffer.createdAt;
        delete currentOffer.__v;
        delete currentOffer.status;
        delete currentOffer.pay_amount_usd;
        const signature = currentOffer.signature;
        delete currentOffer.signature;
        const offer = {
          asset_amount: currentOffer.asset_amount,
          asset_id: currentOffer.asset_id,
          collection_contract: currentOffer.collection_contract,
          deadline: currentOffer.deadline,
          from: currentOffer.from,
          nft_id: currentOffer.nft_id,
          pay_amount: currentOffer.pay_amount,
          payable_token: currentOffer.payable_token,
          royalties_addreses: currentOffer.royalties_addreses,
          royalties_percent: [
            BigNumber.from(currentOffer.royalties_percent[0]).toHexString(),
          ],
          total_supply: BigNumber.from(String(totalSupply)).toHexString(),
        };

        const signatureRaw = {
          _r: signature?._r!,
          _s: signature?._s!,
          _v: signature?._v!,
        };
        const tx = await (await nft.sendOffer(offer, signatureRaw)).wait(1);

        dispatch(
          setTransactionHash({
            hash: tx.transactionHash,
            from: tx.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'buyNFT',
            data: {
              nft_data: {
                pay_amount: Number(
                  utils.formatUnits(
                    currentOffer.pay_amount,
                    payableToken?.decimals
                  )
                ),
                dollar_price: currentUsd,
                payable_token: currentOffer.payable_token,
                nft_image: formatIpfsUrl(selectedNFT?.nft.image[1]),
                nft_name: selectedNFT?.nft.name,
                nft_collection_name: selectedNFT?.nft.nft_collection_name,
                token_image: payableToken?.logoUri,
              },
            },
          })
        );
      }
      setOpenWaitingPopup(false);
      setTransactionSubmitted(true);
    } catch (error) {
      console.log(error);
      setOpenWaitingPopup(false);
      setTransactionRejected(true);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getCurrentUsd = async () => {
    if (payableToken && price) {
      payableToken.amount! = BigNumber.from(price);
      const usd = await getValueInUsd({
        token: payableToken,
        value: utils.formatUnits(payableToken.amount, payableToken.decimals),
      });
      setCurrentUsd(usd?.slice(0, 7));
    }
  };
  useEffect(() => {
    getCurrentUsd();
  }, [payableToken, price]);
  return (
    <>
      {black ? (
        <BlackBuyStyled
          onClick={handleClickOpen}
          disabled={!address}
          style={{
            color: !address ? Color.DARK_PURPLE : Color.PURPLE,
            borderColor: !address ? Color.DARK_PURPLE : Color.PURPLE,
          }}
        >
          {t('buy')}
        </BlackBuyStyled>
      ) : (
        <BuyStyled
          onClick={handleClickOpen}
          disabled={!address}
          style={{
            color: !address ? Color.WHITE_OPACITY_LIGHT : Color.WHITE,
          }}
        >
          {t('buy')}
        </BuyStyled>
      )}
      <StyledDialog
        onClose={handleClose}
        open={open}
      >
        <StyledDialogHeader>
          <StyledDialogTitle>{t('review')}</StyledDialogTitle>
          <Close
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
              pr: 3,
              color: 'white',
              '&:hover': { color: 'grey' },
            }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <CollectionPreview>
            {selectedNFT?.nft.image && !imgError ? (
              <StyledImg
                src={formatIpfsUrl(selectedNFT?.nft.image[0])}
                alt='nft image'
                onError={() => setImgError(true)}
              />
            ) : (
              <StyledSmallNoImage />
            )}
            <div>
              <SubTitleStyled>
                {selectedNFT?.nft.nft_collection_name}
              </SubTitleStyled>
              <TitleStyled>
                {makeShortTextLine(selectedNFT?.nft.name)}
              </TitleStyled>
              <SubTitleStyled>
                {t('token')} ID {''}
                <StyledValue>{selectedNFT?.nft.token_id}</StyledValue>
              </SubTitleStyled>
            </div>
          </CollectionPreview>
          <PaymentDetailsWrap>
            <RowStyled>
              <PaymentText>{t('payWith')}</PaymentText>
              <TitleStyled>
                <NetworkIcon src={payableToken?.icon} />
                {payableToken?.symbol}
              </TitleStyled>
            </RowStyled>
            <RowStyled>
              <PaymentText>{t('totalPayment')}</PaymentText>
              <div style={{ justifyContent: 'flex-end' }}>
                <RowStyled style={{ margin: 0, justifyContent: 'end' }}>
                  <TitleStyled>
                    {price
                      ? utils.formatUnits(`${price}`, payableToken?.decimals)
                      : '-'}
                  </TitleStyled>
                </RowStyled>
                <PaymentFiatValue>{`(~${currentUsd} USD)`}</PaymentFiatValue>
              </div>
            </RowStyled>
            <RowStyled>
              <PaymentText>
                {t('inWallet', { tokenName: payableToken?.symbol })}
              </PaymentText>
              <PaymentCount>
                {isConnected ? (
                  <RowStyled style={{ margin: 0 }}>
                    <TitleStyled>
                      {tokenBalance &&
                        payableToken &&
                        formatTokenBalance(
                          tokenBalance.value,
                          payableToken?.decimals
                        )}
                    </TitleStyled>
                  </RowStyled>
                ) : (
                  <Button
                    variant='outlined'
                    color='inherit'
                    onClick={() => {
                      dispatch(setIsOpen(true));
                    }}
                  >
                    {t('connectWallet')}
                  </Button>
                )}
              </PaymentCount>
            </RowStyled>
          </PaymentDetailsWrap>
          {noEnoughBudget && isConnected && (
            <WarningWrap>
              <ErrorOutlineIcon sx={{ color: 'red' }} />
              <WarningTextStyled>
                {t('noEnoughTokens', { tokenName: payableToken?.symbol })}
              </WarningTextStyled>
            </WarningWrap>
          )}
          {tokenAllowance && isLoading ? (
            <StyledCheckoutButton
              variant='contained'
              disabled
            >
              {t('waitForApprove')}
            </StyledCheckoutButton>
          ) : tokenAllowance ? (
            <StyledCheckoutButton
              variant='contained'
              onClick={() => {
                tokenApprove();
              }}
            >
              {t('approveToken')}
            </StyledCheckoutButton>
          ) : (
            <StyledCheckoutButton
              variant='contained'
              disabled={noEnoughBudget && isConnected}
              onClick={() => {
                buy();
              }}
            >
              {t('checkout')}
            </StyledCheckoutButton>
          )}
        </StyledDialogContent>
      </StyledDialog>
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          fromNFT
        />
      )}
      {transactionSubmitted && (
        <TransactionResultPopup
          openPopup={transactionSubmitted}
          setOpenPopup={setTransactionSubmitted}
          closeParentPopup={handleClose}
          result={transactionStatuses.SUBMIT}
          reloadPage
        />
      )}
      {transactionRejected && (
        <TransactionResultPopup
          openPopup={transactionRejected}
          setOpenPopup={setTransactionRejected}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
}
