import { createSelector } from 'reselect';
import { RootState } from '..';

const nftState = (state: RootState) => state.nft;
/**
 * @description Get data of selected NFT
 */
export const selectedNftSelector = createSelector(
  nftState,
  ({ selectedNft }) => selectedNft
);
