import { Button, Dialog, Stack, styled, ToggleButton } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '531px',
    background:
      'linear-gradient(180deg, rgb(44, 31, 73) 0%, rgb(94, 39, 120) 100%)',
    borderRadius: BorderRadius.NORMAL,
    boxShadow:
      '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 12px rgba(0, 0, 0, 0.86)',
    paddingBottom: '16px',

    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      border: 'none',
      margin: 0,
      marginTop: '64px',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
    },
  },
}));
export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '22.5px 21px',
  borderBottom: ` 1px solid ${Color.DARK_PURPLE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    borderBottom: Border.ACCENT_BORDER,
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: '16px',
});
export const StyledDialogTitle = styled('h6')({
  fontFamily: FontFamily.INTER,
  fontWeight: '700',
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,
});
export const LiquidityPairContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0px',
});

export const LiquidityPair = styled('span')({
  lineHeight: '160%',
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  color: Color.WHITE,
});
export const StyledPositionItemStatus = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));
export const StyledStatusIcon = styled('div')(() => ({
  width: '8px',
  height: '8px',
  borderRadius: BorderRadius.HUGE,
}));
export const StyledStatusTitle = styled('p')(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
}));
export const MainContent = styled('div')({
  padding: '0px 16px',
});
export const FeeTierContainer = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  borderRadius: BorderRadius.SMALL,
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
  marginBottom: '13px',
});
export const TokenContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
});
export const StyledPrice = styled('span')({
  fontWeight: '600',
});

export const SelectedRangeContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const SelectedTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: '700',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
});

export const StyledSwitchPairButton = styled(ToggleButton)({
  padding: '4px 8px',
  display: 'block',
  minWidth: '40px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SUPER_SMALL,
  lineHeight: '160%',
  fontWeight: '400',
  color: Color.WHITE,
  backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  ':hover': {
    backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  },
});

export const MinMaxStyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '12px',
  marginTop: '10px',
  marginBottom: '12px',
});

export const MaxPriceContainer = styled('div')({
  padding: 12,
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  borderRadius: BorderRadius.NORMAL,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: Color.BACKGROUND,
});

export const PriceTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  color: Color.WHITE_OPACITY_LIGHT,
});
export const PriceNumber = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
});
export const PriceDescription = styled('span')({
  fontFamily: FontFamily.INTER,
  fontSize: '11px',
  lineHeight: '150%',
  color: Color.WHITE_OPACITY_LIGHT,
  textAlign: 'center',
  marginTop: '12px',
});

export const StyledBtn = styled(Button)({
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  marginTop: '16px',
  padding: '16px 0px',
});

export const RowContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  marginBottom: '10px',
});

export const RowContainerItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledTokenName = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  marginLeft: '8px',
});
