const SendIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.5218 10.5102L1.89546 0.139475C0.85838 -0.321448 -0.293928 0.600399 0.166995 1.63748L3.04777 9.35794L16.1841 11.5473L3.04777 13.7367L0.166995 21.4572C-0.178697 22.4943 0.85838 23.4161 1.89546 22.8399L22.5218 12.4692C23.3284 12.1235 23.3284 10.9712 22.5218 10.5102Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default SendIcon;
