import { DEXToken, Percent, TradeType, utils } from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance } from 'wagmi';

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RevertIcon from 'svg/RevertIcon';

import {
  AccordionTitleStyled,
  ButtonWrapper,
  ExchangeDetailsItem,
  ExchangeDetailsWrap,
  ExchangePrice,
  ExchangeTitle,
  RevertButton,
  RevertButtonContainer,
  StyledAccordion,
  StyledButton,
  StyledButtonConnect,
  StyledContainer,
  StyledHeader,
  StyledInput,
  StyledInputBase,
  StyledPaper,
  StyledSettings,
  StyledTitle,
} from './styles';

import { useAllowance } from '@hooks/useAllowance';
import { useUniversalRouter } from '@hooks/useContract';
import { SwapType } from '@uniswap/smart-order-router';
import ConfirmSwapModal from 'components/ConfirmSwapModal';
import TopSwitchPanel from 'components/TopSwitchPanel';
import formatTokenBalance from 'helpers/formatTokenBalance';
import formatTokenValueInSwap from 'helpers/formatTokenValueInSwap';
import { roundNumberInSwap } from 'helpers/roundNumberInSwap';
import { Color, FontFamily } from 'helpers/themeStyles';
import useDebounce from '@hooks/useDebounce';
import { rejectPromise, useQuote } from '@hooks/useQuote';
import { ExpandMore } from 'shared/ExpandMoreAndLess';
import {
  firstTokenSelector,
  isDataLoadingSelected,
  openTopSwitchPanelSelector,
  secondTokenSelector,
  swapSettingsSelector,
} from 'state/swap/selectors';
import {
  setFirstToken,
  setIsDataLoading,
  setSecondToken,
  switchTokens,
} from 'state/swap/slice';
import { setIsOpen } from 'state/walletconnect/slice';
import SwapSettings from '../SwapSettings';
import TokenSelector from '../TokenSelector';

const toValidNumber = (i: string): string | undefined => {
  if (i.length == 0) {
    return '';
  }
  if (!isNaN(i as any) && /^(0|[1-9]\d*)(\.\d*)?$/.test(i) && i !== '00') {
    return i;
  }
};

function countDecimals(x: number) {
  if (Math.floor(x) === x) {
    return 0;
  }
  return x.toString().split('.')[1].length || 0;
}
export let isRejected = false;
export const setIsRejected = (value: boolean) => {
  isRejected = value;
};
export let isBlockWatchig = true;
export default function SwapModal() {
  const dispatch = useDispatch();
  const { address, isConnected, chain } = useAccount();
  const [firstTokenValue, setFirstTokenValue] = useState('');
  const [secondTokenValue, setSecondTokenValue] = useState('');
  const [focusField, setFocusField] = useState('input');
  const { address: universalRouterAddress } = useUniversalRouter();
  const [insufficientLiquidity, setInsuficientLiuidity] = useState(false);
  const swapSettings = useSelector(swapSettingsSelector);

  const selectedFirstToken: DEXToken | undefined =
    useSelector(firstTokenSelector);
  const selectedSecondToken: DEXToken | undefined =
    useSelector(secondTokenSelector);
  const isLoading = useSelector(isDataLoadingSelected);
  const debouncedFirstTokenValue = useDebounce(firstTokenValue, 500);
  const debouncedSecondTokenValue = useDebounce(secondTokenValue, 500);
  const { route, aquote /* aquote its per one estimate */, quote, quoteWorks } =
    useQuote({
      refetch: isBlockWatchig,
      setInsuficientLiquidity: (value: boolean) =>
        setInsuficientLiuidity(value),
      insuficientLiquidity: insufficientLiquidity,
    });
  useEffect(() => {
    setFirstTokenValue('');
    setSecondTokenValue('');
    if (!isConnected) {
      setFirstTokenValue('');
      setSecondTokenValue('');
    }
  }, [chain, isConnected]);
  useEffect(() => {
    if (
      insufficientLiquidity &&
      selectedSecondToken &&
      focusField === 'input'
    ) {
      setSecondTokenValue('');
      selectedSecondToken.amount = BigNumber.from(0);
      dispatch(setSecondToken(selectedSecondToken));
    }
    if (
      insufficientLiquidity &&
      selectedFirstToken &&
      focusField === 'output'
    ) {
      setFirstTokenValue('');
      selectedFirstToken.amount = BigNumber.from(0);
      dispatch(setFirstToken(selectedFirstToken));
    }
  }, [insufficientLiquidity]);
  const perOneTokenEstimate =
    selectedSecondToken &&
    aquote &&
    formatTokenValueInSwap(
      aquote.toExact(),
      true,
      selectedSecondToken.decimals
    );

  // Refetch when tokens changed
  useMemo(async () => {
    try {
      dispatch(setIsDataLoading(false));
      setInsuficientLiuidity(false);
      if (quoteWorks) {
        dispatch(setIsDataLoading(true));
        rejectPromise('REJECT PROMISE');
      }
      const q = await quote(
        {
          recipient: address!,
          slippageTolerance: swapSettings.slippage,
          deadline: swapSettings.deadline,
          type: SwapType.SWAP_ROUTER_02,
        },
        focusField === 'output',
        selectedFirstToken,
        selectedSecondToken,
        true
      );
      if (q && focusField === 'input') {
        setSecondTokenValue(q.quote.toExact(6 as unknown as object));
      }
      if (q && focusField === 'output')
        setFirstTokenValue(q.quote.toExact(6 as unknown as object));
    } catch (err) {
      console.log('error', err);
      // setInsuficientLiuidity(true);
    }
  }, [selectedFirstToken, selectedSecondToken, swapSettings.slippage]);
  const { allowance, approve, isWaitingForApprove } = useAllowance(
    selectedFirstToken?.address,
    universalRouterAddress
  );

  const changeTokensOrder = () => {
    setFocusField('input');
    setFirstTokenValue(
      utils.formatUnits(
        selectedSecondToken!.amount,
        selectedSecondToken!.decimals
      )
    );
    setSecondTokenValue('');
    dispatch(switchTokens({ selectedFirstToken, selectedSecondToken }));
  };
  useMemo(() => {
    if (route) {
      // if (
      //   (route?.quote.currency as Token).address !==
      //   selectedSecondToken?.address
      // ) {
      //   return;
      // } idk why i did this but we change UniversalRoute to SwapRoute02.. so now its make bug ^_^
      if (route.trade.tradeType === TradeType.EXACT_OUTPUT) {
        if (selectedFirstToken && route)
          selectedFirstToken.amount = utils.parseUnits(
            route.quote.toExact(),
            selectedFirstToken?.decimals
          );
        dispatch(setSecondToken(selectedSecondToken));
        dispatch(setFirstToken(selectedFirstToken));
      }
      if (route.trade.tradeType === TradeType.EXACT_INPUT) {
        if (
          route.trade.inputAmount.currency.wrapped.address !==
            selectedFirstToken?.wrapped.address ||
          route.trade.outputAmount.currency.wrapped.address !==
            selectedSecondToken?.wrapped.address
        )
          return;
        if (selectedSecondToken && route)
          selectedSecondToken.amount = utils.parseUnits(
            route.quote.toExact(),
            selectedSecondToken?.decimals
          );
        dispatch(setFirstToken(selectedFirstToken));
        dispatch(setSecondToken(selectedSecondToken));
      }
    }
    if (!route) {
      // setInsuficientLiuidity(true);
    }
  }, [route]);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [error, setError] = useState(false);
  const { data: firstTokenBalance } = useBalance({
    address,
    token: !selectedFirstToken?.isNative
      ? (selectedFirstToken?.address as `0x${string}`)
      : undefined,
  });

  const { data: secondTokenBalance } = useBalance({
    address,
    token: !selectedSecondToken?.isNative
      ? (selectedSecondToken?.address as `0x${string}`)
      : undefined,
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isWrapModalOpen, setIsWrapModalOpen] = useState(false);
  const [isUnwrapModalOpen, setIsUnwrapModalOpen] = useState(false);
  const openSwitchPanel = useSelector(openTopSwitchPanelSelector);
  useMemo(async () => {
    if (
      Number(firstTokenValue) <= 0 &&
      focusField === 'input' &&
      selectedSecondToken
    ) {
      selectedSecondToken!.amount = BigNumber.from(0);
      selectedFirstToken!.amount = BigNumber.from(0);
      dispatch(setSecondToken(selectedSecondToken));
      dispatch(setFirstToken(selectedFirstToken));
      setSecondTokenValue('');
    }
    if (
      Number(secondTokenValue) <= 0 &&
      focusField === 'output' &&
      selectedFirstToken
    ) {
      selectedFirstToken!.amount = BigNumber.from(0);
      selectedSecondToken!.amount = BigNumber.from(0);
      dispatch(setFirstToken(selectedFirstToken));
      dispatch(setSecondToken(selectedSecondToken));
      setFirstTokenValue('');
    }
    if (
      (firstTokenValue &&
        selectedFirstToken &&
        !selectedFirstToken.amount.isZero() &&
        focusField === 'input' &&
        utils
          .parseUnits(
            firstTokenValue.replace(/,/g, ''),
            selectedFirstToken.decimals
          )
          .eq(selectedFirstToken.amount)) ||
      (secondTokenValue &&
        selectedSecondToken &&
        !selectedSecondToken.amount.isZero() &&
        utils
          .parseUnits(
            secondTokenValue.replace(/,/g, ''),
            selectedSecondToken.decimals
          )
          .eq(selectedSecondToken.amount) &&
        focusField === 'output')
    ) {
      return;
    }
    if (selectedFirstToken && Number(firstTokenValue) > 0) {
      selectedFirstToken.amount = utils.parseUnits(
        firstTokenValue.substring(
          0,
          firstTokenValue.indexOf('.') + selectedFirstToken?.decimals
        ),
        selectedFirstToken?.decimals
      );
      dispatch(setFirstToken(selectedFirstToken));
    }
    if (selectedSecondToken && Number(secondTokenValue) > 0) {
      selectedSecondToken.amount = utils.parseUnits(
        secondTokenValue.substring(
          0,
          secondTokenValue.indexOf('.') + selectedSecondToken?.decimals
        ),
        selectedSecondToken?.decimals
      );
      dispatch(setSecondToken(selectedSecondToken));
    }
    if (selectedFirstToken && selectedSecondToken) {
      if (
        !selectedFirstToken.amount.isZero() ||
        !selectedSecondToken.amount.isZero()
      ) {
        try {
          if (quoteWorks) {
            isRejected = true;
            rejectPromise('REJECT PROMISE');
            dispatch(setIsDataLoading(true));
          }
          dispatch(setIsDataLoading(true));
          const q = await quote(
            undefined,
            focusField === 'output',
            selectedFirstToken,
            selectedSecondToken,
            true
          );
          if (!q) {
            console.log(
              'Quote undefined, this means no Liquidity no path or somthing else'
            );
            return;
          }

          if (Number(debouncedFirstTokenValue) > 0 && focusField === 'input') {
            setSecondTokenValue(q!.quote.toExact(6 as unknown as object));
          }
          if (
            Number(debouncedSecondTokenValue) > 0 &&
            focusField === 'output'
          ) {
            setFirstTokenValue(q!.quote.toExact(6 as unknown as object));
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [debouncedFirstTokenValue, debouncedSecondTokenValue]);
  useMemo(() => {
    if (
      selectedFirstToken?.isNative &&
      selectedFirstToken.wrapped.address ===
        selectedSecondToken?.wrapped.address
    ) {
      if (focusField === 'input' && firstTokenValue) {
        selectedSecondToken.amount = selectedFirstToken.amount;
        dispatch(setSecondToken(selectedSecondToken));
        dispatch(setIsDataLoading(false));
        setIsWrapModalOpen(true);
        setIsUnwrapModalOpen(false);
        setSecondTokenValue(
          utils.formatUnits(
            selectedFirstToken.amount,
            selectedFirstToken.decimals
          )
        );
      } else if (focusField === 'output' && secondTokenValue) {
        selectedFirstToken.amount = selectedSecondToken.amount;
        dispatch(setFirstToken(selectedFirstToken));
        dispatch(setIsDataLoading(false));
        setIsWrapModalOpen(true);
        setIsUnwrapModalOpen(false);
        setFirstTokenValue(
          utils.formatUnits(
            selectedSecondToken.amount,
            selectedSecondToken.decimals
          )
        );
      }
    } else if (
      selectedSecondToken?.isNative &&
      selectedSecondToken.wrapped.address === selectedFirstToken?.address
    ) {
      if (focusField === 'input' && firstTokenValue) {
        selectedSecondToken.amount = selectedFirstToken.amount;
        dispatch(setSecondToken(selectedSecondToken));
        dispatch(setIsDataLoading(false));
        setIsWrapModalOpen(false);
        setIsUnwrapModalOpen(true);
        setSecondTokenValue(
          utils.formatUnits(
            selectedFirstToken.amount,
            selectedFirstToken.decimals
          )
        );
      } else if (focusField === 'output' && secondTokenValue) {
        selectedFirstToken.amount = selectedSecondToken.amount;
        dispatch(setFirstToken(selectedFirstToken));
        dispatch(setIsDataLoading(false));
        setIsWrapModalOpen(false);
        setIsUnwrapModalOpen(true);
        setFirstTokenValue(
          utils.formatUnits(
            selectedSecondToken.amount,
            selectedSecondToken.decimals
          )
        );
      }
    } else {
      setIsUnwrapModalOpen(false);
      setIsWrapModalOpen(false);
    }
  }, [
    debouncedFirstTokenValue,
    debouncedSecondTokenValue,
    selectedFirstToken,
    selectedSecondToken,
  ]);
  const getSwapButton = useMemo(() => {
    if (!address) {
      return (
        <StyledButtonConnect
          onClick={() => dispatch(setIsOpen(true))}
          variant='contained'
        >
          {t('connectWallet')}
        </StyledButtonConnect>
      );
    }
    if (
      (!firstTokenValue || Number(firstTokenValue) <= 0) &&
      (!secondTokenValue || Number(secondTokenValue) <= 0)
    ) {
      return (
        <StyledButton
          disabled
          variant='contained'
        >
          {t('enterAnAmount')}
        </StyledButton>
      );
    }
    if ((error || insufficientLiquidity) && !isLoading) {
      return <StyledButton disabled>{t('insufficientLiquidity')}</StyledButton>;
    }
    if (
      selectedFirstToken &&
      firstTokenBalance &&
      BigNumber.from(firstTokenBalance.value).gt(selectedFirstToken.amount) &&
      allowance?.lt(selectedFirstToken.amount) &&
      !isLoading &&
      !isWaitingForApprove
    ) {
      return (
        <StyledButton
          onClick={async () => {
            await approve();
          }}
        >
          {t('approveToken')}
        </StyledButton>
      );
    }

    if (isLoading && !isWaitingForApprove) {
      return (
        <StyledButton
          variant='contained'
          disabled
        >
          {t('waitForLoadingData')}
        </StyledButton>
      );
    }
    if (
      Number(firstTokenBalance?.formatted) <
      Number(firstTokenValue.replace(/,/g, ''))
    ) {
      return (
        <StyledButton
          disabled
          variant='contained'
        >{`${t('insufficient')} ${selectedFirstToken?.symbol} ${t(
          'balance'
        )}`}</StyledButton>
      );
    }
    if (isWaitingForApprove) {
      return (
        <StyledButton
          disabled
          variant='contained'
        >
          {t('waitForApprove')}
        </StyledButton>
      );
    }
    if (
      selectedFirstToken?.isNative &&
      selectedFirstToken?.wrapped.address === selectedSecondToken?.address &&
      !isLoading
    ) {
      return (
        <StyledButton
          onClick={() => {
            setIsConfirmModalOpen(true);
          }}
          variant='contained'
        >
          {t('wrap')}
        </StyledButton>
      );
    }
    if (
      selectedSecondToken?.isNative &&
      selectedSecondToken?.wrapped.address === selectedFirstToken?.address &&
      !isLoading
    ) {
      return (
        <StyledButton
          onClick={() => {
            setIsConfirmModalOpen(true);
          }}
          variant='contained'
        >
          {t('unwrap')}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton
          onClick={() => setIsConfirmModalOpen(true)}
          variant='contained'
          disabled={!route || !firstTokenValue || !secondTokenValue}
        >
          {t('swap')}
        </StyledButton>
      );
    }
  }, [
    isLoading,
    firstTokenValue,
    secondTokenValue,
    debouncedFirstTokenValue,
    debouncedSecondTokenValue,
    selectedFirstToken,
    selectedSecondToken,
    address,
    isWaitingForApprove,
  ]);

  return (
    <>
      {isMobile && openSwitchPanel && <TopSwitchPanel />}
      <StyledContainer
        sx={{
          marginBottom:
            isMobile && openSwitchPanel
              ? '207px'
              : isMobile && !openSwitchPanel
              ? '266px'
              : !isMobile
              ? '4px'
              : '4px',
        }}
      >
        <StyledPaper elevation={3}>
          <StyledHeader
            sx={{
              marginTop: isMobile && !openSwitchPanel ? '-18px' : undefined,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              gap='12px'
            >
              <StyledTitle>{t('swap')}</StyledTitle>
              <StyledSettings>
                <SwapSettings />
              </StyledSettings>
            </Stack>
            {!isMobile && (
              <Box
                width={24}
                height={24}
              ></Box>
            )}
          </StyledHeader>
          <Box
            position='relative'
            sx={{
              py: !isMobile ? 1 : 2,
            }}
          >
            <RevertButtonContainer
              sx={{
                backgroundColor: isLoading ? 'gray' : 'initial',
                '& :hover': {
                  backgroundColor: isLoading ? 'gray' : Color.DARK_PURPLE,
                  transition: isLoading
                    ? 'none'
                    : 'background-color 0.3s linear',
                },
              }}
            >
              <RevertButton
                isLoading={isLoading}
                style={{
                  top: isMobile
                    ? '154px'
                    : selectedFirstToken?.symbol || selectedSecondToken?.symbol
                    ? '104px'
                    : '110px',
                  // color: isLoading ? 'gray' : Color.WHITE,
                  // border: isLoading
                  //   ? `4px solid gray`
                  //   : `4px solid ${Color.DARK_PURPLE}`,
                  // cursor: isLoading ? 'auto' : 'pointer',
                }}
                onClick={() => {
                  !isLoading && changeTokensOrder();
                }}
              >
                <RevertIcon />
              </RevertButton>
            </RevertButtonContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <StyledInput>
                {!isMobile ? (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      margin: selectedFirstToken?.symbol
                        ? '0 8px 4px 4px'
                        : '6px 12px',
                    }}
                  >
                    <TokenSelector
                      firstToken
                      fetchData={true}
                    />
                    {isConnected && selectedFirstToken?.name && (
                      <Stack
                        direction='row'
                        alignItems='center'
                      >
                        <Typography
                          fontSize={14}
                          sx={{
                            color: Color.WHITE_OPACITY_LIGHT,
                            fontFamily: FontFamily.INTER,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span style={{ color: Color.WHITE }}>
                            {firstTokenBalance &&
                              formatTokenBalance(
                                firstTokenBalance.value,
                                selectedFirstToken.decimals
                              )}
                          </span>
                        </Typography>
                        {Number(firstTokenBalance?.formatted ?? 0) > 0 && (
                          <Button
                            sx={{
                              p: 0,
                              textTransform: 'uppercase',
                              fontWeight: 400,
                              minWidth: 42,
                              color: Color.PURPLE,
                            }}
                            onClick={() => {
                              // setMaxValue
                              firstTokenBalance &&
                                setFirstTokenValue(
                                  firstTokenBalance?.formatted
                                );
                              dispatch(setIsDataLoading(true));
                              setFocusField('input');
                            }}
                          >
                            {t('max')}
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      margin: selectedFirstToken?.symbol
                        ? '20px 0px 16px 0px'
                        : '20px 20px 16px 16px',
                    }}
                  >
                    <TokenSelector firstToken />
                    {isConnected && selectedFirstToken?.name && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                          marginRight: '16px',
                        }}
                      >
                        <Typography
                          fontSize={14}
                          sx={{
                            color: Color.WHITE_OPACITY_LIGHT,
                            fontFamily: FontFamily.INTER,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span style={{ color: Color.WHITE }}>
                            {firstTokenBalance &&
                              formatTokenBalance(
                                firstTokenBalance.value,
                                selectedFirstToken.decimals
                              )}
                          </span>
                        </Typography>
                        {Number(firstTokenBalance?.formatted ?? 0) > 0 && (
                          <Button
                            sx={{
                              p: 0,
                              textTransform: 'uppercase',
                              fontWeight: 400,
                              minWidth: 42,
                              color: Color.PURPLE,
                            }}
                            onClick={() => {
                              dispatch(setIsDataLoading(true));
                              firstTokenBalance &&
                                setFirstTokenValue(
                                  firstTokenBalance?.formatted
                                );
                              setFocusField('input');
                            }}
                          >
                            {t('max')}
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
                <StyledInputBase
                  placeholder='0.00'
                  inputProps={{ 'aria-label': 'token' }}
                  value={
                    selectedFirstToken &&
                    firstTokenValue &&
                    formatTokenValueInSwap(
                      firstTokenValue,
                      focusField === 'output',
                      selectedFirstToken.decimals
                    )
                  }
                  onChange={(event) => {
                    const i = toValidNumber(event.target.value);
                    if (i !== undefined) {
                      setFirstTokenValue(i);
                      dispatch(setIsDataLoading(true));
                    }
                  }}
                  onFocus={() => setFocusField('input')}
                />
              </StyledInput>
              <StyledInput>
                {!isMobile ? (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      margin: selectedSecondToken?.symbol
                        ? '0 8px 4px 4px'
                        : '6px 12px',
                    }}
                  >
                    <TokenSelector />
                    {isConnected && selectedSecondToken?.name && (
                      <Stack
                        direction='row'
                        alignItems='center'
                      >
                        <Typography
                          fontSize={14}
                          sx={{
                            color: Color.WHITE_OPACITY_LIGHT,
                            fontFamily: FontFamily.INTER,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span style={{ color: Color.WHITE }}>
                            {secondTokenBalance &&
                              formatTokenBalance(
                                secondTokenBalance.value,
                                selectedSecondToken.decimals
                              )}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      margin: selectedSecondToken?.symbol
                        ? '20px 20px 16px 0px'
                        : '20px 20px 16px 16px',
                    }}
                  >
                    <TokenSelector />
                    {isConnected && selectedSecondToken?.name && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                          marginRight: '16px',
                        }}
                      >
                        <Typography
                          fontSize={14}
                          sx={{
                            color: Color.WHITE_OPACITY_LIGHT,
                            fontFamily: FontFamily.INTER,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span style={{ color: Color.WHITE }}>
                            {secondTokenBalance &&
                              formatTokenBalance(
                                secondTokenBalance.value,
                                selectedSecondToken.decimals
                              )}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                )}
                <StyledInputBase
                  placeholder='0.00'
                  inputProps={{ 'aria-label': 'token' }}
                  value={
                    selectedSecondToken &&
                    selectedFirstToken &&
                    secondTokenValue &&
                    formatTokenValueInSwap(
                      secondTokenValue,
                      focusField === 'input',
                      selectedSecondToken!.decimals
                    )
                  }
                  onChange={(event) => {
                    const i = toValidNumber(event.target.value);
                    if (i !== undefined) {
                      setSecondTokenValue(i);
                      dispatch(setIsDataLoading(true));
                    }
                  }}
                  onFocus={() => setFocusField('output')}
                />
                {selectedFirstToken?.name &&
                  selectedSecondToken?.name &&
                  (Number(firstTokenValue.replace(/,/g, '')) > 0 ||
                    firstTokenValue.replace(/,/g, '').length > 3) &&
                  !insufficientLiquidity &&
                  route &&
                  !isLoading && (
                    <div style={{ marginTop: '8px' }}>
                      <StyledAccordion
                        sx={{ padding: isMobile ? '14px 0 0' : '16px 0 0' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                          sx={{
                            margin: 0,
                            '& .MuiAccordionSummary-content': { margin: 0 },
                          }}
                        >
                          <Typography>
                            <AccordionTitleStyled>
                              {'1'} {selectedFirstToken?.symbol} ={' '}
                              {/* ITS a SHITTY way to do this */}
                              {isWrapModalOpen || isUnwrapModalOpen
                                ? '1'
                                : perOneTokenEstimate !== '<0.00001'
                                ? roundNumberInSwap(Number(perOneTokenEstimate))
                                : perOneTokenEstimate}{' '}
                              {selectedSecondToken?.symbol}
                            </AccordionTitleStyled>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: '0 16px' }}>
                          <Typography>
                            <ExchangeDetailsWrap>
                              <ExchangeDetailsItem>
                                <ExchangeTitle>
                                  {t('expectedOutput')}
                                </ExchangeTitle>
                                <ExchangeTitle sx={{ textAlign: 'right' }}>
                                  {isWrapModalOpen || isUnwrapModalOpen
                                    ? firstTokenValue
                                    : focusField === 'input'
                                    ? roundNumberInSwap(
                                        Number(
                                          route?.trade.outputAmount.toExact()
                                        )
                                      )
                                    : secondTokenValue}{' '}
                                  {selectedSecondToken?.symbol}
                                </ExchangeTitle>
                              </ExchangeDetailsItem>
                            </ExchangeDetailsWrap>
                            <ExchangeDetailsItem>
                              <ExchangePrice>
                                {t('minimumReceivedAfterSlippage')}{' '}
                                {`(${
                                  swapSettings?.slippage?.toSignificant() ?? 0.5
                                }%)`}
                              </ExchangePrice>
                              <ExchangePrice sx={{ textAlign: 'right' }}>
                                {isWrapModalOpen || isUnwrapModalOpen
                                  ? firstTokenValue
                                  : roundNumberInSwap(
                                      Number(
                                        route?.trade
                                          .minimumAmountOut(
                                            swapSettings.slippage ??
                                              new Percent(50, 10_000)
                                          )
                                          .toExact()
                                      )
                                    )}{' '}
                                {selectedSecondToken?.symbol}
                              </ExchangePrice>
                            </ExchangeDetailsItem>
                            <ExchangeDetailsItem>
                              <ExchangePrice>
                                {t('estimatedGasUsed')}{' '}
                              </ExchangePrice>
                              <ExchangePrice>
                                {`${route?.estimatedGasUsedUSD.toExact(
                                  2 as unknown as object /* IDK why here object needed but its works, need to invistigate */
                                )}$`}
                              </ExchangePrice>
                            </ExchangeDetailsItem>
                          </Typography>
                        </AccordionDetails>
                      </StyledAccordion>
                    </div>
                  )}
              </StyledInput>
            </div>
          </Box>

          <ButtonWrapper>{getSwapButton}</ButtonWrapper>
        </StyledPaper>
      </StyledContainer>
      {/* </Collapse>
      )} */}
      <ConfirmSwapModal
        isOpen={isConfirmModalOpen}
        route={route}
        setIsOpen={setIsConfirmModalOpen}
        clearTokensValue={() => {
          setFirstTokenValue('');
          setSecondTokenValue('');
        }}
        aquote={aquote?.toExact(6 as unknown as object)}
        isWrap={isWrapModalOpen}
        isUnwrap={isUnwrapModalOpen}
      />
      {/* <ConfirmSwapModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        firstTokenValue={firstTokenValue}
        estimatedOutput={
          Number(
            utils.formatUnits(estimatedOutput, selectedSecondToken?.decimals)
          ) > 0
            ? utils
                .formatUnits(
                  String(estimatedOutput),
                  selectedSecondToken?.decimals
                )
                .slice(0, 10)
            : secondTokenValue
        }
        estimatedPoolFee={estimatedPoolFee}
        intervalId={intervalId}
        exchangeRate={
          !!perOneEstimatedOutput &&
          utils
            .formatUnits(perOneEstimatedOutput, selectedSecondToken?.decimals)
            .slice(0, 7)
        }
        clearTokensValue={() => {
          setFirstTokenValue('');
          setSecondTokenValue('');
        }}
      />
      <ConfirmSwapModal
        isOpen={isUnwrapModalOpen}
        setIsOpen={setIsUnwrapModalOpen}
        firstTokenValue={firstTokenValue}
        estimatedOutput={secondTokenValue}
        estimatedPoolFee={estimatedPoolFee}
        intervalId={intervalId}
        exchangeRate={false}
        clearTokensValue={() => {
          setFirstTokenValue('');
          setSecondTokenValue('');
        }}
        unwrap
      /> */}
    </>
  );
}
