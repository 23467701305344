import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  StyledInfoWrapper,
  StyledPositionItemIcon,
  StyledPositionSymbol,
  StyledDivider,
  StyledStatusIcon,
  StyledStatusTitle,
} from 'components/PoolDetails/styles';

import {
  AmountButtonGroup,
  AmountContainer,
  AmountPercentage,
  AmountPercentageContainer,
  AmountTitle,
  MainContent,
  PercentageBtn,
  PooledContainer,
  PooledTokenContainer,
  StyledDialog,
  StyledDialogTitle,
  StyledHeadingContainer,
  StyledIconButton,
  StyledSlider,
  PooledToken,
  CollectContainer,
  StyledSwitch,
  RemoveBtnContainer,
  StyledRemoveButton,
} from './styles';
import { Color } from 'helpers/themeStyles';
import RemoveLiquiditySubmitter from 'components/RemoveLiquiditySubmitter';
import poolStatuses from 'helpers/poolStatuses';
import SwapSettings from 'components/SwapSettings';
import JSBI from 'jsbi';
import { Percent, Position } from '@omisoftnet/game-dex-sdk';
import { IPosition } from '@omisoftnet/game-dex-sdk/dist/providers';
import useDebounce from '@hooks/useDebounce';
import { BigNumber } from 'ethers';

const RemoveLiquidityModal = ({
  isOpen,
  onClose,
  firstTokenSymbol,
  secondTokenSymbol,
  firstTokenIcon,
  secondTokenIcon,
  firstTokenValue,
  secondTokenValue,
  status,
  slippageTolerance,
  liquidity,
  position,
  firstTokenAddress,
  secondTokenAddress,
  firstTokenChainId,
  secondTokenChainId,
  firstTokenDecimals,
  secondTokenDecimals,
  firstTokenName,
  secondTokenName,
  liquidityId,
  token0Fees,
  token1Fees,
}: {
  isOpen: boolean;
  onClose: () => void;
  firstTokenSymbol: string;
  secondTokenSymbol: string;
  firstTokenIcon: string;
  secondTokenIcon: string;
  firstTokenValue: number;
  secondTokenValue: number;
  status: boolean;
  slippageTolerance?: number;
  liquidity: JSBI;
  position: IPosition;
  firstTokenAddress: string;
  secondTokenAddress: string;
  firstTokenChainId: number;
  secondTokenChainId: number;
  firstTokenDecimals: number;
  secondTokenDecimals: number;
  firstTokenName: string;
  secondTokenName: string;
  liquidityId: string;
  token0Fees: string;
  token1Fees: string;
}) => {
  const { t } = useTranslation();
  const [alignment, setAlignment] = useState('');
  const [amountPercentage, setAmountPercentage] = useState(0);
  const debouncedAmountPercentage = useDebounce<number>(amountPercentage, 5);
  const [isSubmitterOpen, setIsSubmitterOpen] = useState(false);
  const [collectAsWETH, setCollectAsWETH] = useState(false);
  const [sdkPosition, setPosition] = useState<Position>();
  const [sdkPositionLiquidityHex, setPositionLiquidityHex] = useState<string>();
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  const handleChange = (event: Event, percentage: number | number[]) => {
    setAmountPercentage(percentage as number);
  };

  useMemo(() => {
    const total = position.liquidity();
    const part = total.mul(amountPercentage).div(100);
    setPosition(
      new Position({
        pool: position.pool(),
        liquidity: part.toHexString(),
        tickLower: position.wrapped().tickLower,
        tickUpper: position.wrapped().tickUpper,
      })
    );
    setPositionLiquidityHex(part.toHexString());
  }, [amountPercentage]);

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => onClose()}
      >
        <StyledHeadingContainer>
          <StyledIconButton onClick={() => onClose()}>
            <ArrowBackIcon />
          </StyledIconButton>
          <StyledDialogTitle>{t('removeLiquidityTitle')}</StyledDialogTitle>
          <SwapSettings />
        </StyledHeadingContainer>
        <MainContent>
          <StyledInfoWrapper>
            <Stack
              direction='row'
              alignItems='center'
            >
              <StyledPositionItemIcon
                src={firstTokenIcon}
                alt=''
              />
              <StyledPositionItemIcon
                src={secondTokenIcon}
                alt=''
                style={{ position: 'relative', left: '-4px' }}
              />
            </Stack>
            <StyledPositionSymbol>{`${firstTokenSymbol}/${secondTokenSymbol}`}</StyledPositionSymbol>
            <StyledDivider />
            <StyledStatusIcon
              style={{
                background: status ? Color.GREEN : Color.YELLOW,
              }}
            />
            <StyledStatusTitle>
              {status ? t('inRange') : t('outOfRange')}
            </StyledStatusTitle>
          </StyledInfoWrapper>
          <AmountContainer>
            <AmountTitle>{t('amount')}</AmountTitle>
            <AmountPercentageContainer>
              <AmountPercentage>{amountPercentage}%</AmountPercentage>
              <AmountButtonGroup
                size='small'
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label='text alignment'
                sx={{
                  '& .Mui-selected': {
                    backgroundColor: `${Color.WHITE_OPACITY}!important`,
                  },
                }}
              >
                <PercentageBtn
                  value='25'
                  onClick={() => setAmountPercentage(25)}
                >
                  25%
                </PercentageBtn>
                <PercentageBtn
                  value='50'
                  onClick={() => setAmountPercentage(50)}
                >
                  50%
                </PercentageBtn>
                <PercentageBtn
                  value='75'
                  onClick={() => setAmountPercentage(75)}
                >
                  75%
                </PercentageBtn>
                <PercentageBtn
                  value='max'
                  onClick={() => setAmountPercentage(100)}
                >
                  {t('max')}
                </PercentageBtn>
              </AmountButtonGroup>
            </AmountPercentageContainer>
            <div style={{ marginLeft: '10px' }}>
              <StyledSlider
                value={amountPercentage}
                onChange={handleChange}
              />
            </div>
          </AmountContainer>
          <PooledContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {t('pooled')} {firstTokenSymbol}:
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                {sdkPosition?.amount0.toSignificant(6)}{' '}
                <img
                  src={firstTokenIcon}
                  width='24'
                  height='24'
                />
              </PooledToken>
            </PooledTokenContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {t('pooled')} {secondTokenSymbol}:
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                {sdkPosition?.amount1.toSignificant(6)}{' '}
                <img
                  src={secondTokenIcon}
                  width='24'
                  height='24'
                />
              </PooledToken>
            </PooledTokenContainer>
            {(token0Fees !== '0.0' || token1Fees !== '0.0') && (
              <>
                <PooledTokenContainer
                  style={{
                    borderTop: `1px solid ${Color.WHITE_OPACITY}`,
                    paddingTop: '12px',
                    marginTop: '12px',
                  }}
                >
                  <PooledToken style={{ fontWeight: '600' }}>
                    {firstTokenSymbol} {t('feesEarned')}:
                  </PooledToken>
                  <PooledToken
                    style={{
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                  >
                    {token0Fees === '0.0' ? 0 : token0Fees}{' '}
                    <img
                      src={firstTokenIcon}
                      width='24'
                      height='24'
                    />
                  </PooledToken>
                </PooledTokenContainer>
                <PooledTokenContainer>
                  <PooledToken style={{ fontWeight: '600' }}>
                    {secondTokenSymbol} {t('feesEarned')}:
                  </PooledToken>
                  <PooledToken
                    style={{
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                  >
                    {token1Fees === '0.0' ? 0 : token1Fees}{' '}
                    <img
                      src={secondTokenIcon}
                      width='24'
                      height='24'
                    />
                  </PooledToken>
                </PooledTokenContainer>
              </>
            )}
          </PooledContainer>
          {/* <CollectContainer>
            <PooledToken style={{ fontWeight: '400' }}>
              {t('collectAtWETH')}
            </PooledToken>
            <StyledSwitch onChange={() => setCollectAsWETH(!collectAsWETH)} />
          </CollectContainer> */}
        </MainContent>
        <RemoveBtnContainer>
          <StyledRemoveButton
            disabled={amountPercentage === 0}
            style={{
              background:
                amountPercentage === 0
                  ? Color.WHITE_OPACITY
                  : Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
            }}
            onClick={() => setIsSubmitterOpen(true)}
          >
            {t('remove')}
          </StyledRemoveButton>
        </RemoveBtnContainer>
      </StyledDialog>
      <RemoveLiquiditySubmitter
        isOpen={isSubmitterOpen}
        onClose={() => setIsSubmitterOpen(false)}
        firstTokenSymbol={firstTokenSymbol}
        secondTokenSymbol={secondTokenSymbol}
        firstTokenIcon={firstTokenIcon}
        secondTokenIcon={secondTokenIcon}
        firstTokenValue={sdkPosition?.amount0.toSignificant(6) ?? ''}
        secondTokenValue={sdkPosition?.amount1.toSignificant(6) ?? ''}
        slippageTolerance={slippageTolerance}
        position={sdkPosition!}
        collectAsWETH={collectAsWETH}
        percentToRemove={new Percent(amountPercentage, 100)}
        firstTokenAddress={firstTokenAddress}
        secondTokenAddress={secondTokenAddress}
        firstTokenChainId={firstTokenChainId}
        secondTokenChainId={secondTokenChainId}
        firstTokenDecimals={firstTokenDecimals}
        secondTokenDecimals={secondTokenDecimals}
        firstTokenName={firstTokenName}
        secondTokenName={secondTokenName}
        liquidityId={liquidityId}
        token0Fees={token0Fees}
        token1Fees={token1Fees}
      />
    </>
  );
};
export default RemoveLiquidityModal;
