import { KeyboardArrowDown } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  ListItemAvatar,
  ListItemButton,
  Stack,
} from '@mui/material';
import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { BigNumberish } from 'ethers';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import formatTokenBalance from 'helpers/formatTokenBalance';
import {
  firstLiquidityTokenSelector,
  secondLiquidityTokenSelector,
} from 'state/liquidity/selectors';
import { firstTokenSelector, secondTokenSelector } from 'state/swap/selectors';
import {
  StyledBalanceValue,
  StyledTokenIcon,
  StyledTokenName,
  StyledTokenSymbol,
} from './styles';

type ControlProps = {
  token: DEXToken;
  tokenName: string | undefined;
  tokenAddress: string | undefined;
  setToken: (token: DEXToken) => void;
  fromLiquidity?: boolean;
  balance?: BigNumberish;
  isLoading?: boolean;
};

const TokenItem = ({
  token,
  tokenName,
  setToken,
  tokenAddress,
  fromLiquidity,
  balance,
  isLoading,
}: ControlProps) => {
  const firstToken = useSelector(firstTokenSelector);
  const secondToken = useSelector(secondTokenSelector);
  const [imgError, setImgError] = useState(false);
  const firstLiquidityToken = useSelector(firstLiquidityTokenSelector);
  const secondLiquidityToken = useSelector(secondLiquidityTokenSelector);
  const { chain } = useAccount();
  const ref = useRef(null);

  // useEffect(() => {}, [balance]);
  return (
    <>
      <ListItemButton
        onClick={() => {
          token.amount = utils.parseUnits('0', token.decimals);
          setToken(token);
        }}
        disabled={
          !fromLiquidity
            ? ['WETH', 'ETH', 'tBNB', 'WBNB'].includes(token?.symbol!)
              ? firstToken?.symbol === token?.symbol ||
                secondToken?.symbol === token?.symbol
              : firstToken?.address === token?.address ||
                secondToken?.address === token?.address
            : firstLiquidityToken.symbol !== '!' &&
              secondLiquidityToken.symbol !== '!' &&
              (firstLiquidityToken?.wrapped.address ===
                token?.wrapped.address ||
                secondLiquidityToken?.wrapped.address ===
                  token?.wrapped.address)
        }
      >
        <ListItemAvatar>
          {imgError ? (
            <StyledTokenIcon
              src={
                chain?.id === 1
                  ? './images/default_eth_icon.webp'
                  : './images/default_bnb_icon.webp'
              }
            />
          ) : (
            <StyledTokenIcon
              onError={() => setImgError(true)}
              src={token?.icon || token.logoUri}
            />
          )}
        </ListItemAvatar>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          flexGrow={1}
        >
          <Grid
            container
            flexDirection='column'
          >
            <StyledTokenSymbol>{token.symbol}</StyledTokenSymbol>
            <StyledTokenName>{token.name}</StyledTokenName>
          </Grid>

          <Stack
            direction='row'
            alignItems='center'
            ref={ref}
          >
            {isLoading ? (
              <Stack
                justifyContent={'center'}
                alignItems='center'
              >
                <CircularProgress size={10} />
              </Stack>
            ) : (
              <StyledBalanceValue>
                {`${balance && formatTokenBalance(balance, token.decimals)}`}{' '}
              </StyledBalanceValue>
            )}

            {['WETH', 'ETH', 'tBNB', 'WBNB'].includes(tokenName!)
              ? tokenName === token?.symbol && (
                  <KeyboardArrowDown
                    sx={{
                      ml: 'auto',
                      color: (theme) => theme.palette.grey[500],
                    }}
                  />
                )
              : tokenAddress === token?.address && (
                  <KeyboardArrowDown
                    sx={{
                      ml: 'auto',
                      color: (theme) => theme.palette.grey[500],
                    }}
                  />
                )}
          </Stack>
        </Stack>
      </ListItemButton>
    </>
  );
};

export default TokenItem;
