const TradeIcon = () => {
  return (
    <svg
      width='57'
      height='58'
      viewBox='0 0 57 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.3306 1.54474C27.7015 0.884529 29.2985 0.88453 30.6694 1.54474L48.6128 10.1858C49.9837 10.846 50.9794 12.0946 51.318 13.578L55.7496 32.9943C56.0882 34.4778 55.7329 36.0347 54.7842 37.2244L42.367 52.7951C41.4183 53.9847 39.9794 54.6776 38.4578 54.6776H18.5422C17.0206 54.6776 15.5817 53.9847 14.633 52.7951L2.21584 37.2244C1.26712 36.0347 0.911763 34.4778 1.25036 32.9943L5.682 13.578C6.02059 12.0946 7.01629 10.846 8.38722 10.1858L26.3306 1.54474Z'
        fill='#B032D6'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19.5446 28.0787L12.8779 34.9358L19.5446 41.793V36.6501H31.2113V33.2215H19.5446V28.0787ZM42.8779 24.6501L36.2113 17.793V22.9358H24.5446V26.3644H36.2113V31.5073L42.8779 24.6501Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default TradeIcon;
