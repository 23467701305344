import React from 'react';

const AlertIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 22.0235L2.5 12.0235L12.5 2.02344L22.5001 12.0235L12.5 22.0235ZM4.46149 12.0235L12.5 20.062L20.5386 12.0235L12.5 3.98493L4.46149 12.0235Z'
        fill='#FF73A0'
      />
      <path
        d='M11.8056 13.4102H13.1943V7.86021H11.8056V13.4102Z'
        fill='#FF73A0'
      />
      <path
        d='M11.8056 16.1846H13.1943V14.7958H11.8056V16.1846Z'
        fill='#FF73A0'
      />
    </svg>
  );
};

export default AlertIcon;
