import React from 'react';

const DefaultTokenIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2'
        y='1'
        width='21'
        height='21'
        rx='10.5'
        fill='#B032D6'
        fillOpacity='0.5'
      />
      <path
        d='M12.5 1C6.70156 1 2 5.70156 2 11.5C2 17.2984 6.70156 22 12.5 22C18.2984 22 23 17.2984 23 11.5C23 5.70156 18.2984 1 12.5 1ZM17.5766 15.0391C17.6469 15.1094 17.6844 15.2031 17.6844 15.3016C17.6844 15.4 17.6469 15.4984 17.5766 15.5641L16.5641 16.5812C16.4891 16.6562 16.3953 16.6891 16.3016 16.6891C16.2078 16.6891 16.1094 16.6516 16.0391 16.5812L12.5 13.0375L8.96563 16.5859C8.89531 16.6609 8.79688 16.6937 8.70312 16.6937C8.60938 16.6937 8.51094 16.6562 8.44062 16.5859L7.42813 15.5687C7.35781 15.4984 7.32031 15.4047 7.32031 15.3063C7.32031 15.2078 7.35781 15.1094 7.42813 15.0438L10.9766 11.4813L7.41875 7.96563C7.27344 7.82031 7.27344 7.58125 7.41875 7.43594L8.43125 6.41875C8.50156 6.34844 8.59531 6.31094 8.69375 6.31094C8.79219 6.31094 8.88594 6.34844 8.95625 6.41875L12.5047 9.92031L16.0531 6.41875C16.1234 6.34844 16.2172 6.31094 16.3156 6.31094C16.4141 6.31094 16.5078 6.34844 16.5781 6.41875L17.5906 7.43594C17.7359 7.58125 17.7359 7.82031 17.5906 7.96563L14.0328 11.4813L17.5766 15.0391Z'
        fill='white'
      />
      <path
        d='M12.5 1C6.70156 1 2 5.70156 2 11.5C2 17.2984 6.70156 22 12.5 22C18.2984 22 23 17.2984 23 11.5C23 5.70156 18.2984 1 12.5 1ZM17.5766 15.0391C17.6469 15.1094 17.6844 15.2031 17.6844 15.3016C17.6844 15.4 17.6469 15.4984 17.5766 15.5641L16.5641 16.5812C16.4891 16.6562 16.3953 16.6891 16.3016 16.6891C16.2078 16.6891 16.1094 16.6516 16.0391 16.5812L12.5 13.0375L8.96563 16.5859C8.89531 16.6609 8.79688 16.6937 8.70312 16.6937C8.60938 16.6937 8.51094 16.6562 8.44062 16.5859L7.42813 15.5687C7.35781 15.4984 7.32031 15.4047 7.32031 15.3063C7.32031 15.2078 7.35781 15.1094 7.42813 15.0438L10.9766 11.4813L7.41875 7.96563C7.27344 7.82031 7.27344 7.58125 7.41875 7.43594L8.43125 6.41875C8.50156 6.34844 8.59531 6.31094 8.69375 6.31094C8.79219 6.31094 8.88594 6.34844 8.95625 6.41875L12.5047 9.92031L16.0531 6.41875C16.1234 6.34844 16.2172 6.31094 16.3156 6.31094C16.4141 6.31094 16.5078 6.34844 16.5781 6.41875L17.5906 7.43594C17.7359 7.58125 17.7359 7.82031 17.5906 7.96563L14.0328 11.4813L17.5766 15.0391Z'
        fill='black'
      />
    </svg>
  );
};

export default DefaultTokenIcon;
