import { Box, Button, styled } from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from 'helpers/themeStyles';

export const StyledProjectFinInfoContainer = styled('div')(({ theme }) => ({
  display: 'block',
  width: '30%',
  marginLeft: '15px',

  [theme.breakpoints.down('xl')]: {
    width: '38%',
  },

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    margin: '24px 0',
  },
}));

export const StyledFinancialInfoContainer = styled(Box)(({ theme }) => ({
  padding: '40px 24px',
  background: Color.DARK_PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SEMI_SMALL,

  [theme.breakpoints.down('xl')]: {
    padding: '14px',
  },

  [theme.breakpoints.down('mobile')]: {
    position: 'static',
    padding: '8px 16px',
  },
}));

export const StyledFinancialInfoLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledFinancialInfoLineLabel = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 'bold',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,

  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.TABLE_SMALL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledFinancialInfoLineValue = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,

  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.TABLE_SMALL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const ConnectWalletButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  width: '100%',
  border: '3px solid transparent',
  backgroundImage: `linear-gradient(#331C3A, #331C3A), ${Color.TEXT_GRADIENT}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  [theme.breakpoints.down(1500)]: {
    padding: '12px',
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '12px 24px',
  },
}));

export const StyledGradientText = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  background: Color.TEXT_GRADIENT,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.TABLE_SMALL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.TEXT_GRADIENT,
  border: 'none',
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  width: '380px',
  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.TABLE_SMALL,
    padding: '12px',
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
    padding: '12px 24px',
    width: '100%',
    marginBottom: '32px',
  },
}));

export const StyledFinancialTitle = styled('h2')(({ theme }) => ({
  margin: 0,
  marginBottom: '20px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,

  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.MIDDLE_PLUS,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MEDIUM,
  },
}));

export const StyledFinancialInfoGoal = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE_OPACITY_LIGHT,

  [theme.breakpoints.down(1500)]: {
    fontSize: TextSize.TABLE_SMALL,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));
