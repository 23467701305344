import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const TabsWrapper = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    paddingTop: '24px',
    paddingBottom: '64px',
  },
  '& .MuiTabs-flexContainer': {
    gap: '16px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  '& .MuiTabs-indicator': {
    opacity: 0,
  },
}));

export const TabButton = styled(Tab)(() => ({
  '&.MuiTab-root': {
    height: '112px',
    gap: '20px',
    padding: '0',
    background: Color.BORDER_WHITE_EXTRA_OPACITY,
    borderRadius: BorderRadius.SEMI_SMALL,
    textTransform: 'none',
    color: Color.WHITE,
    fontSize: TextSize.TABLE_SMALL,
    fontWeight: '400',
    fontFamily: FontFamily.INTER,
  },
  svg: {
    width: '24px',
    height: '24px',
    padding: '6px',
    borderRadius: BorderRadius.SEMI_SMALL,
  },
  '&.Mui-selected': {
    background: `${Color.BORDER_WHITE_EXTRA_OPACITY}!important`,
  },
}));
