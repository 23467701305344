import * as React from 'react';
import { useState } from 'react';
import Notification from 'utils/Notification';
import { StyledWrapper, StyledContainer, MainContainer } from './styles';

const Container = ({ children }: { children: any }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [notificationIsOpen, setNotification] = useState(false);

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    if (
      element.nodeName === 'LI' ||
      element.nodeName === 'svg' ||
      element.nodeName === 'P' ||
      element.nodeName === 'path'
    ) {
      return;
    }
    setMenuIsOpen(false);
  };
  const hadleCloseNotification = () => {
    setNotification(false);
  };

  return (
    <MainContainer onClick={handleMenuClose}>
      <StyledWrapper>
        <StyledContainer>{children}</StyledContainer>
      </StyledWrapper>
    </MainContainer>
  );
};

export default Container;
