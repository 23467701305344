import BottomNavigation from '@mui/material/BottomNavigation';
import { styled } from '@mui/material';
import { BorderRadius, Color } from 'helpers/themeStyles';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

export const TapBarWrapper = styled(BottomNavigation)(() => ({
  backgroundColor: Color.BACKGROUND_TAP_BAR,
  borderRadius: BorderRadius.SEMI_MEDIUM,
  '&.MuiBottomNavigation-root': {
    alignItems: 'center',
    padding: '0px 16px',
    margin: 0,
    height: '46px',
    justifyContent: 'space-around',
  },
}));

export const TapBarContainer = styled('div')(() => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 50,
  background: Color.BACKGROUND_MAIN,
  padding: '16px 25px',
}));

export const BottomNavigationButton = styled(BottomNavigationAction)(() => ({
  '&.MuiBottomNavigationAction-root': {
    width: '32px',
    height: '32px',
    minWidth: 'auto',
    padding: 0,
    flex: 'inherit',
  },
  '&.MuiBottomNavigationAction-root:nth-child(3)': {
    width: '57px',
    height: '57px',
  },
  '&.Mui-selected:nth-child(odd)': {
    background: Color.BUTTON_ODD_TAP_BAR_GRADIENT,
    borderRadius: BorderRadius.SEMI_SMALL,
  },
  '&.Mui-selected:nth-child(3)': {
    background: 'transparent !important',
  },
  '&.Mui-selected:nth-child(even)': {
    background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
    borderRadius: BorderRadius.SEMI_SMALL,
  },
}));
