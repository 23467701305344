import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useNavigate } from 'react-router';
import {ClickAwayListener }from '@mui/material';
import { useDispatch } from 'react-redux';

import {
  PanelContainer,
  PanelWrapper,
  TabButton,
} from 'components/TopSwitchPanel/styles';
import SwapMobileIcon from 'svg/SwapMobileIcon';
import LiquidityIcon from 'svg/LiquidityIcon';
import { Color } from 'helpers/themeStyles';
import { PageRoutes } from 'config/routes';

import { openTopSwitchPanel } from 'state/swap/slice';

const TopSwitchPanel = () => {
  const [value, setValue] = useState('swap');
  const [pageURL, setPageURL] = React.useState('');
  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 'liquidity') {
      navigate(`${PageRoutes.Liquidity}`);
      setPageURL(PageRoutes.Liquidity);
    }
  }, [value]);

  const handleClick = () => {
    navigate(`${pageURL}`);
    dispatch(openTopSwitchPanel(false));
  };

  return (
    <PanelContainer sx={{ marginTop: isConnected ? '-23px' : '-16px' }}>
      <ClickAwayListener onClickAway={handleClick}>
        <PanelWrapper
          value={value}
          onChange={handleChange}
          aria-label='top switch panel tabs'
        >
          <TabButton
            icon={<SwapMobileIcon />}
            value='swap'
            label='Swap'
          />
          <div
            style={{
              content: '" "',
              margin: 'auto 0',
              width: '1px',
              height: '27px',
              background: Color.WHITE_OPACITY_LIGHT,
              opacity: '0.1',
            }}
          ></div>
          <TabButton
            icon={<LiquidityIcon />}
            sx={{
              padding: '4px 0px',
              svg: {
                background: 'none !important',
                boxShadow: 'none',
                padding: '0px',
              },
            }}
            value='liquidity'
            label='Liquidity'
          />
        </PanelWrapper>
      </ClickAwayListener>
    </PanelContainer>
  );
};

export default TopSwitchPanel;
