import {
  Button,
  InputBase,
  Paper,
  Typography,
  styled,
  Stack,
} from '@mui/material';
import theme, {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled(Paper)(({ theme }) => ({
  width: 531,
  height: 'fit-content',
  borderRadius: BorderRadius.NORMAL,
  background: Color.SWAP_GRADIENT_WITHOUT_OPACITY,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN,
    boxShadow: 'inherit',
  },
}));

export const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 16px',
  borderBottom: Border.ACCENT_BORDER,
});

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: 0,
});

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const MainContainer = styled(Typography)({
  padding: '20px 16px',
  borderBottom: Border.ACCENT_BORDER,
});

export const SelectorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  marginBottom: 16,
});

export const TokenInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
});

export const TokenNameDetails = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
  position: 'relative',
}));

export const TokenName = styled('div')(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE_PLUS,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  color: Color.WHITE,
}));

export const Divider = styled('div')(() => ({
  width: 2,
  height: 32,
  background: Color.WHITE_OPACITY,
}));

export const CopyNotification = styled('h6')(() => ({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  padding: 6,
  background: Color.AMOUNT_BACKGROUND,
  borderRadius: BorderRadius.SMALL,
  position: 'absolute',
  right: '20%',
  bottom: '-20%',
  zIndex: 1,
}));

export const TokenBalance = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
}));

export const StyledInput = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: BorderRadius.NORMAL,
  padding: 20,
  backgroundColor: Color.AMOUNT_BACKGROUND,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.EXTRA_LARGE,
  fontFamily: FontFamily.INTER,
  '& ::placeholder': {
    color: Color.WHITE,
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    '-webkit-text-fill-color': `${Color.WHITE_OPACITY}`,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const UsdTokenValue = styled('span')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  marginTop: 16,
}));

export const PercentageBtnContainer = styled('div')(() => ({
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
}));

export const PercentageBtn = styled('div')(() => ({
  borderRadius: BorderRadius.SMALL,
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  padding: '4px 6px',
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SUPER_SMALL,
  color: Color.WHITE,
  textTransform: 'uppercase',
  cursor: 'pointer',
}));

export const TipContainer = styled(Stack)({
  padding: 12,
  margin: '24px 0',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  background: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
});

export const TipText = styled('div')({
  width: '80%',
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  lineHeight: '150%',
  margin: 0,
});

export const StyledPricesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 8,
});

export const StyledPricesTitle = styled('div')({
  fontFamily: FontFamily.INTER,
  textAlign: 'center',
  fontWeight: 700,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  marginBottom: 8,
});

export const StyledPricesInfo = styled('div')({
  borderRadius: BorderRadius.NORMAL,
  border: Border.ACCENT_BORDER,
  background: Color.BACKGROUND,
  padding: 12,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledPricesInfoItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  gap: 4,

  [theme.breakpoints.down('mobile')]: {
    width: '60%',
  },
}));

export const StyledPricesInfoValue = styled('div')(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledPricesInfoTitle = styled('div')({
  fontFamily: FontFamily.INTER,
  textAlign: 'center',
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 400,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledToleranceContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 16,
});

export const StyledToleranceTitle = styled('div')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 400,
  textTransform: 'capitalize',
  color: Color.WHITE,
});

export const StyledToleranceValue = styled(StyledToleranceTitle)({
  color: Color.PURPLE,
});

export const ButtonContainer = styled('div')({
  margin: '10px 16px',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: 16,
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: 10,
  },
}));
