import React from 'react';

const ExploreIcon: React.FC = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.7 18.3C1.25 18.3 0.854167 18.1292 0.5125 17.7875C0.170833 17.4458 0 17.05 0 16.6V4.2C0 3.73333 0.170833 3.33333 0.5125 3C0.854167 2.66667 1.25 2.5 1.7 2.5H7.275C7.50833 2.5 7.70833 2.58333 7.875 2.75C8.04167 2.91667 8.125 3.11667 8.125 3.35C8.125 3.6 8.04167 3.80417 7.875 3.9625C7.70833 4.12083 7.50833 4.2 7.275 4.2H1.7V16.6H14.1V11.025C14.1 10.7917 14.1833 10.5917 14.35 10.425C14.5167 10.2583 14.7167 10.175 14.95 10.175C15.2 10.175 15.4042 10.2583 15.5625 10.425C15.7208 10.5917 15.8 10.7917 15.8 11.025V16.6C15.8 17.05 15.6333 17.4458 15.3 17.7875C14.9667 18.1292 14.5667 18.3 14.1 18.3H1.7ZM5.75 12.55C5.58333 12.3833 5.49583 12.1875 5.4875 11.9625C5.47917 11.7375 5.56667 11.5417 5.75 11.375L14.9 2.2H10.475C10.2417 2.2 10.0417 2.11667 9.875 1.95C9.70833 1.78333 9.625 1.58333 9.625 1.35C9.625 1.1 9.70833 0.895834 9.875 0.7375C10.0417 0.579167 10.2417 0.5 10.475 0.5H16.95C17.1833 0.5 17.3833 0.583333 17.55 0.75C17.7167 0.916667 17.8 1.11667 17.8 1.35V7.825C17.8 8.05833 17.7167 8.25833 17.55 8.425C17.3833 8.59167 17.1833 8.675 16.95 8.675C16.7 8.675 16.4958 8.59167 16.3375 8.425C16.1792 8.25833 16.1 8.05833 16.1 7.825V3.425L6.925 12.575C6.775 12.7417 6.58333 12.8208 6.35 12.8125C6.11667 12.8042 5.91667 12.7167 5.75 12.55Z'
        fill='white'
      />
    </svg>
  );
};

export default ExploreIcon;
