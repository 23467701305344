import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormValues } from 'components/Launchpad/CreateProjectFirstStep/FormValuesInterface';
import { API } from 'config/api';
import { API_ROUTES } from 'config/apiRoutes';
import { IPaginate } from 'types/pagination';
import { GetPostQuery, Project } from 'types/project';

export const getUpcomingProjectsThunk = createAsyncThunk<
  IPaginate<Project>,
  GetPostQuery
>(
  'launchpad/getUpcomingProjects',
  async ({ status, limit, page }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_PROJECTS_BY_STATUS(status, limit, page)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getCurrentProjectsThunk = createAsyncThunk<
  IPaginate<Project>,
  GetPostQuery
>(
  'launchpad/getCurrentProjects',
  async ({ status, limit, page }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_PROJECTS_BY_STATUS(status, limit, page)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getCompletedProjectsThunk = createAsyncThunk<
  IPaginate<Project>,
  GetPostQuery
>(
  'launchpad/getCompletedProjects',
  async ({ status, limit, page }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_PROJECTS_BY_STATUS(status, limit, page)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createProjectThunk = createAsyncThunk(
  'launchpad/createProject',
  async (
    { id, values }: { id?: string; values: FormValues },
    { rejectWithValue }
  ) => {
    try {
      if (id) {
        const { data } = await API.post(
          `${API_ROUTES.LAUNCHPAD.CREATE_OR_UPDATE_PROJECT}?id=${id}`,
          values
        );
        return data;
      } else {
        const { data } = await API.post(
          API_ROUTES.LAUNCHPAD.CREATE_OR_UPDATE_PROJECT,
          values
        );
        return data;
      }
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const updateProjectThunk = createAsyncThunk(
  'launchpad/updateProject',
  async (
    {
      id,
      values,
    }: {
      id: string;
      values: FormData | FormValues;
    },
    { rejectWithValue }
  ) => {
    try {
      await API.patch(API_ROUTES.LAUNCHPAD.UPDATE_PROJECT(id), values, {
        headers: { 'x-upload-provider': 's3' },
      });
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getProjectByIdThunk = createAsyncThunk(
  'launchpad/getProjectById',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_PROJECT_BY_ID(id)
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getProjectsByOwner = createAsyncThunk(
  'launchpad/getProjectByAddress',
  async (
    { userID, limit, page }: { userID: string; limit: number; page: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_PROJECT_BY_USER_ID(userID, limit, page)
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const deleteProjectById = createAsyncThunk(
  'launchpad/deleteProjectById',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(
        API_ROUTES.LAUNCHPAD.DELETE_PROJECT_BY_ID(id)
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const updateProjectStatusThunk = createAsyncThunk(
  'launchpad/updateProjectStatus',
  async (
    {
      id,
      status,
    }: {
      id: string;
      status: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await API.patch(API_ROUTES.LAUNCHPAD.UPDATE_PROJECT_STATUS(id), {
        status,
      });
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getUsersProjectByDay = createAsyncThunk(
  'launchpad/getUsersProjectByDay',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.LAUNCHPAD.GET_USER_PROJECTS_BY_DAY(id)
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
