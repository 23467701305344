import {
  DEXToken,
  getLinkForToken,
  getLinkForWallet,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { format } from 'date-fns';
import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { Api, ExpandMore, Search, Settings } from '@mui/icons-material';
import {
  AccordionDetails,
  Avatar,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ChangeNetworkPopup from 'components/ChangeNetworkPopup';
import NFTBuyModal from 'components/NFTBuyModal';
import { BuyStyled } from 'components/NFTBuyModal/styles';
import NFTSellModal from 'components/NFTSellModal';
import { NetworkIcon } from 'components/NetworkSelector/styles';
import { StyledNoImgContainer } from 'components/NftCollectionDetails/styles';
import NftDetailsPopup from 'components/NftDetailsPopup';
import NftDetailsBlock from 'components/NftDetailsPopup/NftDetailsBlock';
import NftMoreFromCollection from 'components/NftMoreFromCollection';
import { fetchData, fetchOffer } from 'config/api';
import { BASE_URL } from 'helpers/constants';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { formatWalletKey } from 'helpers/formatWalletKey';
import { summKeysInObject } from 'helpers/summKeysInObject';
import { Color } from 'helpers/themeStyles';
import { useAppSelector } from '@hooks/useAppSelector';
import Loader from 'shared/Loader';
import { selectedNftSelector } from 'state/nft/selectors';
import nftSlice from 'state/nft/slice';
import { getSelectedNftThunk } from 'state/nft/thunks';
import { collectionItemsSelector } from 'state/nftCollections/selectors';
import nftCollectionSlice from 'state/nftCollections/slice';
import { getCollectionItemsThunk } from 'state/nftCollections/thunks';
import { setIsOpen } from 'state/walletconnect/slice';
import {
  Offer,
  OfferOwners,
  PaymentsDexTokens,
  PaymentsTokens,
} from 'types/offer';
import {
  AccordionSubtitle,
  AccordionTitle,
  BlackBuyStyled,
  StyleDetailedBox,
  StyledAccordionSummary,
  StyledButton,
  StyledCollectionTitle,
  StyledContainer,
  StyledDescription,
  StyledDetailsContainer,
  StyledDetailsValue,
  StyledDetaliedStack,
  StyledEventBox,
  StyledHeader,
  StyledHeroContainer,
  StyledImg,
  StyledMetadatasValue,
  StyledNftDetailsStack,
  StyledNftNumber,
  StyledNftPropertiesItem,
  StyledOwnerAddress,
  StyledOwnerBox,
  StyledOwnerInfoContainer,
  StyledOwnerProperties,
  StyledOwnerTitle,
  StyledPrice,
  StyledPriceAndSellBtnStack,
  StyledPriceBlock,
  StyledPriceValue,
  StyledPriceValueSmall,
  StyledPropertiesPercent,
  StyledPropertiesTitle,
  StyledPropertiesValue,
  StyledSaleEventsStack,
  StyledSellBtn,
  StyledTableCell,
  StyledTableText,
  StyledTableTitle,
  StyledTitle,
  StyledUSDPriceValue,
  StyledUSDPriceValueSmall,
} from './styles';

import { useAsUsd } from '@hooks/useAsUsd';
import { useSigner } from '@hooks/useSigner';
import { providers } from 'ethers';
import isOfferBlacklisted from 'helpers/isOfferBlacklisted';
import DefaultTokenIcon from 'svg/DefaultTokenIcon';
import { NftCollection } from 'types/nftCollection';
import { NftEvents } from 'types/nftEvents';

const NftDetails = () => {
  const theme = useTheme();
  const wagmiSigner = useSigner();
  const [signer, setSigner] = useState<providers.JsonRpcSigner>();
  const { chain } = useAccount();
  useEffect(() => {
    if (wagmiSigner) {
      setSigner(wagmiSigner);
    }
  }, [wagmiSigner]);
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const is1440 = useMediaQuery(theme.breakpoints.between('mobile', 1440));
  const selectedNft = useAppSelector(selectedNftSelector);
  const collectionItems = useAppSelector(collectionItemsSelector);
  const { address } = useAccount();
  const isOwner = !!selectedNft?.nft.owner[address?.toLowerCase()!];
  const ownerObject = selectedNft?.nft.owner || {};
  const metadataUrl = `${BASE_URL}nft/metadata/${selectedNft?.nft.asset_contract}/${selectedNft?.nft.token_id}`;

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [openSellModal, setOpenSellModal] = useState(false);
  const [price, setPrice] = useState();
  const [priceOwners, setPriceOwners] = useState<OfferOwners>({});
  const [singleCollection, setsingleCollection] = useState<NftCollection>();
  const [priceOwnersUSD, setPriceOwnersUSD] = useState<OfferOwners>({});
  const [priceUSD, setPriceUSD] = useState<string>('-');
  const [lowestPriceUSD, setLowestPriceUSD] = useState<string>('');
  const [payableToken, setPayableToken] = useState<string>();
  const [offer, setOffer] = useState<Offer>();
  const [token, setToken] = useState<DEXToken>();
  const [isChangeNetworkOpen, setIsChangeNetworkOpen] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [nftEvents, setNftEvents] = useState<NftEvents[]>([]);
  const [processedNftEvents, setProcessedNftEvents] = useState<NftEvents[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [paymentsTokens, setPaymentsTokens] = useState<PaymentsTokens>({});
  const [paymentsDexTokens, setPaymentsDexTokens] = useState<PaymentsDexTokens>(
    {}
  );
  const { getValueInUsd } = useAsUsd();
  const filteredCollectionItems = collectionItems?.filter(
    (item) => item._id !== id
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const currentOwner =
    selectedNft &&
    Object.keys(selectedNft?.nft.owner!).find(
      (key) => selectedNft?.nft.owner[key]
    );
  async function fetchNftOffer() {
    try {
      if (address) {
        const { data } = isOwner
          ? await fetchOffer(`offers/nft/${id}`, {
              from: address.toLowerCase(),
            })
          : await fetchOffer(`offers/nft/${id}`);
        setOffer(data);
        setPrice(data.pay_amount);
        setPayableToken(data.payable_token);
      }
    } catch (error) {
      setPrice(undefined);
    }
  }
  useEffect(() => {
    fetchNftOffer();
  }, [id, isOwner, chain?.id, address, signer]);
  async function fetchNftCollection(id: string) {
    try {
      const { data } = await fetchData(`nft-collection/one/${id}`);
      setsingleCollection(data.nftCollection);
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchNftOfferForAll() {
    if (address) {
      const { data } = await fetchOffer(`offers/all/nft/${id}`);
      const offerOwner: OfferOwners = {};
      const paymentTokens: PaymentsTokens = {};
      data.forEach((offer: any) => {
        const ownerAddress = offer.from.toLowerCase();
        offerOwner[ownerAddress] = offer.pay_amount;
        paymentTokens[ownerAddress] = offer.payable_token_inner;
      });
      data && setPriceOwners(offerOwner);
      data && setPaymentsTokens(paymentTokens);
    }
  }
  async function processNftEvents() {
    try {
      if (singleCollection?.chain_id) {
        setLoading(true);
        const nftEventsWithUsdPriceAndIcon = [];

        for (const nftEvent of nftEvents) {
          const { data } = await fetchData(
            `/tokens?address=${nftEvent.payable_token}&chainId=${singleCollection?.chain_id}`
          );
          const tokenFromAddress = DEXToken.fromAddress(
            nftEvent.payable_token!,
            BigNumber.from(nftEvent.pay_amount),
            singleCollection?.chain_id,
            data.docs[0].decimals,
            data.docs[0].symbol,
            data.docs[0].name,
            data.docs[0].logoURI,
            false
          );
          const token =
            singleCollection?.chain_id !== chain?.id
              ? tokenFromAddress
              : await DEXToken.fromBlockchain(nftEvent.payable_token, signer!);
          token.amount = BigNumber.from(nftEvent.pay_amount);
          const usdPrice = await getValueInUsd({
            token: token,
            value: utils.formatUnits(token.amount, token.decimals),
          });
          nftEventsWithUsdPriceAndIcon.push({
            ...nftEvent,
            pay_amount: utils.formatUnits(
              `${nftEvent.pay_amount}`,
              token?.decimals
            ),
            pay_amount_usd: isOfferBlacklisted(nftEvent.status)
              ? ''
              : usdPrice ?? '0',
            payable_token_icon: token.icon || token.logoUri!,
          });
        }
        setProcessedNftEvents(nftEventsWithUsdPriceAndIcon);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setProcessedNftEvents(nftEvents);
      setLoading(false);
    }
  }

  useEffect(() => {
    const priceOwnersUSDArray = Object.values(priceOwnersUSD);
    const sortedPriceOwnersUSDArray = priceOwnersUSDArray.sort(
      (a, b) => Number(a) - Number(b)
    );
    setLowestPriceUSD(
      sortedPriceOwnersUSDArray.length > 0
        ? String(sortedPriceOwnersUSDArray[0])
        : '-'
    );
  }, [priceOwnersUSD, signer]);

  async function fetchNftEvents() {
    try {
      const { data } = await fetchData(`offers/statuses/${id}`);
      setNftEvents(data);
    } catch (error) {
      console.error(error);
    }
  }

  const getPayableToken = async () => {
    if (payableToken && signer && singleCollection?.chain_id) {
      try {
        if (singleCollection?.chain_id !== chain?.id) {
          const { data } = await fetchData(
            `/tokens?address=${payableToken}&chainId=${singleCollection?.chain_id}`
          );
          const tokenFromAddress = DEXToken.fromAddress(
            payableToken,
            BigNumber.from(price),
            singleCollection?.chain_id,
            data.docs[0].decimals,
            data.docs[0].symbol,
            data.docs[0].name,
            data.docs[0].logoUri,
            false
          );
          setToken(tokenFromAddress);
        } else {
          const tokenFromBlockhain = await DEXToken.fromBlockchain(
            payableToken,
            signer
          );
          if (price) {
            tokenFromBlockhain.amount = BigNumber.from(price);
            setToken(tokenFromBlockhain);
          }
          if (!price) {
            setToken(tokenFromBlockhain);
          }
        }
      } catch (err) {}
    }
  };
  useEffect(() => {
    async function getPricesInUSD() {
      if (!signer || !token) {
        return;
      }
      if (Object.keys(priceOwners).length > 0) {
        const offerOwnerUSD: OfferOwners = {};
        const paymentsDexToken: PaymentsDexTokens = {};
        for (const owner in priceOwners) {
          const tokenFromAddress = DEXToken.fromAddress(
            paymentsTokens[owner].address,
            BigNumber.from(`${priceOwners[owner]}`),
            paymentsTokens[owner].chainId,
            paymentsTokens[owner].decimals,
            paymentsTokens[owner].symbol,
            paymentsTokens[owner].name,
            // @ts-ignore - additional check cause in some tokens we have logoURI instead logoUri
            paymentsTokens[owner].logoURI
              ? // @ts-ignore
                paymentsTokens[owner].logoURI
              : paymentsTokens[owner].logoUri
          );
          const usdPrice = await getValueInUsd({
            token: tokenFromAddress,
            value: utils.formatUnits(
              tokenFromAddress.amount,
              tokenFromAddress.decimals
            ),
          });
          offerOwnerUSD[owner] = usdPrice?.slice(0, 7) ?? '0';
          paymentsDexToken[owner] = tokenFromAddress;
        }
        setPriceOwnersUSD(offerOwnerUSD);
        setPaymentsDexTokens(paymentsDexToken);
      }
      if (price) {
        // format price to a regulat number (eg from 0x0de0b6b3a7640000 to 1), without this we have bugs when use asUSD method
        const formattedPrice = utils.formatUnits(price, token.decimals);
        // format price from 1 to BigNumber
        token.amount = utils.parseUnits(formattedPrice, token.decimals);
        const usdPrice = await getValueInUsd({
          token: token,
          value: utils.formatUnits(token.amount, token.decimals),
        });
        setPriceUSD(usdPrice?.slice(0, 7) ?? '0');
      }
    }
    getPricesInUSD();
  }, [price, signer, priceOwners, token, id, offer, chain?.id]);
  useEffect(() => {
    if (nftEvents.length && signer) {
      processNftEvents();
    }
  }, [nftEvents, signer]);
  useEffect(() => {
    if (singleCollection?.chain_id === chain?.id) {
      setError(false);
    }
    if (singleCollection?.chain_id !== chain?.id) {
      setError(true);
    }
  }, [chain?.id, singleCollection?.chain_id]);

  useEffect(() => {
    fetchNftEvents();
  }, [
    selectedNft,
    id,
    token,
    payableToken,
    singleCollection?.chain_id,
    address,
  ]);

  useEffect(() => {
    getPayableToken();
  }, [payableToken, chain?.id, signer, singleCollection?.chain_id]);

  useEffect(() => {
    if (id) {
      dispatch(getSelectedNftThunk(id));
    }
    return function cleanup() {
      dispatch(nftSlice.actions.resetSelectedNft());
    };
  }, [id]);

  useEffect(() => {
    if (selectedNft) {
      dispatch(getCollectionItemsThunk(selectedNft?.nft.nft_collection || ''));
    }
    return function cleanup() {
      dispatch(nftCollectionSlice.actions.resetCollectionItems());
    };
  }, [id, selectedNft, dispatch]);

  useEffect(() => {
    if (selectedNft) {
      fetchNftCollection(selectedNft.nft.nft_collection);
    }
  }, [selectedNft?.nft.nft_collection]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    fetchNftOfferForAll();
  }, [id, isOwner, chain?.id, address]);
  const summedNftNumber = summKeysInObject(selectedNft?.nft.owner);
  const ownerBlock = useMemo(() => {
    const rows = [];

    for (const owner in ownerObject) {
      if (priceOwners[owner]) {
        rows.push(
          <StyledOwnerInfoContainer>
            <Link
              to=''
              style={{
                textDecoration: 'none',
                color: Color.BACKGROUND,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                gap={1}
              >
                <Stack>
                  <StyledOwnerAddress>
                    {owner === address
                      ? formatWalletKey(address!)
                      : formatWalletKey(owner)}
                  </StyledOwnerAddress>
                  <StyledOwnerAddress>-</StyledOwnerAddress>
                </Stack>
              </Stack>
            </Link>
            {!(owner === address?.toLowerCase()) &&
              !error &&
              selectedNft?.isForSale && (
                <NFTBuyModal
                  black={true}
                  nftID={id!}
                  totalSupply={summedNftNumber}
                  price={String(priceOwners[owner])}
                  selectedNFT={selectedNft}
                  payableToken={payableToken!}
                  offerFrom={owner}
                />
              )}
            {owner === address?.toLowerCase() ? (
              <BlackBuyStyled
                style={{
                  color: !address ? Color.DARK_PURPLE : Color.PURPLE,
                  borderColor: !address ? Color.DARK_PURPLE : Color.PURPLE,
                  margin: isOwner ? 0 : 'inherit',
                }}
              >
                {t('you')}
              </BlackBuyStyled>
            ) : owner !== address?.toLowerCase() &&
              selectedNft?.isForSale &&
              error ? (
              <BlackBuyStyled
                style={{
                  color: !address ? Color.DARK_PURPLE : Color.PURPLE,
                  borderColor: !address ? Color.DARK_PURPLE : Color.PURPLE,
                  cursor: 'pointer',
                  margin: 0,
                }}
                onClick={() => setIsChangeNetworkOpen(true)}
              >
                {t('buy')}
              </BlackBuyStyled>
            ) : null}
          </StyledOwnerInfoContainer>
        );
      }
    }
    return rows;
  }, [
    ownerObject,
    priceOwners,
    address,
    isMobile,
    error,
    selectedNft,
    id,
    summedNftNumber,
    price,
    priceUSD,
    payableToken,
    token,
    t,
  ]);
  const priceBlock = useMemo(() => {
    const rows = [];
    for (const owner in ownerObject) {
      if (priceOwners[owner]) {
        rows.push(
          <StyledPriceBlock>
            <StyledPriceValueSmall
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
              }}
            >
              {owner !== address ? (
                <>
                  {paymentsDexTokens[owner] ? (
                    <NetworkIcon
                      src={paymentsDexTokens[owner].icon}
                      style={{ width: '18px', height: '18px' }}
                    />
                  ) : (
                    <DefaultTokenIcon />
                  )}

                  {utils.formatUnits(
                    `${priceOwners[`${owner}`]}`,
                    paymentsDexTokens[owner]?.decimals
                  )}
                </>
              ) : (
                '-'
              )}
            </StyledPriceValueSmall>
            <StyledUSDPriceValueSmall>
              {priceUSD && token?.chainId === chain?.id
                ? `(~${priceOwnersUSD[`${owner}`]} USD)`
                : '-'}
            </StyledUSDPriceValueSmall>
          </StyledPriceBlock>
        );
      }
    }
    return rows;
  }, [address, priceOwners, priceOwnersUSD, priceUSD, token, ownerObject]);
  return (
    <>
      <Box m={isMobile ? '24px 0px' : '40px 16px'}>
        <StyledHeader>
          <StyledHeroContainer>
            <Stack
              position='relative'
              width={isMobile ? '100%' : '45%'}
            >
              <StyledNftNumber>
                x{' '}
                {selectedNft?.nft.owner[address?.toLowerCase()!]
                  ? selectedNft?.nft.owner[address?.toLowerCase()!]
                  : summedNftNumber}
              </StyledNftNumber>
              {selectedNft?.nft.image && !imgError ? (
                <StyledImg
                  src={formatIpfsUrl(selectedNft?.nft.image[0])}
                  alt='nft title'
                  onError={() => setImgError(true)}
                />
              ) : (
                <StyledNoImgContainer sx={{ height: '400px' }}>
                  <Loader />
                  <StyledDescription mt={4}>
                    {t('imageIsLoadingNow')}
                  </StyledDescription>
                </StyledNoImgContainer>
              )}
            </Stack>
            <StyledDetailsContainer width={isMobile ? '100%' : '55%'}>
              <Link
                to={`/nft-marketplace/collections/${selectedNft?.nft.nft_collection}`}
                style={{ textDecoration: 'none', color: Color.BACKGROUND }}
              >
                <StyledCollectionTitle>
                  {selectedNft?.nft.nft_collection_name}
                </StyledCollectionTitle>
              </Link>
              <StyledTitle sx={{ wordBreak: 'break-all' }}>
                {selectedNft?.nft.name}
              </StyledTitle>
              {!is1440 && (
                <StyledDescription>
                  {selectedNft?.nft.description}
                </StyledDescription>
              )}
              {is1440 &&
                selectedNft?.nft.description &&
                selectedNft?.nft.description.length <= 120 && (
                  <StyledDescription>
                    {selectedNft?.nft.description}
                  </StyledDescription>
                )}
              <StyledPriceAndSellBtnStack>
                <Stack
                  direction={isMobile ? 'row' : 'column'}
                  justifyContent={isMobile ? 'space-between' : 'initial'}
                  alignItems={isMobile ? 'baseline' : 'start'}
                  gap={1}
                  mb={2}
                >
                  <StyledPrice>{t('price')}</StyledPrice>
                  {isMobile ? (
                    <div>
                      <StyledPriceValue>
                        {price && offer ? (
                          <>
                            {!!token?.icon ? (
                              <NetworkIcon src={token?.icon} />
                            ) : (
                              <DefaultTokenIcon />
                            )}

                            {`${utils.formatUnits(
                              `${price}`,
                              token?.decimals
                            )} ${!error ? token?.symbol : ''} 
                            `}
                          </>
                        ) : (
                          '-'
                        )}
                      </StyledPriceValue>
                      {priceUSD &&
                        token?.chainId === chain?.id &&
                        offer &&
                        price && (
                          <StyledUSDPriceValue>{`(~${priceUSD} USD)`}</StyledUSDPriceValue>
                        )}
                    </div>
                  ) : (
                    <>
                      <StyledPriceValue
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '5px',
                        }}
                      >
                        {price && offer && token ? (
                          <>
                            {!!token?.icon ? (
                              <NetworkIcon src={token?.icon} />
                            ) : (
                              <DefaultTokenIcon />
                            )}

                            {`${utils.formatUnits(
                              `${price}`,
                              token?.decimals
                            )} ${!!token?.symbol ? token?.symbol : ''} 
                            `}
                          </>
                        ) : (
                          '-'
                        )}
                      </StyledPriceValue>
                      {priceUSD &&
                        token?.chainId === chain?.id &&
                        offer &&
                        price && (
                          <StyledUSDPriceValue>{`(~${priceUSD} USD)`}</StyledUSDPriceValue>
                        )}
                    </>
                  )}
                </Stack>
                {isOwner && (
                  <StyledSellBtn
                    onClick={() =>
                      !error
                        ? setOpenSellModal(true)
                        : setIsChangeNetworkOpen(true)
                    }
                    disabled={
                      offer?.from === address?.toLowerCase() || !address
                    }
                  >
                    {t('sell')}
                  </StyledSellBtn>
                )}
                {!isOwner && selectedNft?.isForSale && !error && (
                  <NFTBuyModal
                    black={false}
                    nftID={id!}
                    totalSupply={summedNftNumber}
                    price={price!}
                    selectedNFT={selectedNft}
                    payableToken={payableToken!}
                  />
                )}
                {!isOwner && selectedNft?.isForSale && error && (
                  <BuyStyled
                    style={{
                      color: !address ? Color.WHITE_OPACITY_LIGHT : Color.WHITE,
                    }}
                    disabled={!address}
                    onClick={() => setIsChangeNetworkOpen(true)}
                  >
                    {t('buy')}
                  </BuyStyled>
                )}
              </StyledPriceAndSellBtnStack>
            </StyledDetailsContainer>
          </StyledHeroContainer>
          {selectedNft?.nft.description &&
            selectedNft?.nft.description.length >= 120 &&
            is1440 && (
              <StyledDescription>
                {selectedNft?.nft.description}
              </StyledDescription>
            )}
        </StyledHeader>
        <StyledContainer>
          <StyledDetaliedStack>
            <StyleDetailedBox
              defaultExpanded
              sx={{ mb: 2 }}
            >
              <StyledAccordionSummary expandIcon={<ExpandMore />}>
                <Settings sx={{ mr: 1 }} />
                <AccordionTitle>{t('manageYours')}</AccordionTitle>
              </StyledAccordionSummary>
              <AccordionDetails>
                {address && isOwner ? (
                  <NftDetailsBlock
                    selectedNFT={selectedNft}
                    onDetailsOpen={() => setDetailsModalOpen(true)}
                    forSale={offer?.from === address?.toLowerCase()}
                    price={
                      price
                        ? utils.formatUnits(`${price}`, token?.decimals)
                        : '-'
                    }
                    lowestPriceUSD={lowestPriceUSD}
                    icon={token?.icon!}
                  />
                ) : address ? (
                  <AccordionSubtitle>
                    {t('youDontHaveAnyItems')}
                  </AccordionSubtitle>
                ) : (
                  <StyledButton
                    variant='contained'
                    onClick={() => dispatch(setIsOpen(true))}
                    fullWidth
                  >
                    {t('connectWallet')}
                  </StyledButton>
                )}
              </AccordionDetails>
            </StyleDetailedBox>
            <StyleDetailedBox
              defaultExpanded
              sx={{
                mb: 2,
              }}
            >
              <StyledAccordionSummary expandIcon={<ExpandMore />}>
                <Api sx={{ mr: 1 }} />
                <AccordionTitle>
                  {t('properties')} (
                  {selectedNft?.nft.attributes.length &&
                    selectedNft?.nft.attributes.length}
                  )
                </AccordionTitle>
              </StyledAccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: '230px',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Color.BACKGROUND_MAIN,
                  },
                }}
              >
                <Stack mt={1}>
                  {selectedNft?.nft.attributes.map(
                    ({ trait_type, value }, id) => (
                      <StyledNftPropertiesItem key={id}>
                        <StyledPropertiesTitle>
                          {trait_type}
                        </StyledPropertiesTitle>
                        <StyledPropertiesValue>
                          {value}{' '}
                          <StyledPropertiesPercent>
                            ({' '}
                            {singleCollection?.rarity
                              ? `${
                                  100 /
                                  singleCollection.rarity[trait_type][value]
                                } %`
                              : ''}
                            )
                          </StyledPropertiesPercent>
                        </StyledPropertiesValue>
                      </StyledNftPropertiesItem>
                    )
                  )}
                </Stack>
              </AccordionDetails>
            </StyleDetailedBox>
            <StyleDetailedBox
              defaultExpanded
              sx={{ mb: 2 }}
            >
              <StyledAccordionSummary expandIcon={<ExpandMore />}>
                <Search sx={{ mr: 1 }} />
                <AccordionTitle>{t('details')}</AccordionTitle>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Stack mt={1}>
                  <StyledNftDetailsStack>
                    <StyledPropertiesTitle>
                      {t('contractAddress')}
                    </StyledPropertiesTitle>
                    <StyledDetailsValue
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() =>
                        window.open(
                          getLinkForToken(
                            chain?.id!,
                            selectedNft?.nft.asset_contract!
                          )
                        )
                      }
                    >
                      {formatWalletKey(selectedNft?.nft.asset_contract!)}
                    </StyledDetailsValue>
                  </StyledNftDetailsStack>
                  <StyledNftDetailsStack>
                    <StyledPropertiesTitle>
                      {t('ipfsJSON')}
                    </StyledPropertiesTitle>
                    <StyledMetadatasValue
                      to={metadataUrl}
                      target='_blank'
                    >
                      {metadataUrl.slice(0, 12) + '...'}
                    </StyledMetadatasValue>
                  </StyledNftDetailsStack>
                  {singleCollection?.royalties_address &&
                  singleCollection.royalties_percent ? (
                    <StyledNftDetailsStack>
                      <StyledPropertiesTitle
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          window.open(
                            getLinkForWallet(
                              chain?.id!,
                              singleCollection?.royalties_address
                            )
                          )
                        }
                      >
                        {t('creatorFee')}
                      </StyledPropertiesTitle>
                      <StyledDetailsValue>
                        {singleCollection.royalties_percent} %
                      </StyledDetailsValue>
                    </StyledNftDetailsStack>
                  ) : null}
                  {selectedNft?.nft.external_url && (
                    <StyledNftDetailsStack>
                      <StyledPropertiesTitle>
                        {t('externalURL')}
                      </StyledPropertiesTitle>
                      <StyledMetadatasValue
                        to={selectedNft?.nft.external_url}
                        target='_blank'
                      >
                        {selectedNft?.nft.external_url.slice(0, 12) + '...'}
                      </StyledMetadatasValue>
                    </StyledNftDetailsStack>
                  )}
                </Stack>
              </AccordionDetails>
            </StyleDetailedBox>
          </StyledDetaliedStack>
          <StyledSaleEventsStack>
            <StyledOwnerBox sx={{ py: 2 }}>
              <StyledOwnerTitle>{t('forSale')}</StyledOwnerTitle>
              {Object.keys(priceOwners).length ? (
                <Stack direction='row'>
                  <Stack width='35%'>
                    <StyledOwnerProperties>{t('price')}</StyledOwnerProperties>
                    {priceBlock}
                  </Stack>
                  <Stack width='65%'>
                    <StyledOwnerProperties>{t('owner')}</StyledOwnerProperties>
                    {ownerBlock}
                  </Stack>
                </Stack>
              ) : (
                <StyledDescription
                  sx={{ margin: '32px 16px 16px', textAlign: 'center' }}
                >
                  {t('nftIsNotForSaleYet')}
                </StyledDescription>
              )}
            </StyledOwnerBox>
            <StyledEventBox sx={{ pb: 2 }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <StyledTableCell>
                      <StyledTableTitle>{t('event')}</StyledTableTitle>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableTitle>{t('price')}</StyledTableTitle>
                    </StyledTableCell>
                    {!isMobile && (
                      <>
                        <StyledTableCell>
                          <StyledTableTitle>{t('from')}</StyledTableTitle>
                        </StyledTableCell>
                        <StyledTableCell>
                          <StyledTableTitle>{t('to')}</StyledTableTitle>
                        </StyledTableCell>
                      </>
                    )}
                    <StyledTableCell>
                      <StyledTableTitle>{t('date')}</StyledTableTitle>
                    </StyledTableCell>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5}>
                        <Stack
                          alignItems='center'
                          mt={2}
                          mb={1}
                        >
                          <Loader />
                        </Stack>
                      </td>
                    </tr>
                  ) : !processedNftEvents.length ? (
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={5}>
                        <StyledDescription sx={{ margin: '32px 16px 16px' }}>
                          {t('noTransactionsYet')}
                        </StyledDescription>
                      </td>
                    </tr>
                  ) : (
                    processedNftEvents?.map(
                      (
                        {
                          updatedAt,
                          from,
                          pay_amount,
                          pay_amount_usd,
                          status,
                          payable_token_icon,
                          to,
                        },
                        index
                      ) => (
                        <tr key={index}>
                          <td>
                            <StyledTableText
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {status}
                            </StyledTableText>
                          </td>
                          <td>
                            <Stack>
                              {!pay_amount || isOfferBlacklisted(status) ? (
                                <StyledOwnerAddress
                                  sx={{ textAlign: 'center' }}
                                >
                                  -
                                </StyledOwnerAddress>
                              ) : (
                                <>
                                  <StyledPriceValueSmall>
                                    {payable_token_icon ? (
                                      <Avatar
                                        src={payable_token_icon}
                                        sx={{
                                          width: '24px',
                                          height: '24px',
                                          marginRight: '8px',
                                        }}
                                      />
                                    ) : (
                                      <DefaultTokenIcon />
                                    )}
                                    {pay_amount}
                                  </StyledPriceValueSmall>
                                  <StyledUSDPriceValueSmall>
                                    {pay_amount_usd &&
                                    token?.chainId === chain?.id
                                      ? `(~${pay_amount_usd.slice(0, 7)} USD)`
                                      : '-'}
                                  </StyledUSDPriceValueSmall>
                                </>
                              )}
                            </Stack>
                          </td>
                          {!isMobile && (
                            <>
                              <td>
                                <StyledOwnerAddress
                                  style={{ padding: '8px 16px' }}
                                >
                                  {formatWalletKey(from)}
                                </StyledOwnerAddress>
                              </td>
                              <td>
                                <StyledOwnerAddress
                                  sx={{ textAlign: 'start' }}
                                  style={{ padding: '8px 16px' }}
                                >
                                  {to ? formatWalletKey(to) : '-'}
                                </StyledOwnerAddress>
                              </td>
                            </>
                          )}
                          <td>
                            <Stack alignItems='flex-start'>
                              <StyledOwnerAddress sx={{ textAlign: 'center' }}>
                                {updatedAt &&
                                  format(new Date(updatedAt), 'dd/MM/yyyy')}
                              </StyledOwnerAddress>
                              <StyledUSDPriceValueSmall>
                                {updatedAt &&
                                  format(new Date(updatedAt), 'h.mm a')}
                              </StyledUSDPriceValueSmall>
                            </Stack>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {/* <Pagination
                count={TOTAL_PAGES}
                page={page}
                onChange={handleChange}
                renderItem={(item) => <CustomPaginationItem {...item} />}
                sx={{
                  '& .MuiPagination-ul': { justifyContent: 'center' },
                }}
              /> */}
            </StyledEventBox>
          </StyledSaleEventsStack>
        </StyledContainer>
        {!!filteredCollectionItems?.length &&
          filteredCollectionItems?.length >= 1 && (
            <NftMoreFromCollection nfts={filteredCollectionItems} />
          )}
      </Box>
      <NftDetailsPopup
        isOpen={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        totalSupply={summedNftNumber}
        price={price ? utils.formatUnits(`${price}`, token?.decimals) : '-'}
        priceUSD={priceUSD!}
        lowestPriceUSD={lowestPriceUSD}
        offerFrom={offer?.from ? offer?.from : ''}
        previousPrice={
          price ? utils.formatUnits(`${price}`, token?.decimals) : ''
        }
        token={token}
        changeNetworkOpen={() => setIsChangeNetworkOpen(true)}
        collectionChainId={singleCollection?.chain_id}
      />
      {openSellModal && (
        <NFTSellModal
          title={t('setPrice')}
          buttonText={t('enableListing')}
          open={openSellModal}
          setOpen={setOpenSellModal}
          totalSupply={summedNftNumber}
          selectedNFT={selectedNft!}
        />
      )}
      <ChangeNetworkPopup
        isOpen={isChangeNetworkOpen}
        onClose={() => setIsChangeNetworkOpen(false)}
        chainId={singleCollection?.chain_id!}
        unsetError={() => setError(false)}
        title='switchNetworkForBuyNft'
      />
    </>
  );
};

export default NftDetails;
