import { useTranslation } from 'react-i18next';
import { Pagination, SelectChangeEvent, Stack } from '@mui/material';
import { useAccount } from 'wagmi';

import NftCard from 'components/NftCard';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';
import { Nft } from 'types/nft';
import {
  NoNftContainer,
  NoNftsText,
  selectMenuListStyle,
  selectPaperStyle,
  StyledContainer,
  StyledHeaderContainer,
  StyledMenuItem,
  StyledResultsTitle,
  StyledSelect,
  StyledSortBy,
} from './styles';
import { NftCollection } from 'types/nftCollection';
import { StyledLink } from 'components/NftMyCollectionsAndNFTs/styles';
import { PageRoutes } from 'config/routes';
import NoNftsIcon from 'svg/NoNftsIcon';

const NftCollectionItems = ({
  nfts,
  totalNfts,
  totalPages,
  page,
  handlePageChange,
  collection,
}: // sortBy,
// setSortBy,
{
  nfts: Array<Nft>;
  totalNfts: number;
  totalPages: number;
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  collection?: NftCollection;
  // sortBy: string;
  // setSortBy: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  // const handleChange = (event: SelectChangeEvent<string | unknown>) => {
  //   if (typeof event.target.value === 'string') setSortBy(event.target.value);
  // };

  const userId = localStorage.getItem('userId');

  return (
    <Stack py={2}>
      <StyledHeaderContainer>
        <StyledResultsTitle>{`${totalNfts ? totalNfts : 0} ${
          totalNfts === 1 ? t('item') : t('items')
        }`}</StyledResultsTitle>
        {/* <Stack
          gap={1}
          direction='row'
          alignItems='center'
        >
          <StyledSortBy>{t('sortBy')}:</StyledSortBy>
          <StyledSelect
            value={sortBy}
            name='sort'
            onChange={handleChange}
            MenuProps={{
              MenuListProps: {
                style: selectMenuListStyle,
              },
              PaperProps: {
                style: selectPaperStyle,
              },
            }}
          >
            <StyledMenuItem value='date'>{t('dateAdded')}</StyledMenuItem>
            <StyledMenuItem value='asc'>{t('lowestPrice')}</StyledMenuItem>
            <StyledMenuItem value='desc'>{t('highestPrice')}</StyledMenuItem>
          </StyledSelect>
        </Stack> */}
      </StyledHeaderContainer>
      {!nfts.length && (
        <NoNftContainer>
          <NoNftsIcon />
          <NoNftsText>{t('thereIsNoNftInCollection')}</NoNftsText>
          {address && userId === collection?.owner._id && (
            <StyledLink
              to={PageRoutes.CreateNft}
              state={{ collectionId: collection._id }}
            >
              {t('createNft')}
            </StyledLink>
          )}
        </NoNftContainer>
      )}

      {!!nfts.length && (
        <StyledContainer>
          {nfts.map(({ _id, image, name, nft_collection, chain_id }) => (
            <NftCard
              id={_id}
              key={_id}
              image={image}
              collection={nft_collection}
              name={name}
              chainId={chain_id}
            />
          ))}
        </StyledContainer>
      )}
      {totalNfts > 1 && (
        <Stack>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            sx={{ margin: '0 auto 40px' }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default NftCollectionItems;
