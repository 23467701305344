import {
  Box,
  Button,
  IconButton,
  InputBase,
  InputLabel,
  List,
  Popper,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const ContainerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',

  [theme.breakpoints.down('mobile')]: {
    marginRight: '-24px',
    marginLeft: '-24px',
  },
}));

export const StyledConatiner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: Color.SWAP_GRADIENT,
  width: '672px',
  borderRadius: BorderRadius.NORMAL,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN,
  },
}));

export const StyledIconButton = styled(IconButton)({
  '&.MuiButtonBase-root': {
    padding: 0,
    color: Color.WHITE,
  },
});

export const StyledTitle = styled('h2')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  lineHeight: '150%',
  margin: 0,
}));

export const RequiredFields = styled('p')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  margin: 0,
  padding: '0px 16px',
  marginTop: '10px',
  marginBottom: '16px',
  color: Color.WHITE_OPACITY_LIGHT,
});
export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  padding: '22.5px 16px',
  boxSizing: 'border-box',

  [theme.breakpoints.down('mobile')]: {
    borderBottom: Border.ACCENT_BORDER,
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const Subtitle = styled('p')({
  margin: 0,
  marginBottom: '8px',
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
});
export const RequiredStar = styled('span')({
  color: Color.PINK,
});
export const Description = styled('p')(({ theme }) => ({
  margin: 0,
  marginBottom: '8px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  fontWeight: 400,
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledSection = styled('div')(({ theme }) => ({
  marginBottom: '30px',

  [theme.breakpoints.down('mobile')]: {
    marginBottom: 0,
  },
}));

export const StyledInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '30px',

  [theme.breakpoints.down('mobile')]: {
    marginBottom: '16px',
  },
}));

export const PaddingContainer = styled('div')({
  padding: '0px 16px',
});
export const StyledInput = styled(TextField)({
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
  color: `${Color.WHITE}!important`,
  borderRadius: BorderRadius.SMALL,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    border: 'none!important',
    '&:hover fieldset': {
      border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
    },
  },
  '& .MuiInputBase-root': {
    color: Color.WHITE,
  },
});

export const StyledCustomInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
  paddingLeft: '14px',
  borderRadius: BorderRadius.SMALL,
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
});

export const StyledCustomInput = styled(InputBase)({
  padding: '8.5px 0px',
  '& .MuiInputBase-input': {
    padding: 0,
  },
  color: Color.WHITE,
});

export const StyledLinksContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledLinksInput = styled(TextField)({
  border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
  color: `${Color.WHITE}!important`,
  borderRadius: BorderRadius.SMALL,

  width: '100%',
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
    },
  },
  '& .MuiInputBase-root': {
    color: Color.WHITE,
  },
});

export const AppBarButton = styled(Button)({
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  textTransform: 'capitalize',
  color: Color.WHITE,
  justifyContent: 'space-between',
  padding: '8px 14px 8px 8px',
  minWidth: '135px',
  height: '40px',
  borderRadius: BorderRadius.HUGE,
  border: `1px solid ${Color.WHITE_OPACITY}`,
  '&.MuiButtonBase-root': {
    justifyContent: 'center',
    gap: '8px',
  },
  '&:hover': {
    background: Color.BORDER_WHITE_EXTRA_OPACITY,
  },
});

export const NetworkName = styled(Typography)({
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
});
export const NetworkIcon = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
});
export const NetworkPopper = styled(Popper)({
  paddingTop: '15px',
  zIndex: 1101,
});
export const NetworkBox = styled(Box)({
  minWidth: '200px',
  padding: '16px',
  boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,
});
export const NetworkList = styled(List)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& .MuiTypography-root': {
    fontFamily: FontFamily.INTER,
  },
});
export const StyledSelect = styled(Select)({
  borderRadius: BorderRadius.NORMAL,
  background: Color.DARK_PURPLE_OPACITY_MIDDLE,
  '& .MuiInputBase-root': {
    '& .MuiOutlinedInput-root': {
      '& .MuiSelect-root Mui-focused': {
        '& .Mui-focused': {
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            '&.Mui-focused': {
              border: 'none',
            },
          },
        },
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
});
export const StyledNetworkSelect = styled(Select)({
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
  color: Color.WHITE,
  '& .MuiSvgIcon-root': {
    color: Color.WHITE,
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select': {
    color: Color.WHITE,
    fontFamily: FontFamily.ZENDOTS,
    fontSize: TextSize.SMALL,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
});
export const StyledInputLabel = styled(InputLabel)({
  fontFamily: FontFamily.ZENDOTS,
  fontweight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  '&.MuiFormLabel-root': {
    color: Color.WHITE,
  },
});
export const StyledTokenContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '8px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.DARK_PURPLE_BACKGROUND},${Color.DARK_PURPLE_BACKGROUND}), ${Color.BUTTON_GRADIENT_ORANGE_TO_PINK}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  textTransform: 'uppercase',
  borderRadius: BorderRadius.NORMAL,
  cursor: 'pointer',
  padding: '6px 14px',
});

export const UploadAreaContainer = styled('div')(({ theme }) => ({
  width: '640px',
  backgroundColor: Color.BACKGROUND,
  padding: '24px 16px',
  borderRadius: BorderRadius.NORMAL,
  boxSizing: 'border-box',
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_MIDDLE}`,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    margin: '16px 0',
  },
}));

export const UploadArea = styled('div')({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  borderRadius: BorderRadius.NORMAL,
  cursor: 'pointer',
  padding: '93px 0px',
});

export const UploadImage = styled('img')({
  width: '96px',
  height: '96px',
  marginBottom: '32px',
});

export const UploadText = styled('p')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  opacity: '0.6',
});

export const ButtonContainer = styled('div')({
  borderTop: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '16px',
  padding: '10.5px 16px',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: '18px 32px',
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    flexGrow: 1,
    padding: '10px',
  },
}));

export const EarningContainer = styled('div')({
  display: 'flex',
});

export const CreatorFeesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column-reverse',
    alignItems: 'start',
    gap: '16px',
  },
}));

export const AddAddressButton = styled(Button)(({ theme }) => ({
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.DARK_PURPLE_BACKGROUND},${Color.DARK_PURPLE_BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.NORMAL,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE,
  textTransform: 'none',

  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
  },
}));

export const TotalFeesContained = styled('div')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  lineHeight: '160%',
  opacity: 0.5,
  marginRight: '10px',
});
export const TotalFees = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
});

export const TotalErr = styled('span')({
  color: Color.RED,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  display: 'block',
  marginBottom: '5px',
});

export const TokenItemSymbol = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
});

export const TokenItemName = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SUPER_SMALL,
  letterSpacing: '0.04em',
  color: Color.WHITE_OPACITY_LIGHT,
});

export const ErrorContainer = styled(Stack)({
  padding: 12,
  marginBottom: '30px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  background: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
});

export const ErrorText = styled('p')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  lineHeight: '150%',
  margin: 0,
});
