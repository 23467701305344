const NoNftsIcon = () => {
  return (
    <svg
      width='58'
      height='54'
      viewBox='0 0 58 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M43.6045 20.375H51.2395C51.8058 20.375 52.349 20.6 52.7495 21.0005C53.15 21.401 53.375 21.9442 53.375 22.5105L53.375 50.1145C53.375 50.6808 53.15 51.224 52.7495 51.6245C52.349 52.025 51.8058 52.25 51.2395 52.25L3.94803 52.25C3.38166 52.25 2.83849 52.025 2.43801 51.6245C2.03753 51.224 1.81252 50.6808 1.8125 50.1145L1.8125 22.5105C1.81252 21.9442 2.03753 21.401 2.43801 21.0005C2.83849 20.6 3.38166 20.375 3.94803 20.375L38.7077 20.375'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M40.775 24.125L49.625 24.125L49.625 48.5L13.0625 48.5L13.0625 24.125L35.7931 24.125'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M53.0527 1.60522L33.4346 27.4362L33.7936 30.064L36.4214 29.7049L56.0386 3.87304L53.0527 1.60522Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M53.9981 6.56009L51.0117 4.29199'
        stroke='white'
        stroke-width='2'
        stroke-linejoin='round'
      />
      <path
        d='M5.5625 26H9.3125'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.5625 28.8125H9.3125'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.8125 40.0625H13.0625'
        stroke='white'
        stroke-width='2'
        stroke-linejoin='round'
      />
      <path
        d='M13.0625 45.6875H1.8125'
        stroke='white'
        stroke-width='2'
        stroke-linejoin='round'
      />
      <path
        d='M7.4375 37.25C8.9908 37.25 10.25 35.9908 10.25 34.4375C10.25 32.8842 8.9908 31.625 7.4375 31.625C5.8842 31.625 4.625 32.8842 4.625 34.4375C4.625 35.9908 5.8842 37.25 7.4375 37.25Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
export default NoNftsIcon;
