import * as React from 'react';

import LiquidityWrap from 'components/LiquidityWrap';
import LiquidityV2Wrap from 'components/LiquidityV2Wrap';
import { NoInfoWrapper } from './styles';
import { useAccount } from 'wagmi';

const Swap: React.FC = () => {
  const { chain } = useAccount();

  return (
    <NoInfoWrapper>
      {chain?.id && [56, 97].includes(chain?.id) ? (
        <LiquidityV2Wrap />
      ) : (
        <LiquidityWrap />
      )}
    </NoInfoWrapper>
  );
};

export default Swap;
