import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { DEXToken, getLinkForNFT } from '@omisoftnet/game-dex-sdk';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import {
  CollectionPreview,
  IconCoin,
  IconStyled,
  StyledDialog,
  StyledDialogHeader,
  StyledDialogTitle,
  SubTitleStyled,
  TitleStyled,
} from 'components/NFTBuyModal/styles';
import NFTSellModal from 'components/NFTSellModal';
import TransactionResultPopup from 'components/TransactionResultPopup';
import NFT_MARKETPLACE from 'config/contracts';
import { explorers } from 'helpers/explorers';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { formatWalletKey } from 'helpers/formatWalletKey';
import { isChainSupproted } from 'helpers/isChainSupproted';
import { makeShortTextLine } from 'helpers/makeShortTextLine';
import { Color } from 'helpers/themeStyles';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectedNftSelector } from 'state/nft/selectors';
import BscIcon from 'svg/BscIcon';
import {
  NFTDetailsWrapper,
  PaymentDetailsWrap,
  PaymentFiatValue,
  PaymentText,
  PaymentValue,
  RowStyled,
  StyledAdjustSalePriceButton,
  StyledBscScan,
  StyledDialogContent,
  StyledRemoveButton,
  StyledSmallNoImage,
  StyledValue,
} from './styles';

const NftDetailsPopup = ({
  isOpen,
  onClose,
  totalSupply,
  price,
  lowestPriceUSD,
  priceUSD,
  offerFrom,
  previousPrice,
  token,
  changeNetworkOpen,
  collectionChainId,
}: {
  isOpen: boolean;
  onClose: () => void;
  totalSupply: number;
  price: string;
  lowestPriceUSD: string;
  priceUSD: string;
  offerFrom: string;
  previousPrice: string;
  token?: DEXToken;
  changeNetworkOpen?: () => void;
  collectionChainId?: number;
}) => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const selectedNft = useAppSelector(selectedNftSelector);
  const [openSellModal, setOpenSellModal] = useState<boolean>(false);
  const [openAdjustNewPriceModal, setOpenAdjustNewPriceModal] =
    useState<boolean>(false);
  const [openRemoveFromMarketModal, setOpenRemoveFromMarketModal] =
    useState<boolean>(false);
  const [imgError, setImgError] = useState(false);
  const isOfferOwner = offerFrom === address?.toLowerCase();
  const ERC1155 = useMemo(() => {
    if (chain && isChainSupproted(chain?.id!)) {
      return NFT_MARKETPLACE[chain.id].contract;
    }
  }, [chain]);
  return (
    <>
      <StyledDialog
        onClose={() => onClose()}
        open={isOpen}
      >
        <StyledDialogHeader>
          <StyledDialogTitle>{t('details')}</StyledDialogTitle>
          <Close
            onClick={() => onClose()}
            sx={{
              cursor: 'pointer',
              pr: 3,
              color: 'white',
              '&:hover': { color: 'grey' },
            }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <CollectionPreview>
            {selectedNft?.nft.image && !imgError ? (
              <IconStyled
                src={formatIpfsUrl(selectedNft?.nft.image[1])}
                alt=''
                onError={() => setImgError(true)}
              />
            ) : (
              <StyledSmallNoImage />
            )}
            <NFTDetailsWrapper>
              <div>
                <SubTitleStyled>
                  {isOfferOwner
                    ? formatWalletKey(address!)
                    : makeShortTextLine(selectedNft?.nft.nft_collection_name)}
                </SubTitleStyled>
                <TitleStyled sx={{ wordBreak: 'break-all' }}>
                  {makeShortTextLine(selectedNft?.nft.name)}
                </TitleStyled>
              </div>
              {isOfferOwner ? (
                <StyledValue sx={{ textAlign: 'right' }}>
                  {selectedNft?.nft.nft_collection_name}
                </StyledValue>
              ) : (
                <Stack
                  alignItems={'end'}
                  gap={0.5}
                >
                  <SubTitleStyled sx={{ textAlign: 'right' }}>
                    {t('lowestPrice')}
                  </SubTitleStyled>
                  <RowStyled style={{ margin: 0 }}>
                    <PaymentValue sx={{ color: Color.WHITE }}>
                      {selectedNft?.nft.chain_id === chain?.id
                        ? `${lowestPriceUSD} USD`
                        : '-'}
                    </PaymentValue>
                  </RowStyled>
                </Stack>
              )}
            </NFTDetailsWrapper>
          </CollectionPreview>
          <PaymentDetailsWrap>
            {isOfferOwner && (
              <>
                <RowStyled>
                  <PaymentText>{t('lowestPrice')}</PaymentText>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <RowStyled style={{ margin: 0 }}>
                      <PaymentValue>
                        {selectedNft?.nft.chain_id === chain?.id
                          ? `${lowestPriceUSD} USD`
                          : '-'}
                      </PaymentValue>
                    </RowStyled>
                  </div>
                </RowStyled>
                <RowStyled style={{ marginBottom: '25px' }}>
                  <PaymentText>{t('yourPrice')}</PaymentText>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {priceUSD && token?.chainId === chain?.id && (
                      <PaymentFiatValue>{`$${priceUSD}`}</PaymentFiatValue>
                    )}
                    <RowStyled style={{ margin: 0 }}>
                      <IconCoin src={token?.icon} />
                      <PaymentValue>{price}</PaymentValue>
                    </RowStyled>
                  </div>
                </RowStyled>
              </>
            )}
            <RowStyled>
              <SubTitleStyled>
                {t('token')} ID:{' '}
                <StyledValue>
                  {Number(selectedNft?.nft.token_id)
                    ? selectedNft?.nft.token_id
                    : '-'}
                </StyledValue>
              </SubTitleStyled>
              <div style={{ display: 'flex', gap: '6px' }}>
                <StyledBscScan
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(
                      getLinkForNFT(
                        chain?.id!,
                        ERC1155?.toLowerCase()!,
                        selectedNft?.nft?.token_id!
                      )
                    );
                  }}
                >
                  {explorers[chain?.id ?? 1]}
                </StyledBscScan>
                <BscIcon />
              </div>
            </RowStyled>
          </PaymentDetailsWrap>
          {isOfferOwner ? (
            <>
              <StyledAdjustSalePriceButton
                variant='contained'
                onClick={() => {
                  token?.chainId === chain?.id &&
                  collectionChainId === chain?.id
                    ? setOpenAdjustNewPriceModal(true)
                    : changeNetworkOpen && changeNetworkOpen();
                }}
              >
                {t('adjustSalePrice')}
              </StyledAdjustSalePriceButton>
              <StyledRemoveButton
                variant='contained'
                onClick={() => {
                  token?.chainId === chain?.id &&
                  collectionChainId === chain?.id
                    ? setOpenRemoveFromMarketModal(true)
                    : changeNetworkOpen && changeNetworkOpen();
                }}
              >
                {t('removeFromMarket')}
              </StyledRemoveButton>
            </>
          ) : (
            <StyledAdjustSalePriceButton
              sx={{ mb: 2 }}
              variant='contained'
              onClick={() => {
                collectionChainId === chain?.id
                  ? setOpenSellModal(true)
                  : changeNetworkOpen && changeNetworkOpen();
              }}
            >
              {t('sell')}
            </StyledAdjustSalePriceButton>
          )}
        </StyledDialogContent>
      </StyledDialog>
      {openSellModal && (
        <NFTSellModal
          open={openSellModal}
          setOpen={setOpenSellModal}
          title={t('setPrice')}
          buttonText={t('enableListing')}
          totalSupply={totalSupply}
          selectedNFT={selectedNft!}
        />
      )}
      {openAdjustNewPriceModal && (
        <NFTSellModal
          open={openAdjustNewPriceModal}
          setOpen={setOpenAdjustNewPriceModal}
          title={t('adjustNewPrice')}
          buttonText={t('confirm')}
          setNewPrice
          totalSupply={totalSupply}
          previousPrice={previousPrice}
          previousToken={token}
          selectedNFT={selectedNft!}
        />
      )}
      {openRemoveFromMarketModal && (
        <TransactionResultPopup
          openPopup={openRemoveFromMarketModal}
          setOpenPopup={setOpenRemoveFromMarketModal}
          closeParentPopup={() => {}}
          fromNFT
          removeFromMarketNFT
          nftID={selectedNft?.nft._id!}
          totalSupply={totalSupply}
          selectedNFT={selectedNft}
        />
      )}
    </>
  );
};
export default NftDetailsPopup;
