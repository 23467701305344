import React from 'react';

const LiquidityIconActive: React.FC = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_602_3209)'>
        <rect
          x='8'
          y='6'
          width='32'
          height='32'
          rx='6'
          fill='#B032D6'
          shapeRendering='crispEdges'
        />
        <path
          d='M33.3 13.7873C33.3 14.7741 31.6203 15.575 29.5499 15.575C27.4781 15.575 25.7998 14.7741 25.7998 13.7873C25.7998 12.8 27.4781 12 29.5499 12C31.6203 12 33.3 12.8 33.3 13.7873Z'
          fill='white'
        />
        <path
          d='M27.7502 18.6694C27.7502 19.6563 26.0705 20.4571 24.0001 20.4571C21.9281 20.4571 20.25 19.6563 20.25 18.6694C20.25 17.6821 21.9281 16.8821 24.0001 16.8821C26.0705 16.8821 27.7502 17.6823 27.7502 18.6694Z'
          fill='white'
        />
        <path
          d='M18.45 26.8821C16.8922 26.8821 15.5727 26.5146 14.7 25.9087V26.8821C14.7 27.8685 16.378 28.6694 18.45 28.6694C20.5203 28.6694 22.2001 27.8685 22.2001 26.8821V25.9087C21.3269 26.5146 20.0078 26.8821 18.45 26.8821Z'
          fill='white'
        />
        <path
          d='M22.2001 23.5515C22.2001 24.5382 20.5203 25.3391 18.45 25.3391C16.378 25.3391 14.7 24.5382 14.7 23.5515C14.7 22.5642 16.378 21.7642 18.45 21.7642C20.5202 21.7642 22.2001 22.5642 22.2001 23.5515Z'
          fill='white'
        />
        <path
          d='M18.45 30.2124C16.8922 30.2124 15.5727 29.8452 14.7 29.2393V30.2124C14.7 31.1993 16.378 32 18.45 32C20.5203 32 22.2001 31.1993 22.2001 30.2124V29.2393C21.3269 29.8452 20.0078 30.2124 18.45 30.2124Z'
          fill='white'
        />
        <path
          d='M29.5497 17.118C29.2992 17.118 29.0657 17.0936 28.8284 17.0746C29.0519 17.4124 29.1749 17.7882 29.1749 18.1901V18.8872C29.3002 18.8935 29.4216 18.9053 29.5497 18.9053C31.6201 18.9053 33.2998 18.1044 33.2998 17.1179V16.1445C32.4268 16.7505 31.1076 17.1179 29.5497 17.1179L29.5497 17.118Z'
          fill='white'
        />
        <path
          d='M24 21.9999C23.7495 21.9999 23.5158 21.9755 23.2783 21.9565C23.502 22.2945 23.6249 22.6702 23.6249 23.0724V23.769C23.7503 23.7753 23.871 23.7873 24 23.7873C26.0704 23.7873 27.7501 22.9866 27.7501 22V21.0269C26.877 21.6328 25.5578 22 24 22L24 21.9999Z'
          fill='white'
        />
        <path
          d='M24 28.6608C23.8697 28.6608 23.7515 28.6431 23.6249 28.6385V29.7332C23.6249 29.9741 23.5765 30.2045 23.4937 30.4242C23.6606 30.4354 23.8269 30.4486 24 30.4486C26.0704 30.4486 27.7501 29.6475 27.7501 28.6609V27.6875C26.877 28.2933 25.5579 28.6608 24 28.6608L24 28.6608Z'
          fill='white'
        />
        <path
          d='M29.5497 27.1092C29.4194 27.1092 29.3012 27.0915 29.1749 27.0869V28.1816C29.1749 28.4225 29.1266 28.6526 29.0437 28.8721C29.2107 28.8832 29.3763 28.8965 29.5497 28.8965C31.6201 28.8965 33.2998 28.0957 33.2998 27.1092V26.1357C32.4268 26.7417 31.1076 27.1092 29.5497 27.1092L29.5497 27.1092Z'
          fill='white'
        />
        <path
          d='M24.0001 25.3302C23.8698 25.3302 23.7517 25.3126 23.625 25.3079V27.0996C23.7504 27.1059 23.8711 27.1178 24.0001 27.1178C26.0705 27.1178 27.7502 26.3171 27.7502 25.3305V24.3569C26.8771 24.9627 25.558 25.3302 24.0001 25.3302V25.3302Z'
          fill='white'
        />
        <path
          d='M29.5498 23.7781C29.4196 23.7781 29.3014 23.7608 29.175 23.7559V25.5477C29.3004 25.554 29.4219 25.5659 29.5498 25.5659C31.6203 25.5659 33.2999 24.7652 33.2999 23.7783V22.8052C32.427 23.411 31.1077 23.7781 29.5498 23.7781V23.7781Z'
          fill='white'
        />
        <path
          d='M29.5498 20.4482C29.4196 20.4482 29.3014 20.4309 29.175 20.4259V22.2176C29.3004 22.2239 29.4219 22.2355 29.5498 22.2355C31.6203 22.2355 33.2999 21.4349 33.2999 20.4482V19.4751C32.427 20.081 31.1077 20.4482 29.5498 20.4482V20.4482Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_602_3209'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411904 0 0 0 0 0.130625 0 0 0 0 0.870833 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_602_3209'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_602_3209'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LiquidityIconActive;
