import { createSelector } from 'reselect';
import { RootState } from '..';

const launchpadState = (state: RootState) => state.launchpad;
/**
 * @description Get data of first 4 upcoming projects
 */
export const upcomingProjectsSelector = createSelector(
  launchpadState,
  ({ upcomingProjects }) => upcomingProjects
);

/**
 * @description Get data of first 4 current projects
 */
export const currentProjectsSelector = createSelector(
  launchpadState,
  ({ currentProjects }) => currentProjects
);

/**
 * @description Get data of first 4 current projects
 */
export const completedProjectsSelector = createSelector(
  launchpadState,
  ({ completedProjects }) => completedProjects
);

export const newProjectIdSelector = createSelector(
  launchpadState,
  ({ newProjectId }) => newProjectId
);

export const projectDetailsSelector = createSelector(
  launchpadState,
  ({ projectDetails }) => projectDetails
);

export const projectsByOwnerSelector = createSelector(
  launchpadState,
  ({ projectsByOwner }) => projectsByOwner
);

export const loadingSelector = createSelector(
  launchpadState,
  ({ loading }) => loading
);

export const isLimitedSelector = createSelector(
  launchpadState,
  ({ isLimited }) => isLimited
);
