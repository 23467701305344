import { styled } from '@mui/system';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';
import { Grid } from '@mui/material';

export const StyledTittle = styled('h2')(({ theme }) => ({
  marginTop: '32px',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MIDDLE_PLUS,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    marginTop: '40px',
  },
}));

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '16px',
  marginTop: '20px',
  width: '100%',
  [theme.breakpoints.down(1320)]: {
    flexWrap: 'wrap',
    rowGap: '16px',
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    gap: '24px',
  },
}));

export const StyledCardItem = styled('div')(({ theme }) => ({
  color: Color.WHITE,
  width: '100%',
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    maxWidth: '327px',
    marginBottom: '16px',
  },
}));

export const StyledBackgroundImg = styled('img')({
  width: '100%',
  height: '120px',
});

export const StyledSmallImg = styled('img')({
  width: '64px',
  height: '64px',
  position: 'absolute',
  left: '16px',
  top: '72px',
});

export const StyledGrid = styled(Grid)(() => ({
  padding: 16,
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.AMOUNT_BACKGROUND,
}));

export const StyledName = styled('p')({
  marginBottom: '26px',
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
});

export const StyledAuthor = styled('p')({
  margin: 0,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledVolume = styled('p')({
  margin: 0,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledPrice = styled('p')({
  margin: 0,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  whiteSpace: 'nowrap',
});
