import { useTranslation } from 'react-i18next';
import { ArrowBack, Close } from '@mui/icons-material';
import { Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DEXToken,
  getLinkForTransaction,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NFTCollection } from '@omisoftnet/game-nft-sdk';
import { useState } from 'react';
import { BigNumber } from 'ethers';

import ErrorIcon from 'svg/ErrorIcon';
import SubmittedIcon from 'svg/SubmittedIcon';
import RemoveIcon from 'svg/RemoveIcon';
import {
  StyledAddTokenButton,
  StyledBackButton,
  StyledButton,
  StyledContainer,
  StyledContentContainer,
  StyledDialog,
  StyledHeader,
  StyledLink,
  StyledSmallText,
  StyledTitle,
  SubmittedIconContainer,
} from './styles';
import { Border, Color } from 'helpers/themeStyles';
import { transactionHashSelector } from 'state/transactions/selectors';
import { transactionStatuses } from 'helpers/transactionStatuses';
import MetamaskIcon from 'svg/MetamaskIcon';
import { explorers } from 'helpers/explorers';
import { addRecommendedTokenToWallet } from 'helpers/addRecommendedTokenToWallet';
import { fetchOffer } from 'config/api';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import { setTransactionHash } from 'state/transactions/slice';
import { SelectedNft } from 'types/nft';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import NFT_MARKETPLACE from 'config/contracts';
import { useSigner } from '@hooks/useSigner';
type ControlProps = {
  openPopup: boolean;
  setOpenPopup: (newValue: boolean) => void;
  closeParentPopup: () => void;
  result?: string | undefined;
  clearTokensValue?: () => void;
  fromV2?: boolean | undefined;
  fromNFT?: boolean | undefined;
  confirmTransactionNFT?: boolean | undefined;
  removeFromMarketNFT?: boolean | undefined;
  navigateToPoolList?: boolean | undefined;
  sellNft?: boolean | undefined;
  nftID?: string | undefined;
  totalSupply?: number | undefined;
  reloadPage?: boolean | undefined;
  selectedNFT?: SelectedNft | null;
  fromLaunchpad?: boolean | undefined;
  tokenFromLaunchpad?: DEXToken | undefined;
};

const TransactionResultPopup = ({
  openPopup,
  setOpenPopup,
  closeParentPopup,
  result,
  clearTokensValue,
  fromV2,
  fromNFT,
  confirmTransactionNFT,
  removeFromMarketNFT,
  navigateToPoolList,
  sellNft,
  nftID,
  totalSupply,
  reloadPage,
  selectedNFT,
  fromLaunchpad,
  tokenFromLaunchpad,
}: ControlProps) => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const ERC1155 = NFT_MARKETPLACE[chain!.id].contract;
  const NFTExchanger = NFT_MARKETPLACE[chain!.id].market;
  const signer = useSigner();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transactionHash = useSelector(transactionHashSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const NFT = new NFTCollection(signer!, ERC1155, NFTExchanger);
  const [openWaitingPopup, setOpenWaitingPopup] = useState<boolean>(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState<boolean>(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState<boolean>(false);
  const handleClose = () => {
    closeParentPopup();
    setOpenPopup(false);
    closeParentPopup();
    if (result === transactionStatuses.SUBMIT && clearTokensValue) {
      clearTokensValue();
    }
    if (result === transactionStatuses.SUBMIT && navigateToPoolList) {
      navigate('/liquidity');
    }
    if (result === transactionStatuses.SUBMIT && reloadPage) {
      window.location.reload();
    }
  };

  const removeNftFromMarket = async () => {
    try {
      if (address) {
        setOpenWaitingPopup(true);
        const { data } = await fetchOffer(`offers/nft/${nftID}`, {
          from: address.toLowerCase(),
        });
        const signature = data.signature;
        const offer = {
          asset_amount: data.asset_amount,
          asset_id: data.asset_id,
          collection_contract: data.collection_contract,
          deadline: data.deadline,
          from: data.from,
          nft_id: data.nft_id,
          pay_amount: data.pay_amount,
          payable_token: data.payable_token,
          royalties_addreses: data.royalties_addreses,
          royalties_percent: data.royalties_percent,
          total_supply: BigNumber.from(String(totalSupply)).toHexString(),
        };
        const tx = await (await NFT.cancelOffer(offer, signature)).wait(1);
        dispatch(
          setTransactionHash({
            hash: tx?.transactionHash,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'cancelOffer',
            data: {
              nft_data: {
                pay_amount: Number(utils.formatUnits(`${tx?.gasUsed!}`, 18)),
                payable_token: data.payable_token,
                nft_image: formatIpfsUrl(selectedNFT?.nft.image[1]!),
                nft_name: selectedNFT?.nft.name,
                nft_collection_name: selectedNFT?.nft.nft_collection_name,
              },
            },
          })
        );
        setOpenWaitingPopup(false);
        setTransactionSubmittedStatus(true);
      }
    } catch (error) {
      console.error(error);
      setOpenWaitingPopup(false);
      setTransactionRejectedStatus(true);
    }
  };

  return (
    <StyledDialog
      onClose={handleClose}
      open={openPopup}
      sx={{ width: isMobile && !fromNFT ? '90%' : 'inherit' }}
    >
      {fromNFT ? (
        <StyledContainer>
          <StyledHeader
            sx={{
              justifyContent: 'space-between',
              borderBottom: Border.ACCENT_BORDER,
              py: 1.5,
              px: 2,
            }}
          >
            <StyledBackButton
              startIcon={<ArrowBack />}
              onClick={handleClose}
            >
              {t('back')}
            </StyledBackButton>
            <IconButton sx={{ p: 0 }}>
              <Close
                sx={{ cursor: 'pointer', color: Color.WHITE }}
                onClick={handleClose}
              />
            </IconButton>
          </StyledHeader>
          <Box p={2}>
            {confirmTransactionNFT && (
              <>
                <StyledTitle>{t('confirm')}</StyledTitle>
                <StyledSmallText>
                  {t('confirmTransactionInWallet')}
                </StyledSmallText>
                <StyledButton fullWidth>{t('confirm')}</StyledButton>
              </>
            )}
            {removeFromMarketNFT && (
              <>
                <Stack
                  alignItems='center'
                  my={1.5}
                >
                  <RemoveIcon
                    width='60'
                    height='60'
                  />
                </Stack>
                <StyledTitle sx={{ textAlign: 'center' }}>
                  {t('removeFromMarket')}
                </StyledTitle>
                <StyledSmallText sx={{ textAlign: 'center' }}>
                  {t('removeFromMarketDescription')}
                </StyledSmallText>
                <StyledButton
                  fullWidth
                  onClick={removeNftFromMarket}
                >
                  {t('confirm')}
                </StyledButton>
              </>
            )}
          </Box>
        </StyledContainer>
      ) : (
        <StyledContainer sx={{ p: 2 }}>
          <StyledHeader sx={{ justifyContent: 'flex-end' }}>
            <IconButton sx={{ p: 0 }}>
              <Close
                sx={{ cursor: 'pointer', color: Color.WHITE }}
                onClick={handleClose}
              />
            </IconButton>
          </StyledHeader>
          <StyledContentContainer>
            <Box
              pt={!isMobile ? 4 : 5}
              pb={!isMobile ? 1.5 : 2}
            >
              {result === transactionStatuses.REJECT ? (
                <ErrorIcon />
              ) : (
                <SubmittedIconContainer>
                  <SubmittedIcon />
                </SubmittedIconContainer>
              )}
            </Box>
            <StyledTitle
              style={{
                marginBottom: result === transactionStatuses.REJECT ? 32 : 8,
              }}
            >
              {result === transactionStatuses.CONFIRM
                ? t('tansactionConfirmed')
                : result === transactionStatuses.REJECT
                ? t('transactionRejected')
                : t('transactionSubmitted')}
            </StyledTitle>
            {!sellNft &&
              (result === transactionStatuses.SUBMIT ||
                result === transactionStatuses.CONFIRM) && (
                <StyledLink
                  style={{ color: Color.PURPLE, marginBottom: 24 }}
                  onClick={() => {
                    window.open(
                      getLinkForTransaction(chain?.id!, transactionHash)
                    );
                  }}
                >
                  {t('viewTxOnScan', { scanner: explorers[chain!.id] })}
                </StyledLink>
              )}
            {result === transactionStatuses.SUBMIT &&
              fromLaunchpad &&
              tokenFromLaunchpad &&
              window.ethereum && (
                <StyledAddTokenButton
                  onClick={() =>
                    addRecommendedTokenToWallet(
                      tokenFromLaunchpad.address,
                      tokenFromLaunchpad?.name!,
                      tokenFromLaunchpad.decimals,
                      ''
                    )
                  }
                  endIcon={<MetamaskIcon />}
                >
                  {t('addToWallet', {
                    tokenName: tokenFromLaunchpad.name,
                  })}
                </StyledAddTokenButton>
              )}
            <StyledButton
              variant='contained'
              fullWidth
              onClick={handleClose}
              sx={{
                marginTop: isMobile ? '40px' : sellNft ? '20px' : undefined,
              }}
            >
              {result === transactionStatuses.REJECT
                ? t('dismiss')
                : t('close')}
            </StyledButton>
          </StyledContentContainer>
        </StyledContainer>
      )}
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          fromNFT
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          reloadPage
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </StyledDialog>
  );
};

export default TransactionResultPopup;
