const RemoveIcon = ({ width, height }: { width: string; height: string }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M50.2869 9.68793L39.6157 9.68793V7.55367C39.6157 5.19651 37.7045 3.28516 35.3472 3.28516L24.676 3.28516C22.3188 3.28516 20.4075 5.19651 20.4075 7.55367V9.68793L9.73633 9.68793V11.8222H11.9353L14.0716 52.3728C14.0716 54.73 15.9828 56.6413 18.3401 56.6413L41.8167 56.6413C44.174 56.6413 46.0852 54.73 46.0852 52.3728L48.184 11.8222L50.287 11.8222V9.68793H50.2869ZM22.5418 7.55367C22.5418 6.37611 23.5006 5.41941 24.676 5.41941L35.3472 5.41941C36.5248 5.41941 37.4815 6.37611 37.4815 7.55367V9.68793L22.5418 9.68793V7.55367ZM43.955 52.2622L43.9508 52.3165V52.3728C43.9508 53.5483 42.9941 54.507 41.8166 54.507L18.34 54.507C17.1646 54.507 16.2057 53.5483 16.2057 52.3728V52.3165L16.2037 52.2603L14.0716 11.8222L46.0477 11.8222L43.955 52.2622Z'
        fill='#FF3156'
      />
      <path
        d='M31.0791 16.0903H28.9448L28.9448 50.2381H31.0791L31.0791 16.0903Z'
        fill='#FF3156'
      />
      <path
        d='M24.7239 50.1695L22.5397 16.0884L20.4097 16.2237L22.594 50.3048L24.7239 50.1695Z'
        fill='#FF3156'
      />
      <path
        d='M39.6303 16.1572L37.5002 16.0239L35.3472 50.1717L37.4772 50.305L39.6303 16.1572Z'
        fill='#FF3156'
      />
    </svg>
  );
};

export default RemoveIcon;
