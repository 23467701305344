import { Avatar, Button, Stack, styled, Typography } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0 36px',
}));

export const StyledButton = styled(Button)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 600,
  textTransform: 'none',
  color: Color.WHITE,
}));
export const BannerContainer = styled('div')({
  position: 'relative',
  marginBottom: '48px',
  height: '300px',
  objectFit: 'scale-down',
});

export const StyledAvatar = styled(Avatar)(() => ({
  width: '64px',
  height: '64px',
  position: 'absolute',
  left: '16px',
  bottom: '-16px',
  border: `4px solid ${Color.WHITE}`,
  borderRadius: BorderRadius.SMALL,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  color: Color.WHITE,
  fontSize: TextSize.LARGE,
  marginBottom: '16px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
    textTransform: 'uppercase',
    color: Color.WHITE,
  },
}));

export const StyledSubtitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  background: Color.DARK_PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '16px 48px',
  flexDirection: 'row',
  columnGap: '8px',
  justifyContent: 'space-evenly',
  [theme.breakpoints.between('mobile', 1500)]: {
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.between('mobile', 1350)]: {
    padding: '16px 24px',
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.down('mobile')]: {
    borderRadius: BorderRadius.SEMI_SMALL,
    border: Border.ACCENT_BORDER,
    background: Color.DARK_PURPLE_OPACITY,
    padding: '16px 8px',
  },
}));

export const DetailsTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.EXTRA_SMALL,
  },
}));

export const DetailsSubtitle = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
}));

export const StyledSeeMoreOrLessButton = styled(Button)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 700,
  textDecoration: 'underline',
  color: Color.WHITE,
  textTransform: 'none',
  padding: '0 8px',
}));

export const StyledNoImgContainer = styled(Stack)(() => ({
  borderRadius: BorderRadius.NORMAL,
  background: Color.DARK_PURPLE_RADIAL_GRADIENT,
  width: '100%',
  height: '298px',
  alignItems: 'center',
  justifyContent: 'center',
}));
