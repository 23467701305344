import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { useAccount, useBalance } from 'wagmi';
import { getBalance } from '@wagmi/core';
import { BigNumber } from 'ethers';

import TokenSelector from 'components/TokenSelector';
import LiquiditySettings from 'components/LiquiditySettings';
import AlertIcon from 'svg/AlertIcon';
import { Color, FontFamily } from 'helpers/themeStyles';
import {
  firstLiquidityTokenSelector,
  secondLiquidityTokenSelector,
} from 'state/liquidity/selectors';
import { dexSelector } from 'state/dex/selectors';
import {
  BtnContainer,
  HeadingContainer,
  SelectorContainer,
  StyledBtn,
  StyledContainer,
  StyledIconButton,
  StyledText,
  StyledTitle,
  StyledUSDBalance,
  TipContainer,
  TipText,
  TitleContainer,
  TokenContainer,
} from './styles';
import { useAsUsd } from '@hooks/useAsUsd';
import formatTokenBalance from 'helpers/formatTokenBalance';
import { useSigner } from '@hooks/useSigner';

type Props = {
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  openAddLiquidity?: React.MouseEventHandler<HTMLButtonElement>;
};

const ChooseValidPairLiquidityV2 = (props: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const dex = useSelector(dexSelector);
  const firstLiquidityToken = useSelector(firstLiquidityTokenSelector);
  const secondLiquidityToken = useSelector(secondLiquidityTokenSelector);
  const [firstTokenUSDBalance, setFirstTokenUSDBalance] = useState('');
  const [secondTokenUSDBalance, setSecondTokenUSDBalance] = useState('');
  const signer = useSigner();
  const { getValueInUsd } = useAsUsd();
  const { data: firstTokenBalance } = useBalance({
    address,
    chainId: firstLiquidityToken?.chainId,
    token: !firstLiquidityToken?.isNative
      ? (firstLiquidityToken?.address as `0x${string}`)
      : undefined,
  });
  const { data: secondTokenBalance } = useBalance({
    address,
    chainId: secondLiquidityToken?.chainId,
    token: !secondLiquidityToken?.isNative
      ? (secondLiquidityToken?.address as `0x${string}`)
      : undefined,
  });

  async function getUSDBalance(
    token: DEXToken,
    firstTokenFlag: boolean = false
  ) {
    if (!dex) return;
    token.amount = await (token.isNative
      ? BigNumber.from(
          (
            await getBalance(dex.wallet.wagmi, { address: address! })
          ).value
        )
      : token.balanceOf(signer!, address!));
    if (token.amount.isZero()) {
      return;
    }
    const usdBalance = await getValueInUsd({
      token: token,
      value: utils.formatUnits(token.amount, token.decimals),
    });
    const result = firstTokenFlag
      ? setFirstTokenUSDBalance(usdBalance ?? '0')
      : setSecondTokenUSDBalance(usdBalance ?? '0');
    return result;
  }

  useEffect(() => {
    if (firstLiquidityToken && Number(firstTokenBalance?.formatted) > 0) {
      getUSDBalance(firstLiquidityToken, true);
    }
  }, [firstLiquidityToken?.address]);

  useEffect(() => {
    if (secondLiquidityToken && Number(secondTokenBalance?.formatted) > 0) {
      getUSDBalance(secondLiquidityToken);
    }
  }, [secondLiquidityToken?.address]);

  useEffect(() => {}, [address]);

  return (
    <StyledContainer elevation={3}>
      <HeadingContainer>
        <StyledIconButton onClick={props.onBack}>
          <ArrowBackIcon />
        </StyledIconButton>
        <TitleContainer>
          <StyledTitle>{t('addLiquidity')}</StyledTitle>
        </TitleContainer>
        <LiquiditySettings />
      </HeadingContainer>
      <SelectorContainer>
        <StyledText>{t('chooseValidPair')}</StyledText>
        <TokenContainer>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mb={2}
          >
            <TokenSelector
              fromLiquidity
              firstToken
              fromV2
            />
            {firstLiquidityToken.symbol !== '!' && (
              <Typography
                fontSize={14}
                sx={{
                  color: Color.WHITE_OPACITY_LIGHT,
                  fontFamily: FontFamily.INTER,
                }}
              >
                {t('balance')}:{' '}
                <span style={{ color: Color.WHITE }}>
                  {firstTokenBalance &&
                    formatTokenBalance(
                      firstTokenBalance.value,
                      firstLiquidityToken?.decimals
                    )}
                </span>
              </Typography>
            )}
          </Stack>
          {firstLiquidityToken && (
            <StyledUSDBalance>{`~$ ${
              Number(firstTokenBalance?.formatted) > 0
                ? firstTokenUSDBalance.slice(0, 8)
                : '0.0'
            }`}</StyledUSDBalance>
          )}
        </TokenContainer>
        <TokenContainer>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mb={2}
          >
            <TokenSelector
              fromLiquidity
              fromV2
            />
            {secondLiquidityToken.symbol !== '!' && (
              <Typography
                fontSize={14}
                sx={{
                  color: Color.WHITE_OPACITY_LIGHT,
                  fontFamily: FontFamily.INTER,
                }}
              >
                {t('balance')}:{' '}
                <span style={{ color: Color.WHITE }}>
                  {secondTokenBalance &&
                    formatTokenBalance(
                      secondTokenBalance.value,
                      secondLiquidityToken?.decimals
                    )}
                </span>
              </Typography>
            )}
          </Stack>
          {secondLiquidityToken && (
            <StyledUSDBalance>{`~$ ${
              Number(secondTokenBalance?.formatted) > 0
                ? secondTokenUSDBalance.slice(0, 8)
                : '0.0'
            }`}</StyledUSDBalance>
          )}
        </TokenContainer>
      </SelectorContainer>
      <TipContainer>
        <div>
          <AlertIcon />
        </div>
        <div>
          <TipText>{t('chooseValidPairTipFirst')}</TipText>
          <TipText>{t('chooseValidPairTipSecond')}</TipText>
        </div>
      </TipContainer>
      <TipContainer>
        <div>
          <AlertIcon />
        </div>
        <div>
          <TipText>{t('toAddLiquidityYouMustHaveABalanceOfTokens')}</TipText>
        </div>
      </TipContainer>
      <BtnContainer>
        {!firstLiquidityToken ||
        !secondLiquidityToken ||
        [firstLiquidityToken.symbol, secondLiquidityToken.symbol].includes(
          '!'
        ) ? (
          <StyledBtn
            fullWidth
            disabled
          >
            {t('selectAToken')}
          </StyledBtn>
        ) : Number(firstTokenBalance?.formatted) <= 0 ||
          Number(secondTokenBalance?.formatted) <= 0 ? (
          <StyledBtn
            fullWidth
            disabled
          >
            {t('noTokenBalance')}
          </StyledBtn>
        ) : firstLiquidityToken.wrapped.address ===
          secondLiquidityToken.wrapped.address ? (
          <StyledBtn
            fullWidth
            disabled
          >
            {t('chooseValidPair')}
          </StyledBtn>
        ) : (
          <StyledBtn
            fullWidth
            onClick={props.openAddLiquidity}
          >
            {t('addLiquidity')}
          </StyledBtn>
        )}
      </BtnContainer>
    </StyledContainer>
  );
};

export default ChooseValidPairLiquidityV2;
