import { Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledCompletedProjectsWrapper = styled('div')({
  marginTop: '70px',
});

export const StyledCompletedProjectsList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: '24px',
  flexWrap: 'wrap',
  margin: '24px 0',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// CompletedProjects styles

export const StyledLink = styled(Link)(({ theme }) => ({
  width: '32%',
  minWidth: '340px',
  minHeight: '100%',
  textDecoration: 'none',
  fontFamily: FontFamily.INTER,

  [theme.breakpoints.down('xl')]: {
    width: 'calc(50% - 12px)',
  },

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    minWidth: '300px',
  },
}));

export const StyledProjectContainer = styled('div')(({ theme }) => ({
  padding: '24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `1px solid ${Color.PURPLE}`,
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  minHeight: 'calc(100% - 48px)', 

  [theme.breakpoints.down('mobile')]: {
    width: 'calc(100%)',
    boxSizing: 'border-box',
  },
}));

export const StyledProjectHeader = styled(Stack)(({ theme }) => ({
  paddingBottom: '10px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'row',
    paddingBottom: '30px',
  },
}));

export const StyledLogo = styled('img')({
  width: '64px',
  height: '64px',
  boxSizing: 'border-box',
  borderRadius: BorderRadius.SMALL,
  border: `4px solid ${Color.WHITE}`,
});

export const StyledTitle = styled(Typography)({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.MIDDLE_PLUS,
});

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0',

  [theme.breakpoints.down('mobile')]: {
    margin: '8px 0',
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  display: 'inline',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  fontWeight: 700,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.EXTRA_SMALL,
  },
}));

export const StyledTextAccent = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  fontWeight: 700,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.EXTRA_SMALL,
  },
}));

export const StyledCategoriesContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
});

export const StyledCategoryBtn = styled('div')({
  padding: '8px 12px',
  background: Color.DARK_PURPLE,
  border: Border.ACCENT_BORDER,
  color: Color.WHITE,
  borderRadius: BorderRadius.MEDIUM,
});
