import { createSelector } from 'reselect';
import { RootState } from '..';

const nftCollectionState = (state: RootState) => state.nftCollection;
/**
 * @description Get data of first 4 hot NFT Collections
 */
export const hotNftCollectionsSelector = createSelector(
  nftCollectionState,
  ({ hotNftCollections }) => hotNftCollections
);

/**
 * @description Get data of first 12 items of NFT Collection
 */
export const collectionItemsSelector = createSelector(
  nftCollectionState,
  ({ collectionItems }) => collectionItems
);
