import { Box, Button, Stack, Typography, styled } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '90%',
  height: 'fit-content',
  margin: '0 0 24px',
  [theme.breakpoints.down('mobile')]: {
    margin: 0,
  },
}));

export const StyledLiquidityTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: TextSize.EXTRA_LARGE,
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
}));

export const StyledNewPositionBtn = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  padding: '16px 32px',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    padding: '10px 16px',
    marginTop: '16px',
  },
}));

export const StyledModalContent = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: BorderRadius.NORMAL,
  background: Color.SWAP_GRADIENT,

  [theme.breakpoints.down('mobile')]: {
    background: 'inherit',
  },
}));

export const StyledLiquiditySubtitle = styled(Typography)(() => ({
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  margin: '16px 0',
}));

export const StyledConnectWalletBtn = styled(Button)(() => ({
  marginTop: '16px',
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  padding: '16px 32px',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
}));

export const StyledPositionHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: Border.ACCENT_BORDER,
  padding: '16px 0',
}));

export const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  marginLeft: '16px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledHeaderBtn = styled(Button)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.PINK,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  marginRight: '16px',
  textTransform: 'none',
}));

export const StyledPositionItemContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledPositionItemDetails = styled(Stack)(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
}));

export const StyledPositionItemIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
}));

export const StyledPositionSymbol = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  lineHeight: '160%',
}));

export const StyledPositionTolerance = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
}));

export const StyledPositionItemStatus = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledMinMaxTitle = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  marginRight: '4px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.LITTLE,
  },
}));

export const StyledMinMaxValue = styled('span')((theme) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.LITTLE,
}));

export const StyledStatusIcon = styled('div')(() => ({
  width: '8px',
  height: '8px',
  borderRadius: BorderRadius.HUGE,
}));

export const StyledStatusTitle = styled('p')(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
}));

export const StyledTipText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  padding: 4,
}));

export const StyledButton = styled(Button)({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: 16,
  '&.Mui-disabled': {
    color: Color.WHITE_OPACITY_LIGHT,
  },
});
