import { Slider } from '@mui/material';
import { styled } from '@mui/system';
import { Color } from 'helpers/themeStyles';

export const CustomSlider = styled(Slider)({
  width: '90%',
  marginLeft: '5%',
  '& .MuiSlider-rail': {
    height: '2px',
    background: Color.WHITE_OPACITY,
  },
  '& .MuiSlider-track': {
    background: Color.PINK,
    border: 'none',
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    background: Color.PINK,
  },
});
