import { Button, Dialog, InputBase, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  Color,
  Border,
  BorderRadius,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: BorderRadius.NORMAL,
    background: Color.BLACK_OPACITY,

    [theme.breakpoints.down('mobile')]: {
      margin: 0,
      marginTop: '-26px',
      width: '100%',
      border: 'none',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
    },
  },
}));

export const StyledDialogSection = styled('div')(({ theme }) => ({
  background: Color.SWAP_GRADIENT_WITHOUT_OPACITY,
  borderRadius: BorderRadius.NORMAL,

  [theme.breakpoints.down('mobile')]: {
    background: 'inherit',
  },
}));

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '22.5px 16px',
  borderBottom: Border.ACCENT_BORDER,

  [theme.breakpoints.down('mobile')]: {
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  padding: 0,
  minWidth: '16px',
});

export const StyledDialogTitle = styled('h6')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: '700',
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,
  flexGrow: 1,
  textAlign: 'center',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const StyledMainContainer = styled('div')(({ theme }) => ({
  padding: '22.5px 16px',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('mobile')]: {
    paddingBottom: '46px',
  },
}));

export const StyledRowContainer = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledSectionTitle = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
});

export const StyledColouredContainer = styled('div')({
  background: Color.DARK_PURPLE_OPACITY,
  borderRadius: BorderRadius.SMALL,
  padding: 16,
});

export const TokenDetails = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
  textTransform: 'uppercase',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const TokenPrice = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const StyledPercent = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  color: Color.TEXT_SECONDARY,
  fontSize: TextSize.NORMAL,
});

export const StyledSmallText = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  color: Color.WHITE,
  fontSize: TextSize.EXTRA_SMALL,
  cursor: 'pointer',
  textAlign: 'right',
  textTransform: 'capitalize',
});

export const RemoveBtnContainer = styled('div')(({ theme }) => ({
  padding: '10px 16px',
  borderTop: Border.ACCENT_BORDER,

  [theme.breakpoints.down('mobile')]: {
    borderTop: 'inherit',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: '18px 0px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  width: '50%',

  [theme.breakpoints.down('mobile')]: {
    padding: '13px',
  },

  '&.Mui-disabled': {
    background: Color.BORDER_WHITE_EXTRA_OPACITY,
    color: Color.WHITE_OPACITY_LIGHT,
  },
}));
