import { Dialog, Grid, styled } from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledWaitingDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 412,
    borderRadius: BorderRadius.SMALL,
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '24px 16px',

    [theme.breakpoints.down('mobile')]: {
      margin: '0 24px',
      padding: '24px 16px 40px',
    },
  },
}));

export const StyledWaitionDialogContent = styled('div')(() => ({
  paddingTop: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
}));

export const StyledGridTitle = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  fontWeight: 600,
  textTransform: 'capitalize',
});

export const StyledGridInfo = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  fontWeight: 400,
});
