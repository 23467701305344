import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Stack, useMediaQuery, useTheme } from '@mui/material';

import { fetchNFTCollections } from 'config/api';
import { NftCollection } from 'types/nftCollection';
import NftCollectionListItem from './NftCollectionListItem';
import { CustomPaginationItem, StyledTypography } from './styles';
import { scrollToRef } from 'helpers/scrollToRef';

const NftCollectionsList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [collections, setCollections] = useState<NftCollection[]>([]);
  const collectionsRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const fetchCollection = async () => {
    const { data } = await fetchNFTCollections('/nft-collections/all', {
      limit: 12,
      page: page,
    });
    setTotalPages(data.totalPages);
    setCollections(data.docs);
  };

  useEffect(() => {
    fetchCollection();
  }, [page]);

  useEffect(() => {
    scrollToRef(collectionsRef, -120);
  }, []);

  return (
    <>
      <Stack
        py={4.8}
        ref={collectionsRef}
      >
        <StyledTypography variant='h3'>{t('collections')}</StyledTypography>
        <Stack
          direction='row'
          flexWrap='wrap'
          justifyContent='flex-start'
          gap={isMobile ? '24px' : '16px'}
        >
          {collections.map((nft: NftCollection) => (
            <NftCollectionListItem
              key={nft._id}
              nft={nft}
            />
          ))}
        </Stack>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <CustomPaginationItem {...item} />}
          sx={{ margin: '0 auto' }}
        />
      </Stack>
    </>
  );
};

export default NftCollectionsList;
