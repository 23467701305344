import { useState } from 'react';

import AddLiquidity from 'components/AddLiquidity';
import LiquidityPositions from 'components/LiquidityPositions';

export default function LiquidityWrap() {
  const [liquidityStep, setLiquidityStep] = useState(0);

  return (
    <>
      {liquidityStep === 0 ? (
        <LiquidityPositions openAddLiquidity={() => setLiquidityStep(1)} />
      ) : (
        <AddLiquidity onBack={() => setLiquidityStep(0)} />
      )}
    </>
  );
}
