export const addRecommendedTokenToWallet = (
  address: string,
  symbol: string,
  decimals: number,
  image: string
) => {
  (window as any).ethereum
    .request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image,
        },
      },
    })
    .then((success: boolean) => {
      if (!success) {
        throw new Error('Something went wrong.');
      }
    })
    .catch(console.error);
};
