import { styled } from '@mui/material';

export const NoInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'top',
  minHeight: 'calc(100vh - 80px)',
  zIndex: 100,
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    zIndex: 'inherit',
  },
}));
