import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { Color } from 'helpers/themeStyles';
import Loader from 'shared/Loader';
import {
  StyledWaitingDialog,
  StyledWaitionDialogContent,
  StyledGridTitle,
  StyledGridSubtitle,
  StyledGridInfo,
} from './styles';
import ErrorIcon from 'svg/ErrorIcon';

const LiquidityWaitingPopup = ({
  action,
  firstTokenSymbol,
  secondTokenSymbol,
  isOpen,
  onClose,
  firstTokenValue,
  secondTokenValue,
  collectingFees,
  fromNFT,
  createText,
  gasError,
}: {
  action?: string | null;
  firstTokenSymbol?: string;
  secondTokenSymbol?: string;
  isOpen: boolean;
  onClose: () => void;
  firstTokenValue?: string;
  secondTokenValue?: string;
  collectingFees?: boolean;
  fromNFT?: boolean | undefined;
  createText?: string | null;
  gasError?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledWaitingDialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: gasError ? 340 : 412,
        },
      }}
    >
      {!createText && (
        <Stack alignItems={'flex-end'}>
          <Close
            onClick={onClose}
            sx={{ cursor: 'pointer', color: Color.WHITE }}
          />
        </Stack>
      )}
      {gasError ? (
        <StyledWaitionDialogContent style={{ paddingTop: '0px' }}>
          <ErrorIcon
            width='50'
            height='50'
          />
          <StyledGridTitle
            item
            style={{ textAlign: 'center', textTransform: 'none' }}
          >
            {t('notEnoughNativeTokenForPayGas')}
          </StyledGridTitle>
        </StyledWaitionDialogContent>
      ) : (
        <StyledWaitionDialogContent>
          <Loader />
          <StyledGridTitle item>
            {createText ? createText : t('waitingForConfirmation')}
          </StyledGridTitle>
          {fromNFT ? (
            ''
          ) : collectingFees ? (
            <StyledGridSubtitle item>{t('collectingFees')}</StyledGridSubtitle>
          ) : (
            <StyledGridSubtitle item>
              {action} {firstTokenValue?.slice(0, 18)} {firstTokenSymbol}{' '}
              {t('and')} {secondTokenValue?.slice(0, 18)} {secondTokenSymbol}
            </StyledGridSubtitle>
          )}
          <StyledGridInfo item>
            {createText ? t('pleaseWaitForAMoment') : t('confirmTransaction')}
          </StyledGridInfo>
        </StyledWaitionDialogContent>
      )}
    </StyledWaitingDialog>
  );
};

export default LiquidityWaitingPopup;
