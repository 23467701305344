import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'config/api';
import { API_ROUTES } from 'config/apiRoutes';
import { Nft } from 'types/nft';
import { NftCollection } from 'types/nftCollection';
import { IPaginate } from 'types/pagination';

export const getHotNftCollectionsThunk = createAsyncThunk<
  IPaginate<NftCollection>
>('nftCollection/getHotNftCollections', async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.get(API_ROUTES.NFT_COLLECTION.GET_HOT);
    return data;
  } catch (error) {
    rejectWithValue(error);
  }
});

export const getCollectionItemsThunk = createAsyncThunk<Nft[], string>(
  'nftCollection/getCollectionItems',
  async (id: string, { rejectWithValue }) => {
    try {
      const {
        data: { docs },
      } = await API.get(API_ROUTES.NFT_COLLECTION.GET_NFTS_FROM_COLLECTION(id));
      return docs;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
