const ChatIcon = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.27539 16.1499C2.27539 17.1137 3.05597 17.8942 4.01971 17.8942H6.63633V20.5852C6.63633 21.0693 7.02902 21.4572 7.50859 21.4572C7.75301 21.4572 7.97486 21.3567 8.13206 21.1957L11.4335 17.8942H21.0278C21.9915 17.8942 22.7721 17.1137 22.7721 16.1499V5.2473C22.7721 4.28351 21.9915 3.50293 21.0278 3.50293H4.01976C3.05597 3.50293 2.27544 4.28351 2.27544 5.2473L2.27539 16.1499ZM7.50864 11.1347C7.50864 10.533 7.99713 10.0445 8.59886 10.0445C9.20059 10.0445 9.68909 10.533 9.68909 11.1347C9.68909 11.7365 9.20059 12.225 8.59886 12.225C7.99709 12.225 7.50864 11.7365 7.50864 11.1347ZM11.4336 11.1347C11.4336 10.533 11.922 10.0445 12.5238 10.0445C13.1256 10.0445 13.6141 10.533 13.6141 11.1347C13.6141 11.7365 13.1256 12.225 12.5238 12.225C11.922 12.225 11.4336 11.7365 11.4336 11.1347ZM15.3585 11.1347C15.3585 10.533 15.8469 10.0445 16.4488 10.0445C17.0506 10.0445 17.5389 10.533 17.5389 11.1347C17.5389 11.7365 17.0506 12.225 16.4488 12.225C15.8469 12.225 15.3585 11.7365 15.3585 11.1347Z'
        fill='white'
      />
    </svg>
  );
};

export default ChatIcon;
