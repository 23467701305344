import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  StyledStepperWrapper,
  StyledStep,
  StyledStepLabel,
  StyledStepperButton,
  btnAdditionalStyles,
  QontoConnector,
  QontoStepIconRoot,
  StyledTitle,
} from './styles';

import { StepIconProps } from '@mui/material/StepIcon';
import CreateProjectFirstStep from '../CreateProjectFirstStep';
import CreateProjectSecondStep from '../CreateProjectSecondStep';
import ProjectPage from '../ProjectPage';
import { loadingSelector } from 'state/launchpad/selectors';
import { useNavigate } from 'react-router-dom';
import ClearFormPopup from '../ClearFormPopup';

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
    >
      {completed ? (
        <div className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
};

const mockedSteps = ['Campaign settings', 'Project overview', 'Preview'];

const CreateProjectWrapperComponent: React.FC = () => {
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {}
  );
  const [openClearFormPopup, setOpenClearFormPopup] = React.useState(false);
  const navigate = useNavigate();
  const totalSteps = () => {
    return mockedSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? mockedSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleBack = () => {
    const newCompleted = completed;
    delete newCompleted[activeStep - 1];
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (stepDisabled) {
      return;
    }
    if (activeStep === 1 && step === 0) {
      return setOpenClearFormPopup(true);
    } else {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  // SET YOUR COMPONENT FOR EACH STEP HERE:
  const getStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CreateProjectFirstStep
            activeStep={activeStep}
            totalSteps={() => totalSteps()}
            handleNext={() => handleNext()}
          />
        );
      case 1:
        return (
          <CreateProjectSecondStep
            activeStep={activeStep}
            totalSteps={() => totalSteps()}
            handleNext={() => handleNext()}
            setOpenClearFormPopup={setOpenClearFormPopup}
          />
        );
      case 2:
        return <ProjectPage />;
    }
  };
  const stepDisabled = activeStep === 0 && completedSteps() < 1;
  const creationDisabled = completedSteps() < 2;

  return (
    <>
      <StyledTitle>{t('createNewProject')}</StyledTitle>
      <StyledStepperWrapper>
        <Stack alignItems={'center'}>
          <Stepper
            nonLinear
            connector={<QontoConnector />}
            activeStep={activeStep}
            sx={{
              width: isMobile ? '100%' : '71%',
              my: isMobile ? '32px' : '40px',
              alignItems: isMobile ? 'flex-end' : 'center',
            }}
          >
            {mockedSteps.map((label, index) => (
              <StyledStep
                key={label}
                completed={completed[index]}
              >
                <StyledStepLabel
                  onClick={handleStep(index)}
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StyledStepLabel>
              </StyledStep>
            ))}
          </Stepper>
        </Stack>
        <div style={{ color: 'white' }}>{getStep(activeStep)}</div>

        {!allStepsCompleted() && (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {/* {activeStep !== 0 && (
                  <StyledStepperButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, ...btnAdditionalStyles }}
                  >
                    {t('back')}
                  </StyledStepperButton>
                )} */}
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === totalSteps() - 1 && !loading && (
                <StyledStepperButton
                  disabled={creationDisabled}
                  onClick={() => {
                    handleComplete();
                    localStorage.removeItem('newProjectId');
                    navigate('/launchpad/my-projects');
                  }}
                  sx={{ ...btnAdditionalStyles }}
                >
                  {t('createProject')}
                </StyledStepperButton>
              )}
            </Box>
          </React.Fragment>
        )}
      </StyledStepperWrapper>
      {openClearFormPopup && (
        <ClearFormPopup
          isOpen={openClearFormPopup}
          onClose={() => setOpenClearFormPopup(false)}
          goBack={handleBack}
        />
      )}
    </>
  );
};

export default CreateProjectWrapperComponent;
