import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import { useMediaQuery, useTheme } from '@mui/material';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {
  TAWK_TO_PROPERTY_ID,
  TAWK_TO_WIDGET_ID,
} from '../../helpers/constants';

import {
  NavBarWrapper,
  LogoWrap,
  StyledLink,
  StyledLinkNav,
  StyledNav,
  StyledTabs,
  ContentWrap,
  TabTitle,
  TabWrap,
  StyledFullIcon,
} from './styles';
import { PageRoutes } from '../../config/routes';
import LogoIcon from 'svg/LogoIcon';
import HomeIcon from 'svg/HomeIcon';
import LiquidityIcon from 'svg/LiquidityIcon';
import NFTMarketplaceIcon from 'svg/NFTMarketplaceIcon';
import HelpIcon from 'svg/HelpIcon';
import HomeIconActive from 'svg/HomeIconActive';
import LiquidityIconActive from 'svg/LiquidityIconActive';
import NFTMarketplaceIconActive from 'svg/NFTMarketplaceIconActive';
import LogoFullIcon from 'svg/LogoFullIcon';
import { Color } from 'helpers/themeStyles';
import SideBarBottomSection from 'components/SideBarBottomSection';
import LaunchpadIconActive from 'svg/LaunchpadIconActive';
import LaunchpadIcon from 'svg/LaunchpadIcon';
import FAQIcon from 'svg/FAQIcon';

const NavBar: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [isHovering, setIsHovering] = React.useState(false);
  const currentLocation = window.location.href;
  const tawkMessengerRef = useRef();
  const [ref, setRef] = useState();

  React.useEffect(() => {
    if (currentLocation.includes('liquidity')) {
      setValue(1);
    } else if (currentLocation.includes('nft-marketplace')) {
      setValue(2);
    } else if (currentLocation.includes('help')) {
      setValue(3);
    } else if (currentLocation.includes('launchpad')) {
      setValue(4);
    } else {
      setValue(0);
    }
  }, [currentLocation, value]);
  useEffect(() => {
    const element = tawkMessengerRef?.current;
    if (element) {
      setRef(element);
    }
  }, []);
  const customStyle = {
    visibility: {
      desktop: {
        xOffset: '10',
        position: 'cl',
      },
    },
  };
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <>
      {!isMobile && (
        <NavBarWrapper
          style={{
            width: isHovering ? '300px' : '100px',
          }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <StyledLink to=''>
            <LogoWrap>
              {isHovering ? (
                <>
                  <LogoIcon /> <StyledFullIcon>DemoDex</StyledFullIcon>{' '}
                </>
              ) : (
                <LogoIcon />
              )}
            </LogoWrap>
          </StyledLink>
          <ContentWrap>
            <StyledNav>
              <StyledTabs
                orientation='vertical'
                value={value}
                indicatorColor='secondary'
              >
                <StyledLinkNav
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                  }}
                  to={PageRoutes.Home}
                >
                  <TabWrap
                    onClick={() => setValue(0)}
                    style={{
                      backgroundColor:
                        value === 0
                          ? Color.DARK_PURPLE_OPACITY_MIDDLE
                          : Color.BACKGROUND,
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={value === 0 ? <HomeIconActive /> : <HomeIcon />}
                      aria-label='home'
                    />
                    {isHovering && <TabTitle>{t('home')}</TabTitle>}
                  </TabWrap>
                </StyledLinkNav>
                <StyledLinkNav
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                    paddingLeft: isHovering ? '5px' : 0,
                  }}
                  to={PageRoutes.Liquidity}
                >
                  <TabWrap
                    onClick={() => setValue(1)}
                    style={{
                      backgroundColor:
                        value === 1
                          ? Color.DARK_PURPLE_OPACITY_MIDDLE
                          : Color.BACKGROUND,
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={
                        value === 1 ? (
                          <LiquidityIconActive />
                        ) : (
                          <LiquidityIcon />
                        )
                      }
                      aria-label='liquidity'
                    />
                    {isHovering && <TabTitle>{t('liquidity')}</TabTitle>}
                  </TabWrap>
                </StyledLinkNav>
                <StyledLinkNav
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                    paddingLeft: isHovering ? '5px' : 0,
                  }}
                  to={PageRoutes.Nft}
                >
                  <TabWrap
                    onClick={() => setValue(2)}
                    style={{
                      backgroundColor:
                        value === 2
                          ? Color.DARK_PURPLE_OPACITY_MIDDLE
                          : Color.BACKGROUND,
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={
                        value === 2 ? (
                          <NFTMarketplaceIconActive />
                        ) : (
                          <NFTMarketplaceIcon />
                        )
                      }
                      aria-label='nft-marketplace'
                    />
                    {isHovering && <TabTitle>{t('nft')}</TabTitle>}
                  </TabWrap>
                </StyledLinkNav>
                <StyledLinkNav
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                    paddingLeft: isHovering ? '5px' : 0,
                  }}
                  to={PageRoutes.Launchpad}
                >
                  <TabWrap
                    onClick={() => setValue(4)}
                    style={{
                      backgroundColor:
                        value === 4
                          ? Color.DARK_PURPLE_OPACITY_MIDDLE
                          : Color.BACKGROUND,
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={
                        value === 4 ? (
                          <LaunchpadIconActive />
                        ) : (
                          <LaunchpadIcon />
                        )
                      }
                      aria-label='launchpad'
                    />
                    {isHovering && <TabTitle>{t('launchpad')}</TabTitle>}
                  </TabWrap>
                </StyledLinkNav>
                <button
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                    paddingLeft: isHovering ? '5px' : 0,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    //@ts-ignore
                    ref?.toggle();
                  }}
                >
                  <TawkMessengerReact
                    propertyId={TAWK_TO_PROPERTY_ID}
                    widgetId={TAWK_TO_WIDGET_ID}
                    onLoad={() => {
                      //@ts-ignore
                      tawkMessengerRef?.current?.hideWidget();
                    }}
                    ref={tawkMessengerRef}
                    customStyle={customStyle}
                  />
                  <TabWrap
                    onClick={() => setValue(3)}
                    style={{
                      backgroundColor: Color.BACKGROUND,
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={<HelpIcon />}
                      aria-label='helpe'
                    />
                    {isHovering && <TabTitle>{t('help')}</TabTitle>}
                  </TabWrap>
                </button>
                <StyledLinkNav
                  style={{
                    justifyContent: isHovering ? 'flex-start' : 'center',
                    paddingLeft: isHovering ? '5px' : 0,
                  }}
                  // TO DO - enter a proper link from customers
                  to={''}
                  target='_blank'
                >
                  <TabWrap
                    style={{
                      width: isHovering ? '262px' : '64px',
                      justifyContent: isHovering ? 'flex-start' : 'center',
                    }}
                  >
                    <Tab
                      style={{ marginLeft: isHovering ? '-16px' : 0 }}
                      icon={<FAQIcon />}
                      aria-label='FAQ'
                    />
                    {isHovering && <TabTitle>{t('faq')}</TabTitle>}
                  </TabWrap>
                </StyledLinkNav>
              </StyledTabs>
            </StyledNav>
            <SideBarBottomSection isHovering={isHovering} />
          </ContentWrap>
        </NavBarWrapper>
      )}
    </>
  );
};

export default NavBar;
