import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.952 2.67181C17.048 1.14081 14.036 0.881812 13.908 0.870812C13.707 0.853812 13.516 0.967812 13.434 1.15181C13.428 1.16381 13.362 1.31481 13.289 1.54981C14.548 1.76181 16.095 2.18981 17.495 3.05881C17.719 3.19781 17.788 3.49281 17.649 3.71781C17.559 3.86381 17.402 3.94381 17.242 3.94381C17.156 3.94381 17.069 3.92081 16.99 3.87181C14.584 2.37981 11.578 2.30481 11 2.30481C10.422 2.30481 7.415 2.37981 5.011 3.87181C4.786 4.01181 4.492 3.94181 4.352 3.71781C4.212 3.49281 4.282 3.19881 4.506 3.05881C5.906 2.19081 7.452 1.76181 8.712 1.54981C8.638 1.31381 8.572 1.16381 8.567 1.15181C8.484 0.967812 8.294 0.851812 8.092 0.871812C7.965 0.881812 4.953 1.14081 3.023 2.69381C2.015 3.62481 0 9.07281 0 13.7828C0 13.8658 0.022 13.9478 0.063 14.0198C1.454 16.4628 5.248 17.1028 6.113 17.1308C6.118 17.1308 6.123 17.1308 6.128 17.1308C6.281 17.1308 6.425 17.0578 6.515 16.9338L7.39 15.7318C5.031 15.1218 3.826 14.0868 3.756 14.0258C3.558 13.8508 3.539 13.5488 3.714 13.3508C3.889 13.1528 4.19 13.1338 4.388 13.3078C4.417 13.3338 6.636 15.2168 11 15.2168C15.372 15.2168 17.591 13.3258 17.613 13.3068C17.811 13.1348 18.113 13.1528 18.287 13.3518C18.461 13.5498 18.442 13.8508 18.245 14.0248C18.175 14.0868 16.97 15.1208 14.611 15.7308L15.486 16.9328C15.576 17.0568 15.72 17.1298 15.873 17.1298C15.878 17.1298 15.883 17.1298 15.888 17.1298C16.753 17.1028 20.547 16.4628 21.938 14.0188C21.978 13.9468 22 13.8658 22 13.7828C22 9.07281 19.985 3.62481 18.952 2.67181ZM7.891 11.8698C6.967 11.8698 6.217 11.0128 6.217 9.95681C6.217 8.90081 6.966 8.04381 7.891 8.04381C8.816 8.04381 9.565 8.90081 9.565 9.95681C9.565 11.0128 8.816 11.8698 7.891 11.8698ZM14.109 11.8698C13.185 11.8698 12.435 11.0128 12.435 9.95681C12.435 8.90081 13.184 8.04381 14.109 8.04381C15.033 8.04381 15.783 8.90081 15.783 9.95681C15.783 11.0128 15.033 11.8698 14.109 11.8698Z'
        fill='white'
      />
    </svg>
  );
};

export default DiscordIcon;
