import { KeyboardBackspace } from '@mui/icons-material';
import { Stack, ToggleButtonGroup } from '@mui/material';
import {
  CurrencyAmount,
  DEXToken,
  PancakePair,
  Position,
  UniswapProvider,
  tickToPrice,
} from '@omisoftnet/game-dex-sdk';
import { IPosition } from '@omisoftnet/game-dex-sdk/dist/providers';
import { BigNumber, utils } from 'ethers';
import JSBI from 'jsbi';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { useSigner } from '@hooks/useSigner';
import AddLiquidityV2 from 'components/AddLiquidityV2';
import ClaimFeesModal from 'components/ClaimFeesModal';
import IncreaseLiquidityPopup from 'components/IncreaseLiquidityPopup';
import RemoveLiquidityModal from 'components/RemoveLiquidityModal';
import RemoveLiquidityV2Modal from 'components/RemoveLiquidityV2Modal';
import { StyledTitle } from 'components/SwapModal/styles';
import { fetchData } from 'config/api';
import isFirstTokenChoosen from 'helpers/isFirstTokenChoosen';
import { roundNumber } from 'helpers/roundNumber';
import { Border, BorderRadius, Color } from 'helpers/themeStyles';
import Loader from 'shared/Loader';
import { dexSelector, signerSelector } from 'state/dex/selectors';
import { liquiditySettingsSelector } from 'state/liquidity/selectors';
import {
  setFirstLiquidityToken,
  setSecondLiquidityToken,
} from 'state/liquidity/slice';
import { setPopularTokens } from 'state/swap/slice';
import { Pool } from 'types/pool';
import { useAccount } from 'wagmi';
import {
  StyledAccentSubtitle,
  StyledBackButton,
  StyledButton,
  StyledButtonWrapper,
  StyledContainer,
  StyledContent,
  StyledContentContainer,
  StyledDivider,
  StyledDolarSign,
  StyledHeaderContainer,
  StyledIncreaseDialog,
  StyledInfoWrapper,
  StyledMinMaxPriceStack,
  StyledPoolInfoStack,
  StyledPositionItemIcon,
  StyledPositionSymbol,
  StyledPositionTolerance,
  StyledPriceRangeContainer,
  StyledSmallButton,
  StyledStatusIcon,
  StyledStatusTitle,
  StyledSubtitle,
  StyledSwitchPairButton,
  StyledTokenInfo,
  StyledTokenInfoContainer,
  StyledTokenName,
  StyledTokenValue,
} from './styles';
export default function PoolDetails(props: any) {
  const { t } = useTranslation();
  const dex = useSelector(dexSelector);
  const navigate = useNavigate();
  const { id } = useParams();
  const { chain } = useAccount();
  const signer = useSelector(signerSelector);
  const dispatch = useDispatch();
  const V2 = chain?.id && [56, 97].includes(chain?.id);
  const [poolInfo, setPoolInfo] = useState<Pool | undefined>(undefined);
  const [poolV3Info, setPoolV3Info] = useState<IPosition>();
  const [sdkPosition, setPosition] = useState<Position>();
  const [token1, setToken1] = useState<DEXToken | undefined>();
  const [token0, setToken0] = useState<DEXToken | undefined>();
  const [loading, setLoading] = useState(false);
  const [tokenAlignment, setTokenAlignment] = useState<string>(token0?.symbol!);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isIncreaseOpen, setIsIncreaseOpen] = useState<boolean>(false);
  const [isIncreaseV3Open, setIsIncreaseV3Open] = useState<boolean>(false);
  const [isClaimFeesOpen, setIsClaimFeesOpen] = useState<boolean>(false);
  const [token0Fees, setToken0Fees] = useState<string>('');
  const [token1Fees, setToken1Fees] = useState<string>('');
  const [inRange, setInRange] = useState<boolean>(true);
  const [showClaimFeesBtn, setShowClaimFeesBtn] = useState<boolean>(true);
  const [isClosedPosition, setIsClosedPosition] = useState<boolean>(false);
  const [firstTokenPerSecondToken, setFirstTokenPerSecondToken] =
    useState<string>('');
  const [secondTokenPerFirstToken, setSecondTokenPerFirstToken] =
    useState<string>('');
  const [[perOneToken0, perOneToken1], setPerOneToken] = useState<
    [string, string]
  >(['', '']);
  const liquiditySettings = useSelector(liquiditySettingsSelector);

  useEffect(() => {
    if (signer) {
      if (V2) {
        getPoolInfo();
      } else {
        getPoolV3();
      }
    }
  }, [V2]);
  const getPoolInfo = async () => {
    setLoading(true);
    try {
      const poolInfo = await fetchData(`/liquidity/one/${id}`);
      setPoolInfo(poolInfo.data);
      setToken0(poolInfo.data.tokenAInfo);
      setToken1(poolInfo.data.tokenBInfo);
      setLoading(false);
    } catch (error) {
      console.error('ERROR', error);
      setLoading(false);
    }
  };
  useMemo(() => {
    if (
      sdkPosition &&
      sdkPosition.tickLower &&
      sdkPosition.tickUpper &&
      sdkPosition.pool.tickCurrent
    ) {
      if (
        sdkPosition.tickLower <= sdkPosition.pool.tickCurrent &&
        sdkPosition.pool.tickCurrent <= sdkPosition.tickUpper
      ) {
        setInRange(true);
      } else setInRange(false);
    }
  }, [sdkPosition]);

  const getCollectFeesAmounts = async () => {
    if (!dex) return;
    const provider = await dex.getDEXProvider(chain!.id);
    if (provider instanceof UniswapProvider) {
      const [amount0, amount1] = await provider.collectFeesAmounts(Number(id));
      const isSorted = token0 && token1 && token0.sortsBefore(token1);
      setToken0Fees(
        utils.formatUnits(
          isSorted ? `${amount0}` : `${amount1}`,
          isSorted ? token0!.decimals : token1!.decimals
        )
      );
      setToken1Fees(
        utils.formatUnits(
          isSorted ? `${amount1}` : `${amount0}`,
          isSorted ? token1!.decimals : token0!.decimals
        )
      );
    }
  };

  useMemo(() => {
    if (
      signer &&
      token0 &&
      token1 &&
      token0.name !== '!' &&
      token1.name !== '!'
    ) {
      getCollectFeesAmounts();
    }
    if (poolV3Info?.wrapped().liquidity.length === 0) {
      setIsClosedPosition(true);
    }
  }, [signer, token0, token1, chain]);

  const getPoolV3 = async () => {
    setLoading(true);
    if (!dex) return;
    try {
      const position = await dex.liquidityPosition(id!);

      setPosition(
        new Position({
          pool: position?.wrapped().pool,
          liquidity: position?.wrapped().liquidity,
          tickLower: position?.wrapped().tickLower,
          tickUpper: position?.wrapped().tickUpper,
        })
      );

      setToken0(position.token0());
      setToken1(position.token1());
      setPoolV3Info(position);
      setLoading(false);
    } catch (error) {
      console.error('ERROR', error);
      setLoading(false);
    }
  };

  const handleTokenAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (
      newAlignment === null &&
      token0 &&
      token1 &&
      token0.symbol === tokenAlignment
    ) {
      setTokenAlignment(token1.symbol!);
    } else if (newAlignment === null && token1?.symbol === tokenAlignment) {
      setTokenAlignment(token0?.symbol!);
    } else {
      setTokenAlignment(newAlignment);
    }
  };

  const perOneTokenEstimate = async (reverse: boolean = false) => {
    if (token0 && token1 && !V2) {
      const firstToken = DEXToken.fromAddress(
        token0?.address,
        utils.parseUnits('0.0001', token0.decimals),
        token0?.chainId,
        token0?.decimals,
        token0?.symbol,
        token0?.name,
        token0?.icon
      );
      const secondToken = DEXToken.fromAddress(
        token1?.address,
        utils.parseUnits('0.0001', token1.decimals),
        token1?.chainId,
        token1?.decimals,
        token1?.symbol,
        token1?.name,
        token1?.icon
      );
      const selectedToken = reverse ? secondToken : firstToken;
      const selectedOutput = reverse ? firstToken : secondToken;
      if (!dex) return;
      const perOneOutput = await dex.getAmountsIn(
        selectedToken.wrapped,
        selectedOutput.wrapped
      );
      if (reverse) {
        setSecondTokenPerFirstToken(
          String(
            Number(
              utils.formatUnits(`${perOneOutput!}`, secondToken?.decimals)
            ) * 10000
          ).slice(0, 7)
        );
      } else {
        setFirstTokenPerSecondToken(
          String(
            Number(
              utils.formatUnits(`${perOneOutput!}`, firstToken?.decimals)
            ) * 10000
          ).slice(0, 7)
        );
      }
    }
  };
  const isSorted = useMemo(() => {
    if (token0?.wrapped && token1?.wrapped) {
      return token0.wrapped.sortsBefore(token1.wrapped);
    }
  }, [token0, token1]);
  useMemo(async () => {
    if (token0 && token1 && V2 && signer) {
      const firstToken = DEXToken.fromAddress(
        token0?.address,
        utils.parseUnits('0.0001', token0.decimals),
        token0?.chainId,
        token0?.decimals,
        token0?.symbol,
        token0?.name,
        token0?.icon
      );
      const secondToken = DEXToken.fromAddress(
        token1?.address,
        utils.parseUnits('0.0001', token1.decimals),
        token1?.chainId,
        token1?.decimals,
        token1?.symbol,
        token1?.name,
        token1?.icon
      );
      if (!dex) return;
      const provider = await dex.getDEXProvider(
        token0?.chainId,
        firstToken,
        secondToken
      );
      const factoryAddress = await provider.factory();
      const [tokenA, tokenB] = firstToken.wrapped.sortsBefore(
        secondToken.wrapped
      )
        ? [secondToken, firstToken]
        : [firstToken, secondToken];
      const pair = await PancakePair.from(
        tokenA,
        tokenB,
        signer!,
        factoryAddress
      );
      const token0Amount = CurrencyAmount.fromRawAmount(
        pair!.pair.token1,
        utils.parseUnits('1', pair!.pair.token0.decimals).toHexString()
      );
      const token1Amount = CurrencyAmount.fromRawAmount(
        pair!.pair.token0,
        utils.parseUnits('1', pair!.pair.token1.decimals).toHexString()
      );
      const priceTokenA = utils.formatUnits(
        BigNumber.from(
          pair!.pair.priceOf(pair!.pair.token0).quote(token1Amount).quotient
        ),
        pair!.pair.token0.decimals
      );
      const priceTokenB = utils.formatUnits(
        BigNumber.from(
          pair!.pair.priceOf(pair!.pair.token1).quote(token0Amount).quotient
        ),
        pair!.pair.token1.decimals
      );
      setPerOneToken(
        isSorted ? [priceTokenA, priceTokenB] : [priceTokenB, priceTokenA]
      );
    }
  }, [token0, token1, signer]);
  async function getTopTokens() {
    if (!dex) return;
    const topTokens = await dex.getTopTokens(chain?.id ?? 1);
    dispatch(setPopularTokens([...topTokens]));
  }

  useEffect(() => {
    getTopTokens();
  }, [token0, token1]);

  useEffect(() => {
    perOneTokenEstimate();
    perOneTokenEstimate(true);
  }, [token0, token1]);

  useEffect(() => {
    if (V2 && token0 && token1) {
      const firstToken = DEXToken.fromAddress(
        token0?.address!,
        undefined,
        token0?.chainId!,
        token0?.decimals!,
        token0?.symbol!,
        token0?.name!,
        token0?.icon!
      );
      const secondToken = DEXToken.fromAddress(
        token1?.address!,
        undefined,
        token1?.chainId!,
        token1?.decimals!,
        token1?.symbol!,
        token1?.name!,
        token1?.icon!
      );
      dispatch(setFirstLiquidityToken(firstToken));
      dispatch(setSecondLiquidityToken(secondToken));
    }
    return () => {
      if (V2) {
        dispatch(setFirstLiquidityToken(DEXToken.default()));
        dispatch(setSecondLiquidityToken(DEXToken.default()));
      }
    };
  }, [token0, token1]);

  useEffect(() => {
    // @ts-ignore - for some reason for BUSD token logoUri is called logoURI, that's why we need to process this field to a standard format
    if (token0?.logoURI) {
      // @ts-ignore
      token0.logoUri = token0?.logoURI;
    }
    // @ts-ignore
    if (token1?.logoURI) {
      // @ts-ignore
      token1.logoUri = token1?.logoURI;
    }
  }, [token0, token1]);

  return (
    <>
      {loading ? (
        <Stack
          alignItems='center'
          mt={10}
        >
          <Loader />
        </Stack>
      ) : (
        <>
          {token0 && token1 && (
            <StyledContainer>
              <StyledBackButton
                startIcon={<KeyboardBackspace />}
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t('back')}
              </StyledBackButton>
              <StyledHeaderContainer>
                <StyledInfoWrapper>
                  <Stack
                    direction='row'
                    alignItems='center'
                  >
                    <StyledPositionItemIcon
                      src={
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token0.icon || token0?.logoUri
                          : token1.icon || token1?.logoUri
                      }
                      alt=''
                    />
                    <StyledPositionItemIcon
                      src={
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token1.icon || token1?.logoUri
                          : token0.icon || token0?.logoUri
                      }
                      alt=''
                      style={{ position: 'relative', left: '-4px' }}
                    />
                  </Stack>
                  <StyledPositionSymbol>{`${
                    !isFirstTokenChoosen(tokenAlignment, token0.symbol ?? 'UNK')
                      ? token0.symbol
                      : token1.symbol
                  }/${
                    !isFirstTokenChoosen(tokenAlignment, token0.symbol ?? 'UNK')
                      ? token1.symbol
                      : token0.symbol
                  }`}</StyledPositionSymbol>
                  {!V2 && (
                    <>
                      <StyledDivider />
                      <StyledPositionTolerance>
                        {poolV3Info?.wrapped()?.pool?.fee / 10000} %
                      </StyledPositionTolerance>
                      <StyledDivider />
                    </>
                  )}
                  <StyledStatusIcon
                    style={{
                      background: V2
                        ? Color.GREEN
                        : isClosedPosition
                        ? Color.LIGHT_RED
                        : inRange
                        ? Color.GREEN
                        : Color.YELLOW,
                    }}
                  />
                  <StyledStatusTitle>
                    {V2
                      ? t('inRange')
                      : isClosedPosition
                      ? t('closed')
                      : inRange
                      ? t('inRange')
                      : t('outOfRange')}
                  </StyledStatusTitle>
                </StyledInfoWrapper>
                <StyledButtonWrapper>
                  {poolV3Info
                    ?.wrapped()
                    .amount0.toSignificant(5, undefined, 2) > 0 &&
                    poolV3Info
                      ?.wrapped()
                      .amount1.toSignificant(5, undefined, 2) > 0 && (
                      <StyledButton
                        variant='outlined'
                        onClick={() => setIsIncreaseV3Open(true)}
                      >
                        {t('increaseLiquidity')}
                      </StyledButton>
                    )}
                  {V2 && (
                    <StyledButton
                      variant='outlined'
                      onClick={() => setIsIncreaseOpen(true)}
                    >
                      {t('increaseLiquidity')}
                    </StyledButton>
                  )}

                  <StyledButton
                    variant='contained'
                    onClick={() => setIsOpen(true)}
                  >
                    {t('removeLiquidityText')}
                  </StyledButton>
                </StyledButtonWrapper>
              </StyledHeaderContainer>
              <Stack>
                <StyledContent>
                  <StyledPoolInfoStack>
                    <StyledContentContainer
                      width='50%'
                      alignItems='flex-start'
                    >
                      <StyledTitle>{t('liquidity')}</StyledTitle>
                      <StyledDolarSign>$-</StyledDolarSign>
                      <StyledTokenInfoContainer>
                        <StyledTokenInfo
                          style={{
                            paddingBottom: '12px',
                            borderBottom: Border.ACCENT_BORDER,
                          }}
                        >
                          <StyledInfoWrapper>
                            <StyledPositionItemIcon
                              src={
                                !isFirstTokenChoosen(
                                  tokenAlignment,
                                  token0.symbol ?? 'UNK'
                                )
                                  ? token0.icon || token0?.logoUri
                                  : token1.icon || token1?.logoUri
                              }
                              alt=''
                            />
                            <StyledTokenName>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? token0.symbol
                                : token1.symbol}
                            </StyledTokenName>
                          </StyledInfoWrapper>
                          <StyledInfoWrapper>
                            <StyledTokenValue>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? V2
                                  ? utils
                                      .formatUnits(
                                        token0.amount,
                                        token0.decimals
                                      )
                                      .slice(0, 18)
                                  : poolV3Info
                                      ?.wrapped()
                                      .amount0.toSignificant(5, undefined, 2)
                                      .slice(0, 18)
                                : V2
                                ? utils
                                    .formatUnits(token1.amount, token1.decimals)
                                    .slice(0, 18)
                                : poolV3Info
                                    ?.wrapped()
                                    .amount1.toSignificant(5, undefined, 2)
                                    .slice(0, 18)}
                            </StyledTokenValue>
                          </StyledInfoWrapper>
                        </StyledTokenInfo>
                        <StyledTokenInfo style={{ paddingTop: '12px' }}>
                          <StyledInfoWrapper>
                            <StyledPositionItemIcon
                              src={
                                isFirstTokenChoosen(
                                  tokenAlignment,
                                  token0.symbol ?? 'UNK'
                                )
                                  ? token0!.icon || token0?.logoUri
                                  : token1!.icon || token1?.logoUri
                              }
                              alt=''
                            />
                            <StyledTokenName>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? token1.symbol
                                : token0.symbol}
                            </StyledTokenName>
                          </StyledInfoWrapper>
                          <StyledInfoWrapper>
                            <StyledTokenValue>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? V2
                                  ? utils
                                      .formatUnits(
                                        token1.amount,
                                        token1.decimals
                                      )
                                      .slice(0, 18)
                                  : poolV3Info
                                      ?.wrapped()
                                      .amount1.toSignificant(5, undefined, 2)
                                      .slice(0, 18)
                                : V2
                                ? utils
                                    .formatUnits(token0.amount, token0.decimals)
                                    .slice(0, 18)
                                : poolV3Info
                                    ?.wrapped()
                                    .amount0.toSignificant(5, undefined, 2)
                                    .slice(0, 18)}
                            </StyledTokenValue>
                          </StyledInfoWrapper>
                        </StyledTokenInfo>
                      </StyledTokenInfoContainer>
                    </StyledContentContainer>
                    <StyledContentContainer
                      width='50%'
                      alignItems='flex-start'
                      position='relative'
                    >
                      <StyledTitle>{t('unclaimedFees')}</StyledTitle>
                      <StyledDolarSign>$-</StyledDolarSign>
                      {chain?.id &&
                        ![56, 97].includes(chain?.id) &&
                        (token0Fees !== '0.0' || token1Fees !== '0.0') &&
                        showClaimFeesBtn && (
                          <StyledSmallButton
                            variant='contained'
                            onClick={() => setIsClaimFeesOpen(true)}
                          >
                            {t('collectFees')}
                          </StyledSmallButton>
                        )}
                      <StyledTokenInfoContainer>
                        <StyledTokenInfo
                          style={{
                            paddingBottom: '12px',
                            borderBottom: Border.ACCENT_BORDER,
                          }}
                        >
                          <StyledInfoWrapper>
                            <StyledPositionItemIcon
                              src={
                                !isFirstTokenChoosen(
                                  tokenAlignment,
                                  token0.symbol ?? 'UNK'
                                )
                                  ? token0.icon || token0?.logoUri
                                  : token1.icon || token1?.logoUri
                              }
                              alt=''
                            />
                            <StyledTokenName>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? token0.symbol
                                : token1.symbol}
                            </StyledTokenName>
                          </StyledInfoWrapper>
                          <StyledTokenValue>
                            {!isFirstTokenChoosen(
                              tokenAlignment,
                              token0.symbol ?? 'UNK'
                            )
                              ? token0Fees === '0.0'
                                ? 0
                                : token0Fees
                              : token1Fees === '0.0'
                              ? 0
                              : token1Fees}
                          </StyledTokenValue>
                        </StyledTokenInfo>
                        <StyledTokenInfo style={{ paddingTop: '12px' }}>
                          <StyledInfoWrapper>
                            <StyledPositionItemIcon
                              src={
                                !isFirstTokenChoosen(
                                  tokenAlignment,
                                  token0.symbol ?? 'UNK'
                                )
                                  ? token1.icon || token1?.logoUri
                                  : token0.icon || token0?.logoUri
                              }
                              alt=''
                            />
                            <StyledTokenName>
                              {!isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? token1.symbol
                                : token0.symbol}
                            </StyledTokenName>
                          </StyledInfoWrapper>
                          <StyledTokenValue>
                            {!isFirstTokenChoosen(
                              tokenAlignment,
                              token0.symbol ?? 'UNK'
                            )
                              ? token1Fees === '0.0'
                                ? 0
                                : token1Fees
                              : token0Fees === '0.0'
                              ? 0
                              : token0Fees}
                          </StyledTokenValue>
                        </StyledTokenInfo>
                      </StyledTokenInfoContainer>
                    </StyledContentContainer>
                  </StyledPoolInfoStack>
                  <StyledPriceRangeContainer>
                    <StyledTitle>{t('priceRange')}</StyledTitle>
                    <ToggleButtonGroup
                      value={tokenAlignment}
                      onChange={handleTokenAlignment}
                      exclusive
                      aria-label='text alignment'
                      sx={{
                        borderRadius: BorderRadius.SEMI_SMALL,
                        color: Color.WHITE,
                        '& .Mui-selected': {
                          backgroundColor: `${Color.WHITE_OPACITY}!important`,
                        },
                      }}
                    >
                      <StyledSwitchPairButton value={token1.symbol ?? 'UNK'}>
                        {token1.symbol}
                      </StyledSwitchPairButton>
                      <StyledSwitchPairButton value={token0.symbol ?? 'UNK'}>
                        {token0.symbol}
                      </StyledSwitchPairButton>
                    </ToggleButtonGroup>
                  </StyledPriceRangeContainer>
                  <StyledMinMaxPriceStack>
                    <StyledContentContainer
                      width='50%'
                      sx={{
                        border: Border.ACCENT_BORDER,
                        alignItems: 'center',
                      }}
                    >
                      <StyledSubtitle>{t('minPrice')}</StyledSubtitle>
                      <StyledAccentSubtitle
                        sx={{
                          lineHeight: V2 ? 1 : 1.5,
                        }}
                      >
                        {V2 ? (
                          <span
                            style={{
                              fontSize: '30px',
                              color: Color.WHITE,
                            }}
                          >
                            ∞
                          </span>
                        ) : poolV3Info ? (
                          poolV3Info.wrapped().tickUpper === 887220 ? (
                            '0'
                          ) : (
                            tickToPrice(
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token0
                                : poolV3Info?.wrapped().pool.token1,
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token1
                                : poolV3Info?.wrapped().pool.token0,
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().tickLower
                                : poolV3Info?.wrapped().tickUpper
                            ).toSignificant()
                          )
                        ) : (
                          ''
                        )}
                      </StyledAccentSubtitle>
                      <StyledSubtitle>{`${
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token0.symbol
                          : token1.symbol
                      } ${t('per')} ${
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token1.symbol
                          : token0.symbol
                      }`}</StyledSubtitle>
                    </StyledContentContainer>
                    <StyledContentContainer
                      width='50%'
                      sx={{
                        border: Border.ACCENT_BORDER,
                        alignItems: 'center',
                      }}
                    >
                      <StyledSubtitle>{t('maxPrice')}</StyledSubtitle>
                      <StyledAccentSubtitle
                        sx={{
                          lineHeight: V2 ? 1 : 1.5,
                        }}
                      >
                        {V2 ? (
                          <span
                            style={{
                              fontSize: '30px',
                              color: Color.WHITE,
                            }}
                          >
                            ∞
                          </span>
                        ) : poolV3Info ? (
                          poolV3Info.wrapped().tickLower === -887220 ? (
                            '∞'
                          ) : (
                            tickToPrice(
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token0
                                : poolV3Info?.wrapped().pool.token1,
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token1
                                : poolV3Info?.wrapped().pool.token0,
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().tickUpper
                                : poolV3Info?.wrapped().tickLower
                            ).toSignificant()
                          )
                        ) : (
                          ''
                        )}
                      </StyledAccentSubtitle>
                      <StyledSubtitle>{`${
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token0.symbol
                          : token1.symbol
                      } ${t('per')} ${
                        !isFirstTokenChoosen(
                          tokenAlignment,
                          token0.symbol ?? 'UNK'
                        )
                          ? token1.symbol
                          : token0.symbol
                      }`}</StyledSubtitle>
                    </StyledContentContainer>
                  </StyledMinMaxPriceStack>
                  <StyledContentContainer
                    width='96%'
                    sx={{ border: Border.ACCENT_BORDER, alignItems: 'center' }}
                  >
                    <StyledSubtitle>{t('currentPrice')}</StyledSubtitle>
                    <StyledAccentSubtitle>
                      {isFirstTokenChoosen(
                        tokenAlignment,
                        token0.symbol ?? 'UNK'
                      ) && !V2
                        ? firstTokenPerSecondToken
                        : isFirstTokenChoosen(
                            tokenAlignment,
                            token0.symbol ?? 'UNK'
                          ) && V2
                        ? roundNumber(Number(perOneToken1))
                        : !isFirstTokenChoosen(
                            tokenAlignment,
                            token0.symbol ?? 'UNK'
                          ) && V2
                        ? roundNumber(Number(perOneToken0))
                        : secondTokenPerFirstToken}
                      {V2
                        ? null
                        : poolV3Info
                        ? poolV3Info.wrapped().pool.tickCurrent === 8888888
                          ? '∞'
                          : tickToPrice(
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token0
                                : poolV3Info?.wrapped().pool.token1,
                              isFirstTokenChoosen(
                                tokenAlignment,
                                token0.symbol ?? 'UNK'
                              )
                                ? poolV3Info?.wrapped().pool.token1
                                : poolV3Info?.wrapped().pool.token0,
                              poolV3Info?.wrapped().pool.tickCurrent
                            ).toSignificant()
                        : ''}
                    </StyledAccentSubtitle>
                    <StyledSubtitle>{`${
                      !isFirstTokenChoosen(
                        tokenAlignment,
                        token0.symbol ?? 'UNK'
                      )
                        ? token0.symbol
                        : token1.symbol
                    } ${t('per')} ${
                      !isFirstTokenChoosen(
                        tokenAlignment,
                        token0.symbol ?? 'UNK'
                      )
                        ? token1.symbol
                        : token0.symbol
                    }`}</StyledSubtitle>
                  </StyledContentContainer>
                </StyledContent>
              </Stack>
            </StyledContainer>
          )}

          {V2 && token0 && token1 ? (
            <RemoveLiquidityV2Modal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              firstTokenAddress={token0.address}
              secondTokenAddress={token1.address}
              firstTokenChainId={token0.chainId}
              secondTokenChainId={token1.chainId}
              firstTokenDecimals={token0.decimals}
              secondTokenDecimals={token1.decimals}
              firstTokenName={token0.name ?? 'UNK'}
              secondTokenName={token1.name ?? 'UNK'}
              firstTokenSymbol={token0.symbol ?? 'UNK'}
              secondTokenSymbol={token1.symbol ?? 'UNK'}
              firstTokenIcon={token0.icon ?? token0?.logoUri ?? 'UNK'}
              secondTokenIcon={token1.icon ?? token1?.logoUri ?? 'UNK'}
              firstTokenValue={utils.formatUnits(
                `${token0.amount}`,
                token0.decimals
              )}
              secondTokenValue={utils.formatUnits(
                `${token1.amount}`,
                token1.decimals
              )}
              slippageTolerance={liquiditySettings.slippage}
              liquidity={poolInfo?.liquidity!}
              liquidityId={poolInfo?._id!}
              perOneToken0={perOneToken0}
              perOneToken1={perOneToken1}
            />
          ) : token0 && token1 && poolV3Info ? (
            <RemoveLiquidityModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              firstTokenSymbol={token0.symbol ?? 'UNK'}
              secondTokenSymbol={token1.symbol ?? 'UNK'}
              firstTokenIcon={token0.icon ?? 'UNK'}
              secondTokenIcon={token1.icon ?? 'UNK'}
              firstTokenValue={poolV3Info
                ?.wrapped()
                .amount0.toSignificant(5, undefined, 2)}
              secondTokenValue={poolV3Info
                ?.wrapped()
                .amount1.toSignificant(5, undefined, 2)}
              status={inRange}
              firstTokenAddress={token0.address}
              secondTokenAddress={token1.address}
              firstTokenChainId={token0.chainId}
              secondTokenChainId={token1.chainId}
              firstTokenDecimals={token0.decimals}
              secondTokenDecimals={token1.decimals}
              firstTokenName={token0.name ?? 'UNK'}
              secondTokenName={token1.name ?? 'UNK'}
              slippageTolerance={poolV3Info?.wrapped()?.pool?.fee / 10000}
              liquidity={poolV3Info?.wrapped()?.liquidity as JSBI}
              position={poolV3Info}
              liquidityId={poolV3Info.id()}
              token0Fees={token0Fees}
              token1Fees={token1Fees}
            />
          ) : (
            ''
          )}
          {V2 && token0 && token1 && (
            <StyledIncreaseDialog
              open={isIncreaseOpen}
              onClose={() => setIsIncreaseOpen(false)}
            >
              <AddLiquidityV2
                onClose={() => setIsIncreaseOpen(false)}
                fee={0.3}
                liquidityId={poolInfo?._id}
                increase
              />
            </StyledIncreaseDialog>
          )}
          {!V2 && !!token0 && !!token1 && poolV3Info && (
            <ClaimFeesModal
              isOpen={isClaimFeesOpen}
              setIsOpen={setIsClaimFeesOpen}
              firstTokenSymbol={token0.symbol}
              secondTokenSymbol={token1.symbol}
              firstTokenIcon={token0.icon}
              secondTokenIcon={token1.icon}
              onClose={() => setIsOpen(false)}
              firstTokenValue={token0Fees}
              secondTokenValue={token1Fees}
              firstTokenAddress={token0.address}
              secondTokenAddress={token1.address}
              firstTokenChainId={token0.chainId}
              secondTokenChainId={token1.chainId}
              firstTokenDecimals={token0.decimals}
              secondTokenDecimals={token1.decimals}
              firstTokenName={token0.name ?? 'UNK'}
              secondTokenName={token1.name ?? 'UNK'}
              liquidityId={poolV3Info?.id()}
              position={sdkPosition!}
              setShowClaimFeesBtn={setShowClaimFeesBtn}
              setToken0Fees={setToken0Fees}
              setToken1Fees={setToken1Fees}
            />
          )}
          {!V2 && token0 && token1 && poolV3Info && (
            <IncreaseLiquidityPopup
              isOpen={isIncreaseV3Open}
              onClose={() => setIsIncreaseV3Open(false)}
              fee={poolV3Info?.wrapped()?.pool?.fee / 10000}
              depositIn={poolV3Info
                ?.wrapped()
                .amount0.toSignificant(5, undefined, 2)}
              depositOut={poolV3Info
                ?.wrapped()
                .amount1.toSignificant(5, undefined, 2)}
              token0={poolV3Info?.token0()}
              token1={poolV3Info?.token1()}
              position={poolV3Info.wrapped()}
              positionId={poolV3Info.id()}
              firstTokenSymbol={token0.symbol ?? 'UNK'}
              secondTokenSymbol={token1.symbol ?? 'UNK'}
              firstTokenIcon={token0.icon ?? ''}
              secondTokenIcon={token1.icon ?? ''}
            />
          )}
        </>
      )}
    </>
  );
}
