import { ethers } from '@omisoftnet/game-dex-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import { useTokenContract } from './useContract';

type Address = string | undefined;
const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const useAllowance = (token: Address, spender: Address) => {
  const contract = useTokenContract(token);
  const { address } = useAccount();
  let [allowance, setAllowance] = useState<ethers.BigNumber>();
  let [isWaitingForApprove, setIsWaitingForApprove] = useState<boolean>();
  useMemo(async () => {
    if (!address || !contract || !spender) {
      console.log(
        `Allowance for token ${token} cant be fetched because address or contract undefined`
      );
      return ethers.BigNumber.from(0);
    }
    setAllowance(await contract.allowance(address, spender));
  }, [contract, address, spender]);

  const approve = useCallback(async () => {
    if (!spender) return;
    try {
      setIsWaitingForApprove(true);
      const tx = await contract?.approve(spender, MAX_UINT256);
      await tx?.wait(1);
      setAllowance(ethers.BigNumber.from(MAX_UINT256));
      setIsWaitingForApprove(false);
      return tx;
    } catch {
      setIsWaitingForApprove(false);
    }
  }, [contract, address, spender]);

  return { allowance, isWaitingForApprove, approve };
};
