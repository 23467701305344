import { styled, Typography } from '@mui/material';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledMessageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '6px 16px',
}));

export const StyledMessageWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const StyledMessageAvatar = styled('img')(() => ({
  width: '48px',
  height: '48px',
  borderRadius: BorderRadius.SMALL,
  objectFit: 'cover',
  marginRight: '16px',
}));

export const StyledNickname = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
}));

export const StyledTime = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
}));

export const StyledMessage = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  padding: '8px 12px',
  borderRadius: BorderRadius.EXTRA_SMALL,
  wordBreak: 'break-all',
  maxWidth: '100%',
}));
