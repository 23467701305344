import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'config/api';
import { API_ROUTES } from 'config/apiRoutes';
import { SelectedNft } from 'types/nft';

export const getSelectedNftThunk = createAsyncThunk<SelectedNft, string>(
  'nft/getSelectedNft',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.NFT.GET_ONE(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
