import { Box, styled, TextField } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledMessengerContainer = styled(Box)(({ theme }) => ({
  width: '412px',
  height: '25%',
  minHeight: '25%',
  background: Color.SWAP_GRADIENT,
  border: `1px solid ${Color.SWAP_BORDER_COLOR}`,
  borderRadius: BorderRadius.NORMAL,
  margin: '4px 8px 8px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  [theme.breakpoints.down('mobile')]: {
    width: 'inherit!important',
    height: '81vh!important',
    border: 'none!important',
    margin: '-14px 0px -14px!important',
    borderRadius: 'inherit!important',
  },
}));

export const StyledChatBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const StyledMessengerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  columnGap: '150px',
  padding: '10px 0',

  [theme.breakpoints.down('mobile')]: {
    padding: '14px 0',
    background: Color.BACKGROUND_MAIN,
  },
}));

export const StyledHeaderTitle = styled('div')(() => ({
  paddingLeft: '16px',
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
}));

export const StyledHeaderBtn = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: BorderRadius.SMALL,
  background: Color.DARK_PURPLE_OPACITY,
  padding: '6px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  [theme.breakpoints.down('mobile')]: {
    paddingRight: '14px',
  },
}));

export const StyledHeaderBtnText = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SUPER_SMALL,
  color: Color.WHITE,
}));

export const StyledChatBoxTop = styled(Box)(() => ({
  height: '100%',
  overflowY: 'scroll',
  flexGrow: 1,
}));

export const StyledChatBoxBottom = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 16px',
  background: Color.DARK_PURPLE_OPACITY_LIGHT,
  borderRadius: '0px 0px 16px 16px',
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    bottom: '15px',
    marginTop: '16px',
    padding: '16px',
  },
}));

export const StyledEmojiPickerContainer = styled(Box)(() => ({
  position: 'absolute',
  bottom: 75,
  right: 16,
}));

export const StyledChatMessageInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.SMALL,
    fontWeight: 400,
    color: Color.TEXT_GRAY_SECONDARY,
    borderRadius: BorderRadius.SEMI_NORMAL,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '10px 14px',

    [theme.breakpoints.down('mobile')]: {
      padding: '27px',
      background: Color.BACKGROUND,
      color: Color.WHITE,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
}));

export const StyledChatArrow = styled('button')(({ theme }) => ({
  position: 'absolute',
  padding: '8px 10px',
  bottom: '10%',
  right: '45%',
  borderRadius: BorderRadius.SMALL,
  background: Color.PURPLE,
  border: Border.TRANSPARENT_BORDER,
  cursor: 'pointer',
  [theme.breakpoints.down('mobile')]: {
    bottom: '28%',
  },
}));

export const StyledCount = styled('span')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
  marginLeft: '5px',
}));

export const SendIconWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  right: '40px',
  bottom: '42px',
}));
