import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledSmallText } from 'components/CreateNft/styles';
import { BoxShadow, Color } from 'helpers/themeStyles';
import { userChatOperation } from 'helpers/userChatOperation';
import { UserChatAvatars } from 'shared/avatars/getUserChatAvatars';
import { LocalStorage } from 'utils/LocalStorage';
import Notification from 'utils/Notification';
import {
  StyledAvatar,
  StyledAvatarContainer,
  StyledAvatarTitle,
  StyledButton,
  StyledDialog,
  StyledHeaderContainer,
  StyledHeaderTitle,
  StyledInput,
  StyledInputContainer,
} from './styles';

type ControlProps = {
  openCustomizeMenu: boolean;
  setOpenCustomizeMenu: (newValue: boolean) => void;
};

export default function CustomizeChat({
  openCustomizeMenu,
  setOpenCustomizeMenu,
}: ControlProps) {
  const { t } = useTranslation();
  const [isInputError, setInputError] = useState(false);
  const userLogin = LocalStorage.getUserChatData();
  const [charactersLeft, setCharactersLeft] = useState(25);
  const [nickName, setNickName] = useState<string>(userLogin?.nickname ?? '');
  const [notificationMessage, setNotificationMessage] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const notificationHelper = (errorMessage: string) => {
    setNotificationMessage(errorMessage);
  };
  const keyDownHandler = async (e: any) => {
    if (e.key === 'Enter' || e.target.nodeName === 'BUTTON') {
      e.preventDefault();
      try {
        const loginData = {
          nickname: nickName,
          id: userLogin?.id,
        };
        if (!userLogin) {
          await userChatOperation(loginData, 'create');
          setOpenCustomizeMenu(false);
        } else {
          await userChatOperation(loginData, 'update');
          setOpenCustomizeMenu(false);
        }
      } catch (err) {
        notificationHelper(err?.response?.data?.error);
      }
    }
  };

  return (
    <StyledDialog
      onKeyDown={keyDownHandler}
      open={openCustomizeMenu}
      onClose={() => {
        setOpenCustomizeMenu(false);
      }}
    >
      <StyledHeaderContainer>
        <StyledHeaderTitle>{t('customiseChat')}</StyledHeaderTitle>
        <IconButton
          sx={{
            color: Color.WHITE,
            p: 0,
            pr: '20px',
          }}
          onClick={() => setOpenCustomizeMenu(false)}
        >
          <Close />
        </IconButton>
      </StyledHeaderContainer>
      <DialogContent sx={{ p: 0 }}>
        <StyledInputContainer>
          <Box padding='0 20px'>
            <StyledInput
              fullWidth
              placeholder='Your nickname'
              value={nickName}
              onChange={(e) => {
                const value = e.target.value;
                if (value.match(/^[ ]+$/)) {
                  setInputError(true);
                  setNickName('');
                } else {
                  const remaining = 25 - value.length;
                  if (remaining >= 0) {
                    setNickName(value.replace(/[^\x00-\x7F]/g, ''));
                    setCharactersLeft(remaining);
                  }
                  setInputError(false);
                }
              }}
              error={isInputError}
              helperText={isInputError && t('nickNameError')}
            />
            <StyledSmallText
              sx={{
                color: !charactersLeft ? Color.RED : Color.WHITE,
                textAlign: 'right',
              }}
            >
              {t('symbolsLeft', { count: charactersLeft })}
            </StyledSmallText>
          </Box>
        </StyledInputContainer>
        <Box padding='24px 20px'>
          {isMobile && (
            <StyledButton
              variant='contained'
              disabled={!nickName}
              fullWidth
              onClick={(e) => {
                keyDownHandler(e);
              }}
            >
              {t('confirm')}
            </StyledButton>
          )}
        </Box>
      </DialogContent>
      {notificationMessage && (
        <Notification
          onClose={() => setNotificationMessage('')}
          message={notificationMessage}
        />
      )}
    </StyledDialog>
  );
}
