import React from 'react';

const EmojiIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_747_5727)'>
        <circle
          cx='12'
          cy='12'
          r='11'
          stroke='#CECECE'
          strokeWidth='2'
        />
        <path
          d='M17 15C15.7135 16.7962 14.1401 18 11.9999 18C9.85977 18 8.28646 16.7962 7 15'
          stroke='#CECECE'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <circle
          cx='15.5'
          cy='9.5'
          r='1.5'
          fill='#CECECE'
        />
        <circle
          cx='8.5'
          cy='9.5'
          r='1.5'
          fill='#CECECE'
        />
      </g>
      <defs>
        <clipPath id='clip0_747_5727'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmojiIcon;
