const AlertRoundedIcon = () => {
  return (
    <svg
      width='54'
      height='54'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27 16.3333V27M27 37.6667H27.0267M3 27C3 30.1517 3.62078 33.2726 4.82689 36.1844C6.033 39.0962 7.80083 41.742 10.0294 43.9706C12.258 46.1992 14.9038 47.967 17.8156 49.1731C20.7274 50.3792 23.8483 51 27 51C30.1517 51 33.2726 50.3792 36.1844 49.1731C39.0962 47.967 41.742 46.1992 43.9706 43.9706C46.1992 41.742 47.967 39.0962 49.1731 36.1844C50.3792 33.2726 51 30.1517 51 27C51 20.6348 48.4714 14.5303 43.9706 10.0294C39.4697 5.52856 33.3652 3 27 3C20.6348 3 14.5303 5.52856 10.0294 10.0294C5.52856 14.5303 3 20.6348 3 27Z'
        stroke='#FF3156'
        stroke-width='5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
export default AlertRoundedIcon;
