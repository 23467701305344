import { useCallback, useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEXToken,
  Pool,
  priceToClosestTick,
  tickToPrice,
  UniswapProvider,
  utils,
} from '@omisoftnet/game-dex-sdk';
import {
  FeeAmount,
  Position,
  TickMath,
  nearestUsableTick,
} from '@uniswap/v3-sdk';
import { useAccount, useBalance } from 'wagmi';
import JSBI from 'jsbi';
import { Price } from '@uniswap/sdk-core';

import {
  AddLiquidityBtn,
  BalanceStyled,
  BorderStyled,
  BottomContainer,
  ButtonWrapper,
  ClearAllBtn,
  ContentWrap,
  CreatePoolWrap,
  CurrentPoolPriceWrap,
  CurrentPriceTitle,
  EmptyGraphTitle,
  ExternalFeeCircle,
  FeeTier,
  FeeTierDescription,
  FeeTierHeader,
  FeeTierSubtitle,
  FeeTierTitle,
  GraphWrap,
  HeadingContainer,
  InputWrap,
  InternalFeeCircle,
  MaxStyled,
  NotCreatedTitle,
  NotCreatedWrap,
  PriceCard,
  PriceIcon,
  PriceIconWrap,
  PricePlusMinusContainer,
  PriceStyled,
  PriceTitle,
  PriceWrapContainer,
  RightSideContainer,
  RowContainer,
  SelectorItem,
  SelectorTitle,
  SelectTokensContainer,
  ShowMoreBtn,
  StyledFullRangeBtn,
  StyledHeaderBtnContainer,
  StyledIconButton,
  StyledInput,
  StyledInputBase,
  StyledInputHeader,
  StyledPaper,
  StyledToggleButton,
  StyledTokenName,
  StyledTypography,
  SubtitleWrap,
  TitleStyled,
  TokensBalanceWrap,
  OutOfRangeInput,
  OutOfRangeText,
  CurrentPriceStyled,
  TipContainer,
  TipText,
} from './styles';

import { StyledToggleButtonGroup } from './StyledComponents';
import TokenSelector from 'components/TokenSelector';
import { dexSelector, signerSelector } from 'state/dex/selectors';
import {
  firstLiquidityTokenSelector,
  secondLiquidityTokenSelector,
} from 'state/liquidity/selectors';
import { BorderRadius, Color } from 'helpers/themeStyles';
import LiquidityChart from 'components/LiquidityChart';
import AddLiquidityPopup from 'components/AddLiquidityPopup';
import { ExpandLess, ExpandMore } from 'shared/ExpandMoreAndLess';
import SwapSettings from 'components/SwapSettings';
import {
  setFirstLiquidityToken,
  setIsClearAll,
  setSecondLiquidityToken,
} from 'state/liquidity/slice';
import useDebounce from '@hooks/useDebounce';
import { setTransactionHash } from 'state/transactions/slice';
import EfficiencyComparisonModal from 'components/EfficiencyComparisonModal';
import computeSurroundingTicks from 'helpers/computeSurroundingTicks';
import { TickProcessed } from 'types/tickProcessed';
import { ChartEntry } from 'types/chartEntry';
import WarningIcon from 'svg/WarningIcon';
import LockIcon from 'svg/LockIcon';
import DefaultTokenIcon from 'svg/DefaultTokenIcon';
import tryParseCurrencyAmount from 'helpers/tryParseCurrencyAmount';
import { findNearestTeack } from 'helpers/findNearestTick';
import { RANGE_OF_TICK_SPACING, MIN_TICK, MAX_TICK } from 'helpers/constants';
import AlertIcon from 'svg/AlertIcon';
import formatTokenBalance from 'helpers/formatTokenBalance';

type Props = {
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
};

interface Ticks {
  tick: string;
  liquidityNet: string;
  price0: string;
  price1: string;
}

const AddLiquidity = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [firstTokenState, setFirstToken] = useState('');
  const [secondTokenState, setSecondToken] = useState('');
  const [isEfficiencyComparisonModalOpen, setIsEfficiencyComparisonModalOpen] =
    useState(false);
  const [newPool, setNewPool] = useState('');
  const [showFeeTier, setShowFeeTier] = useState(false);
  const [alignment, setAlignment] = useState('0');
  const [tokenAllowance, setTokenAllowance] = useState<boolean>();
  const [secondTokenAllowance, setSecondTokenAllowance] = useState<boolean>();
  const dex = useSelector(dexSelector);
  const firstToken = useSelector(firstLiquidityTokenSelector);
  const secondToken = useSelector(secondLiquidityTokenSelector);
  const [clearAllSettings, setClearAllSettings] = useState(false);
  const { address, chain } = useAccount();
  const [data, setData] = useState<Ticks[]>([]);
  const [pool, setPool] = useState<Pool>();
  const [notCreated, setNotCreated] = useState(false);
  const feeAmount: FeeAmount = Number(alignment) * 10000;
  const [minPrice, setMinPrice] = useState('0');
  const [maxPrice, setMaxPrice] = useState('0');
  const [tickProcessed, setTickProcessed] = useState<TickProcessed[]>([]);
  const [tickLowerMin, _setTickLowerMin] = useState<number>();
  const [tickLowerMax, _setTickLowerMax] = useState<number>();
  const [isFullRange, setIsFullRange] = useState(false);
  const [invalidRangeSelected, setInvalidRangeSelected] =
    useState<boolean>(false);
  const [deposit0Disabled, setDeposit0Disabled] = useState(false);
  const [deposit1Disabled, setDeposit1Disabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const debouncedFirstValue = useDebounce(firstTokenState, 500);
  const debouncedSecondValue = useDebounce(secondTokenState, 500);
  const [isFirstInputBlur, setIsFirstInputBlur] = useState(true);
  const is1400 = useMediaQuery(theme.breakpoints.between(480, 1400));
  const signer = useSelector(signerSelector);
  const dispatch = useDispatch();
  const { data: firstTokenBalance } = useBalance({
    address,
    chainId: firstToken?.chainId,
    token: !firstToken?.isNative
      ? (firstToken?.address as `0x${string}`)
      : undefined,
  });
  const { data: secondTokenBalance } = useBalance({
    address,
    chainId: secondToken?.chainId,
    token: !secondToken?.isNative
      ? (secondToken?.address as `0x${string}`)
      : undefined,
  });

  const [token0, token1] = useMemo(
    () =>
      firstToken &&
      firstToken.symbol !== '!' &&
      secondToken &&
      secondToken.symbol !== '!' &&
      firstToken.wrapped.address !== secondToken.wrapped.address
        ? firstToken.wrapped.into().sortsBefore(secondToken.wrapped.into())
          ? [firstToken.wrapped.into(), secondToken.wrapped.into()]
          : [secondToken.wrapped.into(), firstToken.wrapped.into()]
        : [undefined, undefined],
    [firstToken, secondToken]
  );
  let isSorted = false;
  useEffect(() => {
    if (
      firstToken &&
      firstToken.symbol !== '!' &&
      secondToken &&
      secondToken.symbol !== '!'
    ) {
      isSorted =
        firstToken?.wrapped &&
        secondToken?.wrapped &&
        firstToken.wrapped.address !== secondToken.wrapped.address &&
        firstToken?.wrapped.sortsBefore(secondToken?.wrapped);
    }
  }, [firstToken, secondToken]);

  const price = useMemo(() => {
    const invertPrice = Boolean(
      token0 && token0.wrapped.address !== firstToken?.wrapped.address
    );
    if (notCreated) {
      const parsedQuoteAmount = tryParseCurrencyAmount(
        newPool,
        invertPrice ? token0?.wrapped : token1?.wrapped
      );
      if (parsedQuoteAmount && token0 && token1) {
        const baseAmount = tryParseCurrencyAmount(
          '1',
          invertPrice ? token1.wrapped : token0.wrapped
        );
        const price =
          baseAmount && parsedQuoteAmount
            ? new Price(
                baseAmount.currency,
                parsedQuoteAmount.currency,
                baseAmount.quotient,
                parsedQuoteAmount.quotient
              )
            : undefined;
        return (invertPrice ? price?.invert() : price) ?? undefined;
      }
    } else if (
      pool &&
      (token0?.equals(pool?.token0!) || token0?.equals(pool?.token1!))
    ) {
      return pool && token0 ? pool.priceOf(token0) : undefined;
    } else {
      return;
    }
  }, [token0, token1, newPool, pool]);
  const mockPool = useMemo(() => {
    if (firstToken && secondToken && feeAmount && newPool && price) {
      const currentTick = priceToClosestTick(price);
      const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
      return new Pool(
        firstToken.into().wrapped,
        secondToken.into().wrapped,
        feeAmount,
        currentSqrt,
        JSBI.BigInt(0),
        currentTick,
        []
      );
    } else {
      return undefined;
    }
  }, [feeAmount, price, firstToken, secondToken, newPool]);
  const TICK_SPACINGS: { [amount in FeeAmount]: number } = {
    [FeeAmount.LOWEST]: 1,
    [FeeAmount.LOW]: 10,
    [FeeAmount.MEDIUM]: 60,
    [FeeAmount.HIGH]: 200,
  };
  useMemo(() => {
    if (!!minPrice && !!maxPrice) {
      if (Number(minPrice) >= Number(maxPrice)) {
        setInvalidRangeSelected(true);
      } else {
        setInvalidRangeSelected(false);
      }
      if (minPrice === '0' && maxPrice === '0' && !isFullRange) {
        setInvalidRangeSelected(false);
      }
    }
  }, [maxPrice, minPrice, tickLowerMin, tickLowerMax]);

  function poolActiveLiquidity() {
    if (data.length <= 0 || !pool) {
      return;
    }
    isSorted =
      firstToken?.wrapped &&
      secondToken?.wrapped &&
      firstToken.wrapped.address !== secondToken.wrapped.address &&
      firstToken?.wrapped.sortsBefore(secondToken?.wrapped);
    const activeTick =
      pool?.tickCurrent && feeAmount
        ? Math.floor(pool?.tickCurrent / TICK_SPACINGS[feeAmount]) *
          TICK_SPACINGS[feeAmount]
        : undefined;
    const pivot = data.findIndex(({ tick }) => Number(tick) > activeTick!) - 1;
    if (
      activeTick &&
      activeTick >= MIN_TICK.MIN_TICK &&
      activeTick <= MAX_TICK.MAX_TICK
    ) {
      const activeTickProcessed: TickProcessed = {
        liquidityActive: JSBI.BigInt(pool?.liquidity ?? 0),
        tick: activeTick,
        liquidityNet:
          pivot <= 0
            ? JSBI.BigInt(0)
            : Number(data[pivot].tick) === activeTick
            ? JSBI.BigInt(data[pivot].liquidityNet)
            : JSBI.BigInt(0),
        price0: tickToPrice(
          isSorted ? token0! : token1!,
          isSorted ? token1! : token0!,
          activeTick!
        ).toFixed(8),
      };
      const subsequentTicks = computeSurroundingTicks(
        isSorted ? token0! : token1!,
        isSorted ? token1! : token0!,
        activeTickProcessed,
        data,
        pivot,
        true
      );

      const previousTicks = computeSurroundingTicks(
        isSorted ? token0! : token1!,
        isSorted ? token1! : token0!,
        activeTickProcessed,
        data,
        pivot,
        false
      );

      const ticksProcessed = previousTicks
        .concat(activeTickProcessed)
        .concat(subsequentTicks);
      setTickProcessed(ticksProcessed);
    }
  }
  const formatData = useCallback(() => {
    if (!tickProcessed?.length) {
      return undefined;
    }

    const newData: ChartEntry[] = [];

    for (let i = 0; i < tickProcessed?.length; i++) {
      const t: TickProcessed = tickProcessed[i];

      const chartEntry = {
        activeLiquidity: parseFloat(t.liquidityActive.toString()),
        price0: parseFloat(t.price0),
        tick: t.tick,
      };

      if (chartEntry.activeLiquidity > 0) {
        newData.push(chartEntry);
      }
    }
    return newData;
  }, [tickProcessed]);
  const setTickLowerMin = (tick: number, space: number) => {
    _setTickLowerMin(
      nearestUsableTick(
        tick <= TickMath.MIN_TICK
          ? TickMath.MIN_TICK
          : tick >= TickMath.MAX_TICK
          ? TickMath.MAX_TICK
          : tick,
        space
      )
    );
  };
  const setTickLowerMax = (tick: number, space: number) => {
    _setTickLowerMax(
      nearestUsableTick(
        tick <= TickMath.MIN_TICK
          ? TickMath.MIN_TICK
          : tick >= TickMath.MAX_TICK
          ? TickMath.MAX_TICK
          : tick,
        space
      )
    );
  };

  useEffect(() => {
    if (pool && price) {
      const [token0, token1] =
        firstToken?.wrapped.symbol === pool.token0.wrapped.symbol
          ? [pool.token1, pool.token0]
          : [pool.token0, pool.token1];
      const priceToTick = firstToken.wrapped.sortsBefore(secondToken.wrapped)
        ? priceToClosestTick(pool.token1Price)
        : priceToClosestTick(pool.token0Price);
      if (firstToken.wrapped.sortsBefore(secondToken.wrapped)) {
        setTickLowerMin(
          priceToTick -
            pool.tickSpacing * RANGE_OF_TICK_SPACING +
            pool.tickSpacing,
          pool.tickSpacing
        );
        setMinPrice(
          tickToPrice(
            token1,
            token0,
            findNearestTeack(
              priceToTick -
                pool.tickSpacing * RANGE_OF_TICK_SPACING +
                pool.tickSpacing
            )
          ).toSignificant(5, undefined, 2)
        );
        setTickLowerMax(
          priceToTick +
            pool.tickSpacing * RANGE_OF_TICK_SPACING -
            pool.tickSpacing,
          pool.tickSpacing
        );
        setMaxPrice(
          tickToPrice(
            token1,
            token0,
            findNearestTeack(
              priceToTick +
                pool.tickSpacing * RANGE_OF_TICK_SPACING -
                pool.tickSpacing
            )
          ).toSignificant(5, undefined, 2)
        );
      } else {
        setTickLowerMin(
          priceToTick +
            pool.tickSpacing * RANGE_OF_TICK_SPACING -
            pool.tickSpacing,
          pool.tickSpacing
        );
        setMinPrice(
          tickToPrice(
            token1,
            token0,
            findNearestTeack(
              priceToTick +
                pool.tickSpacing * RANGE_OF_TICK_SPACING -
                pool.tickSpacing
            )
          ).toSignificant(5, undefined, 2)
        );
        setTickLowerMax(
          priceToTick -
            pool.tickSpacing * RANGE_OF_TICK_SPACING +
            pool.tickSpacing,
          pool.tickSpacing
        );
        setMaxPrice(
          tickToPrice(
            token1,
            token0,
            findNearestTeack(
              priceToTick -
                pool.tickSpacing * RANGE_OF_TICK_SPACING +
                pool.tickSpacing
            )
          ).toSignificant(5, undefined, 2)
        );
      }
      if (pool && data.length && token0 && token1) {
        poolActiveLiquidity();
      }
    }
  }, [pool, data, mockPool]);
  async function getPoolFromContract() {
    const provider = await dex?.getDEXProvider(
      chain?.id!,
      firstToken,
      secondToken
    );
    if (firstToken && secondToken && feeAmount) {
      try {
        if (provider instanceof UniswapProvider) {
          const pool = await provider.getPoolFromContract(
            firstToken.wrapped.address,
            secondToken.wrapped.address,
            feeAmount
          );

          setPool(pool);
          setNotCreated(false);
        }
      } catch {
        setNotCreated(true);
        setPool(mockPool);
      }
    }
  }
  const depositAmountsDisabled = useMemo(() => {
    if (!isFullRange) {
      if (!alignment || !firstToken || !secondToken) {
        return true;
      } else if (minPrice && maxPrice && Number(minPrice) >= Number(maxPrice)) {
        return true;
      }
    }
  }, [minPrice, maxPrice, alignment, tickLowerMin, tickLowerMax]);
  const depositAmountsDisabledStyles = useMemo(() => {
    if (!isFullRange) {
      if (!alignment || !firstToken || !secondToken) {
        return true;
      } else if (minPrice && maxPrice && Number(minPrice) >= Number(maxPrice)) {
        return true;
      }
    }
  }, [minPrice, maxPrice, alignment, tickLowerMin, tickLowerMax]);
  async function fetchGraphQL(
    url: string,
    text: string,
    variables?: any
  ): Promise<any> {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: text,
        variables: variables,
      }),
    });

    // Get the response as JSON
    const json = await response.json();
    setData(json.data.ticks);
    return json['data'];
  }
  useMemo(() => {
    if (firstToken.address === secondToken.address) {
      return;
    }
    setPool(undefined);
    setData([]);
    setTickProcessed([]);
    const poolAddress =
      firstToken && secondToken && feeAmount
        ? Pool.getAddress(
            firstToken.wrapped.into(),
            secondToken.wrapped.into(),
            feeAmount
          ).toLowerCase()
        : undefined;
    if (poolAddress) {
      fetchGraphQL(
        'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
        ` query AllV3TicksQuery($poolAddress:String!) {
          ticks(
            first: 1000
            skip: $skip
            where: { poolAddress: $poolAddress}
            orderBy: tickIdx
          ) {
            tick: tickIdx
            liquidityNet
            price0
            price1
          }
        }`,
        { poolAddress: poolAddress.toLowerCase() }
      );
    }

    getPoolFromContract();
  }, [firstToken, secondToken, feeAmount, newPool]);

  // clear inputs after token change
  useEffect(() => {
    setFirstToken('');
  }, [firstToken]);

  useEffect(() => {
    setSecondToken('');
  }, [secondToken]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setPool(undefined);

    setData([]);
    setTickProcessed([]);
    setAlignment(newAlignment);
    setIsFullRange(false);
  };

  async function getMaxTokenValue(
    token: DEXToken,
    firstTokenFlag: boolean = false
  ) {
    if (!dex) return;
    const maxValue = await dex.maxFor(token);
    if (!maxValue[0].isZero() && !maxValue[1].isZero()) {
      firstTokenFlag
        ? setFirstToken(
            utils.formatUnits(`${maxValue[0]}`, token.decimals).slice(0, 10)
          )
        : setSecondToken(
            utils.formatUnits(`${maxValue[0]}`, token.decimals).slice(0, 10)
          );
      firstTokenFlag
        ? estimate(utils.formatUnits(`${maxValue[0]}`, token.decimals), true)
        : estimate(utils.formatUnits(`${maxValue[0]}`, token.decimals), false);
    } else {
      firstTokenFlag ? setFirstToken('0') : setSecondToken('0');
    }
    return maxValue;
  }

  async function getUSDBalance(
    token: DEXToken,
    firstTokenFlag: boolean = false
  ) {
    if (firstTokenFlag) {
      token.amount = utils.parseUnits(firstTokenState, token.decimals);
    } else {
      token.amount = utils.parseUnits(secondTokenState, token.decimals);
    }
  }
  async function checkTokenForAllowance() {
    if (!dex) return;
    if (Number(debouncedFirstValue) > 0) {
      const value = utils.parseUnits(debouncedFirstValue, firstToken.decimals);
      const routerAddress = await dex.getDEXProvider(chain?.id!);
      if (routerAddress instanceof UniswapProvider) {
        const allowance = await firstToken!.wrapped.allowance(
          signer!,
          address!,
          routerAddress.nonfungiblePositionManagerAddress!
        );
        setTokenAllowance(allowance.sub(value).isNegative());
      }
    }
    if (Number(debouncedSecondValue) > 0) {
      const value = utils.parseUnits(
        debouncedSecondValue,
        secondToken.decimals
      );
      const routerAddress = await dex.getDEXProvider(chain?.id!);
      if (routerAddress instanceof UniswapProvider) {
        const allowance = await secondToken!.wrapped.allowance(
          signer!,
          address!,
          routerAddress.nonfungiblePositionManagerAddress!
        );
        setSecondTokenAllowance(allowance.sub(value).isNegative());
      }
    }
  }
  async function tokenApprove() {
    if (!dex) return;
    const routerAddress = await dex.getDEXProvider(chain?.id!);
    if (routerAddress instanceof UniswapProvider) {
      if (tokenAllowance) {
        const tx = await firstToken?.wrapped.approve(
          signer!,
          routerAddress.nonfungiblePositionManagerAddress!
        );
        dispatch(
          setTransactionHash({
            hash: tx?.hash,
            chainId: firstToken?.chainId,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'approve',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: firstToken?.icon,
                },
              },
            },
          })
        );
        setTokenAllowance(false);
      } else if (secondTokenAllowance) {
        const tx = await secondToken?.wrapped.approve(
          signer!,
          routerAddress.nonfungiblePositionManagerAddress!
        );
        dispatch(
          setTransactionHash({
            hash: tx?.hash,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'approve',
            data: {
              token_data: {
                token1: {
                  ...token1,
                  logoURI: secondToken?.icon,
                },
              },
            },
          })
        );
        setSecondTokenAllowance(false);
      }
    }
  }
  useEffect(() => {
    if (firstToken && firstTokenState) {
      getUSDBalance(firstToken, true);
    }
  }, [firstTokenState]);

  useEffect(() => {
    if (secondToken && secondTokenState) {
      getUSDBalance(secondToken);
    }
  }, [secondTokenState]);

  useEffect(() => {
    checkTokenForAllowance();
  }, [
    debouncedFirstValue,
    firstToken?.address,
    debouncedSecondValue,
    secondToken?.address,
  ]);
  const clearAll = () => {
    // Reset the liquidity tokens
    // BUT IF TOKENS IT DEXTOKEN ITS MUST TO BE A DEXTOKEN EVEN IF ITS EMPTY
    dispatch(setFirstLiquidityToken(DEXToken.default()));
    dispatch(setSecondLiquidityToken(DEXToken.default()));
    setAlignment('');
    setShowFeeTier(false);
    dispatch(setIsClearAll(true));
    setTokenAllowance(false);
    setSecondTokenAllowance(false);
    setClearAllSettings(true);
    setMaxPrice('0');
    setMinPrice('0');
    setPool(undefined);
    setData([]);
    setTickProcessed([]);
  };
  const chooseAnotherToken = () => {
    setAlignment('');
    setShowFeeTier(false);
    setTokenAllowance(false);
    setSecondTokenAllowance(false);
    setClearAllSettings(true);
    setMaxPrice('0');
    setMinPrice('0');
    setPool(undefined);

    setData([]);
    setTickProcessed([]);
  };

  useEffect(() => {
    chooseAnotherToken();
  }, [firstToken, secondToken]);

  const changeLowerMin = (plus: boolean) => {
    _setTickLowerMin((prevState) =>
      plus ? prevState! + pool?.tickSpacing! : prevState! - pool?.tickSpacing!
    );
  };
  useEffect(() => {
    if (Number.isInteger(tickLowerMin)) {
      try {
        setMinPrice(
          setTickToPrice(nearestUsableTick(tickLowerMin!, pool?.tickSpacing!))
        );
      } catch {}
    }
  }, [tickLowerMin]);
  const changeLowerMax = (plus: boolean) => {
    _setTickLowerMax((prevState) =>
      plus ? prevState! + pool?.tickSpacing! : prevState! - pool?.tickSpacing!
    );
  };
  useEffect(() => {
    if (Number.isInteger(tickLowerMax)) {
      try {
        setMaxPrice(
          setTickToPrice(nearestUsableTick(tickLowerMax!, pool?.tickSpacing!))
        );
      } catch {}
    }
  }, [tickLowerMax]);
  const setTickToPrice = (tick: number) => {
    const newPrice = tickToPrice(
      firstToken!.wrapped.into(),
      secondToken!.wrapped.into(),
      tick
    );
    return newPrice.toSignificant(5, undefined, 2);
  };

  const estimate = (result: string, firstFlag: boolean) => {
    const tickLower = nearestUsableTick(tickLowerMin!, pool?.tickSpacing!);
    const tickUpper = nearestUsableTick(tickLowerMax!, pool?.tickSpacing!);
    isSorted =
      firstToken?.wrapped &&
      secondToken?.wrapped &&
      firstToken.wrapped.address !== secondToken.wrapped.address &&
      firstToken?.wrapped.sortsBefore(secondToken?.wrapped);
    if (firstFlag && !isSorted && Number(result) > 0) {
      if (result.trim().length <= 0) {
        return setSecondToken('');
      }
      const { amount0 } = Position.fromAmount1({
        pool: pool!,
        tickLower: tickLowerMin! < tickLowerMax! ? tickLower! : tickUpper!,
        tickUpper: tickLowerMax! > tickLowerMin! ? tickUpper! : tickLower!,
        amount1: utils.parseUnits(result, pool?.token1.decimals).toHexString(),
      });
      setSecondToken(amount0.toSignificant());
    } else if (firstFlag && isSorted && Number(result) > 0) {
      if (result.trim().length <= 0) {
        return setSecondToken('');
      }
      const { amount1 } = Position.fromAmount0({
        pool: pool!,
        tickLower: tickLowerMin! < tickLowerMax! ? tickLower! : tickUpper!,
        tickUpper: tickLowerMax! > tickLowerMin! ? tickUpper! : tickLower!,
        amount0: utils.parseUnits(result, pool?.token0.decimals).toHexString(),
        useFullPrecision: true,
      });
      setSecondToken(amount1.toSignificant());
    }
    if (!firstFlag && !isSorted && Number(result) > 0) {
      if (result.trim().length <= 0) {
        return setFirstToken('');
      }
      const { amount1 } = Position.fromAmount0({
        pool: pool!,
        tickLower: tickLowerMin! < tickLowerMax! ? tickLower! : tickUpper!,
        tickUpper: tickLowerMax! > tickLowerMin! ? tickUpper! : tickLower!,
        amount0: utils.parseUnits(result, pool?.token0.decimals).toHexString(),
        useFullPrecision: true,
      });
      setFirstToken(amount1.toSignificant());
    } else if (!firstFlag && isSorted && Number(result) > 0) {
      if (result.trim().length <= 0) {
        return setFirstToken('');
      }
      const { amount0 } = Position.fromAmount1({
        pool: pool!,
        tickLower: tickLowerMin! < tickLowerMax! ? tickLower! : tickUpper!,
        tickUpper: tickLowerMax! > tickLowerMin! ? tickUpper! : tickLower!,
        amount1: utils.parseUnits(result, pool?.token1.decimals).toHexString(),
      });
      setFirstToken(amount0.toSignificant());
    }
  };
  useEffect(() => {
    if (
      tickLowerMin &&
      tickLowerMax &&
      pool &&
      !depositAmountsDisabled &&
      !deposit0Disabled &&
      !deposit1Disabled &&
      !depositAmountsDisabledStyles
    ) {
      if (
        isFirstInputBlur &&
        !depositAmountsDisabled &&
        !deposit0Disabled &&
        !deposit1Disabled &&
        !depositAmountsDisabledStyles
      ) {
        setTimeout(() => {
          estimate(firstTokenState, true);
        }, 100);
      }
      if (
        !isFirstInputBlur &&
        !depositAmountsDisabled &&
        !deposit0Disabled &&
        !deposit1Disabled &&
        !depositAmountsDisabledStyles
      ) {
        setTimeout(() => {
          estimate(secondTokenState, false);
        }, 100);
      }
    }
  }, [
    tickLowerMin,
    tickLowerMax,
    depositAmountsDisabled,
    deposit0Disabled,
    deposit1Disabled,
    depositAmountsDisabledStyles,
  ]);
  useEffect(() => {
    isSorted =
      firstToken?.wrapped &&
      secondToken?.wrapped &&
      firstToken.wrapped.address !== secondToken.wrapped.address &&
      firstToken?.wrapped.sortsBefore(secondToken?.wrapped);
    const deposit0Disabled = isSorted
      ? Boolean(
          typeof tickLowerMax === 'number' &&
            pool &&
            pool.tickCurrent >= tickLowerMax
        )
      : Boolean(
          typeof tickLowerMax === 'number' &&
            pool &&
            pool.tickCurrent <= tickLowerMax
        );

    const deposit1Disabled = isSorted
      ? Boolean(
          typeof tickLowerMin === 'number' &&
            pool &&
            pool.tickCurrent <= tickLowerMin
        )
      : Boolean(
          typeof tickLowerMin === 'number' &&
            pool &&
            pool.tickCurrent >= tickLowerMin
        );

    setDeposit0Disabled(deposit0Disabled);
    setDeposit1Disabled(deposit1Disabled);
  }, [tickLowerMax, tickLowerMin, pool]);
  const outOfRange = deposit0Disabled || deposit1Disabled;
  useEffect(() => {
    if (deposit0Disabled) {
      setFirstToken('0');
    }
    if (deposit1Disabled) {
      setSecondToken('0');
    }
  }, [deposit0Disabled, deposit1Disabled]);

  return (
    <>
      <StyledPaper elevation={3}>
        <HeadingContainer>
          <SubtitleWrap>
            <StyledIconButton onClick={props.onBack}>
              <ArrowBackIcon />
            </StyledIconButton>
            <StyledTypography>{t('addLiquidity')}</StyledTypography>
            <SwapSettings
              fromLiquidity={true}
              clearAllSettings={clearAllSettings}
              setClearAllSettings={setClearAllSettings}
            />
          </SubtitleWrap>
          <StyledHeaderBtnContainer>
            <ClearAllBtn
              onClick={() => {
                clearAll();
              }}
            >
              {t('clearAll')}
            </ClearAllBtn>
          </StyledHeaderBtnContainer>
        </HeadingContainer>
        <RowContainer style={{ alignItems: 'stretch' }}>
          <SelectorItem>
            <SelectorTitle>{t('selectPair')}</SelectorTitle>
            <SelectTokensContainer>
              <TokenSelector
                width='48%'
                firstToken
                fromLiquidity
                height='56px'
                border='1px solid rgba(176, 50, 214, 0.4)'
                fromV3
              />
              <TokenSelector
                width='48%'
                fromLiquidity
                height='56px'
                border='1px solid rgba(176, 50, 214, 0.4)'
                fromV3
              />
            </SelectTokensContainer>
            <BorderStyled
              style={{
                position: 'relative',
                zIndex:
                  firstToken?.wrapped?.address ===
                    secondToken?.wrapped?.address ||
                  firstToken?.symbol === '!' ||
                  secondToken.symbol === '!'
                    ? -1
                    : 1,
              }}
            >
              <Grid
                container
                justifyContent='space-between'
                marginBottom='16px'
              >
                <div>
                  <FeeTierTitle>{t('feeTier')}</FeeTierTitle>
                  <FeeTierSubtitle>
                    {t('the')} {alignment}% {t('earnInFees')}
                  </FeeTierSubtitle>
                </div>
                <ShowMoreBtn
                  sx={{
                    fontSize: '16px',
                    width: '80px',
                    height: '40px',
                  }}
                  onClick={() => setShowFeeTier(!showFeeTier)}
                  variant='outlined'
                >
                  {showFeeTier ? t('hide') : t('edit')}
                  {showFeeTier ? <ExpandLess /> : <ExpandMore />}
                </ShowMoreBtn>
              </Grid>

              {showFeeTier && (
                <StyledToggleButtonGroup
                  size='small'
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label='text alignment'
                  style={{
                    columnGap: !isMobile ? '16px' : 0,
                    flexWrap: isMobile ? 'wrap' : 'inherit',
                    rowGap: isMobile ? '16px' : 0,
                  }}
                >
                  <StyledToggleButton
                    value='0.05'
                    aria-label='centered'
                    onClick={() => {
                      setPool(undefined);

                      setData([]);
                      setTickProcessed([]);
                    }}
                  >
                    <FeeTierHeader>
                      <FeeTier>0.05%</FeeTier>
                      {alignment === '0.05' && (
                        <ExternalFeeCircle>
                          <InternalFeeCircle />
                        </ExternalFeeCircle>
                      )}
                    </FeeTierHeader>
                    <FeeTierDescription>{t('stablePairs')}</FeeTierDescription>
                  </StyledToggleButton>
                  <StyledToggleButton
                    value='0.3'
                    aria-label='right aligned'
                    onClick={() => {
                      setPool(undefined);

                      setData([]);
                      setTickProcessed([]);
                    }}
                  >
                    <FeeTierHeader>
                      <FeeTier>0.3%</FeeTier>
                      {alignment === '0.3' && (
                        <ExternalFeeCircle>
                          <InternalFeeCircle />
                        </ExternalFeeCircle>
                      )}
                    </FeeTierHeader>
                    <FeeTierDescription>{t('mostPairs')}</FeeTierDescription>
                  </StyledToggleButton>
                  <StyledToggleButton
                    value='1'
                    aria-label='justified'
                    onClick={() => {
                      setPool(undefined);

                      setTickProcessed([]);
                      setData([]);
                    }}
                  >
                    <FeeTierHeader>
                      <FeeTier>1%</FeeTier>
                      {alignment === '1' && (
                        <ExternalFeeCircle>
                          <InternalFeeCircle />
                        </ExternalFeeCircle>
                      )}
                    </FeeTierHeader>
                    <FeeTierDescription>{t('exoticPairs')}</FeeTierDescription>
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              )}
            </BorderStyled>
            {is1400 && (
              <RightSideContainer
                style={{
                  position: 'relative',
                  zIndex: firstToken && secondToken && alignment ? 1 : -1,
                }}
              >
                {!!notCreated && (
                  <>
                    <SelectorTitle>{t('setStartingPrice')}</SelectorTitle>
                    <NotCreatedWrap>
                      <div>
                        <WarningIcon />
                      </div>
                      <NotCreatedTitle>
                        {t('poolMustBeInitialized')}
                      </NotCreatedTitle>
                    </NotCreatedWrap>

                    <CreatePoolWrap>
                      <InputWrap>
                        <StyledInputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            color: Color.WHITE,
                            '& ::placeholder': {
                              color: `${Color.WHITE}!important`,
                              opacity: 1,
                            },
                          }}
                          placeholder='0.0'
                          inputProps={{
                            'aria-label': 'token',
                          }}
                          value={newPool}
                          onChange={(event) => {
                            const result = event.target.value.replace(
                              /[^\d\.]/g,
                              ''
                            );
                            result?.match(/\./g)?.length! > 1
                              ? setNewPool(
                                  result.slice(0, result.lastIndexOf('.'))
                                )
                              : setNewPool(result);
                          }}
                        />
                      </InputWrap>
                      <CurrentPoolPriceWrap>
                        <CurrentPriceTitle>
                          {t('current')} {firstToken?.symbol} {t('price')}
                        </CurrentPriceTitle>
                        <CurrentPriceTitle>
                          {newPool ? `${newPool} ${secondToken?.symbol}` : '-'}
                        </CurrentPriceTitle>
                      </CurrentPoolPriceWrap>
                    </CreatePoolWrap>
                  </>
                )}
                <SelectorTitle>{t('setPriceRange')}</SelectorTitle>
                <div
                  style={{
                    backgroundColor: Color.BACKGROUND,
                    border: '1px solid rgba(176, 50, 214, 0.2)',
                    borderRadius: BorderRadius.NORMAL,
                    padding: '16px 16px 16px',
                  }}
                >
                  {!notCreated && (
                    <>
                      {!data.length ||
                      !formatData()?.length ||
                      formatData()?.length! < 20 ? (
                        <GraphWrap style={{ justifyContent: 'flex-start' }}>
                          {pool && firstToken?.symbol && secondToken?.symbol && (
                            <PriceTitle
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '16px',
                                marginBottom: '50px',
                              }}
                            >
                              {t('currentPrice')}:{' '}
                              {firstToken?.wrapped.symbol ===
                              pool?.token0.symbol ? (
                                <>
                                  {pool?.token0Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  ).length! > 30 ? (
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    >
                                      <CurrentPriceStyled>
                                        {pool?.token0Price.toSignificant(
                                          5,
                                          undefined,
                                          2
                                        )}
                                      </CurrentPriceStyled>
                                    </Tooltip>
                                  ) : (
                                    <CurrentPriceStyled>
                                      {pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}{' '}
                                    </CurrentPriceStyled>
                                  )}

                                  <span
                                    style={{
                                      color: Color.WHITE,
                                    }}
                                  >
                                    {secondToken?.symbol} {t('per')}{' '}
                                    {firstToken?.symbol}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {pool?.token1Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  ).length! > 30 ? (
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    >
                                      <CurrentPriceStyled>
                                        {pool?.token1Price.toSignificant(
                                          5,
                                          undefined,
                                          2
                                        )}
                                      </CurrentPriceStyled>
                                    </Tooltip>
                                  ) : (
                                    <CurrentPriceStyled>
                                      {pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}{' '}
                                    </CurrentPriceStyled>
                                  )}

                                  <span
                                    style={{
                                      color: Color.WHITE,
                                    }}
                                  >
                                    {secondToken?.symbol} {t('per')}{' '}
                                    {firstToken?.symbol}
                                  </span>
                                </>
                              )}
                            </PriceTitle>
                          )}
                          <EmptyGraphTitle>
                            {t('yourPositionWillAppearHere')}
                          </EmptyGraphTitle>
                        </GraphWrap>
                      ) : (
                        <>
                          <GraphWrap>
                            <PriceTitle>
                              {t('currentPrice')}:{' '}
                              {firstToken?.wrapped.symbol ===
                              pool?.token0.symbol ? (
                                <span style={{ color: Color.WHITE }}>
                                  {pool?.token0Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  ).length! > 30 ? (
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    >
                                      <CurrentPriceStyled>
                                        {pool?.token0Price.toSignificant(
                                          5,
                                          undefined,
                                          2
                                        )}
                                      </CurrentPriceStyled>
                                    </Tooltip>
                                  ) : (
                                    <CurrentPriceStyled>
                                      {pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}{' '}
                                    </CurrentPriceStyled>
                                  )}
                                  {secondToken?.symbol} {t('per')}{' '}
                                  {firstToken?.symbol}
                                </span>
                              ) : (
                                <span style={{ color: Color.WHITE }}>
                                  {pool?.token1Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  ).length! > 30 ? (
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    >
                                      <CurrentPriceStyled>
                                        {pool?.token1Price.toSignificant(
                                          5,
                                          undefined,
                                          2
                                        )}
                                      </CurrentPriceStyled>
                                    </Tooltip>
                                  ) : (
                                    <CurrentPriceStyled>
                                      {pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}{' '}
                                    </CurrentPriceStyled>
                                  )}
                                  {secondToken?.symbol} {t('per')}{' '}
                                  {firstToken?.symbol}
                                </span>
                              )}
                            </PriceTitle>
                            <LiquidityChart
                              data={
                                tickProcessed &&
                                formatData()?.sort((a, b) =>
                                  b.price0 < a.price0 ? 1 : -1
                                )
                              }
                              minPrice={Number(minPrice)}
                              maxPrice={Number(maxPrice)}
                              setMaxPrice={setMaxPrice}
                              setMinPrice={setMinPrice}
                              setTickLowerMin={_setTickLowerMin}
                              setTickLowerMax={_setTickLowerMax}
                              isFullRange={isFullRange}
                              currentPrice={
                                firstToken?.wrapped.symbol ===
                                pool?.token0.symbol
                                  ? pool?.token0Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )
                                  : pool?.token1Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )
                              }
                            />
                          </GraphWrap>
                        </>
                      )}
                    </>
                  )}
                  <PriceWrapContainer>
                    <PriceCard>
                      <PriceTitle>{t('minPrice')}</PriceTitle>
                      <PricePlusMinusContainer>
                        <PriceIconWrap>
                          <PriceIcon
                            disabled={isFullRange}
                            onClick={() => {
                              firstToken?.wrapped.symbol === pool?.token0.symbol
                                ? changeLowerMin(false)
                                : changeLowerMin(true);
                            }}
                          >
                            -
                          </PriceIcon>
                        </PriceIconWrap>
                        <PriceStyled>
                          {isFullRange ? 0 : minPrice.slice(0, 7)}
                        </PriceStyled>
                        <PriceIconWrap>
                          <PriceIcon
                            disabled={isFullRange}
                            onClick={() => {
                              firstToken?.wrapped.symbol === pool?.token0.symbol
                                ? changeLowerMin(true)
                                : changeLowerMin(false);
                            }}
                          >
                            +
                          </PriceIcon>
                        </PriceIconWrap>
                      </PricePlusMinusContainer>
                      <PriceTitle>
                        {secondToken?.symbol} {t('per')} {firstToken?.symbol}
                      </PriceTitle>
                    </PriceCard>
                    <PriceCard>
                      <PriceTitle>{t('maxPrice')}</PriceTitle>
                      <PricePlusMinusContainer>
                        <PriceIconWrap>
                          <PriceIcon
                            disabled={isFullRange}
                            onClick={() => {
                              firstToken?.wrapped.symbol === pool?.token0.symbol
                                ? changeLowerMax(false)
                                : changeLowerMax(true);
                            }}
                          >
                            -
                          </PriceIcon>
                        </PriceIconWrap>
                        <PriceStyled>
                          {isFullRange ? '∞' : maxPrice.slice(0, 7)}
                        </PriceStyled>
                        <PriceIconWrap>
                          <PriceIcon
                            disabled={isFullRange}
                            onClick={() => {
                              firstToken?.wrapped.symbol === pool?.token0.symbol
                                ? changeLowerMax(true)
                                : changeLowerMax(false);
                            }}
                          >
                            +
                          </PriceIcon>
                        </PriceIconWrap>
                      </PricePlusMinusContainer>
                      <PriceTitle>
                        {secondToken?.symbol} {t('per')} {firstToken?.symbol}
                      </PriceTitle>
                    </PriceCard>
                  </PriceWrapContainer>
                  {!notCreated && (
                    <StyledFullRangeBtn
                      onClick={() => setIsEfficiencyComparisonModalOpen(true)}
                    >
                      {t('fullRange')}
                    </StyledFullRangeBtn>
                  )}
                  {!!invalidRangeSelected && !isFullRange && (
                    <ContentWrap>
                      <WarningIcon />
                      <TitleStyled>{t('invalidRangeSelected')}</TitleStyled>
                    </ContentWrap>
                  )}
                  {outOfRange && (
                    <ContentWrap>
                      <WarningIcon />
                      <TitleStyled>{t('outOfRangeInfo')}</TitleStyled>
                    </ContentWrap>
                  )}
                </div>
              </RightSideContainer>
            )}
            <SelectorTitle
              style={{
                position: 'relative',
                zIndex: depositAmountsDisabledStyles ? -1 : 1,
              }}
            >
              {t('depositAmounts')}
            </SelectorTitle>
            {!!deposit0Disabled ? (
              <OutOfRangeInput>
                <LockIcon />
                <OutOfRangeText>{t('singleAssetDeposit')}</OutOfRangeText>
              </OutOfRangeInput>
            ) : (
              <StyledInput>
                <StyledInputHeader>
                  <Grid
                    container
                    alignItems='center'
                    columnGap='6px'
                    width='40%'
                  >
                    {firstToken.symbol !== '!' ? (
                      <img
                        src={firstToken.icon}
                        width='24'
                        height='24'
                        alt='token-icon'
                      />
                    ) : (
                      <DefaultTokenIcon />
                    )}
                    <StyledTokenName
                      style={{
                        color: !depositAmountsDisabledStyles
                          ? Color.WHITE
                          : Color.WHITE_OPACITY,
                      }}
                    >
                      {firstToken?.symbol !== '!' && firstToken?.symbol}
                    </StyledTokenName>
                  </Grid>
                  <TokensBalanceWrap>
                    {!!firstToken && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                      >
                        <BalanceStyled
                          style={{
                            color: !depositAmountsDisabledStyles
                              ? Color.WHITE
                              : Color.WHITE_OPACITY,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span
                            style={{
                              color: !depositAmountsDisabledStyles
                                ? Color.WHITE
                                : Color.WHITE_OPACITY,
                            }}
                          >
                            {firstTokenBalance &&
                              formatTokenBalance(
                                firstTokenBalance.value,
                                firstToken.decimals
                              )}
                          </span>
                        </BalanceStyled>
                        <MaxStyled
                          disabled={depositAmountsDisabled}
                          onClick={() => getMaxTokenValue(firstToken, true)}
                          style={{
                            color: !depositAmountsDisabledStyles
                              ? Color.PURPLE
                              : Color.WHITE_OPACITY,
                          }}
                        >
                          {t('max')}
                        </MaxStyled>
                      </Stack>
                    )}
                  </TokensBalanceWrap>
                </StyledInputHeader>
                <InputWrap>
                  <StyledInputBase
                    placeholder='0'
                    inputProps={{
                      'aria-label': 'token',
                      maxLength: 26,
                    }}
                    value={firstTokenState}
                    onBlur={() => setIsFirstInputBlur(true)}
                    onChange={(event) => {
                      const result = event.target.value.replace(/[^\d\.]/g, '');
                      result?.match(/\./g)?.length! > 1
                        ? setFirstToken(
                            result.slice(0, result.lastIndexOf('.'))
                          )
                        : result === '00' ||
                          (result.length === 2 &&
                            result[0] === '0' &&
                            result[1] !== '.')
                        ? setFirstToken('0')
                        : result[0] === '.'
                        ? setFirstToken('')
                        : setFirstToken(result);
                      if (deposit1Disabled) {
                        setSecondToken('0');
                      } else {
                        estimate(result, true);
                      }
                    }}
                    disabled={depositAmountsDisabled}
                  />
                </InputWrap>
              </StyledInput>
            )}
            {!!deposit1Disabled ? (
              <OutOfRangeInput>
                <LockIcon />
                <OutOfRangeText>{t('singleAssetDeposit')}</OutOfRangeText>
              </OutOfRangeInput>
            ) : (
              <StyledInput>
                <StyledInputHeader>
                  <Grid
                    container
                    alignItems='center'
                    columnGap='6px'
                    width='40%'
                  >
                    {secondToken.symbol !== '!' ? (
                      <img
                        src={secondToken?.icon}
                        width='24'
                        height='24'
                        alt='token-icon'
                      />
                    ) : (
                      <DefaultTokenIcon />
                    )}
                    <StyledTokenName
                      style={{
                        color: !depositAmountsDisabledStyles
                          ? Color.WHITE
                          : Color.WHITE_OPACITY,
                      }}
                    >
                      {secondToken?.symbol !== '!' && secondToken?.symbol}
                    </StyledTokenName>
                  </Grid>
                  <TokensBalanceWrap>
                    {!!secondToken && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                      >
                        <BalanceStyled
                          style={{
                            color: !depositAmountsDisabledStyles
                              ? Color.WHITE
                              : Color.WHITE_OPACITY,
                          }}
                        >
                          {t('balance')}:{' '}
                          <span
                            style={{
                              color: !depositAmountsDisabledStyles
                                ? Color.WHITE
                                : Color.WHITE_OPACITY,
                            }}
                          >
                            {secondTokenBalance &&
                              formatTokenBalance(
                                secondTokenBalance.value,
                                secondToken.decimals
                              )}
                          </span>
                        </BalanceStyled>
                        <MaxStyled
                          disabled={depositAmountsDisabled}
                          onClick={() => getMaxTokenValue(secondToken)}
                          style={{
                            color: !depositAmountsDisabledStyles
                              ? Color.PURPLE
                              : Color.WHITE_OPACITY,
                          }}
                        >
                          {t('max')}
                        </MaxStyled>
                      </Stack>
                    )}
                  </TokensBalanceWrap>
                </StyledInputHeader>
                <InputWrap>
                  <StyledInputBase
                    placeholder='0'
                    inputProps={{
                      'aria-label': 'token',
                      maxLength: 26,
                    }}
                    value={secondTokenState}
                    onBlur={() => setIsFirstInputBlur(false)}
                    onChange={(event) => {
                      const result = event.target.value.replace(/[^\d\.]/g, '');
                      result?.match(/\./g)?.length! > 1
                        ? setSecondToken(
                            result.slice(0, result.lastIndexOf('.'))
                          )
                        : result === '00' ||
                          (result.length === 2 &&
                            result[0] === '0' &&
                            result[1] !== '.')
                        ? setSecondToken('0')
                        : result[0] === '.'
                        ? setSecondToken('')
                        : setSecondToken(result);
                      if (deposit0Disabled) {
                        setFirstToken('0');
                      } else {
                        estimate(result, false);
                      }
                    }}
                    disabled={depositAmountsDisabled}
                  />
                </InputWrap>
              </StyledInput>
            )}
            {!isMobile && (
              <TipContainer>
                <div>
                  <AlertIcon />
                </div>
                <div>
                  <TipText>
                    {t('toAddLiquidityYouMustHaveABalanceOfTokens')}
                  </TipText>
                </div>
              </TipContainer>
            )}
          </SelectorItem>
          {!is1400 && (
            <RightSideContainer
              style={{
                position: 'relative',
                zIndex: firstToken && secondToken && alignment ? 1 : -1,
              }}
            >
              {!!notCreated && (
                <>
                  <SelectorTitle>{t('setStartingPrice')}</SelectorTitle>
                  <NotCreatedWrap>
                    <div>
                      <WarningIcon />
                    </div>
                    <NotCreatedTitle>
                      {t('poolMustBeInitialized')}
                    </NotCreatedTitle>
                  </NotCreatedWrap>

                  <CreatePoolWrap>
                    <InputWrap>
                      <StyledInputBase
                        sx={{
                          ml: 1,
                          flex: 1,
                          color: Color.WHITE,
                          '& ::placeholder': {
                            color: `${Color.WHITE}!important`,
                            opacity: 1,
                          },
                        }}
                        placeholder='0.0'
                        inputProps={{
                          'aria-label': 'token',
                        }}
                        value={newPool}
                        onChange={(event) => {
                          const result = event.target.value.replace(
                            /[^\d\.]/g,
                            ''
                          );
                          result?.match(/\./g)?.length! > 1
                            ? setNewPool(
                                result.slice(0, result.lastIndexOf('.'))
                              )
                            : setNewPool(result);
                        }}
                      />
                    </InputWrap>
                    <CurrentPoolPriceWrap>
                      <CurrentPriceTitle>
                        {t('current')} {firstToken?.symbol} {t('price')}
                      </CurrentPriceTitle>
                      <CurrentPriceTitle>
                        {newPool ? `${newPool} ${secondToken?.symbol}` : '-'}
                      </CurrentPriceTitle>
                    </CurrentPoolPriceWrap>
                  </CreatePoolWrap>
                </>
              )}
              <SelectorTitle>{t('setPriceRange')}</SelectorTitle>
              <div
                style={{
                  backgroundColor: Color.BACKGROUND,
                  border: '1px solid rgba(176, 50, 214, 0.2)',
                  borderRadius: BorderRadius.NORMAL,
                  padding: '16px 16px 16px',
                }}
              >
                {!notCreated && (
                  <>
                    {!data.length ||
                    !formatData()?.length ||
                    formatData()?.length! < 20 ? (
                      <GraphWrap style={{ justifyContent: 'flex-start' }}>
                        {pool && firstToken?.symbol && secondToken?.symbol && (
                          <PriceTitle
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '16px',
                              marginBottom: '50px',
                            }}
                          >
                            {t('currentPrice')}:{' '}
                            {firstToken?.wrapped.symbol ===
                            pool?.token0.symbol ? (
                              <>
                                {pool?.token0Price.toSignificant(
                                  5,
                                  undefined,
                                  2
                                ).length! > 30 ? (
                                  <Tooltip
                                    enterTouchDelay={0}
                                    title={pool?.token0Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  >
                                    <CurrentPriceStyled>
                                      {pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    </CurrentPriceStyled>
                                  </Tooltip>
                                ) : (
                                  <CurrentPriceStyled>
                                    {pool?.token0Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}{' '}
                                  </CurrentPriceStyled>
                                )}

                                <span
                                  style={{
                                    color: Color.WHITE,
                                  }}
                                >
                                  {secondToken?.symbol} {t('per')}{' '}
                                  {firstToken?.symbol}
                                </span>
                              </>
                            ) : (
                              <>
                                {pool?.token1Price.toSignificant(
                                  5,
                                  undefined,
                                  2
                                ).length! > 30 ? (
                                  <Tooltip
                                    enterTouchDelay={0}
                                    title={pool?.token1Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  >
                                    <CurrentPriceStyled>
                                      {pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    </CurrentPriceStyled>
                                  </Tooltip>
                                ) : (
                                  <CurrentPriceStyled>
                                    {pool?.token1Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}{' '}
                                  </CurrentPriceStyled>
                                )}

                                <span
                                  style={{
                                    color: Color.WHITE,
                                  }}
                                >
                                  {secondToken?.symbol} {t('per')}{' '}
                                  {firstToken?.symbol}
                                </span>
                              </>
                            )}
                          </PriceTitle>
                        )}
                        <EmptyGraphTitle>
                          {t('yourPositionWillAppearHere')}
                        </EmptyGraphTitle>
                      </GraphWrap>
                    ) : (
                      <>
                        <GraphWrap>
                          <PriceTitle>
                            {t('currentPrice')}:{' '}
                            {firstToken?.wrapped.symbol ===
                            pool?.token0.symbol ? (
                              <span style={{ color: Color.WHITE }}>
                                {pool?.token0Price.toSignificant(
                                  5,
                                  undefined,
                                  2
                                ).length! > 30 ? (
                                  <Tooltip
                                    enterTouchDelay={0}
                                    title={pool?.token0Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  >
                                    <CurrentPriceStyled>
                                      {pool?.token0Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    </CurrentPriceStyled>
                                  </Tooltip>
                                ) : (
                                  <CurrentPriceStyled>
                                    {pool?.token0Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  </CurrentPriceStyled>
                                )}{' '}
                                {secondToken?.symbol} {t('per')}{' '}
                                {firstToken?.symbol}
                              </span>
                            ) : (
                              <span style={{ color: Color.WHITE }}>
                                {pool?.token1Price.toSignificant(
                                  5,
                                  undefined,
                                  2
                                ).length! > 30 ? (
                                  <Tooltip
                                    enterTouchDelay={0}
                                    title={pool?.token1Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  >
                                    <CurrentPriceStyled>
                                      {pool?.token1Price.toSignificant(
                                        5,
                                        undefined,
                                        2
                                      )}
                                    </CurrentPriceStyled>
                                  </Tooltip>
                                ) : (
                                  <CurrentPriceStyled>
                                    {pool?.token1Price.toSignificant(
                                      5,
                                      undefined,
                                      2
                                    )}
                                  </CurrentPriceStyled>
                                )}{' '}
                                {secondToken?.symbol} {t('per')}{' '}
                                {firstToken?.symbol}
                              </span>
                            )}
                          </PriceTitle>
                          <LiquidityChart
                            data={
                              tickProcessed &&
                              formatData()?.sort((a, b) =>
                                b.price0 < a.price0 ? 1 : -1
                              )
                            }
                            minPrice={Number(minPrice)}
                            maxPrice={Number(maxPrice)}
                            setMaxPrice={setMaxPrice}
                            setMinPrice={setMinPrice}
                            setTickLowerMin={_setTickLowerMin}
                            setTickLowerMax={_setTickLowerMax}
                            isFullRange={isFullRange}
                            currentPrice={
                              firstToken?.wrapped.symbol === pool?.token0.symbol
                                ? pool?.token0Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  )
                                : pool?.token1Price.toSignificant(
                                    5,
                                    undefined,
                                    2
                                  )
                            }
                          />
                        </GraphWrap>
                      </>
                    )}
                  </>
                )}
                <PriceWrapContainer>
                  <PriceCard>
                    <PriceTitle>{t('minPrice')}</PriceTitle>
                    <PricePlusMinusContainer>
                      <PriceIconWrap>
                        <PriceIcon
                          disabled={isFullRange}
                          onClick={() => {
                            firstToken?.wrapped.symbol === pool?.token0.symbol
                              ? changeLowerMin(false)
                              : changeLowerMin(true);
                          }}
                        >
                          -
                        </PriceIcon>
                      </PriceIconWrap>
                      <PriceStyled>
                        {isFullRange ? 0 : minPrice.slice(0, 7)}
                      </PriceStyled>
                      <PriceIconWrap>
                        <PriceIcon
                          disabled={isFullRange}
                          onClick={() => {
                            firstToken?.wrapped.symbol === pool?.token0.symbol
                              ? changeLowerMin(true)
                              : changeLowerMin(false);
                          }}
                        >
                          +
                        </PriceIcon>
                      </PriceIconWrap>
                    </PricePlusMinusContainer>
                    <PriceTitle>
                      {secondToken?.symbol} {t('per')} {firstToken?.symbol}
                    </PriceTitle>
                  </PriceCard>
                  <PriceCard>
                    <PriceTitle>{t('maxPrice')}</PriceTitle>
                    <PricePlusMinusContainer>
                      <PriceIconWrap>
                        <PriceIcon
                          disabled={isFullRange}
                          onClick={() => {
                            firstToken?.wrapped.symbol === pool?.token0.symbol
                              ? changeLowerMax(false)
                              : changeLowerMax(true);
                          }}
                        >
                          -
                        </PriceIcon>
                      </PriceIconWrap>
                      <PriceStyled>
                        {isFullRange ? '∞' : maxPrice.slice(0, 7)}
                      </PriceStyled>
                      <PriceIconWrap>
                        <PriceIcon
                          disabled={isFullRange}
                          onClick={() => {
                            firstToken?.wrapped.symbol === pool?.token0.symbol
                              ? changeLowerMax(true)
                              : changeLowerMax(false);
                          }}
                        >
                          +
                        </PriceIcon>
                      </PriceIconWrap>
                    </PricePlusMinusContainer>
                    <PriceTitle>
                      {secondToken?.symbol} {t('per')} {firstToken?.symbol}
                    </PriceTitle>
                  </PriceCard>
                </PriceWrapContainer>
                {!notCreated && (
                  <StyledFullRangeBtn
                    onClick={() => setIsEfficiencyComparisonModalOpen(true)}
                  >
                    {t('fullRange')}
                  </StyledFullRangeBtn>
                )}
                {!!invalidRangeSelected && !isFullRange && (
                  <ContentWrap>
                    <WarningIcon />
                    <TitleStyled>{t('invalidRangeSelected')}</TitleStyled>
                  </ContentWrap>
                )}
                {outOfRange && (
                  <ContentWrap>
                    <WarningIcon />
                    <TitleStyled>{t('outOfRangeInfo')}</TitleStyled>
                  </ContentWrap>
                )}
              </div>
            </RightSideContainer>
          )}
          {isMobile && (
            <TipContainer sx={{ margin: '0 16px 16px' }}>
              <div>
                <AlertIcon />
              </div>
              <div>
                <TipText>
                  {t('toAddLiquidityYouMustHaveABalanceOfTokens')}
                </TipText>
              </div>
            </TipContainer>
          )}
        </RowContainer>
        <BottomContainer>
          <ClearAllBtn
            onClick={() => {
              setClearAllSettings(true);
            }}
          >
            {t('clearAllSettings')}
          </ClearAllBtn>
          <ButtonWrapper>
            {tokenAllowance || secondTokenAllowance ? (
              <AddLiquidityBtn onClick={tokenApprove}>
                {t('approveToken')}
              </AddLiquidityBtn>
            ) : (
              <AddLiquidityBtn
                onClick={() => {
                  setIsOpen(true);
                }}
                variant='contained'
                disabled={
                  (Number(firstTokenState) <= 0 && !outOfRange) ||
                  Number(firstTokenBalance?.formatted) <
                    Number(firstTokenState) ||
                  (Number(secondTokenState) <= 0 && !outOfRange) ||
                  Number(secondTokenBalance?.formatted) <
                    Number(secondTokenState) ||
                  depositAmountsDisabled ||
                  deposit0Disabled ||
                  deposit1Disabled
                }
              >
                {firstToken?.wrapped?.address === secondToken?.wrapped?.address
                  ? t('chooseValidPair')
                  : Number(firstTokenBalance?.formatted) <
                      Number(firstTokenState) ||
                    Number(secondTokenBalance?.formatted) <
                      Number(secondTokenState)
                  ? t('insufficientBalance')
                  : (Number(firstTokenState) <= 0 && !outOfRange) ||
                    (Number(secondTokenState) <= 0 && !outOfRange)
                  ? t('enterAnAmount')
                  : t('preview')}
              </AddLiquidityBtn>
            )}
          </ButtonWrapper>
        </BottomContainer>
      </StyledPaper>
      {!!firstToken && !!secondToken && !!alignment && pool && (
        <AddLiquidityPopup
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          fee={Number(alignment)}
          maxPrice={maxPrice}
          minPrice={minPrice}
          currentPrice={
            firstToken?.wrapped.symbol === pool?.token0.symbol
              ? pool?.token0Price.toSignificant(5, undefined, 2)
              : pool?.token1Price.toSignificant(5, undefined, 2)
          }
          tickLower={tickLowerMax}
          tickUpper={tickLowerMin}
          isFullRange={isFullRange}
          poolSpacing={pool?.tickSpacing}
          tickCurrent={pool?.tickCurrent}
          firstToken={firstToken}
          secondToken={secondToken}
          outOfRange={outOfRange}
          newPool={notCreated}
          mockedPool={mockPool}
          clearTokensValue={clearAll}
          deposit0Disabled={deposit0Disabled}
          deposit1Disabled={deposit1Disabled}
        />
      )}
      <EfficiencyComparisonModal
        isOpen={isEfficiencyComparisonModalOpen}
        setIsOpen={setIsEfficiencyComparisonModalOpen}
        setIsFullRange={() => setIsFullRange(true)}
      />
    </>
  );
};

export default AddLiquidity;
