import { styled, Tabs } from '@mui/material';
import { Color, FontFamily } from 'helpers/themeStyles';
import { Link } from 'react-router-dom';

export const NavBarWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  height: '100vh',
  backgroundColor: Color.BACKGROUND,
  zIndex: 110,
  transition: 'width 0.3s ease-in',

  [theme.breakpoints.down('mobile')]: {
    position: 'static',
    backgroundColor: 'inherit',
  },
}));

export const LogoWrap = styled('div')(({ theme }) => ({
  height: '68px',
  display: 'flex',
  paddingLeft: '31px',
  alignItems: 'center',
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
  textDecoration: 'none',

  [theme.breakpoints.down('mobile')]: {
    paddingLeft: '0px',
    borderRightStyle: 'none',
    borderRightWidth: 'inherit',
  },
}));

export const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  '& .MuiButtonBase-root': {
    padding: '4px 0',
    opacity: 1,
  },
  '& .MuiTabs-indicator': {
    opacity: 0,
  },
}));

export const StyledLinkNav = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flex: '1',
  },
}));
export const StyledFullIcon = styled('p')(({ theme }) => ({
  color: Color.WHITE,
  textDecoration: 'none',
  marginLeft: '24px',
  fontSize: '24px',
}));
export const StyledNav = styled('div')(() => ({
  marginTop: '24px',
}));

export const ContentWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 68px)',
  justifyContent: 'space-between',
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
}));

export const TabTitle = styled('span')(() => ({
  color: Color.WHITE,
  fontSize: '18px',
  fontFamily: FontFamily.INTER,
  border: 'none',
  textDecoration: 'none',
}));

export const TabWrap = styled('div')(() => ({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
  margin: '0 auto',
}));
