import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useAccount, useWaitForTransactionReceipt } from 'wagmi';
import { ethers, utils } from '@omisoftnet/game-dex-sdk';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { KeyboardBackspace, Close, HelpOutline } from '@mui/icons-material';

import {
  StyledDialog,
  StyledFieldContainer,
  StyledFieldName,
  StyledFieldValue,
  StyledTitle,
  StyledInput,
  StyledContentContainer,
  StyledInputeLabel,
  StyledDetailsContainer,
  StyledInvestBtn,
  StyledBtnContainer,
  StyledBackBtn,
  StyledContainer,
  StyledRowContainer,
} from './styles';
import useDebounce from '@hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionHash } from 'state/transactions/slice';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { StyledErrorText } from '../CreateProjectFirstStep/styles';
import { stableTokens } from 'helpers/nativeTokens';
import { StyledTokenIcon } from 'components/TokenSelector/styles';
import { launchpadContracts } from 'config/contracts';
import { Color } from 'helpers/themeStyles';
import { projectDetailsSelector } from 'state/launchpad/selectors';
import { transactionHashSelector } from 'state/transactions/selectors';
import WaitingPopup from '../WaitingPopup';
import { useSigner } from '@hooks/useSigner';

const InvestModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const project = useSelector(projectDetailsSelector);
  const [investedValue, setInvestedValue] = useState('');
  const debouncedInvestValue = useDebounce(investedValue, 200);
  const [txApprove, setTxApprove] = useState('');
  const [allowance, setAllowance] = useState(false);
  const [recieved, setRecieved] = useState(0);
  const [error, setError] = useState(false);
  const [maxInvestValue, setMaxInvestValue] = useState<number>(0);
  const { address, chain } = useAccount();
  const payableToken = stableTokens[chain?.id!];
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const filteredInvestor =
    project &&
    project.tokenSaleDetails.participants.find(
      (item) => item.address === address
    );
  const signer = useSigner();
  const dispatch = useDispatch();
  const transactionHash = useSelector(transactionHashSelector);
  const { isLoading: isLoadingInvesting, isSuccess: isInvestSuccess } =
    useWaitForTransactionReceipt({
      hash: transactionHash as `0x${string}`,
    });
  useEffect(() => {
    if (isInvestSuccess) {
      setOpenWaitingPopup(false);
    }
  }, [isInvestSuccess]);
  const { isLoading, isSuccess } = useWaitForTransactionReceipt({
    hash: txApprove as `0x${string}`,
  });
  useEffect(() => {
    setAllowance(false);
  }, [isSuccess]);
  const invest = async () => {
    setOpenWaitingPopup(true);
    const contr = new ethers.Contract(
      project?.launchpadAddress!,
      launchpadContracts[chain?.id!].launchpadAbi,
      signer!
    );
    try {
      const tx = await contr.functions['buy(uint256)'](
        utils.parseUnits(
          investedValue,
          stableTokens[chain?.id!].decimals
          // how much user want to invest
        )
      );
      dispatch(setTransactionHash({ hash: tx.hash }));
      setTransactionSubmittedStatus(true);
    } catch (err) {
      setTransactionRejectedStatus(true);
      setOpenWaitingPopup(false);
    }
  };
  const checkTokenForAllowance = async () => {
    const token = stableTokens[chain?.id!];
    if (token) {
      const allowance = await token.allowance(
        signer!,
        address as `0x${string}`,
        project?.launchpadAddress!
      );
      const isNegative = allowance
        .sub(utils.parseUnits(debouncedInvestValue, token.decimals))
        .isNegative();
      setAllowance(isNegative);
    }
  };
  const approveToken = async () => {
    try {
      const token = stableTokens[chain?.id!];

      const txApprove = await token.approve(
        signer!,
        project?.launchpadAddress!
      );
      setTxApprove(txApprove.hash);
    } catch (err) {
      setAllowance(true);
    }
  };
  useEffect(() => {
    setAllowance(false);
    checkTokenForAllowance();
  }, [debouncedInvestValue]);

  useEffect(() => {
    const result =
      Number(debouncedInvestValue) / Number(project?.tokenSaleDetails?.price);
    setRecieved(Number(result.toFixed(7)));
  }, [debouncedInvestValue]);
  useEffect(() => {
    if (
      debouncedInvestValue &&
      (Number(debouncedInvestValue) <
        Number(project?.tokenSaleDetails?.minAllocation) ||
        Number(debouncedInvestValue) >
          Number(project?.tokenSaleDetails?.maxAllocation) ||
        Number(debouncedInvestValue) > maxInvestValue)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [debouncedInvestValue]);

  useEffect(() => {
    if (project && project.poolInformation?.toRiseProgress) {
      const availableTotalAmountToInvest =
        Number(project.poolInformation.toRise) -
        project.poolInformation.toRiseProgress;
      const maxAllocation = Number(project.tokenSaleDetails.maxAllocation);
      if (filteredInvestor) {
        const remainingInvestmentAmountForUser =
          maxAllocation - filteredInvestor.amount;
        const maxInvestValueForUser = Math.min(
          remainingInvestmentAmountForUser,
          availableTotalAmountToInvest
        );
        setMaxInvestValue(maxInvestValueForUser);
      } else {
        const maxInvestValue = Math.min(
          availableTotalAmountToInvest,
          maxAllocation
        );
        setMaxInvestValue(maxInvestValue);
      }
    } else {
      setMaxInvestValue(Number(project?.tokenSaleDetails?.maxAllocation));
    }
  }, [filteredInvestor, project]);

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => onClose()}
      >
        {!isMobile ? (
          <StyledTitle>
            {t('invest')}
            <IconButton
              aria-label='close'
              onClick={() => onClose()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: Color.WHITE,
              }}
            >
              <Close />
            </IconButton>
          </StyledTitle>
        ) : (
          <StyledBackBtn
            onClick={onClose}
            startIcon={<KeyboardBackspace sx={{ mr: 2 }} />}
          >
            {t('invest')}
          </StyledBackBtn>
        )}

        <StyledContentContainer
          style={{
            borderColor: isMobile
              ? 'transparent'
              : Color.DARK_PURPLE_OPACITY_LIGHT,
          }}
        >
          <StyledInputeLabel>{t('enterAnAmount')}</StyledInputeLabel>
          <StyledInput
            onChange={(e) => {
              setInvestedValue(e.target.value);
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                padding: '5px',
                paddingLeft: '15px',
                minHeight: '40px',
              },
            }}
            //@ts-ignore
            placeholder={t('yourAmount')}
            type='number'
          />
          {error && <StyledErrorText>{t('investErrorText')}</StyledErrorText>}
          <StyledContainer>
            <StyledRowContainer>
              <HelpOutline sx={{ color: Color.PURPLE }} />
              <StyledFieldName>
                {t('forThisProjectYouMayStillProvide')}
              </StyledFieldName>
            </StyledRowContainer>
            <StyledFieldValue>
              <StyledTokenIcon
                src={payableToken?.icon}
                style={{ width: '24px', height: '24px' }}
              />
              {maxInvestValue <= 0 ? 0 : maxInvestValue} {payableToken?.symbol}
            </StyledFieldValue>
          </StyledContainer>
          <StyledInputeLabel>{t('details')}</StyledInputeLabel>
          <StyledFieldContainer style={{ marginBottom: '8px' }}>
            <StyledFieldName>{t('youWillRecieve')}</StyledFieldName>
            <StyledFieldValue>
              {recieved ? recieved : 0} {project?.tokenSaleDetails?.tokenSymbol}
            </StyledFieldValue>
          </StyledFieldContainer>
          <StyledDetailsContainer
            style={{
              flexDirection:
                isMobile ||
                project?.tokenSaleDetails?.price?.length! > 6 ||
                project?.tokenSaleDetails.minAllocation.length! > 6 ||
                project?.tokenSaleDetails.maxAllocation.length! > 6
                  ? 'column'
                  : 'row',
            }}
          >
            <StyledFieldContainer>
              <StyledFieldName>{t('payableToken')}</StyledFieldName>
              <StyledFieldValue>
                <StyledTokenIcon
                  src={payableToken?.icon}
                  style={{ width: '24px', height: '24px' }}
                />
                {payableToken?.symbol}
              </StyledFieldValue>
            </StyledFieldContainer>
            <StyledFieldContainer>
              <StyledFieldName>{t('price')}</StyledFieldName>

              <StyledFieldValue>
                <StyledTokenIcon
                  src={payableToken?.icon}
                  style={{ width: '24px', height: '24px' }}
                />
                {project?.tokenSaleDetails?.price} {payableToken?.symbol}
              </StyledFieldValue>
            </StyledFieldContainer>
            <StyledFieldContainer>
              <StyledFieldName>{t('minPerWallet')}</StyledFieldName>
              <StyledFieldValue>
                <StyledTokenIcon
                  src={payableToken?.icon}
                  style={{ width: '24px', height: '24px' }}
                />
                {project?.tokenSaleDetails?.minAllocation}{' '}
                {payableToken?.symbol}
              </StyledFieldValue>
            </StyledFieldContainer>
            <StyledFieldContainer>
              <StyledFieldName>{t('maxPerWallet')}</StyledFieldName>
              <StyledFieldValue>
                <StyledTokenIcon
                  src={payableToken?.icon}
                  style={{ width: '24px', height: '24px' }}
                />
                {project?.tokenSaleDetails?.maxAllocation}{' '}
                {payableToken?.symbol}
              </StyledFieldValue>
            </StyledFieldContainer>
          </StyledDetailsContainer>
        </StyledContentContainer>
        {project?.launchpadAddress && project?.status !== 'closed' && (
          <StyledBtnContainer>
            {!allowance ? (
              <StyledInvestBtn
                variant='outlined'
                color='inherit'
                onClick={() => {
                  invest();
                }}
                disabled={error || !debouncedInvestValue}
              >
                {t('invest')}
              </StyledInvestBtn>
            ) : (
              <StyledInvestBtn
                variant='outlined'
                color='inherit'
                onClick={() => approveToken()}
                disabled={isLoading || error}
              >
                {isLoading ? t('waitForApprove') : t('approve')}
              </StyledInvestBtn>
            )}
          </StyledBtnContainer>
        )}
      </StyledDialog>
      {transactionSubmittedStatus && !isLoadingInvesting && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {
            onClose();
          }}
          result={transactionStatuses.SUBMIT}
          clearTokensValue={() => setInvestedValue('')}
          reloadPage
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
          clearTokensValue={() => setInvestedValue('')}
        />
      )}
      <WaitingPopup
        isOpen={openWaitingPopup}
        onClose={() => {
          setOpenWaitingPopup(false);
        }}
      />
    </>
  );
};

export default InvestModal;
