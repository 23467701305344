import React from 'react';

const ArrowButtonIcon = () => {
  return (
    <svg
      width='14'
      height='9'
      viewBox='0 0 14 9'
      fill='#ffffff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 1.5L7 7.5L1 1.5'
        stroke='#B032D6'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default ArrowButtonIcon;
