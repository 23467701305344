import {
  Button,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { CSSProperties } from '@mui/material/styles/createMixins';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledCreateProjectWrapper = styled('div')(({ theme }) => ({
  maxWidth: '70%',
  margin: '0 auto',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '100%',
  },
}));

export const StyledTitle = styled(Typography)({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  lineHeight: '150%',
  marginBottom: '16px',
});

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.down('mobile')]: {
    gap: 0,
  },
}));

export const StyledContainer = styled(Stack)({
  alignItems: 'flex-start',
  margin: '8px 0',
  gap: '8px',
});

export const StyledLabel = styled(Typography)({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  lineHeight: '160%',
});

export const StyledInput = styled(TextField)({
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SMALL,
  '& .MuiInputBase-root': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.SMALL,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
  '& ::placeholder': {
    color: Color.WHITE_OPACITY_LIGHT,
  },
});

export const StyledErrorText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.RED,
  fontSize: TextSize.SMALL,
  alignSelf: 'flex-end',
  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledSelect = styled(Select)({
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SMALL,
  color: Color.WHITE,
  '& .MuiSvgIcon-root': {
    color: Color.WHITE,
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
  },
});

export const StyledMenuItem = styled(MenuItem)({
  padding: '12px 8px',
  margin: '0 12px',
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  '&.Mui-selected': {
    backgroundColor: `${Color.DARK_PURPLE_OPACITY_MIDDLE}!important`,
    borderRadius: BorderRadius.SMALL,
  },
});

export const selectMenuListStyle: CSSProperties = {
  maxHeight: '200px',
  overflowY: 'auto',
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  padding: '16px 0',
};

export const selectPaperStyle: CSSProperties = {
  background: 'transparent',
};

export const StyledFormControlLabel = styled(FormControlLabel)({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  '& .MuiCheckbox-root': {
    color: Color.WHITE_OPACITY_LIGHT,
    '&.Mui-checked': {
      color: Color.WHITE_OPACITY_LIGHT,
    },
  },
});

export const StyledOptionButton = styled('div')(
  ({ backgroundImage }: { backgroundImage?: number }) => ({
    padding: '12px',
    borderRadius: BorderRadius.SEMI_NORMAL,
    cursor: 'pointer',
    border: backgroundImage ? Border.TRANSPARENT_BORDER : Border.ACCENT_BORDER,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    backgroundImage: backgroundImage
      ? `linear-gradient(${Color.BACKGROUND_MAIN}, ${Color.BACKGROUND_MAIN}), ${Color.TEXT_GRADIENT}`
      : undefined,
  })
);

export const ExternalOptionCircle = styled('div')({
  width: 21,
  height: 21,
  background: Color.TEXT_GRADIENT,
  borderRadius: BorderRadius.HUGE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const InternalOptionCircle = styled('span')({
  width: 11,
  height: 11,
  background: Color.TEXT_GRADIENT,
  borderRadius: BorderRadius.HUGE,
  border: `3px solid ${Color.BACKGROUND_MAIN}`,
});

export const StyledNextButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  fontWeight: 700,
  color: Color.WHITE,
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: '16px 32px',
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY,
    color: Color.WHITE_OPACITY_LIGHT,
  },
  [theme.breakpoints.down('mobile')]: {
    lineHeight: '140%',
    padding: '12px 28px',
  },
  [theme.breakpoints.down(390)]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledCancelButton = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  fontWeight: 700,
  color: Color.WHITE,
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `2px solid ${Color.WHITE}`,
  padding: '16px 32px',
  [theme.breakpoints.down('mobile')]: {
    lineHeight: '140%',
    padding: '12px 28px',
  },
  [theme.breakpoints.down(390)]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));
