import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flex: '1',
  },
}));

export const LinksWrapper = styled('div')(({ theme }) => ({
  padding: '32px 16px',
  marginTop: '16px',
  transition: 'opacity 1ms ease-in',
  transitionDelay: '0.3s',

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '0',
    padding: '20px 0px',
  },
}));

export const LinkStyled = styled('a')(({ theme }) => ({
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
  fontFamily: FontFamily.INTER,
  textDecoration: 'none',
  display: 'block',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
    fontWeight: '400',
  },
}));

export const SocialIconsWrapper = styled('div')(({ theme }) => ({
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
  padding: '24px 12px',
  display: 'flex',

  [theme.breakpoints.down('mobile')]: {
    padding: '4px 36px 12px',
    justifyContent: 'center',
    border: 'inherit',
  },
}));

export const TransitionWrapper = styled('div')(() => ({
  transition: 'opacity 1ms ease-in',
  transitionDelay: '0.3s',
}));

export const SocialIconsContent = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  height: '97px',
  borderRadius: '8px',
}));

export const IconWrap = styled('a')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '37px',
  color: Color.WHITE,
  fontSize: '18px',
  textDecoration: 'none',
}));

export const CopyrightWrap = styled('p')(({ theme }) => ({
  color: Color.WHITE_OPACITY_LIGHT,
  fontSize: '13px',
  fontFamily: FontFamily.INTER,
  margin: '24px 0 24px 12px',

  [theme.breakpoints.down('mobile')]: {
    margin: 0,
    paddingBottom: '136px',
    textAlign: 'center',
  },
}));
