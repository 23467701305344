import {
  Button,
  InputBase,
  Paper,
  Stack,
  styled,
  ToggleButton,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: '1060px',
  width: '100%',
  height: 'fit-content',
  background: Color.SWAP_GRADIENT,
  borderRadius: BorderRadius.NORMAL,
  padding: '22px 0px 10px',
  margin: '0 auto',

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    border: 'none',
    borderRadius: 'inherit',
    background: Color.BACKGROUND_MAIN_WITH_OPACITY,
    boxShadow: 'inherit',
    padding: 0,
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  justifyContent: 'flex-start',
  minWidth: '16px',
  maxWidth: '24px',
  padding: 0,
  '&hover': {
    backgroundColor: '#ffffff',
  },
});
export const SelectTokensContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '16px',

  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}));

export const ShowMoreBtn = styled(Button)({
  background: 'rgba(176, 50, 214, 0.1)',
  color: Color.WHITE,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  fontWeight: '400',
  justifyContent: 'space-between',
  borderRadius: BorderRadius.SMALL,
});
export const ClearAllBtn = styled(Button)({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.PINK,
  lineHeight: '160%',
  fontStyle: 'normal',
  maxWidth: '140px',
  textTransform: 'none',
  padding: 0,
  fontWeight: '700',
});
export const HeadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(176, 50, 214, 0.4)',
  padding: '0px 16px 22px',
});

export const SelectorTitle = styled('h3')({
  fontFamily: FontFamily.INTER,
  fontWeight: '600',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
  marginBottom: 0,
  marginTop: 0,
});
export const StyledTokenName = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
});
export const CurrentPriceStyled = styled('span')({
  color: Color.WHITE,
  maxWidth: '150px',
  padding: '0 8px ',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
export const FeeTierHeader = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});
export const ExternalFeeCircle = styled('div')({
  width: 21,
  height: 21,
  background: Color.TEXT_GRADIENT,
  borderRadius: BorderRadius.HUGE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '-6px',
  marginTop: '-6px',
});
export const InternalFeeCircle = styled('span')({
  width: 11,
  height: 11,
  background: Color.TEXT_GRADIENT,
  borderRadius: BorderRadius.HUGE,
  border: `3px solid ${Color.BACKGROUND}`,
});
export const FeeTierTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  lineHeight: '150%',
});
export const FeeTier = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
});
export const FeeTierSubtitle = styled('div')({
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  lineHeight: '150%',
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.TEXT_OPACITY_LIGHT,
});
export const FeeSelected = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  lineHeight: '150%',
  fontSize: TextSize.SMALL,
  color: Color.TEXT_OPACITY_LIGHT,
  textTransform: 'none',
  marginTop: '16px',
});
export const SelectorItem = styled('div')(({ theme }) => ({
  width: '48%',
  display: 'grid',
  rowGap: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingLeft: '16px',
  [theme.breakpoints.between('mobile', 1400)]: {
    width: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    width: 'inherit',
  },
}));

export const RightSideContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  rowGap: '16px',
  padding: 16,
  paddingBottom: '20px',
  borderLeft: '1px solid rgba(176, 50, 214, 0.4)',
  [theme.breakpoints.between('mobile', 1400)]: {
    border: 'none',
  },
  [theme.breakpoints.down('mobile')]: {
    width: 'inherit',
    borderLeft: 'inherit',
  },
}));

export const BorderStyled = styled('div')(({ theme }) => ({
  border: '1px solid rgba(176, 50, 214, 0.4)',
  borderRadius: '16px',
  backgroundColor: Color.BACKGROUND,
  padding: '16px',

  [theme.breakpoints.down('mobile')]: {
    zIndex: 'inherit',
  },
}));

export const StyledToggleButton = styled(ToggleButton)({
  border: '1px solid rgba(176, 50, 214, 0.4)!important',
  borderRadius: '16px!important',
  padding: '16px!important',
  width: 'auto!important',
  alignItems: 'flex-start',
  margin: '0!important',
});
export const RowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  [theme.breakpoints.between('mobile', 1400)]: {
    justifyContent: 'center',
    width: 'auto',
  },
  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}));

export const FeeTierDescription = styled('span')({
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  textTransform: 'none',
  marginTop: 10,
  fontFamily: FontFamily.INTER,
  lineHeight: '150%',
  textAlign: 'start',
});

export const SubtitleWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: Color.WHITE,
  columnGap: '16px',
}));
export const StyledTypography = styled('h6')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,
  lineHeight: '150%',
  margin: 0,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
    fontWeight: 600,
  },
}));

export const StyledInput = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: Color.BACKGROUND,
  alignItems: 'flex-start',
  minHeight: '106.5px',
  boxSizing: 'border-box',

  [theme.breakpoints.down('mobile')]: {
    border: Border.ACCENT_BORDER,
    borderRadius: '16px',
  },
}));

export const StyledInputHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '16px',
}));

export const StyledInputBase = styled(InputBase)(() => ({
  width: '100%',
  flex: 1,
  fontSize: TextSize.MEDIUM,
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
  fontWeight: '700',
  '& .MuiInputBase-input': { padding: 0 },
  '& .Mui-disabled': {
    WebkitTextFillColor: Color.WHITE_OPACITY_LIGHT,
  },
  '& ::placeholder': { color: Color.WHITE_OPACITY_LIGHT },
}));

export const StyledHeaderBtnContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SelectorHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const BalanceStyled = styled('span')(() => ({
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
}));

export const MaxStyled = styled('button')(() => ({
  textTransform: 'uppercase',
  cursor: 'pointer',
  fontWeight: 400,
  minWidth: 42,
  color: Color.PURPLE,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
  background: 'none',
  border: 'none',
  padding: 0,
}));

export const TokensBalanceWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const InputWrap = styled('div')(() => ({
  fontSize: '16px',
  padding: '0 auto',
  width: '100%',
}));

export const GraphWrap = styled('div')(() => ({
  width: '100%',
  height: '360px',
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '16px',
  rowGap: '60px',
}));

export const EmptyGraphTitle = styled('h4')(() => ({
  fontSize: '18px',
  textAlign: 'center',
}));

export const NotCreatedWrap = styled('div')(() => ({
  backgroundColor: Color.WARNING_NFT,
  borderRadius: BorderRadius.NORMAL,
  padding: '12px ',
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  display: 'flex',
}));

export const NotCreatedTitle = styled('p')({
  fontSize: TextSize.EXTRA_SMALL,
  fontFamily: FontFamily.INTER,
  lineHeight: '150%',
  color: Color.WHITE_OPACITY_LIGHT,
});
export const StyledInputWhithoutBorder = styled(InputBase)({
  fontSize: '36px',
});
export const ButtonWrapper = styled('div')(() => ({
  margin: '18px 12px',
}));

export const CreatePoolWrap = styled('div')(() => ({}));

export const InputPoolWrap = styled('div')(() => ({
  padding: '12px',
  borderRadius: '16px',
  border: '1px solid rgb(184, 192, 220)',
}));

export const CurrentPoolPriceWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  padding: '24px 16px',
  borderRadius: BorderRadius.NORMAL,
  margin: '18px 0',
}));

export const CurrentPriceTitle = styled('span')({
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  color: Color.WHITE,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
});

export const PriceCard = styled('div')(({ theme }) => ({
  padding: '24px 16px',
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  width: '42%',
  borderRadius: BorderRadius.NORMAL,

  [theme.breakpoints.down('mobile')]: {
    flexGrow: 1,
  },
}));

export const PriceTitle = styled('p')({
  fontFamily: FontFamily.INTER,
  fontWeight: '700',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textAlign: 'center',
  color: Color.WHITE_OPACITY_LIGHT,
  margin: 0,
});
export const PriceWrapContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '16px',

  [theme.breakpoints.down('mobile')]: {
    rowGap: '16px',
    flexWrap: 'wrap',
  },
}));
export const PriceIconWrap = styled('button')({
  width: '36px',
  height: '36px',
  background: 'rgba(176, 50, 214, 0.1)',
  color: Color.WHITE,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
export const PricePlusMinusContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const PriceIcon = styled('button')({
  cursor: 'pointer',
  fontSize: '24px',
  background: 'none',
  border: 'none',
  color: Color.WHITE,
});

export const PriceStyled = styled('span')({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  lineHeight: '160%',
});

export const StyledSwitchPairContainer = styled('div')({
  display: 'flex',
  border: '1px solid gray',
  borderRadius: '10px',
});

export const StyledSwitchPairButton = styled(ToggleButton)({
  padding: 0,
  display: 'block',
  minWidth: '40px',
});

export const StyledFullRangeBtn = styled(Button)({
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  textTransform: 'none',
  width: '100%',
  marginTop: '16px',
});

export const BottomContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 16px 0px',
  borderTop: '1px solid rgba(176, 50, 214, 0.4)',
});

export const AddLiquidityBtn = styled(Button)({
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  fontSize: TextSize.SMALL,
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  color: Color.WHITE,
});
export const TitleStyled = styled('p')({
  color: Color.WHITE_OPACITY_LIGHT,
  display: 'flex',
  alignItems: 'center',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 'bold',
  marginLeft: '8px',
});
export const ContentWrap = styled('div')(() => ({
  backgroundColor: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: '8px',
  margin: '16px 0',
  padding: '12px 14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const OutOfRangeInput = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  padding: '13px',
  backgroundColor: Color.BACKGROUND,
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '106.5px',
  boxSizing: 'border-box',
  rowGap: '10px',

  [theme.breakpoints.down('mobile')]: {
    border: Border.ACCENT_BORDER,
    borderRadius: '16px',
  },
}));

export const OutOfRangeText = styled('span')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.TABLE_SMALL,
  lineHeight: '130%',
  color: Color.WHITE_OPACITY_LIGHT,
  textAlign: 'center',
});

export const TipContainer = styled(Stack)({
  padding: 12,
  margin: '0 0 16px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  background: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
});

export const TipText = styled('p')({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  lineHeight: '150%',
  margin: 0,
});
