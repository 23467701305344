import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM, IResetPassword } from 'types/statuses';
import { StatusGenerator } from 'utils/StatusGenerator';
import { IPaginate } from 'types/pagination';
import { getCollectionItemsThunk, getHotNftCollectionsThunk } from './thunks';
import { Nft } from 'types/nft';

interface IState {
  nftCollection: object | null;
  userNftCollection: IPaginate | null;
  hotNftCollections: IPaginate | null;
  collectionItems: Array<Nft> | null;
}

const initialState: IState = {
  nftCollection: null,
  userNftCollection: null,
  hotNftCollections: null,
  collectionItems: null,
};

const nftCollectionSlice = createSlice({
  name: 'nftCollection',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    sethotNftCollections: (state, action) => {
      state.hotNftCollections = action.payload.data ?? null;
    },
    setNftCollections: (state, action) => {
      state.nftCollection = action.payload.data ?? null;
    },
    setCollectionItems: (state, action) => {
      state.collectionItems = action.payload.data ?? null;
    },
    resetCollectionItems: (state) => {
      state.collectionItems = initialState.collectionItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getHotNftCollectionsThunk.fulfilled,
      (state, { payload }) => {
        state.hotNftCollections = payload;
      }
    );
    builder.addCase(getCollectionItemsThunk.fulfilled, (state, { payload }) => {
      state.collectionItems = payload;
    });
  },
});

export default nftCollectionSlice;
