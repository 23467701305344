import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { useSelector } from 'react-redux';

import { stableTokens } from 'helpers/nativeTokens';
import { dexSelector } from 'state/dex/selectors';
import { useAccount } from 'wagmi';

export const useAsUsd = () => {
  const { chain } = useAccount();
  const dex = useSelector(dexSelector);
  const getValueInUsd = async ({
    token,
    value,
  }: {
    token: DEXToken;
    value: string;
  }) => {
    if (token && chain && value && token.chainId === chain.id && dex) {
      const provider = await dex.getDEXProvider(chain.id);
      console.log('rpovider', provider);
      const stableToken = stableTokens[chain?.id];
      if (stableToken.address === token.address) {
        return utils.formatUnits(token.amount, token.decimals);
      }
      try {
        token.amount = utils.parseUnits(
          value.slice(0, token.decimals),
          token.decimals
        );
        const [_, amountOut] = await provider.getAmountsOut(
          token.wrapped,
          stableToken
        );
        const usdPrice = utils.formatUnits(amountOut, stableToken.decimals);
        return usdPrice;
      } catch (e) {
        console.error(e);
        return '0.0';
      }
    }
  };
  return { getValueInUsd };
};
