import { AppBar, styled } from '@mui/material';
import { Border, Color } from 'helpers/themeStyles';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '24px',
  background: Color.BACKGROUND_MAIN,
  zIndex: 100,
  boxShadow: 'none',

  [theme.breakpoints.down('mobile')]: {
    gap: '8px',
    background: Color.BACKGROUND,
    borderBottom: Border.ACCENT_BORDER,
    '&.MuiAppBar-positionFixed': {
      right: 'auto',
    },
  },
}));
