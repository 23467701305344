import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Close,
  HelpOutlineRounded,
  ReportProblemRounded,
} from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  List,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { SLIPPAGETOLERANCE } from 'helpers/constants';
import { Color } from 'helpers/themeStyles';
import { Infotip } from 'shared/Infotip';
import { setLiquiditySettings } from 'state/liquidity/slice';
import { liquiditySettingsSelector } from 'state/liquidity/selectors';
import SettingsIcon from 'svg/SettingsIcon';
import {
  SLIPPAGE_TOLERANCE_LAST_VALID_VALUE,
  TRANSACTION_DEADLINE_MAX_VALUE,
} from 'helpers/constants';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledLiquiditySettingsInput,
  StyledListItem,
  StyledPercentSymbol,
  StyledSlippageToleranceBtn,
  LiquiditySettingsContainer,
  StyledDialogTitle,
  StyledDialogContentTitle,
  StyledListItemText,
  StyledInfotipText,
  StyledLiquidityDeadlineInput,
  StyledButton,
} from './styles';

import { StyledSmallSubtitle } from '../SwapSettings/styles';

export default function LiquiditySettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const liquiditySettings = useSelector(liquiditySettingsSelector);
  const [open, setOpen] = useState(false);
  const [slippageTolerance, setSlippageTolerance] = useState(
    String(liquiditySettings.slippage) || ''
  );
  const [transactionDeadline, setTransactionDeadline] = useState(
    String(liquiditySettings.deadline) || ''
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const isSlippageToleranceLessMinimum =
    Number(slippageTolerance) < 0.05 &&
    Number(slippageTolerance) >= 0 &&
    !slippageTolerance.includes('-') &&
    slippageTolerance.length > 0;

  const isTransactionMayBeFrontrun =
    Number(slippageTolerance) > 1 &&
    Number(slippageTolerance) <= SLIPPAGE_TOLERANCE_LAST_VALID_VALUE;

  const isTransactionDeadlineError =
    Number(transactionDeadline) < 1 ||
    Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE;

  const isErrorSlippagePercentage =
    Number(slippageTolerance) > SLIPPAGE_TOLERANCE_LAST_VALID_VALUE ||
    Number(slippageTolerance) < 0 ||
    slippageTolerance.includes('-');
  const handleClickOpen = () => {
    setOpen(true);
    if (isErrorSlippagePercentage && open) setSlippageTolerance('');
    if (slippageTolerance.replace(/^0+/, '').length === 1) {
      setSlippageTolerance(`${slippageTolerance.slice(-1)}.00`);
    } else if (
      slippageTolerance !== slippageTolerance.replace(/^0+/, '') &&
      !slippageTolerance.includes('.')
    ) {
      setSlippageTolerance(`${slippageTolerance.slice(-2)}.00`);
    } else if (
      slippageTolerance.includes('.') &&
      slippageTolerance.length > 4 &&
      Number(slippageTolerance) < 50
    ) {
      setSlippageTolerance(
        String(Math.trunc(Number(slippageTolerance) * 100) / 100)
      );
    }
    if (
      transactionDeadline[0] < '4' &&
      Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE
    ) {
      setTransactionDeadline(transactionDeadline.slice(0, 4));
    } else if (
      transactionDeadline[0] >= '4' &&
      Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE
    ) {
      setTransactionDeadline(transactionDeadline.slice(0, 3));
    }
    if (transactionDeadline.startsWith('0')) {
      setTransactionDeadline(transactionDeadline.replace(/^0+/, ''));
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (slippageTolerance === '' && transactionDeadline === '') {
      dispatch(
        setLiquiditySettings({
          slippage: '0.5',
          deadline: '30',
        })
      );
    } else if (slippageTolerance === '' || isErrorSlippagePercentage) {
      dispatch(
        setLiquiditySettings({
          slippage: '0.5',
          deadline: transactionDeadline,
        })
      );
    } else if (transactionDeadline === '') {
      dispatch(
        setLiquiditySettings({
          slippage: slippageTolerance,
          deadline: '30',
        })
      );
    } else {
      dispatch(
        setLiquiditySettings({
          slippage: slippageTolerance,
          deadline: transactionDeadline,
        })
      );
    }
  }, [slippageTolerance, transactionDeadline]);

  useEffect(() => {
    if (Number(slippageTolerance) > 50) {
      return () => setSlippageTolerance('');
    }
  }, [open]);
  return (
    <>
      <LiquiditySettingsContainer>
        <IconButton
          onClick={() => {
            handleClickOpen();
          }}
        >
          <SettingsIcon />
        </IconButton>
      </LiquiditySettingsContainer>
      <StyledDialog
        onClose={handleClose}
        open={open}
      >
        <StyledDialogHeader>
          <StyledDialogTitle>{t('settings')}</StyledDialogTitle>
          <Close
            onClick={handleClose}
            sx={{ cursor: 'pointer', color: Color.WHITE, pr: 2 }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <StyledDialogContentTitle>
            {t('swapsAndLiguidity')}
          </StyledDialogContentTitle>
          <List sx={{ pt: 0, pb: 0 }}>
            <StyledListItem>
              <Stack
                direction='column'
                alignItems='flex-start'
                gap={1.5}
              >
                <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  gap={1}
                >
                  <StyledListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        fontWeight: '700!important',
                      },
                    }}
                    primary={t('slippageTolerance')}
                  />
                  <Infotip
                    title={
                      <StyledInfotipText>
                        {t('liquiditySlippageToleranceTip')}
                      </StyledInfotipText>
                    }
                    placement='top-start'
                  >
                    <HelpOutlineRounded
                      fontSize='small'
                      sx={{ color: 'grey', '&:hover': { color: 'lightgrey' } }}
                    />
                  </Infotip>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  gap={1}
                >
                  {SLIPPAGETOLERANCE.map((item) => (
                    <StyledSlippageToleranceBtn
                      key={item}
                      style={{
                        background:
                          slippageTolerance === item
                            ? Color.PURPLE
                            : Color.BORDER_WHITE_EXTRA_OPACITY,
                      }}
                      onClick={() => setSlippageTolerance(item)}
                    >
                      {item}%
                    </StyledSlippageToleranceBtn>
                  ))}
                  <StyledLiquiditySettingsInput
                    value={slippageTolerance}
                    placeholder='0.5'
                    style={{
                      color: isErrorSlippagePercentage ? Color.RED : '',
                    }}
                    onChange={(event) => {
                      const result = event.target.value.replace(
                        /\.(?=.*\.)|[^\d\.-]/g,
                        ''
                      );
                      setSlippageTolerance(result);
                    }}
                    error={isErrorSlippagePercentage}
                    startAdornment={
                      (isTransactionMayBeFrontrun ||
                        isSlippageToleranceLessMinimum) && (
                        <InputAdornment position='start'>
                          <ReportProblemRounded sx={{ color: Color.YELLOW }} />
                        </InputAdornment>
                      )
                    }
                    endAdornment={
                      <InputAdornment position='end'>%</InputAdornment>
                    }
                    aria-describedby='outlined-weight-helper-text'
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </Stack>
              </Stack>
            </StyledListItem>
            {isTransactionMayBeFrontrun && (
              <StyledSmallSubtitle
                style={{
                  marginTop: '12px',
                  display: 'block',
                  color: Color.YELLOW,
                }}
              >
                {t('transactionFrontrun')}
              </StyledSmallSubtitle>
            )}
            {isSlippageToleranceLessMinimum && (
              <StyledSmallSubtitle
                style={{
                  marginTop: '12px',
                  display: 'block',
                  color: Color.YELLOW,
                }}
              >
                {t('transactionMayFail')}
              </StyledSmallSubtitle>
            )}
            {isErrorSlippagePercentage && (
              <StyledSmallSubtitle
                style={{
                  marginTop: '12px',
                  display: 'block',
                  color: Color.RED,
                }}
              >
                {t('errorSlippagePercentage')}
              </StyledSmallSubtitle>
            )}
            <StyledListItem style={{ padding: '6px 0 0' }}>
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                gap={1}
              >
                <StyledListItemText primary={t('liquidityDeadline')} />
                <Infotip
                  title={
                    <StyledInfotipText>
                      {t('liquidityDeadlineTip')}
                    </StyledInfotipText>
                  }
                  placement='top-start'
                >
                  <HelpOutlineRounded
                    fontSize='small'
                    sx={{ color: 'grey', '&:hover': { color: 'lightgrey' } }}
                  />
                </Infotip>
              </Stack>
              <StyledLiquidityDeadlineInput
                placeholder='30'
                value={transactionDeadline}
                style={{
                  color:
                    isTransactionDeadlineError &&
                    transactionDeadline.length !== 0
                      ? Color.RED
                      : '',
                }}
                onChange={(event) => {
                  const result = event.target.value.replace(/\D/g, '');
                  setTransactionDeadline(result);
                }}
                error={
                  isTransactionDeadlineError && transactionDeadline.length !== 0
                }
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </StyledListItem>
          </List>
          {isMobile && (
            <StyledButton
              variant='contained'
              fullWidth
              onClick={() => setOpen(false)}
            >
              {t('confirm')}
            </StyledButton>
          )}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
}
