import { ArrowBack, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { NFTCollection } from '@omisoftnet/game-nft-sdk';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';

import { useSigner } from '@hooks/useSigner';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { createOffer, fetchData } from 'config/api';
import NFT_MARKETPLACE from 'config/contracts';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { stableTokens } from 'helpers/nativeTokens';
import { Border, Color } from 'helpers/themeStyles';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { setTransactionHash } from 'state/transactions/slice';
import { SelectedNft } from 'types/nft';
import {
  StyledBackButton,
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledStepNumber,
  StyledStepTitle,
  StyledText,
  StylesDialogWrapper,
} from './styles';

export default function NFTEnableListing({
  open,
  onClose,
  price,
  closeParentPopup,
  totalSupply,
  selectedNFT,
  payableToken,
  payablesTokens,
  usdPrice,
}: {
  open: boolean;
  onClose: () => void;
  price: string;
  closeParentPopup: () => void;
  totalSupply: number;
  selectedNFT: SelectedNft;
  payableToken?: DEXToken;
  payablesTokens?: DEXToken[];
  usdPrice: string;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const signer = useSigner({ chainId: chain?.id ?? 1 });
  const ERC1155 = NFT_MARKETPLACE[chain!.id].contract;
  const NFTExchanger = NFT_MARKETPLACE[chain!.id].market;
  const NFT = new NFTCollection(signer!, ERC1155, NFTExchanger);
  const [enableConfirm, setEnableConfirm] = useState<boolean>(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState<boolean>(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState<boolean>(false);

  async function checkCollectionForAllowance() {
    const allowance = await NFT.allowance(address!);
    if (selectedNFT.nft.token_id === '0') {
      return setEnableConfirm(true);
    }
    setEnableConfirm(allowance);
  }

  async function approveCollection() {
    const tx = await NFT.approve();
    dispatch(
      setTransactionHash({
        hash: tx?.hash,
        from: tx?.from,
        to: tx?.to,
        owner: address!,
        transaction_type: 'approveNFT',
        data: {
          nft_data: {
            pay_amount: Number(utils.formatUnits(`${tx?.gasPrice!}`, 18)),
            payable_token: chain?.nativeCurrency.symbol,
            nft_image: formatIpfsUrl(selectedNFT?.nft.image[1]),
            nft_name: selectedNFT?.nft.name,
            nft_collection_name: selectedNFT?.nft.nft_collection_name,
          },
        },
      })
    );
    setEnableConfirm(true);
  }
  function roundCryptoValueString(str: string, decimalPlaces = 18) {
    const arr = str.split('.');
    const fraction = arr[1].substr(0, decimalPlaces);
    return arr[0] + '.' + fraction;
  }
  const sellNFT = async () => {
    const { data } = await fetchData(
      `nft-collection/one/${selectedNFT.nft.nft_collection}`
    );
    try {
      const sellNFT = await NFT.makeOffer({
        mongoTokenId: selectedNFT.nft._id,
        tokenId: Number(selectedNFT.nft.token_id),
        payAmount: utils.parseUnits(
          price,
          payableToken
            ? payableToken.decimals
            : payablesTokens
            ? payablesTokens[0].decimals
            : undefined
        ),
        payableToken: payableToken
          ? payableToken?.address
          : payablesTokens
          ? payablesTokens[0].address
          : undefined,
        tokenAmount: 1,
        deadline: Math.floor(Date.now() / 1000) + 60 * 1000000,
        totalSupply: BigNumber.from(`${totalSupply}`).toHexString(),
        royalties_addreses: data.nftCollection.royalties_address || address!,
        royalties_percent:
          String(
            (Number(data.nftCollection.royalties_percent) / 100) * 10000
          ) || '0',
      });
      const offer = {
        ...sellNFT[0],
        signature: sellNFT[1],
        pay_amount_usd: utils
          .parseUnits(usdPrice, stableTokens[chain?.id!].decimals)
          .toHexString(),
      };
      const result = await createOffer('/offers/create', offer);
      onClose();
      setTransactionSubmittedStatus(true);
    } catch (error) {
      console.error(error);
      onClose();
      setTransactionRejectedStatus(true);
    }
  };

  useEffect(() => {
    checkCollectionForAllowance();
  }, [signer]);

  return (
    <>
      <StyledDialog
        onClose={onClose}
        open={open}
      >
        <StyledDialogHeader>
          <StyledBackButton
            onClick={onClose}
            startIcon={<ArrowBack />}
          >
            {t('back')}
          </StyledBackButton>
          <Close
            onClick={onClose}
            sx={{ cursor: 'pointer' }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <StylesDialogWrapper
            style={{
              border: !enableConfirm ? Border.TRANSPARENT_BORDER : 'none',
              marginBottom: !enableConfirm ? '12px' : '',
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              mb={2.3}
            >
              <StyledStepNumber
                sx={{
                  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
                  color: Color.WHITE,
                }}
              >
                1
              </StyledStepNumber>
              <StyledStepTitle style={{}}>
                {enableConfirm ? t('enabled') : t('enable')}
              </StyledStepTitle>
            </Stack>

            {!enableConfirm && (
              <>
                <StyledText>{t('enableListingText')}</StyledText>
                <StyledButton
                  variant='outlined'
                  onClick={approveCollection}
                >
                  {t('enable')}
                </StyledButton>
              </>
            )}
          </StylesDialogWrapper>
          <StylesDialogWrapper
            style={{
              border: !enableConfirm ? 'none' : Border.TRANSPARENT_BORDER,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              mb={2.3}
            >
              <StyledStepNumber
                sx={{
                  background: !enableConfirm
                    ? Color.WHITE_OPACITY_LIGHT
                    : Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
                  color: Color.WHITE,
                }}
              >
                2
              </StyledStepNumber>
              <StyledStepTitle
                style={{
                  color: !enableConfirm
                    ? Color.WHITE_OPACITY_LIGHT
                    : Color.WHITE,
                }}
              >
                {t('confirm')}
              </StyledStepTitle>
            </Stack>
            <StyledText
              sx={{
                color: !enableConfirm ? Color.WHITE_OPACITY_LIGHT : Color.WHITE,
              }}
            >
              {t('confirmTransaction')}
            </StyledText>
            <StyledButton
              variant='outlined'
              disabled={!enableConfirm}
              onClick={sellNFT}
            >
              {t('confirm')}
            </StyledButton>
          </StylesDialogWrapper>
        </StyledDialogContent>
      </StyledDialog>
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => closeParentPopup()}
          result={transactionStatuses.SUBMIT}
          sellNft
          reloadPage
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
}
