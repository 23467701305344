import { createSelector } from 'reselect';
import { RootState } from '..';

const tokenState = (state: RootState) => state.token;
/**
 * @description Get data of first token
 */
export const firstTokenSelector = createSelector(
  tokenState,
  ({ firstToken }) => firstToken
);
/**
 * @description Get data of second token
 */
export const secondTokenSelector = createSelector(
  tokenState,
  ({ secondToken }) => secondToken
);
export const tokenListSelector = createSelector(
  tokenState,
  ({ tokenList }) => tokenList
);
export const topTokensSelector = createSelector(
  tokenState,
  ({ topTokens }) => topTokens
);
export const swapSettingsSelector = createSelector(
  tokenState,
  ({ swapSettings }) => swapSettings
);

export const openTopSwitchPanelSelector = createSelector(
  tokenState,
  ({ openTopSwitchPanel }) => openTopSwitchPanel
);

export const isDataLoadingSelected = createSelector(
  tokenState,
  ({ isDataLoading }) => isDataLoading
);
