import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PreviewCurrentProjects from 'components/Launchpad/Preview/CurrentProjects';
import PreviewUpcomingProjects from 'components/Launchpad/Preview/UpcomingProjects';
import PreviewCompletedProjects from 'components/Launchpad/Preview/CompletedProjects';
import launchpadSlice from 'state/launchpad/slice';
import { projectsByOwnerSelector } from 'state/launchpad/selectors';

const LaunchpadOverview = () => {
  const dispatch = useDispatch();
  const projectsByAddress = useSelector(projectsByOwnerSelector);

  useEffect(() => {
    // delete project id if the user returned to overview while creating a new project
    localStorage.removeItem('newProjectId');
    dispatch(launchpadSlice.actions.resetProjectId());
  }, []);

  return (
    <Stack my={projectsByAddress?.docs.length ? 5 : 1}>
      <PreviewCurrentProjects />
      <PreviewUpcomingProjects />
      <PreviewCompletedProjects />
    </Stack>
  );
};

export default LaunchpadOverview;
