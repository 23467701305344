import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/slice';
import swapSlice from './swap/slice';
import usersSlice from './users/slice';
import dexSlice from './dex/slice';
import transactionsSlice from './transactions/slice';
import networkSlice from './network/slice';
import liquiditySlice from './liquidity/slice';
import walletSlice from './walletconnect/slice';
import nftCollectionSlice from './nftCollections/slice';
import nftSlice from './nft/slice';
import launchpadSlice from './launchpad/slice';

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [swapSlice.name]: swapSlice.reducer,
    [dexSlice.name]: dexSlice.reducer,
    [transactionsSlice.name]: transactionsSlice.reducer,
    [networkSlice.name]: networkSlice.reducer,
    [liquiditySlice.name]: liquiditySlice.reducer,
    [walletSlice.name]: walletSlice.reducer,
    [nftCollectionSlice.name]: nftCollectionSlice.reducer,
    [nftSlice.name]: nftSlice.reducer,
    [launchpadSlice.name]: launchpadSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
