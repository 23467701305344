import { styled } from '@mui/material';
import { Color } from 'helpers/themeStyles';

export const StyledAside = styled('aside')(() => ({
  width: '444px',
  height: 'calc(100vh - 68px)',
  background: Color.BACKGROUND_MAIN,
  position: 'fixed',
  top: '68px',
  right: 0,
  zIndex: 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
