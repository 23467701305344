import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Messenger from 'components/Messanger';
import SwapModal from 'components/SwapModal';
import { StyledAside } from './styles';

const Aside: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <>
      {!isMobile && (
        <StyledAside>
          <SwapModal />
          <Messenger />
        </StyledAside>
      )}
    </>
  );
};

export default Aside;
