import { useTranslation } from 'react-i18next';

import Loader from 'shared/Loader';
import {
  StyledWaitingDialog,
  StyledWaitionDialogContent,
  StyledGridTitle,
  StyledGridInfo,
} from './styles';

const WaitingPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <StyledWaitingDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledWaitionDialogContent>
        <Loader />
        <StyledGridTitle item>{t('waitingForConfirmation')}</StyledGridTitle>
        <StyledGridInfo item>{t('confirmTransaction')}</StyledGridInfo>
      </StyledWaitionDialogContent>
    </StyledWaitingDialog>
  );
};

export default WaitingPopup;
