import { Box, Button, styled } from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from 'helpers/themeStyles';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 412,
  background: Color.BACKGROUND,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    width: 375,
  },
}));

export const ContentWrap = styled('div')(() => ({
  backgroundColor: Color.PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: '16px',
  margin: '16px',
  padding: '12px 14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TitleStyled = styled('p')({
  color: Color.WHITE_OPACITY_LIGHT,
  display: 'flex',
  columnGap: '10px',
  alignItems: 'center',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 'bold',
  margin: 0,
  marginBottom: '15px',
});

export const SubtitleStyled = styled('p')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledLink = styled('a')({
  fontFamily: FontFamily.INTER,
  cursor: 'pointer',
  color: Color.WHITE_OPACITY_LIGHT,
});

export const TextContent = styled('div')({
  width: '88%',
});

export const StyledButton = styled(Button)({
  background:
    'linear-gradient(90deg, #F89D2F 0%, #E63E36 53.12%, #C81FAD 100%)',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  width: 'calc(100% - 32px)',
  height: '56px',
  margin: '32px 16px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  '&:hover': {
    background:
      'linear-gradient(90deg, #C81FAD 0%, #E63E36 53.12%, #F89D2F 100%)',
  },
});
