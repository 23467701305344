export function roundNumberInSwap(num: number): number | string {
  if (!num) return '';
  if (Math.abs(num) >= 1) {
    return num.toFixed(3);
  } else if (Math.abs(num) >= 0.000000000001) {
    return Number(num.toPrecision(12))
      .toFixed(15)
      .replace(/\.?0+$/, '');
  } else {
    return num.toPrecision(15).replace(/\.?0+$/, '');
  }
}
