import {
  Contract as MulticallContract,
  Provider as MulticallProvider,
} from 'ethers-multicall';
import { BigNumberish } from 'ethers';

import { useAccount, useClient } from 'wagmi';
import { erc20Abi } from 'viem';
import { useMemo, useState } from 'react';
import { ADDRESS_ZERO } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { tokenListSelector } from 'state/swap/selectors';
import { useEthersProvider } from './useProvider';
export type BalancesParams = {
  fetchData: boolean;
  isOpen: boolean;
};
export type BalancesResult = {
  [key: string]: BigNumberish;
};
export const useBalances = (params: BalancesParams) => {
  const { fetchData, isOpen } = params;
  const { address: wallet, chain } = useAccount();
  const tokenList = useSelector(tokenListSelector);
  const provider = useEthersProvider({ chainId: chain?.id ?? 1 });
  const [results, setResults] = useState<BalancesResult | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const isNative = (address: string) => {
    if (address === ADDRESS_ZERO) return true;
    return false;
  };
  const tokenAddresses = useMemo(() => {
    if (tokenList) return tokenList.map((el) => el.address);
  }, [tokenList]);
  useMemo(() => {
    if (!wallet || !tokenAddresses?.length || isLoading || !isOpen || !provider)
      return;
    setIsLoading(true);
    const multicallProvider = new MulticallProvider(provider, chain?.id);
    const calls = tokenAddresses.map((address) => {
      if (isNative(address)) {
        return multicallProvider.getEthBalance(wallet as string);
      }
      const contract = new MulticallContract(address, erc20Abi as any);
      return contract.balanceOf(wallet);
    });
    if (calls && calls.length) {
      multicallProvider
        .all(calls)
        .then((data) => {
          let obj: BalancesResult = {};
          data.map((item, index) => {
            return (obj[tokenAddresses[index]] = item);
          });
          setResults(obj);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [wallet, fetchData, isOpen, tokenAddresses]);

  return { results, isLoading };
};
