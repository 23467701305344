import { useMediaQuery, useTheme } from '@mui/material';
import NetworkSelector from 'components/NetworkSelector';
import WalletPopup from 'components/WalletPopup';
import { StyledAppBar } from './styles';
import { StyledLink } from 'components/NavBar/styles';
import LogoIcon from 'svg/LogoIcon';
import * as React from 'react';

const Header = ({ isConnected }: { isConnected: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <StyledAppBar
      position='fixed'
      style={{
        padding: isMobile
          ? '8px 16px'
          : '' || isConnected
          ? '14px 16px'
          : '10px 16px',
      }}
    >
      {isMobile && (
        <StyledLink to='/'>
          <LogoIcon />
        </StyledLink>
      )}
      <NetworkSelector isConnected={isConnected} />
      <WalletPopup isConnectedProp={isConnected} />
    </StyledAppBar>
  );
};

export default Header;
