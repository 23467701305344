import MarkdownIt from 'markdown-it';
import React, { useMemo } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

import { EditorChangeArgs } from 'types/editorChangeArgs';
import './styles.css';

const Editor = React.memo(
  ({
    handleEditorChange,
    height,
    width,
    marginBottom0,
    value,
  }: {
    handleEditorChange: ({ html, text }: EditorChangeArgs) => void;
    height: string;
    width: string;
    marginBottom0?: boolean | undefined;
    value?: string;
  }) => {
    const mdParser = useMemo(
      () => new MarkdownIt(/* Markdown-it options */),
      []
    );
    return (
      <MdEditor
        style={{
          height: height,
          width: width,
          marginBottom: marginBottom0 ? '0px' : '40px',
        }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        view={{ html: false, menu: true, md: true }}
        markdownClass='markdown'
        htmlClass='preview'
        value={value}
      />
    );
  }
);
export default Editor;
