import React from 'react';

const LogoutIcon: React.FC = () => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.0504 13.4751C13.8837 13.3084 13.8004 13.1043 13.8004 12.8626C13.8004 12.6209 13.8837 12.4168 14.0504 12.2501L15.9504 10.3501H8.15039C7.91706 10.3501 7.71706 10.2668 7.55039 10.1001C7.38372 9.93343 7.30039 9.73343 7.30039 9.5001C7.30039 9.2501 7.38372 9.04593 7.55039 8.8876C7.71706 8.72926 7.91706 8.6501 8.15039 8.6501H15.9004L13.9754 6.7251C13.8254 6.5751 13.7504 6.38343 13.7504 6.1501C13.7504 5.91676 13.8337 5.70843 14.0004 5.5251C14.1671 5.35843 14.3712 5.2751 14.6129 5.2751C14.8546 5.2751 15.0587 5.35843 15.2254 5.5251L18.6254 8.9251C18.7087 9.0251 18.7712 9.1251 18.8129 9.2251C18.8546 9.3251 18.8754 9.4251 18.8754 9.5251C18.8754 9.64176 18.8546 9.74593 18.8129 9.8376C18.7712 9.92926 18.7087 10.0251 18.6254 10.1251L15.2504 13.5001C15.1004 13.6501 14.9087 13.7251 14.6754 13.7251C14.4421 13.7251 14.2337 13.6418 14.0504 13.4751ZM2.50039 18.6501C2.03372 18.6501 1.62956 18.4793 1.28789 18.1376C0.946224 17.7959 0.775391 17.4001 0.775391 16.9501V2.0501C0.775391 1.58343 0.946224 1.18343 1.28789 0.850098C1.62956 0.516764 2.03372 0.350098 2.50039 0.350098H8.95039C9.20039 0.350098 9.40456 0.433431 9.56289 0.600098C9.72123 0.766764 9.80039 0.966764 9.80039 1.2001C9.80039 1.4501 9.72123 1.65426 9.56289 1.8126C9.40456 1.97093 9.20039 2.0501 8.95039 2.0501H2.50039V16.9501H8.95039C9.20039 16.9501 9.40456 17.0334 9.56289 17.2001C9.72123 17.3668 9.80039 17.5668 9.80039 17.8001C9.80039 18.0501 9.72123 18.2543 9.56289 18.4126C9.40456 18.5709 9.20039 18.6501 8.95039 18.6501H2.50039Z'
        fill='white'
      />
    </svg>
  );
};

export default LogoutIcon;
