import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM, IResetPassword } from 'types/statuses';
import { StatusGenerator } from 'utils/StatusGenerator';

interface IState {
  user: object | null;
}

const initialState: IState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => builder,
});

export default authSlice;
