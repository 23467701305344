import { useTranslation } from 'react-i18next';

import { Stack, Modal, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Config, useAccount, useConnect } from 'wagmi';
import { watchAccount } from '@wagmi/core';
// import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
// import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
// import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';

// import { useWeb3Modal } from '@web3modal/react';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  StyledAvatar,
  StyledConnectItem,
  StyledModalContent,
  StyledModalFooter,
  StyledModalFooterText,
  StyledModalLink,
  StyledModalTitle,
  ContentWrap,
  ConnectItemWrap,
} from './styles';
import { Color, TextSize } from 'helpers/themeStyles';
import { dexSelector } from 'state/dex/selectors';
import { walletPopUpIsOpenSelector } from 'state/walletconnect/selectors';
import { setIsOpen } from 'state/walletconnect/slice';
import WelcomeNftPopup from 'components/WelcomeNftPopup';
import CoinBaseIcon from 'shared/wallet-connect/coinbaseWalletIcon.svg';
import MetaMaskIcon from 'shared/wallet-connect/metamask.png';
import WalletConnectIcon from 'shared/wallet-connect/walletConnect.png';
import { bsc } from 'wagmi/chains';
import { config } from 'config/wagmiConfig';
export default function ConnectWalletModal({
  setMobileWarning,
}: {
  setMobileWarning: (newState: boolean) => void;
}) {
  const { t } = useTranslation();
  const dex = useSelector(dexSelector);
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const isWeb3Open = useSelector(walletPopUpIsOpenSelector);
  // const { open: openWeb3 } = useWeb3Modal();
  const { connect, connectors } = useConnect({
    // onSuccess: () => {
    //   setIsAuthOpen(true);
    // },
  });
  const { address, isConnected } = useAccount();
  console.log(isConnected);

  // useEffect(() => {
  //   if (!isConnected) {
  //     if (window.ethereum) {
  //       window.ethereum
  //         .request({ method: 'eth_requestAccounts' })
  //         .then(() => {
  //           const connector = new MetaMaskConnector({
  //             chains: dex.wallet.chains,
  //             options: {
  //               shimDisconnect: true,
  //             },
  //           });
  //           connect({ connector });
  //         })
  //         .catch((err: any) => {
  //           if (err.code === 4001) {
  //             console.log('Please connect to MetaMask.');
  //           } else {
  //             console.error(err);
  //           }
  //         });
  //     }
  //   }
  // }, [isConnected]);

  const dispatch = useDispatch();
  // watchAccount((account) => {
  //   if (
  //     account.address &&
  //     localStorage.getItem('currentWalletAddress') !== account.address
  //   ) {
  //     setIsAuthOpen(true);
  //   }
  // });
  watchAccount(config, {
    onChange(data) {
      if (data.address && !localStorage.getItem('currentWalletAddress')) {
        setIsAuthOpen(true);
      }
      if (
        data.address &&
        localStorage.getItem('currentWalletAddress') !== data.address
      ) {
        setIsAuthOpen(true);
      }
    },
  });
  const handleClose = () => {
    dispatch(setIsOpen(false));
  };
  // const handleConnect = (wallet: string) => {
  //   const browser = navigator.userAgent;
  //   let connector;
  //   switch (wallet) {
  //     case 'metamask':
  //       if (
  //         // !window.ethereum &&
  //         window.innerWidth <= 767 &&
  //         !browser.includes('MetaMask')
  //       ) {
  //         setMobileWarning(true);
  //       }
  //       connector = new MetaMaskConnector({
  //         chains: dex.wallet.wagmi.chains,
  //         options: {
  //           shimDisconnect: true,
  //         },
  //       });
  //       break;
  //     case 'coinbase':
  //       connector = new CoinbaseWalletConnector({
  //         chains: dex.wallet.wagmi.chains,
  //         options: { appName: '' },
  //       });
  //       break;
  //     case 'walletconnect':
  //       connector = new WalletConnectConnector({
  //         chains: [mainnet, bsc],
  //         options: {
  //           version: '2',
  //           projectId: '3c4f8c2124439727876ab8f3e39dfa1e',
  //         } as never,
  //       });
  //       break;
  //     default:
  //       console.error('Unsupported wallet chosen!');
  //       break;
  //   }
  //   if (connector) {
  //     console.log(`${connector} connect`);
  //     connect({ connector });
  //   }
  //   handleClose();
  // };

  useEffect(() => {
    if (address && dex) {
      console.log('Fetching positions');
      dex.liquidityPositions(address!);
    }
  }, [address]);
  console.log(connectors);
  return (
    <>
      <Modal
        open={isWeb3Open}
        onClose={handleClose}
      >
        <StyledModalContent>
          <ContentWrap>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                padding: '16px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
              }}
            >
              <StyledModalTitle style={{ fontSize: TextSize.MIDDLE }}>
                {t('connectAWallet')}
              </StyledModalTitle>
              <IconButton
                sx={{ p: 0, color: Color.WHITE }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack
              style={{ padding: '16px' }}
              gap={1.5}
            >
              {' '}
              {connectors.slice(0, -1).map((connector) => {
                return (
                  <StyledConnectItem
                    onClick={() => {
                      connect({ connector });
                      handleClose();
                    }}
                  >
                    <ConnectItemWrap>
                      <StyledAvatar
                        src={
                          connector.name === 'MetaMask'
                            ? MetaMaskIcon
                            : connector.name === 'WalletConnect'
                            ? WalletConnectIcon
                            : CoinBaseIcon
                        }
                        alt='metamask'
                      />
                      <StyledModalTitle>{connector.name}</StyledModalTitle>
                    </ConnectItemWrap>
                  </StyledConnectItem>
                );
              })}
              {/* <StyledConnectItem onClick={() => {}}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={MetaMaskIcon}
                    alt='metamask'
                  />
                  <StyledModalTitle>{t('metaMask')}</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
              <StyledConnectItem onClick={() => handleConnect('coinbase')}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={CoinBaseIcon}
                    alt='metamask'
                  />
                  <StyledModalTitle>{t('coinbase')}</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
              <StyledConnectItem onClick={() => handleConnect('walletconnect')}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={WalletConnectIcon}
                    alt='metamask'
                  />
                  <StyledModalTitle>{t('walletConnect')}</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem> */}
            </Stack>
            <StyledModalFooter>
              <StyledModalFooterText>
                {t('byConnecting')} <br />
                <StyledModalLink to=''>
                  {t('termsOfService')}
                </StyledModalLink>{' '}
                {t('andConsentTo')}{' '}
                <StyledModalLink to=''>{t('privacyPolicy')}</StyledModalLink>.
              </StyledModalFooterText>
            </StyledModalFooter>
          </ContentWrap>
        </StyledModalContent>
      </Modal>
      <WelcomeNftPopup
        isOpen={isAuthOpen}
        onClose={() => {
          setIsAuthOpen(false);
        }}
        isConnecting
      />
    </>
  );
}
