import { Offer, Signature } from '@omisoftnet/game-nft-sdk';
import { disconnect } from '@wagmi/core';
import axios from 'axios';
import { API_ROUTES } from 'config/apiRoutes';
import { BASE_URL } from 'helpers/constants';
import { LocalStorage } from 'utils/LocalStorage';
import { config } from './wagmiConfig';

/**
 * @description Axios instance used for API requests
 */
export const API = axios.create({
  baseURL: BASE_URL,
  // baseURL: 'http://localhost:5050/',
  // baseURL: 'https://api.planetex.io/',
});

API.interceptors.request.use((config) => {
  const accessToken = LocalStorage.getAccessToken();
  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers['Accept-Language'] = 'en-US';
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.data.error === 'Token expired!') {
      // in case if refresh token is expired
      window.location.replace('');
      await disconnect(config);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
    if (error.response.status !== 401) return Promise.reject(error);
    const tokens = await refreshToken();
    LocalStorage.setAccessToken(tokens.data.accessToken);
    LocalStorage.setRefreshToken(tokens.data.refreshToken);
    //retry last request which responsed with 401 error
    API(error.config);
  }
);
export const refreshToken = async () => {
  return await API.post(API_ROUTES.AUTH.REFRESH_TOKEN, {
    refreshToken: LocalStorage.getRefreshToken(),
  });
};
export const fetchData = async (endpoint: string) => {
  return await API.get(endpoint);
};

// Boilerplate code
// export const createLiquidity = async (
//   endpoint: string,
//   query: {
//     user: string;
//     amountA: string;
//     amountB: string;
//     tokenAAdress: string;
//     tokenBAdress: string;
//     liquidity: string;
//   }
// ) => {
//   const response = await API.post(endpoint, query);
//   return response;
// };

export const updateLiquidity = async (
  endpoint: string,
  query: {
    user: string;
    amountA: string;
    amountB: string;
    tokenAAdress: string;
    tokenBAdress: string;
    liquidity: string;
  }
) => {
  if (endpoint.includes('undefined')) {
    return await API.post(
      endpoint.replace('/update/undefined', '/create/'),
      query
    );
  } else {
    return await API.put(endpoint, query);
  }
};

export const deleteLiquidity = async (endpoint: string) => {
  return await API.delete(endpoint);
};

export const createNftOrNftCollection = async (
  endpoint: string,
  query: FormData
) => {
  const response = await API.post(endpoint, query);
  return response;
};

interface IOffer extends Offer {
  signature: Signature;
}
export const createOffer = async (endpoint: string, query: IOffer) => {
  return await API.post(endpoint, query);
};

export const getAllNfts = async (
  endpoint: string,
  params?: { limit?: number; page?: number }
) => {
  return await API.get(endpoint, { params });
};

export const fetchNFTCollections = async (
  endpoint: string,
  params?: { limit?: number; page?: number; price?: string }
) => {
  return await API.get(endpoint, { params });
};

export const fetchTransactionsHistory = async (
  endpoint: string,
  params?: { limit?: number; page?: number }
) => {
  return await API.get(endpoint, { params });
};

export const fetchOffer = async (
  endpoint: string,
  params?: { from?: string }
) => {
  return await API.get(endpoint, { params });
};
export const createNftCollection = async (
  endpoint: string,
  query: FormData
) => {
  return await API.post(endpoint, query);
};
