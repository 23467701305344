import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, useMediaQuery, useTheme } from '@mui/material';
import { getLinkForTransaction } from '@omisoftnet/game-dex-sdk';
import { format } from 'date-fns';

import { CustomPaginationItem } from 'components/NftDetails/styles';
import { formatWalletKey } from 'helpers/formatWalletKey';
import { Color } from 'helpers/themeStyles';
import DefaultTokenIcon from 'svg/DefaultTokenIcon';
import { scrollToRef } from 'helpers/scrollToRef';
import {
  AvatarWrap,
  CardWrap,
  IconWrap,
  PaginationWrap,
  RowWrap,
  SmallText,
  TableItemStyled,
  TableItemWrap,
  TableTitleStyled,
  TableTitleWrap,
  TitleText,
} from './styles';

const BiggestTransactionsCard = ({
  page,
  setPage,
  totalPages,
  transactions,
  mobileTransactions,
}: {
  page: number;
  setPage: Function;
  totalPages: number;
  transactions: any;
  mobileTransactions: any;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const transactionHistoryRef = useRef<HTMLDivElement>(null);

  const [firstTokenImgError, setFirstTokenImgError] = useState<boolean[]>([]);
  const [secondTokenImgError, setSecondTokenImgError] = useState<boolean[]>([]);

  const isNFT = (title: string) => {
    if (
      title === 'buyNFT' ||
      title === 'approveNFT' ||
      title === 'cancelOffer'
    ) {
      return true;
    } else return false;
  };

  const getEventTitle = (title: string) => {
    if (title === 'buyNFT') {
      return 'Bought';
    } else if (title === 'cancelOffer') {
      return 'Canceled';
    } else if (title === 'approveNFT') {
      return 'Approved';
    } else if (title === 'addLiquidity') {
      return 'Added';
    } else if (title === 'removeLiquidity') {
      return 'Removed';
    } else if (title === 'collectFees') {
      return 'Collected';
    } else if (title === 'approve') {
      return 'Approved';
    } else if (title === 'swap') {
      return 'Swapped';
    } else return '-';
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    scrollToRef(transactionHistoryRef, -120);
  };

  const updateTokenImageErrors = (index: number, firstTokenFlag: boolean) => {
    firstTokenFlag
      ? setFirstTokenImgError((prevErrorStates) => {
          const newErrorStates = [...prevErrorStates];
          newErrorStates[index] = true;
          return newErrorStates;
        })
      : setSecondTokenImgError((prevErrorStates) => {
          const newErrorStates = [...prevErrorStates];
          newErrorStates[index] = true;
          return newErrorStates;
        });
  };

  useEffect(() => {
    setFirstTokenImgError([]);
    setSecondTokenImgError([]);
  }, [page, transactions]);

  return (
    <>
      <div ref={transactionHistoryRef}>
        <CardWrap style={{ borderCollapse: 'collapse' }}>
          {
            <>
              <thead>
                <TableTitleWrap>
                  <TableTitleStyled>{t('item')}</TableTitleStyled>
                  <TableTitleStyled>{t('event')}</TableTitleStyled>
                  <TableTitleStyled>{t('price')}</TableTitleStyled>
                  {!isMobile && (
                    <>
                      <TableTitleStyled>{t('from')}</TableTitleStyled>
                      <TableTitleStyled>{t('to')}</TableTitleStyled>
                      <TableTitleStyled style={{ textAlign: 'center' }}>
                        {t('date')}
                      </TableTitleStyled>
                    </>
                  )}
                </TableTitleWrap>
              </thead>
              <tbody>
                {!isMobile && transactions.length
                  ? //@ts-ignore
                    transactions?.map((item, index) => {
                      return (
                        <TableItemWrap
                          key={item._id + index}
                          onClick={() =>
                            window.open(
                              getLinkForTransaction(
                                Number(item?.chainId),
                                item?.hash
                              )
                            )
                          }
                        >
                          <TableItemStyled>
                            <RowWrap>
                              <AvatarWrap>
                                {isNFT(item.transaction_type) ? (
                                  <img
                                    style={{ borderRadius: '8px' }}
                                    width={64}
                                    height={64}
                                    alt=''
                                    src={item.data.nft_data.nft_image}
                                  />
                                ) : (
                                  <>
                                    {firstTokenImgError[index] ? (
                                      <img
                                        width={24}
                                        height={24}
                                        alt=''
                                        src={
                                          ['1', '5'].includes(item.chainId)
                                            ? './images/default_eth_icon.webp'
                                            : './images/default_bnb_icon.webp'
                                        }
                                      />
                                    ) : (
                                      item.data.token_data?.token0?.logoURI && (
                                        <img
                                          width={24}
                                          height={24}
                                          alt=''
                                          src={
                                            item.data.token_data?.token0
                                              ?.logoURI
                                          }
                                          onError={() =>
                                            updateTokenImageErrors(index, true)
                                          }
                                        />
                                      )
                                    )}

                                    {secondTokenImgError[index] ? (
                                      <img
                                        width={24}
                                        height={24}
                                        alt=''
                                        src={
                                          ['1', '5'].includes(item.chainId)
                                            ? './images/default_eth_icon.webp'
                                            : './images/default_bnb_icon.webp'
                                        }
                                      />
                                    ) : (
                                      item.data.token_data?.token1?.logoURI && (
                                        <img
                                          width={24}
                                          height={24}
                                          style={{ marginLeft: '-5px' }}
                                          alt=''
                                          src={
                                            item.data.token_data?.token1
                                              ?.logoURI
                                          }
                                          onError={() =>
                                            updateTokenImageErrors(index, false)
                                          }
                                        />
                                      )
                                    )}
                                  </>
                                )}
                              </AvatarWrap>
                              <div>
                                {isNFT(item.transaction_type) &&
                                  item.data.nft_data.nft_collection_name && (
                                    <SmallText>
                                      {item.data.nft_data.nft_collection_name}
                                    </SmallText>
                                  )}

                                <TitleText
                                  style={{
                                    marginTop: '6px',
                                    fontWeight: '700',
                                  }}
                                >
                                  {isNFT(item.transaction_type)
                                    ? item.data?.nft_data?.nft_name
                                    : item.data?.token_data?.token0?.symbol &&
                                      item.data?.token_data?.token1?.symbol
                                    ? `${item.data?.token_data?.token0?.symbol}/${item.data?.token_data?.token1?.symbol}`
                                    : item.data?.token_data?.token0?.symbol
                                    ? `${item.data?.token_data?.token0?.symbol}`
                                    : `${item.data?.token_data?.token1?.symbol}`}
                                </TitleText>
                              </div>
                            </RowWrap>
                          </TableItemStyled>
                          <TableItemStyled>
                            <TitleText>
                              {getEventTitle(item?.transaction_type)}
                            </TitleText>
                          </TableItemStyled>
                          <TableItemStyled>
                            <RowWrap>
                              {!isNFT(item.transaction_type) ? (
                                firstTokenImgError[index] ||
                                secondTokenImgError[index] ? (
                                  <img
                                    width={24}
                                    height={24}
                                    alt=''
                                    src={
                                      ['1', '5'].includes(item.chainId)
                                        ? './images/default_eth_icon.webp'
                                        : './images/default_bnb_icon.webp'
                                    }
                                  />
                                ) : (
                                  <img
                                    width={24}
                                    height={24}
                                    alt=''
                                    src={
                                      item.data.token_data?.token0?.logoURI ||
                                      item.data.token_data?.token1?.logoURI
                                    }
                                  />
                                )
                              ) : item.data?.nft_data?.token_image ? (
                                <img
                                  width={24}
                                  height={24}
                                  alt=''
                                  src={item.data.nft_data.token_image}
                                />
                              ) : (
                                <DefaultTokenIcon />
                              )}
                              <TitleText
                                style={{
                                  marginBottom: '6px',
                                  marginLeft: '8px',
                                  fontWeight: '700',
                                }}
                              >
                                {getEventTitle(item.transaction_type)}{' '}
                                {!isNFT(item.transaction_type) &&
                                item.data.token_data?.token0Amount
                                  ? `${Number(
                                      item.data.token_data?.token0Amount
                                    ).toFixed(7)} ${
                                      item.data.token_data?.token0?.symbol
                                    }`
                                  : item.data.nft_data.pay_amount
                                  ? Number(
                                      item.data.nft_data.pay_amount
                                    ).toFixed(8)
                                  : '-'}
                                <br />
                                {!isNFT(item.transaction_type) &&
                                item.data.token_data?.token1Amount
                                  ? `and ${Number(
                                      item.data.token_data?.token1Amount
                                    ).toFixed(7)} ${
                                      item.data.token_data?.token1?.symbol
                                    }`
                                  : item.data.nft_data.dollar_price
                                  ? Number(
                                      item.data.nft_data.dollar_price
                                    ).toFixed(2)
                                  : ''}
                              </TitleText>
                            </RowWrap>
                            <SmallText style={{ marginLeft: '32px' }}>
                              {item?.priceInDol}
                            </SmallText>
                          </TableItemStyled>
                          <TableItemStyled>
                            <TitleText>{formatWalletKey(item?.from)}</TitleText>
                          </TableItemStyled>
                          <TableItemStyled>
                            <TitleText>{formatWalletKey(item?.to)}</TitleText>
                          </TableItemStyled>
                          <TableItemStyled style={{ textAlign: 'center' }}>
                            <TitleText style={{ marginBottom: '6px' }}>
                              {format(new Date(item?.createdAt), 'dd/MM/yyyy')}
                            </TitleText>
                            <SmallText>
                              {format(new Date(item?.createdAt), 'hh:mm')}
                            </SmallText>
                          </TableItemStyled>
                        </TableItemWrap>
                      );
                    })
                  : //@ts-ignore
                    mobileTransactions?.map((item, index) => {
                      return (
                        <TableItemWrap
                          key={item._id + index}
                          onClick={() =>
                            window.open(
                              getLinkForTransaction(
                                Number(item?.chainId),
                                item?.hash
                              )
                            )
                          }
                        >
                          <TableItemStyled>
                            <RowWrap>
                              <AvatarWrap>
                                {isNFT(item.transaction_type) ? (
                                  <img
                                    style={{ borderRadius: '8px' }}
                                    width={64}
                                    height={64}
                                    alt=''
                                    src={item.data.nft_data.nft_image}
                                  />
                                ) : (
                                  <>
                                    {firstTokenImgError[index] ? (
                                      <img
                                        width={24}
                                        height={24}
                                        alt=''
                                        src={
                                          ['1', '5'].includes(item.chainId)
                                            ? './images/default_eth_icon.webp'
                                            : './images/default_bnb_icon.webp'
                                        }
                                      />
                                    ) : (
                                      item.data.token_data?.token0?.logoURI && (
                                        <img
                                          width={24}
                                          height={24}
                                          alt=''
                                          src={
                                            item.data.token_data?.token0
                                              ?.logoURI
                                          }
                                          onError={() =>
                                            updateTokenImageErrors(index, true)
                                          }
                                        />
                                      )
                                    )}
                                    {secondTokenImgError[index] ? (
                                      <img
                                        width={24}
                                        height={24}
                                        alt=''
                                        src={
                                          ['1', '5'].includes(item.chainId)
                                            ? './images/default_eth_icon.webp'
                                            : './images/default_bnb_icon.webp'
                                        }
                                      />
                                    ) : (
                                      item.data.token_data?.token1?.logoURI && (
                                        <img
                                          width={24}
                                          height={24}
                                          style={{ marginLeft: '-5px' }}
                                          alt=''
                                          src={
                                            item.data.token_data?.token1
                                              ?.logoURI
                                          }
                                          onError={() =>
                                            updateTokenImageErrors(index, false)
                                          }
                                        />
                                      )
                                    )}
                                  </>
                                )}
                              </AvatarWrap>
                              <div>
                                {isNFT(item.transaction_type) &&
                                  item.data.nft_data.nft_collection_name && (
                                    <SmallText>
                                      {item.data.nft_data.nft_collection_name}
                                    </SmallText>
                                  )}
                                <TitleText
                                  style={{
                                    marginTop: '0',
                                    fontWeight: '700',
                                  }}
                                >
                                  {isNFT(item.transaction_type)
                                    ? item.data?.nft_data?.nft_name
                                    : item.data?.token_data?.token0?.symbol &&
                                      item.data?.token_data?.token1?.symbol
                                    ? `${item.data?.token_data?.token0?.symbol}/${item.data?.token_data?.token1?.symbol}`
                                    : item.data?.token_data?.token0?.symbol
                                    ? `${item.data?.token_data?.token0?.symbol}`
                                    : `${item.data?.token_data?.token1?.symbol}`}
                                </TitleText>
                              </div>
                            </RowWrap>
                          </TableItemStyled>
                          <TableItemStyled>
                            <TitleText>
                              {getEventTitle(item.transaction_type)}
                            </TitleText>
                          </TableItemStyled>
                          <TableItemStyled>
                            <RowWrap>
                              {!isNFT(item.transaction_type) ? (
                                firstTokenImgError[index] ||
                                secondTokenImgError[index] ? (
                                  <img
                                    width={24}
                                    height={24}
                                    alt=''
                                    src={
                                      ['1', '5'].includes(item.chainId)
                                        ? './images/default_eth_icon.webp'
                                        : './images/default_bnb_icon.webp'
                                    }
                                  />
                                ) : (
                                  <img
                                    width={24}
                                    height={24}
                                    alt=''
                                    src={
                                      item.data.token_data?.token0?.logoURI ||
                                      item.data.token_data?.token1?.logoURI
                                    }
                                  />
                                )
                              ) : item.data?.nft_data?.token_image ? (
                                <img
                                  width={24}
                                  height={24}
                                  alt=''
                                  src={item.data.nft_data.token_image}
                                />
                              ) : (
                                <DefaultTokenIcon />
                              )}
                              <TitleText
                                style={{
                                  marginBottom: '6px',
                                  marginLeft: '8px',
                                  fontWeight: '700',
                                }}
                              >
                                {getEventTitle(item.transaction_type)}{' '}
                                {!isNFT(item.transaction_type) &&
                                item.data.token_data?.token0Amount
                                  ? `${Number(
                                      item.data.token_data?.token0Amount
                                    ).toFixed(6)}.. ${
                                      item.data.token_data?.token0?.symbol
                                    }`
                                  : item.data.nft_data.pay_amount
                                  ? Number(
                                      item.data.nft_data.pay_amount
                                    ).toFixed(6) + '..'
                                  : '-'}
                                <br />
                                {!isNFT(item.transaction_type) &&
                                item.data.token_data?.token1Amount
                                  ? `and ${Number(
                                      item.data.token_data?.token1Amount
                                    ).toFixed(6)}.. ${
                                      item.data.token_data?.token1?.symbol
                                    }`
                                  : item.data.nft_data.dollar_price
                                  ? Number(
                                      item.data.nft_data.dollar_price
                                    ).toFixed(2)
                                  : ''}
                              </TitleText>
                            </RowWrap>
                            <SmallText style={{ marginLeft: '32px' }}>
                              {item?.priceInDol}
                            </SmallText>
                          </TableItemStyled>
                        </TableItemWrap>
                      );
                    })}
              </tbody>
            </>
          }
        </CardWrap>
      </div>
      {transactions?.length && (
        <PaginationWrap>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            sx={{
              '& .MuiPagination-ul': {
                justifyContent: 'center',
              },
              '& .MuiButtonBase-root': {
                color: Color.WHITE,
              },
            }}
          />
        </PaginationWrap>
      )}
    </>
  );
};
export default BiggestTransactionsCard;
