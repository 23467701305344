import { Button, Dialog, DialogTitle, Stack, styled } from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '412px',
    borderRadius: BorderRadius.SMALL,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#161616, #161616), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,

    [theme.breakpoints.down('mobile')]: {
      margin: '24px',
    },
  },
}));

export const StyledDialogHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: Border.ACCENT_BORDER,
}));

export const StyledDialogContent = styled(Stack)(({ theme }) => ({
  padding: '24px 24px 0 24px',

  [theme.breakpoints.down('mobile')]: {
    padding: '24px 16px 0px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontWeight: 700,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
}));

export const TitleStyled = styled('h2')(({ theme }) => ({
  fontSize: TextSize.NORMAL,
  fontWeight: 700,
  margin: '5px 0',
  color: Color.WHITE,
  display: 'flex',
  alignItems: 'center',
  columnGap: '5px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const PaymentFiatValue = styled('h3')(() => ({
  margin: 0,
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  textAlign: 'end',
  color: Color.WHITE_OPACITY_LIGHT,
}));

export const IconStyled = styled('img')(() => ({
  marginRight: '16px',
  width: '68px',
  height: '68px',
  borderRadius: '4px',
}));

export const SubTitleStyled = styled('span')(() => ({
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
}));

export const StyledValue = styled('span')(() => ({
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
  background: Color.TEXT_GRADIENT,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

export const StyledImg = styled('img')(() => ({
  height: '64px',
  width: '64px',
  objectFit: 'cover',
}));

export const CollectionPreview = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '14px',
}));

export const PaymentDetailsWrap = styled('div')(() => ({
  margin: '16px 0',
  padding: '16px',
  background: Color.DARK_PURPLE_OPACITY,
  borderRadius: '8px',
}));

export const RowStyled = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '8px 0',
}));

export const PaymentText = styled('span')(({ theme }) => ({
  fontSize: TextSize.MIDDLE,
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const PaymentCount = styled('span')(() => ({
  fontSize: '13px',
  color: '#7a6eaa',
}));

export const TokenSwitch = styled('div')(() => ({
  height: '26px',
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
}));

export const BuyStyled = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '18px 60px',
  display: 'flex',
  background: Color.TEXT_GRADIENT,
  alignItems: 'center',
  cursor: 'pointer',

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
    justifyContent: 'center',
  },
}));

export const BlackBuyStyled = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '18px 60px',
  display: 'flex',
  background: Color.BACKGROUND,
  border: `2px solid ${Color.PURPLE}`,
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down(1300)]: {
    padding: '12px 30px',
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '10px 20px',
  },
}));
export const WarningWrap = styled('div')(() => ({
  padding: '8px',
  borderRadius: BorderRadius.NORMAL,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: Border.ACCENT_BORDER,
  backgroundColor: Color.WARNING_NFT,
}));

export const WarningTextStyled = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 400,
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
  marginLeft: '8px',
}));

export const IconCoin = styled('img')(() => ({
  width: '16px',
  height: '16px',
  borderRadius: '8px',
  marginRight: '8px',
}));

export const StyledCheckoutButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '12px 0',
  margin: '16px 0',
  color: Color.WHITE,
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));

export const StyledGetButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `2px solid ${Color.PURPLE}`,
  padding: '12px 0',
  margin: '16px 0',
  color: Color.PURPLE,
  '&.MuiButton-root': {
    background: Color.BACKGROUND,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));
