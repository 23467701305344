import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './state';
import { Provider } from 'react-redux';

import './index.css';
import './slick.css';
import './slick-theme.css';
import App from './App';
import { Config, WagmiProvider } from 'wagmi';
import { config } from 'config/wagmiConfig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;
const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
