import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

import { CustomPaginationItem } from 'components/NftCollectionsList/styles';
import NftCard from 'components/NftCard';
import { AllNftsHeading, NftsGallery, StyledPagination } from './styles';
import { getAllNfts } from 'config/api';

const AllNFTs = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [nfts, setNfts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [totalPages, setTotalPages] = useState();
  const allNFTsRef = useRef<HTMLDivElement>(null);
  const is1550width = useMediaQuery(theme.breakpoints.between('mobile', 1550));
  useEffect(() => {
    if (is1550width) {
      setLimit(8);
    } else {
      setLimit(9);
    }
  }, [is1550width]);
  const getNfts = async () => {
    const { data } = await getAllNfts(`/nfts/all`, {
      limit: limit,
      page: page,
    });
    setNfts(data.docs);
    setTotalPages(data.totalPages);
  };
  useEffect(() => {
    getNfts();
  }, [page, limit]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (allNFTsRef.current) {
      const yOffset = -70;
      const yAxisScroll =
        allNFTsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: yAxisScroll, behavior: 'smooth' });
    }
  };
  return (
    <div ref={allNFTsRef}>
      <AllNftsHeading>{t('allNfts')}</AllNftsHeading>
      <NftsGallery>
        {nfts?.length &&
          nfts.map(
            ({
              _id,
              image,
              name,
              nft_collection,
              chain_id,
            }: {
              _id: string;
              image: string;
              name: string;
              nft_collection: { _id: string; name: string };
              chain_id: number;
            }) => (
              <NftCard
                id={_id}
                key={_id}
                image={image}
                collection={nft_collection?.name}
                name={name}
                chainId={chain_id}
              />
            )
          )}
      </NftsGallery>
      <StyledPagination
        count={totalPages}
        siblingCount={isMobile ? 0 : 1}
        page={page}
        onChange={handleChange}
        renderItem={(item) => <CustomPaginationItem {...item} />}
        sx={{ marginTop: '32px !important' }}
      />
    </div>
  );
};

export default AllNFTs;
