import { Stack, Typography, styled } from '@mui/material';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';
import { Link } from 'react-router-dom';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
  lineHeight: '150%',

  [theme.breakpoints.down('mobile')]: {
    lineHeight: '110%',
  },
}));

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0 0 24px',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}));

export const StyledButton = styled(Link)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  textDecoration: 'none',
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
  padding: '12px 24px',
  textAlign: 'center',

  [theme.breakpoints.down('mobile')]: {
    margin: '24px 0',
  },
}));
export const StyledMyProjectsList = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  flexWrap: 'wrap',
});
