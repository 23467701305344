import { Button, Typography, Dialog, styled } from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from 'helpers/themeStyles';

export const StyledModalTitle = styled(Typography)(() => ({
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  fontFamily: FontFamily.INTER,
}));

export const ContentWrap = styled('div')(() => ({
  backgroundColor: Color.DARK_PURPLE_OPACITY,
  borderRadius: '8px',
  margin: '16px',
  padding: '16px',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    width: 412,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    borderRadius: BorderRadius.SMALL,
    color: Color.WHITE,
  },
}));

export const TitleStyled = styled('p')({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
  margin: 0,
  marginLeft: '6px',
});

export const SubtitleStyled = styled('p')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  margin: '32px 16px 16px',
});

export const ContentItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TokenInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  background:
    'linear-gradient(90deg, #F89D2F 0%, #E63E36 53.12%, #C81FAD 100%)',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  width: 'calc(100% - 32px)',
  height: '56px',
  margin: '32px 16px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  '&:hover': {
    background:
      'linear-gradient(90deg, #C81FAD 0%, #E63E36 53.12%, #F89D2F 100%)',
  },

  [theme.breakpoints.down('mobile')]: {
    height: 'inherit',
    padding: '10px',
    margin: '16px',
  },
}));
