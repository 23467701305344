import { Box } from '@mui/material';
import { PageRoutes } from 'config/routes';
import { StyledButton, StyledText } from './styles';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PlanetLogo from 'svg/PlanetLogo';

interface ErrorBoundaryProps extends WithTranslation {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  render() {
    const { t } = this.props;
    {
      if (this.state.hasError) {
        return (
          <Box
            sx={{
              height: '100vh',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <PlanetLogo />
            <StyledText>{t('smtWentWrong')}</StyledText>
            <StyledButton
              onClick={() => (window.location.href = PageRoutes.Home)}
            >
              {t('goToHomePage')}
            </StyledButton>
          </Box>
        );
      }
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
