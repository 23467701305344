import { styled } from '@mui/material';
import { Color } from 'helpers/themeStyles';

export const ExpandLess = styled('div')({
  width: 0,
  height: 0,
  border: '0 solid transparent',
  borderRightWidth: '5px',
  borderLeftWidth: '5px',
  borderBottom: `5px solid ${Color.WHITE}`,
  marginBottom: '2px',
});

export const ExpandMore = styled('div')({
  width: 0,
  height: 0,
  border: '0 solid transparent',
  borderRightWidth: '5px',
  borderLeftWidth: '5px',
  borderTop: `5px solid ${Color.WHITE}`,
});
