import React from 'react';

const ArrowGreen = () => {
  return (
    <svg
      width='11'
      height='7'
      viewBox='0 0 11 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_181_8024)'>
        <path
          d='M5.3033 0.510176L0.853553 4.95992C0.538571 5.27491 0.761653 5.81348 1.20711 5.81348H10.1066C10.5521 5.81348 10.7751 5.27491 10.4602 4.95992L6.01041 0.510176C5.81515 0.314914 5.49856 0.314913 5.3033 0.510176Z'
          fill='#11C243'
        />
      </g>
      <defs>
        <clipPath id='clip0_181_8024'>
          <rect
            width='11'
            height='7'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowGreen;
