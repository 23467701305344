import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputBase, Stack, ToggleButtonGroup } from '@mui/material';
import HttpsIcon from '@mui/icons-material/Https';
import { DEXToken, utils, tickToPrice } from '@omisoftnet/game-dex-sdk';
import { useSelector } from 'react-redux';
import { useAccount, useBalance } from 'wagmi';
import { Position } from '@uniswap/v3-sdk';

import {
  StyledDialog,
  LiquidityPairContainer,
  LiquidityPair,
  StyledPositionItemStatus,
  StyledStatusIcon,
  StyledStatusTitle,
  MainContent,
  FeeTierContainer,
  TokenContainer,
  StyledPrice,
  SelectedRangeContainer,
  SelectedTitle,
  StyledSwitchPairButton,
  MaxPriceContainer,
  MinMaxStyledContainer,
  PriceTitle,
  PriceNumber,
  PriceDescription,
  StyledBtn,
  StyledHeadingContainer,
  StyledIconButton,
  StyledDialogTitle,
  RowContainer,
  RowContainerItem,
  StyledTokenName,
} from './styles';
import { Color, FontFamily, TextSize } from 'helpers/themeStyles';
import {
  BalanceStyled,
  InputWrap,
  MaxStyled,
  StyledInput,
  TokensBalanceWrap,
} from 'components/AddLiquidity/styles';
import { dexSelector } from 'state/dex/selectors';
import AddLiquidityPopup from 'components/AddLiquidityPopup';
import { MOCK_POOL_DETAILS } from 'helpers/mockPoolDetails';
import poolStatuses from 'helpers/poolStatuses';
import SwapSettings from 'components/SwapSettings';
import isFirstTokenChoosen from 'helpers/isFirstTokenChoosen';
import { Token, CurrencyAmount } from '@uniswap/sdk-core';
import LockIcon from 'svg/LockIcon';
import { toHex } from 'components/AddLiquidityV2/AddLiquidityV2';

import { BigNumber } from 'ethers';
import JSBI from 'jsbi';
import formatTokenBalance from 'helpers/formatTokenBalance';

const IncreaseLiquidityPopup = ({
  isOpen,
  onClose,
  fee,
  depositIn,
  depositOut,
  firstTokenSymbol,
  secondTokenSymbol,
  firstTokenIcon,
  secondTokenIcon,
  token0,
  token1,
  position,
  positionId,
}: {
  isOpen: boolean;
  onClose: () => void;
  fee: number;
  depositIn: number;
  depositOut: number;
  firstTokenSymbol: string;
  secondTokenSymbol: string;
  firstTokenIcon: string;
  secondTokenIcon: string;
  token0: DEXToken;
  token1: DEXToken;
  position: Position;
  positionId: string;
}) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const [firstTokenState, setFirstToken] = useState<
    CurrencyAmount<Token> | undefined
  >();
  const [secondTokenState, setSecondToken] = useState<
    CurrencyAmount<Token> | undefined
  >();

  const [_firstTokenState, _setFirstToken] = useState('');
  const [_secondTokenState, _setSecondToken] = useState('');
  const [tokenAlignment, setTokenAlignment] = useState(firstTokenSymbol);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const pool = position.pool;
  const [deposit0Disabled, setDeposit0Disabled] = useState(false);
  const [deposit1Disabled, setDeposit1Disabled] = useState(false);
  const { data: firstTokenBalance } = useBalance({
    address,
    chainId: token0?.chainId,
    token: !token0?.isNative ? (token0?.address as `0x${string}`) : undefined,
  });
  const { data: secondTokenBalance } = useBalance({
    address,
    chainId: token1?.chainId,
    token: !token1?.isNative ? (token1?.address as `0x${string}`) : undefined,
  });

  if (depositIn > 0) {
    token0.amount = utils.parseUnits(`${depositIn}`, token0.decimals);
  }
  if (depositOut) {
    token1.amount = utils.parseUnits(`${depositOut}`, token1.decimals);
  }

  if (firstTokenState) {
    token0.amount = BigNumber.from(toHex(firstTokenState.quotient));
  }
  if (secondTokenState) {
    token1.amount = BigNumber.from(toHex(secondTokenState.quotient));
  }

  const dex = useSelector(dexSelector);

  // useEffect(() => {
  //   getPoolFromContract();
  // }, []);

  // async function getPoolFromContract() {
  //   const provider = await dex.getDEXProvider(chain?.id!, token0, token1);

  //   if (token0 && token1 && feeAmount) {
  //     if (!(provider instanceof UniswapProvider)) {
  //       throw new Error('Unsupported DEX');
  //     }
  //     const poolState = await provider.getPoolFromContract(
  //       token0.wrapped.address,
  //       token1.wrapped.address,
  //       feeAmount
  //     );
  //     setPool(poolState);
  //   }
  // }

  async function getMaxTokenValue(
    token: DEXToken,
    firstTokenFlag: boolean = false
  ) {
    if (!dex) return;
    const maxValue = await dex.maxFor(token);
    if (!maxValue[0].isNegative() && !maxValue[1].isNegative()) {
      firstTokenFlag
        ? setFirstToken(
            CurrencyAmount.fromRawAmount(pool.token1, maxValue[0].toHexString())
          )
        : setSecondToken(
            CurrencyAmount.fromRawAmount(pool.token1, maxValue[0].toHexString())
          );
      firstTokenFlag
        ? estimate(utils.formatUnits(`${maxValue[0]}`, token.decimals), true)
        : estimate(utils.formatUnits(`${maxValue[0]}`, token.decimals), false);
    }
    return maxValue;
  }

  const handleTokenAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment === null && firstTokenSymbol === tokenAlignment) {
      setTokenAlignment(secondTokenSymbol);
    } else if (newAlignment === null && secondTokenSymbol === tokenAlignment) {
      setTokenAlignment(firstTokenSymbol);
    } else {
      setTokenAlignment(newAlignment);
    }
  };
  let isSorted = false;
  if (token0 && token0.symbol !== '!' && token1 && token1.symbol !== '!') {
    isSorted =
      token0?.wrapped &&
      token1?.wrapped &&
      token0.wrapped.address !== token1.wrapped.address &&
      token0?.wrapped.sortsBefore(token1?.wrapped);
  }
  const estimate = async (result: string, firstFlag: boolean) => {
    console.log(`ESTIAMTE ${result}`);
    if (firstFlag) {
      const _result = CurrencyAmount.fromRawAmount(
        pool.token0,
        utils.parseUnits(result, pool.token0.decimals).toHexString()
      );
      const { amount1 } = Position.fromAmount0({
        pool: pool!,
        tickLower: position.tickLower,
        tickUpper: position.tickUpper,
        amount0: utils.parseUnits(result, pool.token0.decimals).toHexString(),
        useFullPrecision: true,
      });
      setSecondToken(amount1);
      setFirstToken(_result);
      _setFirstToken(_result.toSignificant(6));
      _setSecondToken(amount1.toSignificant(6));
    }
    if (!firstFlag) {
      const _result = CurrencyAmount.fromRawAmount(
        pool.token1,
        utils.parseUnits(result, pool.token1.decimals).toHexString()
      );
      const { amount0 } = Position.fromAmount1({
        pool: pool!,
        tickLower: position.tickLower,
        tickUpper: position.tickUpper,
        amount1: utils.parseUnits(result, pool.token1.decimals).toHexString(),
      });
      setFirstToken(amount0);
      setSecondToken(_result);
      _setFirstToken(amount0.toSignificant(6));
      _setSecondToken(_result.toSignificant(6));
    }
  };
  useEffect(() => {
    const deposit0Disabled = isSorted
      ? Boolean(
          typeof position.tickUpper === 'number' &&
            pool &&
            pool.tickCurrent >= position.tickUpper
        )
      : Boolean(
          typeof position.tickUpper === 'number' &&
            pool &&
            pool.tickCurrent <= position.tickUpper
        );

    const deposit1Disabled = isSorted
      ? Boolean(
          typeof position.tickLower === 'number' &&
            pool &&
            pool.tickCurrent <= position.tickLower
        )
      : Boolean(
          typeof position.tickLower === 'number' &&
            pool &&
            pool.tickCurrent >= position.tickLower
        );

    setDeposit0Disabled(deposit0Disabled);
    setDeposit1Disabled(deposit1Disabled);
  }, [pool, pool]);
  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={onClose}
      >
        <StyledHeadingContainer>
          <StyledIconButton onClick={() => onClose()}>
            <ArrowBackIcon />
          </StyledIconButton>
          <StyledDialogTitle>{t('addLiquidity')}</StyledDialogTitle>
          <SwapSettings />
        </StyledHeadingContainer>
        <MainContent>
          <LiquidityPairContainer>
            <LiquidityPair>
              {firstTokenSymbol}/{secondTokenSymbol}
            </LiquidityPair>
            <StyledPositionItemStatus>
              <StyledStatusIcon
                style={{
                  background:
                    MOCK_POOL_DETAILS.status === poolStatuses.IN_RANGE
                      ? Color.GREEN
                      : Color.LIGHT_RED,
                }}
              />
              {/* change after complete logic */}
              <StyledStatusTitle>{'In range'}</StyledStatusTitle>
            </StyledPositionItemStatus>
          </LiquidityPairContainer>
          <FeeTierContainer>
            <TokenContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                <img
                  src={firstTokenIcon}
                  width='24'
                  height='24'
                />
                <span>{firstTokenSymbol}</span>
              </div>
              <StyledPrice>{depositIn}</StyledPrice>
            </TokenContainer>
            <TokenContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                <img
                  src={secondTokenIcon}
                  width='24'
                  height='24'
                />
                <span>{secondTokenSymbol}</span>
              </div>
              <StyledPrice>{depositOut}</StyledPrice>
            </TokenContainer>
            <TokenContainer>
              <span>{t('feeTier')}</span>
              <StyledPrice>{fee} %</StyledPrice>
            </TokenContainer>
          </FeeTierContainer>
          <SelectedRangeContainer>
            <SelectedTitle>{t('selectedRange')}</SelectedTitle>
            <ToggleButtonGroup
              value={tokenAlignment}
              onChange={handleTokenAlignment}
              exclusive
              aria-label='text alignment'
              sx={{
                color: Color.WHITE,
                '& .Mui-selected': {
                  backgroundColor: `${Color.WHITE_OPACITY}!important`,
                },
              }}
            >
              <StyledSwitchPairButton value={secondTokenSymbol!}>
                {secondTokenSymbol}
              </StyledSwitchPairButton>
              <StyledSwitchPairButton value={firstTokenSymbol!}>
                {firstTokenSymbol}
              </StyledSwitchPairButton>
            </ToggleButtonGroup>
          </SelectedRangeContainer>
          <MinMaxStyledContainer>
            <MaxPriceContainer>
              <PriceTitle>{t('minPrice')}</PriceTitle>
              <PriceNumber>
                {isFirstTokenChoosen(tokenAlignment!, firstTokenSymbol!)
                  ? Number(
                      tickToPrice(
                        pool.token1,
                        pool.token0,
                        position.tickUpper
                      ).toFixed(7)
                    ) === 0
                    ? 0
                    : Number(
                        tickToPrice(
                          pool.token1,
                          pool.token0,
                          position.tickUpper
                        ).toFixed(7)
                      ) > 10000000000
                    ? '∞'
                    : Number(
                        tickToPrice(
                          pool.token1,
                          pool.token0,
                          position.tickUpper
                        ).toFixed(7)
                      )
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickLower
                      ).toFixed(7)
                    ) === 0
                  ? 0
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickLower
                      ).toFixed(7)
                    ) > 10000000000
                  ? '∞'
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickLower
                      ).toFixed(7)
                    )}
              </PriceNumber>
              <PriceTitle>
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? firstTokenSymbol
                  : secondTokenSymbol}{' '}
                per{' '}
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? secondTokenSymbol
                  : firstTokenSymbol}
              </PriceTitle>
              <PriceDescription>
                {t('priceLiquidityDescription')}{' '}
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? firstTokenSymbol
                  : secondTokenSymbol}{' '}
                {t('atThisPrice')}
              </PriceDescription>
            </MaxPriceContainer>
            <MaxPriceContainer>
              <PriceTitle>{t('maxPrice')}</PriceTitle>
              <PriceNumber>
                {isFirstTokenChoosen(tokenAlignment!, firstTokenSymbol!)
                  ? Number(
                      tickToPrice(
                        pool.token1,
                        pool.token0,
                        position.tickLower
                      ).toFixed(7)
                    ) === 0
                    ? 0
                    : Number(
                        tickToPrice(
                          pool.token1,
                          pool.token0,
                          position.tickLower
                        ).toFixed(7)
                      ) > 10000000000
                    ? '∞'
                    : Number(
                        tickToPrice(
                          pool.token1,
                          pool.token0,
                          position.tickLower
                        ).toFixed(7)
                      )
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickLower
                      ).toFixed(7)
                    ) === 0
                  ? 0
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickUpper
                      ).toFixed(7)
                    ) > 10000000000
                  ? '∞'
                  : Number(
                      tickToPrice(
                        pool.token0,
                        pool.token1,
                        position.tickUpper
                      ).toFixed(7)
                    )}
              </PriceNumber>
              <PriceTitle>
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? firstTokenSymbol
                  : secondTokenSymbol}{' '}
                per{' '}
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? secondTokenSymbol
                  : firstTokenSymbol}
              </PriceTitle>
              <PriceDescription>
                {t('priceLiquidityDescription')}{' '}
                {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                  ? secondTokenSymbol
                  : firstTokenSymbol}{' '}
                {t('atThisPrice')}
              </PriceDescription>
            </MaxPriceContainer>
          </MinMaxStyledContainer>
          <MaxPriceContainer>
            <PriceTitle>{t('currentPrice')}</PriceTitle>
            <PriceNumber>
              {isFirstTokenChoosen(tokenAlignment!, firstTokenSymbol!)
                ? Number(
                    tickToPrice(
                      pool.token1,
                      pool.token0,
                      position.pool.tickCurrent
                    ).toFixed(7)
                  )
                : Number(
                    tickToPrice(
                      pool.token0,
                      pool.token1,
                      position.pool.tickCurrent
                    ).toFixed(7)
                  )}
            </PriceNumber>
            <PriceTitle>
              {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                ? firstTokenSymbol
                : secondTokenSymbol}{' '}
              per{' '}
              {isFirstTokenChoosen(tokenAlignment, firstTokenSymbol)
                ? secondTokenSymbol
                : firstTokenSymbol}
            </PriceTitle>
          </MaxPriceContainer>
          <SelectedTitle
            style={{ display: 'block', marginTop: '12px', marginBottom: '8px' }}
          >
            {t('addMoreLiquidity')}
          </SelectedTitle>
          {MOCK_POOL_DETAILS.status !== poolStatuses.IN_RANGE && (
            <MaxPriceContainer>
              <HttpsIcon style={{ color: Color.WHITE }} />
              <PriceTitle style={{ textAlign: 'center' }}>
                {t('singleAssetDeposit')}
              </PriceTitle>
            </MaxPriceContainer>
          )}
          {depositIn > 0 ? (
            <StyledInput style={{ marginBottom: '8px' }}>
              <Stack sx={{ width: '100%' }}>
                <RowContainer>
                  <RowContainerItem>
                    <img
                      src={firstTokenIcon}
                      width='24'
                      height='24'
                      alt='token-icon'
                    />

                    <StyledTokenName>{firstTokenSymbol}</StyledTokenName>
                  </RowContainerItem>
                  <TokensBalanceWrap>
                    {firstTokenSymbol && (
                      <>
                        <BalanceStyled>
                          {t('balance')}:{' '}
                          <span>
                            {firstTokenBalance &&
                              formatTokenBalance(
                                firstTokenBalance.value,
                                token0?.decimals
                              )}
                          </span>
                        </BalanceStyled>
                        <MaxStyled
                          onClick={() => getMaxTokenValue(token0, true)}
                        >
                          {t('max')}
                        </MaxStyled>
                      </>
                    )}
                  </TokensBalanceWrap>
                </RowContainer>

                <InputWrap>
                  <InputBase
                    sx={{
                      flex: 1,
                      fontSize: TextSize.MEDIUM,
                      fontFamily: FontFamily.INTER,
                      color: Color.WHITE_OPACITY_LIGHT,
                      fontWeight: '700',
                      '& .MuiInputBase-input': { p: 0 },
                      '& .Mui-disabled': {
                        WebkitTextFillColor: Color.WHITE_OPACITY_LIGHT,
                      },
                      '& ::placeholder': { color: Color.WHITE_OPACITY_LIGHT },
                    }}
                    placeholder='0'
                    inputProps={{
                      'aria-label': 'token',
                    }}
                    value={_firstTokenState}
                    onChange={(event) => {
                      const regexp = /^[0-9]*\.?[0-9]*$/;
                      if (regexp.test(event.target.value)) {
                        _setFirstToken(event.target.value);
                        if (Number(event.target.value) > 0) {
                          estimate(event.target.value, true);
                        }
                      } else {
                        event.target.value = _firstTokenState; // Restore the previous value
                      }
                    }}
                  />
                </InputWrap>
              </Stack>
            </StyledInput>
          ) : (
            <MaxPriceContainer>
              <LockIcon />
              <PriceTitle style={{ textAlign: 'center' }}>
                {t('singleAssetDeposit')}
              </PriceTitle>
            </MaxPriceContainer>
          )}
          {depositOut > 0 ? (
            <StyledInput>
              <Stack sx={{ width: '100%' }}>
                <RowContainer>
                  <RowContainerItem>
                    {secondTokenSymbol && (
                      <img
                        src={secondTokenIcon}
                        width='24'
                        height='24'
                        alt='token-icon'
                      />
                    )}
                    <StyledTokenName> {secondTokenSymbol}</StyledTokenName>
                  </RowContainerItem>
                  <TokensBalanceWrap>
                    {secondTokenSymbol && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                      >
                        <BalanceStyled>
                          {t('balance')}:{' '}
                          <span>
                            {secondTokenBalance &&
                              formatTokenBalance(
                                secondTokenBalance.value,
                                token1?.decimals
                              )}
                          </span>
                        </BalanceStyled>
                        <MaxStyled onClick={() => getMaxTokenValue(token1)}>
                          {t('max')}
                        </MaxStyled>
                      </Stack>
                    )}
                  </TokensBalanceWrap>
                </RowContainer>

                <InputWrap>
                  <InputBase
                    sx={{
                      flex: 1,
                      fontSize: TextSize.MEDIUM,
                      fontFamily: FontFamily.INTER,
                      color: Color.WHITE_OPACITY_LIGHT,
                      fontWeight: '700',
                      '& .MuiInputBase-input': { p: 0 },
                      '& .Mui-disabled': {
                        WebkitTextFillColor: Color.WHITE_OPACITY_LIGHT,
                      },
                      '& ::placeholder': { color: Color.WHITE_OPACITY_LIGHT },
                    }}
                    placeholder='0'
                    inputProps={{
                      'aria-label': 'token',
                    }}
                    value={_secondTokenState}
                    onChange={(event) => {
                      // const result = event.target.value.replace(/[^\d\.]/g, '');
                      const regexp = /^[0-9]*\.?[0-9]*$/;
                      if (regexp.test(event.target.value)) {
                        _setSecondToken(event.target.value);
                        if (Number(event.target.value) > 0) {
                          estimate(event.target.value, false);
                        } // estimate(event.target.value, false);
                      } else {
                        event.target.value = _secondTokenState; // Restore the previous value
                      }
                      // result?.match(/\./g)?.length! > 1
                      //   ? setSecondToken(
                      //       result.slice(0, result.lastIndexOf('.'))
                      //     )
                      //   : result === '00' ||
                      //     (result.length === 2 &&
                      //       result[0] === '0' &&
                      //       result[1] !== '.')
                      //   ? setSecondToken('0')
                      //   : result[0] === '.'
                      //   ? setSecondToken('')
                      //   : setSecondToken(result);
                    }}
                  />
                </InputWrap>
              </Stack>
            </StyledInput>
          ) : (
            <MaxPriceContainer>
              <LockIcon />
              <PriceTitle style={{ textAlign: 'center' }}>
                {t('singleAssetDeposit')}
              </PriceTitle>
            </MaxPriceContainer>
          )}

          <StyledBtn
            disabled={
              BigNumber.from(firstTokenBalance?.value).lte(
                toHex(firstTokenState?.quotient ?? JSBI.BigInt('0'))
              ) ||
              BigNumber.from(secondTokenBalance?.value).lte(
                toHex(secondTokenState?.quotient ?? JSBI.BigInt('0'))
              ) ||
              Number(_firstTokenState) <= 0 ||
              Number(_secondTokenState) <= 0
            }
            onClick={() => setIsOpenConfirm(true)}
            style={{
              background:
                Number(firstTokenState) <= 0 ||
                Number(secondTokenState) <= 0 ||
                Number(firstTokenState) >
                  Number(firstTokenBalance?.formatted) ||
                Number(secondTokenState) > Number(secondTokenBalance?.formatted)
                  ? Color.WHITE_OPACITY
                  : Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
            }}
          >
            {BigNumber.from(firstTokenBalance?.value).lte(
              toHex(firstTokenState?.quotient ?? JSBI.BigInt('0'))
            ) ||
            BigNumber.from(secondTokenBalance?.value).lte(
              toHex(secondTokenState?.quotient ?? JSBI.BigInt('0'))
            )
              ? t('insufficientBalance')
              : firstTokenState?.lessThan(0) || secondTokenState?.lessThan(0)
              ? t('enterAnAmount')
              : t('preview')}
          </StyledBtn>
        </MainContent>
      </StyledDialog>
      <AddLiquidityPopup
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        fee={fee}
        increase
        status={'In range'}
        tickLower={position.tickLower}
        tickUpper={position.tickUpper}
        tickCurrent={position.pool.tickCurrent}
        clearTokensValue={() => {
          setFirstToken(undefined);
          setSecondToken(undefined);
        }}
        positionId={positionId}
        poolSpacing={pool?.tickSpacing}
        firstToken={token0}
        secondToken={token1}
        deposit0Disabled={deposit0Disabled}
        deposit1Disabled={deposit1Disabled}
      />
    </>
  );
};

export default IncreaseLiquidityPopup;
