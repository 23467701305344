import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useDeploy } from '@hooks/useDeploy';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { ProjectDetails } from 'types/project';
import AlertRoundedIcon from 'svg/AlertRoundedIcon';
import { Color } from 'helpers/themeStyles';
import WaitingPopup from '../WaitingPopup';
import {
  StyledContainer,
  StyledDialog,
  StyledBtn,
  StyledTitle,
  StyledBtnContainer,
  StyledCancelBtn,
  StyledList,
  StyledListItem,
} from './styles';

const PlatformCommissionInfoPopup = ({
  isOpen,
  onClose,
  project,
}: {
  isOpen: boolean;
  onClose: () => void;
  project?: ProjectDetails;
}) => {
  const { t } = useTranslation();
  const COMMISSION_PERCENT = 2;
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const { deploy, isLoading } = useDeploy(
    project!,
    setTransactionSubmittedStatus,
    setTransactionRejectedStatus,
    setOpenWaitingPopup
  );

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={onClose}
      >
        <StyledContainer>
          <AlertRoundedIcon />
          <StyledTitle style={{ margin: '18px 0 12px', textAlign: 'center' }}>
            {t('commissionPolicy')}
          </StyledTitle>
          <StyledList>
            <StyledListItem>
              {t('commissionWarningFirstItem', {
                commissionPercent: COMMISSION_PERCENT,
              })}
            </StyledListItem>
            <StyledListItem>{t('commissionWarningSecondItem')}</StyledListItem>
            <StyledListItem>{t('commissionWarningThirdItem')}</StyledListItem>
          </StyledList>
          <StyledBtnContainer>
            <StyledBtn onClick={() => deploy()}>{t('ok')}</StyledBtn>
            <StyledCancelBtn onClick={onClose}>{t('reject')}</StyledCancelBtn>
          </StyledBtnContainer>
        </StyledContainer>
      </StyledDialog>
      {transactionSubmittedStatus && !isLoading && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {
            onClose();
          }}
          result={transactionStatuses.SUBMIT}
          reloadPage
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {
            onClose();
          }}
          result={transactionStatuses.REJECT}
        />
      )}
      <WaitingPopup
        isOpen={openWaitingPopup}
        onClose={() => {
          setOpenWaitingPopup(false);
        }}
      />
    </>
  );
};
export default PlatformCommissionInfoPopup;
