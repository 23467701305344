import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useClient } from 'wagmi';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  CurrencyAmount,
  DEXToken,
  PancakePair,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';
import JSBI from 'jsbi';

import LiquiditySettings from 'components/LiquiditySettings';
import ConfirmLiquidityV2Popup from 'components/ConfirmLiquidityV2Popup';
import CopyIcon from 'svg/CopyIcon';
import MetamaskIcon from 'svg/MetamaskIcon';
import AlertIcon from 'svg/AlertIcon';
import {
  firstLiquidityTokenSelector,
  liquiditySettingsSelector,
  secondLiquidityTokenSelector,
} from 'state/liquidity/selectors';
import { Border, Color } from 'helpers/themeStyles';
import { PERCENTAGE } from 'helpers/percentage';
import { addRecommendedTokenToWallet } from 'helpers/addRecommendedTokenToWallet';
import {
  StyledTitle,
  SelectorContainer,
  StyledIconButton,
  StyledInput,
  StyledInputBase,
  StyledContainer,
  StyledPricesContainer,
  StyledPricesTitle,
  StyledPricesInfo,
  StyledPricesInfoItem,
  StyledPricesInfoTitle,
  StyledPricesInfoValue,
  StyledToleranceContainer,
  StyledToleranceTitle,
  StyledToleranceValue,
  HeaderContainer,
  MainContainer,
  TokenInfo,
  TokenName,
  TokenNameDetails,
  TokenBalance,
  UsdTokenValue,
  PercentageBtnContainer,
  PercentageBtn,
  Divider,
  CopyNotification,
  TipContainer,
  TipText,
  StyledButton,
  ButtonContainer,
} from './styles';
import { dexSelector, signerSelector } from 'state/dex/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import { transactionHashSelector } from 'state/transactions/selectors';
import {
  updateAmountFirstToken,
  updateAmountSecondToken,
} from 'state/liquidity/slice';
import { topTokensSelector } from 'state/swap/selectors';
import { roundNumber } from 'helpers/roundNumber';
import { stableTokens } from 'helpers/nativeTokens';
import { stableTokensForSwapUsdt } from 'helpers/stableTokens';
import formatTokenBalance from 'helpers/formatTokenBalance';
import { useAsUsd } from '@hooks/useAsUsd';
import { useSigner } from '@hooks/useSigner';
import { signatureToCompactSignature } from 'viem';

type Props = {
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: () => void;
  fee?: number;
  liquidityId?: string;
  increase?: boolean | undefined;
};

/**
 * Converts a big int to a hex string
 * @param bigintIsh
 * @returns The hex encoded calldata
 */
export function toHex(bigintIsh: bigint | JSBI) {
  const bigInt = JSBI.BigInt(bigintIsh as JSBI);
  let hex = bigInt.toString(16);
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }
  return `0x${hex}`;
}

const AddLiquidityV2 = ({
  onBack,
  onClose,
  fee,
  liquidityId,
  increase,
}: Props) => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const dex = useSelector(dexSelector);
  const token0 = useSelector(firstLiquidityTokenSelector) ?? DEXToken.default();
  const token1 =
    useSelector(secondLiquidityTokenSelector) ?? DEXToken.default();
  const { getValueInUsd } = useAsUsd();
  const [pair, setPancakePair] = useState<PancakePair>();
  const [factoryAddress, setFactoryAddress] = useState('');
  const isSorted = useMemo(() => {
    return token0.wrapped.sortsBefore(token1.wrapped);
  }, [token0, token1]);
  useEffect(() => {
    if (token0 && token1 && chain) {
      const tokens: [DEXToken, DEXToken][] = [[token0, token1]];
      const getFactory = async () => {
        if (!dex) return;
        const provider = await dex.getDEXProvider(chain?.id!, token0, token1);
        const factory = await provider.factory();
        return factory;
      };
      getFactory().then((data) => setFactoryAddress(data!));
      for (const [i, [tokenA, tokenB]] of tokens.entries()) {
        const [token0, token1] = tokenA.wrapped.sortsBefore(tokenB.wrapped)
          ? [tokenB, tokenA]
          : [tokenA, tokenB];
        PancakePair.from(
          token0.wrapped,
          token1.wrapped,
          signer!,
          factoryAddress
        )
          .then((pair) => {
            switch (i) {
              case 0:
                setPancakePair(pair);
                const token0Amount = CurrencyAmount.fromRawAmount(
                  pair!.pair.token1,
                  utils
                    .parseUnits('1', pair!.pair.token0.decimals)
                    .toHexString()
                );
                const token1Amount = CurrencyAmount.fromRawAmount(
                  pair!.pair.token0,
                  utils
                    .parseUnits('1', pair!.pair.token1.decimals)
                    .toHexString()
                );
                const priceTokenA = utils.formatUnits(
                  BigNumber.from(
                    pair!.pair.priceOf(pair!.pair.token0).quote(token1Amount)
                      .quotient
                  ),
                  pair!.pair.token0.decimals
                );
                const priceTokenB = utils.formatUnits(
                  BigNumber.from(
                    pair!.pair.priceOf(pair!.pair.token1).quote(token0Amount)
                      .quotient
                  ),
                  pair!.pair.token1.decimals
                );
                setPerOneToken(
                  isSorted
                    ? [priceTokenA, priceTokenB]
                    : [priceTokenB, priceTokenA]
                );
                break;
            }
          })
          .catch((err) => {
            console.error(err);
            setPancakePair(undefined);
          });
      }
    }
  }, [token0, token1, factoryAddress]);

  const TOP_TOKENS = useSelector(topTokensSelector);
  const liquiditySettings = useSelector(liquiditySettingsSelector);
  const [percentageFirstToken, setPercentageFirstToken] = useState<number>(0);
  const [percentageSecondToken, setPercentageSecondToken] = useState<number>(0);
  const [openFirstCopyNotification, setOpenFirstCopyNotification] =
    useState<boolean>(false);
  const [openSecondCopyNotification, setOpenSecondCopyNotification] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // const [[firstTokenAllowance, secondTokenAllowance], setAllowance] = useState<
  // [boolean, boolean] /* Tuple */
  // >([true, true]); // If true show approve button ?!
  const [firstTokenAllowance, setFirstTokenAllowance] =
    useState<boolean>(false);
  const [secondTokenAllowance, setSecondTokenAllowance] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [firstTokenValue, setFirstTokenValue] = useState('');
  const [secondTokenValue, setSecondTokenValue] = useState('');
  const [firstTokenValueInUsd, setFirstTokenValueInUsd] = useState('');
  const [secondTokenValueInUsd, setSecondTokenValueInUsd] = useState('');
  const [[perOneToken0, perOneToken1], setPerOneToken] = useState<
    [string, string]
  >(['', '']);
  const signer = useSelector(signerSelector);
  // const highPriceImpact = true;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const transactionHash = useSelector(transactionHashSelector);

  useEffect(() => {
    if (!pair && Number(firstTokenValue) > 0 && Number(secondTokenValue) > 0) {
      setPerOneToken([
        String(Number(firstTokenValue) / Number(secondTokenValue)),
        String(Number(secondTokenValue) / Number(firstTokenValue)),
      ]);
    }
  }, [firstTokenValue, secondTokenValue]);
  useMemo(async () => {
    if (Number(firstTokenValue) > 0) {
      const token0inUsd = await getValueInUsd({
        token: token0,
        value: firstTokenValue,
      });

      if (token0inUsd) {
        setFirstTokenValueInUsd(token0inUsd);
      }
    }
    if (Number(secondTokenValue) > 0) {
      const token1inUsd = await getValueInUsd({
        token: token1,
        value: secondTokenValue,
      });
      if (token1inUsd) {
        setSecondTokenValueInUsd(token1inUsd);
      }
    }
  }, [firstTokenValue, secondTokenValue]);
  const { data: firstTokenBalance } = useBalance({
    address,
    token: token0.isNative ? undefined : (token0.address as `0x${string}`),
  });
  const { data: secondTokenBalance } = useBalance({
    address,
    token: token1.isNative ? undefined : (token1.address as `0x${string}`),
  });
  async function checkTokenForAllowance() {
    if (!dex || !signer) return;
    const { routerAddress } = await dex.getDEXProvider(token0.chainId!);
    const firstTokenAllowance = (
      await token0!.allowance(signer, address!, routerAddress)
    )
      .sub(token0.amount)
      .isNegative();
    const secondTokenAllowance = (
      await token1!.allowance(signer, address!, routerAddress)
    )
      .sub(token1.amount)
      .isNegative();
    setFirstTokenAllowance(firstTokenAllowance);
    setSecondTokenAllowance(secondTokenAllowance);
  }

  useEffect(() => {
    checkTokenForAllowance();
  }, [token0, token1]);
  async function tokenApprove(token: DEXToken, first: boolean) {
    if (!dex || !signer) return;

    setLoading(true);
    const routerAddress = await dex.getDEXProvider(chain?.id!);
    await token
      .approve(signer, routerAddress.routerAddress!)
      .then((tx) => {
        setLoading(false);
        first ? setFirstTokenAllowance(false) : setSecondTokenAllowance(false);
        dispatch(
          setTransactionHash({
            hash: tx?.hash,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'approve',
            data: {
              token_data: {
                token1: {
                  ...token,
                  logoURI: token.icon,
                },
              },
            },
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }
  return (
    <>
      <StyledContainer elevation={3}>
        <HeaderContainer
          style={{
            borderTop: isMobile ? Border.ACCENT_BORDER : 'inherit',
          }}
        >
          <StyledIconButton onClick={increase ? onClose : onBack}>
            <ArrowBack />
          </StyledIconButton>
          <Stack
            flexGrow={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1.5}
          >
            <StyledTitle>
              {`${token0.symbol}-${token1.symbol} ${t('lp')}`}
            </StyledTitle>
          </Stack>
          <LiquiditySettings />
        </HeaderContainer>
        <MainContainer>
          <SelectorContainer>
            <TokenInfo>
              <TokenNameDetails>
                <img
                  src={token0?.icon}
                  alt={token0?.symbol}
                  width='24'
                  height='24'
                />
                <TokenName>{token0?.symbol}</TokenName>
                {!token0?.isNative && (
                  <>
                    <Divider />
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        navigator.clipboard.writeText(token0?.address!);
                        setOpenFirstCopyNotification(true);
                        setTimeout(
                          () => setOpenFirstCopyNotification(false),
                          1500
                        );
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                    {openFirstCopyNotification && (
                      <CopyNotification>
                        {t('tokenAddressCopied')}
                      </CopyNotification>
                    )}
                    {window.ethereum && (
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() =>
                          addRecommendedTokenToWallet(
                            token0.address,
                            token0.symbol ?? 'UNK',
                            token0.decimals,
                            token0.icon
                          )
                        }
                      >
                        <MetamaskIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </TokenNameDetails>
              <TokenBalance>
                {t('balance')}:{' '}
                {firstTokenBalance &&
                  formatTokenBalance(firstTokenBalance.value, token0?.decimals)}
              </TokenBalance>
            </TokenInfo>
            <StyledInput>
              <StyledInputBase
                placeholder='0.0'
                value={firstTokenValue}
                onChange={(event) => {
                  setPercentageFirstToken(0);
                  setPercentageSecondToken(0);
                  const inputValue = event.target.value;
                  const regex = /^(0|[1-9]\d*)(\.\d*)?$/; // Only allow numbers and a single decimal point
                  if (Number(inputValue) <= 0) {
                    setFirstTokenValueInUsd('0.0');
                    setSecondTokenValueInUsd('0.0');
                  }
                  if (regex.test(inputValue) && !pair) {
                    dispatch(
                      updateAmountFirstToken(
                        utils.parseUnits(
                          Number(inputValue).toFixed(token0.decimals),
                          token0.decimals
                        )
                      )
                    );
                    setFirstTokenValue(inputValue);
                    return;
                  }
                  if (regex.test(inputValue) && pair) {
                    const token0CurrencyAmount = CurrencyAmount.fromRawAmount(
                      pair!.pair.token1,
                      utils
                        .parseUnits(
                          Number(inputValue).toFixed(
                            pair!.pair.token0.decimals
                          ),
                          pair!.pair.token0.decimals
                        )
                        .toHexString()
                    );
                    const priceTokenB = utils.formatUnits(
                      BigNumber.from(
                        pair!.pair
                          .priceOf(pair!.pair.token1)
                          .quote(token0CurrencyAmount).quotient
                      ),
                      pair!.pair.token1.decimals
                    );
                    const token1CurrencyAmount = CurrencyAmount.fromRawAmount(
                      pair!.pair.token0,
                      utils
                        .parseUnits(inputValue, pair!.pair.token1.decimals)
                        .toHexString()
                    );
                    const priceTokenA = utils.formatUnits(
                      BigNumber.from(
                        pair!.pair
                          .priceOf(pair!.pair.token0)
                          .quote(token1CurrencyAmount).quotient
                      ),
                      pair!.pair.token0.decimals
                    );

                    setSecondTokenValue(isSorted ? priceTokenB : priceTokenA);
                    dispatch(
                      updateAmountFirstToken(
                        utils.parseUnits(
                          Number(inputValue).toFixed(token0.decimals),
                          token0.decimals
                        )
                      )
                    );

                    dispatch(
                      updateAmountSecondToken(
                        isSorted
                          ? utils.parseUnits(
                              Number(priceTokenB).toFixed(token1.decimals),
                              token1.decimals
                            )
                          : utils.parseUnits(
                              Number(priceTokenA).toFixed(token1.decimals),
                              token1.decimals
                            )
                      )
                    );

                    setFirstTokenValue(inputValue);
                  } else if (inputValue === '') {
                    setFirstTokenValue(inputValue);
                    setSecondTokenValue('');
                    dispatch(updateAmountSecondToken(BigNumber.from('0x0')));
                    dispatch(updateAmountSecondToken(BigNumber.from('0x0')));
                  } else {
                    event.target.value = utils.formatUnits(
                      `${token0.amount}`,
                      token0.decimals
                    ); // Restore the previous value
                    setFirstTokenValue(firstTokenValue);
                  }
                }}
                style={{
                  color:
                    // highPriceImpact ? Color.LIGHT_RED :
                    Color.WHITE,
                }}
              />
              {firstTokenValueInUsd.length > 0 && (
                <UsdTokenValue>~{firstTokenValueInUsd} USD</UsdTokenValue>
              )}
              <PercentageBtnContainer>
                {PERCENTAGE.map((percent) => (
                  <PercentageBtn
                    key={percent}
                    style={{
                      color:
                        percentageFirstToken === percent
                          ? Color.PURPLE
                          : Color.WHITE,
                    }}
                    onClick={() => {
                      setPercentageFirstToken(percent);
                      setPercentageSecondToken(0);
                      const token0BalancePart = String(
                        (Number(firstTokenBalance?.formatted) * percent) / 100
                      ).slice(0, 19);
                      setFirstTokenValue(token0BalancePart);
                      dispatch(
                        updateAmountFirstToken(
                          utils.parseUnits(
                            Number(token0BalancePart).toFixed(token0!.decimals),
                            token0!.decimals
                          )
                        )
                      );
                      const token0CurrencyAmount = CurrencyAmount.fromRawAmount(
                        pair!.pair.token1,
                        utils
                          .parseUnits(
                            Number(token0BalancePart).toFixed(
                              pair!.pair.token0.decimals
                            ),
                            pair!.pair.token0.decimals
                          )
                          .toHexString()
                      );
                      const priceTokenB = utils.formatUnits(
                        BigNumber.from(
                          pair!.pair
                            .priceOf(pair!.pair.token1)
                            .quote(token0CurrencyAmount).quotient
                        ),
                        pair!.pair.token1.decimals
                      );
                      const token1CurrencyAmount = CurrencyAmount.fromRawAmount(
                        pair!.pair.token0,
                        utils
                          .parseUnits(
                            token0BalancePart,
                            pair!.pair.token1.decimals
                          )
                          .toHexString()
                      );
                      const priceTokenA = utils.formatUnits(
                        BigNumber.from(
                          pair!.pair
                            .priceOf(pair!.pair.token0)
                            .quote(token1CurrencyAmount).quotient
                        ),
                        pair!.pair.token0.decimals
                      );

                      setSecondTokenValue(isSorted ? priceTokenB : priceTokenA);
                      dispatch(
                        updateAmountSecondToken(
                          isSorted
                            ? utils.parseUnits(priceTokenB, token1!.decimals!)
                            : utils.parseUnits(priceTokenA, token1!.decimals!)
                        )
                      );
                    }}
                  >
                    {percent === 100 ? t('max') : percent + '%'}
                  </PercentageBtn>
                ))}
              </PercentageBtnContainer>
            </StyledInput>
          </SelectorContainer>
          <SelectorContainer>
            <TokenInfo>
              <TokenNameDetails>
                <img
                  src={token1?.icon}
                  alt={token1?.symbol}
                  width='24'
                  height='24'
                />
                <TokenName>{token1?.symbol}</TokenName>
                {!token1?.isNative && (
                  <>
                    <Divider />
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        navigator.clipboard.writeText(token1.address);
                        setOpenSecondCopyNotification(true);
                        setTimeout(
                          () => setOpenSecondCopyNotification(false),
                          1500
                        );
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                    {openSecondCopyNotification && (
                      <CopyNotification>
                        {t('tokenAddressCopied')}
                      </CopyNotification>
                    )}

                    {(window as any).ethereum && (
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() =>
                          addRecommendedTokenToWallet(
                            token1.address,
                            token1.symbol ?? 'UNK',
                            token1.decimals,
                            token1.icon
                          )
                        }
                      >
                        <MetamaskIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </TokenNameDetails>
              <TokenBalance>
                {t('balance')}:{' '}
                {secondTokenBalance &&
                  formatTokenBalance(
                    secondTokenBalance.value,
                    token1?.decimals
                  )}
              </TokenBalance>
            </TokenInfo>
            <StyledInput>
              <StyledInputBase
                placeholder='0.0'
                value={secondTokenValue}
                onChange={(event) => {
                  setPercentageFirstToken(0);
                  setPercentageSecondToken(0);
                  const inputValue = event.target.value;
                  const regex = /^(0|[1-9]\d*)(\.\d*)?$/; // Only allow numbers and a single decimal point
                  if (Number(inputValue) <= 0) {
                    setFirstTokenValueInUsd('0.0');
                    setSecondTokenValueInUsd('0.0');
                  }
                  if (regex.test(inputValue) && !pair) {
                    dispatch(
                      updateAmountSecondToken(
                        utils.parseUnits(
                          Number(inputValue).toFixed(token1.decimals),
                          token1.decimals
                        )
                      )
                    );
                    setSecondTokenValue(inputValue);
                    return;
                  }
                  if (regex.test(inputValue) && pair) {
                    dispatch(
                      updateAmountSecondToken(
                        utils.parseUnits(
                          Number(inputValue).toFixed(token1.decimals),
                          token1.decimals
                        )
                      )
                    );
                    const token1CurrencyAmount = CurrencyAmount.fromRawAmount(
                      pair!.pair.token0,
                      utils
                        .parseUnits(inputValue, pair!.pair.token1.decimals)
                        .toHexString()
                    );
                    const priceTokenA = utils.formatUnits(
                      BigNumber.from(
                        pair!.pair
                          .priceOf(pair!.pair.token0)
                          .quote(token1CurrencyAmount).quotient
                      ),
                      pair!.pair.token0.decimals
                    );
                    const token0CurrencyAmount = CurrencyAmount.fromRawAmount(
                      pair!.pair.token1,
                      utils
                        .parseUnits(
                          Number(inputValue).toFixed(
                            pair!.pair.token0.decimals
                          ),
                          pair!.pair.token0.decimals
                        )
                        .toHexString()
                    );
                    const priceTokenB = utils.formatUnits(
                      BigNumber.from(
                        pair!.pair
                          .priceOf(pair!.pair.token1)
                          .quote(token0CurrencyAmount).quotient
                      ),
                      pair!.pair.token1.decimals
                    );
                    setFirstTokenValue(isSorted ? priceTokenA : priceTokenB);
                    dispatch(
                      updateAmountFirstToken(
                        isSorted
                          ? utils.parseUnits(
                              Number(priceTokenA).toFixed(token0!.decimals),
                              token0!.decimals
                            )
                          : utils.parseUnits(
                              Number(priceTokenB).toFixed(token0!.decimals),
                              token0!.decimals
                            )
                      )
                    );
                    setSecondTokenValue(inputValue);
                  } else if (inputValue === '') {
                    setSecondTokenValue('');
                    setFirstTokenValue('');
                    dispatch(updateAmountFirstToken(BigNumber.from('0x0')));
                    dispatch(updateAmountSecondToken(BigNumber.from('0x0')));
                  } else {
                    event.target.value = utils.formatUnits(
                      `${token1.amount}`,
                      token1.decimals
                    ); // Restore the previous value
                  }
                }}
              />
              {secondTokenValueInUsd.length > 0 && (
                <UsdTokenValue>~{secondTokenValueInUsd} USD</UsdTokenValue>
              )}
              <PercentageBtnContainer>
                {PERCENTAGE.map((percent) => (
                  <PercentageBtn
                    key={percent}
                    style={{
                      color:
                        percentageSecondToken === percent
                          ? Color.PURPLE
                          : Color.WHITE,
                    }}
                    onClick={() => {
                      setPercentageSecondToken(percent);
                      setPercentageFirstToken(0);
                      const token1BalancePart = String(
                        (Number(secondTokenBalance?.formatted) * percent) / 100
                      ).slice(0, 19);
                      setSecondTokenValue(token1BalancePart);
                      dispatch(
                        updateAmountSecondToken(
                          utils.parseUnits(
                            Number(token1BalancePart).toFixed(token1.decimals),
                            token1.decimals
                          )
                        )
                      );
                      const token1CurrencyAmount = CurrencyAmount.fromRawAmount(
                        pair!.pair.token0,
                        utils
                          .parseUnits(
                            token1BalancePart,
                            pair!.pair.token1.decimals
                          )
                          .toHexString()
                      );
                      const priceTokenA = utils.formatUnits(
                        BigNumber.from(
                          pair!.pair
                            .priceOf(pair!.pair.token0)
                            .quote(token1CurrencyAmount).quotient
                        ),
                        pair!.pair.token0.decimals
                      );
                      const token0CurrencyAmount = CurrencyAmount.fromRawAmount(
                        pair!.pair.token1,
                        utils
                          .parseUnits(
                            Number(token1BalancePart).toFixed(
                              pair!.pair.token0.decimals
                            ),
                            pair!.pair.token0.decimals
                          )
                          .toHexString()
                      );
                      const priceTokenB = utils.formatUnits(
                        BigNumber.from(
                          pair!.pair
                            .priceOf(pair!.pair.token1)
                            .quote(token0CurrencyAmount).quotient
                        ),
                        pair!.pair.token1.decimals
                      );
                      setFirstTokenValue(isSorted ? priceTokenA : priceTokenB);
                      dispatch(
                        updateAmountFirstToken(
                          isSorted
                            ? utils.parseUnits(priceTokenA, token0!.decimals!)
                            : utils.parseUnits(priceTokenB, token0!.decimals)
                        )
                      );
                    }}
                  >
                    {percent === 100 ? t('max') : percent + '%'}
                  </PercentageBtn>
                ))}
              </PercentageBtnContainer>
            </StyledInput>
          </SelectorContainer>
          {/* {highPriceImpact && (
            <TipContainer>
              <AlertIcon />
              <TipText>
                {t('highPriceImpactTip', {
                  tokenName: token0.symbol ?? 'UNK',
                })}
              </TipText>
            </TipContainer>
          )} */}
          <StyledPricesContainer>
            <StyledPricesTitle>{t('prices')}</StyledPricesTitle>
            <StyledPricesInfo
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? '16px' : undefined,
              }}
            >
              <StyledPricesInfoItem>
                <StyledPricesInfoValue>
                  {roundNumber(Number(perOneToken1))}
                </StyledPricesInfoValue>
                <StyledPricesInfoTitle>{`${token1.symbol} ${t('per')} ${
                  token0?.symbol
                }`}</StyledPricesInfoTitle>
              </StyledPricesInfoItem>
              <StyledPricesInfoItem>
                <StyledPricesInfoValue>
                  {roundNumber(Number(perOneToken0))}
                </StyledPricesInfoValue>
                <StyledPricesInfoTitle>{`${token0.symbol} ${t('per')} ${
                  token1.symbol
                }`}</StyledPricesInfoTitle>
              </StyledPricesInfoItem>
            </StyledPricesInfo>
          </StyledPricesContainer>
          <StyledToleranceContainer>
            <StyledToleranceTitle>
              {t('slippageTolerance')}
            </StyledToleranceTitle>
            <StyledToleranceValue>
              {liquiditySettings.slippage}%
            </StyledToleranceValue>
          </StyledToleranceContainer>
        </MainContainer>
        <ButtonContainer>
          {loading ? (
            <StyledButton
              disabled
              fullWidth
            >
              <div>{t('loading')}</div>
            </StyledButton>
          ) : token0?.amount.gt(firstTokenBalance?.value ?? '0') ? (
            <StyledButton
              disabled
              fullWidth
            >{`${t('insufficient')} ${token0?.symbol} ${t(
              'balance'
            )}`}</StyledButton>
          ) : token1.amount.gt(secondTokenBalance?.value ?? '0') ? (
            <StyledButton
              disabled
              fullWidth
            >{`${t('insufficient')} ${token1?.symbol} ${t(
              'balance'
            )}`}</StyledButton>
          ) : firstTokenAllowance ? (
            <StyledButton
              variant='contained'
              fullWidth
              onClick={() => tokenApprove(token0!, true)}
            >{`${t('approve')} ${token0?.symbol}`}</StyledButton>
          ) : loading ? (
            <StyledButton
              disabled
              fullWidth
            >
              {t('waitForApprove')}
            </StyledButton>
          ) : secondTokenAllowance ? (
            <StyledButton
              variant='contained'
              fullWidth
              onClick={() => tokenApprove(token1!, false)}
            >{`${t('approve')} ${token1?.symbol}`}</StyledButton>
          ) : loading ? (
            <StyledButton
              disabled
              fullWidth
            >
              {t('waitForApprove')}
            </StyledButton>
          ) : (
            <StyledButton
              variant='contained'
              fullWidth
              onClick={() => setIsConfirmModalOpen(true)}
              disabled={
                Number(firstTokenValue) <= 0 || Number(secondTokenValue) <= 0
              }
            >
              {t('supply')}
            </StyledButton>
          )}
        </ButtonContainer>
      </StyledContainer>
      <ConfirmLiquidityV2Popup
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        fee={fee}
        token0={token0}
        token1={token1}
        liquidityId={liquidityId}
        firstTokenPerSecondToken={roundNumber(Number(perOneToken1))}
        secondTokenPerFirstToken={roundNumber(Number(perOneToken0))}
        clearTokensValue={() => {
          setFirstTokenValue('');
          setSecondTokenValue('');
          setFirstTokenValueInUsd('');
          setSecondTokenValueInUsd('');
          setPercentageFirstToken(0);
          setPercentageSecondToken(0);
        }}
      />
    </>
  );
};

export default AddLiquidityV2;
