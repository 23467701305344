import { styled, Button } from '@mui/material';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  width: 'inherit',
  padding: '18px 32px',
  top: '65%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  color: Color.WHITE,

  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.LITTLE,
    padding: '12px 30px',
    top: '60%',
  },
}));

export const StyledText = styled('p')(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
  fontWeight: 'bold',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));
