import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useTranslation } from 'react-i18next';

import { PageRoutes } from 'config/routes';
import ChatIcon from 'svg/ChatIcon';
import { TabButton, TabsWrapper } from 'components/SideMenu/styles';
import { Color } from 'helpers/themeStyles';
import SideBarBottomSection from 'components/SideBarBottomSection';
import SliderMobileMenu from 'components/SliderMobileMenu';
import { TAWK_TO_PROPERTY_ID, TAWK_TO_WIDGET_ID } from 'helpers/constants';
import LaunchpadMobileIcon from 'svg/LaunchpadMobileIcon';
import HelpMobileIcon from 'svg/HelpMobileIcon';
import FAQMobileIcon from 'svg/FAQMobileIcon';

const SideMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [value, setValue] = useState('');
  const tawkMessengerRef = useRef();
  const [ref, setRef] = useState();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    const element = tawkMessengerRef?.current;
    if (element) {
      setRef(element);
    }
  }, [value]);

  useEffect(() => {
    if (value === 'chat') {
      navigate(`${PageRoutes.Chat}`);
    }
    if (value === 'launchpad') {
      navigate(`${PageRoutes.Launchpad}`);
    }
    if (value === 'faq') {
      // TO DO - enter a proper link from customers
      window.open('', '_blank');
    }
  }, [value]);

  return (
    <>
      {isMobile && (!value || value === 'faq') && (
        <div style={{ padding: '16px' }}>
          <SliderMobileMenu />
          <TabsWrapper
            value={value}
            onChange={handleChange}
            aria-label='side menu tabs'
          >
            <TabButton
              sx={{
                svg: {
                  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
                },
              }}
              icon={<LaunchpadMobileIcon />}
              value='launchpad'
              label={t('launchpad')}
            />
            <TabButton
              onClick={() => {
                //@ts-ignore
                ref?.toggle();
              }}
              sx={{
                svg: {
                  background: Color.BUTTON_GRADIENT_BLUE_TO_GREEN,
                },
              }}
              icon={<HelpMobileIcon />}
              value='help'
              label={t('help')}
            />
            <TabButton
              sx={{
                svg: {
                  marginTop: '16px',
                  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
                },
              }}
              icon={<ChatIcon />}
              value='chat'
              label={<span style={{ width: '70px' }}>{t('demoDexChat')}</span>}
            />
            <TabButton
              sx={{
                svg: {
                  marginTop: '16px',
                  background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
                },
              }}
              icon={<FAQMobileIcon />}
              value='faq'
              label={t('faq')}
            />
          </TabsWrapper>
          <SideBarBottomSection isHovering />
        </div>
      )}
      <TawkMessengerReact
        propertyId={TAWK_TO_PROPERTY_ID}
        widgetId={TAWK_TO_WIDGET_ID}
        onLoad={() => {
          //@ts-ignore
          tawkMessengerRef?.current?.hideWidget();
        }}
        onChatMinimized={() => {
          setValue('');
        }}
        ref={tawkMessengerRef}
      />
    </>
  );
};

export default SideMenu;
