import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import { deleteProjectById } from 'state/launchpad/thunks';
import RemoveIcon from 'svg/RemoveIcon';
import {
  StyledContainer,
  StyledDialog,
  StyledBtn,
  StyledTitle,
  StyledBtnContainer,
  StyledSubtitle,
  StyledCancelBtn,
} from './styles';

const DeleteProjectPopup = ({
  isOpen,
  onClose,
  projectId,
}: {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledContainer>
        <RemoveIcon
          width='42'
          height='48'
        />
        <StyledTitle style={{ margin: '18px 0 12px', textAlign: 'center' }}>
          {t('areYouSureYouWantToDeleteYourProject')}
        </StyledTitle>
        <StyledSubtitle>
          {t('allImagesTextAndInformationWillBeDeleted')}
        </StyledSubtitle>
        <StyledBtnContainer direction={isMobile ? 'column' : 'row'}>
          <StyledBtn
            onClick={() => {
              dispatch(deleteProjectById({ id: projectId }));
              window.location.reload();
            }}
          >
            {t('delete')}
          </StyledBtn>
          <StyledCancelBtn onClick={onClose}>{t('cancel')}</StyledCancelBtn>
        </StyledBtnContainer>
      </StyledContainer>
    </StyledDialog>
  );
};
export default DeleteProjectPopup;
