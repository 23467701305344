import React from 'react';

const BottomArrowIcon = () => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 20.5L12 4.5M12 20.5L6 14.5M12 20.5L18 14.5'
        stroke='#CECECE'
        strokeWidth='2'
      />
    </svg>
  );
};

export default BottomArrowIcon;
