import React from 'react';

const AvatarIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00001 6.91675C6.04168 6.91675 5.26043 6.61466 4.65626 6.0105C4.05209 5.40633 3.75001 4.62508 3.75001 3.66675C3.75001 2.70841 4.05209 1.92716 4.65626 1.323C5.26043 0.718831 6.04168 0.416748 7.00001 0.416748C7.95834 0.416748 8.73959 0.718831 9.34376 1.323C9.94793 1.92716 10.25 2.70841 10.25 3.66675C10.25 4.62508 9.94793 5.40633 9.34376 6.0105C8.73959 6.61466 7.95834 6.91675 7.00001 6.91675ZM12.375 13.8542H1.62501C1.23612 13.8542 0.902788 13.7154 0.62501 13.4376C0.347232 13.1598 0.208344 12.8265 0.208344 12.4376V11.7709C0.208344 11.2154 0.347232 10.7397 0.62501 10.3438C0.902788 9.948 1.25695 9.64592 1.68751 9.43758C2.63195 9.02092 3.53473 8.70842 4.39584 8.50008C5.25696 8.29175 6.12501 8.18758 7.00001 8.18758C7.87501 8.18758 8.73959 8.29522 9.59376 8.5105C10.4479 8.72578 11.3472 9.04175 12.2917 9.45842C12.7361 9.65286 13.0972 9.948 13.375 10.3438C13.6528 10.7397 13.7917 11.2154 13.7917 11.7709V12.4376C13.7917 12.8265 13.6528 13.1598 13.375 13.4376C13.0972 13.7154 12.7639 13.8542 12.375 13.8542ZM1.62501 12.4376H12.375V11.7917C12.375 11.5695 12.309 11.3577 12.1771 11.1563C12.0451 10.9549 11.882 10.8056 11.6875 10.7084C10.8125 10.2917 10.0104 10.0036 9.28126 9.84383C8.55209 9.68411 7.79168 9.60425 7.00001 9.60425C6.20834 9.60425 5.44098 9.68411 4.69793 9.84383C3.95487 10.0036 3.15279 10.2917 2.29168 10.7084C2.09723 10.8056 1.93751 10.9549 1.81251 11.1563C1.68751 11.3577 1.62501 11.5695 1.62501 11.7917V12.4376ZM7.00001 5.50008C7.52779 5.50008 7.96529 5.32647 8.31251 4.97925C8.65973 4.63203 8.83334 4.19453 8.83334 3.66675C8.83334 3.13897 8.65973 2.70147 8.31251 2.35425C7.96529 2.00703 7.52779 1.83341 7.00001 1.83341C6.47223 1.83341 6.03473 2.00703 5.68751 2.35425C5.34029 2.70147 5.16668 3.13897 5.16668 3.66675C5.16668 4.19453 5.34029 4.63203 5.68751 4.97925C6.03473 5.32647 6.47223 5.50008 7.00001 5.50008Z'
        fill='white'
      />
    </svg>
  );
};

export default AvatarIcon;
