import { ProjectDetails } from 'types/project';

export const initialValues = (currentProject: ProjectDetails) => ({
  logo: currentProject?.logo.length ? currentProject?.logo : '',
  heroPicture: currentProject?.heroPicture.length
    ? currentProject?.heroPicture
    : '',
  teamMembers: currentProject?.teamMembers || [
    { name: '', position: '', about: '', linkedIn: '' },
  ],
  productImage: currentProject?.navigation[0]?.picture || '',
  problemImage: currentProject?.navigation[1]?.picture || '',
  tokenomicsImage: currentProject?.navigation[2]?.picture || '',
  roadmapImage: currentProject?.navigation[3]?.picture || '',
  businessmodelImage: currentProject?.navigation[4]?.picture || '',
  investorsImage: currentProject?.navigation[5]?.picture || '',
  status: 'pending',
  navigation: !currentProject?.poolInformation?.amountFlexible
    ? [
        {
          subtitle: 'Product',
          details: currentProject?.navigation[0]?.details || '',
        },
        {
          subtitle: 'Problem',
          details: currentProject?.navigation[1]?.details || '',
        },
        {
          subtitle: 'Tokenomics',
          details: currentProject?.navigation[2]?.details || '',
        },
        {
          subtitle: 'Roadmap',
          details: currentProject?.navigation[3]?.details || '',
        },
        {
          subtitle: 'BusinessModel',
          details: currentProject?.navigation[4]?.details || '',
        },
        {
          subtitle: 'Investors',
          details: currentProject?.navigation[5]?.details || '',
        },
      ]
    : [
        {
          subtitle: 'Product',
          details: currentProject?.navigation[0]?.details || '',
        },
        {
          subtitle: 'Problem',
          details: currentProject?.navigation[1]?.details || '',
        },
        {
          subtitle: 'Tokenomics',
          details: currentProject?.navigation[2]?.details || '',
        },
        {
          subtitle: 'Roadmap',
          details: currentProject?.navigation[3]?.details || '',
        },
        {
          subtitle: 'BusinessModel',
          details: currentProject?.navigation[4]?.details || '',
        },
        {
          subtitle: 'Investors',
          details: currentProject?.navigation[5]?.details || '',
        },
        {
          subtitle: 'DescriptionOfBudgetDistribution',
          details: currentProject?.navigation[6]?.details || '',
        },
      ],
});
