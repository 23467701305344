import { SyntheticEvent, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  XAxis,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';

import { CustomSlider } from './styles';
import { ChartEntry } from 'types/chartEntry';
import { Color, TextSize } from 'helpers/themeStyles';
import './style.css';

const LiquidityChart = ({
  data,
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
  isFullRange,
  currentPrice,
  setTickLowerMin,
  setTickLowerMax,
}: {
  data: ChartEntry[] | undefined;
  minPrice: number;
  maxPrice: number;
  setMaxPrice: (v: string) => void;
  setMinPrice: (v: string) => void;
  setTickLowerMin: (v: number) => void;
  setTickLowerMax: (v: number) => void;
  isFullRange: boolean;
  currentPrice?: string;
}) => {
  const [value, setValue] = useState<number[]>([0, data! && data.length - 1]);
  const [closestCurrent, setClosestCurrent] = useState<number>();
  const [closestPriceIndex, setClosestIndex] = useState<number>();
  const [isLongNumber, setIsLongNumber] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const intervalParts = isMobile ? 4 : 6;

  const handleChange = (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue)) {
      setMinPrice(data![newValue[0]].price0.toString());
      setMaxPrice(data![newValue[1]].price0.toString());
      setTickLowerMin(data![newValue[0]].tick);
      setTickLowerMax(data![newValue[1]].tick);
    }
    return true;
  };
  useMemo(() => {
    if (data?.length) {
      const closestMin = data.reduce(function (prev, curr) {
        return Math.abs(curr.price0 - minPrice) <
          Math.abs(prev.price0 - minPrice)
          ? curr
          : prev;
      });
      const closestMax = data.reduce(function (prev, curr) {
        return Math.abs(curr.price0 - maxPrice) <
          Math.abs(prev.price0 - maxPrice)
          ? curr
          : prev;
      });

      const min = data.findIndex((el) => el.price0 === closestMin.price0);
      const max = data.findIndex((el) => el.price0 === closestMax.price0);
      setValue([min, max]);
    }
  }, [minPrice, maxPrice, data]);

  useMemo(() => {
    if (data?.length) {
      const closestCurrent = data.reduce(function (prev, curr) {
        return Math.abs(curr.price0 - Number(currentPrice)) <
          Math.abs(prev.price0 - Number(currentPrice))
          ? curr
          : prev;
      });

      const closestIndex = data.findIndex(
        (el) => el.price0 === closestCurrent.price0
      );
      const closest = data.find((el) => el.price0 === closestCurrent.price0);
      setClosestCurrent(closest?.price0);
      setClosestIndex(closestIndex);
    }
  }, [currentPrice, data]);
  const lastIndex = data && data!.length - 1;

  function CustomTooltip({
    active,
    label,
  }: {
    active: boolean;
    label: number;
  }) {
    if (active && label) {
      return (
        <div style={{ fontSize: TextSize.SUPER_SMALL }}>{`$${label.toFixed(
          5
        )}`}</div>
      );
    }
    return null;
  }

  return (
    <div style={{ width: !isMobile ? '430px' : '295px', height: '190px' }}>
      <ResponsiveContainer
        width='100%'
        height='100%'
      >
        <AreaChart
          width={430}
          margin={{ top: 0, right: 0, left: 35, bottom: isLongNumber ? 8 : 0 }}
          height={200}
          data={data}
        >
          <defs>
            <linearGradient id='color'>
              <stop
                offset='0%'
                stopColor='#E15096'
                opacity={0.7}
              />
              <stop
                offset='50%'
                stopColor='#AE2CB1'
                opacity={0.7}
              />
              <stop
                offset='100%'
                stopColor='#4132CF'
                opacity={0.7}
              />
            </linearGradient>
          </defs>
          <Area
            dataKey='activeLiquidity'
            fill='url(#color)'
            stroke='none'
          />

          <XAxis
            tickFormatter={(tick: number, index: number) => {
              let n = 1;
              if (tick === 0) {
                return '0';
              }
              if (tick < 1) {
                n = 5;
              }
              if (!isLongNumber && tick.toFixed(n).toString().length > 9)
                setIsLongNumber(true);
              return tick.toFixed(n);
            }}
            dataKey={'price0'}
            axisLine={false}
            tickLine={false}
            tick={{
              stroke: '#999999',
              strokeWidth: '0.2',
              width: '40',
            }}
            interval={Math.round(lastIndex! / intervalParts)}
            angle={isLongNumber ? -15 : 0}
            dy={isLongNumber ? 10 : 0}
          />

          <CartesianGrid
            horizontal={false}
            opacity={0.1}
          />
          <Tooltip
            content={
              <CustomTooltip
                active={true}
                label={1}
              />
            }
          />
          <ReferenceLine
            x={closestCurrent}
            stroke={Color.WHITE}
            opacity={0.6}
          />
          <ReferenceLine
            x={closestPriceIndex}
            stroke={Color.WHITE}
            opacity={0.6}
          />
        </AreaChart>
      </ResponsiveContainer>
      <CustomSlider
        disabled={isFullRange}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue as number[]);
        }}
        onChangeCommitted={handleChange}
        max={data && data.length - 1}
        min={0}
      />
    </div>
  );
};

export default LiquidityChart;
