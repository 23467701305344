const isFirstTokenChoosen = (
  tokenAlignment: string,
  firstTokenSymbol: string
) => {
  if (tokenAlignment === firstTokenSymbol) {
    return true;
  }
  return false;
};

export default isFirstTokenChoosen;
