import { format } from 'date-fns';
const dateFortmatter = (
  dateStr: string,
  options: string // format 'dd MMMM yyyy',
) => {
  const dateObj = new Date(dateStr);
  const formattedDate = format(dateObj, options);
  return formattedDate;
};

export default dateFortmatter;
