import { styled } from '@mui/material';

export const StyledUpcomingProjectsWrapper = styled('div')({
  marginTop: '70px',
});

export const StyledUpcomingProjectsList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  flexWrap: 'wrap',
  margin: '24px 0',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    gap: '48px',
  },
  [theme.breakpoints.down('mobile')]: {
    gap: '24px',
  },
}));