import { styled } from '@mui/system';
import { Step, StepLabel, Button, Typography } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  lineHeight: '150%',
  color: Color.WHITE,
  textAlign: 'center',
  marginTop: '24px',
  [theme.breakpoints.down('mobile')]: {
    textTransform: 'uppercase',
    fontSize: TextSize.MIDDLE_PLUS,
  },
}));

export const StyledStepperWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'calc(100% - 44px)',
  padding: '0 24px 24px',
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    padding: 0,
  },
}));

export const StyledStep = styled(Step)(({ theme }) => ({
  '& .MuiStepIcon-text': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.NORMAL,
  },
  [theme.breakpoints.down('mobile')]: {
    width: '20%',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '&.MuiStepLabel-root': {
    [theme.breakpoints.down('mobile')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
    },
  },
  '& .MuiStepLabel-label': {
    color: Color.WHITE_OPACITY_LIGHT,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.NORMAL,
    [theme.breakpoints.down('mobile')]: {
      textAlign: 'center',
      fontSize: TextSize.SMALL,
    },
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.MIDDLE,
    fontWeight: '700',
    [theme.breakpoints.down('mobile')]: {
      fontSize: TextSize.SMALL,
    },
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: Color.WHITE_OPACITY_LIGHT,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.NORMAL,
    fontWeight: '700',
    [theme.breakpoints.down('mobile')]: {
      fontSize: TextSize.SMALL,
    },
  },
}));

export const StyledStepperButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: BorderRadius.SMALL,
  background: Color.DARK_PURPLE_OPACITY,
  padding: '6px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));
export const StyledBackButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  fontWeight: 700,
  color: Color.WHITE,
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `2px solid ${Color.WHITE}`,
  padding: '16px 32px',
  [theme.breakpoints.down('mobile')]: {
    lineHeight: '140%',
    padding: '12px 28px',
  },
  [theme.breakpoints.down(390)]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));
export const btnAdditionalStyles = {
  textTransform: 'none',
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
};

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: Color.DARK_PURPLE_OPACITY,
      border: Border.TRANSPARENT_BORDER,
      backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: Color.DARK_PURPLE_OPACITY,
      border: Border.TRANSPARENT_BORDER,
      backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: Color.WHITE_OPACITY_LIGHT,
    borderTopWidth: 1,
    borderRadius: 1,
    [theme.breakpoints.down('mobile')]: {
      margin: '8px 0',
    },
  },
}));

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.NORMAL,
    fontWeight: '700',
  }),
  '& .QontoStepIcon-completedIcon': {
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
    border: 'none',
    textTransform: 'none',
    fontWeight: 700,
    lineHeight: '160%',
  },
  '& .QontoStepIcon-circle': {
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: Color.DARK_PURPLE_OPACITY,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
  },
}));
