export function roundNumber(num: number) {
  if (Math.abs(num) >= 1) {
    // Round to nearest integer
    return Math.round(num);
  } else if (Math.abs(num) >= 0.000000000001) {
    // Round to 12 decimal places
    return Number(num.toPrecision(12))
      .toFixed(15)
      .replace(/\.?0+$/, '');
  } else {
    // Round to 15 decimal places
    return num.toPrecision(15).replace(/\.?0+$/, '');
  }
}
