import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { PageRoutes } from 'config/routes';
import { Color } from 'helpers/themeStyles';
import { CustomNftTab } from 'pages/NftMarketplace/CustomNftTab';
import { StyledLaunchpadWrapper } from './styles';
import { projectsByOwnerSelector } from 'state/launchpad/selectors';
import {
  getProjectsByOwner,
  getUsersProjectByDay,
} from 'state/launchpad/thunks';

const LaunchpadPage = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const userID = localStorage.getItem('userId');
  const [value, setValue] = useState<number>(0);
  const projectsByOwner = useSelector(projectsByOwnerSelector);
  const currentLocation = window.location.href;
  const regexp = /my-projects|create-project/;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userID) {
      dispatch(getProjectsByOwner({ userID, limit: 4, page: 1 }));
    }
  }, []);

  useEffect(() => {
    if (regexp.test(currentLocation)) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [currentLocation, value]);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      dispatch(getUsersProjectByDay({ id: userId }));
    }
  }, []);
  return (
    <StyledLaunchpadWrapper>
      {!!projectsByOwner?.docs.length && (
        <Box sx={{ width: '100%', marginBottom: '30px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{
              '& .MuiTabs-indicator': {
                background: Color.BUTTON_EVEN_TAP_BAR_GRADIENT,
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'center',
                columnGap: isMobile ? '30px' : '0',
              },
            }}
          >
            <CustomNftTab
              label={t('overview')}
              component={Link}
              to={PageRoutes.Launchpad}
            />
            <CustomNftTab
              label={t('myProjects')}
              component={Link}
              to={PageRoutes.MyProjects}
            />
          </Tabs>
        </Box>
      )}
      {children}
    </StyledLaunchpadWrapper>
  );
};

export default LaunchpadPage;
