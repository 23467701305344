const ConnectWalletIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48.75 16.875L11.25 16.875C8.1434 16.875 5.625 19.3934 5.625 22.5L5.625 45C5.625 48.1066 8.1434 50.625 11.25 50.625L48.75 50.625C51.8566 50.625 54.375 48.1066 54.375 45L54.375 22.5C54.375 19.3934 51.8566 16.875 48.75 16.875Z'
        stroke='#11C243'
        strokeWidth='3.75'
        strokeLinejoin='round'
      />
      <path
        d='M48.2062 16.8751V13.3594C48.206 12.4972 48.0154 11.6456 47.6481 10.8655C47.2808 10.0854 46.7459 9.39592 46.0815 8.84635C45.4171 8.29677 44.6395 7.9006 43.8044 7.68612C42.9692 7.47164 42.097 7.44412 41.25 7.60554L10.3875 12.8731C9.04725 13.1285 7.83815 13.8436 6.96873 14.8951C6.09931 15.9466 5.62408 17.2685 5.625 18.6329L5.625 24.3751'
        stroke='#11C243'
        strokeWidth='3.75'
        strokeLinejoin='round'
      />
      <path
        d='M43.125 37.5C42.3833 37.5 41.6583 37.2801 41.0416 36.868C40.4249 36.456 39.9443 35.8703 39.6605 35.1851C39.3766 34.4998 39.3024 33.7458 39.4471 33.0184C39.5918 32.291 39.9489 31.6228 40.4734 31.0984C40.9978 30.5739 41.666 30.2168 42.3934 30.0721C43.1208 29.9274 43.8748 30.0016 44.5601 30.2855C45.2453 30.5693 45.831 31.0499 46.243 31.6666C46.6551 32.2833 46.875 33.0083 46.875 33.75C46.875 34.7446 46.4799 35.6984 45.7767 36.4017C45.0734 37.1049 44.1196 37.5 43.125 37.5Z'
        fill='#11C243'
      />
    </svg>
  );
};

export default ConnectWalletIcon;
