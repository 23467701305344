import { createSlice } from '@reduxjs/toolkit';

interface IState {
  isOpen: boolean;
}

const initialState: IState = {
  isOpen: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialState,
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { setIsOpen } = walletSlice.actions;
export default walletSlice;
