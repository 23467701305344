import { createSelector } from 'reselect';
import { RootState } from '..';

const liquidityTokenState = (state: RootState) => state.liquidity;
/**
 * @description Get data of first token
 */
export const firstLiquidityTokenSelector = createSelector(
  liquidityTokenState,
  ({ firstToken }) => firstToken
);
/**
 * @description Get data of second token
 */
export const secondLiquidityTokenSelector = createSelector(
  liquidityTokenState,
  ({ secondToken }) => secondToken
);

export const liquiditySettingsSelector = createSelector(
  liquidityTokenState,
  ({ liquiditySettings }) => liquiditySettings
);

export const isClearAllSelector = createSelector(
  liquidityTokenState,
  ({ isClearAll }) => isClearAll
);
