import {
  Button,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import {
  BorderRadius,
  Border,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const themes = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: Color.BACKGROUND,
          color: '#161616!important',
          boxShadow:
            '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 4px 99px rgba(0, 0, 0, 0.25)',
          border: Border.TRANSPARENT_BORDER,
          backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          borderRadius: BorderRadius.SMALL,
          opacity: 1,
          width: '412px!important',
          maxWidth: '412px!important',
        },
      },
    },
  },
});
export const StyledDialog = styled(Dialog)({});
export const StyledTitle = styled(DialogTitle)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
  padding: '16px',
  borderBottom: '2px solid rgba(176, 50, 214, 0.2)',
});
export const StyledContent = styled(DialogContent)({
  marginTop: '20px',
});
export const StyledValue = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: TextSize.MEDIUM,
  lineHeight: '150%',
  color: Color.WHITE,
});
export const StyledTokenSymbol = styled(Typography)({
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
});
export const StyledExchange = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  opacity: 0.8,
  color: Color.WHITE,
  paddingBottom: '8px',
  borderBottom: '1px solid gray',
});
export const StyledButton = styled(Button)({
  background:
    'linear-gradient(90deg, #F89D2F 0%, #E63E36 53.12%, #C81FAD 100%)',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  '&:hover': {
    background:
      'linear-gradient(90deg, #C81FAD 0%, #E63E36 53.12%, #F89D2F 100%)',
  },
});

export const StyledCloseIcon = styled(CloseIcon)({
  color: Color.WHITE,
});

export const StyledTokenContainer = styled(Grid)({
  background: 'rgba(176, 50, 214, 0.2)',
  borderRadius: BorderRadius.NORMAL,
  padding: '20px 16px 16px',
  flexDirection: 'column-reverse',
});

export const RevertButton = styled('div')(() => ({
  color: Color.WHITE,
  position: 'absolute',
  padding: ' 5px 7px',
  backgroundColor: Color.BACKGROUND,
  border: '4px solid #3C214D',
  borderRadius: BorderRadius.SMALL,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const StyledSwapTokensContainer = styled(Grid)({
  position: 'relative',
});

export const StyledAdditionalSwapInfo = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  color: Color.WHITE,
  padding: '8px 0px',
  borderBottom: '1px solid gray',
});

export const StyledMinimumReceived = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  color: Color.TEXT_OPACITY_LIGHT,
  paddingBottom: '16px',
  borderBottom: '1px solid gray',
});
export const StyledEstimatedOutputInfo = styled('p')({
  color: Color.TEXT_OPACITY_LIGHT,
  margin: 0,
  marginBottom: '16px',
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
});

export const StyledGridTitle = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: '600',
  color: Color.WHITE,
  marginTop: 24,
});

export const StyledGridSubtitle = styled(Grid)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: '600',
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE,
  textAlign: 'center',
  padding: '0 10px',
}));

export const StyledGridInfo = styled(Grid)({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});
