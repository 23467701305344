import React from 'react';

const FAQIcon: React.FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='32'
        height='32'
        rx='6'
        fill='white'
        fillOpacity='0.1'
      />
      <g clipPath='url(#clip0_3579_15744)'>
        <path
          d='M16 4C14.4308 4 13.2308 5.2 13.2308 6.76923V12.3077C13.2308 13.8769 14.4308 15.0769 16 15.0769H21.5385L25.2308 18.7692V15.0769C26.8 15.0769 28 13.8769 28 12.3077V6.76923C28 5.2 26.8 4 25.2308 4H16ZM19.8658 6.76923H21.4517L23.0098 12.3077H21.6252L21.2495 10.9231H19.8649L19.5197 12.3077H18.3077L19.8658 6.76923ZM20.6154 7.69231C20.5231 8.06154 20.4197 8.512 20.3265 8.78892L20.068 10H21.1637L20.9034 8.788C20.7197 8.512 20.6154 8.06154 20.6154 7.69231ZM6.76923 13.2308C5.2 13.2308 4 14.4308 4 16V21.5385C4 23.1077 5.2 24.3077 6.76923 24.3077V28L10.4615 24.3077H16C17.5692 24.3077 18.7692 23.1077 18.7692 21.5385V16H16C14.2462 16 12.856 14.8 12.3945 13.2308H6.76923ZM11.0098 15.9132C12.5791 15.9132 13.3175 17.2055 13.3175 18.6825C13.3175 19.9748 12.8735 20.7945 12.1351 21.1637C12.5043 21.3483 12.9418 21.4462 13.4034 21.5385L13.0582 22.4615C12.412 22.2769 11.7418 21.9889 11.0957 21.7111C11.0034 21.6188 10.8418 21.6252 10.7495 21.6252C9.64185 21.5329 8.61538 20.6154 8.61538 18.7692C8.61538 17.2 9.53292 15.9132 11.0098 15.9132ZM11.0098 16.9231C10.2714 16.9231 9.91323 17.7538 9.91323 18.7692C9.91323 19.8769 10.2714 20.6154 11.0098 20.6154C11.7483 20.6154 12.1342 19.7846 12.1342 18.7692C12.1342 17.7538 11.7483 16.9231 11.0098 16.9231Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3579_15744'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(4 4)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FAQIcon;
