import { Button, OutlinedInput, styled } from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const SwapSettingsContainer = styled('div')(() => ({
  borderRadius: BorderRadius.SMALL,
  background: Color.DARK_PURPLE_OPACITY,
  padding: '6px',
  width: '20px',
  height: '21px',
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  cursor: 'pointer',
}));
export const SubtitleWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '14px',
  gap: '10px',
}));
export const StyledDivider = styled('div')(() => ({
  width: '100%',
  height: '1px',
  background: Color.WHITE_OPACITY,
  margin: '14px 0',
  display: 'block',
}));
export const StyledSubtitle = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: TextSize.SMALL,
}));
export const StyledSmallSubtitle = styled('span')(() => ({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
}));
export const StyledTitle = styled('h3')(() => ({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  margin: 0,
}));
export const ItemWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const ItemInputWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px',
}));
export const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  padding: '8px',
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  border: `1px solid ${Color.WHITE_OPACITY_LIGHT}`,
  borderRadius: BorderRadius.SMALL,
  height: '40px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 400,
  color: Color.WHITE,
  '& ::placeholder': {
    color: Color.WHITE_OPACITY_LIGHT,
    textAlign: 'right',
  },
  '& .MuiTypography-root': {
    color: Color.WHITE,
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    textAlign: 'right',
  },
}));
export const StyledButton = styled(Button)(() => ({
  height: '40px',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  background: Color.PURPLE,
  borderRadius: BorderRadius.SMALL,
  '&:hover': {
    color: Color.WHITE,
    background: Color.PURPLE,
  },
}));
export const StyledConfirmButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  marginTop: '40px',
  padding: '9px',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
}));
