import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { Close, HelpOutline, ReportProblemRounded } from '@mui/icons-material';

import {
  ItemInputWrap,
  StyledButton,
  StyledConfirmButton,
  StyledDivider,
  StyledOutlinedInput,
  StyledSmallSubtitle,
  StyledSubtitle,
  StyledTitle,
  SubtitleWrap,
  SwapSettingsContainer,
} from './styles';
import { LightTooltip, StyledMenu } from './StyledComponents';
import SettingsIcon from 'svg/SettingsIcon';
import { Color } from 'helpers/themeStyles';
import { setSwapSettings } from 'state/swap/slice';
import { setLiquiditySettings } from 'state/liquidity/slice';
import {
  SLIPPAGE_TOLERANCE_LAST_VALID_VALUE,
  TRANSACTION_DEADLINE_MAX_VALUE,
} from 'helpers/constants';
import { Percent } from '@omisoftnet/game-dex-sdk';

type SwapSettingsProps = {
  fromLiquidity?: boolean;
  clearAllSettings?: boolean;
  setClearAllSettings?: (clear: boolean) => void;
};

export default function SwapSettings({
  clearAllSettings,
  setClearAllSettings,
  fromLiquidity,
}: SwapSettingsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [slippageTolerance, setSlippageTolerance] = useState('');
  const [transactionDeadline, setTransactionDeadline] = useState('');
  const [openFirstTooltip, setOpenFirstTooltip] = useState(false);
  const [openSecondTooltip, setOpenSecondTooltip] = useState(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const isSlippageToleranceLessMinimum =
    Number(slippageTolerance) < 0.05 &&
    Number(slippageTolerance) >= 0 &&
    !slippageTolerance.includes('-') &&
    slippageTolerance.length > 0;

  const isTransactionMayBeFrontrun =
    Number(slippageTolerance) > 1 &&
    Number(slippageTolerance) <= SLIPPAGE_TOLERANCE_LAST_VALID_VALUE;

  const isTransactionDeadlineError =
    Number(transactionDeadline) < 1 ||
    Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE;

  const isErrorSlippagePercentage =
    Number(slippageTolerance) > SLIPPAGE_TOLERANCE_LAST_VALID_VALUE ||
    Number(slippageTolerance) < 0 ||
    slippageTolerance.includes('-');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!fromLiquidity) {
      setTimeout(() => {
        dispatch(
          setSwapSettings({
            slippage: new Percent(
              Number(slippageTolerance.length > 0 ? slippageTolerance : 5) *
                100,
              10_000
            ),
            deadline: Math.floor(
              Date.now() / 1000 +
                60 *
                  Number(
                    transactionDeadline.length > 0 ? transactionDeadline : 20
                  )
            ),
          })
        );
      }, 1000);
    } else {
      if (slippageTolerance === '' && transactionDeadline === '') {
        dispatch(
          setLiquiditySettings({
            slippage: '0.5',
            deadline: '14',
          })
        );
      } else if (slippageTolerance === '') {
        dispatch(
          setLiquiditySettings({
            slippage: '0.5',
            deadline: transactionDeadline,
          })
        );
      } else if (transactionDeadline === '') {
        dispatch(
          setLiquiditySettings({
            slippage: slippageTolerance,
            deadline: '14',
          })
        );
      } else {
        dispatch(
          setLiquiditySettings({
            slippage: slippageTolerance,
            deadline: transactionDeadline,
          })
        );
      }
    }
  }, [slippageTolerance, transactionDeadline, fromLiquidity]);
  useEffect(() => {
    if (Number(slippageTolerance) > 50) {
      return () => setSlippageTolerance('');
    }
  }, []);
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!open) {
      return;
    }
    e.preventDefault();
    if (isErrorSlippagePercentage && open) setSlippageTolerance('');
    if (slippageTolerance.replace(/^0+/, '').length === 1) {
      setSlippageTolerance(`${slippageTolerance.slice(-1)}.00`);
    } else if (
      slippageTolerance !== slippageTolerance.replace(/^0+/, '') &&
      !slippageTolerance.includes('.')
    ) {
      setSlippageTolerance(`${slippageTolerance.slice(-2)}.00`);
    } else if (
      slippageTolerance.includes('.') &&
      slippageTolerance.length > 4 &&
      Number(slippageTolerance) < 50
    ) {
      setSlippageTolerance(
        String(Math.trunc(Number(slippageTolerance) * 100) / 100)
      );
    }
    if (
      transactionDeadline[0] < '4' &&
      Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE
    ) {
      setTransactionDeadline(transactionDeadline.slice(0, 4));
    } else if (
      transactionDeadline[0] >= '4' &&
      Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE
    ) {
      setTransactionDeadline(transactionDeadline.slice(0, 3));
    }
    if (
      transactionDeadline.startsWith('0') &&
      Number(transactionDeadline) > TRANSACTION_DEADLINE_MAX_VALUE
    ) {
      setTransactionDeadline(
        transactionDeadline.replace(/^0+/, '').slice(0, 3)
      );
    } else if (transactionDeadline.startsWith('0')) {
      setTransactionDeadline(transactionDeadline.replace(/^0+/, ''));
    }
  };
  useEffect(() => {
    if (clearAllSettings && setClearAllSettings) {
      setSlippageTolerance('');
      setTransactionDeadline('');
      setClearAllSettings(false);
    }
  }, [clearAllSettings]);

  const handleFirstTooltipClose = () => {
    setOpenFirstTooltip(false);
  };

  const handleFirstTooltipOpen = () => {
    setOpenFirstTooltip(true);
  };

  const handleSecondTooltipClose = () => {
    setOpenSecondTooltip(false);
  };

  const handleSecondTooltipOpen = () => {
    setOpenSecondTooltip(true);
  };

  return (
    <div onClick={(e) => onClickHandler(e)}>
      <SwapSettingsContainer onClick={handleClick}>
        <SettingsIcon />
      </SwapSettingsContainer>

      <StyledMenu
        anchorOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: isMobile ? 'center' : 'right',
        }}
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mb={2}
        >
          <StyledTitle>{t('settings')}</StyledTitle>
          <IconButton
            sx={{ p: 0 }}
            onClick={handleClose}
          >
            <Close sx={{ color: Color.WHITE, fontSize: '20px' }} />
          </IconButton>
        </Stack>
        <SubtitleWrap>
          <StyledSubtitle>{t('slippageTolerance')}</StyledSubtitle>
          {!isMobile ? (
            <LightTooltip
              title={t('slippageToleranceTip')!}
              placement='left'
            >
              <HelpOutline
                sx={{
                  cursor: 'pointer',
                  color: Color.WHITE_OPACITY_LIGHT,
                  '&:hover': { color: 'lightgrey' },
                }}
              />
            </LightTooltip>
          ) : (
            <ClickAwayListener onClickAway={handleFirstTooltipClose}>
              <div>
                <LightTooltip
                  title={t('slippageToleranceTip')!}
                  placement='bottom-start'
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleFirstTooltipClose}
                  open={openFirstTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <HelpOutline
                    onClick={handleFirstTooltipOpen}
                    sx={{
                      cursor: 'pointer',
                      color: Color.WHITE_OPACITY_LIGHT,
                      '&:hover': { color: 'lightgrey' },
                    }}
                  />
                </LightTooltip>
              </div>
            </ClickAwayListener>
          )}
        </SubtitleWrap>
        <ItemInputWrap>
          <StyledButton onClick={() => setSlippageTolerance('0.5')}>
            {t('auto')}
          </StyledButton>
          <FormControl variant='outlined'>
            <StyledOutlinedInput
              id='outlined-adornment-weight'
              placeholder='0.50'
              style={{
                color: isErrorSlippagePercentage ? Color.RED : '',
              }}
              value={slippageTolerance === '' ? '' : slippageTolerance}
              onChange={(event) => {
                const result = event.target.value.replace(
                  /\.(?=.*\.)|[^\d\.-]/g,
                  ''
                );
                setSlippageTolerance(result);
              }}
              error={isErrorSlippagePercentage}
              startAdornment={
                (isTransactionMayBeFrontrun ||
                  isSlippageToleranceLessMinimum) && (
                  <InputAdornment position='start'>
                    <ReportProblemRounded sx={{ color: Color.YELLOW }} />
                  </InputAdornment>
                )
              }
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
            />
          </FormControl>
        </ItemInputWrap>
        {isTransactionMayBeFrontrun && (
          <StyledSmallSubtitle
            style={{ marginTop: '12px', display: 'block', color: Color.YELLOW }}
          >
            {t('transactionFrontrun')}
          </StyledSmallSubtitle>
        )}
        {isSlippageToleranceLessMinimum && (
          <StyledSmallSubtitle
            style={{ marginTop: '12px', display: 'block', color: Color.YELLOW }}
          >
            {t('transactionMayFail')}
          </StyledSmallSubtitle>
        )}
        {isErrorSlippagePercentage && (
          <StyledSmallSubtitle
            style={{
              marginTop: '12px',
              display: 'block',
              color: Color.RED,
            }}
          >
            {t('errorSlippagePercentage')}
          </StyledSmallSubtitle>
        )}
        <StyledDivider />
        <SubtitleWrap>
          <StyledSubtitle>{t('transactionDeadline')}</StyledSubtitle>
          {!isMobile ? (
            <LightTooltip
              title={t('transactionDeadlineTip')!}
              placement='left'
            >
              <HelpOutline
                sx={{
                  cursor: 'pointer',
                  color: Color.WHITE_OPACITY_LIGHT,
                  '&:hover': { color: 'lightgrey' },
                }}
              />
            </LightTooltip>
          ) : (
            <ClickAwayListener onClickAway={handleSecondTooltipClose}>
              <div>
                <LightTooltip
                  title={t('transactionDeadlineTip')!}
                  placement='bottom-start'
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleSecondTooltipClose}
                  open={openSecondTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <HelpOutline
                    onClick={handleSecondTooltipOpen}
                    sx={{
                      cursor: 'pointer',
                      color: Color.WHITE_OPACITY_LIGHT,
                      '&:hover': { color: 'lightgrey' },
                    }}
                  />
                </LightTooltip>
              </div>
            </ClickAwayListener>
          )}
        </SubtitleWrap>
        <ItemInputWrap>
          <FormControl
            variant='outlined'
            sx={{ width: '86px' }}
          >
            <StyledOutlinedInput
              id='outlined-adornment-weight'
              placeholder='30'
              value={transactionDeadline}
              style={{
                color:
                  isTransactionDeadlineError && transactionDeadline.length !== 0
                    ? Color.RED
                    : '',
              }}
              onChange={(event) => {
                const result = event.target.value.replace(/\D/g, '');
                setTransactionDeadline(result);
              }}
              error={
                isTransactionDeadlineError && transactionDeadline.length !== 0
              }
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
            />
          </FormControl>
          <StyledSmallSubtitle>{t('minutes')}</StyledSmallSubtitle>
        </ItemInputWrap>
        {isMobile && (
          <StyledConfirmButton
            variant='contained'
            fullWidth
            onClick={handleClose}
          >
            {t('confirm')}
          </StyledConfirmButton>
        )}
      </StyledMenu>
    </div>
  );
}
