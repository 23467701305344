import { DEXToken } from '@omisoftnet/game-dex-sdk';

export const stableTokensForSwapUsdt: { [key: number]: DEXToken } = {
  56: DEXToken.fromAddress(
    '0x55d398326f99059fF775485246999027B3197955',
    undefined,
    56,
    18,
    'USDT',
    'Binance Pegged USDT',
    'https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png'
  ), // BNB Smart Chain
  97: DEXToken.fromAddress(
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    undefined,
    97,
    18,
    'BUSD',
    'BUSD Token',
    'https://github.com/bnb-chain/tokens-info/raw/master/tokens/bnb/bnb.png'
  ),
  // BSC Testnet
  5: DEXToken.fromAddress(
    '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49',
    undefined,
    5,
    6,
    'USDT',
    'USDT',
    'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663'
  ),
  1: DEXToken.fromAddress(
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    undefined,
    1,
    6,
    'USDT',
    'Tether',
    'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663'
  ),
  // Ethereum
};
