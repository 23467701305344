import { ArrowBack } from '@mui/icons-material';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DEXToken,
  PancakePair,
  PancakeProvider,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { useSigner } from '@hooks/useSigner';
import LiquiditySettings from 'components/LiquiditySettings';
import RemoveLiquidityV2Submitter from 'components/RemoveLiquidityV2Submitter';
import { roundNumber } from 'helpers/roundNumber';
import { dexSelector, signerSelector } from 'state/dex/selectors';
import {
  RemoveBtnContainer,
  StyledButton,
  StyledColouredContainer,
  StyledDialog,
  StyledDialogSection,
  StyledDialogTitle,
  StyledHeadingContainer,
  StyledIconButton,
  StyledMainContainer,
  StyledPercent,
  StyledRowContainer,
  StyledSectionTitle,
  StyledSmallText,
  StyledText,
  TokenDetails,
  TokenPrice,
} from './styles';
const RemoveLiquidityV2Modal = ({
  isOpen,
  onClose,
  firstTokenSymbol,
  secondTokenSymbol,
  firstTokenIcon,
  secondTokenIcon,
  firstTokenValue,
  secondTokenValue,
  slippageTolerance,
  liquidity,
  firstTokenAddress,
  secondTokenAddress,
  firstTokenChainId,
  secondTokenChainId,
  firstTokenDecimals,
  secondTokenDecimals,
  firstTokenName,
  secondTokenName,
  liquidityId,
  perOneToken0,
  perOneToken1,
}: {
  isOpen: boolean;
  onClose: () => void;
  firstTokenSymbol: string;
  secondTokenSymbol: string;
  firstTokenIcon: string;
  secondTokenIcon: string;
  firstTokenValue?: string;
  secondTokenValue?: string;
  slippageTolerance?: number;
  liquidity: string;
  firstTokenAddress: string;
  secondTokenAddress: string;
  firstTokenChainId: number;
  secondTokenChainId: number;
  firstTokenDecimals: number;
  secondTokenDecimals: number;
  firstTokenName: string;
  secondTokenName: string;
  liquidityId: string;
  perOneToken0: string;
  perOneToken1: string;
}) => {
  const { t } = useTranslation();
  const dex = useSelector(dexSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [isSubmitterOpen, setIsSubmitterOpen] = useState<boolean>(false);
  const [liquidityBalance, setLiquidityBalance] = useState<string>('');
  const [enableToken, setEnableToken] = useState<boolean>(false);

  //substitute actual data after implementing the logic
  const { address, chain } = useAccount();
  const signer = useSelector(signerSelector);

  const firstToken = DEXToken.fromAddress(
    firstTokenAddress,
    undefined,
    firstTokenChainId,
    firstTokenDecimals,
    firstTokenSymbol,
    firstTokenName,
    firstTokenIcon
  );
  const secondToken = DEXToken.fromAddress(
    secondTokenAddress,
    undefined,
    secondTokenChainId,
    secondTokenDecimals,
    secondTokenSymbol,
    secondTokenName,
    secondTokenIcon
  );

  async function fetchLiquidityBalance() {
    if (!dex) return;
    const provider = await dex.getDEXProvider(
      firstTokenChainId,
      firstToken,
      secondToken
    );
    const factoryAddress = await provider.factory();
    const pair = await PancakePair.from(
      firstToken,
      secondToken,
      signer!,
      factoryAddress
    );

    setLiquidityBalance(
      utils.formatUnits(`${await pair.balanceOf(address!)}`, 18).slice(0, 16)
    );
  }

  const approveCakeLpToken = async () => {
    if (!dex) return;
    firstToken.amount = utils.parseUnits(
      String(firstTokenValue!).slice(0, firstTokenDecimals),
      firstTokenDecimals
    );
    secondToken.amount = utils.parseUnits(
      String(secondTokenValue!).slice(0, secondTokenDecimals),
      secondTokenDecimals
    );
    const provider = await dex.getDEXProvider(chain!.id);
    if (provider instanceof PancakeProvider) {
      const factoryAddress = await provider.factory();
      const pair = await PancakePair.from(
        firstToken,
        secondToken,
        signer!,
        factoryAddress
      );
      try {
        await pair.approve(provider.routerAddress);
        setEnableToken(true);
      } catch (error) {
        setEnableToken(false);
      }
    }
  };

  useEffect(() => {
    fetchLiquidityBalance();
  }, []);

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => onClose()}
      >
        <StyledDialogSection style={{ width: !isMobile ? 531 : '100%' }}>
          <StyledHeadingContainer>
            <StyledIconButton onClick={() => onClose()}>
              <ArrowBack />
            </StyledIconButton>
            <StyledDialogTitle>
              {t('removeLiquidityV2', {
                firstToken: firstTokenSymbol,
                secondToken: secondTokenSymbol,
              })}
            </StyledDialogTitle>
            <LiquiditySettings />
          </StyledHeadingContainer>
          <StyledMainContainer>
            <StyledRowContainer mb={1}>
              <StyledSectionTitle>{t('receive')}</StyledSectionTitle>
            </StyledRowContainer>
            <StyledColouredContainer>
              <StyledRowContainer
                gap={1}
                mb={1.5}
              >
                <TokenDetails>
                  <img
                    src={firstTokenIcon}
                    alt={firstTokenSymbol}
                    width={24}
                    height={24}
                  />
                  <StyledText>{firstTokenSymbol}</StyledText>
                </TokenDetails>
                <TokenPrice></TokenPrice>
                <StyledText sx={{ fontWeight: 600 }}>
                  {firstTokenValue?.slice(0, 18)}
                </StyledText>
                <StyledPercent>(50%)</StyledPercent>
              </StyledRowContainer>
              <StyledRowContainer
                gap={1}
                mb={0.5}
              >
                <TokenDetails>
                  <img
                    src={secondTokenIcon}
                    alt={secondTokenSymbol}
                    width={24}
                    height={24}
                  />
                  <StyledText>{secondTokenSymbol}</StyledText>
                </TokenDetails>
                <TokenPrice></TokenPrice>
                <StyledText sx={{ fontWeight: 600 }}>
                  {secondTokenValue?.slice(0, 18)}
                </StyledText>
                <StyledPercent>(50%)</StyledPercent>
              </StyledRowContainer>
            </StyledColouredContainer>
            <StyledSectionTitle
              mt={2}
              mb={1}
            >
              {t('prices')}
            </StyledSectionTitle>
            <StyledColouredContainer style={{ marginBottom: 12 }}>
              <StyledRowContainer mb={1.5}>
                <StyledText>{`1 ${firstTokenSymbol} = `}</StyledText>
                <StyledText sx={{ fontWeight: 600 }}>
                  {roundNumber(Number(perOneToken1))} {secondTokenSymbol}
                </StyledText>
              </StyledRowContainer>
              <StyledRowContainer>
                <StyledText>{`1 ${secondTokenSymbol} = `}</StyledText>
                <StyledText sx={{ fontWeight: 600 }}>
                  {roundNumber(Number(perOneToken0))} {firstTokenSymbol}
                </StyledText>
              </StyledRowContainer>
            </StyledColouredContainer>
          </StyledMainContainer>
          <RemoveBtnContainer>
            <StyledRowContainer gap={2}>
              <StyledButton
                onClick={approveCakeLpToken}
                disabled={enableToken}
              >
                {t('enable')}
              </StyledButton>
              <StyledButton
                onClick={() => setIsSubmitterOpen(true)}
                disabled={!enableToken}
              >
                {t('remove')}
              </StyledButton>
            </StyledRowContainer>
          </RemoveBtnContainer>
        </StyledDialogSection>
        <StyledDialogSection
          style={{
            width: !isMobile ? 412 : '90%',
            margin: '16px auto 0',
            padding: 16,
          }}
        >
          <StyledSectionTitle>{t('lpTokensInYourWallet')}</StyledSectionTitle>
          <StyledColouredContainer style={{ margin: '8px 0 12px' }}>
            <StyledRowContainer gap={1}>
              <div>
                <img
                  src={firstTokenIcon}
                  alt={firstTokenSymbol}
                  width={24}
                  height={24}
                />
                <img
                  src={secondTokenIcon}
                  alt={secondTokenSymbol}
                  width={24}
                  height={24}
                  style={{ marginLeft: '-5px' }}
                />
              </div>
              <StyledText sx={{ fontWeight: 600, flexGrow: 1 }}>
                {`${firstTokenSymbol}/${secondTokenSymbol} ${t('lp')}`}
              </StyledText>
              <Stack>
                <StyledText style={{ textAlign: 'end' }}>
                  {liquidityBalance}
                </StyledText>
              </Stack>
            </StyledRowContainer>
          </StyledColouredContainer>
          <StyledRowContainer mb={1.5}>
            <StyledSmallText>
              {t('pooled')} {firstTokenSymbol}
            </StyledSmallText>
            <StyledSmallText>{firstTokenValue}</StyledSmallText>
          </StyledRowContainer>
          <StyledRowContainer>
            <StyledSmallText>
              {t('pooled')} {secondTokenSymbol}
            </StyledSmallText>
            <StyledSmallText>{secondTokenValue}</StyledSmallText>
          </StyledRowContainer>
        </StyledDialogSection>
      </StyledDialog>
      <RemoveLiquidityV2Submitter
        isOpen={isSubmitterOpen}
        onClose={() => setIsSubmitterOpen(false)}
        firstTokenSymbol={firstTokenSymbol}
        secondTokenSymbol={secondTokenSymbol}
        firstTokenIcon={firstTokenIcon}
        secondTokenIcon={secondTokenIcon}
        firstTokenValue={firstTokenValue!}
        secondTokenValue={secondTokenValue!}
        slippageTolerance={slippageTolerance}
        liquidity={liquidity}
        firstTokenAddress={firstTokenAddress}
        secondTokenAddress={secondTokenAddress}
        firstTokenChainId={firstTokenChainId}
        secondTokenChainId={secondTokenChainId}
        firstTokenDecimals={firstTokenDecimals}
        secondTokenDecimals={secondTokenDecimals}
        firstTokenName={firstTokenName}
        secondTokenName={secondTokenName}
        liquidityId={liquidityId}
      />
    </>
  );
};
export default RemoveLiquidityV2Modal;
