import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import PlanetLogo from 'svg/PlanetLogo';
import { StyledText } from './styles';

const LandscapeOrientationWarning = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <PlanetLogo />
      <StyledText>{t('siteSupportsPortraitMode')}</StyledText>
    </Box>
  );
};
export default LandscapeOrientationWarning;
