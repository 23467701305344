import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type NotificationProps = {
  onClose?: () => void;
  message: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant='filled'
      {...props}
    />
  );
});

function Notification({ onClose, message }: NotificationProps) {
  return (
    <Snackbar
      open
      onClose={onClose}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity='error'
        style={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
