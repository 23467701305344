import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowBack,
  Close,
  ErrorOutline,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { NFTCollection } from '@omisoftnet/game-nft-sdk';
import { useAccount } from 'wagmi';
import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber } from 'ethers';

import NFTEnableListing from 'components/NFTEnableListing';
import {
  SelectTokenButton,
  StyledBackBtn,
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledPriceInput,
  StyledRowContainer,
  StyledSmallText,
  StyledSmallTokenIcon,
  StyledTitle,
  StyledTokenIcon,
  StyledTokenName,
} from './styles';
import { Color, TextSize } from 'helpers/themeStyles';
import {
  PaymentDetailsWrap,
  PaymentFiatValue,
  PaymentText,
  RowStyled,
  TitleStyled,
  WarningTextStyled,
  WarningWrap,
} from 'components/NFTBuyModal/styles';

import { createOffer, fetchData, fetchOffer } from 'config/api';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { nativeTokens, stableTokens } from 'helpers/nativeTokens';
import { dexSelector } from 'state/dex/selectors';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import { LightTooltip } from 'components/SwapSettings/StyledComponents';
import { setTransactionHash } from 'state/transactions/slice';
import { ExpandLess, ExpandMore } from 'shared/ExpandMoreAndLess';
import {
  NetworkPopper,
  NetworkBox,
  NetworkIcon,
  NetworkList,
} from 'components/NetworkSelector/styles';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { SelectedNft } from 'types/nft';
import NFT_MARKETPLACE from 'config/contracts';
import { makeShortTextLine } from 'helpers/makeShortTextLine';
import getNetworkIcon from 'helpers/getNetworkIcon';
import { useAsUsd } from '@hooks/useAsUsd';
import { useSigner } from '@hooks/useSigner';

export default function NFTSellModal({
  open,
  setOpen,
  title,
  buttonText,
  setNewPrice,
  totalSupply,
  previousPrice,
  selectedNFT,
  previousToken,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  buttonText: string;
  setNewPrice?: boolean | undefined;
  totalSupply: number;
  selectedNFT: SelectedNft;
  previousPrice?: string;
  previousToken?: DEXToken;
}) {
  const { t } = useTranslation();
  const signer = useSigner();
  const { address, chain } = useAccount();
  const dispatch = useDispatch();
  const [openWaitingPopup, setOpenWaitingPopup] = useState<boolean>(false);
  const [openEnablePopup, setOpenEnablePopup] = useState<boolean>(false);
  const [price, setPrice] = useState<string>(
    previousPrice ? previousPrice : ''
  );
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState<boolean>(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState<boolean>(false);
  const [usdPrice, setUsdPrice] = useState<string>('');
  const [usdFee, setUsdFee] = useState<string>('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [invalidPrice, setInvalidPrice] = useState(false);
  const ERC1155 = NFT_MARKETPLACE[chain!.id].contract;
  const NFTExchanger = NFT_MARKETPLACE[chain!.id].market;
  const NFT = new NFTCollection(signer!, ERC1155, NFTExchanger);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const nativeToken = nativeTokens[chain?.id ?? 1];
  const [payableTokens, setPayableTokens] = useState<DEXToken[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [platformFee, setPlatformFee] = useState<number>();
  const [selectedToken, setSelectedToken] = useState<DEXToken | undefined>(
    previousToken ? previousToken : undefined
  );
  const { getValueInUsd } = useAsUsd();
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getPayableTokens = async () => {
    const percent = await NFT.marketplace.bips();
    setPlatformFee(percent.toNumber() / 100);
    const data = await NFT.getPayableTokens();
    const tokens: DEXToken[] = [];

    data.map((el: string) =>
      DEXToken.fromBlockchain(el, signer!).then((token) => tokens.push(token))
    );
    setPayableTokens(tokens);
  };
  useEffect(() => {
    getPayableTokens();
  }, []);
  const sellNFT = async () => {
    const { data } = await fetchData(
      `nft-collection/one/${selectedNFT.nft.nft_collection}`
    );
    const sellNFT = await NFT.makeOffer({
      tokenId: Number(selectedNFT?.nft.token_id),
      mongoTokenId: selectedNFT?.nft._id,
      payAmount: utils.parseUnits(
        price,
        selectedToken ? selectedToken.decimals : nativeToken.decimals
      ),
      payableToken: selectedToken
        ? selectedToken.address
        : payableTokens
        ? payableTokens[0].address
        : undefined,
      tokenAmount: 1,
      deadline: Math.floor(Date.now() / 1000) + 60 * 1000000,
      totalSupply: BigNumber.from(`${totalSupply}`).toHexString(),
      royalties_addreses: data.nftCollection.royalties_address || address,
      royalties_percent:
        String((Number(data.nftCollection.royalties_percent) / 100) * 10000) ||
        '0',
    });

    const offer = {
      ...sellNFT[0],
      signature: sellNFT[1],
      pay_amount_usd: utils
        .parseUnits(usdPrice, stableTokens[chain?.id!].decimals)
        .toHexString(),
    };
    const result = await createOffer('offers/create', offer);
  };

  const closeOffer = async () => {
    try {
      if (address) {
        setOpenWaitingPopup(true);
        const { data } = await fetchOffer(
          `offers/nft/${selectedNFT?.nft._id}`,
          {
            from: address.toLowerCase(),
          }
        );
        const signature = data.signature;
        const offer = {
          asset_amount: data.asset_amount,
          asset_id: data.asset_id,
          collection_contract: data.collection_contract,
          deadline: data.deadline,
          from: data.from,
          nft_id: data.nft_id,
          pay_amount: data.pay_amount,
          payable_token: data.payable_token,
          royalties_addreses: data.royalties_addreses || address,
          royalties_percent: data.royalties_percent || '0',
          total_supply: BigNumber.from(String(totalSupply)).toHexString(),
        };
        const tx = await NFT.cancelOffer(offer, signature);
        dispatch(
          setTransactionHash({
            hash: tx?.hash,
            from: tx?.from,
            to: tx?.to,
            owner: address!,
            transaction_type: 'cancelOffer',
            data: {
              nft_data: {
                pay_amount: Number(utils.formatUnits(`${tx?.gasPrice!}`, 18)),
                payable_token: data.payable_token,
                nft_image: formatIpfsUrl(selectedNFT?.nft.image[1]),
                nft_name: selectedNFT?.nft.name,
                nft_collection_name: selectedNFT?.nft.nft_collection_name,
                token_image: selectedToken?.logoUri,
              },
            },
          })
        );
        await sellNFT();
        setTransactionSubmittedStatus(true);
        setOpenWaitingPopup(false);
      }
    } catch (error) {
      console.error(error);
      setTransactionRejectedStatus(true);
      setOpenWaitingPopup(false);
    }
  };

  async function getUSDBalance() {
    const token = selectedToken
      ? selectedToken
      : payableTokens
      ? payableTokens[0]
      : undefined;
    if (price && token) {
      token.amount = utils.parseUnits(price, token.decimals);
      const usdPrice = await getValueInUsd({
        token: token,
        value: utils.formatUnits(token.amount, token.decimals),
      });

      setUsdPrice(usdPrice?.substring(0, usdPrice?.indexOf('.') + 4) ?? '0');

      const feeValue = String((Number(price) * platformFee!) / 100);
      token.amount = utils.parseUnits(feeValue, token.decimals);
      const usdFee = await getValueInUsd({
        token: token,
        value: utils.formatUnits(token.amount, token.decimals),
      });
      setUsdFee(usdFee?.slice(0, 6) ?? '0');
    } else {
      setUsdPrice('');
      setUsdFee('');
    }
  }

  function validatePrice(value: string) {
    if (Number(value) < 0.005 || Number(value) > 10000) {
      setInvalidPrice(true);
    }
  }

  useEffect(() => {
    getUSDBalance();
  }, [price, selectedToken]);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <>
      <StyledDialog
        onClose={handleClose}
        open={open}
      >
        <StyledDialogHeader>
          <StyledBackBtn
            startIcon={<ArrowBack />}
            onClick={handleClose}
          >
            {t('back')}
          </StyledBackBtn>
          <Close
            onClick={handleClose}
            sx={{ cursor: 'pointer' }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <StyledTitle>{title}</StyledTitle>
          <Box pt={2}>
            <StyledRowContainer sx={{ alignItems: 'flex-start' }}>
              <Box
                display='flex'
                flexDirection='row'
                gap={1.3}
              >
                <SelectTokenButton
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                    handleClick(e);
                  }}
                >
                  <StyledTokenIcon
                    src={
                      selectedToken
                        ? selectedToken?.icon
                        : getNetworkIcon(chain?.id!)
                    }
                    alt='network icon'
                  />
                  <StyledTokenName>
                    {selectedToken
                      ? selectedToken?.symbol
                      : nativeToken?.symbol}
                  </StyledTokenName>
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </SelectTokenButton>
              </Box>
              <NetworkPopper
                open={isOpen}
                anchorEl={anchorEl}
                placement={isMobile ? 'bottom-start' : 'right-start'}
                onReset={undefined}
                onResetCapture={undefined}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setIsOpen(false);
                    setAnchorEl(null);
                  }}
                >
                  <NetworkBox>
                    <NetworkList>
                      {payableTokens?.map((token) => {
                        return (
                          <ListItemButton
                            key={token.address}
                            sx={{ p: 0 }}
                            onClick={() => {
                              setSelectedToken(token);
                              setIsOpen(false);
                              setAnchorEl(null);
                            }}
                            disabled={selectedToken?.address === token.address}
                          >
                            <ListItemIcon sx={{ minWidth: 0, mr: '16px' }}>
                              <NetworkIcon src={token?.icon} />
                            </ListItemIcon>
                            <ListItemText primary={token.symbol} />
                          </ListItemButton>
                        );
                      })}
                    </NetworkList>
                  </NetworkBox>
                </ClickAwayListener>
              </NetworkPopper>
              <Stack
                alignItems='center'
                flexDirection='initial'
              >
                <StyledPriceInput
                  sx={{
                    input: { '&::placeholder': { color: Color.WHITE } },
                  }}
                  value={price}
                  placeholder='0.00'
                  onChange={(event) => {
                    setInvalidPrice(false);
                    const result = event.target.value.replace(/[^\d\.]/g, '');
                    const dotIndex = result.indexOf('.');
                    let value = '';
                    result?.match(/\./g)?.length! > 1
                      ? (value = result.slice(0, result.lastIndexOf('.')))
                      : result === '00' ||
                        (result.length === 2 &&
                          result[0] === '0' &&
                          result[1] !== '.')
                      ? (value = '0')
                      : result[0] === '.'
                      ? (value = '')
                      : dotIndex !== -1 && result.lastIndexOf('.') !== dotIndex
                      ? (value = result.slice(0, -1))
                      : (value = result);
                    validatePrice(value);
                    setPrice(value);
                  }}
                />
                <StyledSmallText>{`~ $ ${usdPrice} USD`}</StyledSmallText>
              </Stack>
            </StyledRowContainer>
            {invalidPrice && price && (
              <StyledSmallText
                sx={{
                  fontWeight: 400,
                  fontSize: TextSize.TABLE_SMALL,
                  color: Color.RED,
                  mt: 1,
                }}
              >
                {t('allowedPriceRange', {
                  tokenSymbol: selectedToken
                    ? selectedToken.symbol
                    : nativeToken.symbol,
                })}
              </StyledSmallText>
            )}
            <PaymentDetailsWrap>
              <RowStyled
                style={{ margin: '12px 0px', justifyContent: 'start' }}
              >
                <PaymentText>
                  {t('sellerPaysPlatformFeeOnSale', { percent: platformFee })}
                </PaymentText>
                {isMobile ? (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      <LightTooltip
                        title={t('sellerPaysPlatformFeeOnSaleTip', {
                          cryptocurrency: selectedToken
                            ? selectedToken?.symbol
                            : nativeToken?.symbol,
                        })}
                        placement='bottom-start'
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                      >
                        <ErrorOutlineOutlined
                          onClick={handleTooltipOpen}
                          sx={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            color: Color.WHITE_OPACITY_LIGHT,
                            '&:hover': { color: 'lightgrey' },
                          }}
                        />
                      </LightTooltip>
                    </div>
                  </ClickAwayListener>
                ) : (
                  <LightTooltip
                    title={t('sellerPaysPlatformFeeOnSaleTip', {
                      cryptocurrency: selectedToken
                        ? selectedToken?.symbol
                        : nativeToken?.symbol,
                    })}
                    placement='right'
                  >
                    <ErrorOutlineOutlined
                      sx={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        color: Color.WHITE_OPACITY_LIGHT,
                        '&:hover': { color: 'lightgrey' },
                      }}
                    />
                  </LightTooltip>
                )}
              </RowStyled>
              <RowStyled>
                <PaymentText>{t('platformFeeIfSold')}</PaymentText>
                <div style={{ justifyContent: 'flex-end' }}>
                  <RowStyled style={{ margin: 0 }}>
                    <StyledSmallTokenIcon
                      src={
                        selectedToken
                          ? selectedToken?.icon
                          : getNetworkIcon(chain?.id!)
                      }
                    />
                    <TitleStyled>
                      {price && Number(price) >= 1 && platformFee
                        ? (Number(price) * platformFee) / 100
                        : !price || Number(price) === 0
                        ? '0.0'
                        : ((Number(price) * platformFee!) / 100)
                            .toFixed(10)
                            .replace(/\.?0+$/, '')}
                    </TitleStyled>
                  </RowStyled>
                  <PaymentFiatValue>{`$${usdFee}`}</PaymentFiatValue>
                </div>
              </RowStyled>
              {/* <RowStyled>
                <PaymentText>{t('lowestPriceOnMarket')}</PaymentText>
                <div style={{ justifyContent: 'flex-end' }}>
                  <RowStyled style={{ margin: 0 }}>
                    <StyledSmallTokenIcon src={getNetworkIcon(chain?.id!)} />
                    <TitleStyled>0.0095</TitleStyled>
                  </RowStyled>
                  <PaymentFiatValue>$2.82</PaymentFiatValue>
                </div>
              </RowStyled> */}
            </PaymentDetailsWrap>
          </Box>
          <WarningWrap style={{ padding: '12px', marginBottom: '12px' }}>
            <ErrorOutline sx={{ color: 'red' }} />
            <WarningTextStyled style={{ lineHeight: '150%' }}>
              {t('youSellNft')}{' '}
              <span style={{ fontWeight: 'bold', paddingLeft: '4px' }}>
                {t('sellDetails', {
                  qty: 1,
                  nftName: makeShortTextLine(selectedNFT?.nft.name),
                })}
              </span>
            </WarningTextStyled>
          </WarningWrap>
          <WarningWrap style={{ padding: '12px' }}>
            <ErrorOutline sx={{ color: 'red' }} />
            <WarningTextStyled style={{ lineHeight: '150%' }}>
              {t('sellNFTWarning', {
                nativeToken: selectedToken
                  ? selectedToken?.symbol
                  : nativeToken?.symbol,
              })}
            </WarningTextStyled>
          </WarningWrap>
          <StyledButton
            variant='contained'
            onClick={() => {
              if (!setNewPrice) {
                setOpenEnablePopup(true);
              } else {
                closeOffer();
              }
            }}
            disabled={Number(price) <= 0 || invalidPrice}
          >
            {buttonText}
          </StyledButton>
        </StyledDialogContent>
      </StyledDialog>
      <NFTEnableListing
        open={openEnablePopup}
        onClose={() => {
          setOpenEnablePopup(false);
        }}
        closeParentPopup={() => handleClose()}
        price={price}
        totalSupply={totalSupply}
        selectedNFT={selectedNFT}
        payableToken={selectedToken}
        payablesTokens={payableTokens}
        usdPrice={usdPrice}
      />
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          fromNFT
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={handleClose}
          result={transactionStatuses.SUBMIT}
          reloadPage
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
}
