import { useRef } from 'react';

const useScroll = () => {
  const problem = useRef<null | HTMLDivElement>(null);
  const product = useRef<null | HTMLDivElement>(null);
  const businessmodel = useRef<null | HTMLDivElement>(null);
  const tokenomics = useRef<null | HTMLDivElement>(null);
  const roadmap = useRef<null | HTMLDivElement>(null);
  const team = useRef<null | HTMLDivElement>(null);
  const investors = useRef<null | HTMLDivElement>(null);

  const scrollToAnchor = (anchor: string) => {
    let pointToScroll;
    switch (anchor) {
      case 'Problem':
        pointToScroll = problem?.current;
        break;
      case 'Product':
        pointToScroll = product?.current;
        break;
      case 'Business Model':
        pointToScroll = businessmodel?.current;
        break;
      case 'Tokenomics':
        pointToScroll = tokenomics?.current;
        break;
      case 'Roadmap':
        pointToScroll = roadmap?.current;
        break;
      case 'Team':
        pointToScroll = team?.current;
        break;
      case 'Investors':
        pointToScroll = investors?.current;
        break;
    }
    pointToScroll?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return {
    problem,
    product,
    businessmodel,
    tokenomics,
    roadmap,
    team,
    investors,
    scrollToAnchor,
  };
};

export default useScroll;
