import { Stack } from '@mui/material';
import { DEXToken, utils } from '@omisoftnet/game-dex-sdk';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import { useAsUsd } from '@hooks/useAsUsd';
import { useSigner } from '@hooks/useSigner';
import { StyledLink } from 'components/AllNFTs/styles';
import { StyledNoImgContainer } from 'components/NftCollectionsList/styles';
import { fetchData, fetchOffer } from 'config/api';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import { Color } from 'helpers/themeStyles';
import DefaultTokenIcon from 'svg/DefaultTokenIcon';
import {
  StyledCollectionTitle,
  StyledCollectionType,
  StyledImage,
  StyledItemContainer,
  StyledNftTitle,
  StyledPriceContainer,
  StyledPriceTitle,
  StyledPriceUSDValue,
  StyledPriceValue,
  StyledTokenIcon,
} from './styles';

type ControlProps = {
  image: string;
  collection: string;
  name: string;
  id: string | number;
  width?: string | undefined;
  myNft?: boolean | undefined;
  chainId: number;
};

const NftCard = ({
  image,
  collection,
  name,
  id,
  width,
  myNft,
  chainId,
}: ControlProps) => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const [forSale, setForSale] = useState<boolean>(false);
  const [price, setPrice] = useState();
  const [usdPrice, setUsdPrice] = useState<string>();
  const [imgError, setImgError] = useState(false);
  const [payableToken, setPayableToken] = useState<string>();
  const [token, setToken] = useState<DEXToken>();
  const signer = useSigner();
  const { getValueInUsd } = useAsUsd();
  async function fetchNftOffer() {
    try {
      const { data } = !myNft
        ? await fetchOffer(`offers/nft/${id}`)
        : await fetchOffer(`offers/nft/${id}`, {
            from: address?.toLowerCase(),
          });
      setPrice(data.pay_amount);
      setPayableToken(data.payable_token);
      setForSale(true);
    } catch (error) {
      setForSale(false);
    }
  }
  const getPayableToken = async () => {
    if (payableToken && signer && chainId) {
      try {
        if (chainId !== chain?.id) {
          const { data } = await fetchData(
            `/tokens?address=${payableToken}&chainId=${chainId}`
          );
          const tokenFromAddress = DEXToken.fromAddress(
            payableToken,
            BigNumber.from(price),
            chainId,
            data.docs[0].decimals,
            data.docs[0].symbol,
            data.docs[0].name,
            data.docs[0].logoURI,
            false
          );
          setToken(tokenFromAddress);
        } else {
          const tokenFromBlockhain = await DEXToken.fromBlockchain(
            payableToken,
            signer
          );
          tokenFromBlockhain.amount = BigNumber.from(price);
          setToken(tokenFromBlockhain);
        }
      } catch (err) {}
    }
  };

  async function getValueInUSD(value: string) {
    const usd = await getValueInUsd({
      token: token ?? DEXToken.default(),
      value: utils.formatUnits(
        value ?? '0',
        token?.decimals ?? DEXToken.default().decimals
      ),
    });
    setUsdPrice(usd?.slice(0, 7));
  }

  useEffect(() => {
    fetchNftOffer();
  }, []);

  useEffect(() => {
    if (price && token) {
      getValueInUSD(price);
    }
  }, [price, token, signer]);

  useEffect(() => {
    getPayableToken();
  }, [payableToken, chain?.id, signer, chainId, price]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <StyledLink
      onClick={scrollToTop}
      to={`/nft-details/${id}`}
    >
      <StyledItemContainer sx={{ width: width ? width : 'auto' }}>
        {imgError ? (
          <StyledNoImgContainer sx={{ height: '250px', marginBottom: '24px' }}>
            <StyledCollectionTitle sx={{ color: Color.WHITE }}>
              {t('imageIsLoadingNow')}
            </StyledCollectionTitle>
          </StyledNoImgContainer>
        ) : (
          <StyledImage
            src={formatIpfsUrl(image[1])}
            alt={name}
            onError={() => setImgError(true)}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledCollectionTitle>{collection}</StyledCollectionTitle>
          {forSale && (
            <StyledCollectionType>{t('forSale')}</StyledCollectionType>
          )}
        </div>

        <StyledNftTitle>{name}</StyledNftTitle>
        {myNft ? (
          <>
            <StyledPriceContainer>
              <StyledPriceTitle>{t('yourPrice')}</StyledPriceTitle>
              <Stack
                direction='row'
                alignItems='center'
                gap={0.5}
              >
                {usdPrice && token?.chainId === chain?.id && (
                  <StyledPriceUSDValue>{`($${usdPrice?.slice(
                    0,
                    7
                  )})`}</StyledPriceUSDValue>
                )}
                {payableToken &&
                  !!price &&
                  (!!token?.icon ? (
                    <StyledTokenIcon
                      src={token?.icon}
                      alt='network icon'
                    />
                  ) : (
                    <DefaultTokenIcon />
                  ))}
                <StyledPriceValue>
                  {price ? utils.formatUnits(`${price}`, token?.decimals) : '-'}
                </StyledPriceValue>
              </Stack>
            </StyledPriceContainer>
          </>
        ) : (
          <StyledPriceContainer>
            <StyledPriceTitle>{t('askingPrice')}</StyledPriceTitle>
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}
            >
              {usdPrice && token?.chainId === chain?.id && (
                <StyledPriceUSDValue>{`($${usdPrice?.slice(
                  0,
                  7
                )})`}</StyledPriceUSDValue>
              )}
              {payableToken &&
                (!!token?.logoUri || !!token?.icon ? (
                  <StyledTokenIcon
                    src={token?.logoUri || token?.icon}
                    alt='network icon'
                  />
                ) : (
                  <DefaultTokenIcon />
                ))}
              <StyledPriceValue>
                {price ? utils.formatUnits(`${price}`, token?.decimals) : '-'}
              </StyledPriceValue>
            </Stack>
          </StyledPriceContainer>
        )}
      </StyledItemContainer>
    </StyledLink>
  );
};

export default NftCard;
