import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'components/Container';
import { MainWrapper } from './styles';

const Pool: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainWrapper></MainWrapper>
    </Container>
  );
};

export default Pool;
