import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { StylesImage } from 'components/SliderMobileMenu/styles';

const SliderMobileMenu = () => {
  return (
    <Swiper
      spaceBetween={0}
      centeredSlides
      loop
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      allowTouchMove={false}
      speed={1000}
    >
      <SwiperSlide>
        <StylesImage
          src='./images/mobileSlider/1.png'
          alt=''
        />
      </SwiperSlide>
      <SwiperSlide>
        <StylesImage
          src='./images/mobileSlider/2.png'
          alt=''
        />
      </SwiperSlide>
      <SwiperSlide>
        <StylesImage
          src='./images/mobileSlider/3.png'
          alt=''
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SliderMobileMenu;
