import { Dialog, Button, styled } from '@mui/material';
import {
  BorderRadius,
  FontFamily,
  Color,
  TextSize,
  Border,
  BoxShadow,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '412px',
    borderRadius: BorderRadius.SMALL,
    padding: '24px 0px',
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow:
      '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 12px rgba(0, 0, 0, 0.86)',

    [theme.breakpoints.down('mobile')]: {
      alignSelf: 'flex-start',
      minWidth: 'inherit',
      width: '100%',
      margin: '16px',
      marginTop: '56px',

      background: Color.BACKGROUND,
      boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
      borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
    },
  },
}));

export const StyledTitle = styled('h6')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  lineHeight: '150%',
  margin: 0,
});

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '14px',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    borderBottom: Border.ACCENT_BORDER,
  },
}));

export const StyledIconButton = styled(Button)({
  color: Color.WHITE,
  '& :hover': {
    background: 'transparent',
  },
});
export const MainContent = styled('div')({
  marginTop: '16px',
  padding: '0px 16px',
});

export const StyledRemoveButton = styled(Button)({
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  padding: '16px 0px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  width: '100%',
  marginTop: '16px',
});

export const TokenFeesTitle = styled('p')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  fontStyle: 'italic',
  color: Color.WHITE,
  lineHeight: '150%',
  margin: '12px 0 0 0',
});
