import * as Yup from 'yup';
import i18next from 'i18next';

import { ProjectDetails } from 'types/project';
import { getCurrentDate } from 'helpers/dateCheckers';

export const initialValues = (currentProject: ProjectDetails) => ({
  projectTitle: currentProject?.projectTitle || '',
  contactEmail: currentProject?.contactEmail || '',
  contactName: currentProject?.contactName || '',
  information: currentProject?.information || '',
  categories: currentProject?.categories || '',
  videoLink: currentProject?.videoLink || '',
  tokenInformation: currentProject?.tokenInformation || '',
  developmentState: currentProject?.developmentState || '',
  hardCommitments: currentProject?.hardCommitments || '',
  socialMedia: {
    website: currentProject?.socialMedia.website || '',
    twitter: currentProject?.socialMedia.twitter || '',
    telegram: currentProject?.socialMedia.telegram || '',
    github: currentProject?.socialMedia.github || '',
  },
  whitepaper: currentProject?.whitepaper || '',
  pitchDeck: currentProject?.pitchDeck || '',
  launchDateYear: currentProject
    ? new Date(currentProject.poolInformation.launchDate).getUTCFullYear()
    : getCurrentDate().year,
  launchDateMonth: currentProject
    ? new Date(currentProject.poolInformation.launchDate).getUTCMonth() + 1
    : getCurrentDate().month,
  launchDateDay: currentProject
    ? new Date(currentProject.poolInformation.launchDate).getUTCDate()
    : getCurrentDate().day,
  poolInformation: {
    toRise: currentProject?.poolInformation.toRise || '',
    toRiseTotal: currentProject?.poolInformation.toRiseTotal || '',
    amountFlexible: currentProject?.poolInformation.amountFlexible ? 1 : 0,
    lowestPercentageOfFundraisingGoal:
      currentProject?.poolInformation.lowestPercentageOfFundraisingGoal || null,
    launchDate: currentProject
      ? new Date(currentProject.poolInformation.launchDate)
      : new Date(
          getCurrentDate().year,
          getCurrentDate().month,
          getCurrentDate().day
        ),
    dateFlexible: currentProject?.poolInformation.dateFlexible ? 1 : 0,
    chain: currentProject?.poolInformation.chain || 1,
    planingOtherLaunch: currentProject?.poolInformation.planingOtherLaunch
      ? 1
      : 0,
    exclusiveOpen: currentProject?.poolInformation.exclusiveOpen ? 1 : 0,
    otherPlatforms: currentProject?.poolInformation.otherPlatforms || '',
    marketingAgencies: currentProject?.poolInformation.marketingAgencies || '',
    supportingInfluencer:
      currentProject?.poolInformation.supportingInfluencer || '',
    tokenDateYear: currentProject
      ? new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getUTCFullYear()
      : getCurrentDate().year,
    tokenDateMonth: currentProject
      ? new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getUTCMonth() + 1
      : getCurrentDate().month,
    tokenDateDay: currentProject
      ? new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getUTCDate()
      : getCurrentDate().day,
    tokenDistributionDate: currentProject
      ? new Date(currentProject.poolInformation.tokenDistributionDate)
      : new Date(
          getCurrentDate().year,
          getCurrentDate().month,
          getCurrentDate().day
        ),
  },
  tokenSaleDetails: {
    maxAllocation: currentProject?.tokenSaleDetails.maxAllocation || '',
    minAllocation: currentProject?.tokenSaleDetails.minAllocation || '',
    price: currentProject?.tokenSaleDetails.price || '',
    tokenSymbol: currentProject?.tokenSaleDetails.tokenSymbol || '',
    tokenName: currentProject?.tokenSaleDetails.tokenName || '',
    allocation: currentProject?.tokenSaleDetails.allocation || '',
    total_supply: currentProject?.tokenSaleDetails.total_supply || '',
  },
});

export const validationSchema = () =>
  Yup.object().shape({
    contactName: Yup.string().required(() => i18next.t('fieldIsRequired')),
    contactEmail: Yup.string()
      .email(() => i18next.t('invalidEmail'))
      .required(() => i18next.t('fieldIsRequired')),
    projectTitle: Yup.string().required(() => i18next.t('fieldIsRequired')),
    information: Yup.string().required(() => i18next.t('fieldIsRequired')),
    videoLink: Yup.string().matches(
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
      {
        message: i18next.t('videoErrorText'),
      }
    ),
    whitepaper: Yup.string().url(() => i18next.t('invalidURL')),
    pitchDeck: Yup.string().url(() => i18next.t('invalidURL')),
    developmentState: Yup.string().required(() => i18next.t('fieldIsRequired')),
    hardCommitments: Yup.string(),
    tokenInformation: Yup.string().required(() => i18next.t('fieldIsRequired')),
    socialMedia: Yup.object().shape({
      website: Yup.string()
        .url(() => i18next.t('invalidURL'))
        .required(() => i18next.t('fieldIsRequired')),
      twitter: Yup.string().url(() => i18next.t('invalidURL')),
      telegram: Yup.string().required(() => i18next.t('fieldIsRequired')),
      github: Yup.string().url(() => i18next.t('invalidURL')),
    }),
    tokenSaleDetails: Yup.object().shape({
      maxAllocation: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
      minAllocation: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .test(
          'is-smaller-than-max-allocation',
          'Min allocation must be smaller than max allocation',
          function (value) {
            const { maxAllocation } = this.parent;
            return Number(value) < Number(maxAllocation);
          }
        )
        .test(
          'is-smallet-or-eqaul-to-price',
          'Min allocation must be greater or equal to price',
          function (value) {
            const { price } = this.parent;
            return Number(value) >= price;
          }
        )
        .required(() => i18next.t('fieldIsRequired')),
      price: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
      tokenSymbol: Yup.string().required(() => i18next.t('fieldIsRequired')),
      tokenName: Yup.string().required(() => i18next.t('fieldIsRequired')),
      allocation: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
      total_supply: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
    }),
    poolInformation: Yup.object().shape({
      toRise: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
      toRiseTotal: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d*)?$/, {
          message: i18next.t('enterNumbers'),
        })
        .required(() => i18next.t('fieldIsRequired')),
      lowestPercentageOfFundraisingGoal: Yup.number()
        .typeError('Enter only numbers')
        .min(50, 'The value should be bigger or equal to 50')
        .max(100, 'The value should be smaller or equal to 100'),
      otherPlatforms: Yup.string(),
      marketingAgencies: Yup.string(),
      supportingInfluencer: Yup.string(),
    }),
  });
