import { useEffect } from 'react';

import { StyledContainer } from 'components/NewestCollections/styles';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { getHotNftCollectionsThunk } from 'state/nftCollections/thunks';
import { hotNftCollectionsSelector } from 'state/nftCollections/selectors';
import NftCollectionListItem from 'components/NftCollectionsList/NftCollectionListItem';

const HotNftCollections = () => {
  const dispatch = useAppDispatch();
  const collections = useAppSelector(hotNftCollectionsSelector)?.docs;
  useEffect(() => {
    dispatch(getHotNftCollectionsThunk());
  }, []);

  return (
    <>
      <StyledContainer>
        {collections?.map((collection) => {
          return (
            <NftCollectionListItem
              key={collection._id}
              nft={collection}
            />
          );
        })}
      </StyledContainer>
    </>
  );
};

export default HotNftCollections;
