import React from 'react';

const CopyIcon: React.FC = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.9 15.3C4.43333 15.3 4.03333 15.1333 3.7 14.8C3.36667 14.4667 3.2 14.0667 3.2 13.6V2.2C3.2 1.73333 3.36667 1.33333 3.7 1C4.03333 0.666667 4.43333 0.5 4.9 0.5H15.8C16.2667 0.5 16.6667 0.666667 17 1C17.3333 1.33333 17.5 1.73333 17.5 2.2V13.6C17.5 14.0667 17.3333 14.4667 17 14.8C16.6667 15.1333 16.2667 15.3 15.8 15.3H4.9ZM4.9 13.6H15.8V2.2H4.9V13.6ZM1.7 18.5C1.23333 18.5 0.833333 18.3333 0.5 18C0.166667 17.6667 0 17.2667 0 16.8V5.125C0 4.89167 0.0791666 4.69167 0.2375 4.525C0.395833 4.35833 0.6 4.275 0.85 4.275C1.1 4.275 1.30417 4.35833 1.4625 4.525C1.62083 4.69167 1.7 4.89167 1.7 5.125V16.8H12.65C12.8833 16.8 13.0833 16.8833 13.25 17.05C13.4167 17.2167 13.5 17.4167 13.5 17.65C13.5 17.9 13.4167 18.1042 13.25 18.2625C13.0833 18.4208 12.8833 18.5 12.65 18.5H1.7Z'
        fill='white'
      />
    </svg>
  );
};

export default CopyIcon;
