const ArrowBackIcon = () => {
    return (
        <svg 
            width="18" 
            height="14" 
            viewBox="0 0 18 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M2 7H18M2 7L8 1M2 7L8 13" 
                stroke="white" 
                stroke-width="2"/>
        </svg>
    );
  };
  export default ArrowBackIcon;