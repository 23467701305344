import { styled, Typography, Button } from '@mui/material';

import { Color, FontFamily, TextSize, BorderRadius } from 'helpers/themeStyles';

export const PageWrap = styled('div')(({ theme }) => ({
  marginTop: '-48px',
  padding: '12px 24px 32px',
  background: Color.BACKGROUND_MAIN,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    marginTop: '0px',
  },
}));

export const RowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '10px',
  justifyContent: 'center',

  [theme.breakpoints.down('mobile')]: {
    display: 'block',
    '&:nth-child(3), &:nth-child(5)': {
      display: 'flex',
    },
  },
}));

export const TitleStyled = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MIDDLE_PLUS,
  margin: '6px 0 12px',

  [theme.breakpoints.down('mobile')]: {
    maxWidth: '195px',
    margin: '12px 0 20px',
  },
}));

export const SortWrap = styled('div')(() => ({
  display: 'flex',
}));

export const SortTitle = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
}));

export const ClearTitle = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.PINK,
  cursor: 'pointer',
}));

export const ButtonStyled = styled('span')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.TABLE_SMALL,
  cursor: 'pointer',
  background: Color.TEXT_GRADIENT,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export const NoTransactionWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '200px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  background: Color.SWAP_GRADIENT,
}));

export const NoTransactionTitle = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
  marginTop: '40px',
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: '8px',
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: BorderRadius.SEMI_SMALL,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
  padding: '16px 32px',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
}));
