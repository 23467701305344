import {
  Dialog,
  Stack,
  DialogTitle,
  Button,
  Typography,
  styled,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 531,
    borderRadius: BorderRadius.NORMAL,
    background: Color.SWAP_GRADIENT_WITHOUT_OPACITY,

    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      height: '100%',
      maxHeight: 'inherit',
      border: 'none',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
      boxShadow: 'inherit',
      margin: 0,
      marginTop: '56px',
      alignSelf: 'flex-start',
    },
  },
}));

export const StyledDialogHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: Border.ACCENT_BORDER,
  padding: '16px',

  [theme.breakpoints.down('mobile')]: {
    borderTop: Border.ACCENT_BORDER,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  color: Color.WHITE,
  fontSize: TextSize.MEDIUM,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const StyledDialogContent = styled(Stack)(() => ({
  padding: '18px 16px',
}));

export const StyledRowContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 12,
}));

export const StyledTokensName = styled('h4')(({ theme }) => ({
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 400,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  margin: 0,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledTokensAmount = styled('span')(() => ({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,
  fontWeight: 700,
}));

export const StyledDetails = styled('div')(() => ({
  color: Color.WHITE_OPACITY_LIGHT,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 400,
}));

export const StyledColumnContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 16,
  padding: 16,
  background: Color.BACKGROUND,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,

  [theme.breakpoints.down('mobile')]: {
    marginBottom: 16,
  },
}));

export const StyledText = styled(StyledTokensName)(() => ({
  fontSize: TextSize.SMALL,
}));

export const StyledGreenCircle = styled('div')(() => ({
  width: 8,
  height: 8,
  background: Color.GREEN,
  borderRadius: BorderRadius.HUGE,
}));

export const StyledRatesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  marginBottom: 16,
}));

export const StyledRatesTitle = styled('div')(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  padding: 16,

  [theme.breakpoints.down('mobile')]: {
    padding: 10,
  },
}));

export const StyledTipText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  padding: 4,
}));
