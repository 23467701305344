import {
  Box,
  Button,
  Dialog,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '552px',
    background: Color.BACKGROUND,
    borderRadius: BorderRadius.LARGE,
    border: `1px solid ${Color.BORDER_COLOR}`,
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,

    [theme.breakpoints.down('mobile')]: {
      margin: 0,
      alignSelf: 'end',
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
}));

export const StyledHeaderContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 0',
  borderBottom: `1px solid ${Color.WHITE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    padding: '19px 0',
  },
}));

export const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  color: Color.WHITE,
  paddingLeft: '20px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const StyledInputContainer = styled(Box)(() => ({
  padding: '20px 0',
  borderBottom: `1px solid ${Color.WHITE_OPACITY}`,
}));

export const StyledInput = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.SMALL,
    fontWeight: 400,
    color: Color.WHITE,
    borderRadius: BorderRadius.SMALL,
    border: `1px solid ${Color.WHITE_OPACITY}`,
    '& .MuiInputBase-input': {
      padding: ' 14px 16px',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
}));

export const StyledAvatarTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  marginBottom: '16px',
}));

export const StyledAvatarContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '13px',
  alignItems: 'center',

  [theme.breakpoints.down('mobile')]: {
    gap: '11px',
    justifyContent: 'space-between',
  },
}));

export const StyledAvatar = styled('img')(() => ({
  cursor: 'pointer',
  width: '72px',
  height: '72px',
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  transition: 'border 0.3s ease-in',
}));

export const StyledButton = styled(Button)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE,
  letterSpacing: '0.05em',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.TEXT_GRADIENT,
  padding: 13,
  marginTop: 24,
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },
}));
