import React from 'react';

const HelpMobileIcon: React.FC = () => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.842 0.5H6.15797C4.65867 0.504185 3.22182 1.10172 2.16184 2.16184C1.10169 3.22215 0.504179 4.65881 0.5 6.15797V14.842C0.504185 16.3413 1.10172 17.778 2.16184 18.8382C3.222 19.8983 4.65881 20.4958 6.15797 20.5H14.842C16.3413 20.4958 17.7782 19.8983 18.8382 18.8382C19.8983 17.778 20.4958 16.3413 20.5 14.842V6.15797C20.4958 4.65867 19.8983 3.22197 18.8382 2.16184C17.778 1.10169 16.3412 0.504179 14.842 0.5ZM11.2559 14.3863C11.2599 15.0283 10.8999 15.4103 10.2699 15.4103C9.63984 15.4103 9.21991 15.0422 9.2178 14.5043C9.2178 13.7583 9.55179 13.3863 10.2178 13.3663C10.76 13.3503 11.3239 13.6823 11.2559 14.3863V14.3863ZM12.6559 9.77013C12.3718 9.99014 12.0738 10.1902 11.7878 10.4062V10.406C11.3661 10.6824 11.1238 11.1626 11.1519 11.6661C11.1589 11.8447 11.1062 12.0203 11.0017 12.1654C10.8973 12.3103 10.7474 12.4162 10.5759 12.466C10.3933 12.5363 10.1924 12.544 10.0049 12.4879C9.81738 12.4319 9.65388 12.3151 9.53988 12.156C9.4651 12.0308 9.4245 11.888 9.42185 11.7421C9.39256 10.8244 9.81846 9.95161 10.5598 9.41003C10.8808 9.16575 11.1837 8.89831 11.4658 8.61009C11.6003 8.45161 11.6822 8.25518 11.6999 8.04801C11.7459 7.48201 11.382 7.11804 10.7959 7.07996C10.0879 7.03392 9.49188 7.21794 9.07784 7.84394V7.84408C8.99106 7.99168 8.86118 8.10901 8.70562 8.18058C8.55006 8.25215 8.37637 8.27419 8.20782 8.24406C7.60778 8.14403 7.34985 7.65601 7.59578 7.09408C7.85332 6.54635 8.29597 6.10717 8.8458 5.85409C9.41052 5.57647 10.0288 5.42494 10.6579 5.41002C11.0136 5.44713 11.3669 5.5053 11.7158 5.58399C12.7818 5.88603 13.4159 6.738 13.4958 7.87002C13.5744 8.60831 13.2535 9.33236 12.6539 9.77001L12.6559 9.77013Z'
        fill='white'
      />
    </svg>
  );
};

export default HelpMobileIcon;
