export const en = {
  translation: {
    hallo: 'hallo',
    pageNotFound: 'Oops, page not found',
    noPools: 'Have no pools yet',
    swap: 'Swap',
    liquidity: 'Liquidity',
    nft: 'NFT Marketplace',
    launchpad: 'Launchpad',
    pool: 'Pool',
    home: 'Home',
    help: 'Help',
    connect: 'Connect',
    title: 'Landing',
    selectToken: 'Select Token',
    connectWallet: 'Connect Wallet',
    connectAWallet: 'Connect a wallet',
    createProject: 'Create Project',
    next: 'Next',
    back: 'Back',
    settings: 'Settings',
    slippageTolerance: 'Slippage tolerance',
    slippageToleranceTip:
      'Your transaction will revert if the price changes unfavorably by more than this percentage.',
    auto: 'Auto',
    transactionDeadline: 'Transaction deadline',
    transactionDeadlineTip:
      'Your transaction will revert if it is pending for more than this period of time.',
    minutes: 'Minutes',
    interfaceSettings: 'Interface Settings',
    autoRouter: 'Auto Router API',
    autoRouterTip: 'Use API to get faster quotes.',
    expertMode: 'Expert Mode',
    expertModeTip:
      'Allow high price impact trades and skip the confirm screen. Use at your own risk.',
    importPool: 'Import Pool',
    importAnExistingPool: 'Import an existing pool',
    connectWalletToFindPools: 'Connect to a wallet to find pools',
    selectTokenToFindYourLiquidity: 'Select a token to find your liquidity.',
    dontHaveLiquidityInThisPairYet:
      'You don`t have liquidity in this pair yet.',
    addLiquidity: 'Add Liquidity',
    backHome: 'Back To Home',
    tokenNotFound: 'Token not found',
    yourLiquidity: 'Your Liquidity',
    removeLiquidity: 'Remove liquidity to receive tokens back',
    connectWalletLiquidity: 'Connect to a wallet to view your liquidity.',
    noLiquidity: 'No liquidity found.',
    dontSeePair: `Don't see a pair you joined?`,
    chooseValidPair: 'Choose a Valid Pair',
    chooseValidPairTipFirst:
      "By adding liquidity you'll earn 0.3% of all trades on this pair proportional to your share in the trading pair.",
    chooseValidPairTipSecond:
      'Fees are added to the pair, accure in real time and can be claimed by withdrawing your liquidity.',
    findOtherTokens: 'Find other LP tokens',
    swapsAndLiguidity: 'Swaps & Liquidity',
    liquiditySlippageToleranceTip:
      'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
    liquidityDeadline: 'Tx deadline (mins)',
    liquidityDeadlineTip:
      'Your transaction will revert if it is left confirming for longer than this time.',
    liquidityExpertModeTip:
      'Bypasses confirmation modals and allows high slippage trades. Use at your own risk.',
    disableMultihops: 'Disable Multihops',
    disableMultihopsTip: 'Restricts swaps to direct pairs only.',
    flippySounds: 'Flippy sounds',
    flippySoundsTip:
      'Fun sounds to make a truly immersive flipping trading experience.',
    selectAToken: 'Select a token',
    unsupportedNetwork: 'Unsupported network',
    errorConnecting: 'Error connecting',
    errorConnectingTip:
      'The connection attempt failed. Please click try again and follow the steps to connect in your wallet.',
    tryAgain: 'Try Again',
    backToWalletSelection: 'Back to wallet selection',
    confirmSwap: 'Confirm Swap',
    slippageTollerance: 'Slippage Tollerance',
    swapResultDescriptionPart1:
      'Output is estimated. You will receive at least',
    swapResultDescriptionPart2: 'or the transaction will revert.',
    price: 'Price',
    minimumReceived: 'Minimum Received',
    priceImpact: 'Price Impact',
    liquidityProviderFee: 'Liquidity Provider Fee',
    minimumReceivedTip:
      'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
    priceImpactTip:
      'The difference between the market price and your price due to trade size.',
    liquidityProviderFeeTip:
      'For each trade a 0.25% fee is paid - 0.17% to LP token holders - 0.0225% to the Treasury - 0.0575% towards CAKE buyback and burn',
    enterAnAmount: 'Enter an amount',
    transactionSubmitted: 'Transaction submitted!',
    transactionRejected: 'Transaction rejected!',
    close: 'Close',
    dismiss: 'Dismiss',
    error: 'Error',
    viewTxOnScan: 'View on {{scanner}}',
    youWillReceive: 'You will receive',
    receiveTokens: 'Receive LP tokens and earn 0.17% trading fees',
    liquidityProvidersTip:
      'Liquidity providers earn a 0.17% trading fee on all trades made for that token pair, proportional to their share of the liquidity pool.',
    balance: 'Balance',
    prices: 'Prices',
    shareOfPool: 'Share of Pool',
    per: 'per',
    from: 'from',
    to: 'to',
    date: 'date',
    for: 'for',
    lp: 'LP',
    waitingForConfirmation: 'Waiting for confirmation',
    swapping: 'Swapping',
    supplying: 'Supplying',
    and: 'and',
    confirmTransaction: 'Confirm this transaction in your wallet',
    addToWallet: 'Add {{tokenName}} to Wallet',
    max: 'Max',
    min: 'Min',
    insufficient: 'Insufficient',
    copy: 'Copy',
    copied: 'Copied',
    tokenAddressCopied: 'Token address copied',
    explore: 'Explore',
    disconnect: 'Disconnect',
    recentTransactions: 'Recent Transactions',
    noRecentTransactions: 'No recent transactions',
    outputEstimated:
      'Output is estimated. If the price changes by more than 0.1% your transaction will revert.',
    burned: 'Burned',
    insufficientBalance: 'Insufficient Balance',
    expectedOutput: 'Expected output',
    minimumReceivedAfterSlippage: 'Minimum received after slippage',
    commonTokens: 'common tokens',
    searchName: 'Search name or paste address',
    selectPair: 'Select Pair',
    setPriceRange: 'Set Price Range',
    hide: 'Hide',
    show: 'Show',
    edit: 'Edit',
    feeTier: 'Fee tier',
    the: 'The',
    selected: 'Selected',
    earnInFees: 'you will earn in fees',
    depositAmounts: 'Deposit Amounts',
    veryStablePairs: 'Best for very stable pairs.',
    stablePairs: 'Best for stable pairs.',
    mostPairs: 'Best for most pairs.',
    exoticPairs: 'Best for exotic pairs.',
    yourPositionWillAppearHere: 'Your position will appear here.',
    poolMustBeInitialized:
      'This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Gas fees will be higher than usual due to the initialization transaction.',
    current: 'Current',
    setStartingPrice: 'Set Starting Price',
    minPrice: 'Min Price',
    maxPrice: 'Max Price',
    currentPrice: 'Current Price',
    termsOfUse: 'Terms of use',
    communityGuidelines: 'Community guidelines',
    insufficientLiquidity: 'Insufficient liquidity',
    newPosition: 'New Position',
    positionsWillAppearHere:
      'Your active liquidity positions will appear here.',
    metaMask: 'MetaMask',
    coinbase: 'Coinbase Wallet',
    walletConnect: 'WalletConnect',
    byConnecting: 'By connecting a wallet, you agree to DemoDex’s ',
    andConsentTo: 'and consent to its',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    collections: 'Collections',
    volume: 'Volume:',
    createCollection: 'Create collection',
    requiredFields: 'Required fields',
    logoImage: 'Logo image',
    logoImageDescription:
      'This image will also be used for navigation. 350 x 350 recommended.',
    featureImage: 'Featured image',
    featureImageDescription:
      'This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of OpenSea. 600 x 400 recommended.',
    bannerImage: 'Banner image',
    bannerImageDescription:
      'This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 350 recommended.',
    name: 'Name',
    nameExample: 'Example : Treasures of the sea',
    url: 'URL',
    urlDescription:
      'Customize your URL on DemoDex. Must only contain lowercase letters, numbers, and hyphens.',
    description: 'Description',
    descriptionOfDescription:
      'The description will include on the collection details page. Max size 1000 symbols.',
    projectsWebsite: "Project's Website",
    creatorFees: 'Creator fees',
    creatorFeesDescription:
      'Collection owners can collect a fee when a user re-sells an item they created. Contact the collection owner to change the fee percentage or the payout address.',
    addAddress: 'Add Address',
    blockchain: 'Blockchain',
    blockchainDescription:
      'Select the blockchain where you`d like new items from this collection to be added by default.',
    paymentsTokens: 'Payment tokens',
    paymentsTokensDescription:
      'These tokens can be used to buy and sell your items.',
    explicitContent: 'Explicit & sensitive content',
    explicitContentDescription:
      'Set this collection as explicit and sensitive content',
    explicitTip:
      'Setting your collection as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing OpenSea.',
    openRarityRanking: 'Show OpenRarity ranking',
    openRarityDescription:
      'Turn on after all items revealed and attribute metadata is finalized',
    addLiquidityV2: 'Add Liquidity V2',
    createPair: 'Create Pair',
    efficiencyComparison: 'Efficiency Comparison',
    fullRangeWarningText:
      'Full range positions may earn less fees than concentrated positions.',
    iUnderstand: 'I understand',
    createNewItem: 'Create New Item',
    nftImage: 'Image',
    fileTypeSupported: 'File type supported',
    fileTypes: 'JPG, PNG, GIF, SVG, WEBP',
    maxSize: 'Max size: 50 MB.',
    externalLink: 'External link',
    externalLinkDescription:
      'DemoDex will include a link to this URL on this item’s detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details.',
    descriptionOfCreateNft:
      'The description will be included on the item’s detail page underneath its image.',
    collection: 'Collection',
    collectionDescription: 'This is the collection where your item will appear',
    collectionTip:
      'Moving items to a different collection may take up to 30 minutes.',
    selectCollection: 'Select Collection',
    properties: 'Properties',
    propertiesDescription: 'Textual traits that show up as rectangles',
    levels: 'Levels',
    levelsDescription: 'Numerical traits that show as a progress bar',
    stats: 'Stats',
    statsDescription: 'Numerical traits that just show as numbers',
    unlockableContent: 'Unlockable Content',
    unlockableDescription:
      'Include unlockable content that can only be revealed by the owner of the item.',
    supply: 'Supply',
    supplyDescription:
      'The number of items that can be minted. No gas cost to you!',
    freezeMetadata: 'Freeze metadata',
    freezeMetadataTip:
      'Once locked, your content cannot be edited or removed as it is permanently stored in decentralized file storage, which will be accessible for other clients to view and use. ',
    freezeMetadataDescription:
      'Freezing your metadata will allow you to permanently lock and store all of this item`s content in decentralized file storage.',
    freezeMetadataCondition:
      'To freeze your metadata, you must create your item first.',
    uploadArea: 'Upload area',
    loading: 'Loading',
    buy: 'Buy',
    you: 'You',
    manageYours: 'Manage Yours',
    youDontHaveAnyItems: 'You don’t have any of this item.',
    details: 'Details',
    contractAddress: 'Contract Address',
    ipfsJSON: 'IPFS JSON',
    failedToSwitchNetwork: 'Failed to switch network',
    toUseDemoDexSwitchNetwork:
      'To use DemoDex on {{networkName}}, switch the network in your wallet.',
    approveToken: 'Approve Token',
    waitForApprove: 'Waiting for approve',
    exploreCollections: 'Explore collections',
    newestCollections: 'Newest Collections',
    owner: 'Owner',
    event: 'Event',
    hotCollections: 'Hot Collections',
    allCollections: 'All Collections',
    items: 'Items',
    itemsListed: 'Items listed',
    lowest: 'Lowest',
    vol: 'Vol.',
    review: 'Review',
    token: 'Token',
    payWith: 'Pay with',
    totalPayment: 'Total payment',
    inWallet: '{{tokenName}} in wallet',
    noEnoughTokens: 'Not enough {{tokenName}} to purchase this NFT',
    convertTokens:
      'Convert between {{firstTokenName}} and {{secondTokenName}} for free',
    convert: 'Convert',
    checkout: 'Checkout',
    getTokens: 'Get {{firstTokenName}} or {{secondTokenName}}',
    demoDexChat: 'DemoDex Chat',
    customise: 'Customise',
    customiseChat: 'Customise chat',
    selectAvatar: 'Select Avatar',
    yourPositions: 'Your positions',
    closedPosition: '{{action}} closed positions',
    transactionFrontrun: 'Your transaction may be frontrun',
    transactionMayFail: 'Your transaction may fail',
    errorSlippagePercentage: 'Enter a valid slippage percentage',
    increaseLiquidity: 'Increase Liquidity',
    removeLiquidityText: 'Remove Liquidity',
    unclaimedFees: 'Unclaimed fees',
    priceRange: 'Price range',
    removeLiquidityTitle: 'Remove Liquidity',
    amount: 'Amount',
    pooled: 'Pooled',
    feesEarned: 'Fees Earned',
    collectFeesTitle: 'You will also collect fees earned from this position.',
    collectAtWETH: 'Collect at WETH',
    remove: 'Remove',
    clearAll: 'Clear all',
    fullRange: 'Full Range',
    clearAllSettings: 'Clear all settings',
    selectedRange: 'Selected range',
    priceLiquidityDescription: 'Your position will be 100% composed of',
    atThisPrice: 'at this price',
    addMoreLiquidity: 'Add more liquidity',
    askingPrice: 'Asking price',
    sortBy: 'Sort by',
    newestArrivals: 'Newest Arrivals',
    viewAll: 'View all',
    moreFromThisCollection: 'More from this collection',
    count: 'Count',
    rarity: 'Rarity',
    welcomeTitle: 'Welcome to DemoDex',
    hotHFTCollections: 'Hot NFT Collections',
    transactionHistory: 'Transaction history',
    dateAdded: 'Date added',
    lowestPrice: 'Lowest price',
    highestPrice: 'Highest price',
    yourPrice: 'Your price',
    tokenID: 'Token ID',
    filterBy: 'Filter By',
    listed: 'Listed',
    delisted: 'Delisted',
    sold: 'Sold',
    modified: 'Modified',
    refresh: 'Refresh',
    item: 'Item',
    viewAllCollections: 'View all collections',
    nickNameError: 'Nickname cannot start or consist only of spaces',
    inRangeTip:
      'The price of this pool is within your selected range. Your position is currently earning fees.',
    outOfRangeTip:
      'The price of this pool is outside of your selected range. Your position is not currently earning fees.',
    inRange: 'In range',
    outOfRange: 'Out of range',
    closed: 'Closed',
    approveCollectionInWallet:
      'You`ll be asked to approve this collection from your wallet.You onlu need to approve each collection once.',
    goToYourWallet: 'Go to your wallet',
    approveCollection: 'Approve collection',
    chain: 'Chain',
    approveListing:
      'You`ll be asked to review and confirm this listing from your wallet.',
    completeListing: 'Complete listing',
    acceptAndSign: 'Accept and sign',
    collectFees: 'Collect fees',
    collectingFees: 'Collecting fees',
    claimFees: 'Claim fees',
    collectFeesDescription:
      'Collecting fees will withdraw currently available fees for you.',
    collect: 'Collect',
    highPriceImpactTip:
      'Price Impact Too High. Reduce amount of {{tokenName}} to maximum limit',
    input: 'Input',
    rates: 'Rates',
    confirmSupply: 'Confirm supply',
    removeLiquidityV2: 'Remove {{firstToken}}-{{secondToken}} liquidity',
    receive: 'Receive',
    detailed: 'Detailed',
    simple: 'Simple',
    receiveToken: 'Receive {{tokenName}}',
    enable: 'Enable',
    lpTokensInYourWallet: 'LP tokens in your wallet',
    outputIsEstimated:
      'Output is estimated. If the price changes by more than {{slippageTolerance}}% your transaction will revert.',
    removing: 'Removing',
    approve: 'Approve',
    preview: 'Preview',
    noTokenBalance: 'No token balance',
    cancel: 'Cancel',
    seeMore: 'See More',
    seeLess: 'See Less',
    wrap: 'Wrap',
    unwrap: 'Unwrap',
    wrapping: 'Wrapping',
    unwrapping: 'Unwrapping',
    confirmWrap: 'Confirm Wrap',
    confirmUnwrap: 'Confirm Unwrap',
    confirm: 'Confirm',
    confirmTransactionInWallet: 'Please confirm the transaction in your wallet',
    tansactionConfirmed: 'Transaction Confirmed!',
    removeFromMarket: 'Remove from Market',
    removeFromMarketDescription:
      'Removing this NFT from the marketplace will return it to your wallet.',
    continue: 'Continue',
    enabled: 'Enabled',
    enableListingText: 'Please enable your NFT to be sent to the market',
    defaultCollection: 'Default Collection',
    sell: 'Sell',
    setPrice: 'Set price',
    adjustSalePrice: 'Adjust sale price',
    sellerPaysPlatformFeeOnSale:
      'Seller pays {{percent}}% platform fee on sale',
    sellerPaysPlatformFeeOnSaleTip:
      'When selling NFTs from this collection, a portion of the {{cryptocurrency}} paid will diverted before reaching the seller.',
    platformFeeIfSold: 'Platform fee if sold',
    lowestPriceOnMarket: 'Lowest price on market',
    nftWillBeRemovedFromWalletAndPutOnSale:
      'The NFT will be removed from your wallet and put on sale at this price.',
    enableListing: 'Enable listing',
    nftNameAlreadyExists: 'This NFT name already exists. Please, try another!',
    allNfts: 'All NFTs',
    overview: 'Overview',
    myNFTs: 'My NFTs',
    myCollections: 'My Collections',
    createNft: 'Create NFT',
    youDontHaveCreatedCollections: "You don't have created collections yet",
    youDontHaveCreatedNFTs: "You don't have created NFTs yet",
    sellNFTWarning:
      'The NFT will be removed from your wallet and put on sale at this price.\n' +
      'Sales are in {{nativeToken}}.',
    dragYourNftHere: 'Drag your NFT here',
    thereIsNoNftInCollection: 'There is no NFT in this collection yet',
    creatorEarnings: 'Enter address of recipient e.g. 0x85..',
    total: 'Total',
    totalErr: 'Total creator earnings must be between 0.5% and 10%.',
    type: 'Type',
    addMore: 'Add more',
    selectBlockchain: 'Select blockchain',
    forSale: 'For sale',
    adjustNewPrice: 'Adjust new price',
    fileTypeError: 'Invalid image format.',
    fileSizeError: 'Invalid image size.',
    symbolsLeft: '{{count}} symbols left',
    nftTitle: 'NFT title',
    collectionTitle: 'Collection title',
    invalidRangeSelected:
      'Invalid range selected. The min price must be lower than the max price.',
    outOfRangeInfo:
      'Your position will not earn fees or be used in trades until the market price moves into your range.',
    singleAssetDeposit:
      'The market price is outside your specified price range.Single-asset deposit only',
    maxSizeDescription: 'Max size 350 symbols.',
    maxItems: 'Max 10 000 items',
    invalidNumberOfItems: 'Invalid number of items',
    bscScan: 'BscScan',
    pleaseConnectWallet: 'Please connect your Wallet',
    itemIsCreating: '{{item}} is creating',
    pleaseWaitForAMoment: 'Please wait for a moment',
    nftCollection: 'NFT Collection',
    youSellNft: 'You sell',
    sellDetails: '{{qty}} NFT - {{nftName}}.',
    switchNetwork: 'Switch Network',
    switchNetworkForBuyNft:
      'If you want to interact with this NFT,you should switch network',
    noTransaction: 'You don`t have any transactions yet',
    imageIsLoadingNow: 'Image is loading now',
    noDescription: 'No description yet',
    allowedPriceRange:
      'Allowed price range is between 0.005 and 10000 {{tokenSymbol}}',
    deleteTransactionsHistory: 'Clear your transactions history?',
    youWillCreateNftOnNetwork:
      'You will create NFT on {{blockchainName}} blockchain',
    creatorFee: 'Creator Fee',
    nftIsNotForSaleYet: "This NFT is not 'For Sale' yet.",
    noTransactionsYet: 'No transactions yet.',
    lpCurrentProjectsTitle: 'Launched projects',
    lpUpcomingProjectsTitle: 'Upcoming projects',
    lpCompletedProjectsTitle: 'Completed projects',
    registrationOpen: 'Registration open',
    registrationClose: 'Registration closed',
    IDO: 'IDO',
    fundraiseGoal: 'Fundraise goal',
    allocation: 'Allocation',
    allocationRoundStartsIn: 'Allocation round starts in:',
    allocationRoundFinishsIn: 'Allocation round finishes in:',
    toBeAnnounced: 'To be announced',
    lpSocialMedia: 'Social Media',
    lpWebsite: 'Website',
    tokenSaleDetails: 'Token Sale Details',
    salePrice: 'Sale Price',
    tokenAllocationForSale: 'Token Allocation For Sale',
    tokenAllocationForSaleMobile:
      'Token Allocation for Sale (how many tokens in total are you ready to sell on launchpad?)',
    idoVesting: 'IDO Vesting',
    percentOfTotalSupply: '% of Total Supply',
    excludedParticipants: 'Excluded Participants',
    category: 'Category',
    allTimeHigh: 'All time high',
    participants: 'Participants',
    totalRaise: 'Total raise',
    tokenPrice: 'Token price',
    endedIn: 'Ended in',
    basicInformation: 'Basic Information',
    submitApplication: 'Submit application',
    email: 'Email',
    projectName: 'Project Name',
    website: 'Website URL',
    video: 'Video URL (YouTube only)',
    videoErrorText: 'Only links from youtube are acceptable',
    whitepaper: 'Whitepaper URL',
    pitchDeck: 'Pitch Deck URL',
    twitterURL: 'Twitter URL',
    telegramWhatsapp: 'Telegram / WhatsApp',
    tokenInfo: 'Token Info',
    infoAboutTeam: 'Information about the team',
    githubURL: 'Github URL',
    stateOfDevelopment: 'State of Development',
    howMuchHaveYouAlreadyRaised:
      'How much have you already raised? (hard commitments)',
    demoDexPoolInformation: 'DemoDex Pool Information',
    howMuchAreYouLookingToRaise:
      'How much are you looking to raise with DemoDex? ($)',
    howMuchAreYouLookingToRaiseInTotal:
      'How much are you looking to raise in total excluding DemoDex? ($)',
    areYouFlexibleWithAmount: 'Are you flexible with the amount?',
    no: 'No',
    yes: 'Yes',
    whenWouldYouLikeToLaunch: 'When would you like to launch?',
    areYouFlexibleWithDate: 'Are you flexible with the date?',
    whatChainWouldYouPreferToRaiseFundsOn:
      'What chain would you prefer to raise funds on?',
    shareYourInvestors:
      'Please share your three biggest investors, as well as their average ticket size',
    doYouPlanToLaunchOnOtherPlatforms:
      'Do you plan to launch on any other IDO platforms apart from DemoDex?',
    areYouOpenToExclusiveIDO: 'Are you open to exclusive IDO with DemoDex?',
    whatOtherPlatformsAreYouPlanning:
      'What other platforms are you planning? Are they confirmed?',
    areYouWorkingWithMarketingAgencies:
      'Are you working with any marketing agencies?',
    doYouHaveInfluencerNetwork:
      'Do you have an influencer network supporting you?',
    tokenDistributionDate: 'Token Distribution Date (UTC)',
    yourApplications: 'Your Applications',
    applyToLaunchYourProject: 'Apply to launch your project on DemoDex',
    raising: 'Raising',
    appliedAbout: 'Applied about',
    readSelectionCriteria:
      'Important: please read our Selection Criteria before applying',
    newApplication: 'New application',
    applicationUnderReview: 'Your Application is Under Team Review',
    applicationUnderReviewByOurTeam:
      'Your application is now under review by our team of researchers.',
    weWillNotifyYou:
      "We'll notify you via email to {{email}} when there is an update.",
    warningAboutEmailNotifications: 'Warning about Email Notifications',
    warningAboutEmailNotificationsInfo:
      "We'll never ask you for your tokens via email. The only notifications we can send you are progress updates regarding your application process.",
    problem: 'Problem',
    product: 'Product',
    businessmodel: 'Business Model',
    roadmap: 'Roadmap',
    dragYourPictureHere: 'Drag your picture here',
    investors: 'Investors',
    descriptionofbudgetdistribution: 'Description of Budget Distribution',
    maxPerWallet: 'Max allocation',
    lpCountdownDays: 'days',
    lpCountdownHours: 'hours',
    lpCountdownMinutes: 'minutes',
    lpCountdownSeconds: 'seconds',
    minPerWallet: 'Min allocation',
    myProjects: 'My projects',
    tokenSymbol: 'Token symbol',
    tokenName: 'Token name',
    totalSupply: 'Total token supply',
    totalTokenSupply: 'Total Supply',
    invest: 'Invest',
    categories: 'Categories',
    fieldIsRequired: 'Field is required',
    invalidEmail: 'Invalid email address',
    invalidURL: 'Invalid URL',
    enterNumbers: 'Enter only numbers',
    summary: 'Summary',
    myProjectStatus: 'My project status',
    editProject: 'Edit project',
    deployProject: 'Deploy project',
    clickToUploadOrDragAndDrop: 'Click to upload or drag and drop',
    supportedFilesInLaunchpad:
      'All .jpg, .png files up to 10 MB are supproted.',
    memberName: 'Member Name',
    memberPosition: 'Member Position',
    about: 'About',
    linkedInURL: 'LinkedIn URL',
    addMember: 'Add Member',
    projectOverview: 'Project Overview',
    linkedIn: 'LinkedIn',
    payableToken: 'Payable Token',
    youWillRecieve: 'You will recieve',
    investSwitchNetwork:
      'If you want to interact with this Project,you should switch network',
    areYouSureYouWantToDeleteYourProject:
      'Are you sure you want to delete your project?',
    deleteProject: 'Delete project',
    contactInfo: 'Contact Information',
    goal: 'Goal',
    raised: 'Raised',
    goBackAndCleareForm:
      'Please confirm that you want to navigate back to the previous page. Any information entered on the current page will be lost.',
    enterLowestPercentageOfFundraisingGoal:
      'What % of the fundraising goal will be considered successful? (%)',
    allDataWillBeLost:
      'If you leave this page, your changes will be lost, so fill all required fields and save your changes at the end of the page.',
    faq: 'FAQ',
    confirmation: 'Confirmation',
    leavePage: 'Leave Page',
    saveAndContinue: 'Save and continue',
    claim: 'Claim',
    minimumAmountForProjectSuccess:
      'Minimum amount for the success of the project',
    pendingApproval: 'Pending approval',
    createNewProject: 'Create new project',
    delete: 'Delete',
    allFieldsBelowAreRequired:
      'All fields below are required. The information you provide here will be seen by the user going to the sales page of your token.',
    yourAmount: 'Your amount',
    allImagesTextAndInformationWillBeDeleted:
      'All images, text and information, associated with this project, will be permanently deleted.',
    tokenDistributionDateMustBeBiggerThatLaunchDate:
      'The token distribution date must be equal to or bigger than the launch date',
    seeDetails: 'See details',
    hideDetails: 'Hide details',
    pricePerToken: 'Price per token',
    tokenSaleStarts: 'Token Sale Starts',
    goToMetaMaskBrowser:
      'Sorry, but this feature works only in the MetaMask browser. To use this type of connection, please open this website in your MetaMask App. Alternatively, you can use a different type of wallet connection here.',
    metaMaskInfo: 'MetaMask information',
    goToHomePage: 'Go to home page',
    smtWentWrong: ' Oops, something went wrong.',
    youAlreadyCreatedThreeProjects:
      'Sorry, you cannot create a project today. The daily limit is 3 projects.',
    siteSupportsPortraitMode:
      'The site only supports portrait mode, please turn your phone to portrait mode',
    reject: 'Reject',
    commissionPolicy: 'Commission policy',
    commissionWarningFirstItem:
      'Launchpad participation requires a commision of [{{commissionPercent}}% from total token allocation amount] that will be withdrawn after the successful IDO process;',
    commissionWarningSecondItem:
      'After clicking "OK" you agree with DemoDex Commission Policy;',
    commissionWarningThirdItem:
      'Your project can be reviewed in "My Projects" tab;',
    forThisProjectYouMayStillProvide: 'For this project, you may still provide',
    investErrorText:
      'Please enter an invested value that is within the min and max allocation limits and does not exceed the available investment quantity.',
    estimatedGasUsed: 'Estimated gas used',
    externalURL: 'External URL',
    collectionNameOrURLError:
      'Collection name or URL already exists. Please, enter a unique value.',
    sthWentWrongWhileCreatingTryAgainLater:
      'Oops! Something went wrong while creating your {{item}}. Please, try again later.',
    toAddLiquidityYouMustHaveABalanceOfTokens:
      'To add liquidity, you must have a balance of one and the other token.',
    waitForLoadingData: 'Wait for data loading',
    unsupportedNetworkError:
      '{{networkName}} is unsupported. Please, switch the network in your wallet.',
    notEnoughNativeTokenForPayGas:
      "You don't have enough native tokens from that network to pay for gas",
    tokenomics: 'Tokenomics',
    tokenSaleDetailsAndSummary: 'Token sale details and summary',
    pleaseWaitForImageUpload: 'Please wait...',
    contentUploading: 'content is being uploaded, it will take a little time',
  },
};
