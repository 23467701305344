import { styled } from '@mui/system';
import { Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledText = styled('p')(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.LARGE,
  color: Color.WHITE,
  fontWeight: 400,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 0,
  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));
