import ERC20_ABI from '@abis/erc20.json';
import Launchpad_ABI from '@abis/launchpad.json';
import { Erc20, Launchpad } from '@abis/types';
import { Contract } from '@ethersproject/contracts';
import QuoterV2Json from '@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json';
import IUniswapV2PairJson from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json';
import V3MigratorJson from '@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json';
import QuoterJson from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';
import TickLensJson from '@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json';
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json';
// import {
//   ARGENT_WALLET_DETECTOR_ADDRESS,
//   ENS_REGISTRAR_ADDRESSES,
//   MULTICALL_ADDRESS,
//   NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
//   QUOTER_ADDRESSES,
//   TICK_LENS_ADDRESSES,
//   V2_ROUTER_ADDRESS,
//   V3_MIGRATOR_ADDRESSES,
// } from 'constants/addresses';
// import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens';
import { useMemo } from 'react';
// import {
//   NonfungiblePositionManager,
//   Quoter,
//   QuoterV2,
//   TickLens,
//   UniswapInterfaceMulticall,
// } from 'types/v3';

import { V3_SWAP_ROUTER_ADDRESS } from 'config/contracts';
import { useAccount, useClient } from 'wagmi';
import { useSigner } from './useSigner';
const { abi: IUniswapV2PairABI } = IUniswapV2PairJson;
const { abi: QuoterABI } = QuoterJson;
const { abi: QuoterV2ABI } = QuoterV2Json;
const { abi: TickLensABI } = TickLensJson;
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson;
const { abi: V2MigratorABI } = V3MigratorJson;

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any
): T | null {
  const provider = useClient();
  const { chain } = useAccount();
  const chainId = chain?.id ?? 1;
  const signer = useSigner({ chainId: chainId });

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return new Contract(address, ABI, signer);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, provider, signer, chainId]) as T;
}

export function useTokenContract(tokenAddress?: string) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI);
}
export function useLaunchpadContract(launchpadAddress?: string) {
  return useContract<Launchpad>(launchpadAddress, Launchpad_ABI);
}
export function useWETHContract(withSignerIfPossible?: boolean) {
  //   const { chainId } = useWeb3React()
  //   return useContract<Weth>(
  //     chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
  //     WETH_ABI,
  //     withSignerIfPossible
  //   )
}

export function useERC721Contract(nftAddress?: string) {
  //   return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  //   return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

// export function useV3NFTPositionManagerContract(
//   withSignerIfPossible?: boolean
// ): NonfungiblePositionManager | null {
//     return useContract<NonfungiblePositionManager>(
//       NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
//       NFTPositionManagerABI,
//       withSignerIfPossible
//     )
// }

export function useQuoter(useQuoterV2: boolean) {
  //   return useContract<Quoter | QuoterV2>(QUOTER_ADDRESSES, useQuoterV2 ? QuoterV2ABI : QuoterABI)
}

export function useUniversalRouter() {
  const { chain } = useAccount();

  const address = useMemo(() => {
    if (!chain) {
      return;
    }
    return V3_SWAP_ROUTER_ADDRESS[chain.id];
  }, [chain]);

  return { address };
}
