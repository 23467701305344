import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { useAccount } from 'wagmi';
import { useSigner } from '@hooks/useSigner';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  CurrencyAmount,
  DEXToken,
  PancakePair,
  utils,
} from '@omisoftnet/game-dex-sdk';

import TransactionResultPopup from 'components/TransactionResultPopup';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import { Color } from 'helpers/themeStyles';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { liquiditySettingsSelector } from 'state/liquidity/selectors';
import { StyledTooltip } from './StyledComponents';
import {
  StyledButton,
  StyledColumnContainer,
  StyledDetails,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
  StyledGreenCircle,
  StyledRatesContainer,
  StyledRatesTitle,
  StyledRowContainer,
  StyledText,
  StyledTipText,
  StyledTokensAmount,
  StyledTokensName,
} from './styles';
import { dexSelector } from 'state/dex/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import { fetchData, updateLiquidity } from 'config/api';
import { Pool } from 'types/pool';
import { toHex } from 'components/AddLiquidityV2/AddLiquidityV2';

type ConfirmModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  fee?: number;
  token0: DEXToken;
  token1: DEXToken;
  liquidityId?: string;
  firstTokenPerSecondToken: string | number;
  secondTokenPerFirstToken: string | number;
  clearTokensValue: () => void;
};

export default function ConfirmLiquidityV2Popup({
  isOpen,
  setIsOpen,
  fee,
  token0,
  token1,
  liquidityId,
  clearTokensValue,
  firstTokenPerSecondToken,
  secondTokenPerFirstToken,
}: ConfirmModalProps) {
  const { t } = useTranslation();
  const { address } = useAccount();
  const liquiditySettings = useSelector(liquiditySettingsSelector);
  const dex = useSelector(dexSelector);
  const dispatch = useDispatch();
  const signer = useSigner();
  const theme = useTheme();
  const [pools, setPoolsData] = useState<[Pool]>();
  const [existingPool, setExistingPool] = useState<Pool | null>();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [gasError, setGasError] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const [liquidityAmount, setLiquidityAmount] = useState<string>();

  async function fetchLiquidityAmount() {
    if (!dex) return;

    const provider = await dex.getDEXProvider(token0?.chainId!, token0, token1);
    const pair = await PancakePair.from(
      token0.wrapped,
      token1.wrapped,
      signer!,
      await provider.factory()
    );
    const isSorted = pair!.tokenA.sortsBefore(pair!.tokenB);
    const liquidityTokensRaw = pair.pair.getLiquidityMinted(
      CurrencyAmount.fromRawAmount(
        pair.pair.liquidityToken,
        (await pair.totalSupply()).toHexString()
      ),
      CurrencyAmount.fromRawAmount(
        isSorted ? pair.tokenA : pair.tokenB,
        token0.amount.toHexString()
      ),
      CurrencyAmount.fromRawAmount(
        isSorted ? pair.tokenB : pair.tokenA,
        token1.amount.toHexString()
      )
    ).quotient;
    setLiquidityAmount(
      utils
        .formatUnits(
          toHex(liquidityTokensRaw),
          pair.pair.liquidityToken.decimals
        )
        .slice(0, 12)
    );
  }
  const getPools = async () => {
    const userId = localStorage.getItem('userId');
    const data = await fetchData(`/liquidity/${userId}`);
    setPoolsData(data.data);
    const isUserHaveSamePool =
      pools?.find(
        (item) =>
          item?.tokenAAdress === token0?.wrapped.address &&
          item?.tokenBAdress === token1?.wrapped.address
      ) ||
      pools?.find(
        (item) =>
          item?.tokenAAdress === token1?.wrapped.address &&
          item?.tokenBAdress === token0?.wrapped.address
      );
    setExistingPool(isUserHaveSamePool);
  };

  useEffect(() => {
    fetchLiquidityAmount();
    getPools();
  }, [token0, token1]);
  const [tokenA, tokenB] = useMemo(() => {
    return token0.sortsBefore(token1) ? [token0, token1] : [token1, token0];
  }, [token0, token1]);

  async function addLiquidity() {
    if (!dex) return;
    const provider = await dex.getDEXProvider(token0?.chainId!, token0, token1);
    setGasError(false);
    try {
      let tx: any; // ?????????????????
      const parsedSlipage = Math.floor(
        Number.parseFloat(String(liquiditySettings.slippage)) * 100
      );
      const deadline =
        Math.floor(Date.now() / 1000) + 60 * liquiditySettings.deadline;
      try {
        tx = await provider.addLiquidity('', tokenA, tokenB, {
          deadline: deadline,
          slipage: parsedSlipage,
        });
      } catch (err) {
        const a = await err;
        console.log(err);
        if (a.hash) {
          tx = a;
        }
        if (!a.hash && err.reason !== 'user rejected transaction') {
          setGasError(true);
        } else {
          setTransactionRejectedStatus(true);
          setOpenWaitingPopup(false);
        }
      }
      if (tx) {
        const data = utils.defaultAbiCoder.decode(
          ['uint', 'uint', 'uint'],
          tx.data
        );

        dispatch(
          setTransactionHash({
            hash: tx!.hash,
            from: tx.from,
            to: tx.to,
            owner: address!,
            chainId: token0!.chainId,
            transaction_type: 'addLiquidity',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: token0?.icon,
                },
                token1: {
                  ...token1,
                  logoURI: token1?.icon,
                },
                token0Amount: utils
                  .formatUnits(token0.amount, token0.decimals)
                  .slice(0.6),
                token1Amount: utils
                  .formatUnits(token1.amount, token1.decimals)
                  .slice(0.6),
              },
            },
          })
        );
        existingPool
          ? await updateLiquidity(`/liquidity/update/${existingPool._id}`, {
              user: localStorage.getItem('userId')!,
              amountA:
                existingPool.tokenAAdress === token0.wrapped.address
                  ? token0.amount.add(existingPool.amountA).toHexString()
                  : token1.amount.add(existingPool.amountA).toHexString(),
              amountB:
                existingPool.tokenBAdress === token1.wrapped.address
                  ? token1.amount.add(existingPool.amountB).toHexString()
                  : token0.amount.add(existingPool.amountB).toHexString(),
              tokenAAdress:
                existingPool.tokenAAdress === token0.wrapped.address
                  ? token0!.wrapped.address
                  : token1.wrapped.address,
              tokenBAdress:
                existingPool.tokenBAdress === token1.wrapped.address
                  ? token1!.wrapped.address
                  : token0!.wrapped.address,
              liquidity: data[2].toHexString(),
            })
          : await updateLiquidity(`/liquidity/update/undefined`, {
              user: localStorage.getItem('userId')!,
              amountA: token0.amount.toHexString(),
              amountB: token1.amount.toHexString(),
              tokenAAdress: token0!.wrapped.address,
              tokenBAdress: token1!.wrapped.address,
              liquidity: data[2].toHexString(),
            });

        setIsOpen(false);
        setTransactionSubmittedStatus(true);
        setOpenWaitingPopup(false);
      }
    } catch (error) {
      setTransactionRejectedStatus(true);
      setOpenWaitingPopup(false);
      console.error(error); // for debug
    }
  }

  return (
    <>
      <StyledDialog
        onClose={handleClose}
        open={isOpen}
      >
        <StyledDialogHeader>
          <StyledDialogTitle fontWeight={700}>
            {t('youWillReceive')}
          </StyledDialogTitle>
          <Close
            onClick={handleClose}
            sx={{ cursor: 'pointer', color: Color.WHITE }}
          />
        </StyledDialogHeader>
        <StyledDialogContent>
          <StyledRowContainer style={{ marginBottom: 16 }}>
            <div>
              <img
                src={token0?.icon}
                alt={token0?.symbol}
                width='24'
                height='24'
              />
              <img
                src={token1?.icon}
                alt={token1?.symbol}
                width='24'
                height='24'
                style={{ marginLeft: '-5px' }}
              />
            </div>
            <StyledTokensName style={{ flexGrow: 1 }}>
              {`${token0?.symbol}/${token1?.symbol} ${t('lp')}`}
            </StyledTokensName>
            <StyledTokensAmount>{liquidityAmount}</StyledTokensAmount>
          </StyledRowContainer>
          <StyledColumnContainer marginBottom={1}>
            <StyledText>{t('input')}</StyledText>
            <StyledRowContainer style={{ width: '100%' }}>
              <StyledGreenCircle />
              <img
                src={token0?.icon}
                alt={token0?.symbol}
                width='24'
                height='24'
              />
              <StyledText style={{ flexGrow: 1 }}>{token0?.symbol}</StyledText>
              <StyledText>
                {isMobile
                  ? utils
                      .formatUnits(`${token0.amount}`, token0.decimals)
                      .slice(0, 8)
                  : utils
                      .formatUnits(`${token0.amount}`, token0.decimals)
                      .slice(0, 25)}
              </StyledText>
            </StyledRowContainer>
            <StyledRowContainer style={{ width: '100%' }}>
              <StyledGreenCircle />
              <img
                src={token1?.icon}
                alt={token1?.symbol}
                width='24'
                height='24'
              />
              <StyledText style={{ flexGrow: 1 }}>{token1?.symbol}</StyledText>
              <StyledText>
                {isMobile
                  ? utils
                      .formatUnits(`${token1.amount}`, token1.decimals)
                      .slice(0, 8)
                  : utils
                      .formatUnits(`${token1.amount}`, token1.decimals)
                      .slice(0, 25)}
              </StyledText>
            </StyledRowContainer>
          </StyledColumnContainer>

          <StyledRatesContainer>
            <StyledRatesTitle>{t('rates')}</StyledRatesTitle>
            <StyledDetails>
              {`1 ${token0?.symbol} = ${firstTokenPerSecondToken} ${token1?.symbol}`}
            </StyledDetails>
            <StyledDetails>
              {`1 ${token1?.symbol} = ${secondTokenPerFirstToken} ${token0?.symbol}`}
            </StyledDetails>
          </StyledRatesContainer>
          <StyledRowContainer style={{ marginBottom: isMobile ? 24 : 16 }}>
            <StyledDetails style={{ color: Color.WHITE }}>
              {t('slippageTolerance')}
            </StyledDetails>
            <StyledTooltip
              title={
                <StyledTipText>
                  {t('outputIsEstimated', {
                    slippageTolerance: liquiditySettings.slippage,
                  })}
                </StyledTipText>
              }
            >
              <div
                style={{
                  borderBottom: `1px dotted ${Color.PURPLE}`,
                  cursor: 'pointer',
                }}
              >
                <StyledDetails
                  style={{
                    color: Color.PURPLE,
                  }}
                >
                  {liquiditySettings.slippage}%
                </StyledDetails>
              </div>
            </StyledTooltip>
          </StyledRowContainer>
          <StyledButton
            onClick={() => {
              setOpenWaitingPopup(true);
              addLiquidity();
              setIsOpen(false);
            }}
          >
            {t('confirmSupply')}
          </StyledButton>
        </StyledDialogContent>
      </StyledDialog>
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          action={t('supplying')}
          firstTokenSymbol={token0?.symbol!}
          secondTokenSymbol={token1?.symbol!}
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          firstTokenValue={utils.formatUnits(
            `${token0.amount}`,
            token0.decimals
          )}
          secondTokenValue={utils.formatUnits(
            `${token1.amount}`,
            token1.decimals
          )}
          gasError={gasError}
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          fromV2
          clearTokensValue={clearTokensValue}
          navigateToPoolList
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          closeParentPopup={() => {}}
          setOpenPopup={setTransactionRejectedStatus}
          result={transactionStatuses.REJECT}
          clearTokensValue={clearTokensValue}
        />
      )}
    </>
  );
}
