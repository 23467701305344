import {
  Box,
  Button,
  Dialog,
  Stack,
  ToggleButton,
  Typography,
  styled,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: '880px',
  width: '100%',
  height: 'fit-content',
  margin: '24px auto 0',
  minHeight: 'calc(100vh - 80px)',
  paddingBottom: '24px',

  [theme.breakpoints.down('mobile')]: {
    margin: 0,
    padding: '0 16px',
    width: '90%',
  },
}));

export const StyledBackButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  letterSpacing: '0.05em',
  color: Color.WHITE,
  padding: 0,
  [theme.breakpoints.between('mobile', 1420)]: {
    paddingLeft: '10px',
  },
}));

export const StyledHeaderContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '28px',
  marginBottom: '16px',
  [theme.breakpoints.between('mobile', 1420)]: {
    flexDirection: 'column',
    rowGap: '16px',
    paddingLeft: '10px',
  },
  [theme.breakpoints.down('mobile')]: {
    marginTop: 0,
    flexWrap: 'wrap',
    gap: '25px',
  },
}));

export const StyledInfoWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledButtonWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  fontWeight: 700,
  borderRadius: BorderRadius.SMALL,
  fontSize: TextSize.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,
  padding: '16px 32px',
  fontFamily: FontFamily.INTER,
  ':hover': {
    background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
    border: 'none',
    padding: '17px 33px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
    ':hover': {
      background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
      border: 'none',
      padding: '10px',
    },
  },
}));

export const StyledSmallButton = styled(Button)(() => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  textTransform: 'capitalize',
  fontWeight: 700,
  borderRadius: BorderRadius.SMALL,
  fontSize: TextSize.SMALL,
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  color: Color.WHITE,
  padding: '12px 24px',
  fontFamily: FontFamily.INTER,
  ':hover': {
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '11px 23px',
  },
}));

export const StyledPositionItemIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
}));

export const StyledPositionSymbol = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE_PLUS,
  letterSpacing: '0.05em',
  lineHeight: '160%',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const StyledDivider = styled('div')(() => ({
  width: '2px',
  height: '32px',
  background: Color.WHITE_OPACITY,
}));

export const StyledPositionTolerance = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
}));

export const StyledStatusIcon = styled('div')(() => ({
  width: '8px',
  height: '8px',
  borderRadius: BorderRadius.HUGE,
}));

export const StyledStatusTitle = styled('p')(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 400,
  fontSize: TextSize.EXTRA_SMALL,
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
  borderRadius: BorderRadius.NORMAL,
  background: Color.SWAP_GRADIENT,

  [theme.breakpoints.down('mobile')]: {
    background: 'inherit',
    padding: '24px 0px',
  },
}));
export const StyledPoolInfoStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.between('mobile', 1420)]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}));
export const StyledContentContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: BorderRadius.NORMAL,
  background: Color.DARK_PURPLE_OPACITY_LARGE,
  [theme.breakpoints.between('mobile', 1420)]: {
    width: '95%',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '95%',
    marginBottom: '16px',
    paddingRight: 0,
  },
}));

export const StyledDolarSign = styled(Stack)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.EXTRA_LARGE,
  lineHeight: '150%',
  color: Color.WHITE_OPACITY_LIGHT,
  margin: '8px 0',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MEDIUM,
  },
}));

export const StyledTokenInfoContainer = styled(Stack)(({ theme }) => ({
  padding: '16px',
  width: '91%',
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,

  [theme.breakpoints.down('mobile')]: {
    width: '85%',
  },
}));

export const StyledTokenInfo = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledPriceRangeContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '24px',
  marginBottom: '8px',
}));
export const StyledMinMaxPriceStack = styled(Stack)(({ theme }) => ({
  gap: '12px',
  marginBottom: '12px',
  flexDirection: 'row',
  [theme.breakpoints.between('mobile', 1420)]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}));
export const StyledSwitchPairButton = styled(ToggleButton)({
  padding: '4px 8px',
  display: 'block',
  minWidth: '40px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SUPER_SMALL,
  lineHeight: '160%',
  fontWeight: '400',
  color: Color.WHITE,
  backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  ':hover': {
    backgroundColor: Color.BORDER_WHITE_EXTRA_OPACITY,
  },
});

export const StyledSubtitle = styled(Typography)({
  color: Color.WHITE_OPACITY_LIGHT,
  fontSize: TextSize.EXTRA_SMALL,
  fontFamily: FontFamily.INTER,
});

export const StyledAccentSubtitle = styled(Typography)({
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.ZENDOTS,
});

export const StyledTokenName = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: '400',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledTokenValue = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontWeight: '600',
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const StyledIncreaseDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: BorderRadius.NORMAL,
    background: Color.SWAP_GRADIENT_WITHOUT_OPACITY,

    [theme.breakpoints.down('mobile')]: {
      margin: 0,
      width: '100%',
      border: 'none',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
    },
  },
}));
