import {
  Button,
  Dialog,
  OutlinedInput,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '412px',
    borderRadius: BorderRadius.SMALL,
    background: Color.BACKGROUND,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(#161616, #161616), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,

    [theme.breakpoints.down('mobile')]: {
      margin: '24px',
    },
  },
}));

export const StyledDialogHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'transparent',
  color: Color.WHITE,
  borderBottom: Border.ACCENT_BORDER,
  padding: '8px 16px',
}));

export const StyledBackBtn = styled(Button)(() => ({
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
}));

export const StyledDialogContent = styled(Stack)(() => ({
  padding: '16px',
}));

export const StyledTitle = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  textTransform: 'uppercase',
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
}));

export const StyledRowContainer = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 16px 16px',
  borderRadius: BorderRadius.NORMAL,
  border: Border.ACCENT_BORDER,
  gap: '16px',
}));

export const StyledTokenIcon = styled('img')(() => ({
  width: 24,
  height: 24,
  borderRadius: BorderRadius.HUGE,
}));

export const StyledSmallTokenIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
  borderRadius: BorderRadius.HUGE,
  marginRight: '8px',
}));

export const StyledTokenName = styled(Typography)(() => ({
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: TextSize.NORMAL,
  color: Color.WHITE,
}));

export const StyledPriceInput = styled(OutlinedInput)(() => ({
  flex: 1,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,
  '& ::placeholder': {
    color: Color.WHITE,
  },
  '& .MuiInputBase-input': {
    height: 36,
    padding: '0 8px',
    paddingLeft: 0,
    color: Color.WHITE,
    fontWeight: 700,
    '&::placeholder': {
      color: Color.WHITE,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: Color.PURPLE,
    },
  },
  '& .MuiTypography-root': {
    color: Color.WHITE,
  },
}));

export const StyledSmallText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  fontWeight: 600,
  color: Color.WHITE,
}));

export const StyledText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.NORMAL,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '16px',
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '16px 0',
  color: Color.WHITE,
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));

export const SelectTokenButton = styled(Button)({
  padding: 0,
  columnGap: '5px',
});
