import { Button, Stack, styled } from '@mui/material';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const PaymentDetailsWrap = styled('div')(() => ({
  margin: '16px 0',
}));

export const StyledDialogContent = styled(Stack)(({ theme }) => ({
  padding: '24px 16px 0px',
}));

export const RowStyled = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '13px 0',
}));

export const NFTDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledValue = styled('span')(() => ({
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 700,
  fontFamily: FontFamily.INTER,
  background: Color.TEXT_GRADIENT,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

export const PaymentText = styled('span')(({ theme }) => ({
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  color: Color.WHITE_OPACITY_LIGHT,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.SMALL,
  },
}));

export const NftInfoContainer = styled('div')({
  display: 'flex',
  padding: '0px 12px 12px',
  justifyContent: 'space-between',
});
export const NftInfoBlockContainer = styled('div')({
  display: 'flex',
  padding: '0px 12px 12px',
  justifyContent: 'space-between',
  flexDirection: 'column',
});
export const StyledNftImage = styled('img')({
  borderRadius: '8px',
  width: '64px',
  marginRight: '14px',
});
export const StyledNftTitle = styled('h4')({
  margin: '5px 0',
  fontSize: TextSize.NORMAL,
  fontWeight: 700,
  color: Color.WHITE,
});

export const PaymentFiatValue = styled('h3')(() => ({
  margin: 0,
  fontSize: TextSize.TABLE_SMALL,
  fontWeight: 400,
  textAlign: 'end',
  color: Color.TEXT_GRAY_SECONDARY,
}));
export const StyledNftTitlePrice = styled('p')({
  margin: 0,
  color: Color.WHITE_OPACITY_LIGHT,
});
export const PaymentValue = styled('h3')(() => ({
  margin: 0,
  color: Color.WHITE_OPACITY_LIGHT,
}));
export const StyledNftCollectionTitle = styled('p')({
  margin: 0,
});
export const StyledNftForSale = styled('div')({
  margin: 0,
  color: Color.GREEN,
  display: 'flex',
  alignItems: 'center',
});
export const StyledPrice = styled('p')({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
});

export const StyledBscScan = styled('h3')(() => ({
  margin: 0,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: 700,
  textAlign: 'end',
  color: Color.PURPLE,
}));

export const StyledAdjustSalePriceButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  padding: '16px 0',
  color: Color.WHITE,
  '&.MuiButton-root': {
    background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));

export const StyledRemoveButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontFamily: FontFamily.ZENDOTS,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `2px solid ${Color.PURPLE}`,
  padding: '16px 0',
  margin: '16px 0',
  color: Color.PURPLE,
  '&.MuiButton-root': {
    background: Color.BACKGROUND,
  },
  '&.Mui-disabled': {
    background: Color.WHITE_OPACITY_LIGHT,
    color: Color.WHITE_OPACITY_LIGHT,
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '10px',
  },
}));
export const StyledSmallNoImage = styled('div')({
  background: Color.BACKGROUND_MAIN,
  width: 80,
  height: 80,
  borderRadius: BorderRadius.SMALL,
});
