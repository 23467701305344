import { ethers } from '@omisoftnet/game-dex-sdk';

export type NFT_CONTRACTS = {
  [key: number]: {
    url: string;
    contract: string;
    market: string;
  };
};
const NFT_MARKETPLACE: NFT_CONTRACTS = {
  1: {
    url: 'https://eth.llamarpc.com',
    contract: '0x27d8e104bB69E2d887D878d044def1CbC6b6eDE7',
    market: '0x46Eb091d18B3BA05Fd96d447a8A1201ca0309B99',
  },
  5: {
    url: process.env.API_URL ?? 'https://eth-goerli.public.blastapi.io', // Fallback public
    contract: '0x513931Ea3572e84f0805dd14E98AbF05c8882dbA', // ERC1155
    market: '0xae0396635fD8BC7b2e39B811B13931F6c5424b64', // Marketplace
  },
  97: {
    url: 'https://data-seed-prebsc-1-s1.binance.org:8545', // Public node
    contract: '0x666fCE0df613cdccfeB591d2eb41c7c34c508e2b',
    market: '0x3b42266bD8a5D8B468Cc5052890F15F868CfBF21',
  },
  56: {
    url: 'https://bsc-dataseed1.binance.org/', // Public node
    contract: '0x979B2F7C6Ae5E315Bade529659720945cDa0fC3F',
    market: '0x19529DBd49e2486670E5Ba8Eb7d827f6eC10E5Bb',
  },
};

export type ContractsAddressList = {
  [key: number]: string;
};

export const V3_SWAP_ROUTER_ADDRESS: ContractsAddressList = {
  1: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
  5: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
  56: '0xB971eF87ede563556b2ED4b1C0b0019111Dd85d2',
};
export const launchpadContracts: {
  [key: number]: {
    launchpadFactory: string;
    launchpadFactoryAbi: ethers.ContractInterface;
    launchpadAbi: ethers.ContractInterface;
  };
} = {
  1: {
    launchpadAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'bought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: 'merkleProof',
            type: 'bytes32[]',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
        ],
        name: 'claim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
        ],
        name: 'createPosition',
        outputs: [
          {
            internalType: 'uint128',
            name: 'liquidity',
            type: 'uint128',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'currentStage',
        outputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'options',
        outputs: [
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'factory',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'payableToken',
            type: 'address',
          },
          {
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'toSuccessAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalEndTimestamp',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'stages',
        outputs: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'toClaim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalBought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
    launchpadFactory: '0xaebE61Fa1933c40076E402eC11de359a1F56F68A',
    launchpadFactoryAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'pad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'Deployed',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'string',
                name: 'name',
                type: 'string',
              },
              {
                internalType: 'string',
                name: 'symbol',
                type: 'string',
              },
            ],
            internalType: 'struct GenericToken.GenericTokenOptions',
            name: '_tokenOptions',
            type: 'tuple',
          },
        ],
        name: 'deploy',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'emitAddLiquidity',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'emitBought',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'emitRaised',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        name: 'setUniswapOptions',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
  },
  5: {
    launchpadAbi: '',
    launchpadFactory: '',
    launchpadFactoryAbi: '',
  },

  97: {
    launchpadFactory: '0x54f14a05847Ea3381c9E4C8581D12cc2e7848BD5',
    launchpadFactoryAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'pad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'Deployed',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'string',
                name: 'name',
                type: 'string',
              },
              {
                internalType: 'string',
                name: 'symbol',
                type: 'string',
              },
            ],
            internalType: 'struct GenericToken.GenericTokenOptions',
            name: '_tokenOptions',
            type: 'tuple',
          },
        ],
        name: 'deploy',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'emitAddLiquidity',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'emitBought',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'emitRaised',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        name: 'setUniswapOptions',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
    launchpadAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'bought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: 'merkleProof',
            type: 'bytes32[]',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
        ],
        name: 'claim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
        ],
        name: 'createPosition',
        outputs: [
          {
            internalType: 'uint128',
            name: 'liquidity',
            type: 'uint128',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'currentStage',
        outputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'options',
        outputs: [
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'factory',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'payableToken',
            type: 'address',
          },
          {
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'toSuccessAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalEndTimestamp',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'stages',
        outputs: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'toClaim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalBought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
  },
  56: {
    launchpadAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'bought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: 'merkleProof',
            type: 'bytes32[]',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'buy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
        ],
        name: 'claim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount0Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1Desired',
            type: 'uint256',
          },
          {
            internalType: 'uint160',
            name: 'sqrt',
            type: 'uint160',
          },
        ],
        name: 'createPosition',
        outputs: [
          {
            internalType: 'uint128',
            name: 'liquidity',
            type: 'uint128',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'currentStage',
        outputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'options',
        outputs: [
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'factory',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'payableToken',
            type: 'address',
          },
          {
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'toSuccessAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalEndTimestamp',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'stages',
        outputs: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'goal',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxPerWallet',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'whitelist',
            type: 'bool',
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'toClaim',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalBought',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
    launchpadFactory: '0xB7A24699f1Af5Fbd3dF0889F8eDdB6A2b0b46afF',
    launchpadFactoryAbi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'AddLiquidity',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Bought',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'pad',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bytes32',
            name: 'salt',
            type: 'bytes32',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'Deployed',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'launchpad',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            indexed: false,
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'Raised',
        type: 'event',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'payableToken',
                type: 'address',
              },
              {
                internalType: 'bytes32',
                name: 'salt',
                type: 'bytes32',
              },
              {
                internalType: 'uint256',
                name: 'toSuccessAmount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'totalEndTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct ILaunchPad.LaunchPadOptions',
            name: '_options',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage[]',
            name: '_stages',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'string',
                name: 'name',
                type: 'string',
              },
              {
                internalType: 'string',
                name: 'symbol',
                type: 'string',
              },
            ],
            internalType: 'struct GenericToken.GenericTokenOptions',
            name: '_tokenOptions',
            type: 'tuple',
          },
        ],
        name: 'deploy',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'emitAddLiquidity',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'emitBought',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'goal',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'minPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxPerWallet',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'start',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'end',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'whitelist',
                type: 'bool',
              },
              {
                internalType: 'bytes32',
                name: 'merkleRoot',
                type: 'bytes32',
              },
            ],
            internalType: 'struct ILaunchPad.Stage',
            name: 'stage',
            type: 'tuple',
          },
        ],
        name: 'emitRaised',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'factory',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'nonfungiblePositionManager',
                type: 'address',
              },
              {
                internalType: 'uint24',
                name: 'fee',
                type: 'uint24',
              },
            ],
            internalType: 'struct ILaunchPad.UniswapOptions',
            name: '_uniswapOptions',
            type: 'tuple',
          },
        ],
        name: 'setUniswapOptions',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
  },
};

export default NFT_MARKETPLACE;
