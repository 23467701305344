import {
  Button,
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  styled,
} from '@mui/material';
import {
  Color,
  Border,
  BorderRadius,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    background:
      'linear-gradient(180deg, rgba(44, 31, 73, 0.6) 0%, rgba(94, 39, 120, 0.6) 100%), #000000',
    boxShadow:
      '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 12px rgba(0, 0, 0, 0.86)',
    border: '1px solid',
    borderImage:
      'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%, #4132CF, #4132CF, #4132CF, #AE2CB1, #E15096)',
    borderImageSlice: 1,
    borderRadius: BorderRadius.SMALL,
    width: '412px!important',
    [theme.breakpoints.down('mobile')]: {
      width: '100%!important',
      margin: 0,
      border: 'none',
      borderRadius: 'inherit',
      background: Color.BACKGROUND_MAIN,
    },
  },
}));

export const StyledTitle = styled(DialogTitle)({
  color: Color.WHITE,
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  padding: 16,
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
});
export const StyledBackBtn = styled(Button)({
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE_PLUS,
  lineHeight: '150%',
  padding: 16,
  textTransform: 'none',
  justifyContent: 'flex-start',
});
export const StyledDetailsContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
});
export const StyledFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 12,
  background: Color.BACKGROUND,
  border: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
  borderRadius: BorderRadius.NORMAL,
  minWidth: '186px',
  boxSizing: 'border-box',
  rowGap: '8px',
});

export const StyledFieldName = styled('span')({
  fontWeight: '400',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.EXTRA_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  lineHeight: '150%',
});

export const StyledFieldValue = styled('span')({
  fontWeight: 400,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  display: 'flex',
  letterSpacing: '0.05em',
  lineHeight: '160%',
  textTransform: 'uppercase',
});
export const StyledInputeLabel = styled('p')({
  margin: '16px 0 8px',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
});
export const StyledContentContainer = styled('div')({
  padding: '0px 16px 16px 16px',
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
});
export const StyledInput = styled(TextField)({
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.SMALL,
  '& .MuiInputBase-root': {
    color: Color.WHITE,
    fontFamily: FontFamily.INTER,
    fontSize: TextSize.SMALL,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
  '& ::placeholder': {
    color: Color.WHITE_OPACITY_LIGHT,
  },
});

export const StyledInvestBtn = styled(Button)({
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  textTransform: 'none',
  lineHeight: '160%',
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  width: '100%',
  color: Color.WHITE,
  border: 'none',
});

export const StyledBtnContainer = styled('div')({
  padding: 16,
});

export const StyledContainer = styled('div')({
  margin: '12px 0',
  padding: '12px',
  background: Color.DARK_PURPLE_OPACITY,
  border: Border.ACCENT_BORDER,
  borderRadius: BorderRadius.NORMAL,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
});

export const StyledRowContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});
