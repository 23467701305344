import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Add, ArrowBack, Close, ErrorOutline } from '@mui/icons-material';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useAccount } from 'wagmi';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useLocation, useNavigate } from 'react-router-dom';

import { Border, BorderRadius, Color, TextSize } from 'helpers/themeStyles';
import { NftCollection } from 'types/nftCollection';
import { createNftOrNftCollection, fetchNFTCollections } from 'config/api';
import { PageRoutes } from 'config/routes';
import uploadImage from 'shared/upload-image.svg';
import {
  ButtonContainer,
  PropertiesBlock,
  PropertiesText,
  PropertiesTitle,
  selectMenuListStyle,
  selectPaperStyle,
  StyledAddButton,
  StyledCollectionIcon,
  StyledConfirmBtn,
  StyledContainer,
  StyledError,
  StyledFormControl,
  StyledFormFieldTitle,
  StyledFormSection,
  StyledHeaderContainer,
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledSmallText,
  StyledTitle,
  UploadArea,
  UploadAreaContainer,
  UploadImage,
  UploadText,
} from './styles';
import { fileTypes } from 'helpers/fileTypes';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import { WarningTextStyled, WarningWrap } from 'components/NFTBuyModal/styles';
import { networks } from 'helpers/networks';
import NFT_MARKETPLACE from 'config/contracts';
import { isChainSupproted } from 'helpers/isChainSupproted';

interface NFTProperties {
  trait_type: string;
  value: string;
}

interface FormValues {
  image: File | null;
  name: string;
  external_url: string;
  description: string;
  collection: string;
  nftAmount: string;
}

const CreateNft = () => {
  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [isOpenCollectionSelect, setIsOpenCollectionSelect] =
    useState<boolean>(false);
  const [propertiesFields, setNFTPropertiesFields] = useState<NFTProperties[]>(
    []
  );
  const [editPropertiesInterface, setEditPropertiesInterface] =
    useState<boolean>(false);
  const [nftCollections, setNftCollections] = useState<NftCollection[]>([]);
  const isDevelopmentMode = process.env.NODE_ENV === 'development';
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [charactersLeft, setCharactersLeft] = useState(25);
  const [descriptionCharactersLeft, setDescriptionCharactersLeft] =
    useState(350);
  const userId = localStorage.getItem('userId');
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [supplyError, setSupplyError] = useState(false);
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [nftChainId, setNftChainId] = useState(chain?.id);
  const [keyCounter, setKeyCounter] = useState(0);
  const [creatingError, setCreatingError] = useState(false);
  const { state } = useLocation();
  const ERC1155 =
    NFT_MARKETPLACE[nftChainId ? nftChainId : chain?.id!]?.contract;
  const createNFTItem = async (values: FormValues) => {
    const user = localStorage.getItem('userId');
    try {
      setCreatingError(false);
      setOpenWaitingPopup(true);
      const form = new FormData();
      form.append('name', values.name);
      form.append('slug', values.description);
      form.append('owner', user!);
      form.append('image', values.image!);
      form.append('image_banner', values.image!);
      form.append('primary_asset_contract', ERC1155);
      form.append('description', values.description);
      form.append('royalties_address', '');
      form.append('royalties_percent', '');
      form.append('chain_id', `${nftChainId}`);
      form.append('external_url', values.external_url);
      form.append('nftAmount', !values.nftAmount ? '1' : values.nftAmount);
      form.append('publicAddress', address?.toLowerCase()!);
      form.append('token_id', '0');
      // if no collection was created earlier - passing to BE empty collection ID
      form.append(
        'nft_collection',
        values.collection === 'default' || !values.collection
          ? ''
          : values.collection
      );
      if (propertiesFields.length > 0) {
        form.append('attributes', JSON.stringify(propertiesFields));
      } else {
        form.append('attributes', JSON.stringify([]));
      }

      await createNftOrNftCollection('nft/create-with-collection/', form);

      resetForm();
      setOpenWaitingPopup(false);
      navigate(PageRoutes.MyNfts);
    } catch (error) {
      console.log(error);
      setOpenWaitingPopup(false);
      setCreatingError(true);
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setSizeError(false);
    setTypeError(false);
    setSupplyError(false);
    if (imageUrl) {
      setImageUrl('');
    }
    setKeyCounter((prevCounter) => prevCounter + 1);
    setCharactersLeft(25);
    setDescriptionCharactersLeft(350);
    setNFTPropertiesFields([]);
  };

  const validateProperties = () => {
    const notEmptyPropertiesObj = propertiesFields.filter((propObj) =>
      Object.values(propObj).every((val) => val.trim() !== '')
    );
    setNFTPropertiesFields(notEmptyPropertiesObj);
    setEditPropertiesInterface(false);
  };

  const handleAddFields = () => {
    if (
      !propertiesFields.length ||
      (propertiesFields[propertiesFields.length - 1].trait_type &&
        propertiesFields[propertiesFields.length - 1].value)
    ) {
      const values = [...propertiesFields];
      values.push({ trait_type: '', value: '' });
      setNFTPropertiesFields(values);
      setEditPropertiesInterface(true);
    }
  };

  const handleRemoveFields = (index: number) => {
    const values = [...propertiesFields];
    values.splice(index, 1);
    const notEmptyPropertiesObj = values.filter((propObj) =>
      Object.values(propObj).every((val) => val.trim() !== '')
    );
    setNFTPropertiesFields(notEmptyPropertiesObj);
    if (!notEmptyPropertiesObj.length) {
      setEditPropertiesInterface(false);
    }
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...propertiesFields];
    values[index][event.target.name as keyof NFTProperties] =
      event.target.value;
    setNFTPropertiesFields(values);
  };

  const fetchNFTCollection = async () => {
    const { data } = await fetchNFTCollections(
      `/nft-collection/${userId}?notPaginated=true`
    );
    setNftCollections(data.docs);
  };

  useEffect(() => {
    fetchNFTCollection();
  }, []);

  const formik = useFormik<FormValues>({
    initialValues: {
      image: null,
      name: '',
      external_url: '',
      description: '',
      collection: state?.collectionId ? state.collectionId : '',
      nftAmount: '',
    },
    onSubmit: (values) => {
      createNFTItem(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const { collection } = formik.values;
    const selectedCollection = nftCollections.find((c) => c._id === collection);
    setNftChainId(selectedCollection?.chain_id ?? chain?.id);
  }, [formik.values.collection, chain?.id, nftCollections]);

  useMemo(() => {
    if (image) {
      setImageUrl(URL.createObjectURL(image));
    }
  }, [image]);

  const compressedFile = async (file: File) => {
    const imageBitmap = await createImageBitmap(file);
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx!.drawImage(imageBitmap, 0, 0);
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, file.type, 0.5)
    );
    const compressedFile = new File([blob as BlobPart], file.name, {
      type: (blob as File).type,
    });
    return compressedFile;
  };
  return (
    <>
      <div style={{ margin: isMobile ? '20px -24px' : '' }}>
        <StyledContainer>
          <StyledHeaderContainer>
            <Stack
              direction='row'
              gap='20px'
            >
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ color: Color.WHITE, p: 0 }}
              >
                <ArrowBack />
              </IconButton>
              <StyledTitle>{t('createNewItem')}</StyledTitle>
            </Stack>
            <StyledSmallText
              style={{
                color: Color.PINK,
                cursor: 'pointer',
                fontSize: TextSize.SMALL,
                fontWeight: 700,
              }}
              onClick={resetForm}
            >
              {t('clearAll')}
            </StyledSmallText>
          </StyledHeaderContainer>
          <form onSubmit={formik.handleSubmit}>
            <StyledFormFieldTitle
              sx={{ margin: '16px 16px 8px', fontSize: TextSize.MIDDLE }}
            >
              {t('nftImage')} <span style={{ color: Color.PINK }}>*</span>
            </StyledFormFieldTitle>
            <Stack
              direction='row'
              gap={1}
              mx={2}
            >
              <StyledSmallText sx={{ fontSize: TextSize.SMALL }}>
                {t('fileTypeSupported')}
              </StyledSmallText>
              <StyledSmallText
                sx={{
                  color: Color.TEXT_GRAY_SECONDARY,
                  fontSize: TextSize.SMALL,
                  fontWeight: '700',
                }}
              >
                <span
                  style={{
                    color: typeError ? Color.RED : Color.TEXT_GRAY_SECONDARY,
                  }}
                >
                  {t('fileTypes')}
                </span>{' '}
                <span
                  style={{
                    color: sizeError ? Color.RED : Color.TEXT_GRAY_SECONDARY,
                  }}
                >
                  {t('maxSize')}
                </span>
              </StyledSmallText>
            </Stack>
            <StyledFormSection>
              <UploadAreaContainer>
                <FileUploader
                  key={keyCounter}
                  handleChange={async (file: File) => {
                    setTypeError(false);
                    setSizeError(false);
                    if (!file.type.includes('webp' || 'gif')) {
                      const canvasFile = await compressedFile(file);
                      formik.setFieldValue('image', canvasFile);
                      setImage(canvasFile);
                    } else {
                      formik.setFieldValue('image', file);
                      setImage(file);
                    }
                  }}
                  name='file'
                  types={fileTypes}
                  onTypeError={() => {
                    setTypeError(true);
                  }}
                  onSizeError={() => {
                    setSizeError(true);
                  }}
                  maxSize={50}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      width={'100%'}
                      height={'100%'}
                      alt=''
                      style={{
                        borderRadius: BorderRadius.NORMAL,
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <UploadArea>
                      <UploadImage src={uploadImage} />
                      <UploadText>{t('dragYourNftHere')}</UploadText>
                    </UploadArea>
                  )}
                </FileUploader>
                {typeError && <StyledError>{t('fileTypeError')}</StyledError>}
                {sizeError && <StyledError>{t('fileSizeError')}</StyledError>}
              </UploadAreaContainer>
              <StyledInputContainer
                style={{ marginTop: isMobile ? '36px' : '' }}
              >
                <StyledFormFieldTitle>
                  {t('name')} <span style={{ color: Color.PINK }}>*</span>
                </StyledFormFieldTitle>
                <div style={{ width: '100%' }}>
                  <StyledInput
                    name='name'
                    value={formik.values.name}
                    onChange={(event) => {
                      const value = event.target.value;
                      const remaining = 25 - value.length;
                      if (remaining >= 0) {
                        formik.setFieldValue('name', value);
                        setCharactersLeft(remaining);
                      }
                    }}
                    placeholder='Item name'
                    size='small'
                    fullWidth
                  />
                  <StyledSmallText
                    sx={{
                      color: !charactersLeft ? Color.RED : Color.WHITE,
                      textAlign: 'right',
                    }}
                  >
                    {t('symbolsLeft', { count: charactersLeft })}
                  </StyledSmallText>
                </div>
              </StyledInputContainer>
              <StyledInputContainer>
                <StyledFormFieldTitle>{t('externalLink')}</StyledFormFieldTitle>
                <StyledSmallText>
                  {t('externalLinkDescription')}
                </StyledSmallText>
                <StyledInput
                  name='external_url'
                  value={formik.values.external_url}
                  onChange={formik.handleChange}
                  placeholder='https://yoursite.com/item/123'
                  size='small'
                />
              </StyledInputContainer>
              <StyledInputContainer sx={{ m: '16px 0 0' }}>
                <StyledFormFieldTitle>{t('description')}</StyledFormFieldTitle>
                <Stack
                  direction='row'
                  flexWrap='wrap'
                  gap={0.2}
                >
                  <StyledSmallText>
                    {t('descriptionOfCreateNft')}
                  </StyledSmallText>
                  {!descriptionCharactersLeft && (
                    <StyledSmallText sx={{ color: Color.RED }}>
                      {t('maxSizeDescription')}
                    </StyledSmallText>
                  )}
                </Stack>
                <div style={{ width: '100%' }}>
                  <StyledInput
                    name='description'
                    placeholder='Item description'
                    value={formik.values.description}
                    onChange={(event) => {
                      const value = event.target.value;
                      const remaining = 350 - value.length;
                      if (remaining >= 0) {
                        formik.setFieldValue('description', value);
                        setDescriptionCharactersLeft(remaining);
                      }
                    }}
                    size='small'
                    multiline
                    rows={6}
                    fullWidth
                  />
                  <StyledSmallText
                    sx={{
                      color: !descriptionCharactersLeft
                        ? Color.RED
                        : Color.WHITE,
                      textAlign: 'right',
                    }}
                  >
                    {t('symbolsLeft', { count: descriptionCharactersLeft })}
                  </StyledSmallText>
                </div>
              </StyledInputContainer>
            </StyledFormSection>
            <Stack
              m={2}
              gap={1}
            >
              <StyledFormFieldTitle sx={{ fontSize: TextSize.MIDDLE }}>
                {t('collection')}
              </StyledFormFieldTitle>
              <StyledFormControl sx={{ width: '100%' }}>
                {!isOpenCollectionSelect && !formik.values.collection && (
                  <StyledInputLabel id='collection-select'>
                    {t('selectCollection')}
                  </StyledInputLabel>
                )}
                <StyledSelect
                  name='collection'
                  labelId='collection-select'
                  id='collection-select'
                  placeholder='Select Collection'
                  onChange={formik.handleChange}
                  onOpen={() => setIsOpenCollectionSelect(true)}
                  onClose={() => setIsOpenCollectionSelect(false)}
                  value={formik.values.collection}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                >
                  <StyledMenuItem value={'default'}>
                    {t('defaultCollection')}
                  </StyledMenuItem>
                  {nftCollections.map((collection) => (
                    <StyledMenuItem
                      key={collection._id}
                      value={collection._id}
                    >
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='6px'
                      >
                        <StyledCollectionIcon
                          src={formatIpfsUrl(collection.image_url[1])}
                          alt={collection.name}
                        />
                        {collection.name}
                      </Stack>
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </StyledFormControl>
            </Stack>
            <PropertiesTitle>{t('properties')}</PropertiesTitle>
            <StyledFormSection
              sx={{
                padding: '16px',
                margin: '0 16px 16px',
                border: Border.ACCENT_BORDER,
              }}
            >
              {!editPropertiesInterface && (
                <PropertiesText
                  sx={{
                    alignItems:
                      propertiesFields.length > 0 ? 'flex-start' : 'center',
                  }}
                >
                  {propertiesFields.length > 0 ? (
                    <Stack
                      flexWrap='wrap'
                      direction='row'
                      gap={3}
                    >
                      {propertiesFields.map((inputField, index: number) => (
                        <PropertiesBlock key={`${index} - ${inputField}`}>
                          <StyledSmallText
                            sx={{
                              textTransform: 'uppercase',
                              fontSize: TextSize.SUPER_SMALL,
                            }}
                          >
                            {t('type')}
                          </StyledSmallText>
                          <StyledFormFieldTitle
                            sx={{
                              fontSize: TextSize.NORMAL,
                            }}
                          >{`${inputField.trait_type} ${inputField.value}`}</StyledFormFieldTitle>
                        </PropertiesBlock>
                      ))}
                    </Stack>
                  ) : (
                    <StyledSmallText>
                      {t('propertiesDescription')}
                    </StyledSmallText>
                  )}
                  <StyledAddButton onClick={handleAddFields}>
                    <Add sx={{ color: Color.WHITE }} />
                  </StyledAddButton>
                </PropertiesText>
              )}
              {editPropertiesInterface && (
                <>
                  <Stack
                    direction='column'
                    gap={2}
                  >
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='flex-start'
                      gap={2}
                    >
                      <StyledFormFieldTitle style={{ width: '43%' }}>
                        {t('type')}
                      </StyledFormFieldTitle>
                      <StyledFormFieldTitle style={{ width: '43%' }}>
                        {t('name')}
                      </StyledFormFieldTitle>
                    </Stack>
                    {propertiesFields.map((inputField, index: number) => (
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        gap={2}
                        key={`${inputField} - ${index}`}
                      >
                        <Stack width='43%'>
                          <StyledInput
                            type='text'
                            name='trait_type'
                            placeholder='Character'
                            value={inputField.trait_type}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            sx={{ '& .MuiInputBase-input': { p: '8px' } }}
                          />
                        </Stack>
                        <Stack width='43%'>
                          <StyledInput
                            type='text'
                            name='value'
                            placeholder='Male'
                            value={inputField.value}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            sx={{ '& .MuiInputBase-input': { p: '8px' } }}
                          />
                        </Stack>
                        <IconButton
                          type='button'
                          sx={{ p: 0, pl: '10px' }}
                          onClick={() => handleRemoveFields(index)}
                        >
                          <Close sx={{ color: Color.WHITE }} />
                        </IconButton>
                      </Stack>
                    ))}
                    <StyledAddButton
                      onClick={handleAddFields}
                      startIcon={<Add />}
                      sx={{ alignSelf: 'flex-start' }}
                    >
                      {t('addMore')}
                    </StyledAddButton>
                  </Stack>
                  <StyledConfirmBtn
                    fullWidth
                    onClick={validateProperties}
                  >
                    {t('save')}
                  </StyledConfirmBtn>
                </>
              )}
            </StyledFormSection>
            <StyledFormSection sx={{ p: '16px', border: Border.ACCENT_BORDER }}>
              <StyledFormFieldTitle mb={1}>{t('supply')}</StyledFormFieldTitle>
              <Stack
                direction='row'
                mb={2}
                gap={0.5}
              >
                <StyledSmallText>{t('supplyDescription')}</StyledSmallText>
                {supplyError && (
                  <StyledSmallText
                    sx={{
                      color: Color.RED,
                    }}
                  >
                    {t('maxItems')}
                  </StyledSmallText>
                )}
              </Stack>
              <StyledInput
                fullWidth
                name='supply'
                value={formik.values.nftAmount}
                onChange={(event) => {
                  setSupplyError(false);
                  let newValue = event.target.value.replace(/\D/g, '');
                  if (newValue.startsWith('0')) {
                    newValue = newValue.substring(1);
                  }
                  if (Number(newValue) > 10000) {
                    setSupplyError(true);
                  } else {
                    formik.setFieldValue('nftAmount', newValue);
                  }
                }}
                placeholder='1'
                size='small'
              />
              {supplyError && (
                <StyledSmallText
                  sx={{
                    textAlign: 'right',
                    color: Color.RED,
                  }}
                >
                  {t('invalidNumberOfItems')}
                </StyledSmallText>
              )}
            </StyledFormSection>
            <WarningWrap style={{ padding: '12px', margin: '0 16px 16px' }}>
              <ErrorOutline sx={{ color: 'red' }} />
              <WarningTextStyled style={{ lineHeight: '150%' }}>
                {t('youWillCreateNftOnNetwork', {
                  blockchainName: networks[Number(nftChainId)],
                })}
              </WarningTextStyled>
            </WarningWrap>
            {creatingError && (
              <WarningWrap style={{ padding: '12px', margin: '0 16px 16px' }}>
                <ErrorOutline sx={{ color: 'red' }} />
                <WarningTextStyled style={{ lineHeight: '150%' }}>
                  {t('sthWentWrongWhileCreatingTryAgainLater', {
                    item: 'NFT',
                  })}
                </WarningTextStyled>
              </WarningWrap>
            )}
            <ButtonContainer>
              <StyledConfirmBtn
                sx={{
                  flexGrow: isMobile ? 1 : 'inherit',
                  mt: 0,
                  p: '18px',
                  minWidth: '150px',
                  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
                }}
                type='submit'
                disabled={
                  !formik.values.image ||
                  !formik.values.name ||
                  !isChainSupproted(chain?.id!)
                }
              >
                {t('create')}
              </StyledConfirmBtn>
            </ButtonContainer>
          </form>
        </StyledContainer>
      </div>
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          fromNFT
          createText={t('itemIsCreating', { item: 'NFT' })}
        />
      )}
    </>
  );
};

export default CreateNft;
