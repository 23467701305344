import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Close } from '@mui/icons-material';
import { utils } from 'ethers';
import {
  DEXToken,
  PancakePair,
  PancakeProvider,
} from '@omisoftnet/game-dex-sdk';
import { useAccount } from 'wagmi';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { Color } from 'helpers/themeStyles';
import { setTransactionHash } from 'state/transactions/slice';
import { dexSelector } from 'state/dex/selectors';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import TransactionResultPopup from 'components/TransactionResultPopup';
import {
  PooledContainer,
  PooledTokenContainer,
  PooledToken,
} from 'components/RemoveLiquidityModal/styles';
import { deleteLiquidity } from 'config/api';
import { useSigner } from '@hooks/useSigner';
import {
  MainContent,
  StyledDialog,
  StyledHeadingContainer,
  StyledIconButton,
  StyledTitle,
  StyledConfirmButton,
  IconContainer,
  StyledText,
  StyledRowContainer,
} from './styles';

const RemoveLiquidityV2Submitter = ({
  firstTokenSymbol,
  secondTokenSymbol,
  isOpen,
  onClose,
  firstTokenIcon,
  secondTokenIcon,
  firstTokenValue,
  secondTokenValue,
  slippageTolerance,
  liquidity,
  firstTokenAddress,
  secondTokenAddress,
  firstTokenChainId,
  secondTokenChainId,
  firstTokenDecimals,
  secondTokenDecimals,
  firstTokenName,
  secondTokenName,
  liquidityId,
}: {
  firstTokenSymbol: string;
  secondTokenSymbol: string;
  isOpen: boolean;
  onClose: () => void;
  firstTokenIcon: string;
  secondTokenIcon: string;
  firstTokenValue: string;
  secondTokenValue: string;
  slippageTolerance?: number;
  liquidity: string;
  firstTokenAddress: string;
  secondTokenAddress: string;
  firstTokenChainId: number;
  secondTokenChainId: number;
  firstTokenDecimals: number;
  secondTokenDecimals: number;
  firstTokenName: string;
  secondTokenName: string;
  liquidityId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const signer = useSigner();
  const { address } = useAccount();
  const dex = useSelector(dexSelector);
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);
  const [liquidityBalance, setLiquidityBalance] = useState<string>();

  const parsedSlipage = Math.floor(
    Number.parseFloat(String(slippageTolerance)) * 100
  );

  async function fetchLiquidityBalance() {
    const token0 = DEXToken.fromAddress(
      firstTokenAddress,
      utils.parseUnits(String(firstTokenValue), firstTokenDecimals),
      firstTokenChainId,
      firstTokenDecimals,
      firstTokenSymbol,
      firstTokenName,
      firstTokenIcon
    );
    const token1 = DEXToken.fromAddress(
      secondTokenAddress,
      utils.parseUnits(String(secondTokenValue), secondTokenDecimals),
      secondTokenChainId,
      secondTokenDecimals,
      secondTokenSymbol,
      secondTokenName,
      secondTokenIcon
    );
    if (!dex) return;
    const provider = await dex.getDEXProvider(
      firstTokenChainId,
      token0,
      token1
    );
    const factoryAddress = await provider.factory();
    const pair = await PancakePair.from(
      token0,
      token1,
      signer!,
      factoryAddress
    );
    const balance = await pair.balanceOf(address!);
    setLiquidityBalance(utils.formatUnits(balance, 18).slice(0, 12));
  }

  async function removeLiquidity() {
    if (!dex) return;
    const token0 = DEXToken.fromAddress(
      firstTokenAddress,
      utils.parseUnits(
        String(firstTokenValue).slice(0, firstTokenDecimals),
        firstTokenDecimals
      ),
      firstTokenChainId,
      firstTokenDecimals,
      firstTokenSymbol,
      firstTokenName,
      firstTokenIcon
    );
    const token1 = DEXToken.fromAddress(
      secondTokenAddress,
      utils.parseUnits(
        String(secondTokenValue).slice(0, secondTokenDecimals),
        secondTokenDecimals
      ),
      secondTokenChainId,
      secondTokenDecimals,
      secondTokenSymbol,
      secondTokenName,
      secondTokenIcon
    );
    const provider = await dex.getDEXProvider(
      firstTokenChainId,
      token0,
      token1
    );
    if (provider instanceof PancakeProvider) {
      const factoryAddress = await provider.factory();
      const pair = await PancakePair.from(
        token0,
        token1,
        signer!,
        factoryAddress
      );
      try {
        const [firstToken, secondToken] = token0.sortsBefore(token1)
          ? [token0, token1]
          : [token1, token0]; // does safety checks
        const balance = await pair.balanceOf(address!);
        const tx = await provider.removeLiquidity(
          0,
          balance,
          { slipage: parsedSlipage },
          firstToken,
          secondToken
        );
        dispatch(
          setTransactionHash({
            hash: tx.hash,
            from: tx.from,
            to: tx.to,
            owner: address!,
            chainId: firstTokenChainId,
            transaction_type: 'removeLiquidity',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: firstTokenIcon,
                },
                token1: {
                  ...token1,
                  logoURI: secondTokenIcon,
                },
                token0Amount: firstTokenValue,
                token1Amount: secondTokenValue,
              },
            },
          })
        );
        setOpenWaitingPopup(false);
        setTransactionSubmittedStatus(true);
        const deleteLiquidityResponse = await deleteLiquidity(
          `/liquidity/delete/${liquidityId}`
        );
      } catch (error) {
        console.error(error); // for debug
        setOpenWaitingPopup(false);
        setTransactionRejectedStatus(true);
      }
    }
  }

  useEffect(() => {
    fetchLiquidityBalance();
  }, []);

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => onClose()}
      >
        <StyledHeadingContainer>
          <StyledTitle>{t('youWillReceive')}</StyledTitle>
          <StyledIconButton onClick={() => onClose()}>
            <Close />
          </StyledIconButton>
        </StyledHeadingContainer>
        <MainContent>
          <PooledContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {firstTokenValue?.slice(0, 18)}
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                <img
                  src={firstTokenIcon}
                  width='24'
                  height='24'
                  alt={firstTokenSymbol}
                />
                {firstTokenSymbol}
              </PooledToken>
            </PooledTokenContainer>
            <IconContainer>
              <Add sx={{ color: Color.TEXT_GRAY_SECONDARY }} />
            </IconContainer>
            <PooledTokenContainer>
              <PooledToken style={{ fontWeight: '600' }}>
                {secondTokenValue?.slice(0, 18)}
              </PooledToken>
              <PooledToken
                style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '6px',
                }}
              >
                <img
                  src={secondTokenIcon}
                  width='24'
                  height='24'
                  alt={secondTokenSymbol}
                />
                {secondTokenSymbol}
              </PooledToken>
            </PooledTokenContainer>
          </PooledContainer>
          <StyledText style={{ margin: '32px 0' }}>
            {t('outputIsEstimated', { slippageTolerance: slippageTolerance })}
          </StyledText>
          <StyledRowContainer>
            <StyledText>
              {firstTokenSymbol}/{secondTokenSymbol} {t('burned')}
            </StyledText>
            <StyledRowContainer>
              <div>
                <img
                  src={firstTokenIcon}
                  width='24'
                  height='24'
                  alt={firstTokenSymbol}
                />
                <img
                  src={secondTokenIcon}
                  width='24'
                  height='24'
                  alt={secondTokenSymbol}
                  style={{ marginLeft: '-5px' }}
                />
              </div>
              <StyledText>{liquidityBalance}</StyledText>
            </StyledRowContainer>
          </StyledRowContainer>
          <StyledConfirmButton
            onClick={() => {
              onClose();
              setOpenWaitingPopup(true);
              removeLiquidity();
            }}
          >
            {t('confirm')}
          </StyledConfirmButton>
        </MainContent>
      </StyledDialog>
      {openWaitingPopup && (
        <LiquidityWaitingPopup
          action={t('removing')}
          firstTokenSymbol={firstTokenSymbol}
          secondTokenSymbol={secondTokenSymbol}
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          firstTokenValue={firstTokenValue}
          secondTokenValue={secondTokenValue}
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          navigateToPoolList
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
};

export default RemoveLiquidityV2Submitter;
