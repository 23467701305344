import { useTranslation } from 'react-i18next';
import { IconButton, Modal, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';

import {
  ContentWrap,
  StyledButton,
  StyledModalContent,
  SubtitleStyled,
  TextContent,
  TitleStyled,
} from './styles';
import { Color } from 'helpers/themeStyles';
import WarningIcon from 'svg/WarningIcon';

type EfficiencyComparisonModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  setIsFullRange: () => void;
};

export default function EfficiencyComparisonModal(
  props: EfficiencyComparisonModalProps
) {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <StyledModalContent>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <IconButton
            sx={{ p: '16px', color: Color.WHITE }}
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <ContentWrap>
          <WarningIcon />
          <TextContent>
            <TitleStyled>{t('efficiencyComparison')}</TitleStyled>
            <SubtitleStyled>{t('fullRangeWarningText')}</SubtitleStyled>
          </TextContent>
        </ContentWrap>
        <StyledButton
          onClick={() => {
            props.setIsOpen(false);
            props.setIsFullRange();
          }}
        >
          {t('iUnderstand')}
        </StyledButton>
      </StyledModalContent>
    </Modal>
  );
}
