import { RefObject } from 'react';

export const scrollToRef = (
  ref: RefObject<HTMLDivElement>,
  valueToScroll: number
) => {
  if (ref.current) {
    const yOffset = valueToScroll;
    const yAxisScroll =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: yAxisScroll, behavior: 'smooth' });
  }
};
