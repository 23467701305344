import React from 'react';

const NFTMarketplaceIconActive: React.FC = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_602_1026)'>
        <rect
          x='8'
          y='6'
          width='32'
          height='32'
          rx='6'
          fill='#B032D6'
          shapeRendering='crispEdges'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.3425 12.085L32.5926 16.4308C32.8427 16.5626 33 16.8268 33 17.1151L32.9998 26.8849C32.9998 27.1734 32.8427 27.4374 32.5926 27.5692L24.3425 31.9149C24.1276 32.0283 23.8723 32.0283 23.6573 31.9149L15.4072 27.5692C15.1573 27.4374 15 27.1734 15 26.8849V17.1151C15 16.8266 15.1571 16.5626 15.4072 16.4308L23.6573 12.085C23.8722 11.9717 24.1275 11.9717 24.3425 12.085ZM28.4999 20.4616C29.2102 20.4616 30.4196 21.3225 31.4999 22.2289L31.5001 17.5843L24 13.6339L16.4999 17.5843L16.4997 23.9425C17.5837 23.0018 18.6893 22.181 19.3115 22.0269C19.326 22.0244 19.3406 22.0216 19.3551 22.0189C19.4052 22.0096 19.4552 22.0002 19.4999 22.0002C20.27 22.0002 22.6423 23.5332 24.3986 24.953C24.6427 25.1499 24.9964 25.1082 25.1886 24.8568C25.3809 24.6069 25.3391 24.244 25.0951 24.047C24.9896 23.9617 24.6639 23.7015 24.2159 23.3658C25.4106 22.2445 27.4708 20.4616 28.4999 20.4616ZM22.8746 20.4614C23.9101 20.4614 24.7496 19.6003 24.7496 18.5383C24.7496 17.4762 23.9101 16.6152 22.8746 16.6152C21.8391 16.6152 20.9996 17.4762 20.9996 18.5383C20.9996 19.6003 21.8391 20.4614 22.8746 20.4614Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_602_1026'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.411904 0 0 0 0 0.130625 0 0 0 0 0.870833 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_602_1026'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_602_1026'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default NFTMarketplaceIconActive;
