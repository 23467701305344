import React from 'react';

const LockIcon: React.FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 22C5.08333 22 4.72917 21.8542 4.4375 21.5625C4.14583 21.2708 4 20.9167 4 20.5V9.65C4 9.23333 4.14583 8.87917 4.4375 8.5875C4.72917 8.29583 5.08333 8.15 5.5 8.15H7.25V5.75C7.25 4.43333 7.7125 3.3125 8.6375 2.3875C9.5625 1.4625 10.6833 1 12 1C13.3167 1 14.4375 1.4625 15.3625 2.3875C16.2875 3.3125 16.75 4.43333 16.75 5.75V8.15H18.5C18.9167 8.15 19.2708 8.29583 19.5625 8.5875C19.8542 8.87917 20 9.23333 20 9.65V20.5C20 20.9167 19.8542 21.2708 19.5625 21.5625C19.2708 21.8542 18.9167 22 18.5 22H5.5ZM5.5 20.5H18.5V9.65H5.5V20.5ZM12 17C12.5333 17 12.9875 16.8167 13.3625 16.45C13.7375 16.0833 13.925 15.6417 13.925 15.125C13.925 14.625 13.7375 14.1708 13.3625 13.7625C12.9875 13.3542 12.5333 13.15 12 13.15C11.4667 13.15 11.0125 13.3542 10.6375 13.7625C10.2625 14.1708 10.075 14.625 10.075 15.125C10.075 15.6417 10.2625 16.0833 10.6375 16.45C11.0125 16.8167 11.4667 17 12 17ZM8.75 8.15H15.25V5.75C15.25 4.85 14.9333 4.08333 14.3 3.45C13.6667 2.81667 12.9 2.5 12 2.5C11.1 2.5 10.3333 2.81667 9.7 3.45C9.06667 4.08333 8.75 4.85 8.75 5.75V8.15ZM5.5 20.5V9.65V20.5Z'
        fill='white'
      />
    </svg>
  );
};

export default LockIcon;
