import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { BorderRadius, Color } from 'helpers/themeStyles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement='left'
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Color.BACKGROUND,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.BACKGROUND,
    borderRadius: BorderRadius.SEMI_NORMAL,
  },
}));
