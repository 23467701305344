import { Tab, TabProps, useMediaQuery, useTheme } from '@mui/material';
import { Color, FontFamily, TextSize } from 'helpers/themeStyles';
import { LinkProps } from 'react-router-dom';

interface CustomTabProps extends TabProps {
  to: string;
  label: string;
  component: React.ComponentType<LinkProps>;
}

export const CustomNftTab = ({
  label,
  to,
  component: Component,
  ...rest
}: CustomTabProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <Tab
      style={{ padding: isMobile ? 0 : '' }}
      //@ts-ignore
      component={(props) => (
        <Component
          to={to}
          {...props}
        />
      )}
      label={label}
      {...rest}
      sx={{
        fontFamily: FontFamily.INTER,
        textTransform: 'none',
        color: Color.TEXT_GRAY_SECONDARY,
        fontWeight: 700,
        fontSize: isMobile ? TextSize.MIDDLE : TextSize.MIDDLE_PLUS,

        '&.Mui-selected': {
          backgroundColor: 'transparent !important',
          color: Color.WHITE,
        },
      }}
    />
  );
};
