import { Dialog, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { Color, TextSize, FontFamily } from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    padding: 20,
    background: Color.DARK_PURPLE_BACKGROUND,
  },
});

export const StyledTitle = styled(DialogTitle)({
  fontSize: TextSize.NORMAL,
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
});
