import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Box, Pagination, Stack, useMediaQuery } from '@mui/material';
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';

import { PageRoutes } from 'config/routes';
import { fetchNFTCollections } from 'config/api';
import NftCollectionListItem from 'components/NftCollectionsList/NftCollectionListItem';
import { CustomPaginationItem } from 'components/NftCollectionsList/styles';
import { StyledConnectWalletBtn } from 'components/LiquidityPositions/styles';
import NftCard from 'components/NftCard';
import ConnectWalletIcon from 'svg/ConnectWalletIcon';
import NoNftsIcon from 'svg/NoNftsIcon';
import { NftCollection } from 'types/nftCollection';
import { setIsOpen } from 'state/walletconnect/slice';
import {
  ItemsContainer,
  StyledContainer,
  StyledLink,
  StyledRowContainer,
  StyledText,
  StyledTitle,
} from './styles';
import theme from 'helpers/themeStyles';

const NftMyCollectionsAndNFTs = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const [myCollections, setMyCollections] = useState<NftCollection[]>([]);
  const [myCollectionsTotalPages, setMyCollectionsTotalPages] =
    useState<number>();
  const [myCollectionsPage, setMyCollectionsPage] = useState<number>(1);
  const [myNfts, setMyNfts] = useState([]);
  const [myNftsTotalPages, setMyNftsTotalPages] = useState<number>();
  const [myNftsPage, setMyNftsPage] = useState<number>(1);
  const [nftsLimit, setNftsLimit] = useState(3);
  const userId = localStorage.getItem('userId');
  const dispatch = useDispatch();
  const is1550 = useMediaQuery(theme.breakpoints.between('mobile', 1550));

  type SectionRefType = HTMLDivElement & {
    scrollIntoView: (options?: ScrollIntoViewOptions) => void;
  };

  const targetSectionRef = useRef<SectionRefType>(null);

  const handleCollectionChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setMyCollectionsPage(value);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleNftChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setMyNftsPage(value);
    targetSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchCollections = async () => {
    const { data } = await fetchNFTCollections(`/nft-collection/${userId}`, {
      limit: 4,
      page: myCollectionsPage,
    });
    setMyCollectionsTotalPages(data.totalPages);
    setMyCollections(data.docs);
  };

  const fetchNFTs = async () => {
    const { data } = await fetchNFTCollections(
      `nft/by-address/${address?.toLowerCase()}`,
      {
        limit: nftsLimit,
        page: myNftsPage,
      }
    );
    setMyNfts(data.docs);
    setMyNftsTotalPages(data.totalPages);
  };
  useEffect(() => {
    if (is1550) {
      setNftsLimit(2);
    } else {
      setNftsLimit(3);
    }
  }, [is1550]);
  useEffect(() => {
    fetchCollections();
  }, [myCollectionsPage]);

  useEffect(() => {
    fetchNFTs();
  }, [myNftsPage, nftsLimit]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {!address ? (
        <StyledContainer mt={4}>
          <ConnectWalletIcon />
          <StyledText>{t('pleaseConnectWallet')}</StyledText>
          <StyledConnectWalletBtn
            variant='contained'
            onClick={() => dispatch(setIsOpen(true))}
          >
            {t('connectWallet')}
          </StyledConnectWalletBtn>
        </StyledContainer>
      ) : (
        <Box py={4}>
          <StyledRowContainer>
            <StyledTitle>{t('myCollections')}</StyledTitle>
            {!!myCollections.length && (
              <StyledLink to={PageRoutes.CreateNftCollection}>
                {t('createCollection')}
              </StyledLink>
            )}
          </StyledRowContainer>
          {!myCollections.length && (
            <StyledContainer>
              <NoNftsIcon />
              <StyledText>{t('youDontHaveCreatedCollections')}</StyledText>
              <StyledLink to={PageRoutes.CreateNftCollection}>
                {t('createCollection')}
              </StyledLink>
            </StyledContainer>
          )}
          <ItemsContainer>
            {!!myCollections.length &&
              myCollections.map((collection: NftCollection) => (
                <NftCollectionListItem
                  key={collection._id}
                  nft={collection}
                />
              ))}
          </ItemsContainer>
          {myCollectionsTotalPages! > 1 && (
            <Stack>
              <Pagination
                count={myCollectionsTotalPages}
                page={myCollectionsPage}
                onChange={handleCollectionChange}
                renderItem={(item) => <CustomPaginationItem {...item} />}
                sx={{ margin: '0 auto' }}
              />
            </Stack>
          )}
          <StyledRowContainer
            ref={targetSectionRef}
            sx={{ mt: '40px' }}
          >
            <StyledTitle>{t('myNFTs')}</StyledTitle>
            {!!myNfts.length && (
              <StyledLink
                to={PageRoutes.CreateNft}
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                }
              >
                {t('createNft')}
              </StyledLink>
            )}
          </StyledRowContainer>
          {!myNfts.length && (
            <StyledContainer>
              <NoNftsIcon />
              <StyledText>{t('youDontHaveCreatedNFTs')}</StyledText>
              <StyledLink to={PageRoutes.CreateNft}>
                {t('createNft')}
              </StyledLink>
            </StyledContainer>
          )}
          <ItemsContainer>
            {!!myNfts.length &&
              myNfts.map(
                ({
                  _id,
                  image,
                  name,
                  nft_collection,
                  chain_id,
                }: {
                  _id: string;
                  image: string;
                  name: string;
                  nft_collection: { _id: string; name: string };
                  chain_id: number;
                }) => (
                  <NftCard
                    id={_id}
                    key={_id}
                    image={image}
                    collection={nft_collection.name}
                    name={name}
                    myNft={true}
                    chainId={chain_id}
                  />
                )
              )}
          </ItemsContainer>
          {myNftsTotalPages! > 1 && (
            <Stack>
              <Pagination
                count={myNftsTotalPages}
                page={myNftsPage}
                onChange={handleNftChange}
                renderItem={(item) => <CustomPaginationItem {...item} />}
                sx={{ margin: '0 auto 40px' }}
              />
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};

export default NftMyCollectionsAndNFTs;
