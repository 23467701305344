import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Color, FontFamily } from 'helpers/themeStyles';
import {
  StyledContainer,
  StyledDialog,
  StyledBtn,
  StyledTitle,
  StyledBtnContainer,
  StyledHeadingContainer,
  StyledText,
} from './styles';
import { StyledBackButton } from '../CreateProject/styles';
import AlertRoundedIcon from 'svg/AlertRoundedIcon';

const ClearFormPopup = ({
  isOpen,
  onClose,
  goBack,
}: {
  isOpen: boolean;
  onClose: () => void;
  goBack?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledContainer>
        <StyledHeadingContainer>
          <StyledTitle>{t('confirmation')}</StyledTitle>
          <IconButton
            onClick={onClose}
            aria-label='close'
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <Close sx={{ color: Color.WHITE }} />
          </IconButton>
        </StyledHeadingContainer>
        <AlertRoundedIcon />
        <StyledTitle style={{ marginTop: '20px' }}>
          {t('goBackAndCleareForm')}
        </StyledTitle>
        <StyledText>{t('allDataWillBeLost')}</StyledText>
        <StyledBtnContainer>
          <StyledBackButton
            onClick={onClose}
            style={{
              fontFamily: FontFamily.INTER,
              textTransform: 'none',
              flex: '0.5',
            }}
          >
            {t('close')}
          </StyledBackButton>
          <StyledBtn
            onClick={() => {
              goBack && goBack();
              onClose();
            }}
          >
            {t('leavePage')}
          </StyledBtn>
        </StyledBtnContainer>
      </StyledContainer>
    </StyledDialog>
  );
};
export default ClearFormPopup;
