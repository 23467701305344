import React from 'react';

const MediumIcon = () => {
  return (
    <svg
      width='24'
      height='14'
      viewBox='0 0 24 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 0C6.08075 -1.93718e-08 5.1705 0.18106 4.32122 0.532843C3.47194 0.884626 2.70026 1.40024 2.05025 2.05025C1.40024 2.70026 0.884626 3.47194 0.532843 4.32122C0.18106 5.17049 0 6.08075 0 7C0 7.91925 0.18106 8.8295 0.532843 9.67878C0.884626 10.5281 1.40024 11.2997 2.05025 11.9497C2.70026 12.5998 3.47194 13.1154 4.32122 13.4672C5.1705 13.8189 6.08075 14 7 14C7.91925 14 8.8295 13.8189 9.67878 13.4672C10.5281 13.1154 11.2997 12.5998 11.9497 11.9497C12.5998 11.2997 13.1154 10.5281 13.4672 9.67878C13.8189 8.8295 14 7.91925 14 7C14 6.08075 13.8189 5.17049 13.4672 4.32122C13.1154 3.47194 12.5998 2.70026 11.9497 2.05025C11.2997 1.40024 10.5281 0.884626 9.67878 0.532843C8.8295 0.18106 7.91925 -1.93718e-08 7 0ZM18 0.5C17.2044 0.5 16.4413 1.18482 15.8787 2.40381C15.3161 3.62279 15 5.27609 15 7C15 8.72391 15.3161 10.3772 15.8787 11.5962C16.4413 12.8152 17.2044 13.5 18 13.5C18.7956 13.5 19.5587 12.8152 20.1213 11.5962C20.6839 10.3772 21 8.72391 21 7C21 5.27609 20.6839 3.62279 20.1213 2.40381C19.5587 1.18482 18.7956 0.5 18 0.5ZM23 2C22.7348 2 22.4804 2.52678 22.2929 3.46447C22.1054 4.40215 22 5.67392 22 7C22 8.32608 22.1054 9.59785 22.2929 10.5355C22.4804 11.4732 22.7348 12 23 12C23.2652 12 23.5196 11.4732 23.7071 10.5355C23.8946 9.59785 24 8.32608 24 7C24 5.67392 23.8946 4.40215 23.7071 3.46447C23.5196 2.52678 23.2652 2 23 2Z'
        fill='white'
      />
    </svg>
  );
};

export default MediumIcon;
