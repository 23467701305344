import { ethers, utils } from '@omisoftnet/game-dex-sdk';
import { launchpadContracts } from 'config/contracts';
import { stableTokens } from 'helpers/nativeTokens';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transactionHashSelector } from 'state/transactions/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import { ProjectDetails } from 'types/project';
import { useSigner } from './useSigner';
import { useWaitForTransactionReceipt } from 'wagmi';
export const useDeploy = (
  project: ProjectDetails,
  setTransactionSubmittedStatus: Dispatch<SetStateAction<boolean>>,
  setTransactionRejectedStatus: Dispatch<SetStateAction<boolean>>,
  setOpenWaitingPopup: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch = useDispatch();
  const signer = useSigner();
  const transactionHash = useSelector(transactionHashSelector);
  const { isLoading, isSuccess } = useWaitForTransactionReceipt({
    hash: transactionHash as `0x${string}`,
  });
  useEffect(() => {
    if (isSuccess) {
      setOpenWaitingPopup(false);
    }
  }, [isSuccess]);
  const deploy = async () => {
    setOpenWaitingPopup(true);
    const contr = new ethers.Contract(
      launchpadContracts[project.poolInformation.chain].launchpadFactory,
      launchpadContracts[project.poolInformation.chain].launchpadFactoryAbi,
      signer
    );
    try {
      const tx = await contr?.functions.deploy(
        {
          token: '0x0000000000000000000000000000000000000000', // address of your token
          factory:
            launchpadContracts[project.poolInformation.chain].launchpadFactory, // launchpad factory
          payableToken: stableTokens[project.poolInformation.chain].address, // payableToken - which token users can invest - BUSD
          salt: utils.hexZeroPad(utils.toUtf8Bytes(project?._id!), 32),
          toSuccessAmount: project.poolInformation.amountFlexible
            ? utils.parseUnits(
                `${
                  (project.poolInformation.lowestPercentageOfFundraisingGoal /
                    100) *
                  Number(project?.poolInformation.toRise)
                }`,
                stableTokens[project.poolInformation.chain].decimals
              )
            : utils.parseUnits(
                `${project?.poolInformation.toRise}`,
                stableTokens[project.poolInformation.chain].decimals
              ),
          totalEndTimestamp: Math.floor(
            Date.parse(project?.poolInformation.tokenDistributionDate!) / 1000
          ),
        },
        [
          {
            price: utils.parseUnits(
              `${project?.tokenSaleDetails.price}`,
              stableTokens[project.poolInformation.chain].decimals
            ),
            goal: utils.parseUnits(
              `${project?.poolInformation.toRise}`,
              stableTokens[project.poolInformation.chain].decimals
            ),
            minPerWallet: utils.parseUnits(
              `${project?.tokenSaleDetails.minAllocation}`,
              stableTokens[project.poolInformation.chain].decimals
            ),
            maxPerWallet: utils.parseUnits(
              `${project?.tokenSaleDetails.maxAllocation}`,
              stableTokens[project.poolInformation.chain].decimals
            ),
            start: Math.floor(
              Date.parse(project?.poolInformation.launchDate!) / 1000
            ),
            end: Math.floor(
              Date.parse(project?.poolInformation.tokenDistributionDate!) / 1000
            ), // '2023-05-05T12:00:00Z'
            whitelist: false,
            merkleRoot: utils.hexZeroPad('0x05', 32),
          },
        ],
        {
          name: project?.tokenSaleDetails.tokenName,
          symbol: project?.tokenSaleDetails.tokenSymbol,
        }
      );
      dispatch(setTransactionHash({ hash: tx.hash }));
      setTransactionSubmittedStatus(true);
    } catch (err) {
      console.log(err);
      setTransactionRejectedStatus(true);
      setOpenWaitingPopup(false);
    }
  };
  return { deploy, isLoading };
};
