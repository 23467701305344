import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { utils } from 'ethers';

import NftCollectionItems from 'components/NftCollectionItems';
import { NftCollection } from 'types/nftCollection';
import { Nft } from 'types/nft';
import { fetchData, fetchNFTCollections } from 'config/api';
import { PageRoutes } from 'config/routes';
import { formatIpfsUrl } from 'helpers/formatIpfsUrl';
import Loader from 'shared/Loader';
import {
  BannerContainer,
  DetailsTitle,
  StyledAvatar,
  StyledButton,
  StyledContainer,
  StyledNoImgContainer,
  StyledSeeMoreOrLessButton,
  StyledStack,
  StyledSubtitle,
  StyledTitle,
} from './styles';
import { stableTokens } from 'helpers/nativeTokens';
import { useSwitchChain } from 'wagmi';

const NftCollectionDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chains } = useSwitchChain();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const is1500 = useMediaQuery(theme.breakpoints.between('mobile', 1500));
  const [collection, setCollection] = useState<NftCollection>();
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [totalNftItems, setTotalNftItems] = useState<number>();
  const [nftsTotalPages, setNftsTotalPages] = useState<number>();
  const [nftsPage, setNftsPage] = useState<number>(1);
  const [fullDescription, setFullDescription] = useState<boolean>(false);
  const [listedNFTs, setListedNFTs] = useState<number>();
  const [lowestPrice, setLowestPrice] = useState<string>('');
  const [imgError, setImgError] = useState(false);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setNftsPage(value);
  };
  useEffect(() => {
    fetchData(`nft-collection/one/${id}`).then(({ data }) => {
      setCollection(data?.nftCollection);
      if (!data.lowestPrice) {
        return setLowestPrice('');
      }
      setLowestPrice(data?.lowestPrice);
    });
    fetchData(`nft-collection/listed-nft/${collection?._id}`).then(
      ({ data }) => {
        if (typeof data === 'string') {
          return setListedNFTs(0);
        }
        setListedNFTs(data);
      }
    );
  }, [collection?._id]);
  useEffect(() => {
    fetchNFTCollections(`nft-collection/nft/${collection?._id}`, {
      limit: 9,
      page: nftsPage,
      // price: sortBy ? sortBy : undefined,
    }).then(({ data }) => {
      setNfts(data.docs);
      if (data.metadata.length) {
        setTotalNftItems(data.metadata[0].totalDocs);
        setNftsTotalPages(data.metadata[0].totalPages);
      }
    });
  }, [nftsPage, collection?._id]);
  return (
    <>
      <Box
        mt={2}
        mb={6}
      >
        {collection?.image_banner_url && collection?.image_url && (
          <img
            src={
              collection?.image_banner_url
                ? formatIpfsUrl(collection?.image_banner_url[0])
                : formatIpfsUrl(collection?.image_url[0])
            }
            alt=''
            onError={() => setImgError(true)}
            style={{ display: 'none' }}
          />
        )}
        <StyledContainer>
          <StyledButton
            startIcon={<ArrowBack />}
            onClick={() => navigate(PageRoutes.NftCollections)}
          >
            {t('allCollections')}
          </StyledButton>
        </StyledContainer>
        {collection?.image_banner_url && collection?.image_url && !imgError ? (
          <BannerContainer
            style={{
              background: `url(${
                collection?.image_banner_url
                  ? formatIpfsUrl(collection?.image_banner_url[0])
                  : formatIpfsUrl(collection?.image_url[0])
              }) no-repeat center center/cover`,
            }}
          >
            <StyledAvatar src={formatIpfsUrl(collection?.image_url[1])} />
          </BannerContainer>
        ) : (
          <StyledNoImgContainer sx={{ marginBottom: '48px' }}>
            <Loader />
            <DetailsTitle mt={4}>{t('imageIsLoadingNow')}</DetailsTitle>
          </StyledNoImgContainer>
        )}
        <StyledTitle>{collection?.name}</StyledTitle>
        <Stack
          direction={isMobile || is1500 ? 'column' : 'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack width={isMobile || is1500 ? '100%' : '50%'}>
            {collection?.description ? (
              <Collapse
                in={fullDescription}
                collapsedSize={isMobile ? 230 : is1500 ? 120 : 150}
              >
                <StyledSubtitle>
                  {fullDescription
                    ? collection?.description
                    : collection?.description.slice(0, 230)}
                  {collection?.description.length > 230 && (
                    <StyledSeeMoreOrLessButton
                      onClick={() => setFullDescription(!fullDescription)}
                    >
                      {fullDescription ? t('seeLess') : t('seeMore')}
                    </StyledSeeMoreOrLessButton>
                  )}
                </StyledSubtitle>
              </Collapse>
            ) : (
              <DetailsTitle>{t('noDescription')}</DetailsTitle>
            )}
          </Stack>
          <Stack width={isMobile || is1500 ? '100%' : '50%'}>
            <StyledStack>
              <Stack
                alignItems='center'
                gap='12px'
              >
                <DetailsTitle style={{ textAlign: 'center' }}>
                  {t('items')}
                </DetailsTitle>
                <DetailsTitle>{totalNftItems ? totalNftItems : 0}</DetailsTitle>
              </Stack>
              <Stack
                alignItems='center'
                gap='12px'
                justifyContent={'space-between'}
              >
                <DetailsTitle style={{ textAlign: 'center' }}>
                  {t('itemsListed')}
                </DetailsTitle>
                <DetailsTitle>{listedNFTs}</DetailsTitle>
              </Stack>
              <Stack
                alignItems='center'
                gap='12px'
              >
                <DetailsTitle style={{ textAlign: 'center' }}>
                  {t('lowest')} ({'USD'})
                </DetailsTitle>
                <DetailsTitle>{lowestPrice ? lowestPrice : '-'}</DetailsTitle>
              </Stack>
              <Stack
                alignItems='center'
                gap='12px'
              >
                <DetailsTitle style={{ textAlign: 'center' }}>
                  {t('vol')} ({'USD'})
                </DetailsTitle>
                <DetailsTitle>
                  {collection?.volume
                    ? utils.formatUnits(
                        `${collection.volume}`,
                        stableTokens[collection.chain_id].decimals
                      )
                    : '-'}
                </DetailsTitle>
              </Stack>
            </StyledStack>
          </Stack>
        </Stack>
      </Box>
      <NftCollectionItems
        nfts={nfts}
        totalNfts={totalNftItems!}
        totalPages={nftsTotalPages!}
        page={nftsPage}
        handlePageChange={handlePageChange}
        collection={collection}
      />
    </>
  );
};

export default NftCollectionDetails;
