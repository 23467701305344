import React from 'react';

const WarningIcon = () => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22.5235L2 12.5235L12 2.52344L22.0001 12.5235L12 22.5235ZM3.96149 12.5235L12 20.562L20.0386 12.5235L12 4.48493L3.96149 12.5235Z'
        fill='#FF73A0'
      />
      <path
        d='M11.3046 13.9102H12.6934V8.36021H11.3046V13.9102Z'
        fill='#FF73A0'
      />
      <path
        d='M11.3046 16.6846H12.6934V15.2958H11.3046V16.6846Z'
        fill='#FF73A0'
      />
    </svg>
  );
};

export default WarningIcon;
