import { styled } from '@mui/system';
import { Color } from 'helpers/themeStyles';
import { Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

export const NftsGallery = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '23px',
  rowGap: '20px',
  columnGap: '20px',
});

export const AllNftsHeading = styled('h2')({
  margin: 0,
  color: Color.WHITE,
  marginTop: '43px',
});

export const StyledPagination = styled(Pagination)(() => ({
  '&.MuiPagination-root .MuiPagination-ul': {
    justifyContent: 'center',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  width: 'calc((100% - 40px)/3)',
  [theme.breakpoints.down(1550)]: {
    width: 'calc((100% - 20px)/2)',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));
