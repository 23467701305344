/**
 * @description Enum containing routes to all pages rendered by react-router
 */
export enum PageRoutes {
  Login = '/',
  Pool = '/pool',
  Swap = '/swap',
  Chat = '/chat',
  Liquidity = '/liquidity',
  Nft = '/nft-marketplace',
  Home = '/',
  SideMenu = '/menu',
  NftCollections = '/nft-marketplace/collections',
  NftCollection = '/nft-marketplace/collections/:id',
  CreateNftCollection = '/nft-marketplace/create-collection',
  CreateNft = '/nft-marketplace/create-nft',
  MyNfts = '/nft-marketplace/my-nfts',
  NftDetails = '/nft-details/:id',
  Launchpad = '/launchpad',
  LaunchpadProject = '/launchpad/:id',
  CreateProjectOnLaunchpad = '/launchpad/create-project',
  MyProjects = '/launchpad/my-projects',
}
