export const getCurrentDate = () => {
  const currentDate = new Date();
  return {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  };
};

export const isDateInPast = (
  year: number,
  month: number,
  day: number
): boolean => {
  const currentDate = getCurrentDate();
  return (
    new Date(year, month - 1, day) <
    new Date(currentDate.year, currentDate.month - 1, currentDate.day)
  );
};

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};
