import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DEXToken,
  Position,
  tickToPrice,
  utils,
} from '@omisoftnet/game-dex-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IPosition } from '@omisoftnet/game-dex-sdk/dist/providers';
import { Color } from 'helpers/themeStyles';
import { dexSelector } from 'state/dex/selectors';
import { StyledMobileTooltip, StyledTooltip } from './StyledComponents';
import {
  StyledMinMaxTitle,
  StyledMinMaxValue,
  StyledPositionItemContainer,
  StyledPositionItemDetails,
  StyledPositionItemIcon,
  StyledPositionItemStatus,
  StyledPositionSymbol,
  StyledPositionTolerance,
  StyledStatusIcon,
  StyledStatusTitle,
  StyledTipText,
} from './styles';
import { useAccount } from 'wagmi';

type Props = {
  fromV2?: boolean;
  position: IPosition;
};

type IDEXToken = DEXToken & {
  logoURI?: string;
};

export default function PositionItem({ position, fromV2 }: Props) {
  const { t } = useTranslation();
  const { chain } = useAccount();
  const [token1, setToken1] = useState<IDEXToken | undefined>(
    DEXToken.default()
  );
  const [token0, setToken0] = useState<IDEXToken | undefined>(
    DEXToken.default()
  );
  const [sdkPosition, setPosition] = useState<Position>();
  const [inRange, setInRange] = useState<boolean>(true);
  const [isClosedPosition, setIsClosedPosition] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  useEffect(() => {
    if (fromV2) {
      setToken0(position.token0());
      setToken1(position.token1());
    } else {
      getPosition();
      if (
        sdkPosition &&
        sdkPosition.tickLower &&
        sdkPosition.tickUpper &&
        sdkPosition.pool.tickCurrent
      ) {
        if (
          sdkPosition.tickLower <= sdkPosition.pool.tickCurrent &&
          sdkPosition.pool.tickCurrent <= sdkPosition.tickUpper
        ) {
          setInRange(true);
        } else setInRange(false);
      }
      if (position.liquidity().isZero()) {
        setIsClosedPosition(true);
      }
    }
  }, [sdkPosition, chain]);

  const getPosition = async () => {
    setPosition(position.wrapped());
    setToken0(position.token0());
    setToken1(position.token1());
    // DONT DO SAME JOB TWICE
    // try {
    //   // DONT USE HIDDEN VARS
    //   const position = await dex.liquidityPosition(position.id()!);

    //   setPosition(
    //     new Position({
    //       position: position?.wrapped()?.position,
    //       liquidity: position?.wrapped()?.liquidity,
    //       tickLower: position?.wrapped()?.tickLower,
    //       tickUpper: position?.wrapped()?.tickUpper,
    //     })
    //   );

    //   setToken0(position.token0());
    //   setToken1(position.token1());
    // } catch (error) {
    //   console.error('ERROR', error);
    // }
  };

  return (
    <Link
      // DONT USE HIDDEN VARS (position.wrapped().id, map data from back to this interface!)
      to={`/editPool/${position.id()}`}
      style={{ textDecoration: 'none' }}
    >
      <StyledPositionItemContainer>
        <StyledPositionItemDetails>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
          >
            <Stack
              direction='row'
              alignItems='center'
            >
              <StyledPositionItemIcon
                // WHY ON DEXToken exsist getter icon? Maybe for provide fallback variant when logoUri missing | From backend data map it to DEXToken
                src={token0?.icon || token0?.logoUri || token0?.icon}
                alt={token0?.name}
              />
              <StyledPositionItemIcon
                src={token1?.icon || token1?.logoUri || token1?.icon}
                alt={token1?.name}
                style={{ position: 'relative', left: '-4px' }}
              />
            </Stack>
            <StyledPositionSymbol>{`${token0?.symbol}/${token1?.symbol}`}</StyledPositionSymbol>
            <StyledPositionTolerance>
              {/* DONT USE HIDDEN VARS */}
              {!fromV2 && `${position.wrapped().pool.fee / 10000}%`}
            </StyledPositionTolerance>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
          >
            {fromV2 && (
              <>
                <StyledMinMaxValue>{`${utils.formatUnits(
                  `${position.token0().amount}`,
                  token0?.decimals
                )} ${token0?.symbol} `}</StyledMinMaxValue>
                <Stack mx={2}>
                  <img
                    src='./images/swap/vector.png'
                    alt='vector'
                    style={{ transform: 'rotate(90deg)', marginBottom: '-8px' }}
                  />
                  <img
                    src='./images/swap/vector.png'
                    alt='vector'
                    style={{ transform: 'rotate(-90deg)' }}
                  />
                </Stack>
                <StyledMinMaxValue>{`${utils.formatUnits(
                  `${position.token1().amount}`,
                  token1?.decimals
                )} ${token1?.symbol} `}</StyledMinMaxValue>
              </>
            )}
            {!fromV2 && (
              <>
                <StyledMinMaxTitle>{t('min')}:</StyledMinMaxTitle>
                <StyledMinMaxValue>{`${
                  Number(
                    tickToPrice(
                      // DONT USE HIDDEN VARS
                      position.token0().into(),
                      position.token1().into(),
                      position.wrapped().tickLower
                    ).toFixed(7)
                  ) === 0
                    ? 0
                    : Number(
                        tickToPrice(
                          // DONT USE HIDDEN VARS
                          position.token0().into(),
                          position.token1().into(),
                          position.wrapped().tickLower
                        ).toFixed(7)
                      ) > 10000000000
                    ? '∞'
                    : tickToPrice(
                        // DONT USE HIDDEN VARS
                        position.token0().into(),
                        position.token1().into(),
                        position.wrapped().tickLower
                      ).toFixed(7)
                } ${token0?.symbol} ${t('per')} ${
                  token1?.symbol
                }`}</StyledMinMaxValue>
                <Stack mx={2}>
                  <img
                    src='./images/swap/vector.png'
                    alt='vector'
                    style={{ transform: 'rotate(90deg)', marginBottom: '-8px' }}
                  />
                  <img
                    src='./images/swap/vector.png'
                    alt='vector'
                    style={{ transform: 'rotate(-90deg)' }}
                  />
                </Stack>
                <StyledMinMaxTitle>{t('max')}:</StyledMinMaxTitle>
                <StyledMinMaxValue>{`${
                  Number(
                    tickToPrice(
                      // DONT USE HIDDEN VARS
                      position.token0().into(),
                      position.token1().into(),
                      position.wrapped().tickUpper
                    ).toFixed(7)
                  ) === 0
                    ? 0
                    : Number(
                        tickToPrice(
                          // DONT USE HIDDEN VARS
                          position.token0().into(),
                          position.token1().into(),
                          position.wrapped().tickUpper
                        ).toFixed(7)
                      ) > 10000000000
                    ? '∞'
                    : tickToPrice(
                        // DONT USE HIDDEN VARS
                        position.token0().into(),
                        position.token1().into(),
                        position.wrapped().tickUpper
                      ).toFixed(7)
                } ${token1?.symbol} ${t('per')} ${
                  token0?.symbol
                }`}</StyledMinMaxValue>
              </>
            )}
          </Stack>
        </StyledPositionItemDetails>
        {!fromV2 && !isMobile && (
          <StyledTooltip
            title={
              inRange ? (
                <StyledTipText>{t('inRangeTip')}</StyledTipText>
              ) : (
                <StyledTipText>{t('outOfRangeTip')}</StyledTipText>
              )
            }
          >
            <StyledPositionItemStatus>
              <StyledStatusIcon
                style={{
                  background: isClosedPosition
                    ? Color.LIGHT_RED
                    : inRange
                    ? Color.GREEN
                    : Color.YELLOW,
                }}
              />
              <StyledStatusTitle>
                {isClosedPosition
                  ? t('closed')
                  : inRange
                  ? t('inRange')
                  : t('outOfRange')}
              </StyledStatusTitle>
            </StyledPositionItemStatus>
          </StyledTooltip>
        )}
        {!fromV2 && isMobile && (
          <StyledMobileTooltip
            title={
              inRange ? (
                <StyledTipText>{t('inRangeTip')}</StyledTipText>
              ) : (
                <StyledTipText>{t('outOfRangeTip')}</StyledTipText>
              )
            }
          >
            <StyledPositionItemStatus>
              <StyledStatusIcon
                style={{
                  background: isClosedPosition
                    ? Color.LIGHT_RED
                    : inRange
                    ? Color.GREEN
                    : Color.YELLOW,
                }}
              />
              <StyledStatusTitle>
                {isClosedPosition
                  ? t('closed')
                  : inRange
                  ? t('inRange')
                  : t('outOfRange')}
              </StyledStatusTitle>
            </StyledPositionItemStatus>
          </StyledMobileTooltip>
        )}
      </StyledPositionItemContainer>
    </Link>
  );
}
