import { useState } from 'react';

import ImportPool from 'components/ImportPool';
import LiquidityModal from 'components/LiquidityModal';
import AddLiquidityV2 from 'components/AddLiquidityV2';
import LiquidityPositions from 'components/LiquidityPositions';
import ChooseValidPairLiquidityV2 from 'components/ChooseValidPairLiquidityV2';

export default function LiquidityV2Wrap() {
  const [liquidityStep, setLiquidityStep] = useState(0);

  return (
    <>
      {liquidityStep === 0 ? (
        <LiquidityPositions
          openAddLiquidity={() => setLiquidityStep(1)}
          fromV2
        />
      ) : liquidityStep === 1 ? (
        <ChooseValidPairLiquidityV2
          onBack={() => setLiquidityStep(0)}
          openAddLiquidity={() => setLiquidityStep(2)}
        />
      ) : (
        <AddLiquidityV2 onBack={() => setLiquidityStep(1)} />
      )}
    </>
  );
}
