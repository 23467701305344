import React from 'react';

const StarsIcon = () => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3 7.6251L17.225 5.1251L14.625 3.9751L17.225 2.8501L18.3 0.475098L19.375 2.8501L21.975 3.9751L19.375 5.1251L18.3 7.6251ZM18.3 22.5001L17.225 20.1001L14.625 18.9751L17.225 17.8501L18.3 15.3251L19.375 17.8501L21.975 18.9751L19.375 20.1001L18.3 22.5001ZM7.325 18.6501L5.025 13.7251L0 11.4751L5.025 9.2251L7.325 4.3251L9.65 9.2251L14.65 11.4751L9.65 13.7251L7.325 18.6501ZM7.325 14.9501L8.525 12.5501L10.975 11.4751L8.525 10.4001L7.325 8.0001L6.15 10.4001L3.675 11.4751L6.15 12.5501L7.325 14.9501Z'
        fill='white'
      />
    </svg>
  );
};

export default StarsIcon;
