import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { utils } from 'ethers';
import { DEXToken, UniswapProvider, Position } from '@omisoftnet/game-dex-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { Color, TextSize } from 'helpers/themeStyles';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import TransactionResultPopup from 'components/TransactionResultPopup';
import { transactionStatuses } from 'helpers/transactionStatuses';
import { dexSelector } from 'state/dex/selectors';
import { setTransactionHash } from 'state/transactions/slice';
import {
  StyledDialog,
  StyledModalTitle,
  ContentWrap,
  ContentItem,
  TitleStyled,
  SubtitleStyled,
  StyledButton,
  TokenInfo,
} from './styles';

export default function ClaimFeesModal({
  isOpen,
  setIsOpen,
  setToken0Fees,
  setToken1Fees,
  setShowClaimFeesBtn,
  firstTokenSymbol,
  secondTokenSymbol,
  firstTokenIcon,
  secondTokenIcon,
  onClose,
  position,
  firstTokenValue,
  secondTokenValue,
  firstTokenAddress,
  secondTokenAddress,
  firstTokenChainId,
  secondTokenChainId,
  firstTokenDecimals,
  secondTokenDecimals,
  firstTokenName,
  secondTokenName,
  liquidityId,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  setShowClaimFeesBtn: Function;
  setToken0Fees: Function;
  setToken1Fees: Function;
  firstTokenSymbol?: string;
  secondTokenSymbol?: string;
  firstTokenIcon?: string;
  secondTokenIcon?: string;
  onClose: () => void;
  position: Position;
  firstTokenValue: string;
  secondTokenValue: string;
  firstTokenAddress: string;
  secondTokenAddress: string;
  firstTokenChainId: number;
  secondTokenChainId: number;
  firstTokenDecimals: number;
  secondTokenDecimals: number;
  firstTokenName: string;
  secondTokenName: string;
  liquidityId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { address } = useAccount();
  const dex = useSelector(dexSelector);
  const [openFeesWaitingPopup, setOpenFeesWaitingPopup] =
    useState<boolean>(false);
  const [transactionSubmittedStatus, setTransactionSubmittedStatus] =
    useState(false);
  const [transactionRejectedStatus, setTransactionRejectedStatus] =
    useState(false);

  async function collectFees() {
    const token0 = DEXToken.fromAddress(
      firstTokenAddress,
      utils.parseUnits(String(firstTokenValue), firstTokenDecimals),
      firstTokenChainId,
      firstTokenDecimals,
      firstTokenSymbol,
      firstTokenName,
      firstTokenIcon
    );
    const token1 = DEXToken.fromAddress(
      secondTokenAddress,
      utils.parseUnits(String(secondTokenValue), secondTokenDecimals),
      secondTokenChainId,
      secondTokenDecimals,
      secondTokenSymbol,
      secondTokenName,
      secondTokenIcon
    );
    if (!dex) return;
    const provider = await dex.getDEXProvider(
      firstTokenChainId,
      token0,
      token1
    );
    if (provider instanceof UniswapProvider) {
      try {
        const tx = await provider.collectFees(Number(liquidityId), position);
        dispatch(
          setTransactionHash({
            hash: tx!.hash,
            chainId: token0!.chainId,
            from: tx?.from,
            owner: address!,
            to: tx?.to,
            transaction_type: 'collectFees',
            data: {
              token_data: {
                token0: {
                  ...token0,
                  logoURI: firstTokenIcon,
                },
                token1: {
                  ...token1,
                  logoURI: secondTokenIcon,
                },
                token0Amount: firstTokenValue,
                token1Amount: secondTokenValue,
              },
            },
          })
        );
        setOpenFeesWaitingPopup(false);
        setTransactionSubmittedStatus(true);
      } catch (error) {
        console.error(error); // for debug
        setOpenFeesWaitingPopup(false);
        setTransactionRejectedStatus(true);
      }
    }
  }

  return (
    <>
      <StyledDialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            padding: '16px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
          }}
        >
          <StyledModalTitle style={{ fontSize: TextSize.MIDDLE }}>
            {t('claimFees')}
          </StyledModalTitle>
          <IconButton
            sx={{ p: 0, color: Color.WHITE }}
            onClick={() => setIsOpen(false)}
          >
            <Close />
          </IconButton>
        </Stack>
        <ContentWrap>
          <ContentItem style={{ marginBottom: '15px' }}>
            <TokenInfo>
              <img
                src={firstTokenIcon}
                width='24'
                height='24'
              />
              <TitleStyled>{firstTokenValue}</TitleStyled>
            </TokenInfo>
            <TitleStyled style={{ fontWeight: 'bold' }}>
              {firstTokenSymbol}
            </TitleStyled>
          </ContentItem>
          <ContentItem>
            <TokenInfo>
              <img
                src={secondTokenIcon}
                width='24'
                height='24'
              />
              <TitleStyled>{secondTokenValue}</TitleStyled>
            </TokenInfo>
            <TitleStyled style={{ fontWeight: 'bold' }}>
              {secondTokenSymbol}
            </TitleStyled>
          </ContentItem>
        </ContentWrap>
        <SubtitleStyled>{t('collectFeesDescription')} </SubtitleStyled>
        <StyledButton
          onClick={() => {
            collectFees();
            setOpenFeesWaitingPopup(true);
            setIsOpen(false);
          }}
        >
          {t('collect')}
        </StyledButton>
      </StyledDialog>
      {openFeesWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openFeesWaitingPopup}
          onClose={() => setOpenFeesWaitingPopup(false)}
          collectingFees={true}
        />
      )}
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {
            setOpenFeesWaitingPopup(false);
            setShowClaimFeesBtn(false);
            setToken0Fees('0');
            setToken1Fees('0');
          }}
          result={transactionStatuses.SUBMIT}
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => setOpenFeesWaitingPopup(false)}
          result={transactionStatuses.REJECT}
        />
      )}
    </>
  );
}
