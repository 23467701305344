import { styled } from '@mui/material';
import { Border, FontFamily, TextSize } from 'helpers/themeStyles';

export const CardWrap = styled('div')(({ theme }) => ({
  margin: '32px 0 30px',
  padding: '16px',
  width: 'calc(100% /4);',
  border: Border.ACCENT_BORDER,
  borderRadius: '8px',
  boxSizing: 'border-box',
  position: 'relative',
  [theme.breakpoints.down(1320)]: {
    width: 'calc((100% - 20px)/2)',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },
}));

export const RowWrap = styled('div')(() => ({
  display: 'flex',
  marginBottom: '10px',
}));

export const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  minHeight: '50px',
}));

export const TitleStyled = styled('span')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MEDIUM,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE,
  },
}));

export const PriceText = styled('p')({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  zIndex: 10,
  position: 'relative',
  margin: '12px 0 8px',
});
