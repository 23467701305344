import React from 'react';

const FAQMobileIcon: React.FC = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 0.5C10.9308 0.5 9.73077 1.7 9.73077 3.26923V8.80769C9.73077 10.3769 10.9308 11.5769 12.5 11.5769H18.0385L21.7308 15.2692V11.5769C23.3 11.5769 24.5 10.3769 24.5 8.80769V3.26923C24.5 1.7 23.3 0.5 21.7308 0.5H12.5ZM16.3658 3.26923H17.9517L19.5098 8.80769H18.1252L17.7495 7.42308H16.3649L16.0197 8.80769H14.8077L16.3658 3.26923ZM17.1154 4.19231C17.0231 4.56154 16.9197 5.012 16.8265 5.28892L16.568 6.5H17.6637L17.4034 5.288C17.2197 5.012 17.1154 4.56154 17.1154 4.19231ZM3.26923 9.73077C1.7 9.73077 0.5 10.9308 0.5 12.5V18.0385C0.5 19.6077 1.7 20.8077 3.26923 20.8077V24.5L6.96154 20.8077H12.5C14.0692 20.8077 15.2692 19.6077 15.2692 18.0385V12.5H12.5C10.7462 12.5 9.356 11.3 8.89446 9.73077H3.26923ZM7.50985 12.4132C9.07908 12.4132 9.81754 13.7055 9.81754 15.1825C9.81754 16.4748 9.37354 17.2945 8.63508 17.6637C9.00431 17.8483 9.44185 17.9462 9.90339 18.0385L9.55815 18.9615C8.912 18.7769 8.24185 18.4889 7.59569 18.2111C7.50338 18.1188 7.34185 18.1252 7.24954 18.1252C6.14185 18.0329 5.11538 17.1154 5.11538 15.2692C5.11538 13.7 6.03292 12.4132 7.50985 12.4132ZM7.50985 13.4231C6.77138 13.4231 6.41323 14.2538 6.41323 15.2692C6.41323 16.3769 6.77138 17.1154 7.50985 17.1154C8.24831 17.1154 8.63415 16.2846 8.63415 15.2692C8.63415 14.2538 8.24831 13.4231 7.50985 13.4231Z'
        fill='white'
      />
    </svg>
  );
};

export default FAQMobileIcon;
