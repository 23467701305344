/**
 * @description Class containing methods to work with localStorage
 */
export class LocalStorage {
  static getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  static setAccessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  static setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  static clearToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  static getUserChatData() {
    const data: string = localStorage.getItem('chatData') as string;
    return JSON.parse(data);
  }

  static setUserChatData(data: any) {
    const jsonData = JSON.stringify(data);
    return localStorage.setItem('chatData', jsonData);
  }
}
