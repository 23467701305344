import { Button, Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledLink = styled(Link)(({ height }: { height?: string; }) => ({
    width: '49%',
    textDecoration: 'none',
    fontFamily: FontFamily.INTER,

    '@media (max-width: 1767px)': {
      width: '100%',
    }, 

    '@media (max-width: 431px)': {
      height: height,
      backgroundColor: Color.BACKGROUND,
      borderRadius: BorderRadius.SMALL,
    },
  })
);

export const StyledProjectContainer = styled('div')(({ theme }) => ({
  backgroundPosition: '50%',
  backgroundSize: 'cover',
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  height: '374px',
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    height: '474px',
  },
  [theme.breakpoints.down('mobile')]: {
    height: '200px',
  },
}));

export const StyledRowContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '24px',
});

export const StyledHeaderBtn = styled('div')({
  padding: '6px',
  borderRadius: BorderRadius.SMALL,
  backgroundColor: Color.DARK_PURPLE_OPACITY_MIDDLE,
  color: Color.WHITE,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  zIndex: 1,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
});

export const StyledStatusBtn = styled('div')(({ theme }) => ({
  padding: '6px',
  borderRadius: BorderRadius.SMALL,
  backgroundColor: Color.DARK_YELLOW,
  color: Color.BACKGROUND,
  zIndex: 1,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  lineHeight: '160%',
  textTransform: 'capitalize',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
    padding: '4px 6px',
  },
}));

export const StyledEditAndPreviewBtn = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: BorderRadius.SEMI_SMALL,
  border: `2px solid ${Color.WHITE}`,
  backgroundColor: Color.AMOUNT_BACKGROUND,
  color: Color.WHITE,
  zIndex: 1,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: 700,
  lineHeight: '160%',
  textTransform: 'none',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
    padding: '6px 12px',
    border: `1px solid ${Color.WHITE}`,
  },
}));

export const StyledDeployBtn = styled(StyledEditAndPreviewBtn)({
  border: 'none',
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
});

export const StyledNetworkBtn = styled(StyledHeaderBtn)(({ theme }) => ({
  fontFamily: FontFamily.ZENDOTS,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  fontWeight: 400,

  [theme.breakpoints.down('mobile')]: {
    lineHeight: '90%',
  },
}));

export const StyledTextContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  zIndex: 1,
});

export const StyledNetworkIcon = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',

  [theme.breakpoints.down('mobile')]: {
    width: '20px',
    height: '20px',
  },
}));

export const StyledLogo = styled('img')({
  width: '64px',
  height: '64px',
  borderRadius: BorderRadius.SMALL,
  zIndex: 1,
});

export const StyledProjectTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontWeight: 700,
  fontSize: TextSize.MEDIUM,
  zIndex: 1,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.MIDDLE_PLUS,
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  zIndex: 1,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.EXTRA_SMALL,
  },
}));

export const StyledAccentText = styled(StyledText)({
  fontFamily: FontFamily.ZENDOTS,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
});

export const StyledIcon = styled('img')(({ theme }) => ({
  width: '25px',
  height: '25px',
  borderRadius: BorderRadius.HUGE,

  [theme.breakpoints.down('mobile')]: {
    width: '18px',
    height: '18px',
  },
}));

export const StyledOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: Color.BLACK_OPACITY_MIDDLE,
  transition: 'opacity 0.5s ease-in-out',
  borderRadius: BorderRadius.SMALL,
});

export const StyledCountdownContainer = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

export const StyledCountdownElement = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
  width: '50px',
  borderRadius: BorderRadius.SEMI_NORMAL,
  backgroundColor: Color.DARK_PURPLE_OPACITY_MIDDLE,

  [theme.breakpoints.down('mobile')]: {
    width: '40px',
  },
}));

export const StyledCountdownValue = styled('span')(({ theme }) => ({
  textAlign: 'center',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.EXTRA_SMALL,
  fontWeight: '700',
  margin: '0 0 5px',

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.TABLE_SMALL,
  },
}));

export const StyledCountdownLabel = styled('span')(({ theme }) => ({
  textAlign: 'center',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SUPER_SMALL,

  [theme.breakpoints.down('mobile')]: {
    fontSize: TextSize.LITTLE,
  },
}));

export const TransitionWrapper = styled('div')(() => ({
  transition: 'opacity 0.5s ease-in',
}));