import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from 'helpers/themeStyles';

export const PanelContainer = styled('div')(() => ({
  padding: '4px 0',

  background: Color.BACKGROUND,
  borderBottom: `1px solid ${Color.DARK_PURPLE_OPACITY_LIGHT}`,
}));

export const PanelWrapper = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    alignItems: 'center',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  '& .MuiTabs-indicator': {
    opacity: 0,
  },
}));

export const TabButton = styled(Tab)(() => ({
  '&.MuiTab-root': {
    minWidth: 'auto',
    minHeight: 'auto',
    height: '40px',
    padding: '4px 16px',
    flex: 'inherit',
    flexDirection: 'inherit',
    gap: '10px',
    borderRadius: BorderRadius.SEMI_SMALL,
    color: Color.WHITE,
    textTransform: 'none',
    fontSize: TextSize.TABLE_SMALL,
    fontWeight: '500',
    fontFamily: FontFamily.INTER,
  },

  svg: {
    padding: '9px 7px',
    borderRadius: BorderRadius.SEMI_SMALL,
    background: `${Color.PURPLE}!important`,
    boxShadow: BoxShadow.BUTTON_SHADOW,
  },
  '&.Mui-selected': {
    background: `${Color.DARK_PURPLE_OPACITY_MIDDLE}!important`,
  },
}));
