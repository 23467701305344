import { Box, Button, Dialog, Paper, styled, Typography } from '@mui/material';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: BorderRadius.LARGE,

    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      background: Color.BACKGROUND,
      overflowY: 'inherit',
      borderRadius: BorderRadius.SMALL,
    },
  },
}));

export const StyledContainer = styled(Paper)(({ theme }) => ({
  width: '412px',
  background: Color.BACKGROUND,
  border: `1px solid ${Color.BORDER_COLOR}`,
  boxShadow:
    '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 4px 99px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(2px)',
}));

export const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledContentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const SubmittedIconContainer = styled(Box)(() => ({
  width: '60px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: BorderRadius.HUGE,
  border: `3px solid ${Color.GREEN}`,
}));

export const StyledTitle = styled(Typography)(() => ({
  fontSize: TextSize.MIDDLE,
  marginBottom: '8px',
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  color: Color.WHITE,
}));

export const StyledButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
  padding: '16px',
  borderRadius: BorderRadius.SEMI_SMALL,
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
}));

export const StyledAddTokenButton = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  color: Color.PURPLE,
  border: `2px solid ${Color.PURPLE}`,
  padding: 12,
  borderRadius: BorderRadius.SEMI_SMALL,
  marginBottom: 16,

  [theme.breakpoints.down('mobile')]: {
    marginBottom: 0,
    fontFamily: FontFamily.ZENDOTS,
    fontWeight: 400,
    fontSize: TextSize.TABLE_SMALL,
    textTransform: 'uppercase',
  },
}));

export const StyledLink = styled(Typography)(({ theme }) => ({
  fontSize: TextSize.NORMAL,
  marginTop: '16px',
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  color: Color.WHITE,
  textDecoration: 'none',
  cursor: 'pointer',

  [theme.breakpoints.down('mobile')]: {
    margin: '19px 0px 16px 0px',
    color: Color.PURPLE,
  },
}));

// NFT

export const StyledBackButton = styled(Button)(() => ({
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
}));

export const StyledSmallText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  marginBottom: 24,
}));
