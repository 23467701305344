import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import dateFortmatter from 'helpers/dateFormatter';
import { ProjectDetails } from 'types/project';

import { StyledIcon } from 'components/Launchpad/Preview/ProjectItem/styles';
import { stableTokens } from 'helpers/nativeTokens';
import {
  StyledAccordionDetails,
  StyledAccordionTitle,
  StyledRowContainer,
  TokenDetailsBlock,
  TokenDetailsName,
  TokenDetailssValue,
} from '../styles';

type Props = {
  project?: ProjectDetails;
  isConnected?: boolean;
  minimumConsiderRise?: boolean;
  isOwner?: boolean;
  setIsChangeNetworkOpen: Function;
  chainID?: number;
  setIsOpenInvest: Function;
};

const SummaryPopUp: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        rowGap='20px'
        flex='1'
      >
        <StyledAccordionTitle>{t('summary')}</StyledAccordionTitle>
        <StyledAccordionDetails>
          <TokenDetailsBlock>
            <TokenDetailsName>{t('fundraiseGoal')}</TokenDetailsName>
            <StyledRowContainer>
              <StyledIcon
                src={stableTokens[project?.poolInformation?.chain!]?.icon}
              />
              <TokenDetailssValue>
                {project?.poolInformation.toRise}
              </TokenDetailssValue>
            </StyledRowContainer>
          </TokenDetailsBlock>
          <TokenDetailsBlock>
            <TokenDetailsName>{t('allocation')}</TokenDetailsName>
            <TokenDetailssValue>
              {project?.tokenSaleDetails.allocation
                ? project?.tokenSaleDetails.allocation
                : 'TBA'}
            </TokenDetailssValue>
          </TokenDetailsBlock>
          <TokenDetailsBlock>
            <TokenDetailsName>{t('pricePerToken')}</TokenDetailsName>
            <TokenDetailssValue>
              {project?.tokenSaleDetails.price
                ? project?.tokenSaleDetails.price
                : 'TBA'}
            </TokenDetailssValue>
          </TokenDetailsBlock>
          <TokenDetailsBlock>
            <TokenDetailsName>{t('tokenSaleStarts')}</TokenDetailsName>
            <TokenDetailssValue>
              {project?.poolInformation.launchDate
                ? dateFortmatter(
                    project?.poolInformation.launchDate!,
                    'dd MMMM yyyy'
                  )
                : 'TBA'}
            </TokenDetailssValue>
          </TokenDetailsBlock>
          <TokenDetailsBlock>
            <TokenDetailsName>{t('tokenDistributionDate')}</TokenDetailsName>
            <TokenDetailssValue>
              {project?.poolInformation.tokenDistributionDate
                ? dateFortmatter(
                    project?.poolInformation.tokenDistributionDate,
                    'dd MMMM yyyy'
                  )
                : 'TBA'}
            </TokenDetailssValue>
          </TokenDetailsBlock>
        </StyledAccordionDetails>
      </Stack>
      {/* {!isConnected && (
        <ConnectWalletButton
          variant='outlined'
          color='inherit'
          onClick={() => dispatch(setIsOpen(true))}
        >
          <StyledGradientText>{t('connectWallet')}</StyledGradientText>
        </ConnectWalletButton>
      )}
      {project?.status === 'closed' &&
      minimumConsiderRise &&
      participants?.length &&
      depositAmount?.gte(
        BigNumber.from(project?.tokenSaleDetails.minAllocation)
      ) &&
      isConnected ? (
        <StyledButton
          variant='outlined'
          color='inherit'
          onClick={() => {
            chainID === project.poolInformation.chain
              ? handleClaim()
              : setIsChangeNetworkOpen(true);
          }}
        >
          {t('claim')}
        </StyledButton>
      ) : project?.status === 'closed' &&
        !minimumConsiderRise &&
        participants?.length &&
        depositAmount?.gte(
          BigNumber.from(project?.tokenSaleDetails.minAllocation)
        ) &&
        isConnected ? (
        <StyledButton
          variant='outlined'
          color='inherit'
          onClick={() => {
            chainID === project.poolInformation.chain
              ? handleClaim()
              : setIsChangeNetworkOpen(true);
          }}
        >
          {t('redeem')}
        </StyledButton>
      ) : (
        <>
          {isConnected && project?.status === 'approved' && isOwner && (
            <StyledButton
              variant='outlined'
              color='inherit'
              onClick={() => {
                chainID !== project.poolInformation.chain
                  ? setIsChangeNetworkOpen(true)
                  : setOpenCommissionInfoPopup(true);
              }}
            >
              {t('deploy')}
            </StyledButton>
          )}
          {project?.launchpadAddress &&
            project.status !== 'closed' &&
            project.status === 'current' &&
            isConnected && (
              <StyledButton
                variant='outlined'
                color='inherit'
                onClick={() => {
                  chainID === project.poolInformation.chain
                    ? setIsOpenInvest(true)
                    : setIsChangeNetworkOpen(true);
                }}
              >
                {t('invest')}
              </StyledButton>
            )}
        </>
      )}

      <PlatformCommissionInfoPopup
        isOpen={openCommissionInfoPopup}
        onClose={() => setOpenCommissionInfoPopup(false)}
        project={project}
      />
      {transactionSubmittedStatus && (
        <TransactionResultPopup
          openPopup={transactionSubmittedStatus}
          setOpenPopup={setTransactionSubmittedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.SUBMIT}
          clearTokensValue={() => {}}
          reloadPage={true}
          fromLaunchpad={true}
          tokenFromLaunchpad={firstToken}
        />
      )}
      {transactionRejectedStatus && (
        <TransactionResultPopup
          openPopup={transactionRejectedStatus}
          setOpenPopup={setTransactionRejectedStatus}
          closeParentPopup={() => {}}
          result={transactionStatuses.REJECT}
          clearTokensValue={() => {}}
        />
      )}
      <WaitingPopup
        isOpen={isWaitingPopupOpen}
        onClose={() => {
          setIsWaitingPopupOpen(false);
        }}
      /> */}
    </>
  );
};

export default SummaryPopUp;
