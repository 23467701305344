import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StyledContainer, StyledTittle } from './styles';
import { useEffect, useState } from 'react';
import { fetchNFTCollections } from 'config/api';
import { NftCollection } from 'types/nftCollection';
import NftCollectionListItem from 'components/NftCollectionsList/NftCollectionListItem';

const NewestCollections = () => {
  const { t } = useTranslation();
  const [collections, setCollections] = useState<[NftCollection]>();
  const fetchCollections = async () => {
    const { data } = await fetchNFTCollections(`/nft-collections/all`, {
      limit: 4,
    });
    setCollections(data.docs);
  };
  useEffect(() => {
    fetchCollections();
  }, []);
  return (
    <>
      <StyledTittle>{t('newestCollections')}</StyledTittle>
      <StyledContainer>
        {collections?.length &&
          collections.map((collection) => {
            return (
              <NftCollectionListItem
                key={collection._id}
                nft={collection}
              />
            );
          })}
      </StyledContainer>
    </>
  );
};
export default NewestCollections;
