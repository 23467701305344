import { utils } from '@omisoftnet/game-dex-sdk';
const formatTokenValueInSwap = (
  value: string,
  format: boolean,
  decimals: number
) => {
  if (!value) return;
  const formattedValue = value.replace(/,/g, '');
  if (
    utils
      .parseUnits(
        formattedValue.substring(0, formattedValue.indexOf('.') + decimals),
        decimals
      )
      .gte(
        utils.parseUnits(
          '0.00001'.substring(0, '0.00001'.indexOf('.') + decimals),
          decimals
        )
      )
  ) {
    return value;
  } else if (
    utils
      .parseUnits(
        formattedValue.substring(0, formattedValue.indexOf('.') + decimals),
        decimals
      )
      .lte(
        utils.parseUnits(
          '0.00001'.substring(0, '0.00001'.indexOf('.') + decimals),
          decimals
        )
      ) &&
    !format
  ) {
    return value;
  }
  return '<0.00001';
};

export default formatTokenValueInSwap;
