import { Search } from '@mui/icons-material';
import {
  Button,
  createTheme,
  Dialog,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { BorderRadius, Color, FontFamily, TextSize } from 'helpers/themeStyles';
import theme from 'helpers/themeStyles';

export const themes = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: Color.BACKGROUND,
          border: `1px solid ${Color.BORDER_COLOR}`,
          boxShadow:
            '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 4px 99px rgba(0, 0, 0, 0.25)',

          borderRadius: BorderRadius.LARGE,
          maxHeight: '630px!important',
          width: '580px!important',
          maxWidth: '580px!important',

          [theme.breakpoints.down('mobile')]: {
            margin: 0,
            alignSelf: 'flex-end',
            borderRadius: `${BorderRadius.SMALL} ${BorderRadius.SMALL} 0px 0px`,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: 'hidden',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '0px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: '0px 16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: Color.WHITE,
        },
        input: {
          '&::placeholder': {
            color: Color.WHITE,
            opacity: '1',
          },

          [theme.breakpoints.down('mobile')]: {
            padding: '20px 0px',
          },
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root: {
          maxWidth: '100px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          margin: 0,
          width: 'auto',
        },
      },
    },
  },
});

export const StyledButton = styled(Button)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: BorderRadius.HUGE,
  height: '24px',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  boxShadow: 'none',
});
export const StyledIcon = styled(Icon)({
  backgroundColor: 'grey',
  marginRight: '10px',
});
export const StyledTokenIcon = styled('img')({
  width: '40px',
  height: '40px',
  borderRadius: BorderRadius.HUGE,
  marginRight: '8px',
});
export const StyledTokenSelectorIcon = styled(StyledTokenIcon)({
  width: '24px',
  height: '24px',
});
export const StyledTitle = styled(DialogTitle)({
  color: Color.WHITE,
  fontWeight: '700',
  fontSize: TextSize.MEDIUM,
  lineHeight: '150%',
  paddingTop: '24px',
  paddingLeft: '20px',
  paddingBottom: '24px',
  borderBottom: `1px solid ${Color.WHITE_OPACITY}`,
});

export const StyledInput = styled(TextField)(({ theme }) => ({
  border: `1px solid ${Color.WHITE_OPACITY}`,
  borderRadius: BorderRadius.SMALL,
  marginTop: '20px',
  marginLeft: '20px',
  marginRight: '20px',
  padding: 0,
}));

export const StyledCommonTokens = styled(Typography)(({ theme }) => ({
  fontFamily: 'Zen Dots',
  fontWeight: '400',
  fontSize: TextSize.SUPER_SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  color: Color.WHITE,
  padding: '20px',
  paddingBottom: '10px',
}));

export const StyledDialog = styled(Dialog)({});
export const StyledFab = styled(Fab)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  background: Color.WHITE_OPACITY,
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: BorderRadius.HUGE,
  padding: '8px 10px 8px 8px',

  '&:hover': {
    color: 'black',
    backgroundColor: 'gray',
  },
  '&.Mui-disabled': {
    color: Color.TEXT_GREY,
  },
});

export const StyledSearch = styled(Search)({
  color: 'white',
});

export const StyledTokenSymbol = styled(Typography)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
});

export const StyledTokenName = styled(Typography)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  color: Color.WHITE,
});

export const StyledCommonTokensContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: '20px',
  borderBottom: `1px solid ${Color.WHITE_OPACITY}`,

  [theme.breakpoints.down('mobile')]: {
    paddingRight: '28px',
  },
}));

export const StyledBalanceValue = styled(Typography)({
  fontFamily: FontFamily.INTER,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  color: Color.WHITE,
});
