//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  ErrorMessage,
  FastField,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import { format } from 'date-fns';

import { PageRoutes } from 'config/routes';
import { initialValues, validationSchema } from './config';
import { Monthes } from 'helpers/constants';
import {
  getCurrentDate,
  getDaysInMonth,
  isDateInPast,
} from 'helpers/dateCheckers';
import { Color } from 'helpers/themeStyles';
import {
  newProjectIdSelector,
  projectDetailsSelector,
} from 'state/launchpad/selectors';
import launchpadSlice from 'state/launchpad/slice';
import {
  createProjectThunk,
  getProjectByIdThunk,
} from 'state/launchpad/thunks';
import { btnAdditionalStyles } from '../CreateProject/styles';
import { FormValues } from './FormValuesInterface';
import {
  ExternalOptionCircle,
  InternalOptionCircle,
  selectMenuListStyle,
  selectPaperStyle,
  StyledCancelButton,
  StyledContainer,
  StyledCreateProjectWrapper,
  StyledErrorText,
  StyledInput,
  StyledLabel,
  StyledMenuItem,
  StyledNextButton,
  StyledOptionButton,
  StyledRowContainer,
  StyledSelect,
  StyledTitle,
} from './styles';
import { useAccount, useSwitchChain } from 'wagmi';
import { isChainSupproted } from 'helpers/isChainSupproted';

const CreateProjectFirstStep = ({
  activeStep,
  totalSteps,
  handleNext,
}: {
  activeStep?: number;
  totalSteps?: () => number;
  handleNext?: () => void;
}) => {
  const { t } = useTranslation();
  const { chain } = useAccount();
  const { chains } = useSwitchChain();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const currentProject = useSelector(projectDetailsSelector);
  const [dateError, setDateError] = useState(false);
  const location = useLocation();
  const projectIdFromLocation = location.state;
  const [startYear, setStartYear] = useState<number>(getCurrentDate().year);
  const [startMonth, setStartMonth] = useState<number>(getCurrentDate().month);
  const [startDay, setStartDay] = useState<number>(getCurrentDate().day);
  const [endYear, setEndYear] = useState<number>(getCurrentDate().year);
  const [endMonth, setEndMonth] = useState<number>(getCurrentDate().month);
  const [endDay, setEndDay] = useState<number>(getCurrentDate().day);
  const projectIdLS = localStorage.getItem('newProjectId');
  const projectIdRedux = useSelector(newProjectIdSelector);
  const dispatch = useDispatch();

  const years = Array.from(
    { length: 6 },
    (_, index) => getCurrentDate().year + index
  );
  useEffect(() => {
    if (projectIdFromLocation?.projectId) {
      dispatch(
        launchpadSlice.actions.setProjectId(projectIdFromLocation?.projectId)
      );
    }
  }, [projectIdFromLocation?.projectId]);
  useEffect(() => {
    if (projectIdLS) {
      dispatch(launchpadSlice.actions.setProjectId(projectIdLS));
    }
  }, [dispatch, projectIdLS]);

  useEffect(() => {
    if (projectIdRedux) {
      dispatch(getProjectByIdThunk({ id: projectIdRedux }));
    }
  }, [projectIdRedux, dispatch]);

  useEffect(() => {
    dispatch(launchpadSlice.actions.resetProjectDetails());
    return () => {
      dispatch(launchpadSlice.actions.resetProjectDetails());
    };
  }, []);

  const isDevelopmentMode = process.env.NODE_ENV === 'development';
  const networks = useMemo(() => {
    if (isDevelopmentMode) {
      return chains;
    }
    return chains.filter((network) => !network.testnet);
  }, [chains]);

  const Context = () => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    useEffect(() => {
      const date = new Date(
        values.launchDateYear,
        values.launchDateMonth - 1,
        values.launchDateDay
      );
      const formattedDate = format(date, 'yyyy-MM-dd');
      setFieldValue('poolInformation.launchDate', formattedDate);
    }, [values.launchDateDay, values.launchDateMonth, values.launchDateYear]);
    useEffect(() => {
      const date = new Date(
        values.poolInformation.tokenDateYear,
        values.poolInformation.tokenDateMonth - 1,
        values.poolInformation.tokenDateDay
      );
      const formattedDate = format(date, 'yyyy-MM-dd');
      setFieldValue('poolInformation.tokenDistributionDate', formattedDate);
    }, [
      values.poolInformation.tokenDateDay,
      values.poolInformation.tokenDateMonth,
      values.poolInformation.tokenDateYear,
    ]);
    if (
      values.poolInformation.tokenDistributionDate <
      values.poolInformation.launchDate
    ) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    return null;
  };

  useEffect(() => {
    if (currentProject) {
      setStartYear(
        new Date(currentProject.poolInformation.launchDate).getUTCFullYear()
      );
      setStartMonth(
        new Date(currentProject.poolInformation.launchDate).getMonth() + 1
      );
      setStartDay(
        new Date(currentProject.poolInformation.launchDate).getUTCDate()
      );
      setEndYear(
        new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getUTCFullYear()
      );
      setEndMonth(
        new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getMonth() + 1
      );
      setEndDay(
        new Date(
          currentProject.poolInformation.tokenDistributionDate
        ).getUTCDate()
      );
    }
  }, [currentProject]);

  return (
    <Formik
      initialValues={initialValues(currentProject!)}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        isValid,
      }: FormikProps<FormValues>) => (
        <StyledCreateProjectWrapper>
          <Form onSubmit={handleSubmit}>
            <StyledTitle mt={2}>{t('contactInfo')}</StyledTitle>
            <StyledContainer>
              <StyledLabel>
                {t('name')} <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='contactName'>
                {({ field }: { field: any }) => (
                  <Stack width={'100%'}>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.contactName && touched.contactName
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      placeholder='Name'
                      size='small'
                      required
                    />
                    <ErrorMessage
                      name='contactName'
                      component={StyledErrorText}
                    />
                  </Stack>
                )}
              </FastField>
            </StyledContainer>
            <StyledContainer>
              <StyledLabel>
                {t('email')} <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='contactEmail'>
                {({ field }: { field: any }) => (
                  <Stack width={'100%'}>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.contactEmail && touched.contactEmail
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      placeholder='Email'
                      size='small'
                      required
                    />
                    <ErrorMessage
                      name='contactEmail'
                      component={StyledErrorText}
                    />
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('telegramWhatsapp')}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='socialMedia.telegram'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.socialMedia?.telegram &&
                          touched.socialMedia?.telegram
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='socialMedia.telegram'
                      placeholder='Telegram or WhatsApp'
                      size='small'
                      required
                    />
                    {errors.socialMedia?.telegram &&
                      touched.socialMedia?.telegram && (
                        <StyledErrorText>
                          {errors.socialMedia?.telegram}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledTitle mt={4}>{t('basicInformation')}</StyledTitle>
            <StyledContainer>
              <StyledLabel>
                {t('projectName')} <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='projectTitle'>
                {({ field }: { field: any }) => (
                  <Stack width={'100%'}>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.projectTitle && touched.projectTitle
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      placeholder='Project Name'
                      size='small'
                      inputProps={{ maxLength: 25 }}
                      required
                    />
                    <Stack
                      direction='row'
                      gap={1}
                      alignItems='center'
                      justifyContent='flex-end'
                    >
                      <ErrorMessage
                        name='projectTitle'
                        component={StyledErrorText}
                      />
                      <StyledErrorText
                        sx={{
                          color:
                            values.projectTitle.length > 25
                              ? Color.RED
                              : Color.WHITE,
                          textAlign: 'right',
                        }}
                      >
                        {t('symbolsLeft', {
                          count: 25 - values.projectTitle.length,
                        })}
                      </StyledErrorText>
                    </Stack>
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('categories')}</StyledLabel>
              <FastField name='categories'>
                {({ field }: { field: any }) => (
                  <StyledInput
                    {...field}
                    sx={{
                      width: '100%',
                    }}
                    name='categories'
                    onChange={(e) => {
                      const categories = e.target.value;
                      setFieldValue('categories', categories.split(','));
                    }}
                    placeholder='Categories (separated by comma)'
                    size='small'
                  />
                )}
              </FastField>
            </StyledContainer>

            <StyledRowContainer direction={isMobile ? 'column' : 'row'}>
              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>
                  {t('website')} <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='socialMedia.website'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.socialMedia?.website &&
                            touched.socialMedia?.website
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='socialMedia.website'
                        placeholder='Website link'
                        size='small'
                        required
                      />
                      {errors.socialMedia?.website &&
                        touched.socialMedia?.website && (
                          <StyledErrorText>
                            {errors.socialMedia.website}
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>

              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>{t('video')}</StyledLabel>
                <FastField name='videoLink'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.videoLink && touched.videoLink
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        placeholder='Video link'
                        size='small'
                      />
                      {errors.videoLink && touched.videoLink && (
                        <StyledErrorText>{errors.videoLink}</StyledErrorText>
                      )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>
            </StyledRowContainer>

            <StyledRowContainer direction={isMobile ? 'column' : 'row'}>
              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>{t('whitepaper')}</StyledLabel>
                <FastField name='whitepaper'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.whitepaper && touched.whitepaper
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='whitepaper'
                        placeholder='Whitepaper link'
                        size='small'
                      />
                      {errors.whitepaper && touched.whitepaper && (
                        <StyledErrorText>{errors.whitepaper}</StyledErrorText>
                      )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>

              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>{t('pitchDeck')}</StyledLabel>
                <FastField name='pitchDeck'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.pitchDeck && touched.pitchDeck
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='pitchDeck'
                        placeholder='Pitch Deck link'
                        size='small'
                      />
                      {errors.pitchDeck && touched.pitchDeck && (
                        <StyledErrorText>{errors.pitchDeck}</StyledErrorText>
                      )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>
            </StyledRowContainer>

            <StyledContainer>
              <StyledLabel>{t('twitterURL')}</StyledLabel>
              <FastField name='socialMedia.twitter'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.socialMedia?.twitter &&
                          touched.socialMedia?.twitter
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='socialMedia.twitter'
                      placeholder='Twitter link'
                      size='small'
                      required
                    />
                    {errors.socialMedia?.twitter &&
                      touched.socialMedia?.twitter && (
                        <StyledErrorText>
                          {errors.socialMedia?.twitter}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('tokenInfo')} <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='tokenInformation'>
                {({ field }: { field: any }) => (
                  <Stack width={'100%'}>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.tokenInformation && touched.tokenInformation
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      placeholder='Token distribution, token sale metrics (different fundraising prices), release schedule, etc...'
                      size='small'
                      multiline
                      minRows={5}
                    />
                    <ErrorMessage
                      name='tokenInformation'
                      component={StyledErrorText}
                    />
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledRowContainer direction={isMobile ? 'column' : 'row'}>
              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>
                  {t('maxPerWallet')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='tokenSaleDetails.maxAllocation'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.tokenSaleDetails?.maxAllocation &&
                            touched.tokenSaleDetails?.maxAllocation
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='tokenSaleDetails.maxAllocation'
                        placeholder='Max baks per wallet'
                        size='small'
                      />
                      {errors.tokenSaleDetails?.maxAllocation &&
                        touched.tokenSaleDetails?.maxAllocation && (
                          <StyledErrorText>
                            {errors.tokenSaleDetails?.maxAllocation}
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>

              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>
                  {t('minPerWallet')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='tokenSaleDetails.minAllocation'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.tokenSaleDetails?.minAllocation &&
                            touched.tokenSaleDetails?.minAllocation
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='tokenSaleDetails.minAllocation'
                        placeholder='Min baks per wallet (greater or equal to token price)'
                        size='small'
                      />
                      {errors.tokenSaleDetails?.minAllocation &&
                        touched.tokenSaleDetails?.minAllocation && (
                          <StyledErrorText>
                            {errors.tokenSaleDetails?.minAllocation}
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>
            </StyledRowContainer>

            <StyledRowContainer direction={isMobile ? 'column' : 'row'}>
              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>
                  {t('tokenPrice')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='tokenSaleDetails.price'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.tokenSaleDetails?.price &&
                            touched.tokenSaleDetails?.price
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='tokenSaleDetails.price'
                        placeholder='eg: 0.5'
                        size='small'
                      />
                      {errors.tokenSaleDetails?.price &&
                        touched.tokenSaleDetails?.price && (
                          <StyledErrorText>
                            {errors.tokenSaleDetails?.price}
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>

              <StyledContainer width={isMobile ? '100%' : '50%'}>
                <StyledLabel>
                  {t('tokenSymbol')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='tokenSaleDetails.tokenSymbol'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.tokenSaleDetails?.tokenSymbol &&
                            touched.tokenSaleDetails?.tokenSymbol
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='tokenSaleDetails.tokenSymbol'
                        placeholder='Token symbol'
                        size='small'
                      />
                      {errors.tokenSaleDetails?.tokenSymbol &&
                        touched.tokenSaleDetails?.tokenSymbol && (
                          <StyledErrorText>
                            {errors.tokenSaleDetails?.tokenSymbol}
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>
            </StyledRowContainer>

            <StyledContainer>
              <StyledLabel>
                {t('tokenName')}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='tokenSaleDetails.tokenName'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.tokenSaleDetails?.tokenName &&
                          touched.tokenSaleDetails?.tokenName
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='tokenSaleDetails.tokenName'
                      placeholder='Token name'
                      size='small'
                    />
                    {errors.tokenSaleDetails?.tokenName &&
                      touched.tokenSaleDetails?.tokenName && (
                        <StyledErrorText>
                          {errors.tokenSaleDetails?.tokenName}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              {isMobile ? (
                <StyledLabel>
                  {t('tokenAllocationForSaleMobile')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
              ) : (
                <StyledLabel>
                  {t('tokenAllocationForSale')}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
              )}

              <FastField name='tokenSaleDetails.allocation'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.tokenSaleDetails?.allocation &&
                          touched.tokenSaleDetails?.allocation
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='tokenSaleDetails.allocation'
                      placeholder={
                        isMobile
                          ? 'eg: 100 000'
                          : 'How many tokens in total are you ready to sell on launchpad? eg: 100 000'
                      }
                      size='small'
                    />
                    {errors.tokenSaleDetails?.allocation &&
                      touched.tokenSaleDetails?.allocation && (
                        <StyledErrorText>
                          {errors.tokenSaleDetails?.allocation}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('totalSupply')}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='tokenSaleDetails.total_supply'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.tokenSaleDetails?.total_supply &&
                          touched.tokenSaleDetails?.total_supply
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='tokenSaleDetails.total_supply'
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(
                          'tokenSaleDetails.total_supply',
                          values.tokenSaleDetails.total_supply
                        );
                      }}
                      placeholder='Total token supply'
                      size='small'
                    />
                    {errors.tokenSaleDetails?.total_supply &&
                      touched.tokenSaleDetails?.total_supply && (
                        <StyledErrorText>
                          {errors.tokenSaleDetails?.total_supply}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('description')} <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='information'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.information && touched.information
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='information'
                      placeholder='Project description'
                      inputProps={{ maxLength: 100 }}
                      size='small'
                      multiline
                      minRows={5}
                      required
                    />
                    <Stack
                      direction='row'
                      gap={1}
                      alignItems='center'
                      justifyContent='flex-end'
                    >
                      {errors.information && touched.information && (
                        <StyledErrorText>{errors.information}</StyledErrorText>
                      )}
                      <StyledErrorText
                        sx={{
                          color: !(100 - values.information.length)
                            ? Color.RED
                            : Color.WHITE,
                          textAlign: 'right',
                        }}
                      >
                        {t('symbolsLeft', {
                          count: 100 - values.information.length,
                        })}
                      </StyledErrorText>
                    </Stack>
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('githubURL')}</StyledLabel>
              <FastField name='socialMedia.github'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.socialMedia?.github &&
                          touched.socialMedia?.github
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='socialMedia.github'
                      placeholder='Github URL'
                      size='small'
                    />
                    {errors.socialMedia?.github &&
                      touched.socialMedia?.github && (
                        <StyledErrorText>
                          {errors.socialMedia?.github}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('stateOfDevelopment')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='developmentState'>
                {({ field }: { field: any }) => (
                  <Stack width={'100%'}>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.developmentState && touched.developmentState
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      placeholder='Any product & MVP live? Testnet?'
                      size='small'
                    />
                    <ErrorMessage
                      name='developmentState'
                      component={StyledErrorText}
                    />
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('howMuchHaveYouAlreadyRaised')}</StyledLabel>
              <FastField name='hardCommitments'>
                {({ field }: { field: any }) => (
                  <StyledInput
                    {...field}
                    sx={{ width: '100%' }}
                    name='hardCommitments'
                    placeholder='Examples: 100k Seed , 500k Private-Sale'
                    size='small'
                  />
                )}
              </FastField>
            </StyledContainer>

            <StyledTitle mt={4}>{t('demoDexPoolInformation')}</StyledTitle>
            <StyledContainer>
              <StyledLabel>
                {t('howMuchAreYouLookingToRaise')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='poolInformation.toRise'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.poolInformation?.toRise &&
                          touched.poolInformation?.toRise
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='poolInformation.toRise'
                      placeholder='eg: $ 100 000'
                      size='small'
                    />
                    {errors.poolInformation?.toRise &&
                      touched.poolInformation?.toRise && (
                        <StyledErrorText>
                          {errors.poolInformation?.toRise}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('howMuchAreYouLookingToRaiseInTotal')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='poolInformation.toRiseTotal'>
                {({ field }: { field: any }) => (
                  <Stack width='100%'>
                    <StyledInput
                      {...field}
                      sx={{
                        borderColor:
                          errors.poolInformation?.toRiseTotal &&
                          touched.poolInformation?.toRiseTotal
                            ? Color.RED
                            : Color.DARK_PURPLE_OPACITY_LIGHT,
                      }}
                      name='poolInformation.toRiseTotal'
                      placeholder='eg: $ 100 000'
                      size='small'
                    />
                    {errors.poolInformation?.toRiseTotal &&
                      touched.poolInformation?.toRiseTotal && (
                        <StyledErrorText>
                          {errors.poolInformation?.toRiseTotal}
                        </StyledErrorText>
                      )}
                  </Stack>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('areYouFlexibleWithAmount')}</StyledLabel>
              <StyledRowContainer
                direction={'row'}
                style={{ gap: '16px' }}
              >
                <StyledOptionButton
                  backgroundImage={
                    values.poolInformation.amountFlexible ? 1 : 0
                  }
                  onClick={() => {
                    setFieldValue('poolInformation.amountFlexible', 1);
                  }}
                >
                  <StyledRowContainer
                    style={{ gap: '8px' }}
                    direction={'row'}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: values.poolInformation.amountFlexible
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('yes')}
                  </StyledRowContainer>
                </StyledOptionButton>
                <StyledOptionButton
                  backgroundImage={
                    !values.poolInformation.amountFlexible ? 1 : 0
                  }
                  onClick={() => {
                    setFieldValue('poolInformation.amountFlexible', 0);
                    setFieldValue(
                      'poolInformation.lowestPercentageOfFundraisingGoal',
                      null
                    );
                  }}
                >
                  <StyledRowContainer
                    style={{ gap: '8px' }}
                    direction={'row'}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: !values.poolInformation.amountFlexible
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('no')}
                  </StyledRowContainer>
                </StyledOptionButton>
              </StyledRowContainer>
            </StyledContainer>

            {Boolean(values.poolInformation.amountFlexible) && (
              <StyledContainer>
                <StyledLabel>
                  {t('enterLowestPercentageOfFundraisingGoal')}{' '}
                  <span style={{ color: Color.PINK }}>*</span>
                </StyledLabel>
                <FastField name='poolInformation.lowestPercentageOfFundraisingGoal'>
                  {({ field }: { field: any }) => (
                    <Stack width='100%'>
                      <StyledInput
                        {...field}
                        sx={{
                          borderColor:
                            errors.poolInformation
                              ?.lowestPercentageOfFundraisingGoal &&
                            touched.poolInformation
                              ?.lowestPercentageOfFundraisingGoal
                              ? Color.RED
                              : Color.DARK_PURPLE_OPACITY_LIGHT,
                        }}
                        name='poolInformation.lowestPercentageOfFundraisingGoal'
                        placeholder='Minimun permissible value - 50%'
                        size='small'
                      />
                      {errors.poolInformation
                        ?.lowestPercentageOfFundraisingGoal &&
                        touched.poolInformation
                          ?.lowestPercentageOfFundraisingGoal && (
                          <StyledErrorText>
                            {
                              errors.poolInformation
                                ?.lowestPercentageOfFundraisingGoal
                            }
                          </StyledErrorText>
                        )}
                    </Stack>
                  )}
                </FastField>
              </StyledContainer>
            )}

            <StyledContainer>
              <StyledLabel>
                {t('whenWouldYouLikeToLaunch')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={isMobile ? '8px' : 0}
                justifyContent='space-between'
                width='100%'
              >
                <StyledSelect
                  sx={{ maxWidth: isMobile ? '100%' : '32%' }}
                  name='launchDateYear'
                  value={values.launchDateYear}
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = parseInt(event.target.value);
                    setStartYear(value);
                    setFieldValue('launchDateYear', value);
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {years.map((year) => (
                    <StyledMenuItem
                      value={year}
                      key={year}
                      disabled={isDateInPast(year, startMonth, startDay)}
                    >
                      {year}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledSelect
                  name='launchDateMonth'
                  value={values.launchDateMonth}
                  sx={{ maxWidth: isMobile ? '100%' : '32%' }}
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const newMonth = parseInt(event.target.value);
                    const daysInMonth = getDaysInMonth(startYear, newMonth);
                    if (startDay > daysInMonth) {
                      setStartDay(daysInMonth);
                      setFieldValue('launchDateDay', daysInMonth);
                    }
                    setStartMonth(newMonth);
                    setFieldValue('launchDateMonth', newMonth);
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {Monthes.map((monthName, index) => (
                    <StyledMenuItem
                      value={index + 1}
                      key={index}
                      disabled={isDateInPast(startYear, index + 1, startDay)}
                    >
                      {monthName}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledSelect
                  sx={{ maxWidth: isMobile ? '100%' : '32%' }}
                  name='launchDateDay'
                  value={values.launchDateDay}
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setStartDay(parseInt(event.target.value));
                    setFieldValue(
                      'launchDateDay',
                      parseInt(event.target.value)
                    );
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {Array.from(
                    {
                      length: getDaysInMonth(startYear, startMonth),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <StyledMenuItem
                      value={day}
                      key={day}
                      disabled={isDateInPast(startYear, startMonth, day)}
                    >
                      {day}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </Stack>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('areYouFlexibleWithDate')}</StyledLabel>
              <StyledRowContainer
                direction={'row'}
                style={{ gap: '16px' }}
              >
                <StyledOptionButton
                  backgroundImage={values.poolInformation.dateFlexible ? 1 : 0}
                  onClick={() => {
                    setFieldValue('poolInformation.dateFlexible', 1);
                  }}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: values.poolInformation.dateFlexible
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('yes')}
                  </StyledRowContainer>
                </StyledOptionButton>
                <StyledOptionButton
                  onClick={() => {
                    setFieldValue('poolInformation.dateFlexible', 0);
                  }}
                  backgroundImage={!values.poolInformation.dateFlexible ? 1 : 0}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: !values.poolInformation.dateFlexible
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('no')}
                  </StyledRowContainer>
                </StyledOptionButton>
              </StyledRowContainer>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('whatChainWouldYouPreferToRaiseFundsOn')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <FastField name='poolInformation.chain'>
                {({ field }: { field: any }) => (
                  <StyledSelect
                    {...field}
                    name='poolInformation.chain'
                    MenuProps={{
                      MenuListProps: {
                        style: selectMenuListStyle,
                      },
                      PaperProps: {
                        style: selectPaperStyle,
                      },
                    }}
                    fullWidth
                    size='small'
                  >
                    {networks.map((network) => (
                      <StyledMenuItem
                        value={network.id}
                        key={network.id}
                      >
                        {network.name}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('doYouPlanToLaunchOnOtherPlatforms')}
              </StyledLabel>
              <StyledRowContainer
                direction={'row'}
                style={{ gap: '16px' }}
              >
                <StyledOptionButton
                  backgroundImage={
                    values.poolInformation.planingOtherLaunch ? 1 : 0
                  }
                  onClick={() => {
                    setFieldValue('poolInformation.planingOtherLaunch', 1);
                  }}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: values.poolInformation.planingOtherLaunch
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('yes')}
                  </StyledRowContainer>
                </StyledOptionButton>
                <StyledOptionButton
                  backgroundImage={
                    !values.poolInformation.planingOtherLaunch ? 1 : 0
                  }
                  onClick={() => {
                    setFieldValue('poolInformation.planingOtherLaunch', 0);
                  }}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: !values.poolInformation.planingOtherLaunch
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('no')}
                  </StyledRowContainer>
                </StyledOptionButton>
              </StyledRowContainer>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('areYouOpenToExclusiveIDO')}</StyledLabel>
              <StyledRowContainer
                direction={'row'}
                style={{ gap: '16px' }}
              >
                <StyledOptionButton
                  backgroundImage={values.poolInformation.exclusiveOpen ? 1 : 0}
                  onClick={() => {
                    setFieldValue('poolInformation.exclusiveOpen', 1);
                  }}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: values.poolInformation.exclusiveOpen
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('yes')}
                  </StyledRowContainer>
                </StyledOptionButton>
                <StyledOptionButton
                  backgroundImage={
                    !values.poolInformation.exclusiveOpen ? 1 : 0
                  }
                  onClick={() => {
                    setFieldValue('poolInformation.exclusiveOpen', 0);
                  }}
                >
                  <StyledRowContainer
                    direction={'row'}
                    style={{ gap: '8px' }}
                  >
                    <ExternalOptionCircle>
                      <InternalOptionCircle
                        style={{
                          background: !values.poolInformation.exclusiveOpen
                            ? ''
                            : Color.BACKGROUND_MAIN,
                        }}
                      />
                    </ExternalOptionCircle>
                    {t('no')}
                  </StyledRowContainer>
                </StyledOptionButton>
              </StyledRowContainer>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('whatOtherPlatformsAreYouPlanning')}</StyledLabel>
              <FastField name='poolInformation.otherPlatforms'>
                {({ field }: { field: any }) => (
                  <StyledInput
                    {...field}
                    sx={{ width: '100%' }}
                    name='poolInformation.otherPlatforms'
                    size='small'
                    placeholder='List of other platforms'
                  />
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('areYouWorkingWithMarketingAgencies')}
              </StyledLabel>
              <FastField name='poolInformation.marketingAgencies'>
                {({ field }: { field: any }) => (
                  <StyledInput
                    {...field}
                    sx={{ width: '100%' }}
                    name='poolInformation.marketingAgencies'
                    size='small'
                    placeholder='Marketing agencies '
                  />
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>{t('doYouHaveInfluencerNetwork')}</StyledLabel>
              <FastField name='poolInformation.supportingInfluencer'>
                {({ field }: { field: any }) => (
                  <StyledInput
                    {...field}
                    sx={{ width: '100%' }}
                    name='poolInformation.supportingInfluencer'
                    size='small'
                    placeholder='Influencer network'
                  />
                )}
              </FastField>
            </StyledContainer>

            <StyledContainer>
              <StyledLabel>
                {t('tokenDistributionDate')}{' '}
                <span style={{ color: Color.PINK }}>*</span>
              </StyledLabel>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={isMobile ? 1 : 0}
                justifyContent='space-between'
                width='100%'
              >
                <StyledSelect
                  sx={{
                    maxWidth: isMobile ? '100%' : '32%',
                    borderColor: dateError
                      ? Color.RED
                      : Color.DARK_PURPLE_OPACITY_LIGHT,
                  }}
                  name='poolInformation.tokenDateYear'
                  value={values.poolInformation.tokenDateYear}
                  // @ts-ignore
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.target.value);
                    setEndYear(value);
                    setFieldValue('poolInformation.tokenDateYear', value);
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {years.map((year) => (
                    <StyledMenuItem
                      value={year}
                      key={year}
                      disabled={isDateInPast(year, endMonth, endDay)}
                    >
                      {year}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledSelect
                  sx={{
                    maxWidth: isMobile ? '100%' : '32%',
                    borderColor: dateError
                      ? Color.RED
                      : Color.DARK_PURPLE_OPACITY_LIGHT,
                  }}
                  name='poolInformation.tokenDateMonth'
                  value={values.poolInformation.tokenDateMonth}
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const newMonth = parseInt(event.target.value);
                    const daysInMonth = getDaysInMonth(endYear, newMonth);
                    if (endDay > daysInMonth) {
                      setEndDay(daysInMonth);
                      setFieldValue(
                        'poolInformation.tokenDateDay',
                        daysInMonth
                      );
                    }
                    setEndMonth(newMonth);
                    setFieldValue('poolInformation.tokenDateMonth', newMonth);
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {Monthes.map((monthName, index) => (
                    <StyledMenuItem
                      value={index + 1}
                      key={index}
                      disabled={isDateInPast(endYear, index + 1, endDay)}
                    >
                      {monthName}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledSelect
                  sx={{
                    maxWidth: isMobile ? '100%' : '32%',
                    borderColor: dateError
                      ? Color.RED
                      : Color.DARK_PURPLE_OPACITY_LIGHT,
                  }}
                  name='poolInformation.tokenDateDay'
                  value={values.poolInformation.tokenDateDay}
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setEndDay(parseInt(event.target.value));
                    setFieldValue(
                      'poolInformation.tokenDateDay',
                      parseInt(event.target.value)
                    );
                  }}
                  MenuProps={{
                    MenuListProps: {
                      style: selectMenuListStyle,
                    },
                    PaperProps: {
                      style: selectPaperStyle,
                    },
                  }}
                  fullWidth
                  size='small'
                >
                  {Array.from(
                    { length: getDaysInMonth(endYear, endMonth) },
                    (_, i) => i + 1
                  ).map((day) => (
                    <StyledMenuItem
                      value={day}
                      key={day}
                      disabled={isDateInPast(endYear, endMonth, day)}
                    >
                      {day}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </Stack>
              {dateError && (
                <StyledErrorText>
                  {t('tokenDistributionDateMustBeBiggerThatLaunchDate')}
                </StyledErrorText>
              )}
            </StyledContainer>

            {totalSteps && activeStep !== totalSteps() - 1 && (
              <Stack
                justifyContent='space-between'
                alignItems='center'
                flexDirection='row'
                mt={8}
              >
                <StyledCancelButton
                  to={PageRoutes.Launchpad}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  {t('cancel')}
                </StyledCancelButton>
                <StyledNextButton
                  onClick={() => {
                    projectIdRedux
                      ? dispatch(
                          createProjectThunk({
                            id: projectIdRedux,
                            values: values,
                          })
                        )
                      : dispatch(createProjectThunk({ values: values }));
                    handleNext && handleNext();
                  }}
                  sx={{ mr: 1, ...btnAdditionalStyles }}
                  disabled={
                    Boolean(values.poolInformation.amountFlexible)
                      ? !isValid || dateError || !isChainSupproted(chain?.id!)
                      : !values.contactName ||
                        !values.contactEmail ||
                        !values.socialMedia.telegram ||
                        !values.projectTitle ||
                        !values.socialMedia.website ||
                        !values.tokenInformation ||
                        !values.tokenSaleDetails.allocation ||
                        !values.tokenSaleDetails.maxAllocation ||
                        !values.tokenSaleDetails.minAllocation ||
                        !values.tokenSaleDetails.price ||
                        !values.tokenSaleDetails.tokenName ||
                        !values.tokenSaleDetails.tokenSymbol ||
                        !values.tokenSaleDetails.total_supply ||
                        !values.information ||
                        !values.developmentState ||
                        !values.poolInformation.toRise ||
                        !values.poolInformation.toRiseTotal ||
                        !values.poolInformation.chain ||
                        dateError ||
                        Boolean(errors.videoLink) ||
                        !isChainSupproted(chain?.id!)
                  }
                >
                  {t('saveAndContinue')}
                </StyledNextButton>
              </Stack>
            )}
            <Context />
          </Form>
        </StyledCreateProjectWrapper>
      )}
    </Formik>
  );
};

export default CreateProjectFirstStep;
